import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./race_landing.css";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals} from "../../../utils";
import {TrackSelectionSection, StackSelectionSection, ResultsViewSection, LineupViewSection, SettingsSelectionSection} from "./Sections";
import $ from 'jquery';
import { StackInfoBox, RaceHowToBox } from "./Infoboxes";
import {prize_tier_tooltip,training_tier_tooltip,prize_wta_tooltip} from "./race_landing_tooltips";
import { toInteger } from "lodash";

let web3;
let racer_sim_watched_global = 0;
let predictor_sim_watched = 0;
let race_simulated_global = 0;
let current_racer_global = 0;
let current_predictor_global = 0;
let racer_invite_status_global = 0;
let race_hash_global = '';
let league_hash_global = '';
let open_invite_race = 0;
let open_entry_closed = 0;
let race_token_gated = 0;
let racer_token_gated = 0;
let predictions_closed = 0;
let entryDisplay = 'none';
let training_box_race_landing = 0;
let current_race_details = {};

export const RaceLanding = () => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    
    if((racerID.length == 0)||(accountAddress.length == 0)) {
        dispatch(setRacerLogin('',''));
        dispatch(updateLocation("splash"));
        window.location.href= '/';
    }

    var url_race_unique_id = '';
    var url_race_invite_list = '';
    var start_race_section = '';
    var tmp_left_nav_section = 'upcoming';
    var racehash = window.location.href.split("#/");
    if(racehash.length > 1) {
        var racehash2 = racehash[1].replace("#/",'');
        var racehash3 = racehash2.split("/");
        if(racehash3.length > 1) {
            url_race_unique_id = racehash3[1].replace("/",'');
        }
        if((racehash3.length > 2)&&(url_race_unique_id == 'invite')) {
            url_race_invite_list = racehash3[2].replace("/",'');
        }
        if(racehash3[0] == 'race') {
            start_race_section = 'details';
        } else {
            var racehash4 = racehash3[0].split("_");
            start_race_section = racehash4[1];
        }
    }
    race_hash_global = url_race_unique_id;

    var current_training_left = $("#current-racer-traces").val();
    var current_battles_left = $("#current-racer-iraces").val();
    var current_open_left = $("#current-racer-oraces").val();

    const [pageSet, setPage] = useState(false);
    const [headerSet, setHeader] = useState(false);
    const [race_id, setRaceID] = useState(0);
    const [final_race_id, setFinalRaceID] = useState('');
    const [race_unique_id, setRaceUniqueID] = useState(url_race_unique_id);
    const [race_unique_id_url, setRaceUniqueIDURL] = useState(url_race_unique_id);
    const [race_invite_list, setRaceInviteList] = useState(url_race_invite_list);
    const [race_invite_details, setRaceInviteDetails] = useState([]);
    const [race_details, setRaceDetails] = useState([]);
    const [racer_details, setRacerDetails] = useState([]);
    const [racer_status, setRacerStatus] = useState({});
    const [racer_creator_id, setRacerCreator] = useState(0);
    const [racer_invite_status, setRacerInviteStatus] = useState('N');
    const [racer_entry_status, setRacerEntryStatus] = useState('N');
    const [race_simulated, setRaceSimStatus] = useState('N');
    const [racer_sim_watched, setRacerSimWatched] = useState('N');
    const [current_race_section, setCurrentSection] = useState(start_race_section);
    const [leftRailHTML, setLeftRailHTML] = useState('');
    const [pageBannerHTML,setPageBannerHTML] = useState('');
    const [race_landing_html, setRaceLandingHTML] = useState('');
    const [race_name, setRaceName] = useState('');
    const [global_time_text,setGlobalTimeText] = useState('Time');
    const [race_game_type, setRaceType] = useState('');
    const [race_entry_close_text, setRaceEntryCloseText] = useState('');
    const [race_predictions_close_text, setRacePredictionCloseText] = useState('');
    const [race_exr, setRaceEXR] = useState(0);
    const [race_xp, setRaceXP] = useState('');
    const [race_entry_text, setRaceEntryText] = useState('');
    const [race_entry_laps, setRaceEntryLaps] = useState('');
    const [racer_count,setRacerCount] = useState('');
    const [raceTime, setRaceTime] = useState('');
    const [race_prize_type,setRacePrizeType] = useState('');
    const [race_prize_dist,setRacePrizeDist] = useState('');
    const [final_tier_tooltip,setFinalTierTooltop] = useState('');

    const race_creator_types = ['training','bot-race','daily','alpha','invite'];

    let race_track_id = 1;
    let entryFeeDisplay = 'none';
    

    function refreshNewRace() {
        var url_race_unique_id = '';
        var url_race_invite_list = '';
        var start_race_section = '';
        var racehash = window.location.href.split("#/");
        if(racehash.length > 1) {
            var racehash2 = racehash[1].replace("#/",'');
            var racehash3 = racehash2.split("/");
            if(racehash3.length > 1) {
                url_race_unique_id = racehash3[1].replace("/",'');
            }
            if((racehash3.length > 2)&&(url_race_unique_id == 'invite')) {
                url_race_invite_list = racehash3[2].replace("/",'');
            }
            if(racehash3[0] == 'race') {
                start_race_section = 'details';
            } else {
                var racehash4 = racehash3[0].split("_");
                start_race_section = racehash4[1];
            }
        }
        setRaceUniqueID(url_race_unique_id);
        setRaceUniqueIDURL(url_race_unique_id);
        setRaceInviteList(url_race_invite_list);
        setCurrentSection(start_race_section);

        race_hash_global = url_race_unique_id;
        setTimeout(function(){grabRaceDetails(); $(window).scrollTop(0); },500);
    }

    function refreshRaceGlobalHash() {
        var url_race_unique_id = '';
        var url_race_invite_list = '';
        var start_race_section = '';
        var racehash = window.location.href.split("#/");
        if(racehash.length > 1) {
            var racehash2 = racehash[1].replace("#/",'');
            var racehash3 = racehash2.split("/");
            if(racehash3.length > 1) {
                url_race_unique_id = racehash3[1].replace("/",'');
            }
            if((racehash3.length > 2)&&(url_race_unique_id == 'invite')) {
                url_race_invite_list = racehash3[2].replace("/",'');
            }
            if(racehash3[0] == 'race') {
                start_race_section = 'details';
            } else {
                var racehash4 = racehash3[0].split("_");
                start_race_section = racehash4[1];
            }
        }
        race_hash_global = url_race_unique_id;
    }

    function checkNewTrainingBox() {
        var new_training_landing = $("#current-racer-ibox-race-landing").val();
        training_box_race_landing = new_training_landing;
        if((new_training_landing == '')||(new_training_landing == undefined)) {
            setTimeout(function(){
                checkNewTrainingBox();
            },100);
        } else if (new_training_landing == 0) {
            
            showFirstTrainingInfobox('race_landing');
            setTimeout(function(){
                showFirstTrainingInfobox('race_landing');
            },2500);
        }
    }

    var racer_items = [];
    function grabRaceDetails() {
        refreshRaceGlobalHash();
        checkNewTrainingBox();
        
        var addData = new Object();
        var tmp_uniqud_id = race_hash_global;
        if(race_creator_types.indexOf(race_hash_global) >= 0) {
            tmp_uniqud_id = 'Y3qOGyUKkJ';
            if(race_hash_global == 'training') {
                if(current_training_left == 0) {
                    toggleRaceMenu('upcoming');
                }
            } else {
                if(current_battles_left == 0) {
                    toggleRaceMenu('upcoming');
                }
            }

            open_invite_race = 0;
        }
        addData.raceUniqueID = encodeURIComponent(tmp_uniqud_id);
        addData.racerID = encodeURIComponent(racerID);
        race_simulated_global = 0;
        axiosInstance.post('race/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var race_details = result.data;
                if(race_creator_types.indexOf(race_hash_global) === -1) {
                    setFinalRaceID(race_details[0].race_id);
                    setRaceID(race_details[0].race_id);
                    if(race_details[0].race_simulated == 1) {
                        setRaceSimStatus('Y');
                        race_simulated_global = 1;
                        tmp_left_nav_section = 'past';
                    }
                    setRacerCreator(race_details[0].creator_id);
                    race_track_id = race_details[0].race_track_id;
                    open_invite_race = race_details[0].open_invite_race;
                    open_entry_closed = race_details[0].entry_window_over;
                    predictions_closed = race_details[0].predictions_window_over;
                    race_token_gated = race_details[0].token_gated;
                    racer_token_gated = race_details[0].racer_gated;

                    setTimeout(function(){
                        $("#race-id-header").val(race_details[0].race_id);
                        $("#race-unique-id-header").val(race_details[0].race_unique_id);
                    },500);
                }
                current_race_details = race_details[0];
                setRaceDetails(race_details[0]);
                grabRaceParticipants(race_details[0]);
                loadLeftNavigation(race_details[0]);
                startRaceMenu(tmp_left_nav_section);
            }
            var new_races_check = $("#notification-new-races").val();
            if(new_races_check != undefined) {
                if(parseInt(new_races_check) > 0) {
                    $("#racetrack-new-races-text").html(new_races_check);
                    $("#racetrack-new-races-text").show();
                } else {
                    $("#racetrack-new-races-text").hide();
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabRaceParticipants(raceDetails) {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(race_hash_global);
        addData.predictorID = userID;
        current_racer_global = 0;
        racer_sim_watched_global = 0;
        current_predictor_global = 0;
        predictor_sim_watched = 0;
        axiosInstance.post('race/racer_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_list = result.data;
                var current_racer_status = {};
                for(var i = 0;i < racer_list.length;i++) {
                    var racer_predicted_watched = racer_list[i]['racer_predicted_watched'];
                    var racer_sim_watched = racer_list[i]['racer_sim_watched'];
                    var user_racer_predicted = racer_list[i]['racer_predicted'];

                    if(racer_list[i].racer_id == racerID) {
                        current_racer_status = racer_list[i];
                        current_racer_global = 1;
                        setRacerEntryStatus('Y');
                        racer_sim_watched_global = racer_sim_watched;
                        if(racer_list[i].racer_sim_watched == 1) {
                            setRacerSimWatched('Y');
                        }
                    }
                    if(user_racer_predicted == 1) {
                        predictor_sim_watched = racer_predicted_watched;
                        current_predictor_global = 1;
                    }
                }
                setRacerStatus(current_racer_status);
            }
            setRacerDetails(result.data);
            grabRacersInvited(raceDetails,result.data);
            
        }).catch(error => {
            console.log(error);
        });
    }

    function grabRacersInvited(raceDetails,racerList) {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(race_hash_global);
        racer_invite_status_global = 0;
        axiosInstance.post('race/invite_list/',addData).then(result => {
            var invitedDetails = result.data;
            if(Object.keys(invitedDetails).length > 0) {
                var final_invite_list = invitedDetails;
                for(var m = 0;m < final_invite_list.length;m++) {
                    if(final_invite_list[m].racer_id == racerID) {
                        setRacerInviteStatus('Y');
                        racer_invite_status_global = 1;
                    }
                }
                
            }
            setRaceInviteDetails(invitedDetails);
            loadRaceGlobalInfo(raceDetails,racerList,invitedDetails);
            loadRaceSections(raceDetails,racerList,invitedDetails);
        }).catch(error => {
            console.log(error);
        });
    }

    function checkEnterRace() {
        
        

        var current_track_selected = $("#current-track-selected").val();
        var current_track_system_selected = $("#current-track-system-selected").val();
        var current_training_left = parseInt(window.$("#current-racer-traces").val());
        var current_battles_left = parseInt(window.$("#current-racer-iraces").val());
        var current_open_left = parseInt(window.$("#current-racer-oraces").val());
        
        if(current_track_selected == 0) {
            alert('Select A Track To Race On');
        } else {
            $("#stakingbutton").hide();
            $("#stakingloading").show();
            $("#modalentrychecksubmit").hide();
            $("#modalentrycheckloading").show();
            var tmp_race_id = $("#race-id-header").val();
            var tmp_race_uniq_id = $("#race-unique-id-header").val();
            var current_host = window.location.host;

            race_track_id = current_track_selected;

            if(window.location.hash.indexOf("edit_stack") >= 0) {
                updateRaceStack(tmp_race_id,tmp_race_uniq_id);
            } else {
                if((tmp_race_id == 0)||(tmp_race_id == '')) {
                    if(race_hash_global == 'invite') {
                        // if ((current_training_left > 0)&&(current_host != 'tester.exiledracers.com')&&(current_host != 'localhost:3000')&&(current_host != 'localhost:3001')) {
                        //     alert('Unable to Enter. Complete Your Daily Training Races Before Joining.');
                        //     toggleRaceMenu('upcoming');
                        // }
                        // if ((current_battles_left > 0)||((current_host == 'tester.exiledracers.com')||(current_host == 'localhost:3000')||(current_host != 'localhost:3001'))) {
                        //     var challengefee = $("#settingsRPMEntryFee").val();
                        //     var challengercount = race_invite_list.split(",");
                        //     var challenge_racers = challengercount.length+1;
                        //     var challenge_exr = challenge_racers*25;
                        //     // createNewRace('Race Battle', 5, challenge_racers, challengefee, 100, challenge_exr, 0, 0, 'inline_enter');
                        //     createNewRace('Race Battle', 5, challenge_racers, 0, 0, 0, 0, 0, 'inline_enter');
                        // } else {
                        //     alert('Unable to Create New Race. No Invite Races Left');
                        //     toggleRaceMenu('upcoming');
                        // }
    
                        var challengefee = $("#settingsRPMEntryFee").val();
                        var challengercount = race_invite_list.split(",");
                        var challenge_racers = challengercount.length+1;
                        var challenge_exr = challenge_racers*25;
                        // createNewRace('Race Battle', 5, challenge_racers, challengefee, 100, challenge_exr, 0, 0, 'inline_enter');
                        createNewRace('Race Battle', 5, challenge_racers, 0, 0, 0, 0, 0, 'inline_enter');
                    } else {
                        // if ((current_training_left > 0)||((current_host == 'tester.exiledracers.com')||(current_host == 'localhost:3000')||(current_host != 'localhost:3001'))) {
                        //     // createNewRace('Training Race', 5, 2, 0, 100, 50, 25, 0, 'inline_enter');
                        //     createNewRace('Training Race', 5, 2, 0, 0, 0, 0, 0, 'inline_enter');
                        // } else {
                        //     alert('Unable to Enter. No Daily Training Races Left.');
                        //     toggleRaceMenu('upcoming');
                        // }
    
                        createNewRace('Training Race', 5, 2, 0, 0, 0, 0, 0, 'inline_enter');
                    }
                } else {
                    // if (current_battles_left == 0) {
                    //     alert('Unable to Enter. No Invite Races Left');
                    //     toggleRaceMenu('upcoming');
                    // } else if ((current_training_left > 0)&&(current_host != 'tester.exiledracers.com')&&(current_host != 'localhost:3000')&&(current_host != 'localhost:3001')) {
                    //     alert('Unable to Enter. Complete Your Daily Training Races Before Joining.');
                    //     toggleRaceMenu('upcoming');
                    // } else {
                    //     enterRace(tmp_race_id,tmp_race_uniq_id);
                    // }
                    
                    setTimeout(function() {
                        if((parseInt(window.$("#current-racer-oraces").val()) > 0)||(current_race_details['open_invite_race'] == 0)||(window.$("#current-racer-oraces").val() == undefined)) {
                            enterRace(tmp_race_id,tmp_race_uniq_id);
                        } else {
                            alert('Unable to Enter. No Open Races Left');
                            toggleRaceMenu('upcoming');
                        }
                    },1500);
                }
            }
        }
    }

    function createNewRace(raceName, totalLaps, totalRacers, entryFee, xpPrize, coinPrize, coinPrize2, coinPrize3, returnResult) {

        var scoring_type = $("#settingsScoring").val();
        var prize_type = $("#settingsPrizes").val();
        var paid_entry = 0;
        if(prize_type == 'paid') {
            paid_entry = $("#settingsPaidEntryFee").val();
        }
        var prize_dist = $("#settingsWinnings").val();
        if(raceName == 'Training Race') {
            prize_dist = 'standard';
        }

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.raceName = encodeURIComponent(raceName);
        addData.raceLaps = encodeURIComponent(totalLaps);
        addData.totalRacers = encodeURIComponent(totalRacers);
        addData.entryFee = encodeURIComponent(entryFee);
        addData.xpPrize = encodeURIComponent(xpPrize);
        addData.coinPrize = encodeURIComponent(coinPrize);
        addData.coinPrize2 = encodeURIComponent(coinPrize2);
        addData.coinPrize3 = encodeURIComponent(coinPrize3);
        addData.raceScoring = encodeURIComponent(scoring_type);
        addData.prizeScoring = encodeURIComponent(prize_dist);
        addData.paidEntryFee = encodeURIComponent(paid_entry);
        addData.paidEntryCoin = 'USDC';
        addData.raceHost = encodeURIComponent(window.location.host);

        addData.boosters = 1;
        addData.trackID = race_track_id;

        var track_system_selected = $("#current-track-system-selected").val();
        if(track_system_selected.length > 0) {
            addData.trackGalaxy = track_system_selected;
        }

        axiosInstance.post('race/create/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var race_info = result.data;
                if(returnResult == 'refresh') {
                    window.location.href='/#/race/'+race_info.race_unique_id+'/reload';
                    dispatch(updateLocation("race_landing"));
                    setTimeout(function(){ refreshNewRace(); },500);
                } else {
                    setFinalRaceID(race_info.race_id);
                    setRaceUniqueID(race_info.race_unique_id);
                    race_hash_global = race_info.race_unique_id;
                    setTimeout(function(){
                        enterRace(race_info.race_id,race_info.race_unique_id);
                    },2000);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function enterRace(finalRaceID,finalRaceUniqID) {
        updateEntryItems();

        var addData = new Object();
        addData.raceID = encodeURIComponent(finalRaceID);
        addData.racerID = encodeURIComponent(racerID);
        addData.shipID = encodeURIComponent(entryRacer);
        addData.pilotID = encodeURIComponent(entryPilot);
        addData.boosterID = entryBoosters;

        axiosInstance.post('race/racer_entry/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                current_training_left = $("#current-racer-traces").val();
                current_battles_left = $("#current-racer-iraces").val();
                current_open_left = $("#current-racer-oraces").val();

                // SEND INVITES IF INVITE, ADD BOT IF ALPHA OTHERWISE JUST GO TO LINEUP
                if(race_unique_id_url == 'invite') {
                    inviteRacers(race_invite_list,finalRaceID,finalRaceUniqID);
                    $("#current-racer-iraces").val((current_battles_left-1));
                    current_battles_left = current_battles_left-1;
                } else if(race_creator_types.indexOf(race_unique_id_url) >= 0) {
                    var compData = new Object();
                    compData.raceID = encodeURIComponent(finalRaceID);
                    compData.racerID = encodeURIComponent(2);
                    compData.botRacer = encodeURIComponent(1);
                    compData.botLevel = encodeURIComponent(1);
                    axiosInstance.post('race/racer_entry/',compData).then(result2 => {
                        if(Object.keys(result2.data).length > 0) {
                            $("#current-racer-traces").val((current_training_left-1));
                            current_training_left = current_training_left-1;
                            window.location.href='/#/race/'+finalRaceUniqID;
                            dispatch(updateLocation("race_landing"));
                            setTimeout(function(){ $(window).scrollTop(0); window.location.reload(); },1000);
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                } else {
                    if(current_race_details['open_invite_race'] == 1) {
                        $("#current-racer-oraces").val((current_open_left-1));
                        current_open_left = current_open_left-1;
                    } else {
                        $("#current-racer-iraces").val((current_battles_left-1));
                        current_battles_left = current_battles_left-1;
                    }
                    
                    $(window).scrollTop(0);
                    window.location.reload();
                    setTimeout(function(){ grabRaceDetails(); },500);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function updateRaceStack(finalRaceID,finalRaceUniqID) {
        updateEntryItems();

        var addData = new Object();
        addData.raceID = encodeURIComponent(finalRaceID);
        addData.racerID = encodeURIComponent(racerID);
        addData.shipID = encodeURIComponent(entryRacer);
        addData.pilotID = encodeURIComponent(entryPilot);
        addData.boosterID = entryBoosters;
        addData.updateStack = 1;

        axiosInstance.post('race/racer_entry_update/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                window.location.href='/#/race/'+finalRaceUniqID;
                dispatch(updateLocation("race_landing"));
                setTimeout(function(){ $(window).scrollTop(0); window.location.reload(); },1000);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function inviteRacers(racerNames,finalRaceID,finalRaceUniqID) {
        var addData = new Object();
        addData.raceID = encodeURIComponent(finalRaceID);
        addData.racerNames = racerNames;
        addData.invitedByID = encodeURIComponent(racerID);
        axiosInstance.post('race/racer_invite/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                if(racerNames.length > 0) {
                    var finalnames = '';
                    var racerNamesSplit = racerNames.split(",");
                    if(racerNamesSplit.length > 1) {
                        for(var i = 0; i < racerNamesSplit.length; i++) {
                            if((i > 0)&&((i+1) == racerNamesSplit.length)) {
                                finalnames = finalnames+ ' and ';
                            } else if (i > 0) {
                                finalnames = finalnames+ ', ';
                            }
                            finalnames = finalnames+racerNamesSplit[i];
                        }
                    } else {
                        finalnames = racerNames;
                    }
                    
                    window.M.toast({html: 'Sent Invites To: '+finalnames, displayLength: 2000});
                }
                setTimeout(function(){
                    var invitebox = document.getElementById('invitebox');
                    var invite_instance = window.M.Modal.getInstance(invitebox);
                    invite_instance.close();
                    document.getElementById("invitechecksubmit").style.display = 'block';
                    document.getElementById("invitecheckloading").style.display = 'none';
                },1500);
                setTimeout(function(){
                    if(race_unique_id_url != finalRaceUniqID) {
                        window.location.href='/#/race/'+finalRaceUniqID;
                        dispatch(updateLocation("race_landing"));
                        setTimeout(function(){ $(window).scrollTop(0); window.location.reload(); },1000);
                    } else {
                        setRaceUniqueID(finalRaceUniqID);
                        setRaceUniqueIDURL(finalRaceUniqID);
                        race_hash_global = finalRaceUniqID;
                        setTimeout(function(){ grabRaceDetails(); },500);
                    }
                },2000);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function refreshInvites() {
        setTimeout(function(){
            if(race_creator_types.indexOf(race_unique_id) >= 0) {
                window.location.href='/#/race/'+race_unique_id;
                dispatch(updateLocation("race_landing"));
                setTimeout(function(){ refreshNewRace(); },500);
            } else {
                race_hash_global = race_unique_id;
                setTimeout(function(){ grabRaceDetails(); },500);
            }
        },2000);
    }

    function refreshPageHeader() {
        
    }
    
    var entryRacer = 0;
    var entryPilot = 0;
    var entryBoosters = 0;
    function updateEntryItems() {
        var caros = document.getElementsByClassName("carousel-item active");
        for(var i = 0; i < caros.length;i++) {
            var activeitem = caros[i].id.split("_");
            var item_type = activeitem[0];
            var item_id = activeitem[1];
            if(item_type == 'speeder') {
                entryRacer = item_id;
            } else if(item_type == 'racecraft') {
                entryRacer = item_id;
            } else if (item_type == 'pilot') {
                entryPilot = item_id;
            } else if (item_type == 'booster') {
                entryBoosters = item_id;
            }
        }
    }
    function loadRaceGlobalInfo(raceDetails,racerList,invitedDetails) {
        var raceName = 'Loading...';
        if(raceDetails.race_name !== undefined) {
            if(raceDetails.race_name != 'Race Battle') {
                raceName = raceDetails.race_name;
            } else {
                raceName = raceDetails.race_name+" #"+raceDetails.race_id;
            }
        }
        // if((open_invite_race == 1)&&(open_entry_closed == 0)) {
        //     var raceName = 'Open Race Battle';
        // }
        
        var totalEXR = raceDetails.race_coin_prize;
        var totalXP = raceDetails.race_xp_prize;
        var totalLaps = raceDetails.race_laps;
        var botRace = raceDetails.botRace;
        var legendaryRacers = raceDetails.legendary_exiled_racers;
        var days_ended = raceDetails.days_since_race_ended;
        var days_started = raceDetails.days_since_race_starts;
        var date_entry_close = raceDetails.date_race_entry_close_eastern;
        var date_predictions_close = raceDetails.date_race_predictions_close_eastern;
        var date_simulation_close = raceDetails.date_race_simulated_close_eastern;
        var league_group_number = raceDetails.league_group_number;
        var league_season_id = raceDetails.league_season_id;
        var league_group_count = raceDetails.league_groups;
        if((league_group_number > 0)&&(league_group_count > 1)) {
            raceName = raceDetails.race_name+" // Group "+league_group_number;
        } else if((league_group_number > 0)&&(league_group_count == 1)) {
            raceName = raceDetails.race_name+" // Race #"+raceDetails.race_id; 
        }

        if(league_season_id > 0) {
            $("#racertimetxt").removeClass("l7");
            $("#racerrewardstxt").hide();
            $("#racerrewardstxt2").html('');
        }

        if(raceDetails.botRace == 1) {
            totalXP = 0;
            totalEXR = 0;
        }

        var race_time = 'Today';
        var resultsDisplay = 'none';
        entryDisplay = 'none';
        var lineupDisplay = 'block';
        var invitingDisplay = 'none';
        var simstatustext = 'none';
        entryFeeDisplay = 'none';

        var racers_invited = '';
        var race_simulated = window.$("#race-simulated-header").val();
        var racer_entered = window.$("#racer-entry-header").val();
        var racer_watched = window.$("#racer-simulation-watched").val();
        var racer_invited = window.$("#racer-invite-status").val();
        var entry_close_time_text = '';
        var pred_close_time_text = '';

        if((racer_watched == 'N')&&(race_simulated == 'Y')) {
            entryDisplay = 'none';
            race_time = 'Ready';
            simstatustext = 'block';
        } else if((days_ended !== undefined)&&(days_ended !== 'null')&&(days_ended !== '')&&(days_ended !== null)) {
            if(days_ended == 0) {
                race_time = "Ended Today";
            } else if (days_ended == 1) {
                race_time = "Ended Yesterday";
            } else if (league_season_id > 0) {
                var simulation_close_time = new Date(date_simulation_close);
                var sim_min = simulation_close_time.getMinutes();
                var sim_hour = simulation_close_time.getHours();
                var sim_month = simulation_close_time.toLocaleString('default', { month: 'short' });
                var sim_date = simulation_close_time.getDate();
                var timedirection = 'PM';
                if (sim_hour < 12) {
                    timedirection = 'AM';
                } else if (sim_hour > 12) {
                    sim_hour = sim_hour - 12;
                }
                if(sim_hour == 0) {
                    sim_hour = 12;
                }
                let race_time_tmp = sim_hour;
                if(sim_min > 10) {
                    race_time_tmp = race_time_tmp + ':'+sim_min+' ';
                } else if(sim_min > 0) {
                    race_time_tmp = race_time_tmp + ':0'+sim_min+' ';
                }
                race_time = sim_month+' '+sim_date+' @ '+race_time_tmp + timedirection+' EST';
                setGlobalTimeText("Completed");
            } else {
                race_time = "Ended "+days_ended+" Days Ago";
            }
            entryDisplay = 'none';
            resultsDisplay = 'block';
        } else if ((days_started !== undefined)&&(days_started !== 'null')&&(days_started !== '')&&(days_started !== null)) {
            race_time = "In "+days_ended+" Days";
        } else if (open_invite_race == 1) {
            var entry_close_time = new Date(date_entry_close);
            var prediction_close_time = new Date(date_predictions_close);
            var simulation_close_time = new Date(date_simulation_close);

            var sim_min = simulation_close_time.getMinutes();
            var sim_hour = simulation_close_time.getHours();
            var sim_month = simulation_close_time.toLocaleString('default', { month: 'short' });
            var sim_date = simulation_close_time.getDate();
            var timedirection = 'PM';
            if (sim_hour < 12) {
                timedirection = 'AM';
            } else if (sim_hour > 12) {
                sim_hour = sim_hour - 12;
            }
            if(sim_hour == 0) {
                sim_hour = 12;
            }
            let race_time_tmp = sim_hour;
            if(sim_min > 10) {
                race_time_tmp = race_time_tmp + ':'+sim_min+' ';
            } else if(sim_min > 0) {
                race_time_tmp = race_time_tmp + ':0'+sim_min+' ';
            }
            race_time_tmp = sim_month+' '+sim_date+' @ '+race_time_tmp + timedirection+' EST';
            race_time = <a className="exr-light-green-text">{race_time_tmp}</a>;

            var entry_min = entry_close_time.getMinutes();
            var entry_hour = entry_close_time.getHours();
            var entry_month =  entry_close_time.toLocaleString('default', { month: 'short' });
            var entry_date =  entry_close_time.getDate();
            var timedirection = 'PM';
            if (entry_hour < 12) {
                timedirection = 'AM';
            } else if (entry_hour > 12) {
                entry_hour = entry_hour - 12;
            }
            if (entry_hour == 0) {
                entry_hour = 12;
            }
            entry_close_time_text = entry_hour;
            if(entry_min > 10) {
                entry_close_time_text = entry_close_time_text + ':'+entry_min+' ';
            } else if(entry_min > 0) {
                entry_close_time_text = entry_close_time_text + ':0'+entry_min+' ';
            }
            entry_close_time_text = entry_month+' '+entry_date+' @ '+entry_close_time_text + timedirection+' EST';
            
            if(open_entry_closed == 0) {
                setRaceEntryCloseText(<span className="left" style={{marginRight:'40px'}}>Entry Closes: <a className="exr-light-green-text">{entry_close_time_text}</a></span>);
            } else {
                setRaceEntryCloseText(<></>);
            }


            var pred_min = prediction_close_time.getMinutes();
            var pred_hour = prediction_close_time.getHours();
            var timedirection = 'PM';
            if (pred_hour < 12) {
                timedirection = 'AM';
            } else if (pred_hour > 12) {
                pred_hour = pred_hour - 12;
            }
            if (pred_hour == 0) {
                pred_hour = 12;
            }
            pred_close_time_text = pred_hour;
            if(pred_min > 10) {
                pred_close_time_text = pred_close_time_text + ':'+pred_min+' ';
            } else if(pred_min > 0) {
                pred_close_time_text = pred_close_time_text + ':0'+pred_min+' ';
            }
            pred_close_time_text = pred_close_time_text + timedirection+' EST';
            // if((open_invite_race == 1)&&(race_simulated_global == 0)) {
            //     setRacePredictionCloseText(<span className="left" style={{marginRight:'40px'}}>Predictions Close: <a className="exr-light-green-text">{pred_close_time_text}</a></span>);
            // } else {
            //     setRacePredictionCloseText(<></>);
            // }
            setRacePredictionCloseText(<></>);
        }

        if(race_invite_list.length > 2) {
            var invited_names = race_invite_list.replace(/,/g,", ");
            var challengercount = race_invite_list.split(",").length;
            var challengerword = 'INVITING';
            racers_invited = <span><span className="exr-light-green-text">{challengerword}:</span><span>&nbsp;&nbsp;{invited_names}</span></span>;
        }

        if((racer_entered == 'N')&&(race_simulated == 'N')) {
            entryDisplay = 'block';
            var lineupDisplay = 'block';
        }

        var total_racers = 0;
        if(racerList.length > 0) {
            total_racers = total_racers+racerList.length;
        }
        if(invitedDetails.length > 0) {
            total_racers = total_racers+invitedDetails.length;
        }
        if(total_racers < 2) {
            total_racers = 2;
        }
        var race_type = <>Standard Race</>;
        var prize_type = 'RPM';
        var prize_dist = 'Tiered Winnings';
        var raceEntryEXR = raceDetails.race_entry_fee;
        var raceEntryPaid = raceDetails.race_paid_entry_fee;

        if (raceDetails.race_scoring == 'reverse') {
            race_type = <a className="exr-yellow-text">Inverse Race</a>;
        }
        if((race_unique_id == 'daily')||(race_unique_id == 'bot-race')||(race_unique_id == 'training')) {
            raceName = 'DAILY TRAINING RACE';
            race_type = 'Standard Race';
            race_time = 'Today';
            entryDisplay = 'block';
            lineupDisplay = 'none';
            resultsDisplay = 'none';
        } else if(race_unique_id == 'alpha') {
            raceName = 'DAILY TRAINING RACE';
            race_time = 'Today';
            entryDisplay = 'block';
            lineupDisplay = 'none';
            resultsDisplay = 'none';
        } else if(race_unique_id == 'invite') {
            raceName = 'RACE BATTLE';
            race_time = 'Today';
            entryDisplay = 'block';
            lineupDisplay = 'none';
            resultsDisplay = 'none';
            entryFeeDisplay = 'block';
            if(raceEntryEXR == 0) {
                raceEntryEXR = 25;
            }
            totalEXR = total_racers*raceEntryEXR;
        } 

        if(raceEntryEXR > 0) {
            entryFeeDisplay = 'block';
            if((raceName.indexOf('Battle') >= 0)&&(raceDetails.race_prize_scoring == 'standard')) {
                prize_dist = 'Winner Take All';
            }
        }
        var final_tooltip = '';
        if(raceDetails.race_prize_scoring != 'winner') {
            final_tooltip = prize_tier_tooltip;
            if(raceName.indexOf('Training') >= 0) {
                final_tooltip = training_tier_tooltip;
            }
        } else {
            prize_dist = 'Winner Take All';
            final_tooltip = prize_wta_tooltip;
        }

        // Calculate Legendary / Exiled Bonus If Available
        var legendary_bonus = 0;
        if((legendaryRacers > 0)&&(botRace == 0)) {
            legendary_bonus = 5;
        }
        
        var final_entry_text = '';
        var final_prize_text = '';
        if(raceEntryPaid > 0) {
            final_entry_text = final_entry_text+raceEntryPaid+' USDC';
            final_prize_text = final_prize_text+(total_racers*raceEntryPaid)+' USDC';
            if(raceEntryEXR > 0) {
                final_entry_text = final_entry_text+' + '+raceEntryEXR+' RPM';
                final_prize_text = final_prize_text+' + '+((total_racers*raceEntryEXR)+legendary_bonus)+' RPM';
            }
        } else if(raceEntryEXR > 0) {
            final_entry_text = final_entry_text+raceEntryEXR+' RPM';
            final_prize_text = final_prize_text+((total_racers*raceEntryEXR)+legendary_bonus)+' RPM';
        } else {
            final_entry_text = "Free";
            final_prize_text = '50 RPM';
        }

        if(legendary_bonus > 0) {
            final_prize_text = <a className="exr-yellow-text">{final_prize_text+"*"}</a>;
            final_tooltip = final_tooltip.replace("display:none;display:none;","");
        }

        var bigEntryButtonDisplay = 'none';
        if((racer_invited == 'Y')&&(racer_entered == 'N')) {
            bigEntryButtonDisplay = 'block';
        }

        setRaceName(raceName);
        setRaceType(race_type);
        setRaceEXR(final_prize_text);
        setRaceXP(totalXP);
        setRaceEntryText(final_entry_text);
        setRacePrizeType(prize_type);
        setRacePrizeDist(prize_dist);
        setRacerCount(total_racers);
        setRaceTime(race_time);
        setRaceEntryLaps(totalLaps);
        setFinalTierTooltop(final_tooltip);

        if(race_creator_types.indexOf(race_hash_global) >= 0) {
            $(".race-sub-header").each(function(){
                $(this).hide();
            });
        } else {
            $(".race-sub-header").each(function(){
                $(this).show();
            });
        }

        if(headerSet == false) {
            setHeader(true);
            headerCheck();
        }
    }

    

    function loadRaceSections(raceDetails,racerList,invitedDetails) {
        refreshRaceGlobalHash();

        var botrace = 0;
        if((raceDetails.race_name == 'Alpha Races')||(raceDetails.race_name == 'Bot Race')||(raceDetails.race_name == 'Training Race')) {
            botrace = 1;
        }

        var open_editable = 1;
        if((raceDetails.race_simulated == 0)&&
            (current_racer_global == 1)&&
            (racerList.length > 1)&&
            (racerList.length == raceDetails.race_total_racers)&&
            (raceDetails.open_invite_race == 0)) {
                open_editable = 0;
        }

        /* Check Open Invite Race and Entry Window Over */
        if((open_invite_race == 1)&&(open_entry_closed == 0)) {
            if((current_racer_global == 0)&&(racer_token_gated == 0)) {
                var tmp_main = <>
                    <TrackSelectionSection navToggle={toggleRaceMenu} 
                                raceDetails={raceDetails} 
                                hashRaceUniqueID={race_hash_global} 
                                inviteList={invitedDetails}
                                racerStatus={current_racer_global} 
                                raceTrackID={race_track_id} />
                    <StackSelectionSection raceDetails={raceDetails} stakeToRace={checkEnterRace} hashRaceUniqueID={race_hash_global} editStack={0} />
                    <LineupViewSection 
                        raceDetails={raceDetails} 
                        racerList={racerList} 
                        inviteList={invitedDetails} 
                        racerStatus={current_racer_global}
                        refreshPageHeader={refreshPageHeader}
                        startInviteButton={startInviteChips}
                        hashRaceUniqueID={race_hash_global} />
                    </>;
            } else {
                if(window.location.hash.indexOf("edit_stack") >= 0) {
                    var tmp_main = <>
                            
                            <StackSelectionSection raceDetails={raceDetails} stakeToRace={checkEnterRace} hashRaceUniqueID={race_hash_global} editStack={1} />
                            <TrackSelectionSection navToggle={toggleRaceMenu} 
                                        raceDetails={raceDetails} 
                                        hashRaceUniqueID={race_hash_global} 
                                        inviteList={invitedDetails}
                                        racerStatus={current_racer_global} 
                                        raceTrackID={race_track_id} />
                            <LineupViewSection 
                                raceDetails={raceDetails} 
                                racerList={racerList} 
                                inviteList={invitedDetails} 
                                racerStatus={current_racer_global}
                                refreshPageHeader={refreshPageHeader}
                                startInviteButton={startInviteChips}
                                hashRaceUniqueID={race_hash_global} />
                            </>;
                } else if(racerList.length == 0) {
                    var tmp_main = <>
                    <TrackSelectionSection navToggle={toggleRaceMenu} 
                                raceDetails={raceDetails} 
                                hashRaceUniqueID={race_hash_global} 
                                inviteList={invitedDetails}
                                racerStatus={current_racer_global} 
                                raceTrackID={race_track_id} />
                    <LineupViewSection 
                        raceDetails={raceDetails} 
                        racerList={racerList} 
                        inviteList={invitedDetails} 
                        racerStatus={current_racer_global}
                        refreshPageHeader={refreshPageHeader}
                        startInviteButton={startInviteChips}
                        hashRaceUniqueID={race_hash_global} />
                    
                    </>;
                } else {
                    var tmp_main = <>
                        <LineupViewSection 
                            raceDetails={raceDetails} 
                            racerList={racerList} 
                            inviteList={invitedDetails} 
                            racerStatus={current_racer_global}
                            refreshPageHeader={refreshPageHeader}
                            startInviteButton={startInviteChips}
                            hashRaceUniqueID={race_hash_global} />
                        <TrackSelectionSection navToggle={toggleRaceMenu} 
                                    raceDetails={raceDetails} 
                                    hashRaceUniqueID={race_hash_global} 
                                    inviteList={invitedDetails}
                                    racerStatus={current_racer_global} 
                                    raceTrackID={race_track_id} />
                    </>;
                }
                
            }

        } else if(race_creator_types.indexOf(race_hash_global) >= 0) {
            var tmp_main = <><SettingsSelectionSection
                                raceDetails={raceDetails} 
                                hashRaceUniqueID={race_unique_id} />
                            <TrackSelectionSection navToggle={toggleRaceMenu} 
                            raceDetails={raceDetails} 
                            hashRaceUniqueID={race_unique_id} 
                            inviteList={invitedDetails} 
                            racerStatus={0} 
                            raceTrackID={race_track_id}
                            selectTrack={updateRaceTrackChosenDetails} />
                    <StackSelectionSection raceDetails={raceDetails} stakeToRace={checkEnterRace} hashRaceUniqueID={race_hash_global} editStack={0} />
                    </>;
        } else {
            var tmp_main = <>
                    {(() => {
                        if((race_simulated_global == 1)&&(raceDetails.admin_gated == 0)&&
                            ((racer_sim_watched_global == 1)||(predictor_sim_watched == 1)||((current_predictor_global == 0)&&(current_racer_global == 0)))){
                            return (<ResultsViewSection raceDetails={raceDetails} racerList={racerList} />);
                            // return (<LineupViewSection 
                            //     raceDetails={raceDetails} 
                            //     racerList={racerList} 
                            //     inviteList={invitedDetails} 
                            //     racerStatus={current_racer_global}
                            //     refreshPageHeader={refreshPageHeader}
                            //     startInviteButton={startInviteChips}
                            //     entryCloseDate={race_entry_close_text}
                            //     hashRaceUniqueID={race_hash_global} />);

                        } else if (window.location.hash.indexOf("edit_stack") < 0) {
                            return (<LineupViewSection 
                                        raceDetails={raceDetails} 
                                        racerList={racerList} 
                                        inviteList={invitedDetails} 
                                        racerStatus={current_racer_global}
                                        refreshPageHeader={refreshPageHeader}
                                        startInviteButton={startInviteChips}
                                        entryCloseDate={race_entry_close_text}
                                        hashRaceUniqueID={race_hash_global} />);
                        } else {
                            return null;
                        }
                    })()}
                    {(() => {
                        if((race_simulated_global == 0)&&(racer_invite_status_global == 1)&&(current_racer_global == 0)) {
                            return (<StackSelectionSection raceDetails={raceDetails} stakeToRace={checkEnterRace} hashRaceUniqueID={race_hash_global} editStack={0} />);
                        } else {
                            if((current_racer_global == 1)&&(window.location.hash.indexOf("edit_stack") >= 0)&&(open_editable == 1)&&(botrace == 0)) {
                                return (<StackSelectionSection raceDetails={raceDetails} stakeToRace={checkEnterRace} hashRaceUniqueID={race_hash_global} editStack={1} />);
                            } else {
                                return null;
                            }
                            
                        }
                    })()}
                    <TrackSelectionSection navToggle={toggleRaceMenu} 
                            raceDetails={raceDetails} 
                            hashRaceUniqueID={race_hash_global} 
                            inviteList={invitedDetails}
                            racerStatus={current_racer_global} 
                            raceTrackID={race_track_id} />
                    </>;
        }
        
        setRaceLandingHTML(tmp_main);
        var tooltips = document.querySelectorAll('.prize-tips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
        setTimeout(function(){ adjustLeftRailHeight(); },500);
    }

    function loadLeftNavigation(raceDetails) {
        let league_season_id = raceDetails['league_season_id'];
        let league_name = raceDetails['league_name'];
        let league_permalink = raceDetails['league_permalink'];
        let league_groups = raceDetails['league_groups'];
        let league_banner = raceDetails['league_header_photo'];

        league_hash_global = league_permalink;

        let tmpnav = <>
            <div className="headline-text section-title-text center-align" style={{fontSize:'16px',marginTop:"20px",marginBottom:"10px"}}>
                            &nbsp;THE RACETRACK
                            <input type="hidden" id="race-id-header" />
                            <input type="hidden" id="race-unique-id-header" />
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="upcomingtoggle"
                                    onClick={()=> toggleRaceMenu('upcoming')}> 
                                <span className="left side-nav-button-text">Upcoming Races</span>
                                <span id="racetrack-new-races-text" className="top-nav-header-item-badge exr-red-bg white-text" style={{marginTop:'10px',display:'none'}}>
                                    14
                                </span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="leaguestoggle"
                                    onClick={()=> toggleRaceMenu('leagues')}> 
                                <span className="left side-nav-button-text">Leagues</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="leaderboardstoggle"
                                    onClick={()=> toggleRaceMenu('leaderboards')}> 
                                <span className="left side-nav-button-text">Leaderboards</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        
                        <div className="side-nav-button-section" style={{display:'none'}}>
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="clubstoggle"
                                    onClick={()=> toggleRaceMenu('clubs')}> 
                                <span className="left side-nav-button-text">Racing Clubs</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="pasttoggle"
                                    onClick={()=> toggleRaceMenu('past')}> 
                                <span className="left side-nav-button-text">Past Races</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                    </>;
        if(league_season_id > 0) {
            tmpnav = <>
                <div className="headline-text section-title-text center-align" style={{fontSize:'16px',marginTop:"20px",marginBottom:"10px"}}>
                        &nbsp;{league_name.toUpperCase()}
                        <input type="hidden" id="race-id-header" />
                        <input type="hidden" id="race-unique-id-header" />
                    </div>
                    <div className="side-nav-button-section">
                        <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="hometoggle"
                                onClick={()=> toggleLeagueMenu('home')}> 
                            <span className="left side-nav-button-text">League Home</span>
                            <span className="right side-nav-button-bubble">
                                <div className="bubble-button"></div>
                            </span>
                        </button>
                    </div>
                    <div className="side-nav-button-section">
                        <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link side-nav-button-active" id="scheduletoggle"
                                onClick={()=> toggleLeagueMenu('schedule')}> 
                            <span className="left side-nav-button-text">Race Schedule</span>
                            <span className="right side-nav-button-bubble">
                                <div className="bubble-button"></div>
                            </span>
                        </button>
                    </div>
                    <div className="side-nav-button-section">
                        <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="leaderboardstoggle"
                                onClick={()=> toggleLeagueMenu('leaderboards')}> 
                            <span className="left side-nav-button-text">Leaderboards</span>
                            <span className="right side-nav-button-bubble">
                                <div className="bubble-button"></div>
                            </span>
                        </button>
                    </div>
            </>;

            if(league_banner.length > 0) {
                let tmpbanner = <>
                <div className="col s12" style={{position:'relative',marginTop:'7px',padding:'0px',borderRadius:'10px',lineHeight:'1'}}>
                    <img src={league_banner} width="100%" style={{borderRadius:'10px'}} />
                </div>
                </>;

                setPageBannerHTML(tmpbanner);
            }
            
        }

        setLeftRailHTML(tmpnav);
    }

    function toggleRaceMenu(pagename) {
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");
        window.location.href= '/#/races_home/'+pagename;
        dispatch(updateLocation("races_home"));

        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
        },5);
    }

    function startRaceMenu(pagename) {
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");
        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
        },5);
    }

    function toggleLeagueMenu(pagename) {
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");
        window.location.href= '/#/league/'+league_hash_global+'/'+pagename;

        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
        },5);

    }

    function updateRaceTrackChosenDetails(trackid) {
        race_track_id = trackid;
    }

    function sendInviteChipsFromBox() {
        var invitetypeahead = document.getElementById('racer_chips_invite');
        var chipselem = window.M.Chips.getInstance(invitetypeahead);
        var chipsarr = chipselem.chipsData;
        var invitenames = '';
        for(var i = 0; i < chipsarr.length;i++) {
            if(i > 0) {
                invitenames = invitenames+',';
            }
            invitenames = invitenames+chipsarr[i]['tag'];
        }
        var tmp_race_id = window.$("#race-id-header").val();
        document.getElementById("invitechecksubmit").style.display = 'none';
        document.getElementById("invitecheckloading").style.display = 'block';
        inviteRacers(invitenames,tmp_race_id,race_unique_id);
    }

    function startInviteChips() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);

        axiosInstance.post('member/handles/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var typeahead_data = {}
                for(var i = 0; i < result.data.length;i++) {
                    typeahead_data[result.data[i].racer_name] = null;
                }
                var invitetypeahead = document.getElementById('racer_chips_invite');
                var chips_instance = window.M.Chips.init(invitetypeahead, {
                    autocompleteOptions: {
                        data: typeahead_data,
                        minLength: 1,
                        limit: 10
                      },
                    placeholder: '+ Racer Handle',
                    secondaryPlaceholder: '+ Racer Handle',
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }
    
    function adjustLeftRailHeight() {
        var rightsideheight = $("#righsidecontent").height()-10;
        $("#leftrailnav").css({height:rightsideheight+'px'});
    }

    function showRaceHowToInfobox() {
        var infobox = document.getElementById('training_box_race_howto');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    function showRaceTypeInfobox(e) {
        e.preventDefault();
        var infobox = document.getElementById('global_infobox_race_type');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    function showFirstTrainingInfobox(boxid) {
        var infobox = document.getElementById('training_box_race_howto');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.infoBox = encodeURIComponent(boxid);
        axiosInstance.post('member/infobox_view/',addData).then(result => {
            var infobox_viewed = result.data;
            if((boxid == 'race_howto')||(boxid == 'race_landing')) {
                training_box_race_landing = 1;
                $("#current-racer-ibox-race-landing").val(training_box_race_landing);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    document.addEventListener('DOMContentLoaded', function() {
        adjustLeftRailHeight();
        setTimeout(function(){ startInviteChips();},1000);
    });

    function headerCheck() {
        var url_race_unique_id_check = '';
        var racehashb = window.location.href.split("#/");
        if(racehashb.length > 1) {
            var racehash2b = racehashb[1].replace("#/",'');
            var racehash3b = racehash2b.split("/");
            if(racehash3b.length > 1) {
                url_race_unique_id_check = racehash3b[1].replace("/",'');
            }
        }
        if((race_creator_types.indexOf(url_race_unique_id_check) == 0)&&((race_name == 'DAILY TRAINING RACE')||(race_name == 'RACE BATTLE'))) {
            loadRaceGlobalInfo(race_details,racer_details,race_invite_details);
        }
        setTimeout(function(){
            headerCheck();
        },1000);
    }

    window.addEventListener("resize",
    function(){ 
        // clearInterval();
        adjustLeftRailHeight();
    }, false);

    if(pageSet == false) {
        setPage(true);
        window.startPageModals();
        window.resetBodyWidth();
        grabRaceDetails();
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            <div id="race-landing-shell" className="row page-width-wide page-clean-body">
                {pageBannerHTML}
                <div id="leftrailnav" className="col s12 l2 border-section-full exr-new-dark-grey hide-on-med-and-down" style={{height:'100vh',position:'relative',padding:"5px"}}>
                    <div style={{width:'100%'}}>
                        {leftRailHTML}
                    </div>
                </div>
                <div id="righsidecontent" className="col s12 l10" style={{position:'relative', minHeight:'100vh', margin:"0px",padding:"0px"}}>
                <div className="row border-section-full exr-new-dark-grey white-text" style={{minHeight:'100vh',position:'relative',width:"99.5%",marginLeft:"0.5%",padding:"25px"}}>
                    <div className="row exr-new-light-grey " style={{padding:'15px',borderRadius:'10px',marginTop:'-10px'}}>
                        <div className="col s12 headline-text" style={{fontSize:"24px",lineHeight:'1',marginTop:'10px'}}>
                            
                            <span className="left">{race_name}</span>
                            <span className="right material-icons material-symbols-outlined white-red-link" 
                            style={{fontSize:"30px",marginLeft:"10px",marginTop:'-7px'}} 
                            onClick={()=>showRaceHowToInfobox()} 
                            title="Learn How To Race">info</span>
                        </div>
                        <div className="col s12" style={{marginTop:'8px'}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 show-on-large-and-up hide-on-med-and-down" style={{marginTop:"10px",fontSize:'16px'}}>
                            <div className="col s12 l7" id="racertimetxt">
                                <div className="left" style={{marginRight:'40px'}}>
                                    {global_time_text}: {raceTime}
                                </div>
                                {race_entry_close_text}
                                {race_predictions_close_text}
                                <div className="left race-sub-header" style={{marginRight:'40px',cursor:'pointer'}} title="Learn More" onClick={(e)=>showRaceTypeInfobox(e)}>
                                    Type: {race_game_type}
                                </div>
                                {/* <div className="left race-sub-header" style={{display:'none'}}>
                                    Entry: {race_entry_text}
                                </div> */}
                                <div className="left race-sub-header">
                                    Distance: {race_entry_laps} Laps
                                </div>
                            </div>
                            <div className="col s12 l5" id="racerrewardstxt">
                                <div className="right">
                                    All Racers: {race_xp} XP
                                </div>
                                <div className="right prize-tips race-sub-header" style={{marginRight:'40px'}} data-position="bottom" data-tooltip={final_tier_tooltip}>
                                    {race_prize_dist}: {race_exr}
                                </div>
                            </div>
                        </div>
                        <div className="col s12 exr-new-light-grey hide-on-large-only show-on-med-and-down" style={{marginTop:"10px",fontSize:'14px',lineHeight:'2'}}>
                            <div className="col s7 left-align" title="Learn More" onClick={(e)=>showRaceTypeInfobox(e)}>
                                {/* Time: {raceTime}<br/>Type: {race_game_type} // Entry: {race_entry_text} */}

                                Time: {raceTime}<br/>Type: {race_game_type} // {race_entry_laps} Laps
                            </div>
                            <div className="col s5 right-align race-sub-header" id="racerrewardstxt2">
                                {race_prize_dist}: {race_exr} RPM<br/>All Racers: {race_xp} XP
                            </div>
                        </div>
                    </div>
                    {race_landing_html}
                </div>
            </div>
        </div>
        <div id="invitebox" className="modal page-modal page-modal-medium">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>INVITE TO RACE</div>
                        
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'18px'}}>
                        Invite an EXR Racer to this race! EXR Invite Races 
                        require an entry fee from each racer with the winner of the race taking the whole pot.
                        <br/><br/>
                        Invite Race Entry:&nbsp;&nbsp;<b className="exr-light-green-text">25 RPM </b>
                        <br/><br/>Type the name of the racer below and click Send Invite to add this race to their open Race Battles.
                    </div>
                    <div className="input-field col s12" style={{marginTop:'30px',fontSize:'16px'}}>
                        <div className="chips chips-autocomplete chips-placeholder" id="racer_chips_invite"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'30px'}}>
                        <div id="invitechecksubmit" className="center-align modal-trigger right" title="SEND INVITE">
                            <span className="round-border-button" style={{fontSize:'20px'}} title="SEND INVITE" onClick={()=>sendInviteChipsFromBox()}>
                                SEND INVITE
                            </span>
                        </div>
                        <div id="invitecheckloading" className="preloader-wrapper small active right splash-modal-loader">
                            <div className="spinner-layer spinner-red-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="confirmentrybox" className="modal page-modal page-modal-medium">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                            {(window.location.hash.indexOf("edit_stack") >= 0)?'UPDATE RACE STACK':'ENTER THIS RACE'}
                            </div>
                        
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'18px'}}>
                            
                        Enter your racing stack in this race. This race is setup as a  <a className="exr-light-green-text">{race_game_type}</a> with a distance of  <a className="exr-light-green-text">{race_entry_laps} Laps</a>.
                        Standard Races have the FASTEST racers to complete the circuit winning while Inverse racing has the SLOWEST racers winning.
                        Are you sure you still want to enter?
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'30px'}}>
                        <div id="modalentrychecksubmit" className="center-align modal-trigger right" title="ENTER RACE">
                            <span className="round-border-button" style={{fontSize:'20px'}} title="ENTER RACE" onClick={()=>checkEnterRace()}>
                                {(window.location.hash.indexOf("edit_stack") >= 0)?'UPDATE STACK':'ENTER RACE'}
                            </span>
                        </div>
                        <div id="modalentrycheckloading" className="preloader-wrapper small active right splash-modal-loader">
                            <div className="spinner-layer spinner-red-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <StackInfoBox />
        <RaceHowToBox open_race={race_details.open_invite_race} />
    </div>);
};
