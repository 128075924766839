import {CameraController} from "../cameraController.js";
import * as THREE from "three";

export class CameraControllerLineup extends CameraController {
    static #RAISE = 2;
    static #DELTA = new THREE.Vector3(
        3.5,
        1 + CameraControllerLineup.#RAISE,
        0);
    static #SPEED = .1;

    racers;
    index = 0;
    target = new THREE.Vector3();

    /**
     * Construct the lineup camera
     * @param {Camera} camera The camera to control
     * @param {Racers} racers The racers
     */
    constructor(camera, racers) {
        super(camera);

        this.racers = racers;
        this.target.copy(racers.racers[0].position);

        window.addEventListener("keydown", event => {
            switch (event.key) {
                case "z":
                    this.previous();

                    break;
                case "x":
                    this.next();

                    break;
            }
        })
    }

    /**
     * Select the previous racer
     */
    previous() {
        this.index = (((this.index - 1) % this.racers.racers.length) + this.racers.racers.length) % this.racers.racers.length;
    }

    /**
     * Select the next racer
     */
    next() {
        this.index = (this.index + 1) % this.racers.racers.length;
    }

    /**
     * Set the target for this camera controller
     * @param {CameraTargetable} target The target to track
     * @param {CameraTargetable[]} targets All targets that can be tracked
     * @param {Track} track The track
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {boolean} [instant] True if the change should be instant
     */
    setTarget(target, targets, track, time, instant = false) {
        this.target.lerp(this.racers.racers[this.index].position, CameraControllerLineup.#SPEED);

        this.camera.position.copy(this.target).add(CameraControllerLineup.#DELTA);
        this.camera.lookAt(this.target.x, this.target.y + CameraControllerLineup.#RAISE, this.target.z);
    }
}