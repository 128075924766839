import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./global_header_learn.css"

export const GlobalHeaderLearn = () => {

  const { racerID, accountAddress, userID, spectatorID, loginToken, loginTime } = useSelector((state) => state.account);
  var registerbutton = <a href="/#/">Register</a>
  if(userID > 0) {
    registerbutton = <a href="/#/raceway">Raceway</a>
  }
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href="/#">
          <img className="navbar-logo" src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" alt="Logo" />
        </a>
        <ul>
          <li className="navbar-item">
            <a href="/#/gameitems">Items</a>
          </li>
          <li className="navbar-item">
            <a href="/#/gameplay">Gameplay</a>
          </li>
          <li className="navbar-item">
            <a href="/#/factions">Factions</a>
          </li>
          <li className="navbar-item">
            {registerbutton}
          </li>
        </ul>
      </div>
    </nav>
  );
}




