import * as THREE from "three";
import {CameraAnchorFixed} from "../anchors/cameraAnchorFixed.js";
import {CameraAnchorRails} from "../anchors/cameraAnchorRails.js";
import {CameraControllerSegment} from "./cameraControllerSegment.js";
import {TrackPieceStraight} from "../../../../track/pieces/trackPieceStraight.js";
import {RandomRange} from "../../../../utils/randomRange.js";

/**
 * A cinematic camera anchor generate
 */
export class CameraControllerGenerator {
    static SEGMENT_HELICOPTER_MIN = 3;
    static SEGMENT_HELICOPTER_MAX = 9;
    static SEGMENT_RAILS_MIN = 3;
    static SEGMENT_FIXED_MIN = 3;
    static TURN_RATE_RADIUS = 3;
    static TURN_RATE_THRESHOLD = 1;
    static HELICOPTER_HEIGHT = new THREE.Vector2(35, 45);
    static HELICOPTER_RADIUS = new THREE.Vector2(12, 15);
    static HELICOPTER_ANGLE_OFFSET = new THREE.Vector2(-.5, .5);
    static FIXED_HEIGHT = new THREE.Vector2(30, 45);
    static FIXED_RADIUS = new THREE.Vector2(15, 25);

    /**
     * Instantiate a cinematic generator
     * @param {Track} track The track to generate anchors for
     * @param {Vector3} focus An environmental focus point, like the planet surface
     * @param {Random} random A randomizer
     */
    constructor(track, focus, random) {
        this.track = track;
        this.focus = focus;
        this.preferredAngle = Math.atan2(-focus.z, -focus.x);
        this.random = random;
        this.pieces = track.pieces;
        this.pieceCount = this.pieces.length;
        this.turnRating = this.calculateTurnRating();
        this.segments = [];
    }

    /**
     * Get the index of the piece with the highest turn rate
     * @returns {number} The index of the piece with the highest turn rate
     */
    get maxTurnRating() {
        let maxPiece = 0;
        let maxTurnRating = 0;

        for (let piece = 0; piece < this.pieceCount; ++piece) if (this.turnRating[piece] > maxTurnRating) {
            maxTurnRating = this.turnRating[piece];
            maxPiece = piece;
        }

        return maxPiece;
    }

    /**
     * Get the index of the piece with the lowest turn rate
     * @returns {number} The index of the piece with the lowest turn rate
     */
    get minTurnRating() {
        let minPiece = 0;
        let minTurnRating = Number.MAX_VALUE;

        for (let piece = 0; piece < this.pieceCount; ++piece) if (this.turnRating[piece] < minTurnRating) {
            minTurnRating = this.turnRating[piece];
            minPiece = piece;
        }

        return minPiece;
    }

    /**
     * Find the track piece index for a given index
     * @param {number} index The index, which may underflow or overflow
     * @returns {number} The index in the piece array
     */
    pieceIndex(index) {
        if (index < 0) {
            while (index < 0)
                index += this.pieceCount;

            return index;
        }

        return index % this.pieceCount;
    }

    /**
     * Calculate the turn rating for each piece
     * @returns {number[]} The turn rating for each track piece
     */
    calculateTurnRating() {
        const turnRating = new Array(this.pieceCount).fill(0);

        for (let i = 0; i < this.track.pieceCount; ++i) {
            const rotation = this.pieces[(i + CameraControllerGenerator.TURN_RATE_RADIUS) % this.pieceCount].rotation;

            for (let j = 0; j < CameraControllerGenerator.TURN_RATE_RADIUS * 2 + 1; ++j)
                turnRating[(i + j) % this.pieceCount] += rotation / (j + CameraControllerGenerator.TURN_RATE_RADIUS);
        }

        return turnRating;
    }

    /**
     * Create the helicopter segment, which focuses on the track segment with the highest degree of rotation
     * @returns {CameraControllerSegment} The helicopter segment
     */
    createSegmentHelicopter() {
        const angle = this.preferredAngle + RandomRange.linear(CameraControllerGenerator.HELICOPTER_ANGLE_OFFSET, this.random.float);
        const radius = RandomRange.linear(CameraControllerGenerator.HELICOPTER_RADIUS, this.random.float);
        const segment = new CameraControllerSegment(
            this.maxTurnRating,
            1,
            new CameraAnchorRails(
                this.track,
                new THREE.Vector3(
                    Math.cos(angle) * radius,
                    RandomRange.linear(CameraControllerGenerator.HELICOPTER_HEIGHT, this.random.float),
                    Math.sin(angle) * radius),
                false));

        while (true) {
            const turnRatingLeft = this.turnRating[this.pieceIndex(segment.start - 1)];
            const turnRatingRight = this.turnRating[this.pieceIndex(segment.end + 1)];

            if (turnRatingLeft < CameraControllerGenerator.TURN_RATE_THRESHOLD &&
                turnRatingRight < CameraControllerGenerator.TURN_RATE_THRESHOLD &&
                segment.length >= CameraControllerGenerator.SEGMENT_HELICOPTER_MIN)
                break;

            if (turnRatingLeft === turnRatingRight) {
                if (this.random.bool)
                    segment.extendStart(this.pieceCount);
                else
                    segment.extendEnd();
            }
            else if (turnRatingLeft > turnRatingRight)
                segment.extendStart(this.pieceCount);
            else
                segment.extendEnd();

            if (segment.length === CameraControllerGenerator.SEGMENT_HELICOPTER_MAX ||
                segment.length === this.pieceCount)
                break;
        }

        return segment;
    }

    /**
     * Create a rails segment, if possible
     * @returns {CameraControllerSegment | null} The rails segment, or null if none could be created
     */
    createSegmentRails() {
        const segment = new CameraControllerSegment(
            this.minTurnRating,
            1,
            new CameraAnchorRails(
                this.track,
                new THREE.Vector3(5, 5, 0)));

        while (segment.length < this.pieceCount - 1) {
            const straightLeft = this.pieces[this.pieceIndex(segment.start - 1)] instanceof TrackPieceStraight;
            const straightRight = this.pieces[this.pieceIndex(segment.end + 1)] instanceof TrackPieceStraight;

            if (straightLeft === false && straightRight === false)
                break;

            if (straightLeft)
                segment.extendStart(this.pieceCount);

            if (straightRight)
                segment.extendEnd();
        }

        if (segment.length > CameraControllerGenerator.SEGMENT_RAILS_MIN)
            return segment;

        return null;
    }

    /**
     * Check whether a segment overlaps an existing segment
     * @param {CameraControllerSegment} segment The segment to check
     * @returns {boolean} True if the segment overlaps an existing segment
     */
    overlapsExistingSegment(segment) {
        for (const other of this.segments)
            for (let i = 0; i < other.length; ++i)
                for (let j = 0; j < segment.length; ++j)
                    if (this.pieceIndex(segment.start + j) === this.pieceIndex(other.start + i))
                        return true;

        return false;
    }

    /**
     * Try to add a segment, if the segment produces no overlap
     * @param {CameraControllerSegment} segment The segment to add
     * @returns {boolean} True if the segment was added
     */
    tryAddSegment(segment) {
        if (!this.overlapsExistingSegment(segment))
            this.segments.push(segment);
    }

    /**
     * Add padding segments to fill gaps in the segment layout
     * @param {CameraAnchor[]} anchors The anchor list to pad
     */
    padSegments(anchors) {
        let offset = 0;

        while (!(anchors[this.pieceIndex(offset - 1)] && !anchors[offset]))
            ++offset;

        for (let start = 0; start < this.pieceCount; ++start) {
            const first = start + offset;

            if (anchors[first] === null) {
                let length = 1;

                while (length < this.pieceCount && anchors[this.pieceIndex(first + length)] === null)
                    ++length;

                if (length < CameraControllerGenerator.SEGMENT_FIXED_MIN) {
                    for (let offset = 0; (offset << 1) < length; ++offset) {
                        anchors[this.pieceIndex(first + offset)] = anchors[this.pieceIndex(first - 1)];
                        anchors[this.pieceIndex(first + length - 1 - offset)] = anchors[this.pieceIndex(first + length)];
                    }
                }
                else {
                    const position = new THREE.Vector3();
                    const angle = Math.PI * 2 * this.random.float;
                    const radius = RandomRange.linear(CameraControllerGenerator.FIXED_RADIUS, this.random.float);
                    const height = RandomRange.linear(CameraControllerGenerator.FIXED_HEIGHT, this.random.float);

                    for (let i = 0; i < length; ++i)
                        position.add(this.pieces[this.pieceIndex(first + i)].center);

                    position.divideScalar(length);
                    position.add(new THREE.Vector3(
                        Math.cos(angle) * radius,
                        height,
                        Math.sin(angle) * radius));

                    const anchor = new CameraAnchorFixed(position);

                    for (let i = 0; i < length; ++i)
                        anchors[this.pieceIndex(first + i)] = anchor;
                }

                start += length;
            }
        }
    }

    /**
     * Apply all segments
     * @returns {CameraAnchor[]} An array containing a camera anchor for every piece index for every lap
     */
    applySegments() {
        const anchors = new Array(this.pieceCount).fill(null);

        for (const segment of this.segments) for (let i = 0; i < segment.length; ++i)
            anchors[this.pieceIndex(segment.start + i)] = segment.anchor;

        this.padSegments(anchors);

        return Array(this.track.laps).fill().flatMap(() => anchors);
    }

    /**
     * Generate anchors
     * @returns {CameraAnchor[]} Camera anchors
     */
    generate() {
        // TODO: Exhaustive loop
        this.tryAddSegment(this.createSegmentHelicopter());

        const rails = this.createSegmentRails();

        if (rails)
            this.tryAddSegment(rails);

        return this.applySegments();

        // TODO: Add POI cameras
    }
}