import {RacerTurbulenceComponent} from "./component/racerTurbulenceComponent.js";

/**
 * Turbulence motion for a racer
 */
export class RacerTurbulence extends RacerTurbulenceComponent {
    /**
     * Construct turbulence motion
     * @param {RacerTurbulenceComponent[]} components The turbulence components
     */
    constructor(components) {
        super();

        this.components = components;
    }

    /**
     * Apply turbulence
     * @param {Vector3} vector A vector to apply turbulence to
     * @param {Quaternion} rotation A rotation to apply rotation to
     * @param {number} moved The amount the ship has moved
     */
    apply(vector, rotation, moved) {
        for (let component = 0, componentCount = this.components.length; component < componentCount; ++component)
            this.components[component].apply(vector, rotation, moved);
    }
}