import {CameraAnchor} from "./cameraAnchor.js";
import {TrackTracer} from "../../../../track/trackTracer.js";

/**
 * A camera anchor on a rails next to the track
 */
export class CameraAnchorRails extends CameraAnchor {
    /**
     * Construct a rails anchor
     * @param {Track} track The track to follow
     * @param {Vector3} offset The camera offset
     * @param {boolean} [rotate] True if the offset should be rotated
     */
    constructor(track, offset, rotate = true) {
        super(.78, .5);

        this.track = track;
        this.offset = offset;
        this.rotate = rotate;
        this.tracer = new TrackTracer(track);
    }

    /**
     * Get the position of this anchor given a target position
     * @param {CameraTargetable} target The target position
     * @param {CameraTargetable[]} quaternion The target quaternion
     * @param {number} lap The lap length
     * @param {number} time The time interpolation in the range [0, 1]
     */
    update(target, quaternion, lap , time) {
        if (this.rotate)
            this.tracer.trace(
                this.position,
                this.offset,
                target.getDistance(time) % lap);
        else
            this.position.copy(target.getPosition(time)).add(this.offset);
    }
}