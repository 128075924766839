import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { Nft } from "../../Organisms";
import { Button } from "../../Atoms/Button";
import {
  PilotSection,
  RacerSection,
  BoosterSection,
  SplashHeader,
  WalletHeader,
} from "../../Organisms";
import { Token, MintPack } from "../../Pages";
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

export const MintShow = ({ address, index }) => {
  const { active } = useWeb3React();

  const [tokenQty, setTokenQty] = useState(0);
  const [pagehtml, setPageHTML] = useState(baseMintPackHtml);
  const [newminthtml, setMintHTML] = useState(baseMintButton);
  const [tokenUri, setTokenUri] = useState("");
  const [id, setId] = useState(null);
  const [isAlreadyOpen, setIsAlreadyOpen] = useState(false);
  const { data, error } = useSWR(tokenUri, fetcher);
  const { mintPackContract } = useSelector((state) => state.web3);
  const { account } = useWeb3React();

  let mintpackInterval;
  let hasMintPack = false;

  useEffect(() => {
    if (!mintPackContract) return;

    mintpackInterval = setInterval(checkMintPacks, 5000);
    checkMintPacks();

    return () => clearInterval(mintpackInterval);
  }, [mintPackContract]);

  function checkMintPacks() {
    console.log({ hasMintPack });
    if (hasMintPack) {
      clearInterval(mintpackInterval);
      console.log("clearing Interval");
      return;
    }
    console.log("checking mint pack");
    mintPackContract
      .balanceOf(account)
      .then((qty) => {
        console.log({ qty: qty.toNumber() });
        setTokenQty(qty.toNumber());
        hasMintPack = true;
      })
      .catch((err) => {
        console.log("err");
      });
  }

  var baseMintPackHtml = (
    <div className="row">
      <div
        className="col s12 m6"
        style={{ height: "300px", marginBottom: "20px" }}
      >
        <div
          className="exr-dark-grey-bg"
          style={{ height: "100%", width: "100%" }}
        ></div>
      </div>
      <div
        className="col s12 m6"
        style={{ height: "300px", marginBottom: "20px" }}
      >
        <div
          className="exr-dark-grey-bg"
          style={{ height: "100%", width: "100%" }}
        ></div>
      </div>
      <div
        className="col s12 m6"
        style={{ height: "300px", marginBottom: "20px" }}
      >
        <div
          className="exr-dark-grey-bg"
          style={{ height: "100%", width: "100%" }}
        ></div>
      </div>
      <div
        className="col s12 m6"
        style={{ height: "300px", marginBottom: "20px" }}
      >
        <div
          className="exr-dark-grey-bg"
          style={{ height: "100%", width: "100%" }}
        ></div>
      </div>
    </div>
  );
  var baseMintButton = (
    <div id="mint-pack-action-button">
      <div className="white-text headline-text splash-button-bullet-top center-align">
        &#8226;
      </div>
      <div
        className="splash-main-button-wrapper center-align"
        title="Alpha Login"
      >
        <button
          className="btn-flat csr-button-white splash-main-button"
          style={{ fontSize: "18px" }}
          onClick={checkMintPacks}
          title="View Mint Pack"
        >
          <span className="splash-main-button-bracket">[ </span>
          <span className="headline-text">CHECK MINT PACK</span>
          <span className="splash-main-button-bracket"> ]</span>
        </button>
      </div>
      <div className="white-text headline-text splash-button-bullet-bottom center-align">
        &#8226;
      </div>
    </div>
  );

  function checkMintPackItems() {
    mintPackContract.contract
      .balanceOf(account)
      .then((qty) => {
        console.log({ qty: qty.toNumber() });
        setTokenQty(qty.toNumber());
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <WalletHeader />
      <SplashHeader />
      <div className="row white-text page-width" style={{ marginTop: "50px" }}>
        <div className="col s12 m4">
          <div className="row" style={{ margin: "0% 1% 0% 1%", width: "98%" }}>
            <div
              className="headline-text section-title-text center-align"
              style={{ fontSize: "24px" }}
            >
              YOUR MINT PACKS
            </div>
            <div className="section-title-underline"></div>
          </div>
          <div
            className="row center-align"
            style={{ margin: "0% 1% 0% 1%", width: "98%" }}
          >
            <MintPack />
          </div>
        </div>
        <div className="col s12 m8">
          <div className="row" style={{ marginLeft: "5%", width: "95%" }}>
            <div className="col s12 m6" style={{ marginBottom: "20px" }}>
              <PilotSection />
            </div>
            <div className="col s12 m6" style={{ marginBottom: "20px" }}>
              <RacerSection />
            </div>
            <BoosterSection />
          </div>
        </div>
      </div>
    </div>
  );
};
