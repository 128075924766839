import * as THREE from "three";
import {PropGenerator} from "../propGenerator.js";
import {TrackPropInstanceSingle} from "../../instance/trackPropInstanceSingle.js";
import {TrackProp} from "../../trackProp.js";

/**
 * A procedural track prop scatterer
 */
export class PropGeneratorScatterer extends PropGenerator {
    static ATTEMPTS = 4;

    /**
     * Randomize prop rotation
     * @param {Random} random A randomizer
     * @returns {THREE.Vector3} A random rotation
     */
    static randomizeRotation(random) {
        return new THREE.Vector3(
            Math.PI * 2 * random.float,
            Math.PI * 2 * random.float,
            Math.PI * 2 * random.float);
    }

    /**
     * Randomize a direction
     * @param {THREE.Vector3} vector The vector to store the direction in
     * @param {Random} random A randomizer
     */
    static randomizeDirection(vector, random) {
        vector.y = Math.cos(Math.PI * random.float);

        const theta = random.float * Math.PI * 4;
        const iz = Math.sqrt(1 - vector.y * vector.y);

        vector.x = iz * Math.cos(theta);
        vector.z = iz * Math.sin(theta);
    }

    /**
     * Construct a prop scatterer
     * @param {Random} random A randomizer
     * @param {THREE.Vector3} origin The field origin
     * @param {THREE.Vector2} spacing The range of spacing between assets
     * @param {THREE.Vector2} count The range of instance counts
     * @param {string[]} assets The assets to instantiate
     * @param {number} [rotation] A maximum rotation velocity, if any
     */
    constructor(random, origin, spacing, count, assets, rotation = 0) {
        super(random);

        this.assets = assets;
        this.instances = [new TrackPropInstanceSingle(origin, PropGeneratorScatterer.randomizeRotation(random))];

        const instanceCount = Math.round(count.x + (count.y - count.x) * random.float);
        const open = this.instances.slice();
        const direction = new THREE.Vector3();
        const position = new THREE.Vector3();
        let active;

        exhaustive: while (active = open.pop()) {
            attempts: for (let attempt = 0; attempt < PropGeneratorScatterer.ATTEMPTS; ++attempt) {
                PropGeneratorScatterer.randomizeDirection(direction, random);

                position.copy(
                    direction).multiplyScalar(
                        spacing.x + (spacing.y - spacing.x) * random.float).add(
                            active.position);

                for (const instance of this.instances)
                    if (instance.position.distanceToSquared(position) < spacing.x * spacing.x)
                        continue attempts;

                const rotationVector = rotation === 0 ? null : new THREE.Vector3(
                    (Math.random() - .5) * rotation * 2,
                    (Math.random() - .5) * rotation * 2,
                    (Math.random() - .5) * rotation * 2);
                const newInstance = new TrackPropInstanceSingle(
                    position.clone(),
                    PropGeneratorScatterer.randomizeRotation(random),
                    rotationVector);

                this.instances.push(newInstance);

                open.unshift(newInstance);

                if (this.instances.length === instanceCount)
                    break exhaustive;
            }
        }
    }

    /**
     * Generate track props
     * @param {TrackProp[]} props The track prop array to append
     * @param {PropGeneratorExcluder} excluder An excluder
     */
    generate(props, excluder) {
        const propsInstances = [];

        for (let asset = 0; asset < this.assets.length; ++asset)
            propsInstances.push([]);

        for (const instance of this.instances) if (!excluder.cull(instance.position))
            propsInstances[Math.floor(this.random.float * propsInstances.length)].push(instance);

        for (let asset = 0; asset < this.assets.length; ++asset) if (propsInstances[asset].length !== 0)
            props.push(new TrackProp(this.assets[asset], propsInstances[asset], true));
    }
}