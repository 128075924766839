import {axiosInstance, encryptLoginData, getIndexerUserTokens} from "../../../../utils";
import {InjectedConnector, } from "@web3-react/injected-connector";
// import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
// import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5';

import $ from "jquery";

const MAINNET_CHAIN_ID = 1;
const ROPSTEN = 3;
const RINKEBY = 4;
const KOVAN = 42;
const MOONBASE = 1287;
const HARDHAT_ID = 31337;
const MOONBEAM = 1284;
const ETHERIUM = 1;
const BINANCE = 56;
const POLYGON = 137;
const ARBITRUM = 42161;
const AVALANCHE = 43114;

const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: [
    MAINNET_CHAIN_ID,
    HARDHAT_ID,
    ROPSTEN,
    RINKEBY,
    KOVAN,
    MOONBASE,
    MOONBEAM,
    ETHERIUM,
    BINANCE,
    POLYGON,
    ARBITRUM,
    AVALANCHE
  ],
});

export function validateEmailText(emailtext) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailtext.match(validRegex)) {
      return 'pass';
    } else {
      return 'fail';
    }
}

export async function sendEventEmailCode(raceremail) {
    var addData = new Object();
    addData.specEmail = encodeURIComponent(raceremail);

    var email_sent_response = await axiosInstance.post('member/event_email_add/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                return {'status':'email_sent'};
            } else {
                return {'status':'email_error'};
            }
        } else {
            return {'status':'lookup_error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return email_sent_response;
}

export async function checkEventEmailCode(raceremail,racercode,exreventid) {
    
    var addData = new Object();
    addData.specEmail = encodeURIComponent(raceremail);
    addData.specCode = encodeURIComponent(racercode);
    addData.specEvent = encodeURIComponent(exreventid);

    var email_code_check_response = await axiosInstance.post('member/event_email_code_check/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                if(save_result[0]['status'] == 'expired') {
                    return {'status':'email_code_expired'};
                } else if(save_result[0]['status'] == 'pending') {
                    return {'status':'email_code_wrong'};
                } else if (save_result[0]['status'] == 'approved_waitroom') {
                    return {'status':'confirmed_waitroom'};
                } else {
                    // Existing User - Approve Code
                    let userid = save_result[0]['user_id'];
                    let racerid = save_result[0]['racer_id'];
                    let spectatorid = save_result[0]['spectator_id'];
                    let logintime = save_result[0]['time'];
                    let logintoken = save_result[0]['token'];
                    let walletid = save_result[0]['wallet_id'];

                    var login_creds = {
                        'userid':userid,
                        'racerid':racerid,
                        'spectatorid':spectatorid,
                        'logintime':logintime,
                        'logintoken':logintoken,
                        'walletid':walletid}
                    return {'status':'confirmed_existing','login_info':login_creds};
                }
            } else {
                return {'status':'lookup_error'};
            }
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return email_code_check_response;
}

export async function addSpectatorInfo(raceremail,racerhandle,eventid) {
    var racertwitter = ''
    var racerdiscord = ''
    var addData = new Object();
    addData.specEmail = encodeURIComponent(raceremail);
    addData.specHandle = encodeURIComponent(racerhandle);
    addData.specTwitter = encodeURIComponent(racertwitter);
    addData.specDiscord = encodeURIComponent(racerdiscord);
    if(eventid > 0) {
        addData.specSource = 'event';
        addData.specEvent = encodeURIComponent(eventid);
    } else {
        addData.specSource = 'homepage';
    }
    
    let new_user_info = await axiosInstance.post('member/waitroom_spectator_add/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                let userid = save_result[0]['user_id'];
                let racerid = save_result[0]['racer_id'];
                let spectatorid = save_result[0]['spectator_id'];
                let logintime = save_result[0]['time'];
                let logintoken = save_result[0]['token'];
                let walletid = save_result[0]['wallet_id'];

                var login_creds = {
                    'userid':userid,
                    'racerid':racerid,
                    'spectatorid':spectatorid,
                    'logintime':logintime,
                    'logintoken':logintoken,
                    'walletid':walletid}

                return {'status':'confirmed','login_info':login_creds};

            } else {
                return {'status':'update_error'};
            }
        } else {
            return {'status':'lookup_error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return new_user_info;
}

export async function addWalletInfo(raceremail,userid,walletaddress,walletstatus) {

    var addData = new Object();
    addData.specEmail = encodeURIComponent(raceremail);
    addData.specID = encodeURIComponent(userid);
    addData.specWallet = encodeURIComponent(walletaddress);
    addData.walletStatus = encodeURIComponent(walletstatus);

    let wallet_info = axiosInstance.post('member/waitroom_wallet_add/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                if(save_result[0]['status'] == 'duplicate') {
                    return {'status':'duplicate_wallet'};
                } else if(save_result[0]['status'] == 'pending') {
                    return {'status':'no_account'};
                } else {
                    let userid = save_result[0]['user_id'];
                    let racerid = save_result[0]['racer_id'];
                    let spectatorid = save_result[0]['spectator_id'];
                    let logintime = save_result[0]['time'];
                    let logintoken = save_result[0]['token'];
                    let walletid = save_result[0]['wallet_id'];
                    
                    var login_creds = {
                        'userid':userid,
                        'racerid':racerid,
                        'spectatorid':spectatorid,
                        'logintime':logintime,
                        'logintoken':logintoken,
                        'walletid':walletid}
    
                    return {'status':'confirmed','login_info':login_creds};
                }
            } else {
                return {'status':'update_error'};
            }
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return wallet_info;
}

export async function connectMetamask() {
    const isMetaMaskInstalled = () => {
        const { ethereum } = window;
        return Boolean(ethereum && ethereum.isMetaMask);
    };

    if(!isMetaMaskInstalled) {
        return {'status':'not_installed'};
    } else {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if(accounts[0]) {
                let racer_wallet_address = accounts[0];
                let racer_wallet_type = 'metamask';
                let inventory_check = await checkRacerAssetsIndexer(racer_wallet_address);

                return {'status':'connected','wallet_address':racer_wallet_address,'wallet_type':racer_wallet_type,'inventory_check':inventory_check};
            } else {
                alert('Unable To Get Your Accounts');
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if(accounts[0]) {
                    let racer_wallet_address = accounts[0];
                    let racer_wallet_type = 'metamask';
                    let inventory_check = await checkRacerAssetsIndexer(racer_wallet_address);

                    return {'status':'connected','wallet_address':racer_wallet_address,'wallet_type':racer_wallet_type,'inventory_check':inventory_check};
                }
            }
        } catch(e) {
            return {'status':'not_installed'};
        }
    }
}

export async function connectTalisman() {
    const isTalismanInstalled = () => {
        return Boolean(window && window.talismanEth);
    };

    if((!isTalismanInstalled)||(window.talismanEth === undefined)) {
        return {'status':'not_installed'};
    } else {
        try {
            const accounts = await window.talismanEth.request({ method: 'eth_requestAccounts'});
            if(accounts[0]) {
                let racer_wallet_address = accounts[0];
                let racer_wallet_type = 'talisman';
                if(racer_wallet_address) {
                    let inventory_check = await checkRacerAssetsIndexer(racer_wallet_address);
                    return {'status':'connected','wallet_address':racer_wallet_address,'wallet_type':racer_wallet_type,'inventory_check':inventory_check};
                } else {
                    return {'status':'not_connected'};
                }
            } else {
                alert('Unable To Get Your Accounts');
                const accounts = await window.talismanEth.request({ method: 'eth_requestAccounts'});
                if(accounts[0]) {
                    let racer_wallet_address = accounts[0];
                    let racer_wallet_type = 'talisman';
                    let inventory_check = await checkRacerAssetsIndexer(racer_wallet_address);

                    return {'status':'connected','wallet_address':racer_wallet_address,'wallet_type':racer_wallet_type,'inventory_check':inventory_check};
                }
            }
        } catch(e) {
            return {'status':'not_installed'};
        }
    }
}

export async function checkRacerAssetsIndexer(walletaddress) {
    var total_pilots_sync = 0;
    var total_racecrafts_sync = 0;
    var total_boosters_sync = 0;
    var racer_tokens = await getIndexerUserTokens(walletaddress);
    if(racer_tokens != null) {
        var pilot_token_list = [];
        var racecraft_token_list = [];
        var booster_token_list = [];
        if(racer_tokens['ownedTokens'] !== undefined) {
            for(var i = 0; i < racer_tokens['ownedTokens'].length;i++) {
                var indexer_token_text_id = racer_tokens['ownedTokens'][i]['id'];
                var indexer_token_text_split = indexer_token_text_id.split("-");
                var indexer_token_type = indexer_token_text_split[0];
                var indexer_token_id = indexer_token_text_split[1];
                if(indexer_token_type == 'pilot') {
                    pilot_token_list.push(indexer_token_id);
                } else if(indexer_token_type == 'racecraft') {
                    racecraft_token_list.push(indexer_token_id);
                }
            }
        }
        if(racer_tokens['ownedBoosters'] !== undefined) {
            for(var i = 0; i < racer_tokens['ownedBoosters'].length;i++) {
                var indexer_token_text_id = racer_tokens['ownedBoosters'][i]['id'];
                var indexer_token_quantity = racer_tokens['ownedBoosters'][i]['qty'];
                var indexer_token_text_split = indexer_token_text_id.split("-");
                var indexer_token_type = indexer_token_text_split[0];
                var indexer_token_walletsub = indexer_token_text_split[1];
                var indexer_token_id = indexer_token_text_split[2];
                for(var j = 0; j < indexer_token_quantity;j++) {
                    booster_token_list.push(indexer_token_id);
                }
            }
        }
        total_pilots_sync = pilot_token_list.length;
        total_racecrafts_sync = racecraft_token_list.length;
        total_boosters_sync = booster_token_list.length;
        updateWalletTokenOwner(walletaddress,pilot_token_list,'pilot');
        updateWalletTokenOwner(walletaddress,racecraft_token_list,'racecraft');
        updateWalletTokenOwner(walletaddress,booster_token_list,'booster');
    }

    return {'total_pilots':total_pilots_sync, 'total_racecrafts':total_racecrafts_sync, 'total_boosters':total_boosters_sync };
}

export function updateWalletTokenOwner(walletaddress,tokenIds,tokenType) {
    var tokenstring = '';
    for(var i = 0; i < tokenIds.length;i++) {
      if(i > 0) {
        tokenstring = tokenstring+',';
      }
      tokenstring = tokenstring+tokenIds[i];
    }
    var addData = new Object;
    addData.walletID = encodeURIComponent(walletaddress);
    addData.tokenList = tokenstring;
    addData.nType = tokenType;

    if(walletaddress !== null) {
      if(walletaddress.length > 3) {
          axiosInstance
            .post("https://data.exiledracers.com/api/v1/mint/token_owner_check/", addData)
            .then((result) => {
              if (Object.keys(result.data).length > 0) {
                var view_result = result.data;
              }
            })
            .catch((error) => {
              console.log(error);
            });
      }
    }
}

export async function createRedirectToken(userid) {
    let stringfront = (Math.random() + 1).toString(36).substring(7);
    let stringend = (Math.random() + 1).toString(36).substring(7);
    let hashed_userid = stringfront+'u7t'+userid+'b4k'+stringfront;

    var addData = new Object();
    addData.redirectToken = encodeURIComponent(hashed_userid);

    let redirect_token = axiosInstance.post('member/redirect_link/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var info_result = result.data;
            if(info_result[0]['status'] == 'new') {
                return {'status':'new','token':info_result[0]['token']};
            } else {
                return {'status':'error'};
            }
        } else {
            return {'status':'error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return redirect_token;
}

export async function getEventUserInfo(userid,exreventid) {

    var addData = new Object();
    addData.specID = encodeURIComponent(userid);
    addData.specEvent = encodeURIComponent(exreventid);

    let event_info = axiosInstance.post('member/event_info/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var info_result = result.data;
            if(info_result.length > 0) {
                return {'status':'confirmed','event_info':info_result[0]};
            } else {
                return {'status':'error'};
            }
        } else {
            return {'status':'error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return event_info;
}

export async function addEventUserAction(userid,exreventid,actiontype,actionid='') {

    var addData = new Object();
    addData.specID = encodeURIComponent(userid);
    addData.specEvent = encodeURIComponent(exreventid);
    addData.specAction = encodeURIComponent(actiontype);
    addData.specActionID = encodeURIComponent(actionid);

    let event_info = axiosInstance.post('member/event_action/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var info_result = result.data;
            return {'status':'confirmed'};
        } else {
            return {'status':'error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return event_info;
}

export async function saveRacerHandle(userID,start_handle,racerhandle) {
    let handle_info = '';
    var addData = new Object();
    addData.userID = encodeURIComponent(userID);
    if(start_handle !== racerhandle) {
        addData.racerHandle = encodeURIComponent(racerhandle);
    }
    if((start_handle !== racerhandle)&&(racerhandle.length < 3)) {
        alert('Pick a Longer Racer Handle. Handles Must be atleast 3 Letters or Numbers Long.');
        handle_info = 'error_length';
    } else {
        handle_info = await axiosInstance.post('member/settings_update/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var save_result = result.data;
                if(save_result.save_status.includes("success")) {
                    return save_result.save_status;
                } else if(save_result.save_status.includes("error_handle")) {
                    alert('This Racer Handle is Already Taken. Update and Try Again.');
                    return 'error_handle';
                } else {
                    alert('Whoops! Something Went Wrong. Update and Try Again');
                    return 'error';
                }
            } else {
                return 'error';
            }
        }).catch(error => {
            console.log(error);
            return 'error';
        });
    }

    return handle_info;
}

// export async function getWalletConnectModal() {
//     // 1. Get projectId
//     const projectId = '16f92b2d5656feab7242fad0cf8cc20f'

//     // 2. Set chains
//     const wcMainnet = {
//         chainId: 1,
//         name: 'Ethereum',
//         currency: 'ETH',
//         explorerUrl: 'https://etherscan.io',
//         rpcUrl: 'https://cloudflare-eth.com'
//     }

//     // 3. Create a metadata object
//     const wcMetadata = {
//         name: 'Exiled Racers',
//         description: 'The First Racing Manager & Prediction Game Powered by Web3',
//         // url: 'https://base.exiledracers.com',
//         url: 'http://localhost:3001',
//         icons: ['https://www.exiledracers.com/favicon-96x96.png']
//     }

//     // 4. Create Ethers config
//     const ethersConfig = defaultConfig({
//         /*Required*/
//         wcMetadata,
    
//         /*Optional*/
//         enableEIP6963: true, // true by default
//         enableInjected: true, // true by default
//         enableCoinbase: true, // true by default
//         rpcUrl: '...', // used for the Coinbase SDK
//         defaultChainId: 1, // used for the Coinbase SDK
//     });

//     // 5. Create a Web3Modal instance
//     var wcModal = createWeb3Modal({
//         ethersConfig,
//         chains: [wcMainnet],
//         projectId,
//         enableAnalytics: true // Optional - defaults to your Cloud configuration
//     });

//     return wcModal;
// }