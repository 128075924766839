import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import { setRacerLogin } from "../../../../../redux/Account/actions";
import "../../race_track.css";
import $ from "jquery";
import {ShareResultsBox, ShareResultsGraphic} from "../../../../Organisms";
import "../../../RaceLanding/race_landing.css";
import html2canvas from 'html2canvas';

let redirectimer = 0;
let nextredirecttime;

export const FinalResultEmbedScreen = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [race_table_html, setResultsTableHTML] = useState('');
    const [race_winner_html, setResultsWinnerHTML] = useState('');
    const [boardwidth,setBoardWidth] = useState('90%');
    const [boardlmargin,setBoardLMargin] = useState('5%');
    const [shareDisplay, setShareDisplay] = useState('block');
    const [resultsGraphic, setResultsGraphic] = useState('');

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    let items_checked = 'N';
    let race_id = 0;
    let bot_race = 0;
    var racer_items = [];
    function grabRaceParticipants() {
        items_checked = 'Y';
        let watch_racer_id = '0';
        if(userID.length > 0) {
            watch_racer_id = userID;
        }
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.race_unique_id);
        addData.predictorID = watch_racer_id;
        addData.sortBY = 'standings';

        axiosInstance.post('race/racer_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                race_id = result.data[0]['race_id'];
                createPageResults(result.data);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function createPageResults(itemarr) {
        var section_html = [];
        var winner_html = '';
        var racer_attributes = ['speed', 'handling', 'acceleration', 'strength'];
        var pilot_attributes = ['focus', 'stamina', 'aggression', 'intelligence'];
        var booster_attributes = ['speed', 'handling', 'acceleration', 'strength', 'focus', 'stamina', 'aggression', 'intelligence'];
        var pilotcnt = 0;

        var exr_team_total = 0;
        var exr_team_pilot = '';
        var harumi_team_total = 0;
        var harumi_team_pilot = '';
        for(var z = 0; z < itemarr.length;z++) {
            var race_team_id = itemarr[z].race_team_id;
            if(race_team_id == 1) {
                exr_team_total = exr_team_total + itemarr[z].race_final_coins;
                if(exr_team_pilot == '') {
                    exr_team_pilot = itemarr[z].pilot_image_location;
                }
            } else if(race_team_id == 2) {
                harumi_team_total = harumi_team_total + itemarr[z].race_final_coins;
                if(harumi_team_pilot == '') {
                    harumi_team_pilot = itemarr[z].pilot_image_location;
                }
            }  
        }

        if(exr_team_total > 0) {
            if(exr_team_total >= harumi_team_total) {
                var tmp_html = <div key={"exr10000"} className="col s12 exr-new-dark-grey final-result-flex-row" style={{flex:'1',maxHeight:'80px',margin:"0px",padding:"0px",marginTop:'8px',borderRadius:'10px',overflow:'hidden'}}>
                                <div className="row white-text" style={{marginBottom:'0px',fontSize:"16px",height:'100%'}}>
                                    <div className="col s6 l7" style={{display:'flex',flexDirection:'row',overflow:'hidden',height:'100%'}}>
                                        <div className="headline-text center-align  final-result-flex-row-number" style={{fontSize:'calc(16px + 0.5vw)',padding:"0px 10px",letterSpacing:"-4px",marginTop:"10px"}}>
                                            T1
                                        </div>
                                        <div className="hide-on-small-only show-on-med-and-up" style={{padding:"0px 5px",height:'100%'}}>
                                            <img src={exr_team_pilot} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="show-on-small-only hide-on-med-and-up" style={{padding:"0px 5px",marginTop:'6%',height:'60%'}}>
                                            <img src={exr_team_pilot} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="headline-text final-result-flex-row-text" style={{flex:'1',padding:"0px 5px",marginTop:"10px",fontSize:'calc(10px + 0.25vw)'}}>
                                            EXR ALL-STARS
                                        </div>
                                    </div>
                                    <div className="col s6 l5" style={{height:'100%',overflow:'hidden'}}>
                                        <div className="right final-result-flex-row-text" style={{padding:"0px 0px",fontSize:'calc(10px + 0.3vw)',marginRight:'20px'}}>
                                            {exr_team_total} RPM
                                        </div>
                                    </div>
                                </div>
                            </div>;
                section_html.push(tmp_html);
                var tmp_html = <div key={"hr10001"} className="col s12 exr-new-dark-grey final-result-flex-row" style={{flex:'1',maxHeight:'80px',margin:"0px",padding:"0px",marginTop:'8px',borderRadius:'10px',overflow:'hidden'}}>
                                <div className="row white-text" style={{marginBottom:'0px',fontSize:"16px",height:'100%'}}>
                                    <div className="col s6 l7" style={{display:'flex',flexDirection:'row',overflow:'hidden',height:'100%'}}>
                                        <div className="headline-text center-align  final-result-flex-row-number" style={{fontSize:'calc(16px + 0.5vw)',padding:"0px 10px",letterSpacing:"-4px",marginTop:"10px"}}>
                                            T2
                                        </div>
                                        <div className="hide-on-small-only show-on-med-and-up" style={{padding:"0px 5px",height:'100%'}}>
                                            <img src={harumi_team_pilot} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="show-on-small-only hide-on-med-and-up" style={{padding:"0px 5px",marginTop:'6%',height:'60%'}}>
                                            <img src={harumi_team_pilot} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="headline-text final-result-flex-row-text" style={{flex:'1',padding:"0px 5px",marginTop:"10px",fontSize:'calc(10px + 0.25vw)'}}>
                                            HARUMI REAPERS
                                        </div>
                                    </div>
                                    <div className="col s6 l5" style={{height:'100%',overflow:'hidden'}}>
                                        <div className="right final-result-flex-row-text" style={{padding:"0px 0px",fontSize:'calc(10px + 0.3vw)',marginRight:'20px'}}>
                                            {harumi_team_total} RPM
                                        </div>
                                    </div>
                                </div>
                            </div>;
                section_html.push(tmp_html);
            } else {
                var tmp_html = <div key={"hr10000"} className="col s12 exr-new-dark-grey final-result-flex-row" style={{flex:'1',maxHeight:'80px',margin:"0px",padding:"0px",marginTop:'8px',borderRadius:'10px',overflow:'hidden'}}>
                                <div className="row white-text" style={{marginBottom:'0px',fontSize:"16px",height:'100%'}}>
                                    <div className="col s6 l7" style={{display:'flex',flexDirection:'row',overflow:'hidden',height:'100%'}}>
                                        <div className="headline-text center-align  final-result-flex-row-number" style={{fontSize:'calc(16px + 0.5vw)',padding:"0px 10px",letterSpacing:"-4px",marginTop:"10px"}}>
                                            T1
                                        </div>
                                        <div className="hide-on-small-only show-on-med-and-up" style={{padding:"0px 5px",height:'100%'}}>
                                            <img src={harumi_team_pilot} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="show-on-small-only hide-on-med-and-up" style={{padding:"0px 5px",marginTop:'6%',height:'60%'}}>
                                            <img src={harumi_team_pilot} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="headline-text final-result-flex-row-text" style={{flex:'1',padding:"0px 5px",marginTop:"10px",fontSize:'calc(10px + 0.25vw)'}}>
                                            HARUMI REAPERS
                                        </div>
                                    </div>
                                    <div className="col s6 l5" style={{height:'100%',overflow:'hidden'}}>
                                        <div className="right final-result-flex-row-text" style={{padding:"0px 0px",fontSize:'calc(10px + 0.3vw)',marginRight:'20px'}}>
                                            {harumi_team_total} RPM
                                        </div>
                                    </div>
                                </div>
                            </div>;
                section_html.push(tmp_html);
                var tmp_html = <div key={"exr10001"} className="col s12 exr-new-dark-grey final-result-flex-row" style={{flex:'1',maxHeight:'80px',margin:"0px",padding:"0px",marginTop:'8px',borderRadius:'10px',overflow:'hidden'}}>
                                <div className="row white-text" style={{marginBottom:'0px',fontSize:"16px",height:'100%'}}>
                                    <div className="col s6 l7" style={{display:'flex',flexDirection:'row',overflow:'hidden',height:'100%'}}>
                                        <div className="headline-text center-align  final-result-flex-row-number" style={{fontSize:'calc(16px + 0.5vw)',padding:"0px 10px",letterSpacing:"-4px",marginTop:"10px"}}>
                                            T2
                                        </div>
                                        <div className="hide-on-small-only show-on-med-and-up" style={{padding:"0px 5px",height:'100%'}}>
                                            <img src={exr_team_pilot} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="show-on-small-only hide-on-med-and-up" style={{padding:"0px 5px",marginTop:'6%',height:'60%'}}>
                                            <img src={exr_team_pilot} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="headline-text final-result-flex-row-text" style={{flex:'1',padding:"0px 5px",marginTop:"10px",fontSize:'calc(10px + 0.25vw)'}}>
                                            EXR ALL-STARS
                                        </div>
                                    </div>
                                    <div className="col s6 l5" style={{height:'100%',overflow:'hidden'}}>
                                        <div className="right final-result-flex-row-text" style={{padding:"0px 0px",fontSize:'calc(10px + 0.3vw)',marginRight:'20px'}}>
                                            {exr_team_total} RPM
                                        </div>
                                    </div>
                                </div>
                            </div>;
                section_html.push(tmp_html);
            }

            var tmp_html = <div key={"exr100010"} className="col s12" style={{margin:"0px",padding:"0px",marginTop:'8px',marginBottom:'0px',borderRadius:'0px',overflow:'hidden'}}>
                                <div className="row white-text" style={{marginBottom:'0px',fontSize:"16px",height:'1px',border:'1px solid white'}}>
                                </div>
                            </div>;
                section_html.push(tmp_html);
        }


        for(var z = 0; z < itemarr.length;z++) {
            var pilot_image = itemarr[z].pilot_image_location;
            var racer_image = itemarr[z].ship_image_location;

            var racer_name = itemarr[z].racer_name;
            if(itemarr[z].racer_id == 2) {
                bot_race = 1;
            }
            var item_subtitle = 'SERF FLEET';
            
            
            var final_place = itemarr[z].race_final_position
            var placetext = ordinal_suffix_of((z+1));
            var placecolor = 'white-text';
            if(z == 0) {
                placecolor = 'exr-red-text';
                placetext = 'Winner'
            }
            var racer_final_xp = itemarr[z].race_final_xp;
            var racer_final_exr = itemarr[z].race_final_coins;

            var exrcolor = '';
            if(racer_final_exr > 0) {
                exrcolor = ' exr-red-text';
            }

            /* Calculate Race Time */
            var racer_final_seconds = itemarr[z].race_final_time.toString();
            var millisplit = racer_final_seconds.split(".");
            if(millisplit.length == 1) {
                var millicount = '00';
                var totalseconds = parseInt(millisplit[0]);
            } else {
                var millicount = millisplit[1];
                var totalseconds = parseInt(millisplit[0]);
            }
            if(millicount.length == 1) {
                millicount = millicount+'0';
            }
            var totalmins = Math.floor(totalseconds / 60);
            var finalseconds = totalseconds - (totalmins*60);
            var finaltimetxt = '';
            if(totalmins > 0) {
                if(totalmins < 10) {
                    finaltimetxt = finaltimetxt+'0';
                }
                finaltimetxt = finaltimetxt+totalmins+'m ';
            }
            if(finalseconds < 10) {
                finaltimetxt = finaltimetxt+'0';
            }
            finaltimetxt = finaltimetxt+finalseconds+'s ';
            
            finaltimetxt = finaltimetxt+millicount+'ms';

            // pilot_image = pilot_sample[pilotcnt];
            // racer_image = racer_sample[pilotcnt];
            // pilotcnt = pilotcnt+1;
            // if(pilotcnt == pilot_sample.length) {
            //     pilotcnt = 0;
            // }

            var total_racer_predictors = itemarr[z].total_predictors;
            var predicted_html = <></>;
            var predicted_html_row = <></>;
            if(itemarr[z].racer_predicted > 0) {
                if(itemarr[z].racer_predicted_win > 0) {
                    predicted_html = <><div className="col s7">
                                            Your Prediction: <b className="exr-light-green-text">Correct</b>
                                        </div>
                                        <div className="col s5 exr-light-green-text right-align">
                                            {itemarr[z].racer_predicted_win_xp} XP Won
                                        </div></>;
                    predicted_html_row = <span className="exr-light-green-text">(Predicted +{itemarr[z].racer_predicted_win_xp} XP)</span>;

                } else {
                    predicted_html = <><div className="col s7">
                                            Your Prediction: <b className="exr-red-text">Missed</b>
                                        </div>
                                        <div className="col s5 exr-red-text right-align">
                                            0 XP Won
                                        </div></>;
                    predicted_html_row = <span className="exr-red-text">(Prediction Missed)</span>;
                }
            } else {
                predicted_html = <><div className="col s6">
                                            Total Predictors: {total_racer_predictors}
                                        </div>
                                        <div className="col s6 right-align">
                                            0 XP Won
                                        </div></>;
            }
            
            if(z == 0) {
                winner_html = <div id={"fboard"+z} className="exr-dark-grey-bg" style={{padding:'10px',borderRadius:'10px'}}>
                                        <div className="row exr-new-dark-grey white-text center-align" 
                                            style={{margin:'5px 5px',fontSize:'calc(10px + 0.3vw)',borderRadius:'5px',padding:'10px 0px'}}>
                                            <span className="exr-yellow-text"  style={{fontSize:'calc(10px + 0.3vw)',lineHeight:'1.5'}}><b>{placetext.toUpperCase()}</b> </span><br/>
                                            <span className="white-text" style={{fontSize:'calc(14px + 0.3vw)',lineHeight:'1.25'}}>{racer_name.toUpperCase()}</span>
                                        </div>
                                        <div className="row finish-screen-racer-divider" style={{display:'none'}}></div>
                                        <div className="row finish-screen-racer-image">
                                            <img src={pilot_image} width="100%" style={{borderRadius:'5px'}} />
                                        </div>
                                        <div className="row finish-screen-racer-sub">
                                            <div className="left finish-screen-racer-ship"  style={{height:'11vw',background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
                                            </div>
                                            <div className="left finish-screen-racer-stats exr-new-dark-grey" style={{maxHeight:'16vw'}}>
                                                <div className="finish-screen-stats-row">
                                                    RPM Won
                                                </div>
                                                <div className="finish-screen-stats-subrow exr-light-green-text" style={{fontSize:'calc(10px + 0.3vw)'}}>
                                                    {racer_final_exr}
                                                </div>
                                                <div className="finish-screen-stats-row">
                                                    Finish Time
                                                </div>
                                                <div className="finish-screen-stats-subrow" style={{fontSize:'calc(10px + 0.3vw)'}}>
                                                    {finaltimetxt}
                                                </div>
                                            </div>
                                        </div>
                                    </div>;
            }

            var tmp_html = <div key={z+100} className="col s12 exr-new-dark-grey final-result-flex-row" style={{flex:'1',maxHeight:'80px',margin:"0px",padding:"0px",marginTop:'8px',borderRadius:'10px',overflow:'hidden'}}>
                                <div className="row white-text" style={{marginBottom:'0px',fontSize:"16px",height:'100%'}}>
                                    <div className="col s6 l7" style={{display:'flex',flexDirection:'row',overflow:'hidden',height:'100%'}}>
                                        <div className="headline-text center-align  final-result-flex-row-number" style={{fontSize:'calc(16px + 0.5vw)',padding:"0px 10px",letterSpacing:"-4px",marginTop:"10px"}}>
                                            {final_place}
                                        </div>
                                        <div className="hide-on-small-only show-on-med-and-up" style={{padding:"0px 5px",height:'100%'}}>
                                            <img src={pilot_image} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="show-on-small-only hide-on-med-and-up" style={{padding:"0px 5px",marginTop:'6%',height:'60%'}}>
                                            <img src={pilot_image} height="100%" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="headline-text final-result-flex-row-text" style={{flex:'1',padding:"0px 5px",marginTop:"10px",fontSize:'calc(10px + 0.25vw)'}}>
                                            {racer_name.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className="col s6 l5" style={{height:'100%',overflow:'hidden'}}>
                                        <div className="right final-result-flex-row-text" style={{padding:"0px 20px",fontSize:'calc(10px + 0.3vw)'}}>
                                            {finaltimetxt}
                                        </div>
                                        <div className="right final-result-flex-row-text" style={{padding:"0px 0px",fontSize:'calc(10px + 0.3vw)'}}>
                                            {racer_final_exr} RPM
                                        </div>
                                    </div>
                                </div>
                            </div>;
            section_html.push(tmp_html);
        }
        
        // updateLeadboardWidth();
        setResultsWinnerHTML(winner_html);
        setResultsTableHTML(section_html);

        setTimeout(function(){
            updateRaceTableSizes();
        },100);

    }

    if(entrySet == false) {
        setEntry(true);
        grabRaceParticipants();
        checkResultsVisible();
    }

    function updateLeadboardWidth() {
        var totalracers = 0;

        var tmpwidth = '100%';
        var tmpmargin ='0%';
        // if(($("#gui").width() > 1000)&&(totalracers > 2)) {
        //     tmpwidth = '1000px';
        //     tmpmargin = (($("#gui").width() - 1000) / 2)+'px';
        // } else if(($("#gui").width() > 700)&&(totalracers == 2)) {
        //     tmpwidth = '700px';
        //     tmpmargin = (($("#gui").width() - 700) / 2)+'px';
        //     $()
        // }
        //$("#finalboardbox").css({'width':tmpwidth,'left':tmpmargin});

        
        var item_col_size = "m6";
        var subimage_height = '200px';
        if((totalracers > 2)&&($("#gui").width() > 800)) {
            item_col_size = "m4";
            subimage_height = '160px';
        } else if ($("#gui").width() < 550) {
            item_col_size = "";
            subimage_height = '100px';
        }
    }

    function updateRaceTableSizes() {
        if(window.innerWidth < 600) {
            $("#finalracetable").css({height:'84vh'});
        } else {
            //$("#finalracetable").css({height:$("#finalracewinner").height()+'px'});
            $("#finalracetable").css({height:'84vh'});
        }
        if(window.innerWidth < 1000) {
            $(".finish-screen-racer-ship").hide();
            $(".finish-screen-racer-stats").each(function(){
                $(this).css({width:'100%',height:'15.5vw'});
            });
        } else {
            $(".finish-screen-racer-ship").show();
            $(".finish-screen-racer-stats").each(function(){
                $(this).css({width:'49%',height:'10.5vw'});
            });
        }
        
        var newflexheight = $(".final-result-flex-row").height();
        var newtextmargin = ((newflexheight-18)/2);
        $(".final-result-flex-row-text").each(function(){
            $(this).css({marginTop:newtextmargin+'px'});
        });
        $(".final-result-flex-row-number").each(function(){
            $(this).css({marginTop:newtextmargin-5+'px'});
        });
    }

    function goToRacesHome() {
        if(window.location.href.indexOf("bashconsensus") >= 0) {
            window.location.href= '/#/pub/bashconsensus';
            dispatch(updateLocation("pickem"));
        } else if(window.location.href.indexOf("bashdenver") >= 0) {
            window.location.href= '/#/pub/bashdenver';
            dispatch(updateLocation("pickem"));
        } else if(window.location.href.indexOf("watchlive") >= 0) {
            if(window.location.href.indexOf("bashconsensus") >= 0) {
                window.location.href= '/#/pub/bashconsensus';
                dispatch(updateLocation("pickem"));
            } else if(window.location.href.indexOf("oaknetwork") >= 0) {
                window.location.href= '/#/pub/oaknetwork';
                dispatch(updateLocation("pickem"));
            } else if(window.location.href.indexOf("bashdenver") >= 0) {
                window.location.href= '/#/pub/bashdenver';
                dispatch(updateLocation("pickem"));
            } else if(window.location.href.indexOf("puntclub") >= 0) {
                window.location.href= '/#/pub/puntclub';
                dispatch(updateLocation("pickem"));
            } else if(window.location.href.indexOf("apillon") >= 0) {
                window.location.href= '/#/pub/apillon';
                dispatch(updateLocation("pickem"));
            } else if(window.location.href.indexOf("thailand") >= 0) {
                window.location.href= '/#/pub/polkadot-thailand';
                dispatch(updateLocation("pickem"));
            } else if(window.location.href.indexOf("parity") >= 0) {
                window.location.href= '/#/pub/parity';
                dispatch(updateLocation("pickem"));
            } else {
                window.location.href= '/#/pickem';
                dispatch(updateLocation("pickem"));
            }
        } else {
            window.location.href= '/#/raceway';
            dispatch(updateLocation("raceway"));
        }
        setTimeout(function(){
            $("#globalmusicplayer").fadeIn();
        },3000);
    }

    function rewatchRace() {
        if(window.location.href.indexOf("watchlive") >= 0) {
            window.parent.location.reload();
        }
    }

    function goToRaceResults() {
        if((racerID.length == 0)||(accountAddress.length == 0)) {
            dispatch(setRacerLogin('',''));
            dispatch(updateLocation("splash"));
            window.location.href= '/';
        } else {
            window.location.href= '/#/race/'+props.race_unique_id;
            dispatch(updateLocation("race_landing"));
            setTimeout(function(){
                $("#globalmusicplayer").fadeIn();
            },3000);
        }
    }

    function startRedirectTimer() {
        var now = new Date();
        var diff = 0.5;
        nextredirecttime = new Date(now.getTime() + diff*60000);
        loadMoveOnTimer();
    }

    function loadMoveOnTimer() {
        var countDownDate = nextredirecttime;
        var now = Date.now();
        var distance = (countDownDate - now);
        console.log(distance);
        if(distance <= 0) {
            console.log('Going Home');
            goToRacesHome();
        } else {
            setTimeout(function() {
                loadMoveOnTimer();
            },1000);
        }
    }

    function checkResultsVisible() {
        if((window.location.href.indexOf("bashdenver") >= 0)||(window.location.href.indexOf("bashconsensus") >= 0)) {
            if(($("#finalboardbox").is(":visible"))&&(redirectimer == 0)) {
                redirectimer = 1;
                console.log('Starting Timer');
                startRedirectTimer();
            } else {
                setTimeout(function() {
                    checkResultsVisible();
                },1000);
            }
        }
    }

    window.addEventListener("resize", () => {
        updateLeadboardWidth();
        updateRaceTableSizes();
    });

    var linkdisplay = 'block';
    if((window.location.href.indexOf("watch.exiled") >= 0)||(window.location.href.indexOf("watchlive") >= 0)||(window.location.href.indexOf("streamAdmin") >= 0)) {
        linkdisplay = 'none';
    }

    return(<div id="finalboardbox" className="exr-dark-grey-trans" style={{display:'none',padding:'5px 10px',zIndex:'10000',top:'0px',left:'auto',width:'45%',right:'1%',marginTop:'20px'}}>
                <div className="col s12" style={{margin:'0px',padding:'0px'}}>
                    <div className="row" style={{margin:'0px',padding:'0px'}}>
                        <div className="col s12 exr-dark-grey-bg" id="finalracetable" style={{marginTop:'10px',padding:'10px',borderRadius:'10px',display:'flex',flexDirection:'column'}}>
                            <div className="row finalboardtop" style={{margin:'5px 0px',marginBottom:'5px'}}>
                                <div id="finalstandingstopleft" className="col s12 m6 left white-text headline-text left-align" style={{fontSize:'calc(14px + 0.5vw)'}}>
                                    FINAL STANDINGS
                                </div>
                                <div id="finalstandingstopright" className="col s12 m6 right" style={{display:linkdisplay}}>
                                    <div className="right white-fill-link" style={{fontSize:'14px',marginRight:'0px',marginTop:'-5px'}} title="Go Back To Upcoming Races" onClick={() => goToRacesHome() }>
                                        <div className="top-nav-header-item-bubble">
                                            <div className="bubble-button"></div>
                                        </div>
                                        <div className="top-nav-header-item-text">
                                            Raceway
                                        </div>
                                    </div>
                                    <div className="right white-fill-link" style={{fontSize:'14px',marginRight:'10px',marginTop:'-5px'}} title="Go Back To Upcoming Races" onClick={() => goToRaceResults() }>
                                        <div className="top-nav-header-item-bubble">
                                            <div className="bubble-button"></div>
                                        </div>
                                        <div className="top-nav-header-item-text">
                                            Full Results
                                        </div>
                                    </div>
                                    <div className="right white-fill-link" style={{fontSize:'14px',marginRight:'10px',marginTop:'-5px'}} title="Watch The Race Again" onClick={() => rewatchRace() }>
                                        <div className="top-nav-header-item-bubble">
                                            <div className="bubble-button"></div>
                                        </div>
                                        <div className="top-nav-header-item-text">
                                            Rewatch
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {race_table_html}
                        </div>
                    </div>
                </div>
        </div>);
};