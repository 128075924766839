import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals, tokenGate721, tokenGate1155} from "../../../utils";
import "../RacesHome/races_home.css";
import $ from "jquery";

let total_page_results = 25;
let current_racer_pagenum = 0;
let current_racer_timeseries = 'all-time';
let current_racer_sort = 'rpm';
let current_prophet_pagenum = 0;
let current_prophet_timeseries = 'all-time';
let current_prophet_sort = 'xp';
let racerSectionHTMLArray = [];
let prophetSectionHTMLArray = [];
let unlockSectionHTMLArray = [];
let overallSummaryList = [];
let gameUnlocksList = [];

export const TrophyCase = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [completedTrainingHTML, setSummarySectionHTML] = useState('');
    const [showMoreTrainingsHTML, setShowMoreRacersHTML] = useState('');
    const [rewardsSectionHTML, setProphetSectionHTML] = useState('');
    const [showMoreRewardsHTML, setShowMoreProphetsHTML] = useState('');
    const [unlocksHTML, setUnlocksSectionHTML] = useState('');
    const [showMoreUnlocksHTML, setShowMoreUnlocksHTML] = useState('');
    const [rewards_media_content,setRewardsMediaContent] = useState('');
    const [racerTimeSeries, setRacerTimeSeries] = useState('all-time');
    const [prophetTimeSeries, setProphetTimeSeries] = useState('all-time');
    const [invite_racer_name, setInviteRacerName] = useState('');
    const [user_game_points, setUserGamePoints] = useState('');
    const [user_prophet_points, setUserProphetPoints] = useState('');
    const [user_race_wins, setUserRaceWins] = useState('');
    const [user_correct_picks, setUserCorectPicks] = useState('');
    const [user_referral_points,setReferralPoints] = useState('');

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    async function checkGameUnlocks(unlockid) {
        // Setup List of Game Unlocks to Check - Check All Unlocked If Lock ID is 0
        let unlockCheckList = [];
        if (unlockid > 0) {
            for(var i = 0; i < gameUnlocksList.length;i++ ) {
                if(gameUnlocksList[i]['game_unlock_id'] == unlockid) {
                    unlockCheckList.push(gameUnlocksList[i]);
                }
            }
        } else {
            for(var i = 0; i < gameUnlocksList.length;i++ ) {
                if(gameUnlocksList[i]['user_unlocked'] == 0) {
                    unlockCheckList.push(gameUnlocksList[i]);
                }
            }
        }

        // Check Game Unlocks List Available
        for(var z = 0; z < unlockCheckList.length;z++) {
            let unlock_id = unlockCheckList[z]['game_unlock_id'];
            let whitelist_keys = unlockCheckList[z]['whitelist_keys'];
            let unlock_keys = unlockCheckList[z]['unlock_keys'];
            let unlock_found = 0;

            // Check Whitelist Unlocks First 
            for(var j = 0; j < whitelist_keys.length;j++) {
                let whitelist_type = whitelist_keys[j];
                let whitelist_value = '';
                if(whitelist_type == 'whitelist_wallet') {
                    whitelist_value = accountAddress;
                } else if(whitelist_type == 'whitelist_user') {
                    whitelist_value = userID;
                }
                if(whitelist_value.length > 0) {
                    let unlock_status = await backendGameUnlocks(unlock_id,whitelist_type,whitelist_value,'check');
                    if(unlock_status == 'success') {
                        j = whitelist_keys.length;
                        unlock_found = 1;
                    }
                }
            }

            // If No Whitelist Unlocks, Check Token Unlocks
            if((unlock_found == 0)) {
                for(var j = 0; j < unlock_keys.length;j++) {
                    let unlock_type = unlock_keys[j]['key_type'];
                    let unlock_options = unlock_keys[j]['key_options'];
                    if(unlock_options == null) {
                        unlock_options = [];
                    }
                    for(var m = 0; m < unlock_options.length;m++) {
                        let unlock_value1 = unlock_options[m]['key_value'];
                        let unlock_value2 = unlock_options[m]['key_value2'];
                        let unlock_chain = unlock_options[m]['chain_id'];
                        let unlock_token_type = unlock_options[m]['token_type'];

                        if(unlock_type == 'token_contract') {

                            // Check For Token Type
                            if (unlock_token_type == 'erc1155') {
                                const token_result = await tokenGate1155(unlock_value1, accountAddress, unlock_value2, unlock_chain);
                                if(token_result === true) {
                                    let unlock_status = await backendGameUnlocks(unlock_id,unlock_type,unlock_value1,'checked');
                                    m = unlock_options.length;
                                    j = unlock_keys.length;
                                    unlock_found = 1;
                                }
                            } else if (unlock_token_type == 'erc721') {
                                const token_result = await tokenGate721(unlock_value1, accountAddress, unlock_chain);
                                if(token_result === true) {
                                    let unlock_status = await backendGameUnlocks(unlock_id,unlock_type,unlock_value1,'checked');
                                    m = unlock_options.length;
                                    j = unlock_keys.length;
                                    unlock_found = 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        // Update Game Unlocks Box with Any New Unlocks
        grabGameUnlocksUser('load');
    }

   async function backendGameUnlocks(unlockID,keyType,keyValue,keyStatus) {
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.unlockID = encodeURIComponent(unlockID);
        addData.keyType = encodeURIComponent(keyType);
        addData.keyValue = encodeURIComponent(keyValue);
        addData.keyStatus = encodeURIComponent(keyStatus);
        
        var unlockresult = await axiosInstance.post('member/game_unlocks_check/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var unlock_details = result.data; 
                return unlock_details[0]['unlock_status'];
            }
        }).catch(error => {
            console.log(error);
        });

        return unlockresult;
    }

    function grabGameUnlocksUser(pulltype) {
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);

        axiosInstance.post('member/game_unlocks_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var unlock_details = result.data; 
                gameUnlocksList = unlock_details;
                if(pulltype == 'initial') {
                    checkGameUnlocks(0);
                } else {
                    loadUnlocksList(gameUnlocksList,0);
                }                
            }
        }).catch(error => {
            console.log(error);
        });

        //0xcb3c20aD512A4f135f0633b8683985391C0938b5 - TrainingNFT - 721
        // var contractAddress = '0xcb3c20aD512A4f135f0633b8683985391C0938b5';
        // var userAddress = '0x82998Ed7e475Ef3920749d1788dEab8Ca20e2998';
        // var chainId = '1284';
        // var tokenId = '';
        // checkTokenGating('721',contractAddress,userAddress,chainId,tokenId);
    }

    function grabOverallResultsSummaryUser() {

        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('member/overall_results_summary/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var summary_details = result.data; 

                var user_referrals = summary_details[0]['total_referrals'];
                if(user_referrals > 3) {
                    user_referrals = 3;
                }

                var race_rpm = summary_details[0]['racer_rpm'];
                var race_xp = summary_details[0]['racer_xp'];
                var racer_race_points = 5*race_rpm + race_xp;


                var race_races = summary_details[0]['total_races'];
                var race_wins = summary_details[0]['total_wins'];
				var prophet_xp = summary_details[0]['prophet_xp'];
                var race_predictions = summary_details[0]['total_predictions'];
                var correct_predictions = summary_details[0]['total_correct_predictions'];

                setUserGamePoints(racer_race_points);
                setUserProphetPoints(prophet_xp);
                setUserRaceWins(race_wins+" / "+race_races);
                setUserCorectPicks(correct_predictions+" / "+race_predictions);
                setReferralPoints((user_referrals*15));
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabOverallResultsSummaryList() {
        var addData = new Object();
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('member/rewards_results_summary/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var summary_details = result.data; 
                overallSummaryList = summary_details;
                grabResultsSummaryList(racerTimeSeries,0);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabResultsSummaryList(timeSort,pagenum) {
        current_racer_pagenum = pagenum;
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.collectionID = encodeURIComponent(1);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_racer_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('member/rewards_results_summary/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var summary_details = result.data; 
                loadSummaryList(summary_details,pagenum);
                
                if(pagenum == 0) {
                    //grabProphetLeaderboardLottery(timeSort,pagenum);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabRewardsList(timeSort,pagenum) {
        current_prophet_pagenum = pagenum;
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.collectionID = encodeURIComponent(1);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_prophet_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);
        
        axiosInstance.post('member/rewards_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var rewards_details = result.data; 
                loadRewardsList(rewards_details,pagenum);
                if(pagenum == 0) {
                    //grabProphetLeaderboardLottery(timeSort,pagenum);
                }
            } else {
                loadRewardsList([],pagenum);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function updateRacerLeaderBoardTime(newtime) {
        current_racer_timeseries = newtime;
        setRacerTimeSeries(newtime);
        window.$(".racer-board-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#racer-lb-time-"+newtime).removeClass("white-green-link").addClass("white-green-link-active");
        grabResultsSummaryList(newtime,0);
    }

    function updateProphetBoardTime(newtime) {
        current_prophet_timeseries = newtime;
        setProphetTimeSeries(newtime);
        window.$(".prophet-board-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#prophet-lb-time-"+newtime).removeClass("white-green-link").addClass("white-green-link-active");
        grabRewardsList(newtime,0);
    }

    function updateRacerLeaderBoardSort(newsort) {
        current_racer_sort = newsort;
        window.$(".racer-row-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#racer-lb-sort-"+newsort).removeClass("white-green-link").addClass("white-green-link-active");
        grabResultsSummaryList(racerTimeSeries,0);
    }

    function updateProphetLeaderBoardSort(newsort) {
        current_prophet_sort = newsort;
        window.$(".prophet-row-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-red-link");
        });
        window.$("#prophet-lb-sort-"+newsort).removeClass("white-green-link").addClass("white-green-link-active");
        grabRewardsList(prophetTimeSeries,0);
    }

    function showMoreResultsRacers(pagenum) {
        $("#showmoreracersbutton").hide();
        $("#showmoreracersloading").show();
        grabResultsSummaryList(current_racer_timeseries,pagenum);
    }

    function showMoreResultsProphet(pagenum) {
        $("#showmoreprophetsbutton").hide();
        $("#showmoreprophetsloading").show();
        grabRewardsList(current_prophet_timeseries,pagenum);
    }

    function openInviteModal() {
        var invitebox = document.getElementById('invite-single-box');
        var invite_instance = window.M.Modal.getInstance(invitebox);
        invite_instance.open();
    }

    function inviteToRaceSingle(e) {
        var racer_name = e.target.dataset.invitename;
        window.startPageModals();
        setInviteRacerName(racer_name);
        window.$("#invitesinglesubmit").click(function() {
            window.location.href="/#/race_landing/invite/"+encodeURIComponent(racer_name);
        });
        openInviteModal();
    }

    function loadSummaryList(summaryarr,pagenum) {
        var summary_section_html = [];
        var pilotcnt = 0;
        for(var z = 0; z < summaryarr.length;z++) {
            var session_id = summaryarr[z].race_group_collection_id;
            var session_name = summaryarr[z].race_collection_name;
            var user_referrals = summaryarr[z].total_referrals;
            var user_racing_xp = summaryarr[z].racer_xp;
            var user_racing_rpm = summaryarr[z].racer_rpm;
            var user_total_races = summaryarr[z].total_races;
            var user_total_wins = summaryarr[z].total_wins;
            var user_prophet_xp = summaryarr[z].prophet_xp;
            var user_prophet_rpm = summaryarr[z].prophet_rpm;
            var user_predictions = summaryarr[z].total_predictions;
            var user_predictions_correct = summaryarr[z].total_correct_predictions;
            var draw_completed = summaryarr[z].rewards_draw_completed;
            var total_rewards_redeemed = summaryarr[z].total_rewards_redeemed;
            var total_rewards_unclaimed = summaryarr[z].total_rewards_unclaimed;
            var total_rewards_new = summaryarr[z].total_rewards_new;

            /* Calculate Race Time */
            var user_type = 'Prophet';
            var win_pct = 0;
            if(user_total_races > 0) {
                user_type = 'Racer';
                win_pct = Math.round((user_total_wins / user_total_races)*100);
            }

            var predict_pct = 0;
            if(user_predictions > 0) {
                predict_pct = Math.round((user_predictions_correct / user_predictions)*100);
            }

            var user_racer_points = 5*user_racing_rpm + user_racing_xp;
            if(user_referrals > 3) {
                user_racer_points = user_racer_points + (15*3);
            } else {
                user_racer_points = user_racer_points + (15*user_referrals);
            }

            var rewards_button = <span style={{fontSize:'12px',marginTop:'2px',marginRight:'10px'}} title="Show Rewards">
                                        - RESULTS IN PROGRESS
                                    </span>;
            if(draw_completed == 1) {
                rewards_button = <span className="exr-light-green-text" style={{fontSize:'12px',marginTop:'2px',marginRight:'10px'}} title="Show Rewards">
                                    - REWARDS UNLOCKED
                                </span>;
                if(total_rewards_new > 0) {
                    rewards_button = <span className="right round-border-button" style={{marginLeft:'5px',fontSize:'10px',minHeight:'20px',marginTop:'2px'}} title="Show Rewards" onClick={()=>showRevealRewardsInfobox()}>
                                        UNLOCK REWARDS
                                    </span>

                }
                
            }

            var session_total_racer_points = 0;
            var session_total_prophet_points = 0;
            for(var m = 0; m < overallSummaryList.length;m++) {
                if(overallSummaryList[m].race_group_collection_id == session_id) {
                    session_total_racer_points = 5*overallSummaryList[m].racer_rpm + overallSummaryList[m].racer_xp;
                    session_total_prophet_points = overallSummaryList[m].prophet_xp;
                }
            }

            var racerpointsodds = 0;
            if(session_total_racer_points > 0) {
                racerpointsodds = (Math.round((user_racer_points / session_total_racer_points)*10000)/100);
            }

            var prophetpointsodds = 0;
            if(session_total_prophet_points > 0) {
                prophetpointsodds = (Math.round((user_prophet_xp / session_total_prophet_points)*10000)/100);
            }
            
            

            var session_date = summaryarr[z].end_date_month+' '+ordinal_suffix_of(summaryarr[z].end_date_day)+', '+summaryarr[z].end_date_year;

            var resultview = <div className="row">
                <div className="col s12 l6" style={{padding:'0px 10px',marginTop:'10px',marginBottom:'10px'}}>
                    <div className="row center-align" style={{fontSize:'14px',margin:'0px'}}>
                        RACING RESULTS
                    </div>
                    <div className="row exr-new-light-grey" style={{fontSize:'12px',margin:'0px',padding:'10px 0px',borderRadius:'10px'}}>
                        <div className="col s4 center-align">
                            <span className="exr-yellow-text">{user_total_wins} / {user_total_races}</span><br/>WON
                        </div>
                        <div className="col s4 center-align" title="5xRPM + XP + Referral Bonus">
                            <span className="exr-yellow-text">{user_racer_points.toLocaleString()}</span><br/>PTS
                        </div>
                        <div className="col s4 center-align" title="OTWD: Odds to Win Random Draw Reward Based Off Your Points Won">
                            <span className="exr-yellow-text">{racerpointsodds}%</span><br/>OTWD
                        </div>
                    </div>         
                </div>
                <div className="col s12 l6" style={{padding:'0px 10px',marginTop:'10px',marginBottom:'10px'}}>
                    <div className="row center-align" style={{fontSize:'14px',margin:'0px'}}>
                        PROPHET RESULTS
                    </div>
                    <div className="row exr-new-light-grey" style={{fontSize:'12px',margin:'0px',padding:'10px 0px',borderRadius:'10px'}}>
                        <div className="col s4 center-align">
                            <span className="exr-yellow-text">{user_predictions_correct} / {user_predictions}</span><br/>WON
                        </div>
                        <div className="col s4 center-align" title="XP Won By Predicting">
                            <span className="exr-yellow-text">{user_prophet_xp.toLocaleString()}</span><br/>PTS
                        </div>
                        <div className="col s4 center-align" title="OTWD: Odds to Win Random Draw Reward Based Off Your Points Won">
                            <span className="exr-yellow-text">{prophetpointsodds}%</span><br/>OTWD
                        </div>
                    </div>
                </div>
            </div>


            var rowkey = z+1+(pagenum*total_page_results);
            var tmp_html = <div key={rowkey} className="col s12 l6" id={'leader_row_'+session_id} style={{margin:'0px',marginTop:'10px',padding:'5px'}}>
                            <div className="row exr-dark-grey-bg" style={{borderRadius:'10px',margin:'0px',padding:'10px 5px 0px'}} >
                                <div className="col s12">
                                    <div className="row center-align" style={{margin:'0px',marginTop:'5px',marginBottom:'0px'}}>
                                        <div className="headline-text center-align" style={{fontSize:"16px",marginTop:"5px"}}>
                                            {session_name.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className="row center-align" style={{margin:'0px'}}>
                                        <span style={{display:'table',margin:'0 auto'}}>
                                        <span style={{fontSize:'12px',marginTop:'2px',marginRight:'5px'}} title="Show Rewards">
                                            {session_date.toUpperCase()}
                                        </span>
                                            {rewards_button}
                                        </span>
                                    </div>
                                </div>
                                <div className="col s12" style={{marginTop:"10px"}}>
                                    {resultview}
                                </div>
                            </div>
                        </div>;
            summary_section_html.push(tmp_html);
            
        }

        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = racerSectionHTMLArray.concat(summary_section_html);
        } else {
            final_section_html_array = summary_section_html;
        }
        racerSectionHTMLArray = final_section_html_array;

        if(final_section_html_array.length == 0) {
            var tmp_html = <div key={rowkey} className="col s12" style={{cursor:'auto',marginTop:'10px',padding:'5px'}}>
                                <div className="row" style={{padding:'10px','margin':'0px',borderRadius:'10px',height:'25vh'}}>
                                    No Completed Racing Sessions. Check The EXR Twitter and Tune Into Discord To Find Out About The Next Session.
                                </div>
                        </div>;
            summary_section_html.push(tmp_html);
            setSummarySectionHTML(summary_section_html);
        } else {
            setSummarySectionHTML(final_section_html_array);
        }

        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(summary_section_html.length == total_page_results) {
            showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                <div id="showmoreracersbutton" className="center-align" style={{display:'table',margin:'0 auto'}} title="Show More Racers">
                                    <span className="round-border-button" style={{fontSize:'16px'}} title="Show More Racers"  onClick={() => showMoreResultsRacers(nextpage) }>
                                        SHOW MORE
                                    </span>
                                </div>
                                <div id="showmoreracersloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreRacersHTML(showmorebutton);
        
        setTimeout(function(){
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },500);
        setTimeout(function(){
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },2000);
    }


    function loadUnlocksList(unlockarr,pagenum) {
        var unlock_section_html = [];
        var unlockcnt = 0;
        for(var z = 0; z < unlockarr.length;z++) {

            let unlock_id = unlockarr[z]['game_unlock_id'];
            let private_flag = unlockarr[z]['private_flag'];
            let unlock_badge_url = unlockarr[z]['unlock_badge_url'];
            let unlock_description = unlockarr[z]['unlock_description'];
            let unlock_image_url = unlockarr[z]['unlock_image_url'];
            let unlock_method = unlockarr[z]['unlock_method'];
            let unlock_title = unlockarr[z]['unlock_title'];
            let user_unlocked = unlockarr[z]['user_unlocked'];
            let unlock_text = <span className="exr-light-green-text">Unlocked</span>
            if(user_unlocked == 0) {
                unlock_text = <span className="white-text">Not Unlocked</span>
            }

            let unlock_method_text = 'Whitelist';
            if(unlock_method.indexOf('token') >= 0) {
                unlock_method_text = 'Token Holder';
            }
            let backgroundimage = "url('"+unlock_image_url+"')";

            var rowkey = z+1;
            var tmp_html = <div key={rowkey} className="col s12" style={{margin:'0px',marginTop:'10px',padding:'5px'}}>
                            <div className="row exr-dark-grey-bg" style={{display: 'flex',flexDirection: 'row',borderRadius:'10px',margin:'0px',padding:'10px 0px'}} >
                                <div style={{width:'150px',marginTop:"0px",margin:'0px 10px'}}>
                                    <div className="exr-new-light-grey center-align" style={{width:'100%',height:'150px',borderRadius:'10px',background:backgroundimage,backgroundSize:'cover',backgroundPosition:'center'}}>
                                        {/* <img src={unlock_image_url} height="100%" /> */}
                                    </div>
                                </div>
                                <div style={{flex:'1',marginTop:"0px",padding:"0px 5px",margin:'0px 5px'}}>
                                    <div className="row" style={{margin:'0px',marginTop:'5px',marginBottom:'0px'}}>
                                        <div className="headline-text" style={{fontSize:"14px",marginTop:"5px"}}>
                                            {unlock_title.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className="row" style={{margin:'0px',fontSize:'12px'}}>
                                        Status: {unlock_text} // {unlock_method_text}
                                    </div>
                                    <div className="row" style={{margin:'0px',marginTop:'15px',fontSize:'12px',paddingRight:'5px'}}>
                                        {unlock_description}
                                    </div>
                                </div>
                            </div>
                        </div>;
            
            unlock_section_html.push(tmp_html);
        }

        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = unlockSectionHTMLArray.concat(unlock_section_html);
        } else {
            final_section_html_array = unlock_section_html;
        }
        unlockSectionHTMLArray = final_section_html_array;

        if(final_section_html_array.length == 0) {
            var tmp_html = <div key={rowkey} className="col s12" style={{cursor:'auto',marginTop:'10px',padding:'5px'}}>
                                <div className="row" style={{padding:'10px','margin':'0px',borderRadius:'10px',height:'25vh'}}>
                                    No Active Unlocks. Check The EXR Twitter and Tune Into Discord To Find Out About The Next Unlocks.
                                </div>
                        </div>;
            unlock_section_html.push(tmp_html);
            setUnlocksSectionHTML(unlock_section_html);
        } else {
            setUnlocksSectionHTML(final_section_html_array);
        }

        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(unlock_section_html.length == total_page_results) {
            showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                <div id="showmoreunlocksbutton" className="center-align" style={{display:'table',margin:'0 auto'}} title="Show More Unlocks">
                                    <span className="round-border-button" style={{fontSize:'16px'}} title="Show More Racers"  onClick={() => showMoreResultsRacers(nextpage) }>
                                        SHOW MORE
                                    </span>
                                </div>
                                <div id="showmoreracersloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreUnlocksHTML(showmorebutton);
        
        // setTimeout(function(){
        //     $("#showmoreracersbutton").show();
        //     $("#showmoreracersloading").hide();
        //     $("#showmoreprophetsbutton").show();
        //     $("#showmoreprophetsloading").hide();
        // },500);
        // setTimeout(function(){
        //     $("#showmoreracersbutton").show();
        //     $("#showmoreracersloading").hide();
        //     $("#showmoreprophetsbutton").show();
        //     $("#showmoreprophetsloading").hide();
        // },2000);

        setTimeout(function() {
            resizeRewardsBoxes();
        },1000);
    }

    function loadRewardsList(itemarr,pagenum) {
        
        

        var ranking_section_html = [];
        var pilotcnt = 0;
        for(var z = 0; z < itemarr.length;z++) {
            var reward_id = itemarr[z].reward_id;
            var reward_type = itemarr[z].reward_type;
            var reward_title = itemarr[z].reward_title;
            var reward_reason = itemarr[z].reward_reason;
            var redeemed_flag = itemarr[z].redeemed_flag;
            var reward_image_url = itemarr[z].reward_image_url;
            var reward_video_url = itemarr[z].reward_video_url;

            var reward_image = '';
            var reward_video = '';
            if(reward_type == 'pilot_key') {
                var reward_image_bg = "url('https://media.exiledracers.com/global/mint_images/exr_pilot_mint_key_spinning.gif')";
                reward_image = <div className="reward-item-nft" style={{height:'auto',width:'100%',marginTop:'10px',marginBottom:'5px',borderRadius:'10px',background:reward_image_bg,backgroundSize:'cover',backgroundPosition:'center'}} />
            } else if (reward_type == 'booster_key') {
                var reward_image_bg = "url('https://media.exiledracers.com/global/mint_images/exr_booster_mint_key_spinning.gif')";
                reward_image = <div className="reward-item-nft" style={{height:'auto',width:'100%',marginTop:'10px',marginBottom:'5px',borderRadius:'10px',background:reward_image_bg,backgroundSize:'cover',backgroundPosition:'center'}} />
            } else if(reward_image_url.length > 0) {
                reward_image = <img src={reward_image_url} width="100%" style={{'marginTop':'10px',borderRadius:'10px',cursor:'zoom-in'}} onClick={(e)=>openRewardImage(e)} />
            }
            
            var video_play_button = '';
            if(reward_video_url.length > 0) {
                if(reward_image_url.length > 0) {
                    reward_image = <img title="View Video" className="reward-video-nft" src={reward_image_url+"?155"} data-videourl={reward_video_url} width="100%" 
                    style={{height:'auto','marginTop':'10px',borderRadius:'10px',cursor:'pointer'}} onClick={(e)=>openRewardVideo(e)} />
                    video_play_button = <span title="View Video" class="material-symbols-outlined white-text" 
                                            style={{position:'absolute',bottom:'30%',left:'0%',width:'100%',textAlign:'center',cursor:'pointer',fontSize:'60px'}}
                                            data-videourl={reward_video_url} onClick={(e)=>openRewardVideo(e)} >play_circle</span>
                } else {
                    reward_image = <video className="reward-video-nft" src={reward_video_url+"#t=0.9"} data-videourl={reward_video_url} width="100%" style={{height:'auto','marginTop':'10px',borderRadius:'10px'}} controls="true" />
                }
            }

            var actionbutton = <span className="round-border-button" style={{fontSize:'14px'}} title="Claim Item" onClick={()=>showClaimItemInfobox()}>
                                    CLAIM ITEM
                                </span>;
             if (redeemed_flag == 1){
                actionbutton = <span className="round-border-button-green" style={{fontSize:'14px'}} title="Item Airdropped to The Wallet On Your Account">
                    ITEM AIRDROPPED
                </span>
            } else if(reward_type.indexOf("_collectible") >= 0) {
                actionbutton = <span className="round-border-button" style={{fontSize:'14px'}} title="Claim Item" onClick={()=>showClaimItemInfobox()}>
                    CLAIM ITEM
                </span>
            }
            
            var rowkey = z+1+(pagenum*total_page_results);
            var tmp_html = <div key={rowkey} className="col s12 m6 l3" style={{cursor:'auto',marginTop:'10px',padding:'5px'}}>
                                <div className="row exr-dark-grey-bg" style={{padding:'0px','margin':'0px',borderRadius:'10px'}}>
                                <div className="col s12 reward-image-shell" style={{overflow:'hidden',position:'relative',height:'418px',borderRadius:'10px'}}>
                                    {reward_image}
                                    {video_play_button}
                                </div>
                                <div className="col s12 center-align" style={{overflow:'hidden'}}>
                                    <div className="row headline-text" style={{'marginTop':'5px',marginBottom:'0px'}}>
                                        {reward_title.toUpperCase()}
                                    </div>
                                    <div className="row">
                                        {reward_reason}
                                    </div>
                                    <div className="row">
                                        <span style={{display:'table',margin:'0 auto'}}>
                                        {actionbutton}
                                        </span>
                                    </div>
                                </div>
                                </div>
                        </div>;
            ranking_section_html.push(tmp_html);
            
        }

        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = prophetSectionHTMLArray.concat(ranking_section_html);
        } else {
            final_section_html_array = ranking_section_html;
        }
        prophetSectionHTMLArray = final_section_html_array;

        if(final_section_html_array.length == 0) {
            var tmp_html = <div key={rowkey} className="col s12" style={{cursor:'auto',marginTop:'10px',padding:'5px'}}>
                                <div className="row" style={{padding:'10px','margin':'0px',borderRadius:'10px',height:'25vh'}}>
                                    No Active Rewards. Check Your Completed Sessions to Unlock Rewards and Find More Information.
                                </div>
                        </div>;
            ranking_section_html.push(tmp_html);
            setProphetSectionHTML(ranking_section_html);
        } else {
            setProphetSectionHTML(final_section_html_array);
        }
        
        
        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(itemarr.length >= total_page_results) {
            showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                <div id="showmoreprophetsbutton" className="center-align" style={{display:'table',margin:'0 auto'}} title="Show More Prophets">
                                    <span className="round-border-button" style={{fontSize:'16px'}} title="Show More Prophets"  onClick={() => showMoreResultsProphet(nextpage) }>
                                        SHOW MORE
                                    </span>
                                </div>
                                <div id="showmoreprophetsloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreProphetsHTML(showmorebutton);
        
        setTimeout(function(){
            resizeRewardsBoxes();
            // $("#showmoreracersbutton").show();
            // $("#showmoreracersloading").hide();
            // $("#showmoreprophetsbutton").show();
            // $("#showmoreprophetsloading").hide();
            
        },500);
        setTimeout(function(){
            resizeRewardsBoxes();
            // $("#showmoreracersbutton").show();
            // $("#showmoreracersloading").hide();
            // $("#showmoreprophetsbutton").show();
            // $("#showmoreprophetsloading").hide();
            
        },2000);
    }

    window.addEventListener('resize', function(){
        resizeRewardsBoxes();
    });

    function openRewardImage(e) {
        e.preventDefault();
        var image_url = e.target.src;
        window.open(image_url);
        // var image_element = <img src={image_url} width="100%" style={{padding:'0px',margin:'0px',borderRadius:'10px'}} />
        // setRewardsMediaContent(image_element);

        // setTimeout(function(){
        //     var infobox = document.getElementById('rewards_content_show');
        //     var info_instance = window.M.Modal.getInstance(infobox);
        //     info_instance.open();
        // },1000);
    }

    function openRewardVideo(e) {
        e.preventDefault();
        var video_url = e.target.dataset.videourl;
        var image_url = e.target.src;
        //var video_element = <video id="rewards-video" src={video_url+"#t=0.9"} controls="true" style={{padding:'0px',margin:'0px',borderRadius:'10px'}} />
        //video_element = video_url+"#t=0.9";
        //setRewardsMediaContent(video_element);
        //video_url = 'https://media.exiledracers.com/assets/passes/exr_track_meet_collectible_nft.mp4';
        $("#rewards-video").attr("src",video_url+"#t=0.9");

        var infobox = document.getElementById('rewards_content_show');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.destroy();
        
        setTimeout(function(){
            var infobox = document.getElementById('rewards_content_show');
            var info_instance = window.M.Modal.init(infobox,{'opacity':0.75,onOpenStart: function(){
                if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                    $(".modal").css({'width':'95%'});
                } else {
                    $(".modal").css({'width':'50%'});
                }
            }, onCloseEnd: function(){
                var current_video = document.getElementById('rewards-video');
                current_video.src = '';
                console.log(infobox);
            }});
            
            var info_instance = window.M.Modal.getInstance(infobox);
            info_instance.open();
            updateVideoSize();
        },1000);
        
    }

    function updateVideoSize() {
        var current_video = document.getElementById('rewards-video');
        var video_orientation = 'H';
        if(current_video.videoHeight >= current_video.videoWidth) {
            video_orientation = 'V';
        }

        if(video_orientation == 'V') {
            $("#rewards_content_show").addClass("page-modal-media-vertical");
            $("#rewards-video").css({height:'80vh',width:'auto'});
        } else {
            $("#rewards_content_show").removeClass("page-modal-media-vertical");
            $("#rewards-video").css({height:'auto',width:'100%'});
        }
    }

    

    function unlockRaceRewards() {
        $("#checkrewardsbutton").hide();
        $("#checkrewardsloading").show();
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('member/rewards_unlock/',addData).then(result => {
            setTimeout(function(){
                window.location.reload();
            },2000);
        }).catch(error => {
            console.log(error);
        });
    }

    function resizeRewardsBoxes() {
        var unlockheight = $("#unlock-section").innerHeight();
        var sessionheight = $("#session-section").innerHeight();
        if(window.innerWidth < 900) {
            $("#unlock-section").css({'height':'auto'});
            $("#session-section").css({'height':'auto'});
        } else {
            if(unlockheight < sessionheight) {
                $("#unlock-section").css({'height':sessionheight+'px'});
            } else {
                $("#session-section").css({'height':unlockheight+'px'});
            }
        }

        var maxheight = 0;
        $(".reward-image-shell").each(function() {
            if($(this).height() > maxheight) {
                maxheight = $(this).height();
            }
        });

        if (maxheight > 435) {
            maxheight = 435;
        }

        $(".reward-item-nft").each(function() {
            $(this).css({'height':maxheight-17+'px'});
        });

        // $(".reward-video-nft").each(function() {
        //     $(this).css({'height':maxheight-17+'px'});
        // });
    }

    function showClaimItemInfobox() {
        var infobox = document.getElementById('training_box_claim_item');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    function showRevealRewardsInfobox() {
        var infobox = document.getElementById('training_box_rewards_show');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    function showLeaderboardInfobox() {
        var infobox = document.getElementById('training_box_leaderboard');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    window.$(document).ready(function(){
        var sel_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(sel_elems);
    });

    if(entrySet == false) {
        setEntry(true);
        grabOverallResultsSummaryList();
        grabRewardsList(prophetTimeSeries,0);
        grabOverallResultsSummaryUser();
        grabGameUnlocksUser('initial');
    }

    function goToWorkshop() {
        window.location.href= '/#/workshop';
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row white-text page-width-wide top-nav-header-full">
            <div className="row" style={{margin:'0px',borderRadius:'10px',padding:'0px 0px 0px 0px',marginBottom:'10px'}}>
                <div className="col s12 exr-new-dark-grey" style={{position:'relative',fontSize:'30px',padding:'10px 0px',paddingTop:'0px',borderRadius:'10px',overflow:'hidden',marginBottom:'0px'}}>
                    <div className="row" style={{margin:'0px'}}>
                        <div className="col s12 center-align" style={{margin:'0px',padding:'5px 30px'}}>
                            <span className="left exr-light-green-text" style={{width:'100%',fontSize:"16px",marginTop:'5px'}}>RACER & PROPHET</span>
                            <span className="left headline-text" style={{width:'100%',fontSize:"24px",marginTop:'0px'}}>REWARDS & ACHIEVEMENTS</span>
                        </div>
                        <div className="col s12" style={{margin:'0px',padding:'0px 10px'}}>
                            <div className="row exr-new-light-grey" style={{margin:'0px',padding:'10px',borderRadius:'10px',fontSize:'14px'}}>
                                <div className="col s6 m2 center-align" style={{margin:'0px',padding:'5px 10px'}}>
                                    RACES: {user_race_wins.toLocaleString()} WINS
                                </div>
                                <div className="col s6 m2 center-align" style={{margin:'0px',padding:'5px 10px'}}>
                                    RACING TOTAL: {user_game_points.toLocaleString()} POINTS
                                </div>
                                <div className="col s6 m3 center-align" style={{margin:'0px',padding:'5px 10px'}}>
                                    PICKS: {user_correct_picks.toLocaleString()} CORRECT
                                </div>
                                <div className="col s6 m2 center-align" style={{margin:'0px',padding:'5px 10px'}}>
                                    PROPHET TOTAL: {user_prophet_points.toLocaleString()} POINTS
                                </div>
                                <div className="col s12 m3 center-align" style={{margin:'0px',padding:'5px 10px'}}>
                                    REFERRALS: {user_referral_points.toLocaleString()} XP BONUS 
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="material-icons material-symbols-outlined white-red-link" 
                    style={{position:'absolute',right:'20px',top:'10px',fontSize:"30px",marginLeft:"10px",marginTop:'4px'}} 
                    onClick={()=>showLeaderboardInfobox()} 
                    title="Learn About The EXR Leaderboards">info</span>
                </div>
            </div>
            <div className="col s12">
                <div className="row" style={{marginTop:'0px',marginBottom:'0px',fontSize:'16px'}}>
                    <div className="col s12 l8" style={{padding:'5px'}}>
                        <div id="session-section" className="row exr-new-dark-grey" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div className="col s12 l8 headline-text white-text left-align" style={{marginTop:'0px'}}>
                                    COMPLETED SESSIONS
                                </div>
                                <div className="col s12 l4 white-text right-align" style={{fontSize:'14px',display:'none'}}>
                                    
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            {completedTrainingHTML}
                            <div className="col s12">
                                {showMoreTrainingsHTML}
                            </div>
                        </div>
                    </div>
                    <div className="col s12 l4" style={{padding:'5px'}}>
                        <div id="unlock-section" className="row exr-new-dark-grey" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div className="col s12 headline-text white-text left-align" style={{marginTop:'0px'}}>
                                    GAME UNLOCKS
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            {unlocksHTML}
                            <div className="col s12">
                                {showMoreUnlocksHTML}
                            </div>
                        </div>
                    </div>
                    <div className="col s12" style={{padding:'0px'}}>
                        <div className="row  exr-new-dark-grey" style={{margin:'0px',marginBottom:'0px',padding:'20px',borderRadius:'10px'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div className="col s12 m4 headline-text white-text left-align" style={{marginTop:'0px'}}>
                                    REWARDS & BADGES
                                </div>
                                <div className="col s12 m8 white-text right-align" style={{fontSize:'14px',display:'none'}}>
                                    
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            {rewardsSectionHTML}
                            <div className="col s12">
                                {showMoreRewardsHTML}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="training_box_leaderboard" className="modal page-modal page-modal-large">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                            REWARDS & ACHIEVEMENTS
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                            Welcome to the EXR Achievements! Here you can find the stats from your recent races, tournaments and leagues you participated in and the rewards you earned. 
                            <br/><br/><a className="exr-light-green-text">Winner Rewards: </a> 
                            Race sessions, tournaments and leagues can award the top winners automatic rewards. Check the description on your completion badge 
                            to see how many winners are automatically awarded prizes.
                            <br/><br/><a className="exr-light-green-text">Random Draw Prizes: </a> 
                            Certain race sessions, tournaments and leagues may offer prizes to racers and prophets based on a points or non-points random draw.<br/><br/>
                            Racers points draws use a combination of RPM and XP for points while the Prophets points draws use the XP won for points. 
                            Non-points draws give everyone an equal chance of winning. <br/><br/>
                            For all draws, random numbers are pulled on-chain and winners are published on-chain.
                        </div>
                </div>
            </div>
        </div>
        <div id="training_box_claim_item" className="modal page-modal page-modal-medium">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                            CLAIM REWARD ITEM
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                        We'll be airdropping this item over to the wallet address listed on your account soon. Check back later or reach out on Discord to find out more on the status of this reward.
                        <br/><br/>
                        <a className="exr-light-green-text">Note: </a>     
                        If you do not 
                            have a wallet setup in your account and you want to claim a reward or on-chain version of a racing collectible, reach out to us on Discord for help.
                            <br/><br/>
                        <a className="exr-light-green-text">Racing Collectibles: </a> <br/>
                            Racing Collectibles are memorabilia that you earn after completing a racing session, tournament or league. Collect them all as they will be used to unlock different features of the game in the future and for bragging rights.
                            <br/><br/>
                        
                    </div>
                </div>
            </div>
        </div>
        <div id="training_box_rewards_show" className="modal page-modal page-modal-medium">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                            RACE REWARDS
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                            Unlock the new race rewards that you have collected from recent race trainings, sessions and tournaments. Rewards collected from racing can be anything from 
                            Mint Keys, to Racing Collectibles to Rare Prizes. Check to see if you received any new rewards from your recent racing sessions.
                        <br/><br/>
                    </div>
                    <div className="col s12 subheadline-text right-align" style={{marginTop:'20px',fontSize:'16px'}}>
                        <span id="checkrewardsbutton" className="right round-border-button" style={{fontSize:'16px'}} title="Show Rewards" onClick={()=>unlockRaceRewards()}>
                            CHECK FOR REWARDS
                        </span>
                        <div id="checkrewardsloading" className="right preloader-wrapper small active" style={{marginTop: '-8px',display:'none'}}>
                            <div className="spinner-layer spinner-red-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="rewards_content_show" className="modal page-modal page-modal-media center" style={{lineHeight:'0',borderRadius:'10px',overflow:'hidden'}}>
            <video id="rewards-video" controls="true" style={{padding:'0px',margin:'0px',borderRadius:'10px'}} />
        </div>
    </div>);
};