import {Audio} from "../../audio/audio.js";

export class TrackPropPassAudioTrigger {
    static COOL_DOWN = 160;

    #position;
    #radius;
    #threshold;
    #coolDown = TrackPropPassAudioTrigger.COOL_DOWN;

    /**
     * Construct a pass audio trigger
     * @param {THREE.Vector3} position The position of the trigger
     * @param {number} radius The radius
     * @param {number} threshold The trigger threshold
     */
    constructor(position, radius, threshold = 1) {
        this.#position = position;
        this.#radius = radius;
        this.#threshold = threshold;
    }

    /**
     * Play the sound
     * @param {Audio} audio The audio object
     */
    play(audio) {
        const sound = audio.getAudio(Audio.SFX_PASS_BY);
        const id = sound.play();

        sound.pos(this.#position.x, this.#position.y, this.#position.z, id);
        sound.orientation(0, 1, 0, id);
        sound.pannerAttr({
            refDistance: 15,
            rolloffFactor: 2
        }, id);

        this.#coolDown = TrackPropPassAudioTrigger.COOL_DOWN;
    }

    /**
     * Update the trigger state
     * @param {THREE.Vector3} position The camera position
     * @param {THREE.Vector3} velocity The camera velocity
     * @param {Audio} audio The audio object
     */
    update(position, velocity, audio) {
        if (this.#coolDown !== 0) {
            --this.#coolDown;

            return;
        }

        const dx = this.#position.x - position.x;
        const dy = this.#position.y - position.y;
        const dz = this.#position.z - position.z;
        const d2 = dx * dx + dy * dy + dz * dz;

        if (d2 < this.#radius * this.#radius) {
            const id = 1 / Math.sqrt(d2);

            const vx = velocity.x * dx * id;
            const vy = velocity.y * dy * id;
            const vz = velocity.z * dz * id;

            if (vx * vx + vy * vy + vz * vz > this.#threshold * this.#threshold)
                this.play(audio);
        }
    }
}