import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import "./single_signon_flow.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation, signupLoading } from "../../../redux/App/actions";
import { setUserSignOn, setLoginWallet } from "../../../redux/Account/actions";
import {axiosInstance, encryptLoginData, getIndexerUserTokens} from "../../../utils"
import {LoginBox} from "../../Organisms"
import { NoToneMapping } from "three";
import {InjectedConnector, } from "@web3-react/injected-connector";
import $ from "jquery";

import RacerContract from "../../../helpers/RacerContract";
import PilotContract from "../../../helpers/PilotContract";
import BoosterContract from "../../../helpers/BoosterContract";

const MAINNET_CHAIN_ID = 1;
const ROPSTEN = 3;
const RINKEBY = 4;
const KOVAN = 42;
const MOONBASE = 1287;
const HARDHAT_ID = 31337;
const MOONBEAM = 1284;
const ETHERIUM = 1;
const BINANCE = 56;
const POLYGON = 137;
const ARBITRUM = 42161;
const AVALANCHE = 43114;

const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: [
    MAINNET_CHAIN_ID,
    HARDHAT_ID,
    ROPSTEN,
    RINKEBY,
    KOVAN,
    MOONBASE,
    MOONBEAM,
    ETHERIUM,
    BINANCE,
    POLYGON,
    ARBITRUM,
    AVALANCHE
  ],
});

let web3;
let racer_wallet_address = '';
let racer_wallet_type = '';
let pilotContract;
let racerContract;
let boosterContract;
let ext_account_id = "";
let total_pilots_sync = 0;
let total_racecrafts_sync = 0;
let total_boosters_sync = 0;
let pilots_checked = 0;
let racecrafts_checked = 0;
let boosters_checked = 0;
let signup_racer = 'spectator';
let signup_user_id = 0;
let referred_by = '';

export const SingleSignonFlow = (props) => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { userID, racerID, spectatorID, loginToken, loginTime } = useSelector((state) => state.account);
    const { active, chainId, library, error, account, deactivate, activate } = useWeb3React();

    const [pageSet, setPage] = useState(false);
    const [racer_handle, setRacerHandle] = useState('');
    const [racer_email, setRacerEmail] = useState('');
    const [racer_metamask,setRacerMetamask] = useState('');
    const [racer_talisman,setRacerTalisman] = useState('');
    const [get_started_text,setGetStartedText] = useState('GET STARTED ON EXR');
    const [tournament_register,setTournamentRegister] = useState(0);
    const [tournament_title,setTournamentTitle] = useState('EXR Cup');
    const [tournament_time_text,setTournamentTimeText] = useState('soon');
    const [racer_wallet,setRacerWallet] = useState('');
    const [referred_by_state,setReferredBy] = useState('');
    const [racer_code, setRacerEmailCode] = useState('');
    const [twitter_handle, setRacerTwitter] = useState('');
    const [discord_handle, setRacerDiscord] = useState('');
    const [start_email, setStartRacerEmail] = useState('');
    const [start_handle, setStartRacerHandle] = useState('');
    const [start_discord, setStartRacerDiscord] = useState('');

    let bg_image_side = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
    let bg_image = 'https://media.exiledracers.com/assets/game/builder/Splash_Background_-_Serf_Centered_1_1665676555319.jpeg';
    //bg_image = 'https://media.exiledracers.com/global/exr_harumi_cup_background.png';
    let logo_image = 'https://media.exiledracers.com/global/exr_logo_white_stacked.png';

    useEffect(() => {
        if (window) {
            if(window.location.hash.indexOf("invite/") >= 0) {
                let hashtrim = window.location.hash.split("invite/");
                if(hashtrim.length > 1) {
                    referred_by = hashtrim[1].trim();
                    $("#invitewelcome").show();
                    $("#invitewelcomename").html(referred_by.toUpperCase());
                    if(referred_by.length > 1) {
                        $("#signuppage2").show();
                        $("#signuppage7").hide();
                        $("#signuppage0").hide();
                    }
                }
            } else if (window.location.hash.indexOf("p=") >= 0) {
                let hashtrim = window.location.hash.split("p=");
                if(hashtrim.length > 1) {
                    let page_direct = hashtrim[1].trim();
                    if(page_direct == 'login') {
                        $("#signuppage0").hide();
                        $("#signuppage2").hide();
                        $("#signuppage7").show();
                    }
                }
            }

            let subpage = '';
            let hashsplit = window.location.hash.split("/");
            if (hashsplit.length >= 3) {
                subpage = hashsplit[2];
            } else {
                let hostsplit = window.location.host.split(".");
                if((hostsplit[0] != '')&&(hostsplit[0] != 'wwww')) {
                    subpage = hostsplit[0];
                }
            }

            if (subpage == 'harumicup') {
                setTournamentRegister(1);
                setGetStartedText('HARUMI CUP');
                setTournamentTitle('Harumi Cup');
                setTournamentTimeText('August 22nd, 2023');
            }
        }
      }, []);

    useEffect(() => {
        if (error) {
          console.log("ERROR");
          console.log(error);
        }
        const signer = library?.getSigner();
        if (!signer) {
          return () => {};
        }
        pilotContract = new PilotContract(1284, signer);
        racerContract = new RacerContract(1284, signer);
        boosterContract = new BoosterContract(1284, signer);
        
      }, [active,library]);

      async function connectWeb3Contracts() {
        if (!active) {
          try {
            await activate(INJECTED_CONNECTOR, null, true);
          } catch (err) {
            console.log(err);
          }
        }
      }

    function validateEmailText(emailtext) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (emailtext.match(validRegex)) {
          return 'pass';
        } else {
          return 'fail';
        }
    }

    function registerFilter(registertype) {
        if(registertype == 'login') {
            goToLoginForm();
        } else {
            goToRegisterForm();
        }
    }

    function trainingContinue(page) {
        $("#page"+page+"submit").hide();
        $("#page"+page+"skip").hide();
        $("#page"+page+"loading").show();
        if(page == 1){
            setTimeout(function(){
                $("#signuppage"+page).fadeOut(2000);
            },500);
            setTimeout(function(){
                $("#signuppage"+(page+1)).fadeIn(2000);
            },2500);
        } else if(page == 2) {
            sendEmailWaitroomCode(page);
        } else if(page == 3) {
            checkEmailWaitroomCode(page);
        } else if (page == 4) {
            addSpectatorInfo(page);
        } else if (page == 5) {
            addWalletInfo(page);
        } else if (page == 7) {
            if(racer_wallet_address.length > 5) {
                loginWalletCode(page);
            } else {
                resendLoginConfirmationCode(page);
            }
        } else if (page == 8) {
            loginEmailCode(page);
        }
    }

    function sendEmailWaitroomCode(page) {
        var raceremail = document.getElementById("racerEmailAddress").value.trim();

        var addData = new Object();
        addData.specEmail = encodeURIComponent(raceremail);

        if(raceremail.length < 3) {
            alert('Add a Valid Email Address');
            $("#page"+page+"submit").show();
            $("#page"+page+"loading").hide();
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
            $("#page"+page+"submit").show();
            $("#page"+page+"loading").hide();
        } else {
            axiosInstance.post('member/waitroom_add/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var save_result = result.data;
                    if(save_result.length > 0) {
                        if(save_result[0]['status'] == 'registered') {
                            $("#page"+page+"submit").show();
                            $("#page"+page+"loading").hide();
                            alert('Email Registered! Login to your Account.')
                        } else {
                            setRacerEmail(raceremail);
                            setTimeout(function(){
                                $("#signuppage"+page).fadeOut(2000);
                            },500);
                            setTimeout(function(){
                                $("#signuppage"+(page+1)).fadeIn(2000);
                            },2500);
                        }
                    } else {
                        $("#page"+page+"submit").show();
                        $("#page"+page+"loading").hide();
                        alert('Whoops! Something Went Wrong. Update and Try Again');
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function checkEmailWaitroomCode(page) {
        var raceremail = document.getElementById("racerEmailAddress").value.trim();
        var racercode = document.getElementById("racerEmailCode").value.trim();
        var addData = new Object();
        addData.specEmail = encodeURIComponent(raceremail);
        addData.specCode = encodeURIComponent(racercode);
        addData.specReferral = encodeURIComponent(referred_by);

        if(raceremail.length <= 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else if (racercode.length != 6) {
            alert('Add a Valid Email Code');
        } else {
            $("#page"+page+"submit").hide();
            $("#page"+page+"loading").show();
            axiosInstance.post('member/waitroom_check/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var save_result = result.data;
                    if(save_result.length > 0) {
                        if(save_result[0]['status'] == 'expired') {
                            $("#page"+page+"submit").show();
                            $("#page"+page+"loading").hide();
                            alert('Your Confirmation Code Expired. Click Resend Code to Get a New Code.');
                        } else if(save_result[0]['status'] == 'pending') {
                            $("#page"+page+"submit").show();
                            $("#page"+page+"loading").hide();
                            alert('Invalid Code. Check Your Code and Enter it Again');
                        } else {
                            setRacerEmailCode(racercode);
                            setTimeout(function(){
                                $("#signuppage"+page).fadeOut(2000);
                            },500);
                            setTimeout(function(){
                                $("#signuppage"+(page+1)).fadeIn(2000);
                            },2500);
                        }
                    } else {
                        $("#page"+page+"submit").show();
                        $("#page"+page+"loading").hide();
                        alert('Whoops! Something Went Wrong. Update and Try Again');
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function resendConfirmationCode() {
        var raceremail = document.getElementById("racerEmailAddress").value.trim();

        var addData = new Object();
        addData.specEmail = encodeURIComponent(raceremail);

        if(raceremail.length < 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else {
            axiosInstance.post('member/waitroom_add/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var save_result = result.data;
                    if(save_result.length > 0) {
                        if(save_result[0]['status'] == 'registered') {
                            alert('Email Already Registered! Login to your Account.')
                        } else {
                            $("#resend-confirmation-link").html('<span class="exr-light-green-text">Code Sent!</span>');
                            setTimeout(function(){
                                $("#resend-confirmation-link").html('Resend Code');
                            },10000);
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function resendLoginConfirmationCode(page) {
        var raceremail = document.getElementById("racerLoginEmailAddress").value.trim();

        var addData = new Object();
        addData.specEmail = encodeURIComponent(raceremail);

        if(raceremail.length < 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else {
            $("#page"+(page+1)+"submit").show();
            $("#page"+(page+1)+"loading").hide();
            axiosInstance.post('member/login_email_code/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var save_result = result.data;
                    if(save_result.length > 0) {
                        if(save_result[0]['status'] == 'failed') {
                            alert('Email Address Not Registered! Login with a different email, connect your wallet or create a new account.')
                        } else {
                            $("#resend-confirmation-link2").html('<span class="exr-light-green-text">Code Sent!</span>');
                            setTimeout(function(){
                                $("#resend-confirmation-link2").html('Resend Code');
                            },7000);
                            setTimeout(function(){
                                $("#signuppage"+page).fadeOut(2000);
                            },500);
                            setTimeout(function(){
                                $("#signuppage"+(page+1)).fadeIn(2000);
                            },2500);
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function addSpectatorInfo(page) {
        var raceremail = document.getElementById("racerEmailAddress").value.trim();
        var racerhandle = document.getElementById("racerHandle").value.trim();
        var racertwitter = document.getElementById("racerTwitterHandle").value.trim();
        var racerdiscord = document.getElementById("racerDiscordHandle").value.trim();
        var addData = new Object();
        addData.specEmail = encodeURIComponent(raceremail);
        addData.specHandle = encodeURIComponent(racerhandle);
        addData.specTwitter = encodeURIComponent(racertwitter);
        addData.specDiscord = encodeURIComponent(racerdiscord);
        addData.specSource = 'homepage';

        if(racerhandle.length < 3) {
            alert('Add a Valid Racing Handle. Handles must be 3 or more characters.');
        } else {
            axiosInstance.post('member/waitroom_spectator_add/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    setRacerHandle(racerhandle);

                    var save_result = result.data;
                    if(save_result.length > 0) {
                        if(save_result[0]['status'] == 'handle_taken') {
                            $("#page"+page+"submit").show();
                            $("#page"+page+"loading").hide();
                            alert('This Racer Handle Is Taken. Choose Another One.');
                        } else {
                            let userid = save_result[0]['user_id'];
                            let racerid = save_result[0]['racer_id'];
                            let spectatorid = save_result[0]['spectator_id'];
                            let logintime = save_result[0]['time'];
                            let logintoken = save_result[0]['token'];
                            // let logintimehash = save_result[0]['time_hash'];
                            // let hashed_time = encryptLoginData('login',logintime.toString());
                            signup_user_id = userid;
                            dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));
                            setTimeout(function(){
                                $("#signuppage"+page).fadeOut(2000);
                            },500);
                            setTimeout(function(){
                                $("#signuppage"+(page+1)).fadeIn(2000);
                            },2500);
                        }
                    } else {
                        $("#page"+page+"submit").show();
                        $("#page"+page+"loading").hide();
                        alert('Whoops! Something Went Wrong. Update and Try Again');
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function addWalletInfo(page) {
        if(racer_wallet_address.length < 5) {
            setTimeout(function(){
                $("#signuppage"+page).fadeOut(2000);
            },500);
            if(tournament_register == 1) {
                setTimeout(function(){
                    $("#signuppage9").fadeIn(2000);
                },2500);
            } else {
                setTimeout(function(){
                    $("#signuppage"+(page+1)).fadeIn(2000);
                },2500);
            }
        } else {
            var raceremail = document.getElementById("racerEmailAddress").value.trim();
            var addData = new Object();
            addData.specEmail = encodeURIComponent(raceremail);
            addData.specID = encodeURIComponent(signup_user_id);
            addData.specWallet = encodeURIComponent(racer_wallet_address);
            addData.walletStatus = encodeURIComponent(signup_racer);

            if(raceremail.length < 3) {
                alert('Add a Valid Email Address');
            } else if (validateEmailText(raceremail) == 'fail') {
                alert('Add a Valid Email Address');
            } else if(signup_user_id == 0) {
                alert('Signup or Login To Update');
            } else {
                axiosInstance.post('member/waitroom_wallet_add/',addData).then(result => {
                    if(Object.keys(result.data).length > 0) {
                        var save_result = result.data;
                        if(save_result.length > 0) {
                            if(save_result[0]['status'] == 'duplicate') {
                                alert('This Wallet Is Already Registered. Connect a Different Wallet Later or Login to your Account.');
                                setTimeout(function(){
                                    $("#signuppage"+page).fadeOut(2000);
                                },500);
                                setTimeout(function(){
                                    $("#signuppage"+(page+1)).fadeIn(2000);
                                },2500);
                            } else if(save_result[0]['status'] == 'pending') {
                                $("#page"+page+"submit").show();
                                $("#page"+page+"loading").hide();
                                alert('This Email Account is not Registered. Go Back and Complete the Signup Process.');
                            } else {
                                let userid = save_result[0]['user_id'];
                                let racerid = save_result[0]['racer_id'];
                                let spectatorid = save_result[0]['spectator_id'];
                                let logintime = save_result[0]['time'];
                                let logintoken = save_result[0]['token'];
                                dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));
                                dispatch(setLoginWallet(racer_wallet_address));
                                setTimeout(function(){
                                    $("#signuppage"+page).fadeOut(2000);
                                },500);
                                if(tournament_register == 1) {
                                    setTimeout(function(){
                                        $("#signuppage9").fadeIn(2000);
                                    },2500);
                                } else {
                                    setTimeout(function(){
                                        $("#signuppage"+(page+1)).fadeIn(2000);
                                    },2500);
                                }
                            }
                        } else {
                            $("#page"+page+"submit").show();
                            $("#page"+page+"loading").hide();
                            alert('Whoops! Something Went Wrong. Update and Try Again');
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }

    function loginEmailCode(page) {
        var raceremail = document.getElementById("racerLoginEmailAddress").value.trim();
        var racercode = document.getElementById("racerLoginEmailCode").value.trim();
        var addData = new Object();
        addData.email = encodeURIComponent(raceremail);
        addData.emailCode = encodeURIComponent(racercode);

        if(raceremail.length <= 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else if (racercode.length != 6) {
            alert('Add a Valid Email Code');
        } else {
            $("#page"+page+"submit").hide();
            $("#page"+page+"loading").show();
            axiosInstance.post('member/login_email/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    setRacerEmail(raceremail);
                    var save_result = result.data;
                    if(save_result.length > 0) {
                        let userid = save_result[0]['user_id'];
                        let racerid = save_result[0]['racer_id'];
                        let spectatorid = save_result[0]['spectator_id'];
                        let racer_wallet_address = save_result[0]['wallet_id'];
                        let logintime = save_result[0]['time'];
                        let logintoken = save_result[0]['token'];
                        if(racerid > 0) {
                            signup_racer = 'racer';
                        }
                        dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));
                        dispatch(setLoginWallet(racer_wallet_address));
                        if((racer_wallet_address != null)&&(racer_wallet_address !== undefined)) {
                            if(racer_wallet_address.length > 5) {
                                checkRacerAssetsIndexer(racer_wallet_address);
                            }
                        }
                        if(tournament_register == 1) {
                            setTimeout(function(){
                                $("#signuppage"+page).fadeOut(2000);
                            },500);
                            setTimeout(function(){
                                $("#signuppage9").fadeIn(2000);
                            },2500);
                        } else {
                            setTimeout(function(){
                                moveToGame(page);
                            },1000);
                        }
                    }
                } else {
                    $("#page"+page+"submit").show();
                    $("#page"+page+"loading").hide();
                    alert('Whoops! Something Went Wrong. Make Sure You Are Entering The Most Recent Code and Try Again');
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function loginWalletCode(page) {
        var wallettxn = Date.now();
        var walletcode = encryptLoginData('wallet',wallettxn.toString());
        var addData = new Object();
        addData.wallet = encodeURIComponent(racer_wallet_address);
        addData.walletCode = encodeURIComponent(walletcode);
        addData.walletTxn = encodeURIComponent(wallettxn);

        if(racer_wallet_address.length <= 3) {
            alert('Connect Your Wallet');
        } else {
            $("#page"+page+"submit").hide();
            $("#page"+page+"loading").show();
            axiosInstance.post('member/login_wallet/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var save_result = result.data;
                    if(save_result.length > 0) {
                        let loginstatus = save_result[0]['status'];
                        let userid = save_result[0]['user_id'];
                        let racerid = save_result[0]['racer_id'];
                        let spectatorid = save_result[0]['spectator_id'];
                        let racer_wallet_address = save_result[0]['wallet_id'];
                        let logintime = save_result[0]['time'];
                        let logintoken = save_result[0]['token'];
                        if(racerid > 0) {
                            signup_racer = 'racer';
                        }
                        dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));
                        dispatch(setLoginWallet(racer_wallet_address))
                        if(loginstatus == 'new') {
                            setTimeout(function(){
                                moveToRacerRegister(page);
                            },1000);
                        } else {
                            if(tournament_register == 1) {
                                setTimeout(function(){
                                    $("#signuppage"+page).fadeOut(2000);
                                },500);
                                setTimeout(function(){
                                    $("#signuppage9").fadeIn(2000);
                                },2500);
                            } else {
                                setTimeout(function(){
                                    moveToGame(page);
                                },1000);
                            }
                        }
                        
                    }
                } else {
                    $("#page"+page+"submit").show();
                    $("#page"+page+"loading").hide();
                    alert('Whoops! Something Went Wrong. Make Sure This Wallet is Registered on EXR.');
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function moveToGame(page) {
        $("#page"+page+"submit").hide();
        $("#page"+page+"loading").show();
        setTimeout(function(){
            $("#signuppage"+page).fadeOut(2000);
        },3000);
        
        setTimeout(function() {
            dispatch(signupLoading(true));
        }, 4000);
        if(signup_racer == 'racer') {
            setTimeout(function() {
                dispatch(updateLocation("races_home"));
                window.location.href = 'https://www.exiledracers.com/#/races_home';
            }, 5000);
        } else {
            setTimeout(function() {
                dispatch(updateLocation("raceway"));
                window.location.href = 'https://www.exiledracers.com/#/raceway';
            }, 5000);
        }
        
    }

    function moveToRacerRegister(page) {
        $("#page"+page+"submit").hide();
        $("#page"+page+"loading").show();
        setTimeout(function(){
            $("#signuppage"+page).fadeOut(2000);
        },3000);
        
        setTimeout(function() {
            dispatch(signupLoading(true));
        }, 4000);

        setTimeout(function() {
            dispatch(updateLocation("signup"));
            window.location.href = '/#/signup';
        }, 5000);
    }

    function onEmailTextChange(e){
        setRacerEmail(e.target.value.trim());
    }

    function onHandleTextChange(e){
        setRacerHandle(e.target.value.replace("@","").trim());
    }

    async function connectMetamask() {
        const isMetaMaskInstalled = () => {
            const { ethereum } = window;
            return Boolean(ethereum && ethereum.isMetaMask);
        };

        if(!isMetaMaskInstalled) {
            alert('Install Metamask on this Browser');
        } else {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if(accounts[0]) {
                $("#metamask-result").show();
                $("#talisman-result").hide();
                $("#metamask-result-login").show();
                // $("#talisman-description").show();
                setRacerMetamask(accounts[0]);
                setRacerWallet(accounts[0]);
                racer_wallet_address = accounts[0];
                racer_wallet_type = 'metamask';
                connectWeb3Contracts();
                //checkRacerSignup(racer_wallet_address);
                checkRacerAssetsIndexer(racer_wallet_address);
            } else {
                alert('Unable To Get Your Accounts');
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if(accounts[0]) {
                    $("#metamask-result").show();
                    $("#talisman-result").hide();
                    $("#metamask-result-login").show();
                    // $("#talisman-description").show();
                    setRacerMetamask(accounts[0]);
                    setRacerWallet(accounts[0]);
                    racer_wallet_address = accounts[0];
                    racer_wallet_type = 'metamask';
                    connectWeb3Contracts();
                    //checkRacerSignup(racer_wallet_address);
                    checkRacerAssetsIndexer(racer_wallet_address);
                }
            }
        }
    }

    async function connectTalisman() {
        const isTalismanInstalled = () => {
            return Boolean(window && window.talismanEth);
        };

        if((!isTalismanInstalled)||(window.talismanEth === undefined)) {
            alert('Install Talisman on this Browser');
        } else {
            const accounts = await window.talismanEth.request({ method: 'eth_requestAccounts'});
            if(accounts[0]) {
                $("#talisman-result").show();
                // $("#talisman-description").hide();
                $("#metamask-result").hide();
                setRacerTalisman(accounts[0]);
                setRacerWallet(accounts[0]);
                racer_wallet_address = accounts[0];
                racer_wallet_type = 'talisman';
                // checkRacerSignup(racer_wallet_address);
                checkRacerAssetsIndexer(racer_wallet_address);
            } else {
                alert('Unable To Get Your Accounts');
            }
        }
    }

    function createTalismanWallet() {
        window.open('https://www.talisman.xyz/download');
    }

    function createMetamaskWallet() {
        window.open('https://metamask.io/download/');
    }

    function goToLoginForm() {
        setTimeout(function(){
            $("#signuppage0").fadeOut(500);
            $("#signuppage2").fadeOut(500);
        },100);
        setTimeout(function(){
            $("#signuppage7").fadeIn(500);
        },800);
    }

    function goToRegisterForm() {
        setTimeout(function(){
            $("#signuppage0").fadeOut(500);
            $("#signuppage7").fadeOut(500);
        },100);
        setTimeout(function(){
            $("#signuppage2").fadeIn(500);
        },800);
    }

    async function checkRacerAssetsIndexer(walletaddress) {
        $("#metamask-sync-text").html('...Syncing NFTs');
        $("#metamask-sync-text-login").html('...Syncing NFTs');
        var racer_tokens = await getIndexerUserTokens(walletaddress);
        if(racer_tokens != null) {
            var pilot_token_list = [];
            var racecraft_token_list = [];
            var booster_token_list = [];
            if(racer_tokens['ownedTokens'] !== undefined) {
                for(var i = 0; i < racer_tokens['ownedTokens'].length;i++) {
                    var indexer_token_text_id = racer_tokens['ownedTokens'][i]['id'];
                    var indexer_token_text_split = indexer_token_text_id.split("-");
                    var indexer_token_type = indexer_token_text_split[0];
                    var indexer_token_id = indexer_token_text_split[1];
                    if(indexer_token_type == 'pilot') {
                        pilot_token_list.push(indexer_token_id);
                    } else if(indexer_token_type == 'racecraft') {
                        racecraft_token_list.push(indexer_token_id);
                    }
                }
            }
            if(racer_tokens['ownedBoosters'] !== undefined) {
                for(var i = 0; i < racer_tokens['ownedBoosters'].length;i++) {
                    var indexer_token_text_id = racer_tokens['ownedBoosters'][i]['id'];
                    var indexer_token_quantity = racer_tokens['ownedBoosters'][i]['qty'];
                    var indexer_token_text_split = indexer_token_text_id.split("-");
                    var indexer_token_type = indexer_token_text_split[0];
                    var indexer_token_walletsub = indexer_token_text_split[1];
                    var indexer_token_id = indexer_token_text_split[2];
                    for(var j = 0; j < indexer_token_quantity;j++) {
                        booster_token_list.push(indexer_token_id);
                    }
                }
            }
            total_pilots_sync = pilot_token_list.length;
            total_racecrafts_sync = racecraft_token_list.length;
            total_boosters_sync = booster_token_list.length;
            pilots_checked = 1;
            boosters_checked = 1;
            racecrafts_checked = 1;
            updateWalletTokenOwner(walletaddress,pilot_token_list,'pilot');
            updateWalletTokenOwner(walletaddress,racecraft_token_list,'racecraft');
            updateWalletTokenOwner(walletaddress,booster_token_list,'booster');
        }

        setTimeout(function(){
            updateRacerNFTSync();
        },3000);
    }

    function updateRacerNFTSync() {
        if ((total_pilots_sync > 0)&&(total_racecrafts_sync > 0)&&(total_boosters_sync > 0)) {
            signup_racer = 'racer';
            $("#metamask-sync-text").html('Racer NFTs Synced');
            $("#metamask-sync-text-login").html('Racer NFTs Synced');
            $("#congrats-spectator-text").hide();
            $("#congrats-racer-text").show();
        } else {
            signup_racer = 'spectator';
            $("#metamask-sync-text").html('Racer NFTs Not Found');
            $("#metamask-sync-text-login").html('Racer NFTs Not Found');
            $("#congrats-spectator-text").show();
            $("#congrats-racer-text").hide();
        }
    }

    function updateWalletTokenOwner(walletaddress,tokenIds,tokenType) {
        var tokenstring = '';
        for(var i = 0; i < tokenIds.length;i++) {
          if(i > 0) {
            tokenstring = tokenstring+',';
          }
          tokenstring = tokenstring+tokenIds[i];
        }
        var addData = new Object;
        addData.walletID = encodeURIComponent(walletaddress);
        addData.tokenList = tokenstring;
        addData.nType = tokenType;
    
        if(walletaddress !== null) {
          if(walletaddress.length > 3) {
              axiosInstance
                .post("https://data.exiledracers.com/api/v1/mint/token_owner_check/", addData)
                .then((result) => {
                  if (Object.keys(result.data).length > 0) {
                    var view_result = result.data;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
          }
        }
    }

    // function checkRacerSignup(walletaddress) {
    //     $("#metamask-sync-text").html('...Syncing NFTs');
    //     $("#metamask-sync-text-login").html('...Syncing NFTs');
    //     checkPilotItems(walletaddress);
    //     checkRacecraftItems(walletaddress);
    //     checkBoosterItems(walletaddress);
    //     setTimeout(function(){
    //         updateRacerNFTSync();
    //     },3000);
    // }

    // function checkPilotItems(walletaddress) {
    //     if (pilotContract) {
    //         pilotContract.walletOfOwner(walletaddress).then((ids) => {
    //             let formattedIds = ids.map((id) => id.toNumber());
    //             if(formattedIds.length > 0) {
    //                 total_pilots_sync = formattedIds.length;
    //                 updateWalletTokenOwner(walletaddress,formattedIds,'pilot');
    //             }
    //             pilots_checked = 1;
    //         }).catch((err) => console.log(err));
    //     } else {
    //         setTimeout(function(){
    //             checkPilotItems(walletaddress);
    //         },1000);
    //     }
    // }

    // function checkRacecraftItems(walletaddress) {
    //     if (racerContract) {
    //         racerContract
    //           .walletOfOwner(walletaddress)
    //           .then((ids) => {
    //             let formattedIdsRC = ids.map((id) => id.toNumber());
    //             if(formattedIdsRC.length > 0) {
    //               total_racecrafts_sync = formattedIdsRC.length;
    //               updateWalletTokenOwner(walletaddress,formattedIdsRC,'racecraft');
    //             }
    //             racecrafts_checked = 1;
    //           })
    //           .catch((err) => console.log(err));
    //     } else {
    //         setTimeout(function(){
    //             checkRacecraftItems(walletaddress);
    //         },1000);
    //     }
    // }
    
    // function checkBoosterItems(walletaddress) {
    //     if (boosterContract) {
    //         let promises = [];
    //         const promise = boosterContract.balanceOfBatch(walletaddress,0,202);
    //         promises.push(promise);
    //         Promise.all(promises).then((inventoryBalances) => {
    //             var totalboostersclaimed = 0;
    //             var booster_token_list =  new Array();
    //             for (let i = 0; i < inventoryBalances[0].length; i++) {
    //                 if ((inventoryBalances[0][i].toNumber() != 0)&&(inventoryBalances[0][i].toNumber() != '')) {
    //                     totalboostersclaimed = totalboostersclaimed + inventoryBalances[0][i].toNumber();
    //                     for (var z = 0; z < inventoryBalances[0][i].toNumber(); z++) {
    //                         booster_token_list.push(i);
    //                     }  
    //                 }
    //             }
    //             if(booster_token_list.length > 0) {
    //               total_boosters_sync = booster_token_list.length;
    //               updateWalletTokenOwner(walletaddress,booster_token_list,'booster');
    //             }
    //             boosters_checked = 1;
    //         });
    //     } else {
    //         setTimeout(function(){
    //             checkBoosterItems(walletaddress);
    //         },1000);
    //     }
    // }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            $("#signup-main-section").fadeIn(2000);
        });
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row" id="main-landing-page">
            <div id="splash-image-container" style={{width:'100vw',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-20',background:'url('+bg_image+')',backgroundSize:'cover',backgroundPosition:'center'}}>
            </div>
            <div id="signup-main-section" className="col s12 transparent" style={{marginTop:'5vh',position:'relative',zIndex:'10',display:'none'}}>
                <div className="row center-align" style={{marginBottom:'10px'}}>
                    <div className="col s12 m10 offset-m1 l4 offset-l4">
                        <img id="exr-logo-button" style={{cursor:'pointer',marginLeft:'-20px',marginTop:'3vh',minWidth:'250px'}} title="Exiled Racers" width="50%" src={logo_image} />
                    </div>
                </div>
                <div className="row white-text" style={{position:'relative'}}>
                    <div id="signuppage0" className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 exr-trans-black-bg" style={{display:'block',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>{get_started_text}</div>
                            <div className="headline-text right" style={{marginTop:'5px',marginRight:'5px',cursor:'pointer',fontSize:'14px'}} title="Continue to Training" onClick={()=>registerFilter('login')}>
                                LOGIN
                            </div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        
                        <div className="col s12 l6 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px'}}>
                            <div className="row center-align" style={{padding:'5px 5px',marginBottom:'0px',borderRadius:'10px', border:'1px solid #3A3A3A'}}>
                                <div className="col s12 exr-light-green-text" style={{fontSize:'12px',padding:'0px 15px',marginBottom:'0px',marginTop:'10px'}}>
                                    JOIN AS
                                </div>
                                <div className="col s12 headline-text" style={{padding:'0px 15px',marginBottom:'10px',marginTop:'0px'}}>
                                    PREDICTOR
                                </div>
                                
                                <div className="col s12" style={{padding:'0px 10px',marginBottom:'15px'}}>
                                    Register as a prophet and watch and predict on races. No Wallet or NFTs required. 
                                </div>
                                <div className="col s12" style={{padding:'0px 10px',marginBottom:'15px'}}>
                                    <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>registerFilter('register')}>
                                        REGISTER
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l6 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px'}}>
                            <div className="row center-align" style={{padding:'5px 5px',marginBottom:'0px',borderRadius:'10px', border:'1px solid #3A3A3A'}}>
                            <div className="col s12 exr-light-green-text" style={{fontSize:'12px',padding:'0px 15px',marginBottom:'0px',marginTop:'10px'}}>
                                    JOIN AS
                                </div>
                                <div className="col s12 headline-text" style={{padding:'0px 15px',marginBottom:'10px',marginTop:'0px'}}>
                                    RACER
                                </div>
                                <div className="col s12" style={{padding:'0px 10px',marginBottom:'15px'}}>
                                    Join as an official racer. Wallet and EXR Pilot, Racecraft and Booster NFTs required. 
                                </div>
                                <div className="col s12" style={{padding:'0px 10px',marginBottom:'15px'}}>
                                    <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>registerFilter('register')}>
                                        REGISTER
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage1" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 exr-trans-black-bg" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'10px'}}>CONFIDENTIAL | EXR TRAINING</div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            The Supercorps continue to tear civilizations apart across galaxies — there are too many of us that remain trapped in the Old World...war, slavery and destruction — all for Harumi profits. It has to stop. 
                            <br/><br/>
                            EXR recruit, you're joining an elite group of racers from the newly freed factions (Augs, Serfs, and Mercs) under the common banner of Exiled Racers. 
                            <br/><br/>
                            Over the coming months, you brave select few will be training with EXR trainers and other racers in a program designed to help break the chains of The Old World across the galaxy and save the many that remain helpless.
                            <br/><br/>
                            <i>— Knowing This, Do You Wish To Continue?</i>
                        </div>
                        <div className="col s12" style={{marginTop:'30px'}}>
                            <div id="page1submit" className="right" title="Continue to Training">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(1)}>
                                    CONTINUE
                                </span>
                            </div>
                            <div id="page1loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage2" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 exr-trans-black-bg" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>{get_started_text}</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div id="invitewelcome" className="col s12 subheadline-text" style={{display:'none',fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <a className="exr-light-green-text" id="invitewelcomename"></a> invited you to join Exiled Racers.
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Add Your Email Address to get started on Exiled Racers. We'll send a confirmation code to confirm your account in the next step. 
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Email Address:
                            </div>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                                <input id="racerEmailAddress" 
                                        type="text" 
                                        className="browser-default white-bubble-input" 
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div className="left">
                                <a className="white-red-link headline-text" onClick={()=>goToLoginForm()}>LOGIN</a>
                            </div>
                            <div id="page2submit" className="right" title="Submit Email Confirmation Code">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(2)}>
                                    SUBMIT
                                </span>
                            </div>
                            <div id="page2loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage3" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 exr-trans-black-bg" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>EMAIL CONFIRMATION CODE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            We sent a confirmation code to your email address. Add the code sent to your email below to continue. The email may take 
                            a few minutes to reach your inbox.
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Email Address: {racer_email}  
                            </div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Confirmation Code:
                            </div>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                                <input id="racerEmailCode" 
                                        type="text" 
                                        className="browser-default white-bubble-input" 
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div className="left">
                                <a id="resend-confirmation-link" title="Resend Confirmation Code" className="white-red-link" onClick={()=>resendConfirmationCode()}>Resend Code</a>
                            </div>
                            <div id="page3submit" className="right" title="Submit Email Confirmation Code">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(3)}>
                                    SUBMIT
                                </span>
                            </div>
                            <div id="page3loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage4" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 exr-trans-black-bg" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>YOUR HANDLE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Add your Racer User Handle that you want to be known by across EXR races, leaderboards and chats.
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Racer Handle
                            </div>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                                <input id="racerHandle" 
                                        type="text" 
                                        className="browser-default white-bubble-input" 
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Twitter Handle <span className="grey-text">(optional)</span>
                            </div>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                                <input id="racerTwitterHandle" 
                                        type="text" 
                                        className="browser-default white-bubble-input" 
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Discord Username <span className="grey-text">(optional)</span>
                            </div>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                                <input id="racerDiscordHandle" 
                                        type="text" 
                                        className="browser-default white-bubble-input" 
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div id="page4submit" className="right" title="Continue to Training">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(4)}>
                                    SAVE & CONTINUE
                                </span>
                            </div>
                            <div id="page4loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage5" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 exr-trans-black-bg" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>CONNECT WALLET</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Optional: If you have a digital wallet, connect using one of our providers below. Wallets are needed to claim prizes and to race if you are an EXR NFT holder.
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px'}}>
                            <div className="row" style={{padding:'0px 0px',marginBottom:'0px',borderRadius:'10px', border:'1px solid white'}}>
                                <div className="col s2" style={{borderRadius:'10px',overflow:'hidden'}}>
                                    <img src="css/images/talisman_logo.jpeg" style={{marginTop:'10px',borderRadius:'10px'}} width="100%" />
                                </div>
                                <div className="col s10" style={{fontSize:'16px',borderRadius:'10px'}}>
                                    <div className="row headline-text" style={{padding:'0px',marginTop:'12px',marginBottom:'0px'}}>
                                        TALISMAN
                                    </div>
                                    <div className="row" style={{padding:'0px',marginBottom:'10px',fontSize:'14px'}}>
                                        <div className="left white-red-link" onClick={()=>connectTalisman()}>Connect</div>
                                        <div className="left" style={{marginLeft:'10px'}}>&bull;</div>
                                        <div className="left white-red-link" style={{marginLeft:'10px'}} onClick={()=>createTalismanWallet()}>Create Wallet</div>
                                    </div>
                                    
                                </div>
                                <div id="talisman-description" className="col s12" style={{fontSize:'16px',borderRadius:'10px'}}>
                                    <div className="row" style={{padding:'0px 15px',marginBottom:'10px',fontSize:'14px'}}>
                                        Use our preferred wallet provider and be able to manage multiple currencies and NFTs across Moonbeam, Polkadot and Ethereum compatible chains.
                                    </div>
                                    <div id="talisman-result" className="row" style={{display:'none',padding:'0px',padding:'0px 15px',marginBottom:'15px',fontSize:'14px'}}>
                                        <div className="divider-thin" style={{marginBottom:'10px'}}></div>
                                        
                                        <div id="talisman-wallet-id" className="right right-align">{'0x....'+racer_talisman.substring(racer_talisman.length-10)}</div>
                                        <div className="right exr-light-green-text" style={{marginRight:'10px'}}>Connected: </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px'}}>
                            <div className="row" style={{padding:'0px 0px',marginBottom:'0px',borderRadius:'10px', border:'1px solid white'}}>
                                <div className="col s2" style={{borderRadius:'10px',overflow:'hidden'}}>
                                    <img src="css/images/metamask_logo.jpeg" style={{marginTop:'10px',borderRadius:'10px'}} width="100%" />
                                </div>
                                <div className="col s10" style={{fontSize:'16px',borderRadius:'10px'}}>
                                    <div className="row headline-text" style={{padding:'0px',marginTop:'12px',marginBottom:'0px'}}>
                                        METAMASK
                                    </div>
                                    <div className="row" style={{padding:'0px',marginBottom:'10px',fontSize:'14px'}}>
                                        <div className="left white-red-link" onClick={()=>{connectMetamask()}}>Connect</div>
                                        <div className="left" style={{marginLeft:'10px'}}>&bull;</div>
                                        <div className="left white-red-link" style={{marginLeft:'10px'}} onClick={()=>createMetamaskWallet()}>Create Wallet</div>
                                    </div>
                                </div>
                                <div className="col s12" style={{fontSize:'16px',borderRadius:'10px'}}>
                                    <div id="metamask-result" className="row right-align" style={{display:'none',padding:'0px',padding:'0px 15px',marginBottom:'15px',fontSize:'14px'}}>
                                        <div className="divider-thin" style={{marginBottom:'10px'}}></div>
                                        <div className="left exr-light-green-text" style={{marginRight:'10px'}}>Connected: </div>
                                        <div id="metamask-wallet-id" className="left">{'0x....'+racer_metamask.substring(racer_metamask.length-10)}</div>
                                        <div className="right" id="metamask-sync-text"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div id="page5submit" className="right" title="Continue to Training">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(5)}>
                                    SAVE & CONTINUE
                                </span>
                            </div>
                            <div id="page5loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage6" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 exr-trans-black-bg" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"20px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>REGISTRATION COMPLETE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div id="congrats-racer-text" className="col s12 subheadline-text" style={{display:'none',fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Congratulations <a className="exr-light-green-text">{racer_handle}</a> you are now an Exiled Racer. 
                            <br/><br/>We've taken your belongings to <a className="exr-light-green-text">The Workshop</a> where you'll find your ships, boosters and 
                            other important assets. Check them out but don't take too long as it looks like your EXR Trainer is waiting for you at&nbsp;
                            <a className="exr-light-green-text">The Racetrack</a> for your first lesson.
                            <br/><br/>
                            Fly Safe Recruit...
                        </div>
                        <div id="congrats-spectator-text" className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Congratulations <a className="exr-light-green-text">{racer_handle}</a> you are now an Exiled Racer Prophet. 
                            <br/><br/>Head to the <a className="exr-light-green-text">EXR Raceway</a> where you'll find upcoming races you can watch and make predictions on. 
                            Sort through races based on your favorite racers, race types and most competitive and make your picks before the races begin. Join the banter with other 
                            racers and prophets.
                            <br/><br/>
                            Fly Safe Racer...
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div id="page6submit" className="right" title="Continue to Training">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>moveToGame(6)}>
                                    CONTINUE
                                </span>
                            </div>
                            <div id="page6loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage7" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 exr-trans-black-bg" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>LOGIN TO EXR</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Enter Your Email Address or connect to your Wallet to log in to Exiled Racers. 
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px'}}>
                            <div className="row" style={{padding:'5px 5px',marginBottom:'0px',borderRadius:'10px', border:'1px solid #3A3A3A'}}>
                                <div className="col s12 headline-text" style={{padding:'0px 15px',marginBottom:'0px',marginTop:'10px'}}>
                                    EMAIL ADDRESS
                                </div>
                                <div className="col s12" style={{padding:'0px 10px',marginBottom:'15px'}}>
                                    <input id="racerLoginEmailAddress" 
                                            type="text" 
                                            className="browser-default white-bubble-input" 
                                            style={{width:'98%',borderColor:'#3A3A3A'}} />
                                </div>
                            </div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'10px'}}>
                                <div className="col s12 headline-text" style={{padding:'0px 15px',marginBottom:'0px',marginTop:'10px'}}>
                                    OR
                                </div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',marginTop:'10px',padding:'0px 20px'}}>
                            <div className="row white-red-link" style={{padding:'0px 0px',marginBottom:'0px',borderRadius:'10px', border:'1px solid #3A3A3A'}} onClick={()=>{connectMetamask()}}>
                                <div className="col s2" style={{borderRadius:'10px',overflow:'hidden'}}>
                                    <img src="css/images/metamask_logo.jpeg" style={{marginTop:'10px',borderRadius:'10px'}} width="100%" />
                                </div>
                                <div className="col s10" style={{fontSize:'16px',borderRadius:'10px'}}>
                                    <div className="row headline-text" style={{padding:'0px',marginTop:'12px',marginBottom:'0px'}}>
                                        METAMASK
                                    </div>
                                    <div className="row" style={{padding:'0px',marginBottom:'10px',fontSize:'14px'}}>
                                        <div className="left white-red-link" onClick={()=>{connectMetamask()}}>Connect</div>
                                    </div>
                                </div>
                                <div className="col s12" style={{fontSize:'16px',borderRadius:'10px'}}>
                                    <div id="metamask-result-login" className="row right-align" style={{display:'none',padding:'0px',padding:'0px 15px',marginBottom:'15px',fontSize:'14px'}}>
                                        <div className="divider-thin" style={{marginBottom:'10px'}}></div>
                                        <div className="left exr-light-green-text" style={{marginRight:'10px'}}>Connected: </div>
                                        <div id="metamask-wallet-id2" className="left white-text">{'0x....'+racer_metamask.substring(racer_metamask.length-10)}</div>
                                        <div className="right white-text" id="metamask-sync-text-login"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div className="left" style={{display:'block'}}>
                                <a className="white-red-link headline-text" onClick={()=>goToRegisterForm()}>SIGNUP</a>
                            </div>
                            <div id="page7submit" className="right" title="Submit Email Confirmation Code">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(7)}>
                                    CONTINUE
                                </span>
                            </div>
                            <div id="page7loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage8" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 exr-trans-black-bg" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>EMAIL CONFIRMATION CODE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            We sent a confirmation code to your email address. Add the code sent to your email below to continue. The email may take 
                            a few minutes to reach your inbox.
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Email Address: {racer_email}  
                            </div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Confirmation Code:
                            </div>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                                <input id="racerLoginEmailCode" 
                                        type="text" 
                                        className="browser-default white-bubble-input" 
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div className="left">
                                <a id="resend-confirmation-link2" title="Resend Confirmation Code" className="white-red-link" onClick={()=>resendLoginConfirmationCode(7)}>Resend Code</a>
                            </div>
                            <div id="page8submit" className="right" title="Submit Email Confirmation Code">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(8)}>
                                    SUBMIT
                                </span>
                            </div>
                            <div id="page8loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage9" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 exr-trans-black-bg" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"20px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>REGISTRATION COMPLETE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div id="congrats-tourney-register" className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Congratulations, you are now registered for the <a className="exr-light-green-text">{tournament_title}</a>. 
                            <br/><br/>Races start on <a className="exr-light-green-text">{tournament_time_text}</a>. In the meantime, head to the <a className="exr-light-green-text">EXR Raceway</a> where you'll find races you can watch and join the banter with other racers and predictors.
                            <br/><br/>
                            Fly Safe...
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div id="page9submit" className="right" title="Continue to Training">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Raceway" onClick={()=>moveToGame(9)}>
                                    CONTINUE
                                </span>
                            </div>
                            <div id="page9loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};
