import * as THREE from "three";

/**
 * A cinematic camera anchor
 */
export class CameraAnchor {
    /**
     * Construct a camera anchor
     * @param {number} [springPosition] The position spring
     * @param {number} [springQuaternion] The quaternion spring
     */
    constructor(springPosition = 1, springQuaternion = 1) {
        this.position = new THREE.Vector3();
        this.quaternion = new THREE.Quaternion();
        this.springPosition = springPosition;
        this.springQuaternion = springQuaternion;
    }

    /**
     * Get the position of this anchor given a target position
     * @param {CameraTargetable} target The target position
     * @param {CameraTargetable[]} quaternion The target quaternion
     * @param {number} lap The lap length
     * @param {number} time The time interpolation in the range [0, 1]
     */
    update(target, quaternion, lap , time) {

    }
}
