import {DataLoader} from "../utils/dataLoader.js";

export class RaceDesign extends DataLoader {
    static KEY_TRACK = "track";
    static KEY_ENVIRONMENT = "environment";

    /**
     * Construct a race design
     */
    constructor() {
        super("", "", "");

        this.track = null;
        this.environment = null;
    }

    /**
     * Receive loaded data
     * @param {Object} data The loaded data
     */
    receiveData(data) {
        if (data.hasOwnProperty(RaceDesign.KEY_TRACK))
            this.track = data[RaceDesign.KEY_TRACK];

        if (data.hasOwnProperty(RaceDesign.KEY_ENVIRONMENT))
            this.environment = data[RaceDesign.KEY_ENVIRONMENT];
    }
}