import { Audio } from "../audio/audio.js";

/**
 * Lap info
 */
export class LapInfo {
  static PREFIX = "Lap ";
  static SEPARATOR = " / ";

  #audio;

  /**
   * Construct lap info
   * @param {HTMLElement} element The element to display lap info in
   * @param {Racer} racer The racer to track
   * @param {number} length The length of one lap
   * @param {number} laps The total number of laps
   * @param {Audio} audio The audio object
   */
  constructor(element, racer, length, laps, audio) {
    this.#audio = audio;

    this.element = element;
    this.racer = racer;
    this.length = length;
    this.laps = laps;

    this.counter = 0;
    this.firstcounter = 1;
    this.firstplayed = true;

    this.update();
  }

  /**
   * Update the state
   */
  update(soundEffects) {
    if (this.racer.moved !== -1) {
      // const laps = Math.min(this.laps, 1 + Math.floor(this.racer.moved / this.length)).toString();
      // this.element.innerText = LapInfo.PREFIX + laps + LapInfo.SEPARATOR + this.laps.toString();

      this.currentlap = Math.min(
        this.laps,
        1 + Math.floor(this.racer.moved / this.length)
      ).toString();
      this.element.innerText =
        LapInfo.PREFIX +
        this.currentlap +
        LapInfo.SEPARATOR +
        this.laps.toString();

      let show_lap_banner = "N";
      let lapbanner = "LAP " + this.currentlap;
      if (this.currentlap !== this.counter && this.currentlap < this.laps) {
        this.counter = this.currentlap;
        if(this.currentlap > 1) {
          this.#audio.getAudio(Audio.SFX_LAP_MARKER).play();
        }
        show_lap_banner = "Y";
        
      } else if (this.currentlap !== this.counter && this.currentlap == this.laps) {
        this.counter = this.currentlap;

        this.#audio.getAudio(Audio.SFX_LAP_MARKER_FINAL).play();

        lapbanner = "FINAL LAP";
        show_lap_banner = "Y";
      }

      // Show Lap Banner
      if (show_lap_banner == "Y" && this.currentlap > 1) {
        window.$("#racerbanner").fadeIn();
        var finaltxt = lapbanner;
        window.$("#racerbannertxt").html(finaltxt);
        setTimeout(function () {
          window.$("#racerbanner").fadeOut(1000);
        }, 3000);
      }

      //turn off loop after the race finishes
    }
  }
}
