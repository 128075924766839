import * as THREE from "three";
import {RaceRenderOrder} from "../../../raceRenderOrder.js";
import {RacerFlare} from "../flare/racerFlare.js";

/**
 * A trail to show behind a racer
 */
export class RacerTrail {
    // language=GLSL
    static SHADER_VERTEX = `
        uniform float shift;
        
        attribute vec2 offset;
        
        attribute vec4 center;
        attribute vec3 direction;
        attribute vec3 color;

        varying vec3 vColor;
        varying vec2 vUv;
        
        void main() {
            float longitude = offset.x + shift;
            vec3 up = normalize(cross(cameraPosition - center.xyz, direction)) * pow(longitude, 0.3) * center.w;
            
            vColor = color;
            vUv = vec2(longitude, offset.y);
            gl_Position = projectionMatrix * modelViewMatrix * vec4(center.xyz + up * offset.y, 1.0);
        }
        `;

    // language=GLSL
    static SHADER_FRAGMENT = `
        varying vec3 vColor;
        varying vec2 vUv;
        
        void main() {
            float multiplier = 1.0;
            float longitude = 0.4;
            float latitude = 0.4;
            float alpha = sin(3.141593 * pow(vUv.x, longitude)) * pow(cos(vUv.y * 1.570796), latitude) * multiplier;
            
            gl_FragColor = vec4(mix(vColor, vec3(1.0), pow(alpha, 5.0)), alpha);
        }
        `;

    static MATERIAL = new THREE.ShaderMaterial({
        uniforms: {
            shift: {
                value: 0
            }
        },
        blending: THREE.AdditiveBlending,
        depthWrite: false,
        side: THREE.DoubleSide,
        vertexShader: RacerTrail.SHADER_VERTEX,
        fragmentShader: RacerTrail.SHADER_FRAGMENT
    });

    static SEGMENTS = 64;
    static SPACING = 2.8;
    static TRAIL_DATA_STRIDE = 10;
    static TRAIL_DATA_STRIDE_SEGMENT = RacerTrail.TRAIL_DATA_STRIDE << 1;
    static COLOR_BOOST = new THREE.Color(1, 0, 0);
    //static COLOR_BOOST = new THREE.Color(1,0,1);
    static RADIUS = new THREE.Vector2(.7, .9);
    static RADIUS_BOOST = new THREE.Vector2(-.2, 1.3);

    /**
     * Make the trail geometry
     * @param {THREE.InterleavedBufferAttribute} centroids The centroids for this mesh
     * @param {THREE.InterleavedBufferAttribute} directions The directions
     * @param {THREE.InterleavedBufferAttribute} colors The colors for this mesh
     * @returns {THREE.BufferGeometry} The generic trail geometry
     */
    static makeGeometry(centroids, directions, colors) {
        const geometry = new THREE.BufferGeometry();
        const vertices = [];
        const indices = [];

        for (let segment = 0; segment < RacerTrail.SEGMENTS; ++segment) {
            vertices.push(
                segment / (RacerTrail.SEGMENTS - 1), -1,
                segment / (RacerTrail.SEGMENTS - 1), 1);

            if (segment !== RacerTrail.SEGMENTS - 1)
                indices.push(
                    segment * 2,
                    segment * 2 + 1,
                    (segment + 1) * 2,
                    (segment + 1) * 2,
                    (segment + 1) * 2 + 1,
                    segment * 2 + 1);
        }

        geometry.setAttribute(
            "offset",
            new THREE.BufferAttribute(new Float32Array(vertices), 2));
        geometry.setAttribute(
            "center",
            centroids);
        geometry.setAttribute(
            "direction",
            directions);
        geometry.setAttribute(
            "color",
            colors);
        geometry.setIndex(indices);

        return geometry;
    }

    /**
     * Make the trail material
     * @returns {THREE.ShaderMaterial} The trail material
     */
    static makeMaterial() {
        return RacerTrail.MATERIAL.clone();
    }

    /**
     * Construct a racer trail
     * @param {RacerTrailProperties} properties The trail properties
     * @param {THREE.Vector3} offset The offset relative to the ship
     */
    constructor(properties, offset) {
        this.properties = properties;
        this.offset = offset;
        this.offsetRotated = new THREE.Vector3();
        this.trailData = new Float32Array(RacerTrail.TRAIL_DATA_STRIDE_SEGMENT * RacerTrail.SEGMENTS);
        this.trailBuffer = new THREE.InterleavedBuffer(this.trailData, RacerTrail.TRAIL_DATA_STRIDE);
        this.trailBuffer.usage = THREE.DynamicDrawUsage;
        this.centroids = new THREE.InterleavedBufferAttribute(this.trailBuffer, 4, 0);
        this.directions = new THREE.InterleavedBufferAttribute(this.trailBuffer, 3, 4);
        this.colors = new THREE.InterleavedBufferAttribute(this.trailBuffer, 3, 7);
        this.head = new THREE.Vector3();
        this.direction = new THREE.Vector3();
        this.geometry = RacerTrail.makeGeometry(this.centroids, this.directions, this.colors);
        this.material = RacerTrail.makeMaterial();
        this.flare = new RacerFlare();
        this.boost = 0;
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.position.set(0, 0, 0);
        this.mesh.frustumCulled = false;
        this.mesh.visible = true;
        this.mesh.renderOrder = RaceRenderOrder.ORDER_TRAILS;
        this.mesh.add(this.flare.sprite);
    }

    /**
     * The centroids have changed
     */
    dataChanged() {
        this.geometry.getAttribute("center").needsUpdate = true;
    }

    /**
     * Teleport the trail to a position
     * @param {THREE.Vector3} position The position to teleport to
     */
    teleport(position) {
        this.head.copy(position);

        for (let segment = 0; segment < RacerTrail.SEGMENTS; ++segment) {
            this.trailData[segment * RacerTrail.TRAIL_DATA_STRIDE_SEGMENT] = this.trailData[segment * RacerTrail.TRAIL_DATA_STRIDE_SEGMENT + RacerTrail.TRAIL_DATA_STRIDE] = position.x;
            this.trailData[segment * RacerTrail.TRAIL_DATA_STRIDE_SEGMENT + 1] = this.trailData[segment * RacerTrail.TRAIL_DATA_STRIDE_SEGMENT + RacerTrail.TRAIL_DATA_STRIDE + 1] = position.y;
            this.trailData[segment * RacerTrail.TRAIL_DATA_STRIDE_SEGMENT + 2] = this.trailData[segment * RacerTrail.TRAIL_DATA_STRIDE_SEGMENT + RacerTrail.TRAIL_DATA_STRIDE + 2] = position.z;
        }

        this.dataChanged();
    }

    updateHeadData(position) {
        let radius = RacerTrail.RADIUS.x + (RacerTrail.RADIUS.y - RacerTrail.RADIUS.x) * Math.random();

        if (this.boost !== 0)
            radius += this.boost * (RacerTrail.RADIUS_BOOST.x + (RacerTrail.RADIUS_BOOST.y - RacerTrail.RADIUS_BOOST.x) * Math.random());

        this.trailData[0] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE] = position.x + this.offsetRotated.x;
        this.trailData[1] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 1] = position.y + this.offsetRotated.y;
        this.trailData[2] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 2] = position.z + this.offsetRotated.z;
        this.trailData[3] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 3] = radius;
        this.trailData[4] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 4] = this.direction.x;
        this.trailData[5] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 5] = this.direction.y;
        this.trailData[6] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 6] = this.direction.z;
        this.trailData[7] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 7] = this.boost * RacerTrail.COLOR_BOOST.r + (1 - this.boost) * this.properties.color.r;
        this.trailData[8] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 8] = this.boost * RacerTrail.COLOR_BOOST.g + (1 - this.boost) * this.properties.color.g;
        this.trailData[9] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 9] = this.boost * RacerTrail.COLOR_BOOST.b + (1 - this.boost) * this.properties.color.b;

        if ((this.boost !== 0)&&(this.properties.sponsored == 1)) {
            this.trailData[7] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 7] = this.properties.boostColor.r;
            this.trailData[8] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 8] = this.properties.boostColor.g;
            this.trailData[9] = this.trailData[RacerTrail.TRAIL_DATA_STRIDE + 9] = this.properties.boostColor.b;
        }
        
    }

    /**
     * Move the head of this trail
     * @param {THREE.Vector3} position The new head position
     * @param {THREE.Quaternion} rotation The ship rotation
     */
    moveHead(position, rotation) {
        let distance = position.distanceTo(this.head);

        this.direction.copy(position).sub(this.head).normalize();

        while (distance > RacerTrail.SPACING) {
            this.head.addScaledVector(this.direction, RacerTrail.SPACING);

            distance -= RacerTrail.SPACING;

            this.updateHeadData(this.head);
            this.shiftData();
        }

        this.material.uniforms.shift.value = distance / (RacerTrail.SEGMENTS * RacerTrail.SPACING);

        this.updateHeadData(position);

        this.offsetRotated.copy(this.offset).applyQuaternion(rotation);
        this.flare.sprite.position.copy(position).add(this.offsetRotated);

        this.dataChanged();
    }

    /**
     * Shift the latest head position into the centroid array
     */
    shiftData() {
        for (let segment = RacerTrail.SEGMENTS; segment-- > 0;)
            for (let i = 0; i < RacerTrail.TRAIL_DATA_STRIDE_SEGMENT; ++i)
                this.trailData[segment * RacerTrail.TRAIL_DATA_STRIDE_SEGMENT + i] =
                    this.trailData[(segment - 1) * RacerTrail.TRAIL_DATA_STRIDE_SEGMENT + i];
    }


    /**
     * Set the boost
     * @param {number} boost The boost in the range [0, 1]
     */
     showAggressionBoost(boost) {
         this.boost = boost;
    }
}