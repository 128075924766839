import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import "./pretraining_splash.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import {WalletSync} from ".."
import {TrainingRegistration, EmailRegistration} from "../../Organisms"
import {InjectedConnector, } from "@web3-react/injected-connector";
import $ from "jquery";

let web3;

const HARDHAT_CHAIN_ID = 31337;
const MOONBASE_CHAIN_ID = 1287;
const MOONBEAM_CHAIN_ID = 1284;

const supportedChainIds = [
  HARDHAT_CHAIN_ID,
  MOONBASE_CHAIN_ID,
];

const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: supportedChainIds,
});

export const PretrainingSplash = () => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, accountAddress } = useSelector((state) => state.account);

    const { chainId, account } = useWeb3React();

    if((racerID.length > 1)&&(accountAddress.length > 1)) {
        // dispatch(updateLocation('workshop'));
    }

    const [pageSet, setPage] = useState(false);

    let bg_image_side = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
    let bg_image = 'https://media.exiledracers.com/assets/game/builder/Splash_Background_-_Serf_Centered_1_1665676555319.jpeg';
    let logo_image = 'https://media.exiledracers.com/global/exr_logo_white_stacked.png';

    document.addEventListener('DOMContentLoaded', function() {
        
        window.resetCenterHeight();
        window.startPageModals();
        window.resetBodyWidth();
        startSwappingHoverButtons();

        window.$("#exr-logo-button").click(function() {
            window.location.reload();
        });

        window.$("#discord-access-button").click(function() {
            window.open('https://discord.gg/exiledracers');
        });

        window.$("#twitter-access-button").click(function() {
            window.open('https://twitter.com/exiledracers');
        });

        window.$("#medium-access-button").click(function() {
            window.open('https://blog.exiledracers.com/exiled-racers-announces-stunning-3d-nft-project-with-bonus-play-and-earn-racing-manager-backed-by-5a2fecac5419');
        });
    });

    function startSwappingHoverButtons() {
        window.$("#discord-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Discord_White_Gif0.png');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Discord_White_Gif0.png');
        });

        window.$("#twitter-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Twitter_White_Gif0.png');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Twitter_White_Gif0.png');
        });

        window.$("#medium-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Medium_White_Gif0.png');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Medium_White_Gif0.png');
        });
    }

    function requestTrainingAccess() {
        $("#main-splash-section").fadeOut(2000);
        setTimeout(function(){ 
            $("#register-main-section").fadeIn(3000);
        },2500);
    }

    function goToMintSite() {
        window.location.href="https://mint.exiledracers.com"
    }

    function goToCollectionViewer() {
        window.location.href="https://view.exiledracers.com"
    }

    window.addEventListener('resize', function() {
        window.resetCenterHeight();
    });

    if(setPage === false) {
        pageSet(true);
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row" id="main-landing-page">
            <div id="splash-image-container" style={{width:'100%',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-20',background:'url('+bg_image+')',backgroundSize:'cover',backgroundPosition:'center'}}>
            </div>
            <div id="main-splash-section" className="col s12 transparent" style={{marginTop:'10vh',position:'relative',zIndex:'10'}}>
                <div className="row center-align">
                    <div className="col s12 m10 offset-m1 l4 offset-l4">
                    <img id="exr-logo-button" style={{cursor:'pointer',marginLeft:'-20px',marginTop:'3vh',minWidth:'250px'}} title="Exiled Racers" width="65%" src={logo_image} />
                    </div>
                </div>
                <div className="row center-align" style={{marginTop:'20vh'}}>
                    <div className="col s12 center-align show-on-large hide-on-med-and-down">
                        <span className="round-border-button " style={{fontSize:'32px',paddingTop:'12px'}} title="Register for Training"  onClick={() => requestTrainingAccess() }>
                            REGISTER FOR TRAINING
                        </span>
                    </div>
                    <div className="col s12 show-on-med-and-down hide-on-large-only center-align">
                        <span className="round-border-button " style={{fontSize:'20px',paddingTop:'10px'}} title="Register for Training"  onClick={() => requestTrainingAccess() }>
                            REGISTER FOR TRAINING
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col s10 offset-s1 l6 offset-l3 center-align white-text" style={{padding:'0px'}}>
                        
                        <span className="headline-text white-red-link center-align" title="View NFT Collection" style={{marginBottom:'10px'}} onClick={() => goToCollectionViewer() }>
                            VIEW COLLECTION
                        </span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <span className="headline-text white-red-link center-align" title="Go To Mint Site" style={{marginBottom:'10px'}} onClick={() => goToMintSite() }>
                            MINT NOW
                        </span>
                    </div>
                </div>
                <div id="social-button-row" className="row center-align" style={{position:'relative',width:'100%',marginTop:'13vh',zIndex:'100'}}>
                    <div className="col s10 offset-s1 l4 offset-l4">
                        <img style={{cursor:'pointer',marginLeft:'20px',minWidth:'67px'}} width="15%" title="EXR Discord"
                            id="discord-access-button" src="https://media.exiledracers.com/global/splash/Discord_White_Gif0.png"/>
                        <img style={{cursor:'pointer',marginLeft:'20px',minWidth:'67px'}} width="15%" title="EXR Twitter"
                            id="twitter-access-button" src="https://media.exiledracers.com/global/splash/Twitter_White_Gif0.png"/>
                        <img style={{cursor:'pointer',marginLeft:'20px',minWidth:'67px'}} width="15%" title="EXR Blog"
                            id="medium-access-button" src="https://media.exiledracers.com/global/splash/Medium_White_Gif0.png"/>
                    </div>
                </div>
            </div>
            <EmailRegistration />
        </div>
    </div>);
};
