import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerXP, setRacerCoins } from "../../../redux/Account/actions";
import { setRacerLogin } from "../../../redux/Account/actions";

import {axiosInstance} from "../../../utils";
import {SettingsModal} from "../../Pages/Settings"
let web3;

export const GlobalHeader = () => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    if((racerID.length == 0)||(accountAddress.length == 0)) {
        dispatch(setRacerLogin('',''));
        dispatch(updateLocation("prelaunch"));
        window.location.href= '/';
    }

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [headerSet, setHeader] = useState(false);
    const [racerXP, setXP] = useState(0);
    const [racerCoins, setCoins] = useState(0);
    const [racerFirstName, setFirstName] = useState('Racer');
    const [racerHandle, setRacerHandle] = useState('Racer');

    function grabRacerInfo() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;

                // Set Racer XP, Coins and Name
                setXP(racer_details[0].racer_xp);
                setCoins(racer_details[0].racer_coins);
                setFirstName(racer_details[0].first_name);
                setRacerHandle(racer_details[0].racer_name);
            }
        }).catch(error => {
            console.log(error);
            dispatch(updateLocation("staging"));
        });
    }

    function goToNewRace() {
        //window.location.href= 'race';
        dispatch(updateLocation("race"));
    }
    function goToWorkshop() {
        window.location.href= '/#/workshop';
        dispatch(updateLocation("workshop"));
    }


    function logoutGame() {
        dispatch(setRacerLogin('',''));
    }

    if(headerSet == false) {
        setHeader(true);
        grabRacerInfo();
    }

    window.$(document).ready(function(){
        window.startNavigationDropdown();
        window.startPageModals();
        var tooltips = document.querySelectorAll('.tooltipped');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    });

    var xp_tooltip = '<div style="width:250px;padding:10px;"><div class="row headline-text" style="padding-bottom:10px;font-size:18px;border-bottom:1px solid white;">RACER XP</div>';
    xp_tooltip = xp_tooltip +'<div class="row" style="margin-bottom:0px;">XP (or Experience Points) are earned everytime you race. XP will be needed to gain access to weekly tournaments and unlock features in game.</div></div>';

    var exr_tooltip = '<div style="width:250px;padding:10px;"><div class="row headline-text" style="padding-bottom:10px;font-size:18px;border-bottom:1px solid white;">EXR COIN</div>';
    exr_tooltip = exr_tooltip +'<div class="row" style="margin-bottom:0px;">EXR Coin is an in game currency earned when you race. EXR can be used to recharge boosters, enter weekly tournaments, unlock workshop space and stake in private races.</div></div>';


    return(<div className="row white-text page-width top-nav-header">
                <nav className="transparent">
                <div className="col s12 transparent nav-wrapper" style={{marginTop:'10px'}}>
                        <div className="top-nav-left-logo brand-logo">
                            <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" height="50px" className="cursor-pointer" onClick={()=>goToWorkshop()} />
                        </div>
                        <ul className="right hide-on-med-and-down" style={{marginTop:'15px'}}>
                            <li className="top-nav-header-right-item dropdown-trigger" data-target="settings-dropdown" style={{lineHeight:'1.5'}}>
                                <span className="top-nav-header-right-profile-icon">
                                    <img src="https://media.exiledracers.com/assets/pilots/base_pilot_small.png" height="37px" style={{margin:'-45%'}} />
                                </span>
                                <span className="top-nav-header-right-item-text" id="current-racer-handle">
                                    {racerHandle.toUpperCase()}
                                </span>
                                <i class="material-icons" style={{lineHeight:'1.5',paddingLeft:'10px'}}>arrow_drop_down</i>
                            </li>
                            <li className="top-nav-header-right-item tooltipped" style={{lineHeight:'1.5',cursor:'context-menu'}} data-position="bottom" data-tooltip={xp_tooltip} >
                                <span className="top-nav-header-right-item-icon">
                                    <img src="https://media.exiledracers.com/global/xp_icon_white.png" height="35px" />
                                </span>
                                <span className="top-nav-header-right-item-text">
                                    {racerXP.toLocaleString("en-US")}
                                </span>
                            </li>
                            <li className="top-nav-header-right-item tooltipped" style={{lineHeight:'1.5',cursor:'context-menu'}} data-position="bottom" data-tooltip={exr_tooltip}>
                                <span className="top-nav-header-right-item-icon" style={{marginTop:'-1px'}}>
                                    <img src="https://media.exiledracers.com/global/coin_icon_white2.png" height="37px" />
                                </span>
                                <span className="top-nav-header-right-item-text" style={{marginLeft:'7px'}}>
                                    {racerCoins.toLocaleString("en-US")}
                                </span>
                            </li>
                            </ul>
                    </div>
                </nav>
                <ul id="settings-dropdown" class="dropdown-content transparent subheadline-text">
                    <li className="white center-align white-red-button" style={{borderRadius:'2px',marginTop:'12px'}}>
                        <a className="modal-trigger black-text white-red-button center-align" data-target="settingsbox" style={{borderRadius:'2px',fontSize:'14px',fontWeight:'900'}}>SETTINGS</a>
                    </li>
                    <li className="white-red-button center-align" style={{borderRadius:'2px',fontSize:'12px',marginTop:'10px'}}>
                        <a className="black-text white-red-button center-align" onClick={()=>logoutGame()} style={{borderRadius:'2px',fontSize:'14px',fontWeight:'900'}}>LOGOUT</a>
                    </li>
                </ul>
                <SettingsModal />
        </div>);
};