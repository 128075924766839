import {lerp} from "three/src/math/MathUtils.js";

class ClipAnchor {
    time;
    rate;

    /**
     * Construct a clip anchor
     * @param {number} time The absolute time
     * @param {number} rate The playback rate
     */
    constructor(time, rate) {
        this.time = time;
        this.rate = rate;
    }
}

export class ClipRate {
    #anchors = [];

    /**
     * Add a time anchor
     * @param {number} time The absolute time
     * @param {number} rate The playback rate
     */
    addAnchor(time, rate) {
        const anchor = new ClipAnchor(time, rate);
        const anchorCount = this.#anchors.length;

        for (let index = 0; index < anchorCount; ++index) {
            if (this.#anchors[index].start > time) {
                this.#anchors.splice(index, 0, anchor);

                break;
            }
        }

        this.#anchors.push(anchor);
    }

    /**
     * Sample the clip rate
     * @param {number} time The time to sample at
     * @returns {number} The playback rate
     */
    sample(time) {
        if (this.#anchors.length === 0)
            return 1;

        if (this.#anchors.length === 1)
            return this.#anchors[0].rate;

        let anchor = this.#anchors.length - 1;

        for (;this.#anchors[--anchor].time >= time && anchor > 0;);

        const duration = this.#anchors[anchor + 1].time - this.#anchors[anchor].time;
        const interpolation = (time - this.#anchors[anchor].time) / duration;

        if (interpolation < 0)
            return this.#anchors[anchor].rate;
        else if (interpolation > 1)
            return this.#anchors[anchor + 1].rate;
        else
            return lerp(
                this.#anchors[anchor].rate,
                this.#anchors[anchor + 1].rate,
                .5 - .5 * Math.cos(Math.PI * interpolation));
    }
}