import * as THREE from "three";

/**
 * A track tracer
 */
export class TrackTracer {
    /**
     * Construct a track tracer
     * @param {Track} track The track to trace
     */
    constructor(track) {
        this.offset = 0;
        this.track = track;
        this.piece = track.start;
        this.lap = 0;
    }

    /**
     * Advance the tracer by a given distance
     * @param {number} distance The distance to advance
     * @returns {TrackTracer} This tracer
     */
    advance(distance) {
        this.offset += distance;

        while (this.offset > this.piece.length) {
            this.offset -= this.piece.length;

            if (this.piece.next)
                this.piece = this.piece.next;
            else {
                this.piece = this.track.start;

                ++this.lap;
            }
        }

        return this;
    }

    /**
     * Set the tracer position
     * @param {number} moved The moved distance
     * @returns {TrackTracer} This tracer
     */
    set(moved) {
        const trackLength = this.track.length;

        this.offset = 0;
        this.piece = this.track.start;

        return this.advance(moved - Math.floor(moved / trackLength) * trackLength);
    }

    /**
     * Trace the track
     * @param {Vector3} target The target vector to write to
     * @param {Vector3} [offset] The offset from the track center
     * @param {number} [distance] The distance from the tracer position
     * @param {boolean} [rotate] True if the offset should be rotated
     * @returns {number} The direction of the track at this position
     */
    trace(target, offset = new THREE.Vector3(), distance = 0, rotate = true) {
        let piece = this.piece;

        distance += this.offset;

        while (distance > piece.length) {
            distance -= piece.length;
            piece = piece.next || this.track.start;
        }

        const sampleAt = distance / piece.length;
        const direction = piece.derivative(sampleAt);
        const c = Math.cos(direction);
        const s = Math.sin(direction);

        piece.sample(target, sampleAt);

        if (rotate) {
            target.x += c * offset.x - s * offset.z;
            target.y += offset.y;
            target.z += s * offset.x + c * offset.z;
        }
        else
            target.add(offset);

        return direction;
    }
}