import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import useSWR from "swr";

import { useMetaMask } from "metamask-react";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import { isCommunityResourcable } from "@ethersproject/providers";
import {
  InjectedConnector,
  UserRejectedRequestError,
  NoEthereumProviderError,
} from "@web3-react/injected-connector";

let web3;

const HARDHAT_CHAIN_ID = 31337;
const MOONBASE_CHAIN_ID = 1287;
const MOONBEAM_CHAIN_ID = 1284;

const supportedChainIds = [
  HARDHAT_CHAIN_ID,
  MOONBASE_CHAIN_ID,
];

const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: supportedChainIds,
});


const fetcher = (url) => fetch(url).then((res) => res.json());

export const WalletSync = ({ address, index }) => {
  const { active } = useWeb3React();
  const dispatch = useDispatch();
  const { page_loaded }= useSelector((state) => state.app);
  const { racerID, accountAddress } = useSelector((state) => state.account);

  if((racerID.length > 1)&&(accountAddress.length > 1)) {
      dispatch(updateLocation('workshop'));
  }

  const [pageSet, setPage] = useState(false);

  async function updateWalletAddress() {
      let wallet_address = await syncAccountWallet();
      if(wallet_address) {
          if(wallet_address.length > 5) {
              document.getElementById("walletID").value = wallet_address;
              document.getElementById("WID").value = wallet_address;
              document.getElementById("walletlabel").style.display = 'none';
          }
      }
  }

  var intialStatus = 'Syncing EXR NFTs......';
  let currentInterval = 0;
  const[currentLoginStatus,setCurrentStatus] = useState(intialStatus);

  const [mintPackTokenQty, setMintPackTokenQty] = useState(0);
  let pilotTokenQty = 0;
  let racerTokenQty = 0;
  let boosterTokenQty = 0;

  const [mintPackCheck, setMintPackCheck] = useState(0);
  const [pilotCheck, setPilotCheck] = useState(0);
  const [racerCheck, setRacerCheck] = useState(0);
  const [boosterCheck, setBoosterCheck] = useState(0);

  const [mintPackUnopen, setMintPackUnopen] = useState(false);
  const [mintPackImage,  setMintPackImage] = useState('');
  const [mintPackIDs, setMintPackID] = useState('');

  let pilotTokenIDs = [];
  let racerTokenIDs = [];
  let boosterTokenIDs = [];

  const [tokenUri, setTokenUri] = useState("");
  const [id, setId] = useState(null);
  const [isAlreadyOpen, setIsAlreadyOpen] = useState(false);
  const { data, error } = useSWR(tokenUri, fetcher);
  const { mintPackContract, pilotContract, racerContract, boosterContract } = useSelector((state) => state.web3);
  const { account, chainId } = useWeb3React();

  let mintpackInterval;
  let hasMintPack = false;
  let hasMintPackUnopen = false;
  var basecheck = <i className="material-icons white-text">check</i>;

  // useEffect(() => {
  //   if (!mintPackContract) return;

  //   mintpackInterval = setInterval(checkMintPacks, 5000);
  //   checkMintPacks();
    
  //   return () => clearInterval(mintpackInterval);

  // }, [mintPackContract]);

  function startLoginOne() {
    mintpackInterval = setInterval(checkMintPacks, 5000);
    checkMintPacks();
    
    return () => clearInterval(mintpackInterval);
  }

  function checkMintPacks() {
    //console.log({ hasMintPack });
    currentInterval = currentInterval+1;
    if(hasMintPack) {
      setTimeout(function() { loginStepTwo(); },1000);
      clearInterval(mintpackInterval);
      console.log("Clearing Interval: "+currentInterval);
      
      return;
    }
    if(currentInterval == 3) {
      setTimeout(function() { loginStepTwo(); },1000);
      clearInterval(mintpackInterval);
      console.log("Clearing Interval: "+currentInterval);
      return;
    }
    //console.log("checking mint pack");
    checkPilotItems();
    checkRacerItems();
    checkBoosterItems();
    mintPackContract
      .balanceOf(account)
      .then((qty) => {
        var mpID = qty.toNumber();
        setMintPackTokenQty(mpID);
        
        /* If Mint Pack Exists, Check For Open Mint Packs */
        if(mpID > 0) {
          setMintPackCheck(basecheck);
          for(var i = 0; i < mpID;i++) {
            var tmpid = mintPackOpenCheck(i);
          }
        } else {
          setMintPackCheck('X');
        }
        hasMintPack = true;
      })
      .catch((err) => {
        console.log("err");
      });
  }

  async function mintPackOpenCheck(mintPackIndex) {
    try {
      const tempId = await mintPackContract.tokenOfOwnerByIndex(
        account,
        mintPackIndex
      );
      const uri = await mintPackContract.tokenUri(tempId.toNumber());
      const hasBeenOpened = await mintPackContract.checkPackOpenState(
        tempId.toNumber()
      );
      //console.log('Has Pack Opened: '+hasBeenOpened);
      if(hasBeenOpened === false) {
        hasMintPackUnopen = true;
        setMintPackUnopen(true);
        setMintPackImage(uri);
        setMintPackID(tempId);
        //console.log(uri);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function checkPilotItems() {
    if(supportedChainIds.includes(chainId)) {
      pilotContract
        .balanceOf(account)
        .then((qty) => {
          var itemCount = qty.toNumber();
          //console.log("num pilots: ", itemCount);
          pilotTokenQty = itemCount;
          for(var i = 0; i < itemCount;i++) {
            updatePilotID(i);
          }
        })
        .catch((err) => console.log(err));
    }
      
  }

  function checkRacerItems() {
    if(supportedChainIds.includes(chainId)) {
      racerContract
        .balanceOf(account)
        .then((qty) => {
          var itemCount = qty.toNumber();
          //console.log("num racers: ", itemCount);
          racerTokenQty = itemCount;
          for(var i = 0; i < itemCount;i++) {
            updateRacerID(i);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  function checkBoosterItems() {
    if(supportedChainIds.includes(chainId)) {
      boosterContract
        .balanceOf(account)
        .then((qty) => {
          var itemCount = qty.toNumber();
          //console.log("num boosters: ", itemCount);
          boosterTokenQty = itemCount;
          for(var i = 0; i < itemCount;i++) {
            updateBoosterID(i);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  async function updatePilotID(itemIndex) {
    try {
      const itemID = await pilotContract.tokenOfOwnerByIndex(account, itemIndex);
      pilotTokenIDs.push(itemID.toNumber());
      return itemID.toNumber();

    } catch (err) {
      console.log(err);
    }
  }

  async function updateRacerID(itemIndex) {
    try {
      const itemID = await racerContract.tokenOfOwnerByIndex(account, itemIndex);
      racerTokenIDs.push(itemID.toNumber());
      return itemID.toNumber();

    } catch (err) {
      console.log(err);
    }
  }

  async function updateBoosterID() {
    try {
      const itemID = await boosterContract.getBalanceOf(account, 1);
      boosterTokenIDs.push(itemID.toNumber());
      return itemID.toNumber();

    } catch (err) {
      console.log(err);
    }
  }

  function loginStepTwo() {
    //console.log(pilotTokenIDs);
    if((hasMintPackUnopen == false)&&(pilotTokenQty == 0)&&(racerTokenQty == 0)&&(boosterTokenQty == 0)) {
      /* No Mint Pack or NFTs - Send Back to Homepage */
      alert('Couldn\'t Find Your EXR NFTs. Make Sure you\'re logged into the correct Wallet and Switched to the Moonbase Alpha Network.');
      window.$("#loginstatus").hide();
      window.$("#metamaskconnect").show();
      document.getElementById("loginform").style.display = 'none';
      document.getElementById("loginheader").style.display = 'none';
      document.getElementById("errorheader").style.display = 'block';
      document.getElementById("confirmerror").style.display = 'block';

    } else if((hasMintPackUnopen == true)&&(pilotTokenQty == 0)&&(racerTokenQty == 0)&&(boosterTokenQty == 0)) {
      /* Unopned Mint Pack, No NFTs - Send to Show Mint Pack Page */
      setCurrentStatus('Open Mint Pack to Enter Game.');
      goToMintPage();
    } else if((hasMintPackUnopen == true)&&(pilotTokenQty > 0)&&(racerTokenQty > 0)&&(boosterTokenQty > 0)) {
      /* Unopened Mint Pack + Existing NFTs - Give Option of Mint Pack Page or Workshop */
      setCurrentStatus('Open Mint Pack or Go To Workshop with Your EXR NFTs.');
      window.$("#unpackorshop").show();
      window.$("#formsubmitsection").hide();
      window.$("#loginstatus").hide();
    } else if((hasMintPackUnopen == false)&&(pilotTokenQty > 0)&&(racerTokenQty > 0)&&(boosterTokenQty > 0)) {
      /* No Mint Pack + Existing NFTs - Go to Workshop */
      setCurrentStatus('Found EXR NFTs. Syncing to Game.');
      submitAlphaLogin();
    }
  }

  async function submitAlphaLogin(loginsource) {
      var walletID = document.getElementById("walletID").value;

      var addData = new Object();
      addData.walletID = encodeURIComponent(walletID);
      if(loginsource == 'split') {
        window.$("#workshopsubmit").hide();
        window.$("#loginloading2").show();
      }
      axiosInstance.post('member/alpha_login/',addData).then(result => {
          if(Object.keys(result.data).length > 0) {
              var login_result = result.data;
                  
              if(login_result.login_status.includes("success")) {
                  let racerID = login_result.racer_id;
                  dispatch(setRacerLogin(walletID,racerID));
                  //dispatch(updateLocation("workshop"));
                  
                  setTimeout(function() {
                    dispatch(updateLocation("racer_home"));
                    window.location.href = '/#/racer_home';
                    // dispatch(updateLocation("track_uploader"));
                    // window.location.href = '/#/track_uploader';
                  }, 1500);
              } else {
                  setTimeout(function() {
                      document.getElementById("loginform").style.display = 'none';
                      document.getElementById("loginheader").style.display = 'none';
                      document.getElementById("errorheader").style.display = 'block';
                      document.getElementById("confirmerror").style.display = 'block';
                  }, 3000);
              }
          }
      });
  }

  function startLoginProcess(e) {
    e.preventDefault();

    //var email = document.getElementById("email").value;
    var walletID = document.getElementById("walletID").value;

    var addData = new Object();
    //addData.email = encodeURIComponent(email);
    addData.walletID = encodeURIComponent(walletID);

    if(walletID.length < 10) {
        alert('Connect Your Metamask Wallet to Register');
    } else {
        document.getElementById("loginsubmit").style.display = 'none';
        document.getElementById("loginloading").style.display = 'block';
        window.$("#loginstatus").show();
        window.$("#metamaskconnect").hide();
        startLoginOne();
    }
  }

  function goToMintPage() {
    //console.log('CLicked to Go To Mint Page');
    window.location.href="/#/mint";
  }


  return (
    <div>
      
      <div className="modal-content white">
                <div className="row">
                    <div className="col s12" style={{marginBottom:'15px'}}>                    
                        <h5 id="loginheader" className="headline-text left" style={{marginBottom:'5px'}}>ALPHA LOGIN</h5>
                        <h5 id="errorheader" className="headline-text left splash-modal-title-alt">NOT AUTHORIZED</h5>
                        <img src="https://media.exiledracers.com/global/exr_logo_v2_black_trans.png" height="40px" className="right hide-on-small-only" />
                    </div>
                    <div id="confirmerror" className="col s12 subheadline-text splash-modal-confirmtxt">
                        You're not registered for the Exiled Racers Alpha. 
                        <br /><br />
                        The EXR Alpha is open to a limited set of early testers. Use the Request Access Button on the Exiled Racers homepage to register for the mint pass lottery.
                        <br /><br />
                        - The EXR Team
                    </div>
                    <form id="loginform" className="subheadline-text" style={{fontSize:'14px'}} onSubmit={startLoginProcess}>
                        <div className="col s12" style={{fontSize:'16px',marginBottom:'10px'}}>
                          Exiled Racers is in a limited Alpha. If you have been given access as an Alpha Tester, login below.
                        </div>
                        <div className="col s12 m12 input-field">
                            <input name="WID" id="WID" type="text" className="validate left" disabled required />
                            <label id="walletlabel" htmlFor="WID" className="black-text">Wallet Address</label>
                        </div>
                        <div className="col s12 m12" id="metamaskconnect">
                            <div id="mmasksync" className="btn-flat csr-button-black left enableEthereumButton splash-submodal-button"
                                title="Connect MetaMask Wallet"
                                onClick={updateWalletAddress}>
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">CONNECT METAMASK</span>
                                <span className="splash-main-button-bracket">]</span>
                            </div>
                        </div>
                        <div className="col s12 m12 headline-text" id="loginstatus" style={{display:'none'}}>
                          {currentLoginStatus}
                        </div>
                        <div id="formsubmitsection" className="col s12" style={{marginTop:'15px'}}>
                            <input name="walletID" id="walletID" type="hidden" className="validate left" disabled required />
                            <button id="loginsubmit" className="btn-flat csr-button-black right splash-modal-button"
                                type="submit" title="Testnet Login">
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">SUBMIT</span>
                                <span className="splash-main-button-bracket">]</span>
                            </button>
                            <div id="loginloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div id="unpackorshop" className="col s12" style={{marginTop:'15px',display:'none'}}>
                        <button id="mintpacksubmit" className="btn-flat csr-button-black left splash-modal-button"
                            title="Open Your Mint Pack" onClick={()=>goToMintPage()}>
                            <span className="splash-main-button-bracket">[</span>
                            <span className="headline-text">VIEW NEW MINTPACK</span>
                            <span className="splash-main-button-bracket">]</span>
                        </button>
                        <button id="workshopsubmit" className="btn-flat csr-button-black right splash-modal-button"
                            title="Go To Workshop" onClick={()=>submitAlphaLogin('split')}>
                            <span className="splash-main-button-bracket">[</span>
                            <span className="headline-text">GO TO WORKSHOP</span>
                            <span className="splash-main-button-bracket">]</span>
                        </button>
                        <div id="loginloading2" className="preloader-wrapper small active right splash-modal-loader">
                            <div className="spinner-layer spinner-red-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  );
};
