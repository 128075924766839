import {CameraController} from "../cameraController.js";

/**
 * A static finish line camera
 */
export class CameraControllerFinish extends CameraController {
    /**
     * Construct a finish camera controller
     * @param {Camera} camera The camera to control
     * @param {Vector3} origin The camera origin
     * @param {Vector3} target The camera target
     * @param {number} distance The distance to the finish line after which this camera triggers
     */
    constructor(camera, origin, target, distance) {
        super(camera);

        this.origin = origin;
        this.target = target;
        this.distance = distance;
    }

    /**
     * Set the target for this camera controller
     * @param {CameraTargetable} target The target to track
     * @param {CameraTargetable[]} targets All targets that can be tracked
     * @param {Track} track The track
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {boolean} [instant] True if the change should be instant
     */
    setTarget(target, targets, track, time, instant = false) {
        this.camera.position.copy(this.origin);
        this.camera.lookAt(this.target.x, this.target.y, this.target.z);
    }
}