import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../Sportsbook/sportsbook.css";
import "./pickem_host.css";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals} from "../../../utils";
import {RaceViewer} from "./Sections";
import $ from 'jquery';

let web3;
let racer_sim_watched_global = 0;
let race_simulated_global = 0;
let current_racer_global = 0;
let racer_invite_status_global = 0;
let race_hash_global = '';
let current_race_hash = '';
let current_race_group = 6;
let open_invite_race = 0;
let open_entry_closed = 0;
let predictions_closed = 0;
let entryDisplay = 'none';
let training_box_race_landing = 0;
let current_time_schedule = 0;
let scheduleready = 0;
let schedulestarted = 0;
let race_schedule_array = [];
let current_sort = 'recent';
let current_filter = 'groupid_6';
let current_group_array = [];
let pickem_admin = [1,3,5,55,56,61];

export const PickemHost = () => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    
    if((racerID != 1)&&(racerID != 3)&&(racerID != 5)) {
        dispatch(updateLocation("workshop"));
        window.location.href= '/#/workshop';
    }

    var url_race_unique_id = '';
    var url_race_invite_list = '';
    var start_race_section = '';
    var tmp_left_nav_section = 'upcoming';
    var racehash = window.location.href.split("#/");
    if(racehash.length > 1) {
        var racehash2 = racehash[1].replace("#/",'');
        var racehash3 = racehash2.split("/");
        if(racehash3.length > 1) {
            url_race_unique_id = racehash3[1].replace("/",'');
        }
        if((racehash3.length > 2)&&(url_race_unique_id == 'invite')) {
            url_race_invite_list = racehash3[2].replace("/",'');
        }
        if(racehash3[0] == 'race') {
            start_race_section = 'details';
        } else {
            var racehash4 = racehash3[0].split("_");
            start_race_section = racehash4[1];
        }
    }
    race_hash_global = url_race_unique_id;

    const [pageSet, setPage] = useState(false);
    const [headerSet, setHeader] = useState(false);
    const [race_id, setRaceID] = useState(0);
    const [raceHash, setRaceHash] = useState('');
    const [raceViewerHTML, setRaceViewerHTML] = useState('');
    const [selected_prediction_html,setSelectedPredictionHTML] = useState('');
    

    var racer_items = [];
    function grabRacerRaces(pagenum) {
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.sortBy = encodeURIComponent(current_sort);
        addData.filterBy = encodeURIComponent(current_filter);
        // addData.raceStatus = 'past';
        addData.raceStatus = 'live_pickem';
        addData.page = pagenum;
        addData.rcount = 20;
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('sportsbook/recent_races/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;
                var next_race_hash = racer_details[0]['race_unique_id'];
                updateRaceViewer(next_race_hash)
            } else {
                setRaceViewerHTML(<RaceViewer raceID={'waitroom'} />)
            }
        }).catch(error => {
            console.log(error);
            setRaceViewerHTML(<RaceViewer raceID={'waitroom'} />)
        });
    }

    function updateRaceViewer(newRaceHash) {
        if(newRaceHash.length > 0) {
            current_race_hash = newRaceHash;
            setRaceHash(newRaceHash);
            setRaceViewerHTML(<RaceViewer raceID={newRaceHash} />)
        }
    }

    if(pageSet == false) {
        setPage(true);
        window.startPageModals();
        window.resetBodyWidth();
        grabRacerRaces(0);
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            <div className="row white-text page-width-wide top-nav-header-full">
                {raceViewerHTML}
            </div>
        </div>);
};
