import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import axios from "axios";
import { updateLocation, signupLoading } from "../../../redux/App/actions";
import { setUserSignOn, clearUserSignOn, setLoginWallet } from "../../../redux/Account/actions";
import {axiosInstance, encryptLoginData} from "../../../utils";
import {InjectedConnector, } from "@web3-react/injected-connector";
import $ from "jquery";

let web3;

export const LoginCheck = () => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { userID, racerID, spectatorID, loginToken, loginTime } = useSelector((state) => state.account);
    const { chainId, account } = useWeb3React();
    const [pageSet, setPage] = useState(false);

    function checkLoginCredentials() {
        let redirect_cred = '';
        let redirect_link = '';
        if(window.location.href.indexOf('/redirect?p=') >= 0) {
            let redirectsplit = window.location.href.split("redirect?p=");
            if (redirectsplit.length > 1) {
                let redirectsplit2 = redirectsplit[1].split("&");
                if (redirectsplit2.length > 1) {
                    redirect_cred = redirectsplit2[1];
                    redirect_link = redirectsplit2[0];
                }
            }
        }

        if((redirect_cred.length > 5)&&(redirect_link.length > 4)) {
            checkLoginRedirectCredentials(redirect_cred,redirect_link);
        } else {
            let hashed_time = encryptLoginData('login',loginTime.toString());
            var addData = new Object();
            addData.userToken1 = encodeURIComponent(loginToken);
            addData.userToken2 = encodeURIComponent(hashed_time);
            if((loginToken !== undefined)&&(loginToken.length > 5)&&(loginTime.length > 5)) {
                axiosInstance.post('member/login_status/',addData).then(result => {
                    if(Object.keys(result.data).length > 0) {
                        var data_result = result.data;
                        if(data_result.length > 0) {
                            let userid = data_result[0]['user_id'];
                            let racerid = data_result[0]['racer_id'];
                            let spectatorid = data_result[0]['spectator_id'];
                            let logintime = data_result[0]['time'];
                            let logintoken = data_result[0]['token'];
                            dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));
                            setTimeout(function(){
                                checkLoginCredentials();
                            },300000);
                        } else {
                            dispatch(clearUserSignOn('0'));
                            dispatch(updateLocation("splash"));
                            window.location.href= '/';
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            } else if((loginToken.length > 5)||(loginTime.length > 5)) {
                dispatch(clearUserSignOn());
                dispatch(updateLocation("splash"));
                window.location.href= '/';
                setTimeout(function(){
                    checkLoginCredentials();
                },60000);
            } else {
                setTimeout(function(){
                    checkLoginCredentials();
                },60000);
            }
        }
    }

    function checkLoginRedirectCredentials(redirecttoken,redirecturl) {
        let hashed_time = encryptLoginData('login',loginTime.toString());
        var addData = new Object();
        addData.redirectToken = encodeURIComponent(redirecttoken);
        if((redirecttoken !== undefined)&&(redirecttoken.length > 5)) {
            axiosInstance.post('member/login_redirect/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var data_result = result.data;
                    if(data_result.length > 0) {
                        let userid = data_result[0]['user_id'];
                        let racerid = data_result[0]['racer_id'];
                        let spectatorid = data_result[0]['spectator_id'];
                        let logintime = data_result[0]['time'];
                        let logintoken = data_result[0]['token'];
                        let wallet_id = data_result[0]['wallet_id'];
                        dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));
                        dispatch(setLoginWallet(wallet_id));
                        setTimeout(function(){
                            window.location.href='/#/'+redirecturl;
                        },100);
                    } else {
                        dispatch(clearUserSignOn('0'));
                        dispatch(updateLocation("splash"));
                        window.location.href= '/';
                    }
                } else {
                    dispatch(clearUserSignOn('0'));
                    dispatch(updateLocation("splash"));
                    window.location.href= '/';
                }
            }).catch(error => {
                console.log(error);
                dispatch(clearUserSignOn('0'));
                dispatch(updateLocation("splash"));
                window.location.href= '/';
            });
        } else {
            dispatch(clearUserSignOn('0'));
            dispatch(updateLocation("splash"));
            window.location.href= '/';
        }
    }

    function logoutEXRApp() {
        dispatch(clearUserSignOn('0'));
        dispatch(updateLocation("splash"));
        window.location.href= '/';
        setTimeout(function(){
            checkLoginCredentials();
        },60000);
    }

    if(pageSet === false) {
        setPage(true);
        checkLoginCredentials();
    }
    
    return(<></>);
};
