import { request, gql } from 'graphql-request';

const endpoint = 'https://squid.subsquid.io/exr-spaceport/v/v1/graphql';

export const getIndexerUserTokens = async (address) => {
    var final_address = address.toLowerCase();
    const query = gql`
    {
        accountById(id: "${final_address}") {
            id
            ownedBoosters {
                id
                qty
            }
            ownedTokens {
                id
            }
        }
    }`;

    try {
        const data = await request(endpoint, query);
        return data.accountById;
    } catch (error) {
        console.error(error);
        return null;
    }
}