import * as THREE from "three";

export class CameraControllerOrbit {
    static FRICTION = .1;
    static ZOOM_SPEED = .1;
    static PITCH_RANGE = new THREE.Vector2(Math.PI * -.45, Math.PI * .45);
    static ZOOM_RANGE = new THREE.Vector2(4, 200);

    /**
     * Construct orbit camera controls for the track editor
     * @param {Camera} camera The camera to control
     * @param {HTMLElement} element The element to listen for events on
     * @param {number} [sensitivityRotation] Rotate sensitivity
     * @param {number} [sensitivityPan] Pan sensitivity
     */
    constructor(
        camera,
        element,
        sensitivityRotation = 7,
        sensitivityPan = 2.5) {
        this.camera = camera;
        this.mouseDown = false;
        this.sensitivityRotation = sensitivityRotation;
        this.sensitivityPan = sensitivityPan;
        this.zoom = 100;
        this.pitch = Math.PI * .12;
        this.angle = 0;
        this.pitchSpeed = 0;
        this.angleSpeed = 0;
        this.origin = new THREE.Vector2();
        this.originVelocity = new THREE.Vector2();

        this.addEventListeners(element);
    }

    /**
     * Add mouse event listeners
     * @param {HTMLElement} element The element to listen for events on
     */
    addEventListeners(element) {
        let mx = 0;
        let my = 0;
        let button = -1;

        document.addEventListener("contextmenu", event => event.preventDefault());

        element.addEventListener("mousedown", event => {
            mx = event.clientX;
            my = event.clientY;
            button = event.button;

            this.mouseDown = true;
        });

        element.addEventListener("mousemove", event => {
            if (this.mouseDown) {
                let dx, dy;

                switch (button) {
                    case 0:
                        dx = this.sensitivityRotation * (event.clientX - mx) / window.innerWidth;
                        dy = this.sensitivityRotation * (event.clientY - my) / window.innerHeight;

                        this.angleSpeed += dx;
                        this.pitchSpeed += dy;
                        this.angle += dx;
                        this.pitch += dy;

                        this.limitPitch();

                        break;
                    case 2:
                        dx = this.sensitivityPan * (event.clientX - mx) / window.innerWidth;
                        dy = this.sensitivityPan * (event.clientY - my) / window.innerHeight;

                        const vx = this.zoom * (-Math.sin(this.angle) * dx - Math.cos(this.angle) * dy);
                        const vy = this.zoom * (-Math.sin(this.angle) * dy + Math.cos(this.angle) * dx);

                        this.originVelocity.x += vx;
                        this.originVelocity.y += vy;
                        this.origin.x += vx;
                        this.origin.y += vy;

                        break;
                }

                mx = event.clientX;
                my = event.clientY;
            }
        });

        element.addEventListener("mouseup", () => {
            this.mouseDown = false;
        });

        element.addEventListener("wheel", event => {
            if (event.deltaY > 0)
                this.zoom *= 1 + CameraControllerOrbit.ZOOM_SPEED;
            else
                this.zoom *= 1 - CameraControllerOrbit.ZOOM_SPEED;

            this.limitZoom();
        });
    }

    /**
     * Limit pitch
     */
    limitPitch() {
        if (this.pitch > CameraControllerOrbit.PITCH_RANGE.y) {
            this.pitch = CameraControllerOrbit.PITCH_RANGE.y;
            this.pitchSpeed = 0;
        }
        else if (this.pitch < CameraControllerOrbit.PITCH_RANGE.x) {
            this.pitch = CameraControllerOrbit.PITCH_RANGE.x;
            this.pitchSpeed = 0;
        }
    }

    /**
     * Limit zoom
     */
    limitZoom() {
        if (this.zoom > CameraControllerOrbit.ZOOM_RANGE.y)
            this.zoom = CameraControllerOrbit.ZOOM_RANGE.y;
        else if (this.zoom < CameraControllerOrbit.ZOOM_RANGE.x)
            this.zoom = CameraControllerOrbit.ZOOM_RANGE.x;
    }

    /**
     * Update the state
     */
    update() {
        if (this.mouseDown) {
            this.pitchSpeed = 0;
            this.angleSpeed = 0;
            this.originVelocity.set(0, 0);
        }
        else {
            this.pitch += this.pitchSpeed;
            this.angle += this.angleSpeed;
            this.origin.add(this.originVelocity);

            this.limitPitch();

            this.pitchSpeed -= this.pitchSpeed * CameraControllerOrbit.FRICTION;
            this.angleSpeed -= this.angleSpeed * CameraControllerOrbit.FRICTION;
            this.originVelocity.x -= this.originVelocity.x * CameraControllerOrbit.FRICTION;
            this.originVelocity.y -= this.originVelocity.y * CameraControllerOrbit.FRICTION;
        }
    }

    /**
     * Update the state before rendering
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        const pitch = this.pitch + (this.mouseDown ? 0 : time * this.pitchSpeed);
        const angle = this.angle + (this.mouseDown ? 0 : time * this.angleSpeed);
        const x = this.origin.x + (this.mouseDown ? 0 : time * this.originVelocity.x);
        const y = this.origin.y + (this.mouseDown ? 0 : time * this.originVelocity.y);

        this.camera.position.set(
            x + this.zoom * Math.cos(angle) * Math.cos(pitch),
            this.zoom * Math.sin(pitch),
            y + this.zoom * Math.sin(angle) * Math.cos(pitch));
        this.camera.lookAt(x, 0, y);
    }
}