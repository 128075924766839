import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerXP, setRacerCoins } from "../../../redux/Account/actions";
import { clearUserSignOn } from "../../../redux/Account/actions";
import {axiosInstance,closeAllModals} from "../../../utils";
import {SettingsModal} from "../../Pages/Settings";
import $ from "jquery";
let web3;

export const GlobalHeaderGrey = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress, userID, spectatorID, loginToken, loginTime } = useSelector((state) => state.account);
    const { admin_racers, live_hosts } = useSelector((state) => state.app);

    if(racerID.length == 0) {
        dispatch(clearUserSignOn());
        dispatch(updateLocation("splash"));
        window.location.href= '/';
    }

    const [headerSet, setHeader] = useState(false);
    const [racerXP, setXP] = useState(0);
    const [racerCoins, setCoins] = useState(0);
    const [racerTrainingLeft, setTrainingLeft] = useState(0);
    const [racerBattlesLeft, setBattlesLeft] = useState(0);
    const [racerFirstName, setFirstName] = useState('Racer');
    const [racerHandle, setRacerHandle] = useState('Racer');

    let testing_site = 1;
    let testing_banner = 'block';
    for(var i = 0; i < live_hosts.length;i++){
        if (live_hosts[i] == window.location.host) {
            testing_site = 0;
            testing_banner = 'none';
        }
    }

    function grabRacerInfo() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.userID = encodeURIComponent(userID);
        addData.raceHost = encodeURIComponent(window.location.host);
        
        axiosInstance.post('member/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;

                // Set Racer XP, Coins and Name
                if (testing_site == 0) {
                    setXP(racer_details[0].racer_xp);
                    setCoins(racer_details[0].racer_coins);
                } else {
                    setXP(racer_details[0].racer_xp_test);
                    setCoins(racer_details[0].racer_coins_test);
                }
                
                setFirstName(racer_details[0].first_name);
                setRacerHandle(racer_details[0].racer_name);
                setTrainingLeft(racer_details[0].daily_race_training_left);
                setBattlesLeft(racer_details[0].daily_race_invites_left);
                $("#current-racer-handle").val(racer_details[0].racer_name);
                $("#current-racer-traces").val(racer_details[0].daily_race_training_left);
                $("#current-racer-iraces").val(racer_details[0].daily_race_invites_left);
                $("#current-racer-oraces").val(racer_details[0].daily_open_races_left);
                $("#current-racer-preds").val(racer_details[0].daily_predictions_left);
                $("#current-racer-ibox-race-home").val(racer_details[0].training_race_home_box);
                $("#current-racer-ibox-race-landing").val(racer_details[0].training_race_landing_box);
                $("#current-racer-ibox-leaderboard").val(racer_details[0].training_leaderboard_box);
            }
        }).catch(error => {
            console.log(error);
            dispatch(updateLocation("splash"));
        });
    }

    function goToRacerHome() {
        window.location.href= '/#/workshop';
        dispatch(updateLocation("workshop"));
    }
    function goToRacesHome() {
        dispatch(updateLocation("races_home"));
        if(window.location.href.indexOf('races_home/leagues') > 0) {
            window.location.href= '/#/races_home';
            window.location.reload();
        } else {
            window.location.href= '/#/races_home';
        }
    }
    function goToLeaguesHome() {
        if(window.location.href.indexOf('races_home/leagues') == -1) {
            if(window.location.href.indexOf('races_home') > 0) {
                window.location.href= '/#/races_home/leagues';
                window.location.reload();
            } else {
                window.location.href= '/#/races_home/leagues';
            }
        } else {
            window.location.href= '/#/races_home/leagues';
        }
    }

    function goToRacewayHome() {
        window.location.href= '/#/raceway';
        dispatch(updateLocation("raceway"));
    }
    function goToLeaderboards() {
        window.location.href= '/#/open_leaderboard';
        dispatch(updateLocation("open_leaderboard"));
    }
    

    function goToRewards() {
        window.location.href= '/#/rewards';
        dispatch(updateLocation("rewards"));
    }

    function goToArenaHome() {
        window.location.href= '/#/raceway';
        dispatch(updateLocation("raceway"));
    }

    function logoutGame() {
        dispatch(clearUserSignOn());
        window.location.href= '/';
        dispatch(updateLocation("splash"));
    }

    function showReferralBox() {
        var infobox = document.getElementById('global_infobox_referrals');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
        
    }

    if(headerSet == false) {
        setHeader(true);
        grabRacerInfo();
        
    }

    window.$(document).ready(function(){
        window.startNavigationDropdown();
        window.startPageModals();
        var tooltips = document.querySelectorAll('.tooltipped');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
        var mnav = document.getElementById('mobile-header-nav');
	    var mainsidenav = window.M.Sidenav.init(mnav);
    });

    var xp_helper_text = 'XP (or Experience Points) are earned everytime you race. XP is needed to reach new levels in your training and missions and unlock features in the game.';
    var rpm_helper_text = `RPM (or Rare Processed Metal) is the most valuable commidity in the galaxy. 
    Mined by the evil megacorps, the Exiled rebels have been capturing RPM through stealth raids on enemy territory. 
    RPM is won through racing and can be used to enter new races, unlock tracks and collect rewards.`;

    var xp_tooltip = '<div class="row" style="width:250px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-15px;margin-bottom:15px;padding-bottom:10px;font-size:18px;border-bottom:1px solid white;">';
    xp_tooltip = xp_tooltip +'<div class="col s6 right-align" style="padding:0px;margin:0px;height:30px;overflow:hidden;"><img src="https://media.exiledracers.com/global/icons/exr_xp_icon.png" height="35px" /></div>';
    xp_tooltip = xp_tooltip +'<div class="col s6 left-align" style="padding:0px;margin:0px;margin-top:11px;">XP</div></div>';
    xp_tooltip = xp_tooltip +'<div class="row" style="margin-bottom:0px;">'+xp_helper_text+'</div></div>';

    var rpm_tooltip = '<div class="row" style="width:300px;padding:10px;margin:0px;"><div class="row center-align" style="margin:0px;margin-top:-15px;margin-bottom:15px;padding-bottom:10px;font-size:18px;border-bottom:1px solid white;">';
    rpm_tooltip = rpm_tooltip +'<div class="col s6 right-align" style="padding:0px;margin:0px;height:30px;overflow:hidden;"><img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="35px" /></div>';
    rpm_tooltip = rpm_tooltip +'<div class="col s6 left-align" style="padding:0px;margin:0px;margin-top:11px;">RPM</div></div>';
    rpm_tooltip = rpm_tooltip +'<div class="row" style="margin-bottom:0px;">'+rpm_helper_text+'</div></div>';

    var workshoplink = '';
    var raceslink = '';
    var racewaylink = '';
    var leaderboardlink = '';
    var rewardslink = '';
    var leagueslink = '';
    if((props.currpage == 'workshop')||(props.currpage == 'racer_home')) {
        workshoplink = ' white-fill-link-filled';
    } else if(window.location.href.indexOf('league') >= 0) {
        leagueslink = ' white-fill-link-filled';
    } else if((props.currpage == 'race')||(props.currpage == 'races_home')||(props.currpage == 'race_landing')) {
        raceslink = ' white-fill-link-filled';
    } else if((props.currpage == 'raceway')||(props.currpage == 'arena')||(props.currpage == 'sportsbook')) {
        racewaylink = ' white-fill-link-filled';
    } else if((props.currpage == 'open_leaderboard')) {
        leaderboardlink = ' white-fill-link-filled';
    } else if((props.currpage == 'trophy_case')||(props.currpage == 'rewards')||(props.currpage == 'achievements')) {
        rewardslink = ' white-fill-link-filled';
    }

    return(<div className="row white-text page-width-wide top-nav-header-full exr-new-dark-grey">
                <nav className="transparent z-depth-0">
                <div className="col s12 transparent nav-wrapper" style={{marginTop:'5px',marginBottom:'0px'}}>
                        <div className="left top-nav-left-logo-full brand-logo" style={{lineHeight:'1',marginBottom:'0px'}}>
                            <img src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" height="50px" className="cursor-pointer" onClick={()=>goToRacerHome()} />
                            <input type="hidden" id="current-racer-handle" />
                            <input type="hidden" id="current-racer-traces" />
                            <input type="hidden" id="current-racer-iraces" />
                            <input type="hidden" id="current-racer-oraces" />
                            <input type="hidden" id="current-racer-preds" />
                            <input type="hidden" id="current-racer-ibox-leaderboard" />
                            <input type="hidden" id="current-racer-ibox-race-home" />
                            <input type="hidden" id="current-racer-ibox-race-landing" />
                        </div>
                        <div data-target="mobile-header-nav" className="sidenav-trigger right show-on-med-and-down hide-on-large-only">
					    	<i className="top-nav-header-left-item material-icons" style={{fontSize:'28px',marginTop:'2px',marginBottom:'0px',cursor:'pointer'}}>menu</i>
					    </div>
                        <div className="left top-nav-header-left-section hide-on-small-only">
                            <div className={'top-nav-header-left-item hide-on-med-and-down white-fill-link'+workshoplink} style={{lineHeight:'1.5'}}  onClick={()=>goToRacerHome()} >
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Workshop
                                </div>
                            </div>
                            <div className={'top-nav-header-left-item hide-on-med-and-down white-fill-link'+raceslink} style={{lineHeight:'1.5'}}  onClick={()=>goToRacesHome()} >
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Races
                                </div>
                                <div id="header-new-races-text" className="top-nav-header-item-badge exr-red-bg white-text" style={{display:'none'}}>
                                </div>
                            </div>
                            <div className={'top-nav-header-left-item hide-on-med-and-down white-fill-link'+racewaylink} style={{lineHeight:'1.5',display:'none'}}  onClick={()=>goToRacewayHome()} >
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Raceway
                                </div>
                            </div>
                            <div className={'top-nav-header-left-item white-fill-link'+leaderboardlink} style={{lineHeight:'1.5',display:'none'}}  onClick={()=>goToLeaderboards()} >
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Rankings
                                </div>
                            </div>
                            <div className={'top-nav-header-left-item white-fill-link'+leagueslink} style={{lineHeight:'1.5'}}  onClick={()=>goToLeaguesHome()} >
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Leagues
                                </div>
                            </div>
                            <div className={'top-nav-header-left-item white-fill-link'+rewardslink} style={{lineHeight:'1.5'}}  onClick={()=>goToRewards()} >
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Rewards
                                </div>
                            </div>
                        </div>
                        <div className="right hide-on-med-and-down" style={{marginTop:'15px'}}>
                            <div className="top-nav-header-right-item-full" style={{lineHeight:'1.5',display:testing_banner}}>
                                <div title="You Are Using a Test Version of EXR" 
                                    className="top-nav-header-item-text exr-yellow-text" 
                                    style={{cursor:'help',border:'1px solid #FFF367', lineHeight:'1', marginTop:'7px',padding:'5px',borderRadius:'5px'}}>
                                    TEST MODE
                                </div>
                            </div>
                            <div className="top-nav-header-right-item-full tooltipped" style={{lineHeight:'1.5',cursor:'context-menu'}} data-position="bottom" data-tooltip={rpm_tooltip}>
                                <span className="top-nav-header-item-icon" style={{marginTop:'-1px'}}>
                                    <img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="37px" />
                                </span>
                                <span className="top-nav-header-item-text" style={{marginLeft:'7px'}}>
                                    {racerCoins.toLocaleString("en-US")}
                                </span>
                            </div>
                            <div className="top-nav-header-right-item-full  tooltipped" style={{lineHeight:'1.5',cursor:'context-menu'}} data-position="bottom" data-tooltip={xp_tooltip} >
                                <span className="top-nav-header-item-icon ">
                                    <img src="https://media.exiledracers.com/global/icons/exr_xp_icon.png" height="35px" />
                                </span>
                                <span className="top-nav-header-item-text">
                                    {racerXP.toLocaleString("en-US")}
                                </span>
                            </div>
                            <div className="modal-trigger top-nav-header-right-item-full white-fill-link" style={{lineHeight:'1.5'}} data-target="settingsbox">
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    My Account
                                </div>
                            </div>
                            <div className="top-nav-header-right-item-full white-fill-link" style={{lineHeight:'1.5'}} onClick={()=>showReferralBox()}>
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Invite
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <ul id="settings-dropdown" className="dropdown-content transparent subheadline-text">
                    <li className="white center-align white-red-button" style={{borderRadius:'2px',marginTop:'12px'}}>
                        <a className="modal-trigger black-text white-red-button center-align" data-target="settingsbox" style={{borderRadius:'2px',fontSize:'14px',fontWeight:'900'}}>SETTINGS</a>
                    </li>
                    <li className="white-red-button center-align" style={{borderRadius:'2px',fontSize:'12px',marginTop:'10px'}}>
                        <a className="black-text white-red-button center-align" onClick={()=>logoutGame()} style={{borderRadius:'2px',fontSize:'14px',fontWeight:'900'}}>LOGOUT</a>
                    </li>
                </ul>
                <SettingsModal />
                <ul className="sidenav exr-new-dark-grey" id="mobile-header-nav" style={{padding:'10px 20px'}}>
                    <div className="row" style={{padding:'0px 30px',marginTop:'10px',marginBottom:'10px'}}>
                        <img src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" height="50px" className="cursor-pointer" onClick={()=>goToRacerHome()} />
                    </div>
                    <div className="divider"></div>
                    <div className={'row white-fill-link'+workshoplink} style={{padding:'5px 30px',marginTop:'10px'}}  onClick={()=>goToRacerHome()} >
                        <div className="top-nav-header-item-bubble">
                            <div className="bubble-button"></div>
                        </div>
                        <div className="top-nav-header-item-text">
                            Workshop
                        </div>
                    </div>
                    <div className={'row white-fill-link'+raceslink} style={{padding:'5px 30px'}} onClick={()=>goToRacesHome()} >
                        <div className="top-nav-header-item-bubble">
                            <div className="bubble-button"></div>
                        </div>
                        <div className="top-nav-header-item-text">
                            Races
                        </div>
                        <div id="header-new-races-text" className="top-nav-header-item-badge exr-red-bg white-text" style={{display:'none'}}>
                        </div>
                    </div>
                    <div className={'row white-fill-link'+leagueslink} style={{padding:'5px 30px'}} onClick={()=>goToLeaguesHome()} >
                        <div className="top-nav-header-item-bubble">
                            <div className="bubble-button"></div>
                        </div>
                        <div className="top-nav-header-item-text">
                            Leagues
                        </div>
                    </div>
                    <div className={'row white-fill-link'+racewaylink} style={{padding:'5px 30px'}} onClick={()=>goToRacewayHome()} >
                        <div className="top-nav-header-item-bubble">
                            <div className="bubble-button"></div>
                        </div>
                        <div className="top-nav-header-item-text">
                            Raceway
                        </div>
                    </div>
                    <div className={'row white-fill-link'+leaderboardlink} style={{padding:'5px 30px'}} onClick={()=>goToLeaderboards()} >
                        <div className="top-nav-header-item-bubble">
                            <div className="bubble-button"></div>
                        </div>
                        <div className="top-nav-header-item-text">
                            Rankings
                        </div>
                    </div>
                    <div className={'row white-fill-link'+rewardslink} style={{padding:'5px 30px'}} onClick={()=>goToRewards()} >
                        <div className="top-nav-header-item-bubble">
                            <div className="bubble-button"></div>
                        </div>
                        <div className="top-nav-header-item-text">
                            Rewards
                        </div>
                    </div>
                    <div className={'row white-fill-link'+raceslink} style={{padding:'5px 30px'}} onClick={()=>showReferralBox()} >
                        <div className="top-nav-header-item-bubble">
                            <div className="bubble-button"></div>
                        </div>
                        <div className="top-nav-header-item-text">
                            Invite
                        </div>
                        <div id="header-new-races-text" className="top-nav-header-item-badge exr-red-bg white-text" style={{display:'none'}}>
                        </div>
                    </div>
                    <div className="row modal-trigger white-fill-link" style={{padding:'5px 30px'}} data-target="settingsbox">
                        <div className="top-nav-header-item-bubble">
                            <div className="bubble-button"></div>
                        </div>
                        <div className="top-nav-header-item-text">
                            My Account
                        </div>
                    </div>
                    <div className="row divider"></div>
                    <div className="row" style={{marginTop:'20px'}}>
                        <div className="top-nav-header-left-item tooltipped" style={{lineHeight:'1.5',cursor:'context-menu'}} data-position="bottom" data-tooltip={rpm_tooltip}>
                            <span className="top-nav-header-item-icon" style={{marginTop:'-1px'}}>
                                <img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="37px" />
                            </span>
                            <span className="top-nav-header-item-text" style={{marginLeft:'7px'}}>
                                {racerCoins.toLocaleString("en-US")}
                            </span>
                        </div>
                        <div className="top-nav-header-left-item tooltipped" style={{lineHeight:'1.5',cursor:'context-menu'}} data-position="bottom" data-tooltip={xp_tooltip} >
                            <span className="top-nav-header-item-icon ">
                                <img src="https://media.exiledracers.com/global/icons/exr_xp_icon.png" height="35px" />
                            </span>
                            <span className="top-nav-header-item-text">
                                {racerXP.toLocaleString("en-US")}
                            </span>
                        </div>
                    </div>
                </ul>
                <div id="global_infobox_referrals" className="modal page-modal page-modal-medium">
                    <div className="modal-content">
                        <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                            <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                                X
                            </div>
                            <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                                <div className="left headline-text" style={{marginLeft:'5px'}}>
                                INVITE RACERS
                                </div>
                            </div>
                            <div className="col s12" style={{padding:"0px 5px"}}>
                                <div className="divider-thin"></div>
                            </div>
                            <div className="col s12 subheadline-text" style={{marginTop:'10px',fontSize:'14px'}}>
                                <div className="row" style={{margin:'10px 0px',fontSize:'16px',marginBottom:'15px'}}>
                                    Invite your friends to spectate and predict on the EXR races. Use the referral link below to 
                                    allow them to create a free Spectator Account. No NFT's needed just an Email Address.
                                    <br/><br/>
                                    BONUS: The <a className="exr-light-green-text">First 3 Spectators</a> that sign up through your link will 
                                    add <a className="exr-light-green-text">15 XP</a> to each of your final XP Totals, helping you both climb the leaderboard and collect rewards.
                                    <br/><br/>
                                    Your Invite Link:<br/><input type="text" className="browser-default" style={{width:'80%',borderRadius:'10px',padding:'10px',backgroundColor:'#EBEBEB'}}
                                    value={"https://www.exiledracers.com/#/invite/"+racerHandle.replace(" ","")} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>);
};