export class Designed {
    /**
     * Construct an object with a design
     */
    constructor() {
        this.loaded = false;
    }

    /**
     * Get the design in JSON format
     * @returns {Object} The design in JSON format
     */
    getDesign() {
        return {};
    }

    /**
     * Unload the current design, if any
     */
    unload() {
        this.loaded = false;
    }

    /**
     * Load a design
     * @param {Object} design The design
     */
    load(design = null) {
        if (this.loaded)
            this.unload();

        this.loaded = true;
    }
}