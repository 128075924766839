import { useEffect, useState } from "react";
import { MintRacer } from "../../Organisms";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { Button } from "../../Atoms";
import { toast } from "react-toastify";

import {} from "../../../helpers/MintPackContract";

export const Admin = () => {
  const { racerID, accountAddress } = useSelector((state) => state.account);

  const { mintPackContract } = useSelector((state) => state.web3);
  const [address, setAddress] = useState("");
  const [txHash, setTxHash] = useState(null);
  const { account } = useWeb3React();

  function onTextChange(e) {
    setAddress(e.target.value);
  }

  if((accountAddress.toLowerCase() != '0x82998Ed7e475Ef3920749d1788dEab8Ca20e2998'.toLowerCase())&&
      (accountAddress.toLowerCase() != '0xefaa0f8e57f6280d46072b97473ac3f04b083f9d'.toLowerCase())&&
      (accountAddress.toLowerCase() != '0x174caAcA5263dccA693224818d97a430E4e64804'.toLowerCase())&&
      (accountAddress.toLowerCase() != '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266'.toLowerCase())&&
      (accountAddress.toLowerCase() != '0xa4464043350b1bd4f11ea345db9e1da2ff7b7dfb'.toLowerCase())&&
      (accountAddress.toLowerCase() != '0xA4464043350B1Bd4f11eA345DB9E1da2FF7b7DFB'.toLowerCase())&&
      (racerID != 8)&&
      (racerID != 3)&&
      (racerID != 5)&&
      (racerID != 7)) {
        window.location.href="/";
  }

  function mintPack(e) {
    e.preventDefault();

    console.log(mintPackContract);

    mintPackContract.subscribeToTransferEvent(account, (id) => {
      console.log("received a token: ", id);
      toast.success(`You received Token ID: ${id}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: id,
      });
    });

    console.log("minting race pack to :", address);
    mintPackContract
      .mintRacePackTo(address)
      .then(({ hash }) => {
        console.log({ hash });
        setTxHash(hash);
        toast.info("Transaction Submitted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: hash,
        });
      })
      .catch((err) => console.log(err));
  }

  return (
    <div style={{ position: "relative", width: "auto", height: "auto" }}>
      <div className="show-on-medium-and-up hide-on-small-only splash-discord-top">
        <div style={{ padding: "20px" }}>&nbsp;</div>
      </div>
      <div className="row" id="main-landing-page">
        <div className="col s12 transparent" style={{ position: "relative" }}>
          <div id="splash-main">
            <div className="show-on-medium-and-up hide-on-small-only splash-logo">
              <img
                src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png"
                height="200px"
              />
            </div>
            <div className="show-on-small hide-on-med-and-up splash-logo">
              <img
                src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png"
                width="75%"
              />
            </div>
            <div className="white-text subheadline-text splash-headline">
              Mintpack Admin
            </div>
            <div className="white-text headline-text splash-button-bullet-top">
              &#8226;
            </div>
            <div className="splash-main-button-wrapper" title="Alpha Login">
              <button
                className="btn-flat csr-button-white modal-trigger splash-main-button"
                data-target="createmintpackbox"
                type="submit"
                title="Create Mint Pack"
              >
                <span className="splash-main-button-bracket">[ </span>
                <span className="headline-text">CREATE MINTPACK</span>
                <span className="splash-main-button-bracket"> ]</span>
              </button>
            </div>
            <div className="white-text headline-text splash-button-bullet-bottom">
              &#8226;
            </div>
          </div>
        </div>
      </div>
      <div id="createmintpackbox" className="modal">
        <div className="modal-content">
          <div className="row">
            <div className="col s12" style={{ marginBottom: "15px" }}>
              <h5
                id="loginheader"
                className="headline-text left"
                style={{ marginBottom: "5px" }}
              >
                CREATE NEW MINT PACK
              </h5>
              <h5
                id="errorheader"
                className="headline-text left splash-modal-title-alt"
              >
                NOT AUTHORIZED
              </h5>
              <img
                src="https://media.exiledracers.com/global/exr_logo_v2_black_trans.png"
                height="40px"
                className="right hide-on-small-only"
              />
            </div>
            <div
              id="confirmerror"
              className="col s12 subheadline-text splash-modal-confirmtxt"
            >
              You're not registered for the Exiled Racers Alpha.
              <br />
              <br />
              Use the Register Button on the Exiled Racers homepage to register
              for the waitlist.
              <br />
              <br />- The CSR Team
            </div>
            <form
              id="loginform"
              className="subheadline-text"
              style={{ fontSize: "14px" }}
              onSubmit={mintPack}
            >
              <div className="col s12 m12 input-field">
                <input
                  name="walletID"
                  id="walletID"
                  type="text"
                  className="validate left"
                  value={address}
                  onChange={onTextChange}
                />
                <label
                  id="walletlabel"
                  htmlFor="walletID"
                  className="black-text"
                >
                  Wallet Address
                </label>
                {txHash && (
                  <a
                    href={`https://moonbase.moonscan.io/tx/${txHash}`}
                    target="_blank"
                  >
                    [ Track Your TX on Moonscan]
                  </a>
                )}
              </div>
              <div className="col s12" style={{ marginTop: "15px" }}>
                <button
                  id="loginsubmit"
                  className="btn-flat csr-button-black modal-trigger right splash-modal-button"
                  type="submit"
                  title="Testnet Login"
                >
                  <span className="splash-main-button-bracket">[</span>
                  <span className="headline-text">Mint Pack</span>
                  <span className="splash-main-button-bracket">]</span>
                </button>
                <div
                  id="loginloading"
                  className="preloader-wrapper small active right splash-modal-loader"
                >
                  <div className="spinner-layer spinner-red-only">
                    <div className="circle-clipper left">
                      <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                      <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                      <div className="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
