import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance} from "../../../../../utils";
import "../../race_landing.css";
import {genetic_tooltip, genetic_max_tooltip, genetic_track_tooltip, genetic_tooltip_wtrack, genetic_max_tooltip_wtrack} from "../../race_landing_tooltips";
import $ from "jquery";
import {StackAccordianSelect, StackCarouselSelect} from "./SelectionViews";

let current_stack_view = 'carousel';

export const StackSelectionSection = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    const [entrySet, setEntry] = useState(false);
    const [racecraft_choice_html, setracecraftChoiceHTML] = useState('');
    const [pilot_choice_html, setPilotChoiceHTML] = useState('');
    const [booster_choice_html, setBoosterChoiceHTML] = useState('');
    const [track_summary_html, setTrackSummary] = useState('');
    const [genetic_boost_html, setGeneticBoostHTML] = useState('');
    const [selectionHTML, setSelectionHTML] = useState('');
    const [stakeButtonMargin, setStakeButtonMargin] = useState('10px');
    const [item_list, setItemList] = useState([]);

    const [score_speed, setScoreSpeed] = useState(0);
    const [score_handling, setScoreHandling] = useState(0);
    const [score_acceleration, setScoreAcceleration] = useState(0);
    const [score_strength, setScoreStregth] = useState(0);
    const [score_focus, setScoreFocus] = useState(0);
    const [score_stamina, setScoreStamima] = useState(0);
    const [score_intelligence, setScoreIntelligence] = useState(0);
    const [score_aggression, setScoreAggression] = useState(0);
    const [score_speed2, setScoreSpeed2] = useState(0);
    const [score_speed3, setScoreSpeed3] = useState(0);
    const [score_handling2, setScoreHandling2] = useState(0);
    const [score_handling3, setScoreHandling3] = useState(0);
    const [score_acceleration2, setScoreAcceleration2] = useState(0);
    const [score_strength2, setScoreStregth2] = useState(0);
    const [score_focus2, setScoreFocus2] = useState(0);
    const [score_focus3, setScoreFocus3] = useState(0);
    const [score_stamina2, setScoreStamima2] = useState(0);
    const [score_intelligence2, setScoreIntelligence2] = useState(0);
    const [score_intelligence3, setScoreIntelligence3] = useState(0);
    const [score_aggression2, setScoreAggression2] = useState(0);

    const race_creator_types = ['training','bot-race','daily','alpha','invite'];

    let items_checked = 'N';
    var racer_items = [];

    function grabRacerItems() {
        items_checked = 'Y';
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        
        // Add Race ID if Garage Exists
        if(props.raceDetails['garage_id'] > 0) {
            addData.raceID = encodeURIComponent(props.raceDetails.race_id);
        }

        if(props.raceDetails['league_shared_garage_id'] > 0) {
            addData.raceID = encodeURIComponent(props.raceDetails.race_id);
            addData.sharedGarageID = encodeURIComponent(props.raceDetails['league_shared_garage_id']);
        }

        axiosInstance.post('member/items_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_item_list = result.data;
                
                var pilot_arr = []
                var racecraft_arr = []
                var booster_arr = []
                for (var z = 0; z < new_item_list.length;z++) {
                    if (new_item_list[z].item_type == 'booster') {
                        booster_arr.push(new_item_list[z]);
                    } else if (new_item_list[z].item_type == 'pilot') {
                        pilot_arr.push(new_item_list[z]);
                    } else if (new_item_list[z].item_type == 'ship') {
                        racecraft_arr.push(new_item_list[z]);
                    } else if (new_item_list[z].item_type == 'racecraft') {
                        racecraft_arr.push(new_item_list[z]);
                    }
                }
                loadStackSelection(current_stack_view,new_item_list);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadStackSelection(viewtype,itemlist) {
        if(viewtype == 'accordian') {
            let stackcomp = <StackAccordianSelect item_list={itemlist} startSelects={startAccordianSelects} />;
            setSelectionHTML(stackcomp);
        } else {
            let stackcomp = <StackCarouselSelect item_list={itemlist} startSliders={startEntrySliders} />;
            setSelectionHTML(stackcomp);
        }
    }

    function moveItemSlide(slidername,direction) {
        var nav_elem = document.getElementById(slidername+"_slider_slide");
        var nav_instance = window.M.Carousel.getInstance(nav_elem);
        if(direction == 'right') {
            nav_instance.next();
        } else {
            nav_instance.prev();
        }
        updateStackSummary();
    }

    function startEntrySliders() {
        var pilot_slide_elem2 = document.getElementById("pilot_slider_slide");
        var instances2 = window.M.Carousel.init(pilot_slide_elem2,{
            duration:150,
            numVisible:1,
            dist:0,
            onCycleTo: function() {
                updateStackSummary();
            }
        });

        var racecraft_slide_elem2 = document.getElementById("racecraft_slider_slide");
        var instances2 = window.M.Carousel.init(racecraft_slide_elem2,{
            duration:150,
            numVisible:1,
            dist:0,
            onCycleTo: function() {
                updateStackSummary();
            }
        });

        var racecraft_slide_elem2 = document.getElementById("booster_slider_slide");
        var instances2 = window.M.Carousel.init(racecraft_slide_elem2,{
            duration:150,
            numVisible:1,
            dist:0,
            onCycleTo: function() {
                updateStackSummary();
            }
        });

        window.$("#racecraft_slider_nav_left").click(function(){
            moveItemSlide('racecraft','left');
        });
        window.$("#racecraft_slider_nav_right").click(function(){
            moveItemSlide('racecraft','right');
        });

        window.$("#pilot_slider_nav_left").click(function(){
            moveItemSlide('pilot','left');
        });
        window.$("#pilot_slider_nav_right").click(function(){
            moveItemSlide('pilot','right');
        });

        window.$("#booster_slider_nav_left").click(function(){
            moveItemSlide('booster','left');
        });
        window.$("#booster_slider_nav_right").click(function(){
            moveItemSlide('booster','right');
        });

        updateStackSummary();
    }

    function startAccordianSelects() {

        window.$(".pilot-select-button").click(function(){
            setTimeout(function(){
                updateStackSummary()
            },100);
        });
        window.$(".racecraft-select-button").click(function(){
            setTimeout(function(){
                updateStackSummary()
            },100);
        });
        window.$(".booster-select-button").click(function(){
            setTimeout(function(){
                updateStackSummary()
            },100);
        });

        updateStackSummary();
    }

    function updateStackSummary() {
        var tscore_speed = 0;
        var tscore_handling = 0;
        var tscore_acceleration = 0;
        var tscore_strength = 0;
        var tscore_focus = 0;
        var tscore_stamina = 0;
        var tscore_intelligence = 0;
        var tscore_aggression = 0;
        var tscore_speed2 = 0;
        var tscore_handling2 = 0;
        var tscore_acceleration2 = 0;
        var tscore_strength2 = 0;
        var tscore_focus2 = 0;
        var tscore_stamina2 = 0;
        var tscore_intelligence2 = 0;
        var tscore_aggression2 = 0;
        var tscore_handling3 = 0;
        var tscore_focus3 = 0;
        var tscore_speed3 = 0;
        var tscore_intelligence3 = 0;
        var factionarr = [];
        var factionmatch = 0;
        var track_faction_selected = $("#current-track-system-selected").val();
        if(track_faction_selected == '') {
            track_faction_selected = $("#current-track-system-viewing").val();
        }
        var track_faction_pilot_match = 0;
        var track_faction_racecraft_match = 0;
        
        if(current_stack_view == 'accordian') {
            var caros = document.getElementsByClassName("selected-accordian-item");
        } else {
            var caros = document.getElementsByClassName("carousel-item active");
        }
        for(var i = 0; i < caros.length;i++) {
            var activeitem = caros[i].id.split("_");
            var item_id = activeitem[1];
            var item_type = caros[i].dataset.itemtype;
            var item_faction = caros[i].dataset.faction;
            if((item_type != 'booster')||((item_type == 'booster')&&(factionmatch == 1))) {
                for(var z = 0; z < factionarr.length;z++) {
                    if(item_faction == factionarr[z]) {
                        factionmatch = factionmatch + 1;
                        z = factionarr.length;
                    } else if ((item_type == 'booster')&&(item_faction == 'Any')){
                        factionmatch = factionmatch + 1;
                        z = factionarr.length; 
                    }
                }
                if((item_type == 'pilot')&&(item_faction ==track_faction_selected)) {
                    track_faction_pilot_match = 1;
                } else if((item_type == 'racecraft')&&(item_faction ==track_faction_selected)) {
                    track_faction_racecraft_match = 1;
                }
            }
            factionarr.push(item_faction);
            var itemscores = caros[i].dataset.scores;
            var score_arr = itemscores.split(",");
            for(var j = 0;j < score_arr.length;j++) {
                var score_split = score_arr[j].split("_");
                var score_attribute = score_split[0];
                var score_score = parseInt(score_split[1]);
                if((item_type == 'racecraft')||(item_type == 'pilot')) {
                    if(score_attribute == 'speed') {
                        var tscore_speed = score_score;
                    } else if(score_attribute == 'handling') {
                        var tscore_handling = score_score;
                    } else if(score_attribute == 'acceleration') {
                        var tscore_acceleration = score_score;
                    } else if(score_attribute == 'strength') {
                        var tscore_strength = score_score;
                    } else if(score_attribute == 'focus') {
                        var tscore_focus = score_score;
                    } else if(score_attribute == 'stamina') {
                        var tscore_stamina = score_score;
                    } else if(score_attribute == 'aggression') {
                        var tscore_aggression = score_score;
                    } else if(score_attribute == 'intelligence') {
                        var tscore_intelligence = score_score;
                    }
                } else if (item_type == 'booster') {
                    if(score_attribute == 'speed') {
                        var tscore_speed2 = score_score;
                    } else if(score_attribute == 'handling') {
                        var tscore_handling2 = score_score;
                    } else if(score_attribute == 'acceleration') {
                        var tscore_acceleration2 = score_score;
                    } else if(score_attribute == 'strength') {
                        var tscore_strength2 = score_score;
                    } else if(score_attribute == 'focus') {
                        var tscore_focus2 = score_score;
                    } else if(score_attribute == 'stamina') {
                        var tscore_stamina2 = score_score;
                    } else if(score_attribute == 'aggression') {
                        var tscore_aggression2 = score_score;
                    } else if(score_attribute == 'intelligence') {
                        var tscore_intelligence2 = score_score;
                    }
                }
            }
        }        

        var genetic_boost_text = <></>;
        var stakebutton = '35px';

        if(track_faction_pilot_match > 0) {
            var tscore_intelligence3 = 2;
        }
        if(track_faction_racecraft_match > 0) {
            var tscore_speed3 = 2;
        }
        if(factionmatch == 1) {
            var tscore_handling3 = 3;
            var tscore_focus3 = 3;
            stakebutton = '10px';
            var finalboosttip = genetic_tooltip;
            if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                finalboosttip = genetic_tooltip_wtrack;
            }
            genetic_boost_text = <div className="row headline-text center-align stacktips" style={{marginTop:'5px',marginBottom:'0px',fontSize:'12px',cursor:'context-menu'}}  data-position="top" data-tooltip={finalboosttip}>
                                    <span className="exr-yellow-text">GENETIC BOOST</span>
                                </div>;
        } else if(factionmatch > 1) {
            var tscore_handling3 = 4;
            var tscore_focus3 = 4;
            stakebutton = '10px';
            var finalboosttip = genetic_max_tooltip;
            if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                finalboosttip = genetic_max_tooltip_wtrack;
            }
            genetic_boost_text = <div className="row headline-text center-align stacktips" style={{marginTop:'5px',marginBottom:'0px',fontSize:'12px',cursor:'context-menu'}}  data-position="top" data-tooltip={finalboosttip}>
                                    <span className=" exr-yellow-text">GENETIC BOOST MAX</span>
                                </div>;
        } else if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
            stakebutton = '10px';
            genetic_boost_text = <div className="row headline-text center-align stacktips" style={{marginTop:'5px',marginBottom:'0px',fontSize:'12px',cursor:'context-menu'}}  data-position="top" data-tooltip={genetic_track_tooltip}>
                                    <span className=" exr-yellow-text">GENETIC TRACK</span>
                                </div>;
        }

        


        setGeneticBoostHTML(genetic_boost_text);
        setStakeButtonMargin(stakebutton);
        setScoreSpeed(tscore_speed);
        setScoreHandling(tscore_handling);
        setScoreAcceleration(tscore_acceleration);
        setScoreStregth(tscore_strength);
        setScoreFocus(tscore_focus);
        setScoreStamima(tscore_stamina);
        setScoreIntelligence(tscore_intelligence);
        setScoreAggression(tscore_aggression);
        setScoreSpeed2(tscore_speed2);
        setScoreHandling2(tscore_handling2);
        setScoreAcceleration2(tscore_acceleration2);
        setScoreStregth2(tscore_strength2);
        setScoreFocus2(tscore_focus2);
        setScoreStamima2(tscore_stamina2);
        setScoreIntelligence2(tscore_intelligence2);
        setScoreAggression2(tscore_aggression2);
        setScoreHandling3(tscore_handling3);
        setScoreFocus3(tscore_focus3);
        setScoreSpeed3(tscore_speed3);
        setScoreIntelligence3(tscore_intelligence3);

        var tooltips = document.querySelectorAll('.stacktips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    }

    function checkEnterRace() {
        props.stakeToRace();
    }

    function warnEnterRace() {
        var sinfobox = document.getElementById('confirmentrybox');
        var info_instance = window.M.Modal.getInstance(sinfobox);
        info_instance.open();
    }

    function showStackInfoBox() {
        var sinfobox = document.getElementById('stackinfobox');
        var info_instance = window.M.Modal.getInstance(sinfobox);
        info_instance.open();
    }

    if(entrySet == false) {
        setEntry(true);
        grabRacerItems();
    }

    var entrytext = 'Enter Stack';
    if(window.location.hash.indexOf("edit_stack") >= 0) {
        entrytext = 'Update Stack';
    } else if(props.hashRaceUniqueID == 'invite') {
        entrytext = 'Enter Stack';
    }

    var entryheadlinetxt = '3. ENTER YOUR STACK';
    if(window.location.hash.indexOf("edit_stack") >= 0) {
        entryheadlinetxt = 'UPDATE YOUR STACK';
    } else if (race_creator_types.indexOf(props.hashRaceUniqueID) == -1) {
        entryheadlinetxt = 'ENTER YOUR STACK';
    }

    return(<div className="row">
        <div className="col s12 headline-text" style={{fontSize:"24px",marginTop:"15px"}}>
            <span className="left">{entryheadlinetxt}</span>
            <span className="left material-icons material-symbols-outlined white-red-link" 
                style={{fontSize:"26px",marginLeft:"10px",marginTop:'1px'}} 
                onClick={()=>showStackInfoBox()}>info</span>
        </div>
        <div className="col s12">
            <div className="divider-thin"></div>
        </div>

        <div className="col s12" style={{marginTop:'15px'}}>
            <div className="row" style={{margin:"0px",padding:"0px"}}>
                {selectionHTML}
                <div className="col s12 l3" style={{padding:'0px'}}>
                    <div className="stack-selection-box exr-new-light-grey" style={{width:"100%"}}>
                        <div className="row white-text stack-selection-col" style={{margin:'0px',padding:'10px'}}>
                            <div className="col s12 white-text">
                                <div className="row headline-text" style={{marginTop:'5px',marginBottom:'0px',fontSize:'16px'}}>
                                    <span className="left white-green-link" style={{marginLeft:'10px'}}  onClick={()=>showStackInfoBox()}>STACK SUMMARY</span>
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row white-text" style={{fontSize:'14px',marginTop:'15px',marginBottom:'5px'}}>
                                    <div className="col s12 " style={{display:'flex',flexDirection:'row'}} title="Speed: Good for Straights">
                                        <div style={{order:'1',flexBasis:'40px'}}>
                                            <img src="https://media.exiledracers.com/global/icons/exr_speed.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                        </div>
                                        <div style={{order:'2',flex:'10'}}>
                                            <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                <div className="left">Speed</div>
                                                <div className="right">{(score_speed+score_speed2+score_speed3)}</div>
                                            </div>
                                            <div style={{width:'100%'}}>
                                                <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                    <div className="determinate white left" style={{width:score_speed+'%'}}></div>
                                                    <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_speed2+'%',left:score_speed+'%'}}></div>
                                                    <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_speed3+'%',left:(score_speed+score_speed2)+'%'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                                    <div className="col s12" style={{display:'flex',flexDirection:'row'}} title="Handling: Good for Turns">
                                        <div style={{order:'1',flexBasis:'40px'}}>
                                            <img src="https://media.exiledracers.com/global/icons/exr_handling.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                        </div>
                                        <div style={{order:'2',flex:'10'}}>
                                            <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                <div className="left">Handling</div>
                                                <div className="right">{(score_handling+score_handling2+score_handling3)}</div>
                                            </div>
                                            <div style={{width:'100%'}}>
                                                <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                    <div className="determinate white left" style={{width:score_handling+'%'}}></div>
                                                    <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_handling2+'%',left:score_handling+'%'}}></div>
                                                    <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_handling3+'%',left:(score_handling+score_handling2)+'%'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                                    <div className="col s12" style={{display:'flex',flexDirection:'row'}} title="Acceleration: Good for Speed Shifts">
                                        <div style={{order:'1',flexBasis:'40px'}}>
                                            <img src="https://media.exiledracers.com/global/icons/exr_acceleration.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                        </div>
                                        <div style={{order:'2',flex:'10'}}>
                                            <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                <div className="left">Acceleration</div>
                                                <div className="right">{(score_acceleration+score_acceleration2)}</div>
                                            </div>
                                            <div style={{width:'100%'}}>
                                                <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                    <div className="determinate white left" style={{width:score_acceleration+'%'}}></div>
                                                    <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_acceleration2+'%',left:score_acceleration+'%'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}} title="Strength: Good for More Laps">
                                    <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                                        <div style={{order:'1',flexBasis:'40px'}}>
                                            <img src="https://media.exiledracers.com/global/icons/exr_strength.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                        </div>
                                        <div style={{order:'2',flex:'10'}}>
                                            <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                <div className="left">Strength</div>
                                                <div className="right">{(score_strength+score_strength2)}</div>
                                            </div>
                                            <div style={{width:'100%'}}>
                                                <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                    <div className="determinate white left" style={{width:score_strength+'%'}}></div>
                                                    <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_strength2+'%',left:score_strength+'%'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}} title="Focus: Helps you maintain Max Distance">
                                    <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                                        <div style={{order:'1',flexBasis:'40px'}}>
                                            <img src="https://media.exiledracers.com/global/icons/exr_focus.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                        </div>
                                        <div style={{order:'2',flex:'10'}}>
                                            <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                <div className="left">Focus</div>
                                                <div className="right">{(score_focus+score_focus2+score_focus3)}</div>
                                            </div>
                                            <div style={{width:'100%'}}>
                                                <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                    <div className="determinate white left" style={{width:score_focus+'%'}}></div>
                                                    <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_focus2+'%',left:score_focus+'%'}}></div>
                                                    <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_focus3+'%',left:(score_focus+score_focus2)+'%'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}} title="Stamina: Good for More Laps">
                                    <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                                        <div style={{order:'1',flexBasis:'40px'}}>
                                            <img src="https://media.exiledracers.com/global/icons/exr_stamina.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                        </div>
                                        <div style={{order:'2',flex:'10'}}>
                                            <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                <div className="left">Stamina</div>
                                                <div className="right">{(score_stamina+score_stamina2)}</div>
                                            </div>
                                            <div style={{width:'100%'}}>
                                                <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                    <div className="determinate white left" style={{width:score_stamina+'%'}}></div>
                                                    <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_stamina2+'%',left:score_stamina+'%'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}} title="Aggression: Helps you Overtake Racers">
                                    <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                                        <div style={{order:'1',flexBasis:'40px'}}>
                                            <img src="https://media.exiledracers.com/global/icons/exr_aggression.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                        </div>
                                        <div style={{order:'2',flex:'10'}}>
                                            <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                <div className="left">Aggression</div>
                                                <div className="right">{(score_aggression+score_aggression2)}</div>
                                            </div>
                                            <div style={{width:'100%'}}>
                                                <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                    <div className="determinate white left" style={{width:score_aggression+'%'}}></div>
                                                    <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_aggression2+'%',left:score_aggression+'%'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}} title="Intelligence: Helps you know how to use Aggression">
                                    <div className="col s12" style={{display:'flex',flexDirection:'row'}}>
                                        <div style={{order:'1',flexBasis:'40px'}}>
                                            <img src="https://media.exiledracers.com/global/icons/exr_intelligence.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                        </div>
                                        <div style={{order:'2',flex:'10'}}>
                                            <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                <div className="left">Intelligence</div>
                                                <div className="right">{(score_intelligence+score_intelligence2+score_intelligence3)}</div>
                                            </div>
                                            <div style={{width:'100%'}}>
                                                <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                    <div className="determinate white left" style={{width:score_intelligence+'%'}}></div>
                                                    <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_intelligence2+'%',left:score_intelligence+'%'}}></div>
                                                    <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_intelligence3+'%',left:(score_intelligence+score_intelligence2)+'%'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-thin"></div>
                                {genetic_boost_html}
                                <div className="row white-text" style={{fontSize:'14px',marginTop:stakeButtonMargin,marginBottom:'5px'}}>
                                    <div id="stakingbutton" className="center-align" title="Stake To Race">
                                        <span style={{display:'table',margin:'0 auto'}}>
                                            <span className="round-border-button" style={{fontSize:'20px'}} title="Stake Stack to Enter Race"  onClick={() => warnEnterRace() }>
                                                {entrytext.toUpperCase()}
                                            </span>
                                        </span>
                                    </div>
                                    <div id="stakingloading" className="preloader-wrapper small active" style={{left:'45%',marginTop: '-8px',display:'none'}}>
                                        <div className="spinner-layer spinner-red-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>);
    };