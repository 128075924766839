import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "./racerhome.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation, signupLoading} from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals, getIndexerUserTokens} from "../../../utils";
import {NFTViewerPage, TrophyCasePage, ForgePage} from "./Sections"
import $ from 'jquery';

let web3;

export const WorkshopHome = () => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers, signup_loading } = useSelector((state) => state.app);
    
    if((racerID.length == 0)||(accountAddress.length == 0)) {
        dispatch(setRacerLogin('',''));
        dispatch(updateLocation("splash"));
        window.location.href= '/';
    }

    var url_race_unique_id = '';
    var tmp_current_section = 'pilots';
    var pagehash = window.location.href.split("#/");
    if(pagehash.length > 1) {
        var pagehash2 = pagehash[1].replace("#/",'');
        var pagehash3 = pagehash2.split("/");
        if(pagehash3.length > 1) {
            tmp_current_section = pagehash3[1].replace("/",'');
        }
    }

    const [pageSet, setPage] = useState(false);
    const [item_list, setItemList] = useState([]);
    const [new_race_count, setNewRaces] = useState(<i className="material-icons workshop-nav-button-chevron">chevron_right</i>);
    const [current_section, setCurrentSection] = useState(tmp_current_section);
    const [current_page_html, setCurrentPageHTML] = useState('');
    const [current_scroll_html, setCurrentScrollHTML] = useState('');

    function toggleWorkshop(pagename) {
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");
        var sectiontxt = <></>;
        setCurrentPageHTML(sectiontxt);
        if(pagename == 'forge') {
            sectiontxt = <ForgePage />;
        } else if (pagename == 'trophies') {
            sectiontxt = <TrophyCasePage />;
        } else if (pagename == 'boosters') {
            sectiontxt = <NFTViewerPage assetType="booster" />;
        } else if (pagename == 'racecrafts') {
            sectiontxt = <NFTViewerPage assetType="racecraft" />;
        } else if (pagename == 'pilots') {
            sectiontxt = <NFTViewerPage assetType="pilot" />;
        } else {
            sectiontxt = <NFTViewerPage assetType="pilot" />;
        }
        setCurrentPageHTML(sectiontxt);

        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
            adjustLargeNFTImage();
        },500);
        setTimeout(function(){
            adjustLargeNFTImage();
        },1500);
    }

    function toggleWorkshopSelection() {
        var pageselection = $("#workshopPageSelection").val();
        toggleWorkshop(pageselection);
    }

    function adjustLargeNFTImage() {
        var imagewidth = $("#largenftviewer").width();
        $("#largenftviewer").css({height:imagewidth+'px'});
        $("#nftdetailsright").css({height:imagewidth+'px'});
        var rightsideheight = $("#righsidecontent").height()-10;
        $("#leftrailnav").css({height:rightsideheight+'px'});
    }


    document.addEventListener('DOMContentLoaded', function() {
        adjustLargeNFTImage();
    });

    // window.addEventListener("hashchange",
    // function(){ 
    //     //window.location.reload(); 
    // }, false);

    window.addEventListener("resize",
    function(){ 
        adjustLargeNFTImage();
    }, false);

    function turnOffSignupLoading() {
        if(signup_loading === true) {
            $("#workshop-main-page").hide();
            setTimeout(function(){
                dispatch(signupLoading(false));
                $("#workshop-main-page").fadeIn(3000);
            },2000);
        } else {
            $("#workshop-main-page").show();
        }
    }

    async function checkRacerAssetsIndexer(walletaddress) {
        $("#game-asset-workshop-sync-text").html('...Syncing Game Assets');
        
        var racer_tokens = await getIndexerUserTokens(walletaddress);
        if(racer_tokens != null) {
            var pilot_token_list = [];
            var racecraft_token_list = [];
            var booster_token_list = [];
            if(racer_tokens['ownedTokens'] !== undefined) {
                for(var i = 0; i < racer_tokens['ownedTokens'].length;i++) {
                    var indexer_token_text_id = racer_tokens['ownedTokens'][i]['id'];
                    var indexer_token_text_split = indexer_token_text_id.split("-");
                    var indexer_token_type = indexer_token_text_split[0];
                    var indexer_token_id = indexer_token_text_split[1];
                    if(indexer_token_type == 'pilot') {
                        pilot_token_list.push(indexer_token_id);
                    } else if(indexer_token_type == 'racecraft') {
                        racecraft_token_list.push(indexer_token_id);
                    }
                }
            }
            if(racer_tokens['ownedBoosters'] !== undefined) {
                for(var i = 0; i < racer_tokens['ownedBoosters'].length;i++) {
                    var indexer_token_text_id = racer_tokens['ownedBoosters'][i]['id'];
                    var indexer_token_quantity = racer_tokens['ownedBoosters'][i]['qty'];
                    var indexer_token_text_split = indexer_token_text_id.split("-");
                    var indexer_token_type = indexer_token_text_split[0];
                    var indexer_token_walletsub = indexer_token_text_split[1];
                    var indexer_token_id = indexer_token_text_split[2];
                    for(var j = 0; j < indexer_token_quantity;j++) {
                        booster_token_list.push(indexer_token_id);
                    }
                }
            }

            updateWalletTokenOwner(walletaddress,pilot_token_list,'pilot');
            updateWalletTokenOwner(walletaddress,racecraft_token_list,'racecraft');
            updateWalletTokenOwner(walletaddress,booster_token_list,'booster');

        }

        setTimeout(function(){
            updateRacerNFTSync();
        },3000);
    }

    function updateRacerNFTSync() {
        $("#game-asset-workshop-sync-text").html('Game Assets Synced...Refreshing');
        setTimeout(function(){
            window.location.reload();
        },3000);
    }

    function updateWalletTokenOwner(walletaddress,tokenIds,tokenType) {
        var tokenstring = '';
        for(var i = 0; i < tokenIds.length;i++) {
          if(i > 0) {
            tokenstring = tokenstring+',';
          }
          tokenstring = tokenstring+tokenIds[i];
        }
        var addData = new Object;
        addData.walletID = encodeURIComponent(walletaddress);
        addData.tokenList = tokenstring;
        addData.nType = tokenType;
    
        if(walletaddress !== null) {
          if(walletaddress.length > 3) {
              axiosInstance
                .post("https://data.exiledracers.com/api/v1/mint/token_owner_check/", addData)
                .then((result) => {
                  if (Object.keys(result.data).length > 0) {
                    var view_result = result.data;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
          }
        }
    }

    if(pageSet == false) {
        setPage(true);
        window.startPageModals();
        window.resetBodyWidth();
        toggleWorkshop(current_section);
        turnOffSignupLoading();
        setTimeout(function(){
            var mnav_elem = document.getElementById('workshopPageSelection');
            var mnav_instance = window.M.FormSelect.init(mnav_elem,{classes:'exr-select'});
        },500);
    }

    return(<div id="workshop-main-page" style={{position:'relative',width:'auto',height:'auto'}}>
            <div className="row page-width-wide page-clean-body">
                <div id="workshop-mobile-nav" className="col s12 border-section-full exr-new-dark-grey show-on-med-and-down hide-on-large-only" 
                        style={{position:'relative',padding:"5px",paddingBottom:'0px',marginBottom:'0px'}}>
                    <div className="left headline-text white-text" style={{fontSize:'16px',marginTop:"15px",marginBottom:"10px",marginLeft:'30px'}}>
                        &nbsp;THE WORKSHOP
                    </div>
                    <div className="right" style={{fontSize:'16px',marginTop:"10px",marginBottom:"10px",marginRight:'30px'}}>
                        <select onChange={toggleWorkshopSelection} id="workshopPageSelection" className="input-field light-grey-bubble-input"
                            style={{fontSize:'20px',fontFamily:'helvetica',padding:'0px'}}>
                            <option value="pilots">Pilots</option>
                            <option value="racecrafts">Racecrafts</option>
                            <option value="boosters">Boosters</option>
                        </select>
                    </div>
                </div>
                <div id="leftrailnav" className="col s12 l2 border-section-full exr-new-dark-grey hide-on-med-and-down" style={{position:'relative',padding:"5px",minHeight:'90vh'}}>
                    <div style={{width:'100%'}}>
                        <div className="headline-text section-title-text center-align" style={{fontSize:'16px',marginTop:"20px",marginBottom:"10px"}}>
                            &nbsp;THE WORKSHOP
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="pilotstoggle"
                                    onClick={()=> toggleWorkshop('pilots')}> 
                                <span className="left side-nav-button-text">Pilots</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="racecraftstoggle"
                                    onClick={()=> toggleWorkshop('racecrafts')}> 
                                <span className="left side-nav-button-text">Racecrafts</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="boosterstoggle"
                                    onClick={()=> toggleWorkshop('boosters')}> 
                                <span className="left side-nav-button-text">Boosters</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section" style={{display:'none'}}>
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="trophiestoggle"
                                    onClick={()=> toggleWorkshop('trophies')}> 
                                <span className="left side-nav-button-text">Trophy Case</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section" style={{display:'none'}}>
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="forgetoggle"
                                    onClick={()=> toggleWorkshop('forge')}> 
                                <span className="left side-nav-button-text">The Forge</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <div id="game-asset-workshop-sync" className="row center-align" style={{marginTop:'20px',marginBottom:'0px'}}>
                                <div style={{textAlign:'center'}}>

                                    <a id="game-asset-workshop-sync-text" style={{fontSize:'14px'}} className="white-green-link" onClick={()=>checkRacerAssetsIndexer(accountAddress)}>
                                        <span className="material-icons material-icons-outline white-green-link" style={{verticalAlign:'middle',fontSize:'16px',paddingRight:'5px'}}>refresh</span>
                                        <span style={{verticalAlign:'middle',lineHeight:'1'}}>Sync Game Assets</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="righsidecontent" className="col s12 l10" style={{position:'relative', minHeight:'90vh', margin:"0px",padding:"0px"}}>
                    <div className="row border-section-full exr-new-dark-grey white-text" style={{minHeight:'90vh',position:'relative',width:"99.5%",marginLeft:"0.5%",padding:"25px"}}>
                        {current_page_html}
                    </div>
                </div>
            </div>
        </div>);
};
