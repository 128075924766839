import {LeaderResult} from "./leaderResult.js";

/**
 * A leaderboard line
 */
export class LeaderLine {
    static CLASS = "leaderboardrow";
    static CLASS_BACKGROUND = "background";
    static CLASS_BACKGROUND_STRIPE = "stripe";
    static CLASS_AVATAR = "avatar";
    static CLASS_FINISHED = "finished";
    static CLASS_NUMBER = "number";
    static CLASS_PREDICT = "predict";
    static CLASS_STAR = "star";
    static CLASS_ANGRY = "anger";
    static CLASS_BOOSTING = "boosting";
    static CLASS_LEGENDARY = "leaderboardrowlegendary";
    static CLASS_EXILED = "leaderboardrowexiled";
    static CLASS_TRACKED = "tracked";
    static STRIPES = [41, 7.5, 3.725];

    /**
     * Create the background decoration
     * @param {THREE.Color} color The decoration color
     * @returns {HTMLDivElement} The decoration element
     */
    static createBackground(color) {
        const colorStyle = color.getStyle();
        const element = document.createElement("div");

        element.className = LeaderLine.CLASS_BACKGROUND;

        for (const width of LeaderLine.STRIPES) {
            const stripe = document.createElement("div");

            stripe.className = LeaderLine.CLASS_BACKGROUND_STRIPE;
            stripe.style.backgroundColor = colorStyle;
            stripe.style.width = width.toString() + "px";

            element.appendChild(stripe);
        }

        return element;
    }

    /**
     * Create the avatar
     * @param {string} url The avatar image URL
     * @returns {HTMLDivElement} The avatar
     */
    static createAvatar(url) {
        const element = document.createElement("div");
        const image = document.createElement("img");

        element.className = LeaderLine.CLASS_AVATAR;
        element.appendChild(image);

        image.src = url;

        return element;
    }

    /**
     * Create the leaderboard number
     * @param {int} num The current row ranking
     * @returns {HTMLDivElement} The number element
     */
     static createLeaderboardNumber(num) {
        const element = document.createElement("div");

        element.className = LeaderLine.CLASS_NUMBER;
        element.innerHTML = num;

        return element;
    }

    /**
     * Create the leaderboard your racer icon
     * @returns {HTMLDivElement} The predicted styling
     */
     static createLeaderboardPredicted() {
        const element = document.createElement("div");
        element.className = LeaderLine.CLASS_PREDICT;
        element.title="Your Predicted Winner";
        const predicted_icon = document.createElement("i");
        predicted_icon.className = 'material-icons material-icons-outlined white-text';
        predicted_icon.innerHTML = 'verified';
        element.appendChild(predicted_icon);
        return element;
    }

    /**
     * Create the leaderboard predicted icon
     * @returns {HTMLDivElement} The predicted styling
     */
     static createLeaderboardStar() {
        const element = document.createElement("div");
        element.className = LeaderLine.CLASS_STAR;
        element.title="Your Racer";
        const predicted_icon = document.createElement("i");
        predicted_icon.className = 'material-icons material-icons-outlined exr-yellow-text';
        predicted_icon.innerHTML = 'star_rate';
        element.appendChild(predicted_icon);
        return element;
    }

    /**
     * Create the leaderboard angression icon
     * @returns {HTMLDivElement} The predicted styling
     */
     static createLeaderboardAggression() {
        const element = document.createElement("div");
        element.className = LeaderLine.CLASS_ANGRY;
        element.title="Agression Boosting";
        const angry_icon = document.createElement("span");
        angry_icon.className = 'exr-red-text';
        angry_icon.innerHTML = '&#128545';
        // const angry_icon = document.createElement("i");
        // angry_icon.className = 'material-icons material-symbols exr-red-text';
        // angry_icon.innerHTML = 'sentiment_extremely_dissatisfied';
        element.appendChild(angry_icon);
        return element;
    }

    /**
     * Construct a leaderboard line
     * @param {Leaderboard} leaderboard The leaderboard
     * @param {Racers} racers The racers object
     * @param {Racer} racer The racer for this line
     * @param {string} name The racer line
     * @param {THREE.Color} color A color
     * @param {string} avatar The avatar image url
     * @param {number} index The index of this line in the leaderboard
     */
    constructor(leaderboard, racers, racer, name, color, avatar, index, currentracer=0, predicted=0, specialracer=0) {
        const header = document.createElement("h2");

        header.appendChild(document.createTextNode(name));

        this.name = name;

        this.color = color;
        this.index = index;
        this.result = new LeaderResult();
        this.element = document.createElement("div");
        this.element.className = LeaderLine.CLASS;
        this.element.appendChild(LeaderLine.createBackground(color));
        this.element.appendChild(LeaderLine.createAvatar(avatar));
        this.element.appendChild(LeaderLine.createLeaderboardNumber(index+1))
        this.element.appendChild(this.result.element);
        this.element.appendChild(header);
        if(specialracer >= 10) {
            this.element.classList.add(LeaderLine.CLASS_LEGENDARY);
            this.element.title = 'Legendary Racer';
        } else if (specialracer > 0) {
            this.element.classList.add(LeaderLine.CLASS_EXILED);
            this.element.title = 'Exiled Racer';
        }
        if(currentracer == 1) {
            this.element.appendChild(LeaderLine.createLeaderboardStar());
        }
        if(predicted == 1) {
            this.element.appendChild(LeaderLine.createLeaderboardPredicted());
        }
        this.element.appendChild(LeaderLine.createLeaderboardAggression());

        this.element.onclick = () => {
            leaderboard.tracked?.classList.toggle(LeaderLine.CLASS_TRACKED);

            if (this.element !== leaderboard.tracked) {
                racers.trackingRacer = racer;
                leaderboard.tracked = this.element;

                this.element.classList.toggle(LeaderLine.CLASS_TRACKED);
            }
            else {
                racers.trackingRacer = null;
                leaderboard.tracked = null;
            }
        };
    }

    /**
     * Set this line to finished
     * @param {number} place The place the racer finished at
     */
    finish(place) {
        // this.element.classList.add(LeaderLine.CLASS_FINISHED);
        // this.element.getElementsByClassName("number")[0].style.color = 'black'; 
        // this.element.getElementsByClassName("number")[0].style.textShadow = 'none'; 
        // this.result.activate(place + 1);

        this.element.getElementsByClassName("number")[0].innerHTML = (place + 1).toString();
        //this.element.getElementsByClassName("number")[0].style.display='none';
    }

    /**
     * Set the Line Number
     * @param {number} index The line index
     */
    setRanking(index) {
        this.element.getElementsByClassName("number")[0].innerHTML = index + 1;
    }

    /**
     * Set the line index
     * @param {number} index The line index
     */
    setIndex(index) {
        const style = window.getComputedStyle(this.element, null);
        const stride = this.element.getBoundingClientRect().height +
            Number.parseFloat(style.getPropertyValue("padding-top")) +
            Number.parseFloat(style.getPropertyValue("padding-bottom"));
        this.element.style.top = ((index - this.index) * stride).toString() + "px";
    }

    /**
     * Set the Line Color
     * @param {number} boostflag If this racer is currently boosting
     */
     setBoosting(boostflag) {
        if(boostflag == 1) {
            this.element.classList.add(LeaderLine.CLASS_BOOSTING);
            this.element.getElementsByClassName("anger")[0].style.display = 'block';
        } else {
            this.element.classList.remove(LeaderLine.CLASS_BOOSTING);
            this.element.getElementsByClassName("anger")[0].style.display = 'none';
        }
    }
}