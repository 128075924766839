import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "./trackuploader.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerXP, setRacerCoins } from "../../../redux/Account/actions";
import {axiosInstance} from "../../../utils";
import {GlobalHeaderGrey} from "../../Organisms"
import { racerContractAddresses } from "../../../constants";
import $ from 'jquery';
//import * as tus from "tus-js-client";

let web3;

export const TrackUploader = () => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    
    if((racerID.length == 0)||(accountAddress.length == 0)) {
        window.location.href='/';
    }

    // if(admin_racers.indexOf(parseInt(racerID)) === -1) {
    //     window.location.href='/';
    // }

    var url_race_unique_id = '';
    var tmp_current_section = 'pilots';
    var pagehash = window.location.href.split("#/");
    if(pagehash.length > 1) {
        var pagehash2 = pagehash[1].replace("#/",'');
        var pagehash3 = pagehash2.split("/");
        if(pagehash3.length > 1) {
            tmp_current_section = pagehash3[1].replace("/",'');
        }
    }

    const [pageSet, setPage] = useState(false);
    const [asset_list_html, setAssetListHTML] = useState('');

    function grabCurrentAssets() {
        // var addData = new Object();
        // addData.racerID = encodeURIComponent(racerID);
        // addData.layout = 'grouped';
        var dataurl = 'builder/asset_list/?racerID='+racerID+"&layout=grouped";

        axiosInstance.get(dataurl).then(result => {
            if(Object.keys(result.data).length > 0) {
                var listarr = result.data; 
                loadAssetList(listarr);
            } 
        }).catch(error => {
            console.log(error);
        });
    }

    function removeAsset(e) {
        e.preventDefault();
        var asset_id = e.target.id.replace("asset","");
        var addData = new Object();
        addData.trackAssetID = asset_id;
        if(window.confirm("Are you sure you want to remove this asset?")) {
            axiosInstance.post('builder/remove_asset/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    window.location.reload();
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function loadAssetList(listarr) {
        var asset_arr = [];
        for(var j = 0; j < listarr.length;j++) {
            var asset_list = listarr[j]['assets'];

            var category_name = listarr[j]['asset_category'];
            if(category_name != '') {
                var categorywords = category_name.split("_");
                for (let i = 0; i < categorywords.length; i++) {
                    categorywords[i] = categorywords[i][0].toUpperCase() + categorywords[i].substr(1);
                }
                category_name = categorywords.join(" ");
            }
            let spacer = '40px';
            if(i > 0) {
                spacer = '80px';
            }
            var tempasset = <><div className="row stats-viewer-stats-headline" style={{marginTop:spacer}}>
                                <div className="col s12 m2">
                                    {category_name}
                                </div>
                                <div className="col s12 m2">
                                    Upload Time
                                </div>
                                <div className="col s12 m6">
                                    Location
                                </div>
                                <div className="col s12 m1">
                                    Size
                                </div>
                                <div className="col s12 m1">
                                    Action
                                </div>
                            </div>
                            <div className="divider-thin"></div></>
            asset_arr.push(tempasset);

            for(var i = 0; i < asset_list.length;i++) {
                var actionhtml = <span>Default</span>
                if(asset_list[i]['default_asset'] == 0) {
                    actionhtml = <span id={"asset"+asset_list[i]['race_track_asset_id']} 
                                        onClick={(e)=>removeAsset(e)} 
                                        title="Remove Asset" 
                                        className="white-red-link"
                                        style={{cursor:'pointer'}}>
                                            Remove
                                </span>
                }
                var tempasset = <><div key={i} className="row white-text" style={{fontSize:'14px',marginTop:'15px',marginBottom:'5px'}}>
                                <div className="col s12 m2">
                                    {asset_list[i]['asset_name']}
                                </div>
                                <div className="col s12 m2">
                                {category_name}
                                </div>
                                <div className="col s12 m6" style={{height:"18px",overflow:"hidden"}}>
                                    <a target="blank" className="white-text" href={asset_list[i]['asset_location']}>
                                        {asset_list[i]['asset_location']}
                                    </a>
                                </div>
                                <div className="col s12 m1">
                                    {asset_list[i]['asset_size']}
                                </div>
                                <div className="col s12 m1">
                                    {actionhtml}
                                </div>
                            </div>
                            <div className="divider-thin"></div></>
                asset_arr.push(tempasset);
            }
        }
        setAssetListHTML(asset_arr);
        setTimeout(function(){
            adjustUploaderBox();
        },100);
    }

    function adjustUploaderBox() {
        var rightsideheight = $("#righsidecontent").height()-10;
        $("#leftrailnav").css({height:rightsideheight+'px'});
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

	async function saveAssetUploadInfo(mediaURL) {
		var final_asset_link = mediaURL;
        var assetname = $("#upload-asset-name").val();
        var assetnameclean = $.trim(assetname).replaceAll(" ","_");
        var asset_category = $("#upload-sel-cat").val();

        var ufile = document.getElementById('upload-asset-file').files[0];
		var upload_size = ufile.size.toString();
        var upload_size_text = formatBytes(upload_size);

		var updData = new Object();
		updData.racerID = racerID;
		updData.assetLocation = final_asset_link;
		updData.assetCategory = asset_category;
		updData.assetName = encodeURIComponent(assetname);
        updData.assetShortname = encodeURIComponent(assetnameclean);
        updData.assetSize = encodeURIComponent(upload_size_text);
		axiosInstance.post('builder/save_asset/', updData).then(result => {
			window.location.reload();
		}).catch(error => {
			console.error(error);
		});
    }

    function getAssetUploadURL() {
        var asset_category = $("#upload-sel-cat").val();
        var assetname = $("#upload-asset-name").val();
        var assetnameclean = $.trim(assetname).replaceAll(" ","_");
        var current_filename = '';
        var new_filename = '';
        let ufile;
        if(document.getElementById('upload-asset-file').files.length > 0) {
            ufile = document.getElementById('upload-asset-file').files[0];
            current_filename = ufile.name;
            var namesplit = current_filename.split(".");
            var filetypetxt = namesplit[namesplit.length-1];
            new_filename = assetnameclean+"_"+Date.now().toString()+'.'+filetypetxt;
        }
        
        var s3path = 'assets/game/builder/'
        var filetype = ufile.type.toString();
        var fullfilepath = 'https://media.exiledracers.com/'+s3path+new_filename;

		var updData = new Object();
		updData.fname = new_filename;
		updData.fpath = s3path;
		updData.ftype = filetype;

        if(asset_category == 'all') {
            alert("Select A Category");
        } else if (assetnameclean.length < 4) {
            alert("Add an Asset Name");
        } else if(current_filename.length < 4) {
            alert("Select a File");
        } else {
            $("#uploadassetbutton").hide();
            $("#uploadprogress").show();
            console.log(updData);
            axiosInstance.post('builder/upload_url/', updData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var uploadinfo = result.data;
                    uploadNewAsset(uploadinfo,fullfilepath);
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

	async function uploadNewAsset(uploadinfo,fullfilepath) {

		// Get the selected file from the input element
		var ufile = document.getElementById('upload-asset-file').files[0];

		var upload_size = ufile.size.toString();
		var upload_endpoint = uploadinfo['url'];
		var mediaId = '';
		var totalPct = 0;
		var fields = uploadinfo['fields'];
        // metadatainfo['filename'] = filename;
		// metadatainfo['filetype'] = file.type.toString();

        const formData = new FormData();
        formData.append('Content-Type', ufile.type.toString());
        Object.keys(fields).forEach(key => {
            formData.append(key, fields[key]);
        }); 
        formData.append('file', ufile); // The file has be the last element
      
        const response = await axios.post(upload_endpoint, formData, {
          headers: {'Content-Type': 'multipart/form-data'},
        }).then((response) => {
            console.log('Completed! '+fullfilepath);
		    saveAssetUploadInfo(fullfilepath);
          }, (error) => {
            console.log(error);
          });
		// Setup Upload Options
		// var upload_options = {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     },
		// 	endpoint: upload_endpoint,
		// 	metadata: { 'body': formData},
		// 	chunkSize: 50 * 1024 * 1024, // Set To 50MB
		// 	//retryDelays: [0, 3000, 5000, 10000, 20000],
		// 	uploadSize: upload_size,
		// 	onError: function (error) {
		// 		console.log("Failed because: " + error);
		// 	},
		// 	onProgress: function (bytesUploaded, bytesTotal) {
		// 		var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
		// 		totalPct = Math.round(percentage);
		// 		document.getElementById("uploadprogress").style.display = 'block';
		// 		document.getElementById("uploadprogress").innerText = "Uploading: "+totalPct+"%";
		// 	},
		// 	onSuccess: function () {
		// 		console.log("Upload Finished");
		// 		if((upload_endpoint != '')&(totalPct == 100)) {
        //             console.log('Completed! '+upload_endpoint);
		// 			//saveAssetUploadInfo(upload_endpoint);
		// 		}
		// 	},
		// };
		// // Create a new tus upload
		// var media_upload = new tus.Upload(file, upload_options);
		// media_upload.start();
	}


    document.addEventListener('DOMContentLoaded', function() {
        adjustUploaderBox();
    });

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    window.addEventListener("resize",
    function(){ 
        adjustUploaderBox();
    }, false);

    window.$(document).ready(function(){
        var sel_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(sel_elems);
    });

    if(pageSet == false) {
        setPage(true);
        window.startPageModals();
        window.resetBodyWidth();
        grabCurrentAssets();
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            <div className="row page-width-wide page-clean-body">
                
                <div id="leftrailnav" className="col s12 m3 border-section-full exr-new-dark-grey white-text" style={{position:'relative',padding:"5px",minHeight:'600px'}}>
                    <div className="headline-text section-title-text center-align" style={{fontSize:'16px',marginTop:"20px",marginBottom:"10px"}}>
                        &nbsp;TRACK UPLOADER
                    </div>
                    <div style={{marginLeft:'5%',width:"90%",marginTop:'20px'}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div style={{marginLeft:'5%',width:"90%",marginTop:'20px'}}>
                        Category:
                    </div>
                    <div style={{marginLeft:'5%',width:"86%",marginTop:'5px'}}>
                        <select
                            id="upload-sel-cat"
                            className="input-field">
                            <option value="all" selected>Choose Type</option>
                            <option value="gate">Gates</option>
                            <option value="track_furniture">Track Furniture</option>
                            <option value="HDR_skybox">HDR Skyboxes</option>
                            <option value="skybox">Skyboxes General</option>
                            <option value="game_backdrop">Game Backdrops</option>
                            <option value="loading_screen">Loading Screens</option>
                            <option value="planet_texture">Planet Textures</option>
                            <option value="asteroid">Asteroids</option>
                            <option value="test_ship">Test Ships</option>
                            <option value="ship_sound">Ship Sounds</option>
                            <option value="track_sound">Track Sounds</option>
                            <option value="game_music">Game Music</option>
                            <option value="planet">Planet Objects</option>
                            <option value="other">Other Assets</option>
                        </select>
                    </div>
                    <div style={{marginLeft:'5%',width:"90%",marginTop:'20px'}}>
                        Asset Name:
                    </div>
                    <div style={{marginLeft:'5%',width:"90%",marginTop:'5px'}}>
                        <input id="upload-asset-name" className="browser-default grey-bubble-input"  style={{width:'100%'}} type="text" />
                    </div>
                    
                    <div style={{marginLeft:'5%',width:"90%",marginTop:'20px'}}>
                        Asset File:
                    </div>
                    <div style={{marginLeft:'5%',width:"90%",marginTop:'5px'}}>
                        <input id="upload-asset-file" className="browser-default grey-bubble-input"  style={{width:'100%'}} type="file" />
                    </div>
                    <div style={{marginLeft:'5%',width:"90%",marginTop:'40px'}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div style={{marginLeft:'5%',width:"90%",marginTop:'20px'}}>
                        <button className="btn-flat transparent waves-effect side-nav-button-red" id="uploadassetbutton"
                                onClick={()=> getAssetUploadURL()}> 
                            <span className="center side-nav-button-text">Upload Asset</span>
                        </button>
                        <div id="uploadprogress" className="center" style={{display:'none',width:'100%',marginTop:'20px'}}>
                            Uploading...
                        </div>
                    </div>
                </div>
                <div id="righsidecontent" className="col s12 m9 white-text" style={{position:'relative', minHeight:'600px', minHeight:'90vh',paddingLeft:'17px'}}>
                    <div className="row border-section-full exr-new-dark-grey" style={{position:'relative',padding:"25px",minHeight:'90vh',paddingBottom:'80px'}}>
                        <div className="row stats-viewer-headline" style={{marginBottom:"5px"}}>
                            <div className="col s12">
                                Track Manager
                            </div>
                        </div>
                        
                        {asset_list_html}
                    </div>
                </div>
            </div>
        </div>);
};
