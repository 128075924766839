import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import "./prelaunch.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import {WalletSync} from "../../Pages"
import { NoToneMapping } from "three";
import {InjectedConnector, } from "@web3-react/injected-connector";

let web3;

const HARDHAT_CHAIN_ID = 31337;
const MOONBASE_CHAIN_ID = 1287;
const MOONBEAM_CHAIN_ID = 1284;

const supportedChainIds = [
  HARDHAT_CHAIN_ID,
  MOONBASE_CHAIN_ID,
];

const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: supportedChainIds,
});

export const PreLaunchSplash = () => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, accountAddress } = useSelector((state) => state.account);

    const { chainId, account } = useWeb3React();

    if((racerID.length > 1)&&(accountAddress.length > 1)) {
        // dispatch(updateLocation('workshop'));
    }

    const [pageSet, setPage] = useState(false);

    async function updateWalletAddress() {
        let wallet_address = await syncAccountWallet();
        if(wallet_address) {
            if(wallet_address.length > 5) {
                document.getElementById("walletID0").value = wallet_address;
                document.getElementById("walletID").value = wallet_address;
                document.getElementById("walletID2").value = wallet_address;
                document.getElementById("WID0").value = wallet_address;
                document.getElementById("WID").value = wallet_address;
                document.getElementById("WID2").value = wallet_address;
                document.getElementById("walletlabel0").style.display = 'none';
                document.getElementById("walletlabel").style.display = 'none';
                document.getElementById("walletlabel2").style.display = 'none';
            }
        }
    }

    async function submitWhitelistSignup(e) {
        e.preventDefault();

        var firstname = document.getElementById("fname").value;
        var lastname = document.getElementById("lname").value;
        var email = document.getElementById("email").value;
        var walletID = document.getElementById("walletID").value;
        var invitecode = document.getElementById("invitecode").value;

        var addData = new Object();
        addData.fname= encodeURIComponent(firstname);
        addData.lname = encodeURIComponent(lastname);
        addData.email = encodeURIComponent(email);
        addData.walletID = encodeURIComponent(walletID);
        addData.invite_code = encodeURIComponent(invitecode);

        if(walletID.length < 10) {
            alert('Connect Your Metamask Wallet to Register');
        } else {
            document.getElementById("registersubmit").style.display = 'none';
            document.getElementById("registerloading").style.display = 'block';
            axiosInstance.post('whitelist/register/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var signup_result = result.data;
                        
                    if(signup_result.register_status.includes("whitelist")) {
                        setTimeout(function() {
                            document.getElementById("registerform").style.display = 'none';
                            document.getElementById("signupheader").style.display = 'none';
                            document.getElementById("confirmheader").style.display = 'block';
                            document.getElementById("confirmsignupwhitelist").style.display = 'block';
                        }, 3000);
                    } else {
                        setTimeout(function() {
                            document.getElementById("registerform").style.display = 'none';
                            document.getElementById("signupheader").style.display = 'none';
                            document.getElementById("confirmheader").style.display = 'block';
                            document.getElementById("confirmsignuplottery").style.display = 'block';
                        }, 3000);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    async function checkWhitelistSignup(e) {
        e.preventDefault();
        var walletID = document.getElementById("walletID2").value;
        var addData = new Object();
        addData.walletID= encodeURIComponent(walletID);

        document.getElementById("regchecksubmit").style.display = 'none';
        document.getElementById("regcheckloading").style.display = 'block';
        axiosInstance.post('whitelist/user_check/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var signup_result = result.data;
                if(signup_result.register_status == 'whitelist') {
                    setTimeout(function() {
                        document.getElementById("registercheckform").style.display = 'none';
                        document.getElementById("regcheckheader").style.display = 'none';
                        document.getElementById("whitelistheader").style.display = 'block';
                        document.getElementById("confirmwhitelist").style.display = 'block';
                    }, 3000);
                } else if(signup_result.register_status == 'lottery') {
                    setTimeout(function() {
                        document.getElementById("registercheckform").style.display = 'none';
                        document.getElementById("regcheckheader").style.display = 'none';
                        document.getElementById("mintpassheader").style.display = 'block';
                        document.getElementById("confirmlottery").style.display = 'block';
                    }, 3000);
                } else {
                    setTimeout(function() {
                        document.getElementById("registercheckform").style.display = 'none';
                        document.getElementById("regcheckheader").style.display = 'none';
                        document.getElementById("noconfirmheader").style.display = 'block';
                        document.getElementById("confirmnone").style.display = 'block';
                    }, 3000);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    document.addEventListener('DOMContentLoaded', function() {
        
        window.resetCenterHeight();
        window.startPageModals();
        window.resetBodyWidth();
        resizeBGVideo();

        window.$("#exr-logo-button").click(function() {
            window.location.reload();
        });

        window.$("#discord-access-button").click(function() {
            window.open('https://discord.gg/exiledracers');
        });

        window.$("#twitter-access-button").click(function() {
            window.open('https://twitter.com/exiledracers');
        });

        window.$("#medium-access-button").click(function() {
            window.open('https://blog.exiledracers.com/exiled-racers-announces-stunning-3d-nft-project-with-bonus-play-and-earn-racing-manager-backed-by-5a2fecac5419');
        });
    });

    function startSwappingHoverButtons() {
        window.$("#exr-logo-button").css({'margin-top':'5vh',width:'65%'});
        window.$("#enter-alpha-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/EnterAlpha_White_Gif.gif');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/EnterAlpha_Red_Gif.gif');
        });

        window.$("#check-status-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/AccessStatus_White_Gif.gif');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/AccessStatus_Red_Gif.gif');
        });

        window.$("#request-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/RequestAccess_White_Gif.gif');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/RequestAccess_Red_Gif.gif');
        });

        window.$("#discord-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Discord_White_Gif.gif');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Discord_Red_Gif.gif');
        });

        window.$("#twitter-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Twitter_White_Gif.gif');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Twitter_Red_Gif.gif');
        });

        window.$("#medium-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Medium_White_Gif.gif');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Medium_Red_Gif.gif');
        });

        window.$("#exr-logo-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/ExiledRacers_White_Gif.gif');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/ExiledRacers_Red_Gif.gif');
        });
    }

    function startWhiteMobileButtons() {
        window.$("#exr-logo-button").css({'margin-top':'15vh',width:'300px','margin-left':'0px'});
        window.$("#enter-alpha-button").attr("src",'https://media.exiledracers.com/global/splash/EnterAlpha_White_Gif0.png');
        window.$("#check-status-button").attr("src",'https://media.exiledracers.com/global/splash/AccessStatus_White_Gif0.png');
        window.$("#request-access-button").attr("src",'https://media.exiledracers.com/global/splash/RequestAccess_White_Gif0.png');
        window.$("#discord-access-button").attr("src",'https://media.exiledracers.com/global/splash/Discord_White_Gif0.png');
        window.$("#twitter-access-button").attr("src",'https://media.exiledracers.com/global/splash/Twitter_White_Gif0.png');
        window.$("#medium-access-button").attr("src",'https://media.exiledracers.com/global/splash/Medium_White_Gif0.png');
        window.$("#exr-logo-button").attr("src",'https://media.exiledracers.com/global/splash/ExiledRacers_White_Gif0.png');
    }
    
    function resizeBGVideo() {
        if((window.innerWidth / window.innerHeight) >= 1.77) {
            window.$("#splash-video-container").show();
            window.$("#splash-image-container").hide();
            window.$("#splash-image-container-horizontal").hide();
            window.$("#request-access-button").css({'marginLeft':'-40px'});
            window.$("#check-status-button").css({'marginLeft':'-30px'});
            window.$("#social-button-row").css({'marginTop':'25vh'});
            startSwappingHoverButtons();
            var bgvideo = document.getElementById("main-splash-video");
            if(bgvideo) {
                bgvideo.play();
            }
        } else if(window.innerWidth > 450) {
            window.$("#splash-video-container").hide();
            window.$("#splash-image-container").show();
            window.$("#splash-image-container-horizontal").hide();
            window.$("#request-access-button").css({'marginLeft':'-10px'});
            window.$("#check-status-button").css({'marginLeft':'0px'});
            window.$("#social-button-row").css({'marginTop':'20vh'});
            startSwappingHoverButtons();
        } else {
            window.$("#splash-video-container").hide();
            window.$("#splash-image-container").hide();
            window.$("#splash-image-container-horizontal").show();
            window.$("#request-access-button").css({'marginLeft':'0px'});
            window.$("#check-status-button").css({'marginLeft':'0px'});
            window.$("#social-button-row").css({'marginTop':'20vh'});
            startWhiteMobileButtons();
        }
    }

    window.addEventListener('resize', function() {
        window.resetCenterHeight();
        resizeBGVideo();
    });

    if(setPage === false) {
        pageSet(true);
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row" id="main-landing-page">
            <div id="splash-image-container" style={{display:'none',width:'100%',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-20',background:'url(https://media.exiledracers.com/global/splash/EXR_LandingPage_Clean.jpg)',backgroundSize:'cover',backgroundPosition:'center'}}>
            </div>
            <div id="splash-image-container-horizontal" style={{width:'100%',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-20',background:'url(https://media.exiledracers.com/global/splash/exr_prelaunch_splash_half_dark.png)',backgroundSize:'cover',backgroundPosition:'left'}}>
            </div>
            <div id="splash-video-container" style={{width:'100%',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-10'}}>
                <video id="main-splash-video" loop autoPlay muted width="100%">
                    <source src="https://media.exiledracers.com/global/splash/exr_landing_background_video.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="col s12 transparent" style={{position:'relative',height:'100vh'}}>
                <div id="splash-main-prelaunch" style={{top:'0px !important',height:'100%',position:'relative'}}>
                    <div className="row center-align">
                        <div className="col s10 offset-s1 l4 offset-l4">
                        <img id="exr-logo-button" style={{cursor:'pointer',marginLeft:'-20px',marginTop:'5vh',minWidth:'250px'}} title="Exiled Racers"
                           width="65%" src="https://media.exiledracers.com/global/splash/ExiledRacers_Red_Gif.gif" />
                        </div>
                    </div>
                    <div className="row center-align" style={{marginBottom:'10px'}}>
                        <div className="col s10 offset-s1 l6 offset-l3 center-align">
                            <img className="modal-trigger" data-target="registerbox" style={{cursor:'pointer',paddingTop:'14vh',marginLeft:'-40px',minWidth:'300px'}} 
                                id="request-access-button" title="Request Access"
                                src="https://media.exiledracers.com/global/splash/RequestAccess_Red_Gif.gif" width="80%" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s10 offset-s1 l4 offset-l4 center-align" style={{padding:'0px'}}>
                                <img className="modal-trigger" data-target="whitelistcheckbox" style={{cursor:'pointer',marginLeft:'-58px',marginTop:'-5px',minWidth:'220px'}} title="Check Whitelist Status"
                                    id="check-status-button" src="https://media.exiledracers.com/global/splash/AccessStatus_Red_Gif.gif" width="53%" />
                                <img className="modal-trigger" data-target="alphabox" style={{cursor:'pointer',marginLeft:'1px',marginRight:'1px',minWidth:'200px'}} title="Alpha Login"
                                    id="enter-alpha-button" src="https://media.exiledracers.com/global/splash/EnterAlpha_Red_Gif.gif" width="47%" />
                        </div>
                    </div>
                    <div id="social-button-row" className="row center-align" style={{position:'relative',width:'100%',marginTop:'25vh',zIndex:'100'}}>
                        <div className="col s10 offset-s1 l4 offset-l4">
                            <img style={{cursor:'pointer',marginLeft:'20px',minWidth:'67px'}} width="15%" title="EXR Discord"
                                id="discord-access-button" src="https://media.exiledracers.com/global/splash/Discord_Red_Gif.gif"/>
                            <img style={{cursor:'pointer',marginLeft:'20px',minWidth:'67px'}} width="15%" title="EXR Twitter"
                                id="twitter-access-button" src="https://media.exiledracers.com/global/splash/Twitter_Red_Gif.gif"/>
                            <img style={{cursor:'pointer',marginLeft:'20px',minWidth:'67px'}} width="15%" title="EXR Blog"
                                id="medium-access-button" src="https://media.exiledracers.com/global/splash/Medium_Red_Gif.gif"/>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="alphabox" className="modal">
            <WalletSync />
        </div>
        <div id="registerbox" className="modal">
            <div className="modal-content">
                <div className="row">
                    <div className="col s12" style={{marginBottom:'15px'}}>                    
                        <h5 id="signupheader" className="headline-text left" style={{marginBottom:'5px'}}>ENTER THE MINT PASS LOTTERY</h5>
                        <h5 id="confirmheader" className="headline-text left splash-modal-title-alt">YOU'RE REGISTERED!</h5>
                        <img src="https://media.exiledracers.com/global/exr_logo_v2_black_trans.png" height="40px" className="right hide-on-small-only" />
                    </div>
                    <div id="confirmsignupwhitelist" className="col s12 subheadline-text splash-modal-confirmtxt">
                        Thanks! You're now registered for the Exiled Racers whitelist. 
                        <br /><br />
                        We'll be in touch soon with more details on the dates and prices for minting and how the game will work. 
                        <br /><br />
                        Join our <a onClick={() =>  window.joinDiscordOpen()} className="cursor-pointer">Discord</a> to keep in the loop with updates and banter. You can reach out to us 
                         <a href="mailto:info@exiledracers.com"> here</a> if you have any questions.
                        <br /><br />
                        - The EXR Team
                    </div>
                    <div id="confirmsignuplottery" className="col s12 subheadline-text splash-modal-confirmtxt">
                        Thanks! You're now registered for the Exiled Racers mint pass lottery. 
                        <br /><br />
                        We'll be in touch soon with more details on dates for the lottery selection, prices of the mint and how the game will work. 
                        <br /><br />
                        Join our <a onClick={() =>  window.joinDiscordOpen()} className="cursor-pointer">Discord</a> to keep in the loop with updates and banter. You can reach out to us 
                         <a href="mailto:info@exiledracers.com"> here</a> if you have any questions.
                        <br /><br />
                        - The EXR Team
                    </div>
                    <form id="registerform" className="subheadline-text" style={{fontSize:'14px'}} onSubmit={submitWhitelistSignup}>
                        <div className="col s12" style={{fontSize:'16px',marginBottom:'10px'}}>
                            We're releasing a limited set of Exiled Racers NFT's soon. Join the Exiled Racers Mint Pass Lottery to get a chance to be one of the first to purchase the Exiled Racers NFT's.
                            <br/><br/>Enter your information below to enter the lottery.
                        </div>
                        <div className="col s12 m6 input-field">
                            <input name="fname" id="fname" type="text" className="validate left" required />
                            <label htmlFor="fname" className="black-text">First Name</label>
                        </div>
                        <div className="col s12 m6 input-field">
                            <input name="lname" id="lname" type="text" className="validate left" required />
                            <label htmlFor="lname" className="black-text">Last Name</label>
                        </div>
                        <div className="col s12 m6 input-field">
                            <input name="email" id="email" type="email" className="validate left" required />
                            <label htmlFor="email" className="black-text">Email Address</label>
                        </div>
                        <div className="col s12 m6 input-field">
                            <input name="invitecode" id="invitecode" type="text" className="left" />
                            <label htmlFor="invitecode" className="black-text">Whitelist Code (optional)</label>
                        </div>
                        <div className="col s12 m12 input-field">
                            <input name="WID0" id="WID0" type="text" className="validate left" disabled required />
                            <label id="walletlabel0" htmlFor="WID0" className="black-text">Wallet Address</label>
                        </div>
                        <div className="col s12 m12">
                            <div id="mmasksync" className="btn-flat csr-button-black left enableEthereumButton splash-submodal-button"
                                title="Connect MetaMask Wallet"
                                onClick={updateWalletAddress}>
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">CONNECT METAMASK</span>
                                <span className="splash-main-button-bracket">]</span>
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'15px'}}>
                            <input name="walletID0" id="walletID0" type="hidden" className="validate left" disabled required />
                            <button id="registersubmit" className="btn-flat csr-button-black modal-trigger right splash-modal-button"
                                type="submit" title="Submit Registration">
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">REGISTER</span>
                                <span className="splash-main-button-bracket">]</span>
                            </button>
                            <div id="registerloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="whitelistcheckbox" className="modal">
            <div className="modal-content">
                <div className="row">
                    <div className="col s12" style={{marginBottom:'15px'}}>                    
                        <h5 id="regcheckheader" className="headline-text left" style={{marginTop:'15px'}}>
                            CHECK MY STATUS</h5>
                        <h5 id="whitelistheader" className="headline-text left splash-modal-title-alt" style={{marginTop:'15px'}}>
                            ON THE WHITELIST!</h5>
                        <h5 id="mintpassheader" className="headline-text left splash-modal-title-alt" style={{marginTop:'15px'}}>
                            MINT PASS LOTTERY!</h5>
                        <h5 id="noconfirmheader" className="headline-text left splash-modal-title-alt" style={{marginTop:'15px'}}>
                            YOU'RE NOT REGISTERED!</h5>
                        <img src="https://media.exiledracers.com/global/exr_logo_v2_black_trans.png" 
                            height="40px" className="right hide-on-small-only" />
                    </div>
                    <div id="confirmwhitelist" className="col s12 subheadline-text splash-modal-confirmtxt">
                        Congrats! You're registered for the Exiled Racers Whitelist. 
                        <br /><br />
                        Join our <a onClick={() =>  window.joinDiscordOpen()} className="cursor-pointer">Discord</a> to keep in the loop with updates and banter. You can reach out to us 
                         <a href="mailto:info@exiledracers.com"> here</a> if you have any questions.
                        <br /><br />
                        - The EXR Team
                    </div>
                    <div id="confirmlottery" className="col s12 subheadline-text splash-modal-confirmtxt">
                        You're registered for the Exiled Racers Mint Pass Lottery. 
                        <br /><br />
                        Join our <a onClick={() =>  window.joinDiscordOpen()} className="cursor-pointer">Discord</a> to keep in the loop with updates and banter. You can reach out to us 
                         <a href="mailto:info@exiledracers.com"> here</a> if you have any questions.
                        <br /><br />
                        - The EXR Team
                    </div>
                    <div id="confirmnone" className="col s12 subheadline-text splash-modal-confirmtxt">
                        You're not registered for the Exiled Racers Whitelist or Mint Pass Lottery. 
                        <br /><br />
                        Use the Request Access Button on the Exiled Racers homepage to register.
                        <br /><br />
                        - The EXR Team
                    </div>
                    <form id="registercheckform" className="subheadline-text" style={{fontSize:'14px'}} onSubmit={checkWhitelistSignup}>
                        <div className="col s12" style={{fontSize:'16px',marginBottom:'10px'}}>
                          Connect your Wallet to see if you are registered for the Exiled Racers Whitelist or Mint Pass Lottery.
                        </div>
                        <div className="col s12 m12 input-field">
                            <input name="WID2" id="WID2" type="text" className="validate left" disabled required />
                            <label id="walletlabel2" htmlFor="WID2" className="black-text">Wallet Address</label>
                        </div>
                        <div className="col s12 m12">
                            <div id="mmasksync" className="btn-flat csr-button-black left enableEthereumButton2 splash-submodal-button"
                                title="Connect MetaMask Wallet"
                                onClick={updateWalletAddress}>
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">CONNECT METAMASK</span>
                                <span className="splash-main-button-bracket">]</span>
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'15px'}}>
                            <input name="walletID2" id="walletID2" type="hidden" className="validate left" disabled required />
                            <button id="regchecksubmit" className="btn-flat csr-button-black modal-trigger right splash-modal-button"
                                type="submit" title="Submit Registration">
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">SUBMIT</span>
                                <span className="splash-main-button-bracket">]</span>
                            </button>
                            <div id="regcheckloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>);
};
