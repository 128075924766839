import {RacersEvent} from "./racersEvent.js";

export class RacersEventClustering extends RacersEvent {
    /**
     * Construct a clustering event
     * @param {number} frame The frame this event takes place at
     * @param {number} density The density factor, lower is denser
     * @param {number} playerDistance The distance of the player to the average position of all racers
     */
    constructor(frame, density, playerDistance) {
        super(frame);

        this.density = density;
        this.playerDistance = playerDistance;
    }
}