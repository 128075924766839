import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./sportsbook.css";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals} from "../../../utils";
import {RaceExplorer, RaceViewer, RaceScheduler} from "./Sections";
import $ from 'jquery';
import { toInteger } from "lodash";

let web3;
let racer_sim_watched_global = 0;
let race_simulated_global = 0;
let current_racer_global = 0;
let racer_invite_status_global = 0;
let race_hash_global = '';
let current_race_hash = '';
let current_race_group = 1;
let current_race_collection = 6;
let collectionList = [];
let open_invite_race = 0;
let open_entry_closed = 0;
let predictions_closed = 0;
let entryDisplay = 'none';
let training_box_race_landing = 0;
let current_time_schedule = 0;
let scheduleready = 0;
let schedulestarted = 0;
let race_schedule_array = [];
let time_schedule = [];
// time_schedule.push({schedule_type:'wait', group_id:1, race_time: '19:17:00'});
// time_schedule.push({schedule_type:'play', group_id:1, race_time: '19:18:00'});
// time_schedule.push({schedule_type:'wait', group_id:2, race_time: '19:04:30'});
// time_schedule.push({schedule_type:'play', group_id:2, race_time: '19:06:00'});
// time_schedule.push({schedule_type:'wait', group_id:3, race_time: '19:08:30'});
// time_schedule.push({schedule_type:'play', group_id:3, race_time: '19:10:00'});
// time_schedule.push({schedule_type:'wait', group_id:4, race_time: '19:13:30'});
// time_schedule.push({schedule_type:'play', group_id:4, race_time: '19:15:00'});
// time_schedule.push({schedule_type:'wait', group_id:5, race_time: '19:18:30'});
// time_schedule.push({schedule_type:'play', group_id:5, race_time: '19:20:00'});
// time_schedule.push({schedule_type:'play', group_id:5, race_time: '19:20:00'});

time_schedule.push({schedule_type:'wait', group_id:1, race_time: '19:43:00'});
time_schedule.push({schedule_type:'play', group_id:1, race_time: '19:43:30'});
time_schedule.push({schedule_type:'wait', group_id:2, race_time: '19:44:00'});
time_schedule.push({schedule_type:'play', group_id:2, race_time: '19:44:30'});
time_schedule.push({schedule_type:'wait', group_id:3, race_time: '19:45:00'});


export const Sportsbook = () => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    
    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(setRacerLogin('',''));
    //     dispatch(updateLocation("splash"));
    //     window.location.href= '/';
    // }

    var url_race_unique_id = '';
    var url_race_invite_list = '';
    var start_race_section = '';
    var tmp_left_nav_section = 'upcoming';
    var racehash = window.location.href.split("#/");
    if(racehash.length > 1) {
        var racehash2 = racehash[1].replace("#/",'');
        var racehash3 = racehash2.split("/");
        if(racehash3.length > 1) {
            url_race_unique_id = racehash3[1].replace("/",'');
        }
        if((racehash3.length > 2)&&(url_race_unique_id == 'invite')) {
            url_race_invite_list = racehash3[2].replace("/",'');
        }
        if(racehash3[0] == 'race') {
            start_race_section = 'details';
        } else {
            var racehash4 = racehash3[0].split("_");
            start_race_section = racehash4[1];
        }
    }
    race_hash_global = url_race_unique_id;

    const [pageSet, setPage] = useState(false);
    const [headerSet, setHeader] = useState(false);
    const [race_id, setRaceID] = useState(0);
    const [raceHash, setRaceHash] = useState('');
    const [raceViewerHTML, setRaceViewerHTML] = useState('');
    const [raceExplorerHTML, setRaceExplorerHTML] = useState('');
    const [raceSchedulerHTML, setRaceSchedulerHTML] = useState('');
    const [selected_prediction_html,setSelectedPredictionHTML] = useState('');

    function grabOfficialCollectionList() {
        var addData = new Object();
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('sportsbook/race_collection_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                collectionList = result.data; 
                startPageSections(collectionList);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function startPageSections(collectionarr) {
        current_race_collection = collectionarr[0]['race_group_collection_id'];

        current_race_group = 'groupid_'+collectionarr[0]['primary_race_group_id'];
        if(collectionarr[0]['race_group_collection_id'] == 5) {
            current_race_group = 'groupid_10';
        } else if(collectionarr[0]['race_group_collection_id'] == 6) {
            current_race_group = 'groupid_13';
        }
        if (collectionList[0]['collection_type'] == 'hourly_open') {
            if (collectionList[0]['recent_races_show'] > 0) {
                current_race_group = 'recent_'+collectionarr[0]['primary_race_group_id'];
            } else if ((collectionList[0]['upcoming_races'] > 0)||(collectionList[0]['races_status'] == 'upcoming')) {
                current_race_group = 'upcoming_'+collectionarr[0]['primary_race_group_id'];
            } else {
                current_race_group = 'past_'+collectionarr[0]['primary_race_group_id'];
            }
        }

        setRaceViewerHTML(<RaceViewer collectionID={current_race_collection} 
                                    collectionList={collectionarr} 
                                    raceID={'waitroom'} 
                                    updateCollection={updateRaceCollection} />)
        setRaceExplorerHTML(<RaceExplorer collectionID={current_race_collection} 
                                collectionList={collectionarr} 
                                raceID={'waitroom'} raceGroupID={current_race_group} 
                                switchRaces={updateRaceViewer} 
                                loadPredictionBox={updateRacePredictionBox} 
                                updateRaceGroup={updateCurrentRaceGroupSelected}
                                updateCollection={updateRaceCollection} />);
        setRaceSchedulerHTML(<RaceScheduler collectionID={current_race_collection} 
                                collectionList={collectionarr} 
                                raceID={'waitroom'} raceGroupID={current_race_group} 
                                switchRaces={updateRaceViewer} 
                                loadPredictionBox={updateRacePredictionBox} 
                                updateRaceGroup={updateCurrentRaceGroupSelected}
                                updateSchedule={updateRaceScheduleArray}
                                updateCollection={updateRaceCollection} />);
    }


    if(pageSet == false) {
        setPage(true);
        grabOfficialCollectionList();
        window.startPageModals();
        window.resetBodyWidth();
    }

    function startTimeSchedule() {
        for(var i = current_time_schedule; i < time_schedule.length;i++) {
            var currenttime = time_schedule[i]['race_time'];
            var countDownDate = new Date( "Apr 19, 2023 "+currenttime+" GMT-0400 (Eastern Daylight Time)").getTime();
            var now = new Date().getTime();
            var distance = countDownDate - now;
            if((distance <= 0)||(schedulestarted == 0)) {
                if(schedulestarted == 0) {
                    current_time_schedule = 0;
                } else {
                    current_time_schedule = i+1;
                }
                schedulestarted = 1;
                
                var current_group_id = time_schedule[i]['group_id'];
                var nexthash = race_schedule_array[(current_group_id-1)];
                current_race_hash = nexthash;

                var current_action = time_schedule[i].schedule_type;

                var current_title = 'NEXT RACE STARTS IN';
                var nextime = '22:00';
                if((i+1) < time_schedule.length) {
                    nextime = time_schedule[(i+1)]['race_time'];
                }
                
                let countdownarray = {'action':current_action,'title':current_title,'time':nextime};
                setRaceViewerHTML(<RaceViewer raceID={current_race_hash} loadPredictionBox={updateRacePredictionBox} countdownInfo={countdownarray} />);
                i = time_schedule.length;
            }
            // {schedule_type:'race_wait',group_id:1,race_time: '19:00:00'},
        }

        setTimeout(function(){
            startTimeSchedule();
        },1000);
    }

    function updateRaceScheduleArray(hasharray) {
        race_schedule_array = hasharray;
        scheduleready = 1;
        current_race_hash = race_schedule_array[0];
        //startTimeSchedule();
    }

    function updateRaceViewer(newRaceHash) {
        if(newRaceHash.length > 0) {
            current_race_hash = newRaceHash;
            setRaceHash(newRaceHash);
            setRaceViewerHTML(<RaceViewer collectionID={current_race_collection} raceID={newRaceHash} loadPredictionBox={updateRacePredictionBox} updateCollection={updateRaceCollection} />)
        }
    }

    function updateRaceCollection(collectionid) {
        current_race_collection = collectionid;
        for(var j = 0; j < collectionList.length;j++) {
            if(collectionid == collectionList[j]['race_group_collection_id']) {
                current_race_group = 'groupid_'+collectionList[j]['primary_race_group_id'];
                if (collectionList[j]['collection_type'] == 'hourly_open') {
                    if (collectionList[j]['recent_races_show'] > 0) {
                        current_race_group = 'recent_'+collectionList[j]['primary_race_group_id'];
                    } else if ((collectionList[j]['upcoming_races'] > 0)||(collectionList[j]['races_status'] == 'upcoming')) {
                        current_race_group = 'upcoming_'+collectionList[j]['primary_race_group_id'];
                    } else {
                        current_race_group = 'past_'+collectionList[j]['primary_race_group_id'];
                    }
                }
            }
        }

        setRaceHash('');
        setRaceViewerHTML('')
        setRaceExplorerHTML('');
        setRaceSchedulerHTML('');
        setRaceViewerHTML(<RaceViewer collectionID={collectionid} 
                                collectionList={collectionList}
                                raceID={'waitroom'} 
                                loadPredictionBox={updateRacePredictionBox} 
                                updateCollection={updateRaceCollection} />)
        setRaceExplorerHTML(<RaceExplorer collectionID={collectionid} 
                                collectionList={collectionList} 
                                raceID={'waitroom'} raceGroupID={current_race_group} 
                                switchRaces={updateRaceViewer} 
                                loadPredictionBox={updateRacePredictionBox} 
                                updateRaceGroup={updateCurrentRaceGroupSelected}
                                updateCollection={updateRaceCollection} />);
        // setRaceSchedulerHTML(<RaceScheduler collectionID={collectionid} 
        //                         collectionList={collectionList}
        //                         raceID={'waitroom'} raceGroupID={current_race_group} 
        //                         switchRaces={updateRaceViewer} 
        //                         loadPredictionBox={updateRacePredictionBox} 
        //                         updateRaceGroup={updateCurrentRaceGroupSelected}
        //                         updateSchedule={updateRaceScheduleArray} 
        //                         updateCollection={updateRaceCollection} />);
    }

    function addRacePrediction(race_id, pred_racer_id, pred_participant_id, pred_finish, pred_amount) {
        var addData = new Object();
        addData.raceID = race_id;
        addData.userID = userID;
        addData.racerID = racerID;
        addData.predRacerID = pred_racer_id;
        addData.predParticipantID = pred_participant_id;
        addData.predFinish = pred_finish;
        addData.predAmount = pred_amount;

        if(userID == 0) {
            alert('Login To Your Account to Predit. To Signup, Ask Another Community Member on Discord For An Invite Link.');
        } else {
            $("#freepredcheckbutton").hide();
            $("#freepredcheckloading").show();
            axiosInstance.post('race/racer_predict/',addData).then(result => {
                setTimeout(function(){
                    $("#freepredcheckbutton").show();
                    $("#freepredcheckloading").hide();
                    var predictbox = document.getElementById('sbpredictracerbox');
                    var predict_instance = window.M.Modal.getInstance(predictbox);
                    predict_instance.close();
                },1500);
                setRaceViewerHTML(<RaceViewer collectionID={current_race_collection} 
                                        collectionList={collectionList}
                                        raceID={'waitroom'} 
                                        loadPredictionBox={updateRacePredictionBox} 
                                        updateCollection={updateRaceCollection} />);
                setRaceExplorerHTML('');
                setTimeout(function() {
                    setRaceViewerHTML(<RaceViewer collectionID={current_race_collection} 
                        collectionList={collectionList} 
                        raceID={current_race_hash} 
                        loadPredictionBox={updateRacePredictionBox} 
                        updateCollection={updateRaceCollection} />);

                        setRaceExplorerHTML(<RaceExplorer collectionID={current_race_collection} 
                            collectionList={collectionList} 
                            raceID={current_race_hash} raceGroupID={current_race_group} 
                            switchRaces={updateRaceViewer} 
                            loadPredictionBox={updateRacePredictionBox} 
                            updateRaceGroup={updateCurrentRaceGroupSelected}
                            updateCollection={updateRaceCollection} />);
                },1000);
                
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function makeRacePrediction(e) {
        e.preventDefault();
        let race_elem = e.target;
        let praceid = race_elem.dataset.raceid; 
        let pracerid = race_elem.dataset.pracerid;
        let participantid = race_elem.dataset.participantid;
        let predfinish = race_elem.dataset.predfinish;
        let freepred = race_elem.dataset.freepred;

        let add_prediction = addRacePrediction(praceid, pracerid, participantid, predfinish, freepred);

    }

    function updateCurrentRaceGroupSelected(newracegroup) {
        current_race_group = newracegroup;
    }

    function updateRacePredictionBox(raceid, predracerid, racernumber, racername, pilotimage, racecraftimage, predxp, winchance, picklast=0, userpredplace=0) {
        var race_id = 0;
        var pred_racer_id = 0;
        var pred_participant_id = 0;
        var pred_finish = 1;
        var free_pred_amount = 0;
        var paid_pred_amount = 10;
        var racer_number = 1;
        var racer_name = 'Racer';
        var pilot_image = '';
        var racecraft_image = '';
        var pred_xp = 5;
        var win_chance = 50;

        race_id = raceid;
        pred_racer_id = predracerid;
        racer_number = racernumber;
        racer_name = racername;
        pilot_image = pilotimage;
        racecraft_image = racecraftimage;
        pred_xp = predxp;
        win_chance = winchance;
        var pred_xp_text = pred_xp+' XP';
        var select_prediction_button = <><div id="freepredcheckbutton" className="round-border-button right" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                title="Place Free Race Prediction" 
                                                data-raceid={race_id} 
                                                data-pracerid={pred_racer_id}
                                                data-participantid={pred_participant_id}
                                                data-predfinish={pred_finish} 
                                                data-freepred={free_pred_amount}
                                                onClick={(e)=>makeRacePrediction(e)}>
                                            PREDICT
                                        </div></>
        if(picklast == 1) {
            pred_xp_text = 'Pick 1st: '+pred_xp+' XP // Pick Last: '+Math.round(pred_xp/2)+' XP';
            if(userpredplace == 5) {
                select_prediction_button = <>   
                                            <div className="col s12 m6">
                                            <div id="freepredcheckbutton" className="round-border-button left" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                    title="Place Free Race Prediction" 
                                                    data-raceid={race_id} 
                                                    data-pracerid={pred_racer_id}
                                                    data-participantid={pred_participant_id}
                                                    data-predfinish={1} 
                                                    data-freepred={free_pred_amount}
                                                    onClick={(e)=>makeRacePrediction(e)}>
                                                PREDICT 1ST
                                            </div>
                                        </div>
                                        <div className="col s12 m6">
                                            <div id="freepredcheckbutton2" className="round-border-button-green left" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                    title="Place Free Race Prediction" 
                                                    data-raceid={race_id} 
                                                    data-pracerid={pred_racer_id}
                                                    data-participantid={pred_participant_id}
                                                    data-predfinish={5} 
                                                    data-freepred={free_pred_amount}>
                                                PREDICTED LAST
                                            </div>
                                        </div>
                                        </>
            } else if(userpredplace == 1) {
                select_prediction_button = <><div className="col s12 m6" style={{padding:'0px',marginBottom:'10px'}}>
                                                <div id="freepredcheckbutton" className="round-border-button-green left" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                        title="Place Free Race Prediction" 
                                                        data-raceid={race_id} 
                                                        data-pracerid={pred_racer_id}
                                                        data-participantid={pred_participant_id}
                                                        data-predfinish={1} 
                                                        data-freepred={free_pred_amount}>
                                                    PREDICTED 1ST
                                                </div>
                                            </div>
                                            <div className="col s12 m6" style={{padding:'0px',marginBottom:'10px'}}>
                                                <div id="freepredcheckbutton2" className="round-border-button left" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                        title="Place Free Race Prediction" 
                                                        data-raceid={race_id} 
                                                        data-pracerid={pred_racer_id}
                                                        data-participantid={pred_participant_id}
                                                        data-predfinish={5} 
                                                        data-freepred={free_pred_amount}
                                                        onClick={(e)=>makeRacePrediction(e)}>
                                                    PREDICT LAST
                                                </div>
                                            </div>
                                        </>
            } else {
                select_prediction_button = <><div className="col s12 m6" style={{padding:'0px',marginBottom:'10px'}}>
                                            <div id="freepredcheckbutton" className="round-border-button left" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                    title="Place Free Race Prediction" 
                                                    data-raceid={race_id} 
                                                    data-pracerid={pred_racer_id}
                                                    data-participantid={pred_participant_id}
                                                    data-predfinish={1} 
                                                    data-freepred={free_pred_amount}
                                                    onClick={(e)=>makeRacePrediction(e)}>
                                                PREDICT 1ST
                                            </div>
                                        </div>
                                        <div className="col s12 m6" style={{padding:'0px',marginBottom:'10px'}}>
                                            <div id="freepredcheckbutton2" className="round-border-button left" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                    title="Place Free Race Prediction" 
                                                    data-raceid={race_id} 
                                                    data-pracerid={pred_racer_id}
                                                    data-participantid={pred_participant_id}
                                                    data-predfinish={5} 
                                                    data-freepred={free_pred_amount}
                                                    onClick={(e)=>makeRacePrediction(e)}>
                                                PREDICT LAST
                                            </div>
                                        </div>
                                        </>
            }
        }

        var selected_prediction_html = <><div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div className="row exr-new-dark-grey" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                    <div className="col s12 m6" style={{position:'relative',zIndex:'10px'}}>
                                                    <div className="left" style={{width:"40%",maxWidth:'100px',lineHeight:'0.75'}}>
                                                        <img src={racecraft_image} style={{width:'100%',borderRadius:'5px'}} />
                                                    </div>
                                                    <div className="left" style={{marginLeft:'0px',width:"47%",maxWidth:'200px',fontSize:'16px',fontFamily:"helvetica"}}>
                                                        <div className="center center-align headline-text" style={{width:'100%',fontSize:'24px',marginTop:'10px',marginBottom:'0px'}}>
                                                            {win_chance}%
                                                        </div>
                                                        <div className="center center-align" style={{width:'100%',marginTop:'-5px'}}>
                                                            WIN CHANCE
                                                        </div>
                                                        <div className="center center-align" style={{width:'100%',fontSize:'14px'}}>
                                                            Your Selection
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m6 subheadline-text" style={{position:'relative',zIndex:'10px',fontSize:'16px',fontFamily:"helvetica",paddingLeft:'10px',paddingRight:'10px'}}>
                                                    <div className="row" style={{margin:'0px',marginTop:'15px',marginBottom:'0px'}}>
                                                        <b>RACER {racer_number}:</b> {racer_name.toUpperCase()}
                                                    </div>
                                                    <div className="row" style={{margin:'0px',fontSize:'14px',marginBottom:'0px'}}>
                                                        When we test ran this race over 100 times, this racer won {win_chance}% of the time vs all other racers.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div id="free-prediction-select-box" className="row exr-stats-light-grey predict-hover" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{fontSize:"14px",marginTop:"0px",padding:"5px 10px 0px 10px"}}>
                                                    <div className="left headline-text" style={{marginLeft:'5px'}}>FREE PREDICTIONS</div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    Play our free predictions game to build your reputation, earn XP for predicting winners.
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    <div className="col s6" style={{padding:"0px"}}>
                                                        <b>Prediction Ticket</b><br/>
                                                        Free 
                                                    </div>
                                                    <div className="col s6">
                                                        <b>Correct Pick Rewards*</b><br/>
                                                        {pred_xp_text}
                                                    </div>
                                                </div>
                                                <div className="col s12 right-align" style={{marginTop:'35px',marginBottom:'7px',padding:"0px 15px"}}>
                                                    {select_prediction_button}
                                                    <div id="freepredcheckloading" className="preloader-wrapper small active right splash-modal-loader" 
                                                            style={{marginTop:'-5px',height:"25px",width:"25px",marginRight:"10px"}}>
                                                        <div className="spinner-layer spinner-red-only">
                                                            <div className="circle-clipper left">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="gap-patch">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="circle-clipper right">
                                                                <div className="circle"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div className="row" style={{position:'relative',margin:'0px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{marginTop:'5px',padding:"0px 15px",fontSize:'12px'}}>
                                                    * Note: XP rewards determined by the number of racers and the odds to win the race. 
                                                </div>
                                            </div>
                                        </div></>;

        if(window.innerWidth < 800) {
            $("#sbpredictracerbox").removeClass("page-modal-large").addClass("page-modal-full");
        } else {
            $("#sbpredictracerbox").removeClass("page-modal-full").addClass("page-modal-large");
        }

        setSelectedPredictionHTML(selected_prediction_html);
        var predictbox = document.getElementById('sbpredictracerbox');
        var predict_instance = window.M.Modal.getInstance(predictbox);
        predict_instance.open();

        
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            <div className="row white-text page-width-wide top-nav-header-full">
                {raceViewerHTML}
                {/* {raceSchedulerHTML} */}
                {raceExplorerHTML}
            </div>
            <div id="sbpredictracerbox" className="modal page-modal page-modal-large">
                <div className="modal-content" style={{paddingBottom:'5px'}}>
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                        <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>PREDICT RACE WINNER</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        {selected_prediction_html}
                    </div>
                </div>
            </div>
        </div>);
};
