import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "./terms.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerXP, setRacerCoins } from "../../../redux/Account/actions";
import {axiosInstance} from "../../../utils";
import {GlobalHeaderGrey} from "../../Organisms"
import { racerContractAddresses } from "../../../constants";
import $ from 'jquery';

let web3;

export const Terms = () => {
    function goToHomePage() {
        window.location.href="https://www.exiledracers.com/#/workshop";
    }
    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            <div className="row page-width page-clean-body white-text">
                <div className="col s12 transparent nav-wrapper" style={{marginTop:'10px'}}>
                        <div className="brand-logo center-align">
                            <img src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" height="80px" className="cursor-pointer" onClick={()=>goToHomePage()} />
                        </div>
                </div>
                <div className="col s12 exr-new-light-grey" style={{marginTop:'10px',borderRadius:'20px',padding:'40px',marginBottom:'40px'}}>
                <div className="col s12 center-align headline-text headline-text-terms" style={{marginTop:'20px'}}>
                    NFT PURCHASE T&Cs AND PLATFORM TERMS OF USE
                </div>
                <div className="col s12" style={{marginTop:'50px'}}>
<span className="headline-text">Last Updated Date: February 2023</span>
<br/><br/>
Exiled Racers is an online platform that provides users with the opportunity to purchase, collect, race and showcase digital blockchain racing collectibles (the “Platform”). R Labs Ltd. (the “Seller”) is the issuer and original seller of the Collectibles while Exiled Studios Inc (the “Operator”) is the owner and operator of the Platform (references herein to "we" or "us" are references to the Seller and/or the Operator as the case may be) and is making the Platform available to you. Before you use the Platform, however, you will need to agree to these Terms of Use and any terms and conditions incorporated herein by reference (collectively, these “Terms") and any further terms and condition that apply specifically with respect to the Platform itself. 
<br/><br/>
PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE PLATFORM. THESE TERMS GOVERN YOUR USE OF THE PLATFORM, UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO MAKE THE PLATFORM AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS AND ANY FURTHER TERMS AND CONDITION THAT APPLY SPECIFICALLY WITH RESPECT TO THE PLATFORM ITSELF. BY USING THE PLATFORM OR ANY PART OF IT, OR BY CLICKING “I ACCEPT” BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU” WILL MEAN THAT ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE UNWILLING TO MAKE THE PLATFORM AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE PLATFORM.
<br/><br/>
ANY PURCHASE OR SALE YOU MAKE, ACCEPT OR FACILITATE OUTSIDE OF THE PLATFORM OF A COLLECTIBLE (AS DEFINED BELOW) WILL BE ENTIRELY AT YOUR RISK. WE DO NOT CONTROL OR ENDORSE PURCHASES OR SALES OF COLLECTIBLES OUTSIDE OF THE PLATFORM. WE EXPRESSLY DENY ANY OBLIGATION TO INDEMNIFY YOU OR HOLD YOU HARMLESS FOR ANY LOSSES YOU MAY INCUR BY TRANSACTING, OR FACILITATING TRANSACTIONS, IN COLLECTIBLES OUTSIDE OF THE PLATFORM.
<br/><br/>
This document contains very important information regarding your rights and obligations, as well as conditions, limitations and exclusions that might apply to you. Please read it carefully. Before you use the Platform, you will need to agree to these Terms and any further terms and condition that apply specifically with respect to the Platform itself.
<br/><br/>
BY USING THE PLATFORM OR ANY PART OF IT, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS.
<br/><br/>
Any changes to these Terms will be in effect as of the “Last Updated Date” referred to at the top of this page. You should review these Terms before using the Platform or purchasing any product or using any services that are available through the Platform. Your continued use of the Platform after the “Last Updated Date” will constitute your acceptance of and agreement to such changes.
<br/><br/>
By using the Platform, you affirm that you are of legal age to enter into these Terms, and you accept and are bound by these Terms. You affirm that if you are using the Platform on behalf of an organization or company, you have the legal authority to bind any such organization or company to these Terms.
<br/><br/>
You may not use the Platform if you: (i) do not agree to these Terms; (ii) are not of the age of majority in your jurisdiction of residence; or (iii) are prohibited from accessing or using the Platform or any of the Platform’s contents, products or services by applicable law.
                </div>


                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">1.	USE OF THE PLATFORM; ACCOUNT SET-UP AND SECURITY</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
    (i)	Account and Wallet Set-Up. To most easily use the Platform, you should follow the instruction on the Platform’s website. You will also need to use a supported electronic wallet, which will enable you to purchase and store Collectibles that you collect or purchase via the Platform. Each Collectible is a Non-Fungible Token (an “NFT”) on the Moonbeam blockchain network (the “Network”). 
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
    (ii)	Account Registration. You must provide accurate and complete registration information when you create an account for the Platform. By creating an account, you agree to provide accurate, current and complete account information about yourself, and to maintain and promptly update your account information as necessary. We reserve the right reclaim usernames without liability to you.
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
    (iii)	Account Security. You are responsible for the security of your account for the Platform and for your electronic wallets. If you become aware of any unauthorized use of your password or of your account with us, you agree to notify us immediately through our website.
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
    (iv)	Account Transactions. You can use your electronic wallet to purchase, store, and engage in transactions using one or more fiat or cryptocurrencies that we may elect to accept from time to time. Transactions that take place on the Platform are managed and confirmed via the Network. You understand that your Network public address will be made publicly visible whenever you engage in a transaction on the Platform.
                    </div>
                </div>



                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">2.	PURCHASING AND EARNING COLLECTIBLES</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
                    (i)	Acquiring Collectibles. The Platform allows you to purchase, collect, race and showcase digital blockchain racing collectibles (each, a “Collectible”). Each Collectible is an NFT on the Network. 
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(ii)	Purchasing Collectibles. You can purchase Collectibles in two ways: (a) by buying Collectibles on the Platform (a “Pack”); or (b) by buying Collectibles from other users on one or more third party marketplaces (each a “Marketplace”). There are different types of Packs available for purchase on the Platform, and we reserve the right to modify the types, prices and numbers of Packs available at our discretion. Depending on the type of Pack you buy, you will collect Collectibles of varying levels of scarcity. Before you buy a Pack, we may let you know the types of Collectibles (but not the exact Collectibles) that are contained in that Pack. If you buy an individual Collectible from another user in a Marketplace, you will know the exact Collectible that you are purchasing. We strongly encourage you not to purchase Collectibles other than in Packs or on a recognised Marketplace. If you decide to purchase Collectibles in any other way, you understand that such purchases will be entirely at your sole risk. 
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iii)	Earning Collectibles. You may be able earn Collectibles for free by participating in certain challenges or marketing campaigns on the Platform, or by completing certain Platform tasks that we may make generally available from time to time. 
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iv)	Characteristics of Collectibles. Collectibles may be comprised of a photograph of one or more racing collectibles, a video of one or more racing collectibles, and/or a set of statistics that are associated with one or more racing collectibles. Each Collectible has a defined set of attributes – including scarcity – which may determine the value of the Collectible (if any). 
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(v)	Subjectivity of Collectibles. The value of each Collectible (if any) is inherently subjective, in the same way the value of other collectibles is inherently subjective. Each Collectible has no inherent or intrinsic value. Some collectors might prefer to have a Collectible featuring a certain racing collectible, while another might prefer an equivalent Collectible featuring a different racing collectible. Each racing collectible can have more than one Collectible associated with it, and those Collectibles will each have different characteristics.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(vi)	Restrictions on Purchases and Transfers: You may not purchase a Collectible (either from us or from any third party) if you are a Prohibited Person and nor may you transfer a Collectible to any Prohibited Person. A “Prohibited Person” means any such person, as determined by us in our sole and absolute discretion, that is:
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
a.	unable to pass any know-your-client requirements as may be determined by us from time to time in our sole and absolute discretion;
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
b.	a U.S. Person as defined in Rule 902 under the United States Securities Act of 1933 or a U.S. person within the meaning Section 7701(a)(30) of the United States Internal Revenue Code;
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
c.	a member of the public in the British Virgin Islands;
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
d.	a citizen or resident of or located in, or a legal entity formed or incorporated within or subject to the laws of, a Prohibited Jurisdiction  (irrespective of whether use of a virtual private network or other technical workaround to effect such transaction and avoid detection within a Prohibited Jurisdiction);
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
e.	an individual or an individual employed by or associated with a legal entity or a legal entity identified on the United States Department of Commerce’s denied persons or entity list, the United States Department of Treasury’s specially designated nationals or blocked persons lists, the United States Department of State’s debarred parties list, the consolidated sanctions list maintained by the United States Department of Treasury’s Office of Foreign Assets Control any United Nations Security Council sanctions lists or any other sanctions list; 
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
f.	identified as a terrorist organization on any other relevant lists maintained by any governmental authority; 
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
g.	acting, directly or indirectly, in contravention of any applicable law; 
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
h.	in any manner limited or prohibited (or that requires licensing, registration or approval of any kind) from the purchasing, possessing, transferring, using or otherwise conducting a transaction involving any amount of digital assets under applicable law;
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
i.	or has been involved at any time in any type of activity associated with money laundering or terrorist financing or any other applicable anti-corruption or anti bribery statute or has been subject to any investigation or sanction by, or a request for information from, any governmental authority relating to money laundering, terrorist financing, corruption or bribery in any jurisdiction or under any applicable law; or
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
j.	a politically exposed person (“PEP”) as defined by the Financial Action Task Force (or such similar person under any applicable law) as an individual who is or has been entrusted with a prominent public function or an immediate family member or close associate of a PEP or any corporation, business or other entity that has been formed by, or for the benefit of, a PEP or any immediate family member or close associate of a PEP.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(vii)	Showcasing Collectibles. The Platform may allow you to organize your Collectibles into collections, and show them to others. 
                    </div>
                </div>

                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">3.	PAYMENT, GAS FEES, AND TAXES</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
    (i)	Financial Transactions on Platform. Any payments or financial transactions that you engage in via the Platform will be conducted solely through the Network. We have no control over these payments or transactions, nor do we have the ability to reverse any payments or transactions. We have no liability to you or to any third party for any claims or damages that may arise as a result of any payments or transactions that you engage in via the Platform, or any other payment or transactions that you conduct via the Network. We do not provide refunds for any purchases that you might make on or through the Platform – whether for Collectibles, Packs, or anything else.
    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(ii)	Gas Fees. Every transaction on the Network requires the payment of a transaction fee (each, a “Gas Fee”). The Gas Fees fund the network of computers that run the decentralized network upon which the Network operates. This means that you will need to pay a Gas Fee for each transaction that you instigate via the Platform. Except as otherwise expressly set forth in these Terms, you will be solely responsible to pay any Gas Fee for any transaction that you instigate via the Platform.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iii)	Responsibility for Taxes. You will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority (collectively, the “Taxes”) associated with your use of the Platform. Except for income taxes levied on us, you: (a) will pay or reimburse us for all national, federal, state, local or other taxes and assessments of any jurisdiction, including value added taxes and taxes as required by international tax treaties, customs or other import or export taxes, and amounts levied in lieu thereof based on charges set, services performed or payments made hereunder, as are now or hereafter may be imposed under the authority of any national, state, local or any other taxing jurisdiction; and (b) will not be entitled to deduct the amount of any such taxes, duties or assessments from payments (including Gas Fees) made to us pursuant to these Terms. 

                    </div>
                </div>


                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">4.	OWNERSHIP, LICENSE, AND OWNERSHIP RESTRICTIONS</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div style={{marginTop:'10px'}}>
                    YOUR OWNERSHIP OF COLLECTIBLES WILL ONLY BE RECOGNIZED BY US IF YOU HAVE PURCHASED OR OTHERWISE RIGHTFULLY ACQUIRED SUCH COLLECTIBLES FROM A LEGITIMATE SOURCE AND NOT THROUGH ANY OF THE CATEGORY B PROHIBITED ACTIVITIES (AS DEFINED BELOW).
<br/><br/>
For the purposes of this Section 4, the following capitalized terms will have the following meanings: 
<br/><br/>
“Art” means any art, design, and drawings (in any form or media, including, without limitation, video or photographs) that may be associated with a Collectible that you Own.
<br/><br/>
“Own” means, with respect to a Collectible, a Collectible that you have purchased or otherwise rightfully acquired from a legitimate source (and not through any of the Category B Prohibited Activities (as defined below)), where proof of such purchase is recorded on the Network.
<br/><br/>
“Purchased Collectible” means a Collectible that you Own. 
<br/><br/>
“Third Party IP” means any third party patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognized in any country or jurisdiction in the world.
<br/><br/>
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
                    (i)	Ownership of Collectible. Because each Collectible is an NFT on the Network, when you purchase a Collectible in accordance with these Terms (and not through any of the Category B Prohibited Activities), you own the underlying NFT completely. This means that you have the right to swap your Collectible, sell it, or give it away. Ownership of the Collectible is mediated entirely by the Network. Except as otherwise permitted by these Terms in cases where we determine that the Collectible has not been rightfully acquired from a legitimate source (including, without limitation, through any of the Category B Prohibited Activities), at no point will we seize, freeze, or otherwise modify the ownership of any Collectible. 
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(ii)	We Own the Platform. You acknowledge and agree that we (or, as applicable, our licensors) owns all legal right, title and interest in and to all other elements of the Platform, and all intellectual property rights therein (including, without limitation, all Art, designs, systems, methods, information, computer code, software, services, “look and feel”, organization, compilation of the content, code, data, and all other elements of the Platform (collectively, the “Platform Materials”)). You acknowledge that the Platform Materials are protected by copyright, trade dress, patent, and trademark laws, international conventions, other relevant intellectual property and proprietary rights, and applicable laws. All Platform Materials are the copyrighted property of us or our licensors, and all trademarks, service marks, and trade names associated with the Platform or otherwise contained in the Platform Materials are proprietary to us or our licensors.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iii)	No User License or Ownership of Platform Materials. Except as expressly set forth herein, your use of the Platform does not grant you ownership of or any other rights with respect to any content, code, data, or other Platform Materials that you may access on or through the Platform. We reserve all rights in and to the Platform Materials that are not expressly granted to you in these Terms.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iv)	Further User Ownership Acknowledgements. For the sake of clarity, you understand and agree: (a) that your purchase of a Collectible, whether via the Platform or otherwise, does not give you any rights or licenses in or to the Platform Materials (including, without limitation, our copyright in and to the associated Art) other than those expressly contained in these Terms; (b) that you do not have the right, except as otherwise set forth in these Terms, to reproduce, distribute, or otherwise commercialize any elements of the Platform Materials (including, without limitation, any Art) without our prior written consent in each case, which consent we may withhold in our sole and absolute discretion; and (c) that you will not apply for, register, or otherwise use or attempt to use any of our trademarks or service marks, or any confusingly similar marks, anywhere in the world without our prior written consent in each case, which consent we may withhold at our sole and absolute discretion.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(v)	User License to Art. Subject to your continued compliance with these Terms, we grant you a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display the Art for your Purchased Collectibles, solely for the following purposes: (a) for your own personal, non-commercial use; (b) as part of a marketplace that permits the purchase and sale of your Purchased Collectibles, provided that the marketplace cryptographically verifies each Collectible owner’s rights to display the Art for their Purchased Collectible to ensure that only the actual owner can display the Art; or (c) as part of a third party website or application that permits the inclusion, involvement, or participation of your Purchased Collectible, provided that the website/application cryptographically verifies each Collectible’s owner’s rights to display the Art for their Purchased Collectible to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased Collectible leaves the website/application. 
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(vi)	Restrictions on Ownership. You agree that you may not, nor permit any third party to do or attempt to do any of the foregoing without our (or, as applicable, our licensors’) express prior written consent in each case: (a) modify the Art for your Purchased Collectible in any way, including, without limitation, the shapes, designs, drawings, attributes, or color schemes; (b) use the Art for your Purchased Collectible to advertise, market, or sell any third party product or service; (c) use the Art for your Purchased Collectible in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others; (d) use the Art for your Purchased Collectible in movies, videos, or any other forms of media, except to the limited extent that such use is expressly permitted in these Terms or solely for your own personal, non-commercial use; (e) sell, distribute for commercial gain (including, without limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the Art for your Purchased Collectible; (f) attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art for your Purchased Collectible; or (g) otherwise utilize the Art for your Purchased Collectible for your or any third party’s commercial benefit.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(vii)	Third Party IP. If the Art associated with your Purchased Collectible contains Third Party IP (e.g., licensed intellectual property from a third party), you understand and agree as follows: (a) that you will not have the right to use such Third Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained herein; (b) that, depending on the nature of the license granted from the owner of the Third Party IP, we may need to (and reserve every right to) pass through additional restrictions on your ability to use the Art; and (c) to the extent that we inform you of such additional restrictions in writing, you will be responsible for complying with all such restrictions from the date that you receive the notice, and that failure to do so will be deemed a breach of the license contained in this Section 4.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(viii)	Other Terms of License. The license granted in Section 4(v) above applies only to the extent that you continue to Own the applicable Purchased Collectible. If at any time you sell, swap, donate, give away, transfer, or otherwise dispose of your Purchased Collectible for any reason, the license granted in Section 4(v) will immediately expire with respect to that Collectible without the requirement of notice, and you will have no further rights in or to the Art for that Collectible. The restrictions in Sections 4(vii) and 4(viii) will survive the expiration or termination of these Terms.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(ix)	All secondary marketplace transactions are subject to the following provisions:
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
a.	the Collectible transferee (the “Transferee”) shall, by receiving an ownership interest in the Collectible, be deemed to accept all of the provisions of these Terms; 
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
b.	the Collectible transferor (the “Transferor”) shall provide notice to the Transferee of these Terms including a link or other method by which these Terms may be accessible by the Transferee;
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
c.	the Transferor shall directly or indirectly pay us, our affiliate or any entitled third party such percentage of the gross amounts paid by the Transferee as we, our affiliate or any entitled third party may reasonably determine as a Platform user fee (the “User Fee”) regardless of the venue of the sale of the Collectible;
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
d.	the User Fee shall be paid directly or indirectly on the same terms and at the same time as the Transferor is paid by the Transferee; 
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
e.	both the Transferor and the Transferee acknowledge and agree that the amounts payable hereunder as the User Fee do not include, and are not intended to cover, any additional fees imposed or required by the transferring platform or Marketplace through which You transfer the Collectible to the Transferee; and
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
f.	without limitation of any other termination rights, we may suspend or terminate the license granted in Section 4(v) if we have a reasonable basis for believing that You have engaged in an off-chain sale of a Collectible, or otherwise engaged in any off-chain transactions for the purchase or sale of a Collectible without paying the applicable User Fee. You, and all subsequent Transferees, shall be responsible for paying the User Fees associated with the secondary transaction purchase price, regardless of whether such purchase price is fulfilled on-chain, off-chain, or in a combination of the foregoing.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(x)	User Feedback. You may choose to submit comments, bug reports, ideas or other feedback about the Platform, including without limitation about how to improve the Platform (collectively, “Feedback”). By submitting any Feedback, you agree that we are free to use such Feedback at our discretion and without additional compensation to you, and to disclose such Feedback to third parties (whether on a non-confidential basis, or otherwise). You hereby grant us a perpetual, irrevocable, nonexclusive, worldwide license under all rights necessary for us to incorporate and use your Feedback for any purpose.
                    </div>
                </div>


                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">5.	CONDITIONS OF USE AND PROHIBITED ACTIVITIES</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div style={{marginTop:'10px'}}>
                YOU AGREE THAT YOU ARE RESPONSIBLE FOR YOUR OWN CONDUCT WHILE ACCESSING OR USING THE PLATFORM, AND FOR ANY CONSEQUENCES THEREOF. YOU AGREE TO USE THE PLATFORM ONLY FOR PURPOSES THAT ARE LEGAL, PROPER AND IN ACCORDANCE WITH THESE TERMS AND ANY APPLICABLE LAWS OR REGULATIONS OR OTHER RULES THAT MAY APPLY WITH RESPECT TO THE PLATFORM.
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
                    (i)	User Warranties. Without limiting the foregoing, you warrant and agree that your use of the Platform will not (and will not allow any third party to):
                    </div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
(a)	in any manner:
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(1)	involve the sending, uploading, distributing or disseminating any unlawful, defamatory, harassing, abusive, fraudulent, obscene, or otherwise objectionable content;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(2)	involve the distribution of any viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(3)	involve the uploading, posting, transmitting or otherwise making available through the Platform any content that infringes the intellectual proprietary rights of any party;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(4)	involve using the Platform to violate the legal rights (such as rights of privacy and publicity) of others; 
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(5)	involve engaging in, promoting, or encouraging illegal activity (including, without limitation, money laundering);
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(6)	involve interfering with other users’ enjoyment of the Platform;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(7)	involve exploiting the Platform for any unauthorized commercial purpose;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(8)	involve modifying, adapting, translating, or reverse engineering any portion of the Platform;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(9)	involve removing any copyright, trademark or other proprietary rights notices contained in or on the Platform or any part of it;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(10)	involve reformatting or framing any portion of the Platform;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(11)	involve displaying any content on the Platform that contains any hate-related or violent content or contains any other material, products or services that violate or encourage conduct that would violate any criminal laws, any other applicable laws, or any third party rights;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(12)	involve using any spider, site search/retrieval application, or other device to retrieve or index any portion of the Platform or the content posted on the Platform, or to collect information about its users for any unauthorized purpose;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(13)	involve accessing or using the Platform for the purpose of creating a product or service that is competitive with any of our products or services;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(14)	involve abusing, harassing, or threatening another user of the Platform or any of our authorized representatives, customer service personnel, chat board moderators, or volunteers (including, without limitation, filing support tickets with false information, sending excessive emails or support tickets, obstructing our employees from doing their jobs, refusing to follow the instructions of our employees, or publicly disparaging us by implying favoritism by any of our employees or otherwise); or
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(15)	involve using any abusive, defamatory, ethnically or racially offensive, harassing, harmful, hateful, obscene, offensive, sexually explicit, threatening or vulgar language when communicating with another user of the Platform or any of our authorized representatives, customer service personnel, chat board moderators, or volunteers
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
(each, a “Category A Prohibited Activity”); and/or
</div>
                    <div className="row terms-tab-2" style={{marginTop:'10px'}}>
(b)	in any manner:
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(1)	involve creating user accounts by automated means or under false or fraudulent pretenses;
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(2)	involve the impersonation of another person (via the use of an email address or otherwise);
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(3)	involve using, employing, operating, or creating a computer program to simulate the human behavior of a user (“Bots”);
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(4)	involve using, employing, or operating Bots or other similar forms of automation to engage in any activity or transaction on the Platform (including, without limitation, purchases of Packs, or of Collectibles on a Marketplace);
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(5)	involve acquiring Collectibles through inappropriate or illegal means (including, among other things, using a stolen credit card, or a payment mechanism that you do not have the right to use, or purchasing a Collectible and then attempting to charge the cost back to your payment method while still maintaining ownership or control of the Collectible or selling, gifting or trading the Collectible to someone else); or
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(6)	involve the purchasing, selling or facilitating the purchase and sale of any user’s account(s) to other users or third parties for cash or cryptocurrency consideration outside of the Platform; or
</div>
                    <div className="row terms-tab-3" style={{marginTop:'10px'}}>
(7)	otherwise involve or result in the wrongful seizure or receipt of any Collectibles or other digital assets (each, a “Category B Prohibited Activity” and, together with Category A Prohibited Activity, the “Prohibited Activities”).
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(ii)	Effect of Your Breaches. If you engage in any of the Prohibited Activities, we may, at our sole and absolute discretion, without notice or liability to you, and without limiting any of our other rights or remedies at law or in equity, immediately suspend or terminate your user account and/or delete your Collectibles’ images and descriptions from the Platform. If we delete your Collectibles’ images and descriptions from the Platform, such deletion will not affect your ownership rights in any NFTs that you already Own, but you will not receive a refund of any amounts you paid for those Collectibles. 

                    </div>
                    <div style={{marginTop:'10px'}}>
                    NOTWITHSTANDING THE FOREGOING, HOWEVER, IF WE REASONABLY BELIEVE THAT YOU ARE ENGAGED IN ANY OF THE CATEGORY B PROHIBITED ACTIVITIES, IN ADDITION TO OUR RIGHT TO IMMEDIATELY SUSPEND OR TERMINATE YOUR USER ACCOUNT AND/OR DELETE YOUR COLLECTIBLES’ IMAGES AND DESCRIPTIONS FROM THE PLATFORM, WE ALSO RESERVE THE RIGHT, AT OUR SOLE AND ABSOLUTE DISCRETION, WITHOUT NOTICE OR LIABILITY TO YOU, TO TAKE ANY OR ALL OF THE FOLLOWING ACTIONS: (A) TO DEEM ANY TRANSACTION THAT TOOK PLACE VIA OR AS THE RESULT OF SUCH ACTIVITIES TO BE VOID AB INITIO; AND/OR (B) TO IMMEDIATELY CONFISCATE ANY COLLECTIBLES (INCLUDING THEIR UNDERLYING NFTS) THAT WERE PURCHASED OR ACQUIRED AS THE RESULT OF SUCH ACTIVITIES.
                    </div>
                </div>


                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">6.	TERMINATION</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
                    (i)	You Terminate. You may terminate these Terms at any time by canceling your account on the Platform and discontinuing your access to and use of the Platform. If you cancel your account, or otherwise terminate these Terms, you will not receive any refunds for any purchases that you might make on or through the Platform – whether for Collectibles, Packs, or anything else.
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(ii)	We Terminate. You agree that we, in our sole discretion and for any or no reason, may terminate these Terms and suspend and/or terminate your account(s) for the Platform without the provision of prior notice. You agree that any suspension or termination of your access to the Platform may be without prior notice, and that we will not be liable to you or to any third party for any such suspension or termination.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iii)	Other Remedies Available. If we terminate these Terms or suspend or terminate your access to or use of the Platform due to your breach of these Terms or any suspected fraudulent, abusive, or illegal activity (including, without limitation, if you engage in any of the Prohibited Activities), then termination of these Terms will be in addition to any other remedies we may have at law or in equity.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iv)	Referral to Governmental Authority. We have the right, without provision of prior notice, to take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the Platform. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone using the Platform.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(v)	Effect of Termination. Upon any termination or expiration of these Terms, whether by you or us, you may no longer have access to information that you have posted on the Platform or that is related to your account, and you acknowledge that we will have no obligation to maintain any such information in our databases or to forward any such information to you or to any third party. Sections 1 and 3 through 17 will survive the termination or expiration of these Terms for any reason.

                    </div>

                    <div style={{marginTop:'10px'}}>
YOU WAIVE AND HOLD US AND OUR PARENT, SUBSIDIARIES, AFFILIATES AND OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS HARMLESS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY US AND ANY OF THE FOREGOING PARTIES RELATING TO ANY INVESTIGATIONS BY EITHER US OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.
                    </div>
                </div>


                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">7.	DISCLAIMERS</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE PLATFORM IS AT YOUR SOLE RISK, AND THAT THE PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE PLATFORM AND ANY PART OF IT, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR PARENT, SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE PLATFORM WILL MEET YOUR REQUIREMENTS; (II) YOUR ACCESS TO OR USE OF THE PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR; (III) USAGE DATA PROVIDED THROUGH THE PLATFORM WILL BE ACCURATE; (IV) THE PLATFORM OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE PLATFORM ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (V) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE PLATFORM WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
<br/><br/>
YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
<br/><br/>
WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE NETWORK, OR YOUR ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (II) SERVER FAILURE OR DATA LOSS; (III) CORRUPTED WALLET FILES; OR (IV) UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING, BUT NOT LIMITED TO, THE USE OF VIRUSES, PHISHING, BRUTE-FORCING OR OTHER MEANS OF ATTACK AGAINST THE PLATFORM, THE NETWORK, OR ANY ELECTRONIC WALLET.
<br/><br/>
COLLECTIBLES ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE NETWORK. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE DECENTRALIZED LEDGER WITHIN THE NETWORK. WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT TO SMART CONTRACTS.
<br/><br/>
WE ARE NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE NETWORK, OR ANY ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE NETWORK, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.

                </div>

                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">8.	LIMITATION OF LIABILITY</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    YOU UNDERSTAND AND AGREE THAT WE, OUR PARENT, SUBSIDIARIES, AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, DIMINUTION OF VALUE OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    <br/><br/>
YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE PLATFORM, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE AMOUNTS YOU HAVE ACTUALLY AND LAWFULLY PAID US UNDER THESE TERMS IN THE TWO (2) MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE.
<br/><br/>
YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE PLATFORM AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE REPRESENTATIONS AND WARRANTIES, DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN US AND YOU AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US AND YOU. WE WOULD NOT BE ABLE TO PROVIDE THE PLATFORM TO YOU WITHOUT THESE LIMITATIONS.

                </div>

                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">9.	ASSUMPTION OF RISK</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
                    (i)	Value and Volatility. The prices of collectible blockchain assets are extremely volatile and subjective and collectible blockchain assets have no inherent or intrinsic value. Fluctuations in the price of other digital assets could materially and adversely affect the value of your Collectibles, which may also be subject to significant price volatility. Each Collectible has no inherent or intrinsic value. We cannot guarantee that any Collectibles purchased will retain their original value, as the value of collectibles is inherently subjective and factors occurring outside of the Platform’s ecosystem may materially impact the value and desirability of any particular Collectible.
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(ii)	Tax Calculations. You are solely responsible for determining what, if any, taxes apply to your Collectible-related transactions. We are not responsible for determining the taxes that apply to your transactions on the Platform.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iii)	Use of Blockchain. The Platform does not store, send, or receive Collectibles. This is because Collectibles exist only by virtue of the ownership record maintained on the Platform’s supporting blockchain in the Network. Any transfer of Collectibles occurs within the supporting blockchain in the Network, and not on the Platform.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iv)	Inherent Risks with Internet Currency. There are risks associated with using an Internet-based currency, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your electronic wallet. You accept and acknowledge that we will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Network, however caused.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(v)	Regulatory and Legal Uncertainty. The regulatory regime governing blockchain technologies, cryptocurrencies and tokens is uncertain, and new regulations or policies may materially adversely affect the development of the Platform’s ecosystem, and therefore the potential utility or value of your Collectibles. Any Transferee of a Collectible may need to take additional steps to perfect the license granted in Section 4(v) to the original Transferor. The requirements under applicable law may vary from jurisdiction to jurisdiction. We may be willing to assist with such perfection of the license so long as we do not incur any costs and the effort required is not onerous in any way. 
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(vi)	Software Risks. Upgrades to the Network, a hard fork in the Network, or a change in how transactions are confirmed on the Network may have unintended, adverse effects on all blockchains using the Network’s NFT standard, including the Platform’s ecosystem. 

                    </div>
                </div>


                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">10.	INDEMNIFICATION</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div style={{marginTop:'10px'}}>
                    You agree to hold harmless and indemnify us and our parent, subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost and attorneys' fees arising out of or in any way related to: (i) your breach of these Terms; (ii) your misuse of the Platform; or (iii) your violation of applicable laws, rules or regulations in connection with your access to or use of the Platform. You agree that we will have control of the defense or settlement of any such claims.
                    </div>
                </div>

                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">11.	EXTERNAL SITES</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div style={{marginTop:'10px'}}>
                    The Platform may include hyperlinks to other websites or resources (collectively, the “External Sites”), which are provided solely as a convenience to our users. We have no control over any External Sites. You acknowledge and agree that we are not responsible for the availability of any External Sites, and that we do not endorse any advertising, products or other materials on or made available from or through any External Sites. Furthermore, you acknowledge and agree that we are not liable for any loss or damage which may be incurred as a result of the availability or unavailability of the External Sites, or as a result of any reliance placed by you upon the completeness, accuracy or existence of any advertising, products or other materials on, or made available from, any External Sites.
                    </div>
                </div>


                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">12.	FORCE MAJEURE</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
                    (i)	Force Majeure Events. We will not be liable or responsible to you, nor be deemed to have defaulted under or breached these Terms, for any failure or delay in fulfilling or performing any of these Terms, when and to the extent such failure or delay is caused by or results from the following force majeure events (“Force Majeure Event(s)”): (a) acts of God; (b) flood, fire, earthquake, epidemics, pandemics, including the 2019 novel coronavirus pandemic (COVID-19), tsunami, explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (d) government order, law, or action; (e) embargoes or blockades in effect on or after the date hereof; (f) strikes, labour stoppages or slowdowns or other industrial disturbances; (g) shortage of adequate or suitable Internet connectivity, telecommunication breakdown or shortage of adequate power or electricity; and (h) other similar events beyond our control.
</div><div className="row terms-tab-1" style={{marginTop:'10px'}}>
(ii)	Performance During Force Majeure Events. If we suffer a Force Majeure Event, we will use reasonable efforts to promptly notify you of the Force Majeure Event, stating the period of time the occurrence is expected to continue. We will use diligent efforts to end the failure or delay and ensure the effects of such Force Majeure Event are minimized. We will resume the performance of our obligations as soon as reasonably practicable after the removal of the cause. In the event that our failure or delay remains uncured for a period of forty-five (45) consecutive days following written notice given by us under this Section 12, we may thereafter terminate these Terms upon fifteen (15) days' written notice.

                    </div>
                </div>


                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">13.	CHANGES TO THE PLATFORM</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div style={{marginTop:'10px'}}>
                    We are constantly innovating the Platform to help provide the best possible experience. You acknowledge and agree that the form and nature of the Platform, and any part of it, may change from time to time without prior notice to you, and that we may add new features and change any part of the Platform at any time without notice.

                    </div>
                </div>

                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">14.	CHILDREN</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div style={{marginTop:'10px'}}>
                    You affirm that you are over the age of 18. The Platform is not intended for children under 18. If you are under the age of 18, you may not use the Platform. We do not knowingly collect information from or direct any of our content specifically to children under the age of 18. If we learn or have reason to suspect that you are a user who is under the age of 18, we will unfortunately have to close your account. Other countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not use the Platform. 

                    </div>
                </div>

                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">15.	PRIVACY POLICY</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div style={{marginTop:'10px'}}>
                    Our Privacy Policy describes the ways we collect, use, store and disclose your personal information, and is hereby incorporated by this reference into these Terms. You agree to the collection, use, storage, and disclosure of your data in accordance with our Privacy Policy.

                    </div>
                </div>


                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">16.	GOVERNING LAW AND JURISDICTION</span>
                </div>
                <div className="col s12" style={{marginTop:'10px'}}>
                    <div style={{marginTop:'10px'}}>
                    All rights and obligations hereunder in connection with the Collectibles and the Platform shall be governed by the Laws of the British Virgin Islands, without regard to the conflicts of law provisions of such jurisdiction. The parties submit to the non-exclusive jurisdiction of the courts of the British Virgin Islands and any courts competent to hear appeals from those courts.

                    </div>
                </div>

                <div className="col s12" style={{marginTop:'40px'}}>
                    <span className="headline-text">17.	GENERAL</span>
                </div>
                <div className="col s12" style={{marginTop:'10px', marginBottom:'100px'}}>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
                    (i)	Entire Agreement. These Terms and our Privacy Policy constitute the entire legal agreement between you and us and will be deemed to be the final and integrated agreement between you and us, and govern your access to and use of the Platform, and completely replace any prior or contemporaneous agreements between you and us related to your access to or use of the Platform, whether oral or written.
                    </div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(ii)	Third-Party Beneficiaries. You hereby acknowledge and agree that for the purposes of applicable law each indemnified person not a party to these Terms is an intended third-party beneficiary under these Terms (and we shall hold the benefit of such provisions on trust for each such indemnified persons). However, the parties to these Terms may rescind or vary these Terms (including, without limitation, any variation so as to extinguish or alter a third party's entitlement to enforce any provisions of these Terms) without the consent of any such third party.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iii)	Interpretation. The language in these Terms will be interpreted as to its fair meaning, and not strictly for or against any party.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(iv)	Severability. Should any part of these Terms be held invalid, illegal, void or unenforceable, that portion will deemed severed from these Terms and will not affect the validity or enforceability of the remaining provisions of these Terms.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(v)	No Waivers. Our failure or delay to exercise or enforce any right or provision of these Terms will not constitute or be deemed a waiver of future exercise or enforcement of such right or provision. The waiver of any right or provision of these Terms will be effective only if in writing and signed for and on behalf of us by a duly authorized representative.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(vi)	Notices. We may provide you with any notices (including, without limitation those regarding changes to these Terms) by email or postings on the Platform. By providing us with your email address, you consent to our using the email address to send you any notices. Notices sent by email will be effective when we send the email, and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(vii)	Assignment. You may not assign any of your rights or obligations under these Terms, whether by operation of law or otherwise, without our prior written consent. We may assign our rights and obligations under these Terms in our sole discretion to an affiliate, or in connection with an acquisition, sale or merger.
</div>
                    <div className="row terms-tab-1" style={{marginTop:'10px'}}>
(viii)	We may change, modify, amend, alter or supplement these Terms (each an “Amendment”) at any time in order to reflect (i) changes to applicable law that may be, or which may otherwise become, applicable to the Platform or the Collectibles, (ii) any developments that may otherwise reasonably be capable of materially adversely impacting the Platform or the Collectibles; or (iii) as we may in good faith deem advisable to protect our reputation or the effective operation of the Platform and/or the Collectibles.


                    </div>
                    <div style={{marginTop:'10px'}}>
                    Your continued use of the Platform and/or the Collectibles after any such Amendment shall constitute your consent to such Amendment and acceptance of the amended Terms. If we change, amend, modify, alter or supplement these Terms we shall publish on our website such amended version of these Terms reflecting such Amendment. The revised Terms will be effective from the date of posting on our website or such other date as indicated in the amended Terms. You waive any right you may have to receive specific notice of such Amendment. If you do not agree to the Terms in effect when you access or use the Platform and/or the Collectibles, you must stop using the Platform and the Collectibles.
                    </div>
                </div>
                </div>
            </div>
        </div>);
};
