import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "../../../RacesHome/races_home.css";
import $ from "jquery";

export const PubRaceController = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    const [entrySet, setEntry] = useState(false);

    if(entrySet == false) {
        setEntry(true);
    }

    function generateNewRace() {
        props.newRaceButton();
    }

    function simulateRaceResults() {
        props.simRaceButton();
    }

    return(<div className="row " id="left-side-viewer-shell" style={{margin:'0px',position:'relative'}}>
            <div className="row exr-dark-grey-bg" style={{margin:'0px',padding:'20px 30px 7px 25px',marginBottom:'0px',padding:'10px',borderRadius:'10px',height:'15vh',position:'relative',overflow:'hidden'}}>
                <div className="col s12 m6" style={{margin:'0px',marginBottom:'10px',lineHeight:'1',fontSize:'14px',marginTop:'2.25vh'}}>
                    <div id="start-new-race-button" className="left center-align" style={{display:'none',padding:'2.5px'}}>
                        <div className="grey-light-grey-button-link center-align" style={{borderRadius:'10px',padding:'2.75vh'}} onClick={()=>generateNewRace()}>
                            CREATE NEW RACE
                        </div>
                    </div> 
                    <div id="simulate-new-race-button" className="left center-align" style={{display:'none',padding:'2.5px',marginLeft:'15px'}}>
                        <div className="grey-light-grey-button-link center-align" style={{borderRadius:'10px',padding:'2.75vh'}} onClick={()=>simulateRaceResults()}>
                            SIMULATE RACE
                        </div>
                    </div>
                    <div className="row left-align headline-text exr-yellow-text" style={{paddingLeft:'20px',fontSize:'14px',lineHeight:'1',marginBottom:'5px',marginTop:'-5px'}}>
                        NEXT PREDICTIONS IN
                    </div>
                    <div id="countdown-timer-pred" className="row left-align" style={{paddingLeft:'40px',fontSize:'30px',lineHeight:'1',marginTop:'12px',marginBottom:'0px'}}>
                        0h 0m 0s
                    </div>
                </div>
                
                <div className="col s12 m6" style={{margin:'0px',padding:'0px',marginBottom:'10px',lineHeight:'1',paddingRight:'15vh',marginTop:'3.75vh'}}>
                    <div className="row right-align headline-text hide-on-med-and-down show-on-large" style={{fontSize:'14px',lineHeight:'1',marginTop:'-5px',marginBottom:'5px',marginRight:'20px'}}>
                        START PLAYING AT
                    </div>
                    <div className="row right-align" style={{fontSize:'18px',lineHeight:'1',marginTop:'2px',marginBottom:'0px',marginRight:'20px'}}>
                        PICKEM.EXILEDRACERS.COM
                    </div>
                </div>
                <img src="css/images/qrcode_pickem.png" style={{height:'15vh',position:'absolute',top:'0px',right:'0px'}} />
            </div>
    </div>);
};     