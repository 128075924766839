import * as THREE from "three";

export class Effect {
    #group = new THREE.Group();

    /**
     * Get the group
     * @returns {THREE.Group} The group
     */
    get group() {
        return this.#group;
    }

    /**
     * Update the effect
     * @returns {boolean} True if the effect should be deleted
     */
    update() {
        return true;
    }

    /**
     * Render
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {

    }
}