import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";

import { updateLocation } from "../../../redux/App/actions";
import $ from "jquery";

export const LoginLoading = (props) => {
  const dispatch = useDispatch();
  const { page_loaded, signup_loading }= useSelector((state) => state.app);
  const { racerID, accountAddress } = useSelector((state) => state.account);
  const { chainId, account } = useWeb3React();

  const [pageSet, setPage] = useState(false);

  let bg_image = 'https://media.exiledracers.com/assets/game/builder/Splash_Background_-_Serf_Centered_1_1665676555319.jpeg';
  let logo_image = 'https://media.exiledracers.com/global/exr_logo_white_stacked.png';

  if(pageSet == false) {
      setPage(true);
  }

  return(<div id="signuploadingscreen" style={{position:'fixed',width:'100%',height:'100vh',zIndex:'100',top:'0px',left:'0px',display:'none'}}>
            <div id="splash-image-container" style={{width:'100%',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-10',background:'url('+bg_image+')',backgroundSize:'cover',backgroundPosition:'center'}}>
            </div>
            <div className="col s12 transparent" style={{top:'5vh',left:'0px',position:'relative',zIndex:'100'}}>
                <div className="row center-align" style={{marginBottom:'10px'}}>
                    <div className="col s12 m10 offset-m1 l4 offset-l4">
                        <img id="exr-logo-button" style={{cursor:'pointer',marginLeft:'-20px',marginTop:'3vh',minWidth:'250px'}} title="Exiled Racers" width="50%" src={logo_image} />
                    </div>
                </div>
                <div className="row white-text" style={{position:'relative',display:'none'}}>
                    <div id="signuppage10000" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'10px'}}>EXR TRAINING TIPS</div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Choose 
                        </div>
                    </div>
                </div>
                <div className="row white-text" id="sim-progress-bar" style={{display:'none'}}>
                    <div className="col s10 offset-s1 m6 offset-m3">
                        <div className="progress exr-light-grey-bg" style={{height:'10px'}}>
                            <div className="determinate exr-red-bg" id="sim-bar-inner" style={{width:'5%'}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};
