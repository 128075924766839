import {Pointer} from "./pointer.js";

/**
 * Pointers hovering above the racecrafts
 */
export class Pointers {
    static CLASS_VISIBLE = "visible";
    static POINTER_COUNTDOWN = 3;
    static AVOID_THRESHOLD = 2;
    static POINTER_TRIES = 2;

    /**
     * Construct the pointers
     * @param {HTMLElement} element The element
     * @param {Racers} racers The racers
     */
    constructor(element, racers) {
        this.element = element;
        this.pointers = [];
        this.visible = false;
        this.racers = racers;
        this.pointerCountdown = 1;

        let index = 0;

        for (const racer of racers.racers) {
            const pointer = new Pointer(racer, index++, racers.racers.length > Pointers.AVOID_THRESHOLD);

            this.pointers.push(pointer);

            element.appendChild(pointer.element);
        }

        this.setVisible(true);
    }

    /**
     * Set visibility of pointers
     * @param {boolean} visible True if visible, false if not
     */
    setVisible(visible) {
        if (this.visible = visible)
            this.element.classList.add(Pointers.CLASS_VISIBLE);
        else
            this.element.classList.remove(Pointers.CLASS_VISIBLE);
    }

    /**
     * Update the state
     * @param {number} width The screen width in pixels
     * @param {number} height The screen height in pixels
     * @param {number} delta The time delta
     */
    update(width, height, delta) {
        const pointerCount = this.pointers.length;

        if (pointerCount > Pointers.AVOID_THRESHOLD && !--this.pointerCountdown) {
            const order = this.racers.order();

            for (let pointer = 0; pointer < pointerCount; ++pointer) {
                this.pointers[pointer].update(delta);
                this.pointers[pointer].first = this.pointers[pointer].index === order[0];
                this.pointers[pointer].leadDistance = Pointer.LEAD_DISTANCE;
                this.pointers[pointer].tries = Pointers.POINTER_TRIES;
                this.pointers[pointer].calculateScreenPosition(width, height);
            }

            const overlapping = this.pointers.slice().sort((a, b) => b.position.y - a.position.y);

            while (overlapping.length) {
                const pointer = overlapping.pop();

                if (pointer.hidden)
                    continue;

                for (let other = 0, otherCount = overlapping.length; other < otherCount; ++other) {
                    if (!overlapping[other].hidden && pointer.overlaps(overlapping[other])) {
                        pointer.moveUp(width, height, pointer.delta(overlapping[other]));

                        if (pointer.tries--)
                            overlapping.unshift(pointer);

                        break;
                    }
                }
            }

            this.pointers.sort((a, b) => a.position.y - b.position.y);

            this.pointerCountdown = Pointers.POINTER_COUNTDOWN;
        }
    }

    /**
     * Update before rendering
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {Camera} camera The camera
     * @param {number} width The screen width in pixels
     * @param {number} height The screen height in pixels
     */
    render(time, camera, width, height) {
        if (this.visible)
            for (let pointer = 0, pointerCount = this.pointers.length; pointer < pointerCount; ++pointer)
                this.pointers[pointer].render(
                    ((Pointers.POINTER_COUNTDOWN - this.pointerCountdown) + time) / Pointers.POINTER_COUNTDOWN,
                    camera,
                    width,
                    height,
                    pointer);
    }

    /**
     * Clear all pointers
     */
    clear() {
        for (const pointer of this.pointers)
            this.element.removeChild(pointer.element);
    }
}