import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import "../../races_home.css";
import $ from "jquery";

let total_page_results = 25;
let current_pagenum = 0;
let sectionHTMLArray = [];

export const PastRacesPage = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [sectionHTML, setSectionHTML] = useState('');
    const [showMoreHTML, setShowMoreHTML] = useState('');

    var racer_items = [];
    function grabRacerRaces(pagenum) {
        current_pagenum = pagenum;
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.raceStatus = 'past';
        addData.page = pagenum;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('member/races_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;
                loadRacerRaces(racer_details,pagenum);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function startBlankRace() {
        window.location.href="/#/race/invite";
    }

    if(entrySet == false) {
        setEntry(true);
        grabRacerRaces(0);
    }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function loadRacerRaces(racearr,pagenum) {
        var past_section_html = [];
        for(var z = 0; z < racearr.length;z++) {
            var race_id = racearr[z].race_id;
            var race_unique_id = racearr[z].race_unique_id;
            var race_name = racearr[z].race_name;
            var final_race_name = race_name+' #'+race_id;
            var race_coins_earned = racearr[z].race_final_coins - racearr[z].race_entry_fee;
            var race_xp_earned = racearr[z].race_final_xp;
            var race_standing = racearr[z].race_final_position;
            var racer_sim_watched = racearr[z].racer_sim_watched;
            var invited_by = racearr[z].invited_by_racer_name;
            var racer_names = racearr[z].racer_names;

            var open_invite_race = racearr[z].open_invite_race;
            var entry_window_closed = racearr[z].entry_window_over;
            var date_entry_close = racearr[z].date_race_entry_close_eastern;
            var date_predictions_close = racearr[z].date_race_predictions_close_eastern;
            var date_simulation_close = racearr[z].date_race_simulated_close_eastern;

            if(open_invite_race == 1) {
                final_race_name = 'Open '+final_race_name;
            }

            var statuscolor = 'white black-text';
            if(race_standing == 1) {
                statuscolor = 'exr-red-bg white-text';
            } 
            if(race_standing != null) {
                race_standing = ordinal_suffix_of(race_standing).toUpperCase()+' PLACE';
            } else {
                race_standing = 'DNP';
            }

            var days_ended = racearr[z].days_since_race_ended;
            var race_date = '';
            if(days_ended == 0) {
                race_date = "Today";
            } else if (days_ended == 1) {
                race_date = "Yesterday";
            } else {
                race_date = days_ended+" Days Ago";
            }


            /* Calculate Race Time */
            var racer_final_seconds = racearr[z].race_final_time;
            if(racer_final_seconds == null) {
                var finaltimetxt = '00:00:00';
            } else {
                racer_final_seconds = racer_final_seconds .toString();
                var millisplit = racer_final_seconds.split(".");
                if(millisplit.length == 1) {
                    var millicount = '00';
                    var totalseconds = parseInt(millisplit[0]);
                } else {
                    var millicount = millisplit[1];
                    var totalseconds = parseInt(millisplit[0]);
                }
                if(millicount.length == 1) {
                    millicount = millicount+'0';
                }
                var totalmins = Math.floor(totalseconds / 60);
                var finalseconds = totalseconds - (totalmins*60);
                var finaltimetxt = '';
                if(totalmins < 10) {
                    finaltimetxt = finaltimetxt+'0';
                }
                finaltimetxt = finaltimetxt+totalmins+':';
                if(finalseconds < 10) {
                    finaltimetxt = finaltimetxt+'0';
                }
                finaltimetxt = finaltimetxt+finalseconds+':';
                
                finaltimetxt = finaltimetxt+millicount;
            }

            var person_icon = 'person';
            var invited_by_text = '';
            var total_invites = 0;
            for(var x = 0; x < racer_names.length;x++) {
                if(racer_names[x].toUpperCase() != $("#current-racer-handle").val().toUpperCase()) {
                    if(total_invites > 0) {
                        invited_by_text = invited_by_text+","; 
                    }
                    total_invites = total_invites + 1;
                    if(total_invites < 3) {
                        invited_by_text = invited_by_text+" "+racer_names[x];
                    } else if((total_invites == 3)&&(racer_names.length <= 3)) {
                        invited_by_text = invited_by_text+" "+racer_names[x];
                    } else if (total_invites == 3) {
                        var remaining_people = racer_names.length-3;
                        invited_by_text = invited_by_text+" +"+remaining_people+' More';
                        x = racer_names.length;
                    }
                }
            }

            if(total_invites == 0) {
                invited_by_text = 'Time Trial';
            }
            
            var rowkey = z+(pagenum*total_page_results*100);
            var nfticondisplay = 'none';
            var tmp_html = <div key={rowkey} className="row race-board-row" id={'race_row_'+race_id}>
                            <a data-raceid={race_unique_id} onClick={(e)=>switchToRace(e)} className="topnavtoggle">
                                <div className="col s12 l3">
                                    <span className="left topnavtoggle">
                                        {final_race_name.toUpperCase()}
                                    </span>
                                    <span className="left"><i className="material-icons topnavtoggle race-board-row-launch-icon">launch</i></span>
                                    <div className="right right-align show-on-med-and-down hide-on-large-only" style={{marginRight:'0px',marginBottom:'10px'}}>
                                        <span className={"new badge left "+statuscolor} style={{fontWeight:'bold'}} data-badge-caption={race_standing}></span>
                                    </div>
                                    <div className="right right-align show-on-med-and-down hide-on-large-only" style={{minWidth:'85px',marginRight:'15px'}}>
                                        {race_date}
                                    </div>
                                </div>
                                <div className="col s12 l9 right-align">
                                    <div className="left left-align hide-on-med-and-down show-on-large-only" style={{minWidth:'85px',marginRight:'15px'}}>
                                        {race_date}
                                    </div>
                                    <div className="left left-align hide-on-med-and-down show-on-large-only" style={{minWidth:'95px',marginRight:'15px'}}>
                                        <span className={"new badge left "+statuscolor} style={{fontWeight:'bold'}} data-badge-caption={race_standing}></span>
                                    </div>
                                    <div className="left">
                                        <span className="left left-align">
                                            <i className="material-icons grey-text" style={{marginTop:'-3px',marginRight:'5px'}}>{person_icon}</i>
                                        </span>
                                        <span className="left left-align">{invited_by_text}</span>
                                    </div>
                                    <div className="right">
                                        <span className="race-board-prize-text" title="XP Earned">{race_xp_earned}</span>
                                        <span className="race-board-prize-icon" title="XP Earned">
                                            <img src="https://media.exiledracers.com/global/icons/exr_xp_icon.png" height="25px" />
                                        </span>
                                        <span className="race-board-prize-text" style={{marginLeft:'5px'}} title="RPM Awarded">{race_coins_earned}</span>
                                        <span className="race-board-prize-icon" title="RPM Awarded">
                                            <img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="25px" />
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>;
            past_section_html.push(tmp_html);
        }

        /* Add No Past Races */
        if((past_section_html.length == 0)&&(pagenum == 0)) {
            var tmp_html = <div className="col s12" style={{marginBottom:'5px'}}>
                                NO PAST RACES
                            </div>;
            past_section_html.push(tmp_html);
        }
        
        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = sectionHTMLArray.concat(past_section_html);
        } else {
            final_section_html_array = past_section_html;
        }
        sectionHTMLArray = final_section_html_array;
        setSectionHTML(final_section_html_array);

        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(past_section_html.length == total_page_results) {
            
            showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                <div id="showmorebutton" className="center-align" title="Show More Race Results">
                                    <span className="round-border-button" style={{fontSize:'20px'}} title="Show More Race Results"  onClick={() => showMoreResults(nextpage) }>
                                        SHOW MORE RACES
                                    </span>
                                </div>
                                <div id="showmoreloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreHTML(showmorebutton);

        setTimeout(function(){
            props.sizeAdjust();
            $("#showmorebutton").show();
            $("#showmoreloading").hide();
        },500);
        setTimeout(function(){
            props.sizeAdjust();
            $("#showmorebutton").show();
            $("#showmoreloading").hide();
        },2000);
    }

    function showMoreResults(pagenum) {
        $("#showmorebutton").hide();
        $("#showmoreloading").show();
        grabRacerRaces(pagenum);
    }

    function switchToRace(e) {
        e.preventDefault();
        var race_hash = '';
        let race_elem = e.target;
        while(race_hash == '') {
            if(race_elem.dataset.raceid) {
                race_hash = race_elem.dataset.raceid;
            } else {
                race_elem = race_elem.parentElement;
            }
        }
        window.location.href= '/#/race/'+race_hash;
        dispatch(updateLocation("race_landing"));
    }

    return(<>
                <div className="col s12 headline-text" style={{fontSize:"30px"}}>
                    PAST RACES
                </div>
                <div className="col s12" style={{marginBottom:"20px"}}>
                    <div className="divider-thin"></div>
                </div>
                <div id="race_list" className="col s12">
                    {sectionHTML}
                </div>
                <div className="col s12">
                    {showMoreHTML}
                </div>
            </>);
};