import * as THREE from "three";

/**
 * A track surface plane
 */
export class TrackSurface {
    /**
     * Make normals for a set of points
     * @param {Vector3[]} points The points
     * @returns {Vector3[]} The normals for these points
     */
    static makeNormals(points) {
        const normals = [];
        const edgeNormals = [];

        for (let point = 0, pointCount = points.length - 1; point < pointCount; ++point) {
            const edge = points[point + 1].clone().sub(points[point]).normalize();
            const tangent = new THREE.Vector3(-edge.z,0, edge.x);

            edgeNormals.push(tangent.clone().cross(edge));
        }

        for (let point = 0, pointCount = points.length; point < pointCount; ++point) {
            if (point === 0)
                normals.push(edgeNormals[0]);
            else if (point === pointCount - 1)
                normals.push(edgeNormals[edgeNormals.length - 1]);
            else
                normals.push(edgeNormals[point - 1].clone().add(edgeNormals[point]).normalize());
        }

        return normals;
    }

    /**
     * Construct a track surface cross-section
     * @param {Vector3[]} points The points making up the surface
     */
    constructor(points) {
        this.points = points;
        this.normals = TrackSurface.makeNormals(points);
    }

    /**
     * Get the vertex count for this surface
     * @returns {number} The vertex count
     */
    get vertexCount() {
        return this.points.length;
    }

    /**
     * Get the length of this surface
     * @returns {number} The length of this surface
     */
    get length() {
        let length = 0;

        for (let point = 1, pointCount = this.points.length; point < pointCount; ++point)
            length += this.points[point].distanceTo(this.points[point - 1]);

        return length;
    }
}