/**
 * A turbulence component
 */
export class RacerTurbulenceComponent {
    /**
     * Apply turbulence
     * @param {Vector3} vector A vector to apply turbulence to
     * @param {Quaternion} rotation A rotation to apply rotation to
     * @param {number} moved The amount the ship has moved
     */
    apply(vector, rotation, moved) {

    }
}