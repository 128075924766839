import { MintRacer } from "../../Organisms";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";

import { RacerNft } from "../../Organisms";
export const Mint = () => {
  const { mintIds } = useSelector((state) => state.web3);

  return (
    <div className="page stacked border-green">
      Mint
      <MintRacer />
      <div>
        <div>Owned Tokens</div>
        <div>
          {mintIds.map((id) => (
            <RacerNft tokenId={id} key={id} />
          ))}
        </div>
      </div>
    </div>
  );
};
