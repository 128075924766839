import {Random} from "../math/random.js";

export class AudioShuffler {
    static #SHUFFLER = new Random();

    #order = [];
    #index;

    /**
     * Create a shuffler
     * @param {number} variations The number of variations
     */
    constructor(variations) {
        for (let variation = 0; variation < variations; ++variation)
            this.#order.push(variation);

        this.#index = variations - 1;
    }

    /**
     * Get a shuffled index
     * @returns {number} The index
     */
    get index() {
        if (this.#index === this.#order.length) {
            const lastIndex = this.#order[this.#index - 1];

            this.#index = 0;

            AudioShuffler.#SHUFFLER.shuffle(this.#order);

            if (this.#order[0] === lastIndex) {
                const t = this.#order[0];

                this.#order[0] = this.#order[this.#order.length - 1];
                this.#order[this.#order.length - 1] = t;
            }
        }

        return this.#order[this.#index++];
    }
}