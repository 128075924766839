import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import "../../race_landing.css";
import {genetic_tooltip, genetic_max_tooltip, genetic_track_tooltip, genetic_tooltip_wtrack, genetic_max_tooltip_wtrack} from "../../race_landing_tooltips";
import $ from "jquery";

let predictDisplay = 'none';

export const LineupViewSection = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    const [entrySet, setEntry] = useState(false);
    const [inviteSet, setInvite] = useState(false);
    const [current_racer_remove, setRacerRemove] = useState(0);
    const [race_results_html, setResultsHTML] = useState('');
    const [current_racer_html,setCurrentRacerHTML] = useState('');
    const [lineupheadertext, setLineupHeader] = useState('');
    const [lineuprightheadertext, setLineupRightHeader] = useState('');
    const [simulate_button_html, setSimulateButtonHTML] = useState('');
    const [predictbannertext, setPredictionsBanner] = useState('');
    const [favorite_prediction_html, setFavoritePredictionHTML] = useState('');
    const [selected_prediction_html, setSelectedPredictionHTML] = useState('');
    const [close_prediction_html, setClosePredictionHTML] = useState('');
    // const [predictDisplay, setPredictDisplay] = useState('none');
    const [current_predicted_racer_name,setCurrentPredictedRacerName] = useState('None Selected');
    const [current_predicted_totals, setCurrentPredictedTotals] = useState(0);
    const [current_total_racers, setCurrentTotalRacers] = useState(0);

    let [racerList,setRacerList] = useState(props.racerList);
    let [inviteList,setInviteList] = useState(props.inviteList);

    const race_creator_types = ['training','bot-race','daily','alpha','invite'];

    let predict_bg_image = 'https://media.exiledracers.com/assets/banners/EXR_PLANET_01.jpg';

    let items_checked = 'N';
    var racer_items = [];
    let predicted_racer_id = 0;
    let invitechipsloaded = 0;
    let refreshtimeron = 0;
    let predictions_closed = 0;
    let league_admin = 0;

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    useEffect(() => {
        if(entrySet == true) {
            setRacerList(props.racerList);
            setInviteList(props.inviteList);
            updateLineupResults();
        }
    }, [props.inviteList, props.racerList]);

    function showRaceLineup() {

        createPageResults(racerList,inviteList);
        setGlobalButtons();
    }

    function setGlobalButtons() {
        var botrace = 0;
        if((props.raceDetails.race_name == 'Alpha Races')||(props.raceDetails.race_name == 'Bot Race')||(props.raceDetails.race_name == 'Training Race')) {
            botrace = 1;
        }

        var league_admin_arr = props.raceDetails.league_admins.split(",");
        for(var m = 0; m < league_admin_arr.length;m++) {
            if ((league_admin_arr[m] > 0)&&(league_admin_arr[m] == userID)) {
                league_admin = 1;
            }
        }

        if((props.raceDetails.creator_id == racerID)&&
            (props.racerList.length < 10)&&
            (props.racerList.length > 1)&&
            (props.raceDetails.race_simulated == 0)&&
            (predictions_closed == 0)) {
            var tmp_invite_button = <>
                    <span className="round-border-button modal-trigger" data-target="invitebox" style={{fontSize:'14px'}} title="Invite Racers To Race"  onClick={() => props.startInviteButton }>
                        + INVITE RACERS
                    </span>
                    <span className="round-border-button modal-trigger" data-target="closepredictionbox" style={{marginLeft:'10px',fontSize:'14px'}} title="Close Race Predictions"  onClick={() => closePredictionsBox() }>
                        CLOSE PREDICTIONS
                    </span></>;
            if(botrace == 1) {
                tmp_invite_button = <span className="round-border-button" style={{fontSize:'16px'}} title="Simulate Race"  onClick={() => startRaceSimulator() }>
                                        SIMULATE RACE
                                    </span>;
            }
            setSimulateButtonHTML(tmp_invite_button);
        } else if((props.raceDetails.race_simulated == 0)&&
            (props.racerStatus == 1)&&
            (props.racerList.length > 1)&&
            (racerList.length == props.raceDetails.race_total_racers)&&
            (props.raceDetails.open_invite_race == 0)) {
            var tmp_simulate_button = <span className="round-border-button" style={{fontSize:'16px'}} title="Simulate Race"  onClick={() => startRaceSimulator() }>
                                        SIMULATE RACE
                                    </span>;
            if(predictions_closed == 0) {
                tmp_simulate_button = <>
                                <span className="round-border-button modal-trigger" data-target="closepredictionbox" style={{marginLeft:'10px',fontSize:'14px'}} title="Close Race Predictions"  onClick={() => closePredictionsBox() }>
                                    CLOSE PREDICTIONS
                                </span></>;
            }
            setSimulateButtonHTML(tmp_simulate_button);
        } else if((props.raceDetails.race_simulated == 1)&&((props.raceDetails.admin_gated == 0)||(racerID == 1)||(racerID == 3)||(racerID == 5)||(league_admin == 1))) {
            var tmp_simulate_button = <><span className="round-border-button" style={{fontSize:'16px'}} title="Watch Race"  onClick={() => watchInitialRace() }>
                WATCH RACE
            </span></>;
            if(props.raceDetails.admin_gated == 1) {
                tmp_simulate_button = <><span className="left round-border-button" style={{fontSize:'16px'}} title="Watch Race"  onClick={() => watchInitialRace() }>
                    WATCH RACE
                </span>
                <span id="unlock-results-button" className="left round-border-button" style={{marginLeft:'10px',fontSize:'16px'}} title="Unlock Race Results"  onClick={() => unlockRaceResults() }>
                    UNLOCK RESULTS
                </span></>;
            }

            setSimulateButtonHTML(tmp_simulate_button);
        } else if((props.raceDetails.creator_id == racerID)&&(props.raceDetails.race_total_racers < 10)&&(predictions_closed == 0)&&(botrace == 0)) {
            var tmp_invite_button = <span className="round-border-button modal-trigger" data-target="invitebox" style={{fontSize:'16px'}} title="Invite Racers To Race"  onClick={() => props.startInviteButton }>
                + INVITE RACERS
            </span>;
            setSimulateButtonHTML(tmp_invite_button);
        } else if((props.raceDetails.race_simulated == 0)&&
                (props.raceDetails.entry_window_over == 0)&&
                (props.raceDetails.open_invite_race == 1)) {
                    var tmp_invite_button = 'Race Simulating at 2pm';
                // setSimulateButtonHTML(tmp_invite_button);
        }


        var date_entry_close = props.raceDetails.date_race_entry_close_eastern;
        var entry_close_time = new Date(date_entry_close);
        var entry_min = entry_close_time.getMinutes();
        var entry_hour = entry_close_time.getHours();
        var entry_month =  entry_close_time.toLocaleString('default', { month: 'short' });
        var entry_date =  entry_close_time.getDate();
        var timedirection = 'PM';
        if (entry_hour < 12) {
            timedirection = 'AM';
        } else if (entry_hour > 12) {
            entry_hour = entry_hour - 12;
        }
        if (entry_hour == 0) {
            entry_hour = 12;
        }
        var entry_close_time_text = '';
        entry_close_time_text = entry_hour;
        if(entry_min > 10) {
            entry_close_time_text = entry_close_time_text + ':'+entry_min+' ';
        } else if(entry_min > 0) {
            entry_close_time_text = entry_close_time_text + ':0'+entry_min+' ';
        }
        entry_close_time_text = entry_month+' '+entry_date+' @ '+entry_close_time_text + timedirection+' EST';


        var date_predictions_open = props.raceDetails.date_race_predictions_start_eastern;
        var prediction_start_time = new Date(date_predictions_open);
        var pstart_min = prediction_start_time.getMinutes();
        var pstart_hour = prediction_start_time.getHours();
        var pstart_month =  prediction_start_time.toLocaleString('default', { month: 'short' });
        var pstart_date =  prediction_start_time.getDate();
        var timedirection = 'PM';
        if (pstart_hour < 12) {
            timedirection = 'AM';
        } else if (pstart_hour > 12) {
            pstart_hour = pstart_hour - 12;
        }
        if (pstart_hour == 0) {
            pstart_hour = 12;
        }
        var prediction_start_time_text = '';
        prediction_start_time_text = pstart_hour;
        if(pstart_min > 10) {
            prediction_start_time_text = prediction_start_time_text + ':'+pstart_min+' ';
        } else if(pstart_min > 0) {
            prediction_start_time_text = prediction_start_time_text + ':0'+pstart_min+' ';
        }
        prediction_start_time_text = pstart_month+' '+pstart_date+' @ '+prediction_start_time_text + timedirection+' EST';


        var lineuptext = 'RACE LINEUP';
        var predictionstext = '';
        if ((props.raceDetails.open_invite_race == 1)&&(props.raceDetails.entry_window_over == 0)&&(props.racerStatus == 0)) {
            lineuptext = 'RACERS ENTERED';
        } else if ((props.raceDetails.league_season_id > 0)&&(props.raceDetails.entry_window_over == 0)&&(props.racerStatus == 1)) {
            lineuptext = <><a className="exr-light-green-text">NEXT:</a> ENTER STACKS</>
            predictionstext = <><span style={{float:'right',marginTop:'5px'}}><a className="exr-light-green-text">Predictions:</a> Open {prediction_start_time_text}</span></>
        } else if ((props.raceDetails.open_invite_race == 1)&&(props.raceDetails.entry_window_over == 0)&&(props.racerStatus == 1)) {
            lineuptext = <><a className="exr-light-green-text">NEXT:</a> WAITING ON RACERS</>
            predictionstext = <><span style={{float:'right',marginTop:'5px'}}><a className="exr-light-green-text">Predictions:</a> Open {entry_close_time_text}</span></>
        } else if ((props.raceDetails.open_invite_race == 1)&&(props.raceDetails.entry_window_over == 1)&&(props.raceDetails.race_simulated == 0)) {
            if(props.raceDetails.predictions_window_open == 0) {
                lineuptext = <><a className="exr-light-green-text">NEXT:</a> PREDICT SOON</>
            } else {
                lineuptext = <><a className="exr-light-green-text">NEXT:</a> PREDICT WINNER</>
            }
        } else if((racerList.length == 1)&&(inviteList.length == 0)&&(botrace == 0)) {
            lineuptext = <><a className="exr-light-green-text">NEXT:</a> INVITE RACERS</>
        } else if((botrace == 1)&&(props.raceDetails.race_simulated == 0)) {
            lineuptext = <><a className="exr-light-green-text">NEXT:</a> PREDICT & SIMULATE</>
        } else if((racerList.length > 1)&&(inviteList.length == 0)&&(props.raceDetails.race_simulated == 0)&&(predictions_closed == 0)){
            if(props.raceDetails.predictions_window_open == 0) {
                lineuptext = <><a className="exr-light-green-text">NEXT:</a> PREDICT SOON</>
            } else {
                lineuptext = <><a className="exr-light-green-text">NEXT:</a> PREDICT WINNER</>
            }
        } else if((racerList.length > 1)&&(inviteList.length == 0)&&(props.raceDetails.race_simulated == 0)&&(predictions_closed == 1)){
            lineuptext = <><a className="exr-light-green-text">NEXT:</a> SIMULATE RACE</>
        }
        setLineupHeader(lineuptext);
        setLineupRightHeader(predictionstext);

        var predictbanner = 'Predictions Now Open';
        if((props.raceDetails.race_simulated == 1)||(predictions_closed == 1)) {
            predictbanner = 'Predictions Closed';
            if(predictions_closed == 1){
                $(".predict-open").each(function() {
                    var base_predict_button = `<span style="display:table;margin:0 auto;"><span class="round-border-button center" style="font-size:14px;margin-top:-1px;cursor:not-allowed" 
                                                    title="Predict This Racer to Win">
                                                PICKS CLOSED
                                            </span></span>`;
                    $(this).replaceWith(base_predict_button);

                });
            }
        } else if((props.raceDetails.entry_window_over == 1)&&(predictions_closed == 0)&&(props.raceDetails.predictions_window_open == 0)) {
            predictbanner = 'Predictions Open '+prediction_start_time_text;
            setTimeout(function() {
                let subtext = 'The Race Lineup is set and predictions will open soon. Correctly predict the winner of this race and get XP to add to your profile and bragging rights in the EXR Predictor Rankings.'
                $("#predictions_open_subtext").html(subtext);
            },100);
        }
        setPredictionsBanner(predictbanner);
    }

    function updateLineupResults() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);
        addData.predictorID = userID;
        axiosInstance.post('race/racer_list/',addData).then(result => {
            setRacerList(result.data);
            grabRacersInvitedLineup(result.data);
            //props.refreshFullPage();
        }).catch(error => {
            console.log(error);
        });
    }

    function unlockRaceResults() {
        var loadingspinner = `<div class="preloader-wrapper small active" style="height:14px;width:14px;">
                                        <div class="spinner-layer spinner-red-only">
                                            <div class="circle-clipper left">
                                                <div class="circle"></div>
                                            </div>
                                            <div class="gap-patch">
                                                <div class="circle"></div>
                                            </div>
                                            <div class="circle-clipper right">
                                                <div class="circle"></div>
                                            </div>
                                        </div>
                                    </div>`;

        var addData = new Object();
        addData.raceID = encodeURIComponent(props.raceDetails.race_id);
        addData.userID = userID;
        if(window.confirm('Are you sure you want to unlock results to racers?')) {
            $("#unlock-results-button").html(loadingspinner);
            axiosInstance.post('league/unlock_races/',addData).then(result => {
                setTimeout(function() {
                    $("#unlock-results-button").hide();
                    window.location.reload();
                    
                },3000);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function grabRacersInvitedLineup(racerList) {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);
        axiosInstance.post('race/invite_list/',addData).then(result => {
            var invitedDetails = result.data;
            setInviteList(result.data);
            createPageResults(racerList,invitedDetails);
        }).catch(error => {
            console.log(error);
        });
    }

    function createPageResults(itemarr,inviteitemarr) {
        var section_html = [];
        var section_html_final = [];
        var racer_attributes = ['speed', 'handling', 'acceleration', 'strength'];
        var pilot_attributes = ['focus', 'stamina', 'aggression', 'intelligence'];
        var booster_attributes = ['speed', 'handling', 'acceleration', 'strength', 'focus', 'stamina', 'aggression', 'intelligence'];

        var item_col_size = "col s12 m6";
        var item_image_height = '200px';
        var stats_box_height = '407px';
        if(((itemarr.length+inviteitemarr.length) > 2)&&($("#race-landing-shell").width() > 800)) {
            item_col_size = "col s12 m6 l4";
            item_image_height = '200px';
            stats_box_height = '407px';
        } else if ($("#race-landing-shell").width() < 550) {
            var item_col_size = "col s12";
            var item_image_height = '200px';
            var stats_box_height = '407px';
        }

        item_image_height = '350px';

        let total_xp_points = 20+(20*(itemarr.length-2));
        if((itemarr.length >= 4)||(props.raceDetails.open_invite_race == 0)) {
            total_xp_points = 100;
        }
        let current_max_pct = 0;
        let favorite_racer_id = 0;
        var tmp_predicted_totals = 0;
        var favorite_racer_adjustment = 0;
        
        /* CALCULATE BEST ODDS */
        for(var z = 0; z < itemarr.length;z++) {
            var racer_racer_id = itemarr[z].racer_id;
            var racer_bot_racer = itemarr[z].bot_racer;
            var racer_win_pct = itemarr[z].racer_start_win_pct;
            if(racer_win_pct > current_max_pct) {
                current_max_pct = racer_win_pct;
                favorite_racer_id = racer_racer_id
            }

            /* Update Prediction */
            if(itemarr[z].racer_predicted == 1) {
                predicted_racer_id = racer_racer_id;
            }
        }

        var prediction_whitelisted = 0;
        var prediction_whitelist_list = [];
        var user_prediction_whitelist_okay = 1;
        if(props.raceDetails.predictor_whitelist.length > 0) {
            prediction_whitelisted = 1;
            prediction_whitelist_list = props.raceDetails.predictor_whitelist.split(",");
            user_prediction_whitelist_okay = 0;
            for(var z = 0; z < prediction_whitelist_list.length;z++) {
                if(prediction_whitelist_list[z] == userID) {
                    user_prediction_whitelist_okay = 1;
                }
            }
        } 

        setCurrentTotalRacers(itemarr.length);

        for(var z = 0; z < itemarr.length;z++) {
            var racer_racer_id = itemarr[z].racer_id;
            var race_participant_id = itemarr[z].race_participant_id;
            var pilot_image = itemarr[z].pilot_image_location;
            var racer_image = itemarr[z].ship_image_location;
            var booster_image = itemarr[z].boosters[6];
            var racer_name = itemarr[z].racer_name;
            var racer_bot_racer = itemarr[z].bot_racer;
            var race_track_faction = itemarr[z].race_track_galaxy;
            
            var score_speed = 0;
            var score_handling = 0;
            var score_acceleration = 0;
            var score_strength = 0;
            var score_focus = 0;
            var score_stamina = 0;
            var score_intelligence = 0;
            var score_aggression = 0;
            var score_speed2 = 0;
            var score_handling2 = 0;
            var score_acceleration2 = 0;
            var score_strength2 = 0;
            var score_focus2 = 0;
            var score_stamina2 = 0;
            var score_intelligence2 = 0;
            var score_aggression2 = 0;
            var score_handling3 = 0;
            var score_focus3 = 0;
            var score_speed3 = 0;
            var score_intelligence3 = 0;

            /* Setup Top Attribute Array */
            var attribute_arr = [];
            for(var p = 0; p < racer_attributes.length;p++) {
                var tmp_attr = {};
                tmp_attr['name'] = racer_attributes[p];
                tmp_attr['value'] = 0;
                attribute_arr.push(tmp_attr);
            }

            for(var p = 0; p < pilot_attributes.length;p++) {
                var tmp_attr = {};
                tmp_attr['name'] = pilot_attributes[p];
                tmp_attr['value'] = 0;
                attribute_arr.push(tmp_attr);
            }

            /* If Bot Racer, Then Use Override Attribute Stats and Booster Stats */
            if(racer_bot_racer == 1) {
                for(var m = 0; m < itemarr[z].override_attributes.length;m++) {
                    itemarr[z].override_attributes[m].attribute = itemarr[z].override_attributes[m].attribute.toLowerCase();
                    if(itemarr[z].override_attributes[m].attribute == 'speed') {
                        score_speed = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'handling') {
                        score_handling = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'acceleration') {
                        score_acceleration = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'strength') {
                        score_strength = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'focus') {
                        score_focus = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'stamina') {
                        score_stamina = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'aggression') {
                        score_aggression = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'intelligence') {
                        score_intelligence = itemarr[z].override_attributes[m].score;
                    } 

                    for(var p = 0; p < attribute_arr.length;p++) {
                        if(itemarr[z].override_attributes[m].attribute == attribute_arr[p]['name']) {
                            attribute_arr[p]['value'] = attribute_arr[p]['value'] + itemarr[z].override_attributes[m].score;
                        }
                    }
                }

                var booster_attribute_score = itemarr[z].boosters[9];
                var booster_attribute_name = itemarr[z].boosters[4].toLowerCase();
                if(booster_attribute_name == 'focus') {
                    score_focus2 = booster_attribute_score;
                } else if(booster_attribute_name == 'stamina') {
                    score_stamina2 = booster_attribute_score;
                } else if(booster_attribute_name == 'aggression') {
                    score_aggression2 = booster_attribute_score;
                } else if(booster_attribute_name == 'intelligence') {
                    score_intelligence2 = booster_attribute_score;
                } else if(booster_attribute_name == 'speed') {
                    score_speed2 = booster_attribute_score;
                } else if(booster_attribute_name == 'handling') {
                    score_handling2 = booster_attribute_score;
                } else if(booster_attribute_name == 'acceleration') {
                    score_acceleration2 = booster_attribute_score;
                }  else if(booster_attribute_name == 'strength') {
                    score_strength2 = booster_attribute_score;
                } 

                for(var p = 0; p < attribute_arr.length;p++) {
                    if(booster_attribute_name == attribute_arr[p]['name']) {
                        attribute_arr[p]['value'] = attribute_arr[p]['value'] + booster_attribute_score;
                    }
                }

            } else {
                
                for(var m = 0; m < itemarr[z].ship_attributes.length;m++) {
                    itemarr[z].ship_attributes[m].attribute = itemarr[z].ship_attributes[m].attribute.toLowerCase();

                    if(itemarr[z].ship_attributes[m].attribute == 'speed') {
                        score_speed = itemarr[z].ship_attributes[m].score;
                    } else if(itemarr[z].ship_attributes[m].attribute == 'handling') {
                        score_handling = itemarr[z].ship_attributes[m].score;
                    } else if(itemarr[z].ship_attributes[m].attribute == 'acceleration') {
                        score_acceleration = itemarr[z].ship_attributes[m].score;
                    }  else if(itemarr[z].ship_attributes[m].attribute == 'strength') {
                        score_strength = itemarr[z].ship_attributes[m].score;
                    } 

                    for(var p = 0; p < attribute_arr.length;p++) {
                        if(itemarr[z].ship_attributes[m].attribute == attribute_arr[p]['name']) {
                            attribute_arr[p]['value'] = attribute_arr[p]['value'] + itemarr[z].ship_attributes[m].score;
                        }
                    }
                }

                for(var m = 0; m < itemarr[z].pilot_attributes.length;m++) {
                    itemarr[z].pilot_attributes[m].attribute = itemarr[z].pilot_attributes[m].attribute.toLowerCase();
                    
                    if(itemarr[z].pilot_attributes[m].attribute == 'focus') {
                        score_focus = itemarr[z].pilot_attributes[m].score;
                    } else if(itemarr[z].pilot_attributes[m].attribute == 'stamina') {
                        score_stamina = itemarr[z].pilot_attributes[m].score;
                    } else if(itemarr[z].pilot_attributes[m].attribute == 'aggression') {
                        score_aggression = itemarr[z].pilot_attributes[m].score;
                    } else if(itemarr[z].pilot_attributes[m].attribute == 'intelligence') {
                        score_intelligence = itemarr[z].pilot_attributes[m].score;
                    } 

                    for(var p = 0; p < attribute_arr.length;p++) {
                        if(itemarr[z].pilot_attributes[m].attribute == attribute_arr[p]['name']) {
                            attribute_arr[p]['value'] = attribute_arr[p]['value'] + itemarr[z].pilot_attributes[m].score;
                        }
                    }
                }
                
                var booster_attributes = itemarr[z].boosters[8];
                if(booster_attributes != null) {
                    for(var m = 0; m < booster_attributes.length;m++) {
                        booster_attributes[m].attribute = booster_attributes[m].attribute.toLowerCase();
                        if(booster_attributes[m].attribute == 'focus') {
                            score_focus2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'stamina') {
                            score_stamina2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'aggression') {
                            score_aggression2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'intelligence') {
                            score_intelligence2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'speed') {
                            score_speed2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'handling') {
                            score_handling2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'acceleration') {
                            score_acceleration2 = booster_attributes[m].score;
                        }  else if(booster_attributes[m].attribute == 'strength') {
                            score_strength2 = booster_attributes[m].score;
                        } 

                        for(var p = 0; p < attribute_arr.length;p++) {
                            if(booster_attributes[m].attribute == attribute_arr[p]['name']) {
                                attribute_arr[p]['value'] = attribute_arr[p]['value'] + booster_attributes[m].score;
                            }
                        }
                    }
                }
            }

            

            var genetic_boost_text = <></>;
            var track_faction_pilot_match = 0;
            var track_faction_racecraft_match = 0;
            if(itemarr[z].pilot_faction.toLowerCase() == race_track_faction.toLowerCase()) {
                track_faction_pilot_match = 1;
                score_intelligence3 = 2;
            }
            if(itemarr[z].ship_faction.toLowerCase() == race_track_faction.toLowerCase()) {
                track_faction_racecraft_match = 1;
                score_speed3 = 2;
            }
            if(itemarr[z].boosters[5] == null) {
                itemarr[z].boosters[5] = '';
            }

            if((itemarr[z].pilot_faction.toLowerCase() == itemarr[z].ship_faction.toLowerCase())&&
                (((itemarr[z].pilot_faction.toLowerCase() == itemarr[z].boosters[5].toLowerCase())||(itemarr[z].boosters[5].toLowerCase() == 'any'))||
                    (race_track_faction.toLowerCase() == itemarr[z].pilot_faction.toLowerCase()))) {
                score_handling3 = 4;
                score_focus3 = 4;
                var finalboosttip = genetic_max_tooltip;
                if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                    finalboosttip = genetic_max_tooltip_wtrack;
                }
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={finalboosttip}>GENETIC MAX</span>;
            } else if(itemarr[z].pilot_faction.toLowerCase() == itemarr[z].ship_faction.toLowerCase()) {
                score_handling3 = 3;
                score_focus3 = 3;
                var finalboosttip = genetic_tooltip;
                if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                    finalboosttip = genetic_tooltip_wtrack;
                }
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={finalboosttip}>GENETIC BOOST</span>;
            } else if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={genetic_track_tooltip}>GENETIC TRACK</span>;
            }

            for(var p = 0; p < attribute_arr.length;p++) {
                if(attribute_arr[p]['name'] == 'handling') {
                    attribute_arr[p]['value'] = attribute_arr[p]['value'] + score_handling3;
                } else if(attribute_arr[p]['name'] == 'focus') {
                    attribute_arr[p]['value'] = attribute_arr[p]['value'] + score_focus3;
                } else if(attribute_arr[p]['name'] == 'speed') {
                    attribute_arr[p]['value'] = attribute_arr[p]['value'] + score_speed3;
                } else if(attribute_arr[p]['name'] == 'intelligence') {
                    attribute_arr[p]['value'] = attribute_arr[p]['value'] + score_intelligence3;
                }
            }

            attribute_arr = attribute_arr.sort(function(a, b){ return b.value - a.value });

            var top3_stats_html = [];
            for(var p = 0;p<3;p++) {
                var attribute_name = attribute_arr[p]['name'];
                if(attribute_name.length > 6) {
                    attribute_name = attribute_name.substring(0,5);
                }
                var attribute_value = attribute_arr[p]['value'];
                var tmp_top3 = <>
                                <span className="left exr-stats-light-grey"
                                                        style={{marginLeft:'0px',marginRight:'10px',borderRadius:'5px',position:'relative',overflow:'hidden'}}>
                                                        <span className="left" style={{padding:'2.5px 10px',fontSize:'12px'}}>
                                                            {attribute_name}
                                                        </span>
                                                        <span className="right exr-stats-light-grey2" style={{padding:'2.5px 10px',fontSize:'12px'}}>
                                                            {attribute_value}
                                                        </span>
                                                    </span>
                            </>;
                top3_stats_html.push(tmp_top3);
            }

            var tmp_top3 = <><span className="left exr-stats-light-grey light-grey-stats-grey-button-link hide-on-med-and-down show-on-large-only" data-racerid={racer_racer_id} 
                                                        style={{marginLeft:'0px',marginRight:'10px',borderRadius:'5px',position:'relative',overflow:'hidden'}} 
                                                        onClick={(e)=>viewRacerLinueupStack(e)}>
                                                        <span className="left " style={{padding:'2.5px 10px',fontSize:'12px'}}
                                                             id={"view_stack_button_"+racer_racer_id} data-racerid={racer_racer_id}>
                                                            View Stack
                                                        </span>
                                                    </span>
                            </>;
            top3_stats_html.push(tmp_top3);


            var win_chance = 0;
            var pred_xp = 0; 
            var predicted_place = '--';
            var predicted_place_avg = '--';
            if(itemarr[z].racer_start_win_pct != 0) {
                win_chance = Math.floor(100*(itemarr[z].racer_start_win_pct));
                pred_xp = Math.round(total_xp_points*(1-(itemarr[z].racer_start_win_pct)));
            }

            
            if(itemarr[z]['racer_start_likely_position'] > 0) {
                predicted_place = ordinal_suffix_of(itemarr[z]['racer_start_likely_position']);
            }

            if(itemarr[z]['racer_start_med_position'] > 0) {
                predicted_place_avg = itemarr[z]['racer_start_med_position'];
            }

            if ((win_chance == 0)&&(predicted_place != '--')) {
                win_chance = 1;
                favorite_racer_adjustment = favorite_racer_adjustment + 1;
            } else if ((win_chance == 0)&&(predicted_place == '--')) {
                win_chance = '--';
            }
            if(racer_racer_id == favorite_racer_id) { 
                win_chance = win_chance - favorite_racer_adjustment;
            }

            racerList[z].win_chance = win_chance;
            racerList[z].pred_xp = pred_xp;

            var total_predicted_amount = itemarr[z].total_predicted_amount;
            var total_predictors = itemarr[z].total_predictors;
            var tmp_predicted_totals = tmp_predicted_totals + parseInt(total_predictors);
            
            /* Update Prediction */
            if(itemarr[z].racer_predicted == 1) {
                predicted_racer_id = racer_racer_id;
                let predicted_name = <span className="exr-light-green-text">RACER {z+1} - {itemarr[z].racer_name.toUpperCase().substring(0,15)} - {win_chance}% WIN</span>
                setCurrentPredictedRacerName(predicted_name);
            }

            var actionbutton = <></>;
            var leaveactionbutton = <>
                                    <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white'}} 
                                            title="Update Your Stack"
                                            onClick={(e)=>updateRaceStack(e)} 
                                            id={"racer-"+racer_racer_id} data-racername={racer_name}  >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'15px',verticalAlign: 'text-bottom'}}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}>
                                                UPDATE STACK
                                            </span>
                                        </span>
                                        <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white'}} 
                                            title="Leave This Race"
                                                onClick={(e)=>leaveRaceCheck(e)} 
                                                id={"racer-"+racer_racer_id} data-racername={racer_name}  >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'15px',verticalAlign: 'text-bottom'}}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}>
                                                LEAVE RACE
                                            </span>
                                        </span></>;
            if((props.raceDetails.race_total_racers == 1)&&(itemarr[z].racer_id == racerID)&&(props.raceDetails.race_simulated == 0)&&(props.raceDetails.open_invite_race == 0)) {
                actionbutton = leaveactionbutton;
            } else if ((itemarr[z].racer_id == racerID)&&(racerID != itemarr[0].racer_id)&&(props.raceDetails.race_simulated == 0)&&(props.raceDetails.open_invite_race == 0)) {
                actionbutton = leaveactionbutton;
            } else if ((itemarr[z].racer_id == racerID)&&(props.raceDetails.race_simulated == 0)&&(props.raceDetails.open_invite_race == 1)&&(props.raceDetails.entry_window_over == 0)) {
                if(props.raceDetails.race_type == 'L') {
                    leaveactionbutton = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white'}} 
                                            title="Update Your Stack"
                                            onClick={(e)=>updateRaceStack(e)} 
                                            id={"racer-"+racer_racer_id} data-racername={racer_name}  >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'15px',verticalAlign: 'text-bottom'}}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}>
                                                UPDATE STACK
                                            </span>
                                        </span>;
                    actionbutton = leaveactionbutton;
                } else {
                    actionbutton = leaveactionbutton;
                }
                
            } else if((inviteList.length > 0)&&(itemarr[z].racer_id == racerID)&&(props.raceDetails.race_simulated == 0)&&(props.raceDetails.open_invite_race == 0)) {
                leaveactionbutton = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white'}} 
                                            title="Update Your Stack"
                                            onClick={(e)=>updateRaceStack(e)} 
                                            id={"racer-"+racer_racer_id} data-racername={racer_name}  >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'15px',verticalAlign: 'text-bottom'}}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}>
                                                UPDATE STACK
                                            </span>
                                        </span>;
                actionbutton = leaveactionbutton;
            }

            var score_html = <div className="col s12 white-text" style={{fontSize:'14px'}}>
                                <div className="row headline-text" style={{marginTop:'5px',marginBottom:'0px',padding:'0px',position:'relative',fontSize:'12px'}}>
                                    <span className="left white-green-link" title="Learn About Racing Stacks" style={{marginLeft:'15px'}} onClick={()=>showStackInfoBox()}>
                                        RACE STACK
                                    </span>
                                    {genetic_boost_text} 
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row" style={{marginTop:'15px',marginBottom:'5px'}}>
                                    <div className="col s12">
                                            <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Speed</div>
                                                    <div className="right">{(score_speed+score_speed2+score_speed3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_speed+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_speed2+'%',left:score_speed+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_speed3+'%',left:(score_speed+score_speed2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Handling</div>
                                                    <div className="right">{(score_handling+score_handling2+score_handling3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_handling+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_handling2+'%',left:score_handling+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_handling3+'%',left:(score_handling+score_handling2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Acceleration</div>
                                                    <div className="right">{(score_acceleration+score_acceleration2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_acceleration+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_acceleration2+'%',left:score_acceleration+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Strength</div>
                                                    <div className="right">{(score_strength+score_strength2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_strength+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_strength2+'%',left:score_strength+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col s12">
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Focus</div>
                                                    <div className="right">{(score_focus+score_focus2+score_focus3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_focus+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_focus2+'%',left:score_focus+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_focus3+'%',left:(score_focus+score_focus2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Stamina</div>
                                                    <div className="right">{(score_stamina+score_stamina2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_stamina+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_stamina2+'%',left:score_stamina+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Aggression</div>
                                                    <div className="right">{(score_aggression+score_aggression2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_aggression+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_aggression2+'%',left:score_aggression+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Intelligence</div>
                                                    <div className="right">{(score_intelligence+score_intelligence2+score_intelligence3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_intelligence+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_intelligence2+'%',left:score_intelligence+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_intelligence3+'%',left:(score_intelligence+score_intelligence2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;

            
            var base_predict_button = <span className="round-border-button center predict-open" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                    title="Predict This Racer to Win" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}
                                                    onClick={(e)=>openPredictRacerBox(e)}>
                                                PREDICT
                                            </span>;
            var inline_predict_button = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white'}}
                                                title="Predict This Racer to Win"  >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom'}}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}
                                                        onClick={(e)=>openPredictRacerBox(e)}>
                                                    PREDICT
                                                </span>
                                            </span>;
            
            
            if ((props.raceDetails.open_invite_race == 1)&&(props.raceDetails.entry_window_over == 0)) {
                base_predict_button = <></>;
                inline_predict_button = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white'}} 
                                                title="Picks Are Closed" >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}>
                                                    CLOSED
                                                </span>
                                            </span>;
            } else if ((predicted_racer_id > 0)&&(predicted_racer_id == racer_racer_id)) {
                base_predict_button = <span className="round-border-button-green center" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                    title="You Predicted This Racer To Win" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id} >
                                                    PREDICTED
                                            </span>;
                inline_predict_button = <span className="light-grey-green-button-link-active" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white'}} 
                                                title="You Predicted This Racer To Win" >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom'}}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}>
                                                    PICKED
                                                </span>
                                            </span>;
            } else if ((props.raceDetails.open_invite_race == 1)&&(props.raceDetails.predictions_window_open == 0)) {
                base_predict_button = <></>;
                inline_predict_button = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white'}} 
                                                title="Picks Are Closed" >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}>
                                                    CLOSED
                                                </span>
                                            </span>;
            } else if ((props.raceDetails.open_invite_race == 0)&&(props.raceDetails.predictions_window_over == 1)) {
                base_predict_button = <span className="round-border-button center" style={{fontSize:'14px',marginTop:'-1px',cursor:'not-allowed'}} 
                                                    title="Predict This Racer to Win" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id} >
                                                PICKS CLOSED
                                            </span>;
                inline_predict_button = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                                style={{borderRadius:'15px',border:'1px solid white'}} 
                                                title="Picks Are Closed" >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                        data-racerid={racer_racer_id}
                                                        data-pracerid={race_participant_id}>
                                                    CLOSED
                                                </span>
                                            </span>;
            } else if (((predicted_racer_id > 0)&&(predicted_racer_id != racer_racer_id)&&(total_predicted_amount > 0))||(props.raceDetails.race_simulated == 1)) {
                base_predict_button = <span className="round-border-button center" style={{fontSize:'14px',marginTop:'-1px',cursor:'not-allowed'}} 
                                                    title="Predict This Racer to Win" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id} >
                                                PICKS CLOSED
                                            </span>;
                inline_predict_button = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white'}} 
                                            title="Picks Are Closed" >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}>
                                                CLOSED
                                            </span>
                                        </span>;
            } else if (user_prediction_whitelist_okay == 0) {
                base_predict_button = <span className="round-border-button center" style={{fontSize:'14px',marginTop:'-1px',cursor:'not-allowed'}} 
                                                    title="You Don't Have Access To Predict This Race" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id} >
                                                LOCKED
                                            </span>;
                inline_predict_button = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white'}} 
                                            title="You Don't Have Access To Predict This Race" >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}>
                                                LOCKED
                                            </span>
                                        </span>;
                
            } else if ((predicted_racer_id > 0)&&(predicted_racer_id != racer_racer_id)&&(user_prediction_whitelist_okay == 1)) {
                base_predict_button = <span className="round-border-button center predict-open" style={{fontSize:'14px',marginTop:'-1px',textAlign:'center'}} 
                                                title="Change Prediction to This Racer" 
                                                data-racerid={racer_racer_id}
                                                data-pracerid={race_participant_id}
                                                onClick={(e)=>openPredictRacerBox(e)}>
                                                CHANGE PICK
                                            </span>;
                inline_predict_button = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white'}}
                                            title="Predict This Racer to Win"  >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom'}}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}
                                                    onClick={(e)=>openPredictRacerBox(e)}>
                                                CHANGE
                                            </span>
                                        </span>;
            } else if((props.raceDetails.open_invite_race == 1)&&($("#current-racer-preds").val() == 0)) {
                base_predict_button = <span className="round-border-button center" style={{fontSize:'14px',marginTop:'-1px',cursor:'not-allowed'}} 
                                                    title="You Are Out Of Predictions For This Race Day" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id} >
                                                PICKS CLOSED
                                            </span>;
                inline_predict_button = <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white'}} 
                                            title="You Are Out Of Predictions For This Race Day">
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'text-bottom',cursor:'not-allowed'}}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}>
                                                CLOSED
                                            </span>
                                        </span>;
            }

            var total_predictors_text = total_predictors+' Predictor';
            if(total_predictors != 1) {
                total_predictors_text = total_predictors_text+'s';
            }

            var bonus_html = '';
            var underdog_bonus = itemarr[z].racer_underdog_bonus;
            if (underdog_bonus > 0) {
                bonus_html = ' // '+underdog_bonus+' RPM Bonus';
            }
            if((underdog_bonus == 0)&&(predicted_place == '--')) {
                underdog_bonus = '--';
            } else {
                underdog_bonus = underdog_bonus+' RPM';
            }

            var predict_html = <><div className="row finish-screen-racer-divider"></div>
                                    <div className="row finish-screen-racer-sub" style={{margin:'0px',padding:'0px'}}>
                                        <div className="col s6 left-align">
                                            {win_chance}% Win&nbsp;{bonus_html}
                                        </div>
                                        <div className="col s6 right-align">
                                            {pred_xp} XP Reward&nbsp;&nbsp;//&nbsp;&nbsp;
                                            {total_predictors_text}
                                        </div>
                                        <div className="col s12 center-align" style={{padding:'0px',paddingTop:'10px'}}>
                                            <span style={{display:'table',margin:'0 auto'}}>  
                                            {base_predict_button}
                                            </span>
                                        </div>
                                    </div></>
            // var predict_html = <></>;

            if((props.raceDetails.open_invite_race == 1)&&(props.raceDetails.entry_window_over == 0)) {
                predict_html = <></>;
            }

            if((itemarr.length == 1)||(inviteitemarr.length > 0)) {
                predict_html = <></>;
            }

            let racernamenumber = 'RACER '+(z+1)+':';
            // if(racer_racer_id == racerID){
            //     racernamenumber = 'RACER '+(z+1)+' (YOU):';
            // }

            let statsopen = 'none';
            if(racer_racer_id == racerID){
                statsopen = 'block'
            }

            var shared_items = itemarr[z]['shared_items'];
            var user_owns_stacks = 0;
            var pilot_shared_index = -1;
            var racecraft_shared_index = -1;
            var booster_shared_index = -1;
            for(var p = 0; p < shared_items.length;p++) {
                var shared_item_id = shared_items[p]['shared_item_id'];
                var lender_id = shared_items[p]['lender_id'];
                var lender_name = shared_items[p]['lender_name'];
                if(shared_item_id == itemarr[z]['pilot_item_id']) {
                    pilot_shared_index = p;
                    if(lender_id == userID) {
                        user_owns_stacks = 1;
                    }
                } else if(shared_item_id == itemarr[z]['ship_item_id']) {
                    racecraft_shared_index = p;
                    if(lender_id == userID) {
                        user_owns_stacks = 1;
                    }
                } else if(shared_item_id == itemarr[z].boosters[0]) {
                    booster_shared_index = p;
                    if(lender_id == userID) {
                        user_owns_stacks = 1;
                    }
                }                
            }
            var booster_split = itemarr[z].boosters[3].replace("EXR ","").split("[");
            var booster_name = booster_split[0];

            var pilot_faction = itemarr[z].pilot_faction;
            var ship_faction = itemarr[z].ship_faction;
            var booster_faction = itemarr[z].boosters[5];
            if(pilot_faction == 'Augment') {
                pilot_faction = 'Aug';
            } else {
                pilot_faction = pilot_faction.substring(0,4);
            }
            if(ship_faction == 'Augment') {
                ship_faction = 'Aug';
            } else {
                ship_faction = ship_faction.substring(0,4);
            }
            if(booster_faction == 'Augment') {
                booster_faction = 'Aug';
            } else {
                booster_faction = booster_faction.substring(0,4);
            }
            

            var stack_visual = [];
            var tmp_pilot_visual = <div className="left" style={{width:"32%",height:"400px",borderRadius:"10px",marginRight:'1%',background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
            if(pilot_shared_index >= 0) {
                var sectiontextcolor = 'grey-text';
                if(shared_items[pilot_shared_index]['lender_id'] == userID) {
                    sectiontextcolor = 'exr-light-green-text';
                }
                tmp_pilot_visual = <div className="left" style={{position:'relative',width:"32%",height:"400px",borderRadius:"10px",marginRight:'1%',background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}>
                    <div className="exr-trans-grey-bg center-align" 
                        style={{position:'absolute',bottom:'5px',left:'1.5%',width:'97%',paddingTop:'20px',paddingBottom:'5px',borderRadius:'10px',fontSize:'14px'}}>
                        <div className="row headline-text" style={{margin:'0px',padding:'0px 20px',maxHeight:'45px',overflow:'hidden'}}>
                            {itemarr[z].pilot_name.replace("EXR ","").toUpperCase()}
                        </div>
                        <div className="row subheadline-text exr-light-green-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',fontSize:'12px'}}>
                            {itemarr[z].pilot_subname.toUpperCase()} &nbsp;&nbsp;///&nbsp;&nbsp;{pilot_faction.toUpperCase()}
                        </div>
                        <div className={"row subheadline-text grey-text"} style={{fontSize:'12px',margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',overflow:'hidden'}}>
                            SHARED BY: <span className={sectiontextcolor} >{shared_items[pilot_shared_index]['lender_name'].toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            }
            var tmp_racecraft_visual = <div className="left" style={{width:"32%",height:"400px",borderRadius:"10px",marginRight:'1%',background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
            if(racecraft_shared_index >= 0) {
                var sectiontextcolor = 'grey-text';
                if(shared_items[racecraft_shared_index]['lender_id'] == userID) {
                    sectiontextcolor = 'exr-light-green-text';
                }
                tmp_racecraft_visual = <div className="left" style={{position:'relative',width:"32%",height:"400px",borderRadius:"10px",marginRight:'1%',background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}>
                    <div className="exr-trans-grey-bg center-align" 
                        style={{position:'absolute',bottom:'5px',left:'1.5%',width:'97%',paddingTop:'20px',paddingBottom:'5px',borderRadius:'10px',fontSize:'14px'}}>
                        <div className="row headline-text" style={{margin:'0px',padding:'0px 20px',maxHeight:'45px',overflow:'hidden'}}>
                            {itemarr[z].ship_name.replace("EXR ","").toUpperCase()}
                        </div>
                        <div className="row subheadline-text exr-light-green-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',fontSize:'12px'}}>
                            {itemarr[z].ship_subname.toUpperCase()} &nbsp;&nbsp;///&nbsp;&nbsp;{ship_faction.toUpperCase()}
                        </div>
                        <div className={"row subheadline-text grey-text"} style={{fontSize:'12px',margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',overflow:'hidden'}}>
                            SHARED BY: <span className={sectiontextcolor} >{shared_items[racecraft_shared_index]['lender_name'].toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            }

            var tmp_booster_visual = <div className="left" style={{width:"32%",height:"400px",borderRadius:"10px",background:'url(\''+booster_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
            if(booster_shared_index >= 0) {
                var sectiontextcolor = 'grey-text';
                if(shared_items[booster_shared_index]['lender_id'] == userID) {
                    sectiontextcolor = 'exr-light-green-text';
                }
                tmp_booster_visual = <div className="left" style={{position:'relative',width:"32%",height:"400px",borderRadius:"10px",background:'url(\''+booster_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}>
                    <div className="exr-trans-grey-bg center-align" 
                        style={{position:'absolute',bottom:'5px',left:'1.5%',width:'97%',paddingTop:'20px',paddingBottom:'5px',borderRadius:'10px',fontSize:'14px'}}>
                        <div className="row headline-text" style={{margin:'0px',padding:'0px 20px',maxHeight:'45px',overflow:'hidden'}}>
                            {booster_name.toUpperCase()}
                        </div>
                        <div className="row subheadline-text exr-light-green-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',fontSize:'12px'}}>
                            {itemarr[z].boosters[4].toUpperCase()} &nbsp;&nbsp;///&nbsp;&nbsp;{booster_faction.toUpperCase()}
                        </div>
                        <div className={"row subheadline-text grey-text"} style={{fontSize:'12px',margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',overflow:'hidden'}}>
                            SHARED BY: <span className={sectiontextcolor} >{shared_items[booster_shared_index]['lender_name'].toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            }

            var user_owned_flag = <></>;
            if(user_owns_stacks == 1) {
                var margleft = '-10px';
                if(itemarr[z].racer_id == racerID) {
                    margleft = '10px';
                }
                user_owned_flag = <span className="left white-green-border-link"
                                            style={{borderRadius:'5px',border:'1px solid white',display:'inline-block',lineHeight:'14px',padding: '2.5px 0px',marginTop:'4px',marginLeft:margleft}}
                                            title="Your Items Are Being Used In This Race">
                                            <span className="white-text" style={{padding:'10px 5px',fontSize:'10px',verticalAlign: 'middle'}} >
                                                SHARED STACK
                                            </span>
                                        </span>
            }

            var tmp_html_grid = <div key={z} className={item_col_size+" finish-screen-position"} style={{margin:'0px',padding:'0px',paddingLeft:"4px",paddingRight:"4px",minWidth:'250px',minHeight:'300px'}}>
                <div id={"fboard"+z} className="final-results-col exr-new-light-grey">
                    <div className="row finish-screen-racer-name white-text" style={{fontSize:"16px"}}>
                        <span className="left"><b>{racernamenumber}</b>&nbsp;{racer_name.toUpperCase().substring(0,15)}</span>
                        <span className="right">{actionbutton}</span>
                    </div>
                    <div className="row finish-screen-racer-divider"></div>
                    <div className="row finish-screen-racer-sub">
                        <div className="col s12" style={{padding:'0px'}}>
                            <div className="col s4" style={{margin:"0px",padding:"0px",marginBottom:"5px",paddingRight:'3px'}}>
                                <div style={{width:"100%",borderRadius:"10px",height:item_image_height,background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                            </div>
                            <div className="col s4" style={{margin:"0px",padding:"0px",marginBottom:"5px",paddingRight:'1.5px',paddingLeft:'1.5px'}}>
                                <div style={{width:"100%",borderRadius:"10px",height:item_image_height,background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                            </div>
                            <div className="col s4" style={{margin:"0px",padding:"0px",marginBottom:"5px",paddingLeft:'3px'}}>
                                <div style={{width:"100%",borderRadius:"10px",height:item_image_height,background:'url(\''+booster_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="row exr-new-dark-grey" style={{padding:"10px",borderRadius:"10px",height:'auto',marginBottom:'0px'}}>
                                {score_html}
                            </div>
                        </div>
                    </div>
                    {predict_html}
                </div>
                </div>;

            var tmp_html = <div key={z} className={"col s12 finish-screen-position"} style={{margin:'0px',padding:'0px',paddingLeft:"4px",paddingRight:"4px"}}>
            <div id={"fboard"+z} className="final-results-col exr-new-light-grey" style={{position:'relative'}}>
                <div className="row" style={{marginBottom:'0px'}}>
                    <div className="col s12" style={{padding:'0px'}}>
                        <div className="col s12 l6">
                            <div className="row" style={{display:'flex',flexDirection:'row',padding:'0px 20px 0px 20px',marginTop:'5px',marginBottom:'10px'}}>
                                <div className="left" style={{marginRight:'5px'}}>
                                    <div className="left" style={{width:"30px",height:"40px",borderRadius:"2px",marginRight:'2px',background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                    <div className="left hide-on-small-only show-on-med-and-up" style={{width:"30px",height:"40px",borderRadius:"2px",marginRight:'2px',background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                    <div className="left hide-on-small-only show-on-med-and-up" style={{width:"30px",height:"40px",borderRadius:"2px",marginRight:'0px',background:'url(\''+booster_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}></div>
                                </div>
                                <div className="left" style={{flex:'1'}}>
                                    <div className="left" style={{fontSize:'20px'}}>
                                        <div className="left grey-text" style={{marginTop:'0px',width:'100%'}}>
                                            <div style={{marginLeft:'1px',padding:'0px',fontSize:'10px',verticalAlign: 'middle'}}>
                                            RACER {z+1}
                                            </div>
                                        </div>
                                        <div className="left" style={{marginTop:'-2px',width:'100%',overflow:'hidden'}}>
                                            <div className="left" style={{marginTop:'0px',marginRight:'20px'}}>{racer_name.toUpperCase().substring(0,15)}</div>
                                            <div className="left">{actionbutton}</div>
                                            {user_owned_flag}
                                        </div>
                                    </div>
                                </div>
                                <div className="right center-align show-on-med-and-down hide-on-large-only" style={{width:'70px'}}>
                                    <div className="left" style={{width:'100%',marginBottom:'2px',fontSize:'10px'}}>Win {pred_xp} XP</div>
                                    <div className="left" style={{width:'100%',marginBottom:'10px',fontSize:'16px'}}>
                                        {inline_predict_button}
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{padding:'0px 20px 0px 20px',marginBottom:'5px'}}>
                                <div className="left">
                                    {top3_stats_html}
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l6">
                            <div className="row show-on-large-only hide-on-med-and-down" style={{margin:'0px',padding:'0px',marginBottom:'2px'}}>
                                &nbsp;
                            </div>
                            <div className="row center-align" style={{padding:'0px 20px',marginTop:'0px',marginBottom:'10px'}}>
                                <div className="col s12 m2">
                                    &nbsp;
                                </div>
                                <div className="col s3 m2">
                                    <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Win Chance</div>
                                    <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>{win_chance}%</div>
                                </div>
                                <div className="col s3 m2">
                                    <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Most Finishes</div>
                                    <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>{predicted_place}</div>
                                </div>
                                <div className="col s3 m2">
                                    <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Dog Bonus</div>
                                    <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>{underdog_bonus}</div>
                                </div>
                                <div className="col s3 m2">
                                    <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Predictors</div>
                                    <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>{total_predictors}</div>
                                </div>
                                <div className="col s12 m2 show-on-large-only hide-on-med-and-down">
                                    <div className="row" style={{marginBottom:'2px',fontSize:'10px'}}>Win {pred_xp} XP</div>
                                    <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>
                                        {inline_predict_button}
                                    </div>
                                </div>
                                <div className="col s6 m2" style={{display:'none'}}>
                                    <div className="row" style={{marginBottom:'0px',fontSize:'10px'}}>Win ZTG</div>
                                    <div className="row" style={{marginBottom:'10px',fontSize:'16px'}}>
                                        <span className="light-grey-green-button-link" data-predracerid={racer_racer_id} 
                                            style={{borderRadius:'15px',border:'1px solid white',display:'inline-block',lineHeight:'16px'}} >
                                            <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign: 'middle'}}
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}
                                                    onClick={(e)=>openPredictRacerBox(e)}>
                                                PAID
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 center-align show-on-med-and-down hide-on-large-only">
                            <span className="left exr-stats-light-grey light-grey-stats-grey-button-link" data-racerid={racer_racer_id} 
                                style={{width:'100%',marginLeft:'0%',borderRadius:'5px',position:'relative',overflow:'hidden'}} 
                                onClick={(e)=>viewRacerLinueupStack(e)}>
                                <span style={{padding:'2.5px 10px',fontSize:'12px'}}
                                        id={"view_stack_button2_"+racer_racer_id} data-racerid={racer_racer_id}>
                                    View Stack
                                </span>
                            </span>
                        </div>
                    </div>
                    <div id={"lineup_stack_"+racer_racer_id} className="col s12" style={{padding:'5px 20px',display:statsopen}}>
                        <div className="row exr-trans-black-bg-subtle" style={{margin:'0px',padding:"10px",borderRadius:"10px",height:'auto',marginBottom:'0px'}}>
                            <div className="col s12 m8" style={{padding:'0px'}}>
                                {tmp_pilot_visual}
                                {tmp_racecraft_visual}
                                {tmp_booster_visual}
                            </div>
                            <div className="col s12 m4" style={{padding:'10px 0px 0px 0px'}}>
                                {score_html}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>;

            
            if(racer_racer_id == racerID){
                section_html_final.push(tmp_html);
            } else {
                section_html.push(tmp_html);
            }
            
            if(racer_racer_id == favorite_racer_id) {
                var top_prediction_html = <><div className="right" style={{marginLeft:'0px',width:"40%",maxWidth:'150px',fontSize:'16px',marginTop:'10px',fontFamily:"helvetica"}}>
                                                <div className="center center-align headline-text" style={{width:'100%',fontSize:'24px',marginTop:'25px',marginBottom:'0px',lineHeight:'1'}}>
                                                    {win_chance}%
                                                </div>
                                                <div className="center center-align" style={{width:'100%',overflow:'hidden'}}>
                                                    {racer_name.toUpperCase().substring(0,15)}
                                                </div>
                                                <div className="center center-align" style={{width:'100%',fontSize:'14px'}}>
                                                    Current Favorite
                                                </div>
                                            </div>
                                            <div className="right" style={{width:"40%",maxWidth:'120px',marginTop:'10px'}}>
                                                <img src={pilot_image} style={{width:'100%',borderRadius:'5px'}} />
                                            </div></>;

                setFavoritePredictionHTML(top_prediction_html);
            }
        }

        for(var m = 0; m < section_html.length;m++) {
            section_html_final.push(section_html[m]);
        }

        if((itemarr.length == 1)||(inviteitemarr.length > 0)) {
            predictDisplay = 'none';
        } else if((props.raceDetails.open_invite_race == 1)&&(props.raceDetails.entry_window_over == 0)) {
            predictDisplay = 'none';
        } else if((props.racerList == 0)) {
            predictDisplay = 'none';
        } else {
            predictDisplay = 'block';
        }

        if(inviteitemarr != undefined) {
            if(inviteitemarr.length > 0) {
                setInvite(true);
                var invitearr = createInviteLineup(inviteitemarr,itemarr.length);
                for(var z = 0; z < invitearr.length;z++) {
                    section_html_final.push(invitearr[z]);
                }
            } else if((props.raceDetails.race_simulated == 0)&&(itemarr.length > 1)) {
                if(refreshtimeron == 0) {
                    startRefreshTimer(30);
                }
            }
        }
        /* Set Close Predictions Text */
        setCurrentPredictedTotals(tmp_predicted_totals);
        var closepredtxt = <></>;
        closepredtxt = <>There are <a className="exr-light-green-text">{tmp_predicted_totals} of {itemarr.length} Racer Predictions</a> made on this race. Are you sure you want to close predictions to all racers and spectators?</>;
        setClosePredictionHTML(closepredtxt);

        setResultsHTML(section_html_final);
        startInviteChipsLineup();

        var tooltips = document.querySelectorAll('.lineuptips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    }

    function createInviteLineup(itemarr,racercnt) {
        var section_html = [];
        

        var item_col_size = "col s12 m6";
        var item_image_height = '200px';
        var stats_box_height = '407px';
        if(((itemarr.length+racercnt) > 2)&&($("#race-landing-shell").width() > 800)) {
            item_col_size = "col s12 m6 l4";
            item_image_height = '200px';
            stats_box_height = '407px';
        } else if ($("#race-landing-shell").width() < 550) {
            var item_col_size = "col s12";
            var item_image_height = '200px';
            var stats_box_height = '407px';
        }
        item_image_height = '350px';
        var currently_invited = 0;

        for(var z = 0; z < itemarr.length;z++) {
            var pilot_image = itemarr[z].pilot_image_location;
            var racer_name = 'Anon Racer';
            if(itemarr[z].racer_name) {
                var racer_name = itemarr[z].racer_name.toUpperCase();
            }
            var invite_racer_id = itemarr[z].racer_id;
            var score_speed = 0;
            var score_handling = 0;
            var score_acceleration = 0;
            var score_strength = 0;
            var score_focus = 0;
            var score_stamina = 0;
            var score_intelligence = 0;
            var score_aggression = 0;
            var score_speed2 = 0;
            var score_handling2 = 0;
            var score_acceleration2 = 0;
            var score_strength2 = 0;
            var score_focus2 = 0;
            var score_stamina2 = 0;
            var score_intelligence2 = 0;
            var score_aggression2 = 0;
            
            var racernumber = racerList.length+z+1;

            var invitestatus = ' (INVITED)';
            var actionbutton = <></>;
            var waitingtext = <><div className="row headline-text" style={{fontSize:"18px",margin:'0px',marginBottom:'5px'}}>RACER {racernumber}:&nbsp;&nbsp;{racer_name.toUpperCase()}</div>
            <div className="row" style={{fontSize:"16px",margin:'0px',marginBottom:"5px"}}>WAITING FOR RACER TO JOIN RACE</div>
            <div className="row refresh-countdown-holder"  style={{margin:'0px',marginBottom:"20px",fontSize:"16px"}}>RECHECKING IN: <span className="refresh-countdown-num"></span></div></>;
            
            if (props.raceDetails.creator_id == racerID) {
                actionbutton = <span className="center round-border-button" 
                                    style={{fontSize:'12px'}} 
                                    title="Remove Racers Invite To Race"
                                    onClick={(e)=>declineInviteCheck(e)}
                                    id={"invite-"+invite_racer_id} data-racername={racer_name} >
                            REMOVE INVITE
                        </span>;
            } else if (invite_racer_id == racerID) {
                actionbutton = <span className="center round-border-button" 
                                    style={{fontSize:'12px',minHeight: '22px'}} 
                                    title="Decline Invite To Race"
                                    onClick={(e)=>declineInviteCheck(e)}
                                    id={"invite-"+invite_racer_id} data-racername={racer_name} >
                            DECLINE INVITE
                        </span>;
                waitingtext = <><div className="row headline-text" style={{fontSize:"20px",marginBottom:'5px'}}>RACER {racernumber}:&nbsp;&nbsp;{racer_name.toUpperCase()}</div>
                                <div className="row exr-light-green-text" style={{fontSize:"16px"}}>ENTER YOUR STACK BELOW TO RACE</div></>;
                currently_invited = 1;
            }

            var score_html = <div className="col s12 white-text" style={{fontSize:'14px'}}>
                                <div className="row headline-text" style={{marginTop:'5px',marginBottom:'0px',padding:'0px',position:'relative',fontSize:'12px'}}>
                                    <span className="left white-green-link" title="Learn About Racing Stacks" style={{marginLeft:'15px'}} onClick={()=>showStackInfoBox()}>
                                        RACE STACK
                                    </span>
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row" style={{marginTop:'15px',marginBottom:'5px'}}>
                                        <div className="col s6">
                                            <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Speed</div>
                                                    <div className="right">{(score_speed+score_speed2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_speed+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_speed2+'%',left:score_speed+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Handling</div>
                                                    <div className="right">{(score_handling+score_handling2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_handling+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_handling2+'%',left:score_handling+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Acceleration</div>
                                                    <div className="right">{(score_acceleration+score_acceleration2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_acceleration+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_acceleration2+'%',left:score_acceleration+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Strength</div>
                                                    <div className="right">{(score_strength+score_strength2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_strength+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_strength2+'%',left:score_strength+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col s6">
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Focus</div>
                                                    <div className="right">{(score_focus+score_focus2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_focus+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_focus2+'%',left:score_focus+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Stamina</div>
                                                    <div className="right">{(score_stamina+score_stamina2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_stamina+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_stamina2+'%',left:score_stamina+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Aggression</div>
                                                    <div className="right">{(score_aggression+score_aggression2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_aggression+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_aggression2+'%',left:score_aggression+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Intelligence</div>
                                                    <div className="right">{(score_intelligence+score_intelligence2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_intelligence+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_intelligence2+'%',left:score_intelligence+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;

            var tmp_html = <div key={racernumber} className={item_col_size+" finish-screen-position"} style={{margin:'0px',padding:'0px',paddingLeft:"4px",paddingRight:"4px",minWidth:'250px',minHeight:'300px'}}>
                <div id={"fboard"+racernumber} className="final-results-col exr-new-light-grey">
                    <div className="row finish-screen-racer-name white-text" style={{fontSize:"16px"}}>
                        <span className="left"><b>RACER {racernumber}:</b>&nbsp;{racer_name.toUpperCase()}&nbsp;<span className="exr-light-green-text">(INVITED)</span></span>
                    </div>
                    <div className="row finish-screen-racer-divider"></div>
                    <div className="row finish-screen-racer-sub">
                        <div className="col s12" style={{padding:'0px'}}>
                            <div className="col s12" style={{margin:"0px",padding:"0px",marginBottom:"5px"}}>
                                <div className="exr-stats-light-grey" style={{position:'relative',width:"100%",borderRadius:"10px",height:item_image_height}}>
                                    <div className="center-align" style={{width:'100%',margin:'0px',padding:'0px',position:'absolute',top:'35%',left:'0%'}}>
                                        {waitingtext}
                                        <div class="row center-align">
                                            <span style={{display:'table',margin:'0 auto'}}>{actionbutton}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="row exr-new-dark-grey" style={{padding:"10px",borderRadius:"10px",height:'auto',marginBottom:'0px'}}>
                                {score_html}
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
            section_html.push(tmp_html);
        }

        if((props.raceDetails.race_simulated == 0)&&(currently_invited == 0)) {
            if(refreshtimeron == 0) {
                startRefreshTimer(30);
            }
        } else {
            $(".refresh-countdown-holder").each(function(){
                $(this).hide();
            });
        }

        return section_html;
        
    }

    
    function startRefreshTimer(num) {
        if(num > 0) {
            refreshtimeron = 1;
            var newnum = num-1;
            setTimeout(function(){
                startRefreshTimer(newnum);
            },1000);
            $(".refresh-countdown-num").each(function(){
                $(this).html(newnum);
            });
        } else {
            var loadingspinner = `<div class="preloader-wrapper small active" style="height:14px;width:14px;">
                                        <div class="spinner-layer spinner-red-only">
                                            <div class="circle-clipper left">
                                                <div class="circle"></div>
                                            </div>
                                            <div class="gap-patch">
                                                <div class="circle"></div>
                                            </div>
                                            <div class="circle-clipper right">
                                                <div class="circle"></div>
                                            </div>
                                        </div>
                                    </div>`;
            $(".refresh-countdown-num").each(function(){
                $(this).html(loadingspinner);
            });
            setTimeout(function(){
                refreshtimeron = 0;
                updateLineupResults();
            },3000);
        }
    }

    function showStackInfoBox() {
        var sinfobox = document.getElementById('stackinfobox');
        var info_instance = window.M.Modal.getInstance(sinfobox);
        info_instance.open();
    }

    function declineInviteCheck(e) {
        var tmp_racer_id = e.target.id.replace("invite-","");
        window.startPageModals();
        setRacerRemove(tmp_racer_id);
        if(props.raceDetails.creator_id == racerID) {
            var invitebox = document.getElementById('removeinvitebox');
        } else {
            var invitebox = document.getElementById('declineinvitebox');
        }
        var invite_instance = window.M.Modal.getInstance(invitebox);
        invite_instance.open();
    }

    function leaveRaceCheck(e) {
        var tmp_racer_id = e.target.id.replace("racer-","");
        window.startPageModals();
        setRacerRemove(tmp_racer_id);
        if(props.raceDetails.creator_id == racerID) {
            var invitebox = document.getElementById('deleteracebox');
        } else {
            var invitebox = document.getElementById('leaveracebox');
        }
        var invite_instance = window.M.Modal.getInstance(invitebox);
        invite_instance.open();
    }

    function updateRaceStack(e) {
        var tmp_racer_id = e.target.id.replace("racer-","");
        window.location.href= '/#/race/'+props.raceDetails.race_unique_id+'/edit_stack';
        dispatch(updateLocation("race_landing"));

        setTimeout(function(){
            window.location.reload();
        },500);
    }

    function viewRacerLinueupStack(e) {
        var tmp_racer_id = e.target.dataset.racerid;
        if($("#lineup_stack_"+tmp_racer_id).is(":visible")) {
            $("#lineup_stack_"+tmp_racer_id).slideUp();
            $("#view_stack_button_"+tmp_racer_id).html('View Stack');
            $("#view_stack_button2_"+tmp_racer_id).html('View Stack');
        } else {
            $("#lineup_stack_"+tmp_racer_id).slideDown();
            $("#view_stack_button_"+tmp_racer_id).html('Hide Stack');
            $("#view_stack_button2_"+tmp_racer_id).html('Hide Stack');
        }
    }

    function declineInviteFromBox(racertype) {
        if(racertype == 'owner') {
            document.getElementById("removechecksubmit").style.display = 'none';
            document.getElementById("removecheckloading").style.display = 'block';
        } else {
            document.getElementById("declinechecksubmit").style.display = 'none';
            document.getElementById("declinecheckloading").style.display = 'block';
        }

        var addData = new Object();
        addData.raceID = props.raceDetails.race_id;
        addData.racerID = current_racer_remove;
        addData.removeInvite = 'Y';
        axiosInstance.post('race/racer_invite_update/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                if(racertype == 'owner') {
                    setTimeout(function() {
                        var invitebox = document.getElementById('removeinvitebox');
                        var invite_instance = window.M.Modal.getInstance(invitebox);
                        invite_instance.close();
                        document.getElementById("removechecksubmit").style.display = 'block';
                        document.getElementById("removecheckloading").style.display = 'none';
                        updateLineupResults();
                    },2000);
                } else {
                    setTimeout(function() {
                        document.getElementById("declinechecksubmit").style.display = 'block';
                        document.getElementById("declinecheckloading").style.display = 'none';
                        window.location.href= '/#/races_home/upcoming';
                        dispatch(updateLocation("races_home"));
                    },2000);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function leaveRaceFromBox(racertype) {
        var invitenames = window.$("#racer_name_invite").val();
        if(racertype == 'owner') {
            document.getElementById("deletechecksubmit").style.display = 'none';
            document.getElementById("deletecheckloading").style.display = 'block';
        } else {
            document.getElementById("leavechecksubmit").style.display = 'none';
            document.getElementById("leavecheckloading").style.display = 'block';
        }

        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);
        addData.racerID = current_racer_remove;
        axiosInstance.post('race/racer_remove/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                setTimeout(function() {
                    window.location.href= '/#/races_home/upcoming';
                    dispatch(updateLocation("races_home"));
                },2000);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function startInviteChipsLineup() {
        if(invitechipsloaded == 0) {
            var addData = new Object();
            addData.racerID = encodeURIComponent(racerID);
            invitechipsloaded = 1;
            axiosInstance.post('member/handles/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var typeahead_data = {}
                    for(var i = 0; i < result.data.length;i++) {
                        typeahead_data[result.data[i].racer_name] = null;
                    }
                    var invitetypeahead = document.getElementById('racer_chips_invite');
                    var chips_instance = window.M.Chips.init(invitetypeahead, {
                        autocompleteOptions: {
                            data: typeahead_data,
                            minLength: 1,
                            limit: 10
                        },
                        placeholder: '+ Racer Handle',
                        secondaryPlaceholder: '+ Racer Handle',
                    });
                    invitechipsloaded = 1;
                }
            }).catch(error => {
                console.log(error);
            });
        }
        
    }

    function closePredictionsBox() {
        window.startPageModals();
        var predictionclosebox = document.getElementById('closepredictionsbox');
        var pred_instance = window.M.Modal.getInstance(predictionclosebox);
        pred_instance.open();
    }

    function closePredictions() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);

        document.getElementById("predclosechecksubmit").style.display = 'none';
        document.getElementById("predclosecheckloading").style.display = 'block';

        predictions_closed = 1;
        setTimeout(function() {
            showRaceLineup();
            var lineuptext = <><a className="exr-light-green-text">NEXT:</a> SIMULATE RACE</>;
            setLineupHeader(lineuptext);
            var predictionclosebox = document.getElementById('closepredictionsbox');
            var pred_instance = window.M.Modal.getInstance(predictionclosebox);
            pred_instance.close();
        },2000);

        // axiosInstance.post('race/close_predictions/',addData).then(result => {
        //     if(Object.keys(result.data).length > 0) {
        //         setTimeout(function() {
        //             showRaceLineup();
        //         },2000);
        //     }
        // }).catch(error => {
        //     console.log(error);
        // });
    }

    function simulateRaceResults() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);

        axiosInstance.post('race/simulate_race/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var sim_details = result.data;
                setTimeout(function() {
                    watchInitialRace();
                },2000);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function startRaceSimulator() {
        var generatingSimulation = <div id="sim-status" className="left">Running Race Algorithm....</div>;
        setSimulateButtonHTML(generatingSimulation);
        window.$("#sim-progress-tracker").show();
        setTimeout(function() {
            simulateRaceResults();
        },3000);
    }

    function finishRaceSimulator() {
        
        var simulationComplete = <div id="sim-status" className="left">Saving Results....</div>
        setSimulateButtonHTML(simulationComplete);

        var tmp_simulate_button = <span className="round-border-button" style={{fontSize:'16px'}} title="Stake Stack to Enter Race"  onClick={() => watchInitialRace() }>
                WATCH RACE
            </span>;
        
        setTimeout(function() {
            updateSimulationBar(100);
            window.$("#sim-progress-tracker").fadeOut(1000);
        },2000);

        setTimeout(function() {
            setSimulateButtonHTML(tmp_simulate_button);
        },3000);
    }

    function updateSimulationBar(simpct) {
        $("#simulator-complete-pct").html(simpct+'%');
    }

    function addRacePrediction(pred_racer_id, pred_participant_id, pred_finish, pred_amount) {
        var addData = new Object();
        addData.raceID = props.raceDetails.race_id;
        addData.userID = userID;
        addData.racerID = racerID;
        addData.predRacerID = pred_racer_id;
        addData.predParticipantID = pred_participant_id;
        addData.predFinish = pred_finish;
        addData.predAmount = pred_amount;

        $("#freepredcheckbutton").hide();
        $("#freepredcheckloading").show();
        axiosInstance.post('race/racer_predict/',addData).then(result => {
            updateLineupResults();
            setTimeout(function(){
                $("#freepredcheckbutton").show();
                $("#freepredcheckloading").hide();
                var predictbox = document.getElementById('predictracerbox');
                var predict_instance = window.M.Modal.getInstance(predictbox);
                predict_instance.close();
            },1500);
        }).catch(error => {
            console.log(error);
        });
    }

    if(entrySet == false) {
        setEntry(true);
        showRaceLineup();
        setTimeout(function(){window.startPageModals();},500);
        setTimeout(function(){updateLineupResults(); },1500);
    }

    function watchInitialRace() {
        if((props.raceDetails.admin_gated == 0)||(racerID == 1)||(racerID == 3)||(racerID == 5)||(league_admin == 1)) {
            var addData = new Object();
            addData.raceID = encodeURIComponent(props.raceDetails.race_id);
            addData.userID = encodeURIComponent(userID);
            axiosInstance.post('race/racer_watched/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    window.location.href= '/#/watch/'+props.raceDetails.race_unique_id;
                    dispatch(updateLocation("race_track"));
                }
            }).catch(error => {
                console.log(error);
            });
        } else {
            alert('Race Not Ready To Watch Yet');
        }
    }

    function openPredictRacerBox(e) {
        e.preventDefault();

        var pred_racer_id = e.target.dataset.racerid;
        var pred_participant_id = e.target.dataset.pracerid;
        var pred_finish = 1;
        var free_pred_amount = 0;
        var paid_pred_amount = 10;
        var racer_number = 1;
        var racer_name = 'Racer';
        var pilot_image = '';
        var pred_xp = 5;
        var win_chance = 50;

        for(var i = 0; i < racerList.length;i++) {
            if(racerList[i].racer_id == pred_racer_id) {
                racer_number = i+1;
                racer_name = racerList[i].racer_name.substring(0,15);
                pilot_image = racerList[i].pilot_image_location;
                pred_xp = racerList[i].pred_xp;
                win_chance = racerList[i].win_chance;
            }
        }

        var selected_prediction_html = <><div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div class="row exr-new-dark-grey" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                    <div className="col s12 m6" style={{position:'relative',zIndex:'10px'}}>
                                                    <div className="left" style={{width:"40%",maxWidth:'100px',lineHeight:'0.75'}}>
                                                        <img src={pilot_image} style={{width:'100%',borderRadius:'5px'}} />
                                                    </div>
                                                    <div className="left" style={{marginLeft:'0px',width:"47%",maxWidth:'200px',fontSize:'16px',fontFamily:"helvetica"}}>
                                                        <div className="center center-align headline-text" style={{width:'100%',fontSize:'24px',marginTop:'10px',marginBottom:'0px'}}>
                                                            {win_chance}%
                                                        </div>
                                                        <div className="center center-align" style={{width:'100%',marginTop:'-5px'}}>
                                                            WIN CHANCE
                                                        </div>
                                                        <div className="center center-align" style={{width:'100%',fontSize:'14px'}}>
                                                            Your Selection
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m6 subheadline-text" style={{position:'relative',zIndex:'10px',fontSize:'16px',fontFamily:"helvetica"}}>
                                                    <div className="row" style={{marginTop:'15px',marginBottom:'0px'}}>
                                                        <b>RACER {racer_number}:</b> {racer_name.toUpperCase().substring(0,15)}
                                                    </div>
                                                    <div className="row" style={{width:'100%',fontSize:'14px',marginBottom:'0px'}}>
                                                        When we test ran this race over 500 times, this racer won {win_chance}% of the time vs all other racers.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s6" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div id="free-prediction-select-box" class="row exr-stats-light-grey predict-hover" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{fontSize:"14px",marginTop:"0px",padding:"5px 10px 0px 10px"}}>
                                                    <div className="left headline-text" style={{marginLeft:'5px'}}>FREE PREDICTIONS</div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    Play our free predictions game to build your reputation and earn XP for predicting winners.
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    <div className="col s6" style={{padding:"0px"}}>
                                                        <b>Prediction Ticket</b><br/>
                                                        Free 
                                                    </div>
                                                    <div className="col s6">
                                                        <b>Winner Rewards*</b><br/>
                                                        {pred_xp} XP
                                                    </div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px",display:"none"}}>
                                                    * Note: XP Amount determined by racers odds to win the race. 
                                                </div>
                                                <div className="col s12 right-align" style={{marginTop:'35px',marginBottom:'7px',padding:"0px 15px"}}>
                                                    <span className="left" style={{marginTop:'0px',lineHeight:'1'}}>
                                                        <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" height="20px"/>
                                                    </span>
                                                    <div id="freepredcheckbutton" className="round-border-button right" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                            title="Place Free Race Prediction" 
                                                            onClick={()=>addRacePrediction(pred_racer_id, pred_participant_id, pred_finish, free_pred_amount)}>
                                                        PREDICT
                                                    </div>
                                                    <div id="freepredcheckloading" className="preloader-wrapper small active right splash-modal-loader" 
                                                            style={{marginTop:'-5px',height:"25px",width:"25px",marginRight:"10px"}}>
                                                        <div className="spinner-layer spinner-red-only">
                                                            <div className="circle-clipper left">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="gap-patch">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="circle-clipper right">
                                                                <div className="circle"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s6" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div id="paid-prediction-select-box" class="row exr-stats-light-grey predict-hover" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{fontSize:"14px",marginTop:"0px",padding:"5px 10px 0px 10px"}}>
                                                    <div className="left headline-text" style={{marginLeft:'5px'}}>PAID PREDICTIONS</div>
                                                    <div className="right" style={{marginTop:'-5px',display:'none'}}>
                                                        <img src="https://media.exiledracers.com/assets/game/builder/Zeitgeist_Icon_1665706117695.png" height="20px"/>
                                                    </div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    Or win big with our paid predictions partner. Subject to local availablity.
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    <div className="col s6" style={{padding:"0px"}}>
                                                        <b>Prediction Ticket</b><br/>
                                                        $ ---  
                                                    </div>
                                                    <div className="col s6">
                                                        <b>Winner Rewards*</b><br/>
                                                        $ --- + {pred_xp} XP
                                                    </div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px",display:"none"}}>
                                                    * Note: Final payout amounts are set once all predictors place bets. 
                                                </div>
                                                <div className="col s12 right-align" style={{marginTop:'35px',marginBottom:'5px',padding:"0px 15px"}}>
                                                    <span className="left" style={{marginTop:'-8px',lineHeight:'1',cursor:'pointer',display:'none'}} title="View Zeitgeist App" onClick={()=>goToZeitgeist()}>
                                                        <img src="https://media.exiledracers.com/assets/game/builder/Zeitgeist_Logo_1665706100333.png" height="30px"/>
                                                    </span>
                                                    <div id="paidpredcheckbutton" className="round-border-button right" 
                                                            style={{fontSize:'14px',marginTop:'-1px',cursor:'not-allowed'}} title="THOON&trade;">
                                                        COMING THOON&trade;
                                                    </div>
                                                    <div id="paidpredcheckloading" className="preloader-wrapper small active right splash-modal-loader" 
                                                            style={{marginTop:'-5px',height:"25px",width:"25px",marginRight:"10px"}}>
                                                        <div className="spinner-layer spinner-red-only">
                                                            <div className="circle-clipper left">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="gap-patch">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="circle-clipper right">
                                                                <div className="circle"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div class="row" style={{position:'relative',margin:'0px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{marginTop:'5px',padding:"0px 15px",fontSize:'12px'}}>
                                                    * Note: XP rewards determined by the number of racers and the odds to win the race. Final paid winner rewards are set once all predictions are in. 
                                                </div>
                                            </div>
                                        </div></>;

        setSelectedPredictionHTML(selected_prediction_html);

        var predictbox = document.getElementById('predictracerbox');
        var predict_instance = window.M.Modal.getInstance(predictbox);
        predict_instance.open();

        setTimeout(function(){
            var freepredboxheight = $("#free-prediction-select-box").outerHeight();
            var paidpredboxheight = $("#paid-prediction-select-box").outerHeight();
            if(freepredboxheight > paidpredboxheight) {
                $("#paid-prediction-select-box").css({'min-height':freepredboxheight+'px'});
            }else {
                $("#free-prediction-select-box").css({'height':paidpredboxheight+'px'});
            }
        },100);
    }

    function goToZeitgeist() {
        window.open('https://app.zeitgeist.pm/');
    }

    return(<div className="row">
        <div className="col s12" style={{fontSize:"24px",marginTop:"15px"}}>
            <div className="left headline-text" style={{marginLeft:'5px'}}>
                {lineupheadertext}
            </div>
            <div className="right" id="sim-progress-tracker" style={{marginTop:'0px',marginLeft:'-5px',display:'none',fontSize:'16px'}}>
                <div className="left preloader-wrapper small active" style={{height:'20px',width:'20px'}}>
                    <div className="spinner-layer spinner-red-only">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>
                        <div className="gap-patch">
                            <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right" style={{marginRight:'10px',marginTop:'0px',fontSize:'16px'}}>
                {lineuprightheadertext} {simulate_button_html}
            </div>
        </div>
        <div className="col s12">
            <div className="divider-thin"></div>
        </div>
        <div id="predictbanner" className="col s12" style={{marginTop:"5px",display:predictDisplay}}>
            <div className="row exr-stats-light-grey" style={{position:'relative',margin:"5px",padding:"20px",borderRadius:'5px'}}>
                <div className="col s12 m6 l7" style={{position:'relative',zIndex:'10px'}}>
                    <div className="row headline-text" style={{fontSize:'20px',marginBottom:'5px'}}>
                        {predictbannertext}
                    </div>
                    <div id="predictions_open_subtext" className="row">
                        The Race Lineup is set and you can now make predictions. 
                        Correctly predict the winner of this race and get XP to add to your profile and bragging rights in the EXR Predictor Rankings. 
                    </div>
                    <div className="row show-on-large-only hide-on-med-and-down" style={{fontSize:'14px',fontFamily:"helvetica",marginBottom:'5px'}}>
                        {/* <b>All Predictions:</b>&nbsp;{current_predicted_totals} of {current_total_racers} &nbsp;&nbsp;-&nbsp;&nbsp; */}
                        <b>Total Predictions:</b>&nbsp;{current_predicted_totals}&nbsp;&nbsp;//&nbsp;&nbsp;
                        <b>Your Prediction:</b>&nbsp;{current_predicted_racer_name}
                    </div>
                    <div className="row hide-on-med-and-up show-on-small-only" style={{fontSize:'14px',fontFamily:"helvetica",marginBottom:'5px'}}>
                        {/* <b>All Predictions:</b>&nbsp;{current_predicted_totals} of {current_total_racers} &nbsp;&nbsp;-&nbsp;&nbsp; */}
                        <b>Total Predictions:</b>&nbsp;{current_predicted_totals}<br/>
                        <b>Your Prediction:</b>&nbsp;{current_predicted_racer_name}
                    </div>
                </div>
                <div className="col s12 m6 l5 show-on-large-only hide-on-med-and-down" style={{position:'relative',zIndex:'10px'}}>
                    {favorite_prediction_html}
                    <div className="right show-on-large-only hide-on-med-and-down" style={{marginLeft:'0px',marginRight:'30px',width:'1px',height:'140px',borderLeft:'1px solid white'}}>
                    </div>
                </div> 
            </div>
        </div>
        <div className="col s12" style={{marginTop:'5px'}}>
        
            <div className="row" style={{margin:"0px",padding:"0px"}}>
                
                {race_results_html}
            </div>
        </div>
        
        <div id="declineinvitebox" className="modal page-modal page-modal-small" style={{maxWidth:'500px'}}>
                <div className="modal-content">
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                        <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>DECLINE INVITE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'18px'}}>
                            Are you sure you want to decline this Race Battle Invite? 
                            Click the Decline Invite button to leave this race.
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'40px'}}>
                            <div id="declinechecksubmit" className="center-align modal-trigger right" title="DECLINE INVITE">
                                <span className="round-border-button" style={{fontSize:'20px'}} title="DECLINE INVITE" onClick={()=>declineInviteFromBox('racer')}>
                                    DECLINE INVITE
                                </span>
                            </div>
                            <div id="declinecheckloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="removeinvitebox" className="modal page-modal page-modal-small">
                <div className="modal-content">
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                        <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>REMOVE INVITE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'18px'}}>
                            Are you sure you want to remove this Race Battle Invite? 
                            Click the Remove Invite button to uninvite this racer.
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'40px'}}>
                            <div id="removechecksubmit" className="center-align modal-trigger right" title="REMOVE INVITE">
                                <span className="round-border-button" style={{fontSize:'20px'}} title="REMOVE INVITE" onClick={()=>declineInviteFromBox('owner')}>
                                    REMOVE INVITE
                                </span>
                            </div>
                            <div id="removecheckloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="leaveracebox" className="modal page-modal page-modal-small">
                <div className="modal-content">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                        <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>LEAVE RACE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'18px'}}>
                            Are you sure you want to leave this Race? 
                            Click the Leave Race button to remove yourself from this race. For Open Races, make sure to re-enter before the entry period closes.
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'40px'}}>
                            <div id="leavechecksubmit" className="center-align modal-trigger right" title="LEAVE RACE">
                                <span className="round-border-button" style={{fontSize:'20px'}} title="LEAVE RACE" onClick={()=>leaveRaceFromBox('racer')}>
                                    LEAVE RACE
                                </span>
                            </div>
                            <div id="leavecheckloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="deleteracebox" className="modal page-modal page-modal-small">
                <div className="modal-content">
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                        <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>DELETE RACE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'18px'}}>
                            Are you sure you want to delete this Race? 
                            Click the Delete Race button to remove this race.
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'40px'}}>
                            <div id="deletechecksubmit" className="center-align modal-trigger right" title="DELETE RACE">
                                <span className="round-border-button" style={{fontSize:'20px'}} title="DELETE RACE" onClick={()=>leaveRaceFromBox('owner')}>
                                    DELETE RACE
                                </span>
                            </div>
                            <div id="deletecheckloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="predictracerbox" className="modal page-modal page-modal-large">
                <div className="modal-content" style={{paddingBottom:'5px'}}>
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                        <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>PREDICT RACE WINNER</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        {selected_prediction_html}
                    </div>
                </div>
            </div>
            <div id="closepredictionsbox" className="modal page-modal page-modal-smedium">
                <div className="modal-content" style={{paddingBottom:'5px'}}>
                    <div className="row" style={{marginBottom:'20px',position:'relative'}}>
                        <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>CLOSE RACE PREDICTIONS</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        
                        <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'18px'}}>
                            {close_prediction_html}
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'40px'}}>
                            <div id="predclosechecksubmit" className="center-align modal-trigger right" title="CLOSE PREDICTIONS">
                                <span className="round-border-button" style={{fontSize:'16px'}} title="CLOSE PREDICTIONS" onClick={()=>closePredictions()}>
                                    CLOSE PREDICTIONS
                                </span>
                            </div>
                            <div id="predclosecheckloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>);
};