import * as THREE from "three";
import {TrackTracer} from "../../trackTracer.js";

/**
 * An excluder used for culling props
 */
export class PropGeneratorExcluder {
    static RADIUS = 24;
    static SPACING = PropGeneratorExcluder.RADIUS * .25;

    /**
     * Construct an excluder
     * @param {Track} track The track to keep open
     */
    constructor(track) {
        const intervals = Math.round(track.length / PropGeneratorExcluder.SPACING);
        const interval = track.length / intervals;
        const tracer = new TrackTracer(track);
        const position = new THREE.Vector3();

        this.positions = [];

        for (let i = 0; i < intervals; ++i) {
            tracer.advance(interval);
            tracer.trace(position);

            this.positions.push(position.clone());
        }
    }

    /**
     * Check whether an object at a position should be culled
     * @param {THREE.Vector3} position The position to check
     * @param {number} [radius] The radius of the object at the position
     * @returns {boolean} True if the given position should be culled
     */
    cull(position, radius = 0) {
        const squaredSpacing = (radius + PropGeneratorExcluder.RADIUS) * (radius + PropGeneratorExcluder.RADIUS);

        for (let positionIndex = 0, positionCount = this.positions.length; positionIndex < positionCount; ++positionIndex)
            if (this.positions[positionIndex].distanceToSquared(position) < squaredSpacing)
                return true;

        return false;
    }
}