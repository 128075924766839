import {GuiPanel} from "./guiPanel.js";

export class GuiPanelEnvironment extends GuiPanel {
    /**
     * Construct the track gui panel
     * @param {HTMLElement} popupElement The element to place popups on
     * @param {Race} race The race to make changes on
     */
    constructor(popupElement, race) {
        super(popupElement);

        this.addFieldOptions(
            "Cubemap type",
            ["Equirectangular HDR"],
            "Equirectangular HDR",
            value => {},
            ["equirectangular-hdr"]);

        this.addFieldAsset(
            "Equirectangular HDR",
            race.environment.equirectangularHDR,
            value => race.environment.equirectangularHDR = value,
            null,
            "equirectangular-hdr");
    }
}