/**
 * Interpolation methods
 */
export class Interpolation {
    /**
     * Calculate the shortest distance between two values in radians
     * @param {number} a The first value in radians
     * @param {number} b The second value in radians
     * @returns {number} The smallest distance
     */
    static radiansDelta(a, b) {
        const delta = b - a;

        if (delta < -Math.PI)
            return delta + Math.PI + Math.PI;
        else if (delta > Math.PI)
            return delta - Math.PI - Math.PI;

        return delta;
    }

    /**
     * Interpolate linearly
     * @param {number} a The first value
     * @param {number} b The second value
     * @param {number} t The interpolation in the range [0, 1]
     * @returns {number} The interpolated value
     */
    static lerp(a, b, t) {
        return a + (b - a) * t;
    }

    /**
     * Interpolate linearly in radians
     * @param {number} a The first value
     * @param {number} b The second value
     * @param {number} t The interpolation in the range [0, 1]
     * @returns {number} The interpolated value
     */
    static lerpRadians(a, b, t) {
        return a + Interpolation.radiansDelta(a, b) * t;
    }

    /**
     * Interpolate linearly on a vector
     * @param {Vector3} target The vector to write to
     * @param {Vector3} a The start vector
     * @param {Vector3} b The end vector
     * @param {number} t The interpolation in the range [0, 1]
     * @returns {Vector3} The target
     */
    static lerpVector(target, a, b, t) {
        target.x = a.x + (b.x - a.x) * t;
        target.y = a.y + (b.y - a.y) * t;
        target.z = a.z + (b.z - a.z) * t;

        return target;
    }
}