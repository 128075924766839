import * as THREE from "three";
import {TrackMap} from "../track/map/trackMap.js";

/**
 * A racer pointer
 */
export class Pointer {
    static CLASS = "pointer";
    static CLASS_HIDDEN = "hidden";
    static CLASS_ARROW = "arrow";
    static CLASS_NAME_TAG = "name-tag";
    static CLASS_LEAD = "lead";
    static ARROW_RAISE = 22;
    static ARROW_WIDTH = 19;
    static ARROW_HEIGHT = 18;
    static ARROW_DENT = 4;
    static HIDE_DISTANCE = new THREE.Vector2(2, 150);
    static RAISE = 1.35;
    static ANGLE = Math.PI * -.3;
    static LEAD_DISTANCE = 64;
    static LEAD_SIZE = .8;

    /**
     * Create the arrow
     * @param {THREE.Color} color The arrow color
     * @returns {SVGSVGElement} The arrow element
     */
    static createArrow(color) {
        const svg = document.createElementNS(TrackMap.SVG_URI, "svg");
        const left = document.createElementNS(TrackMap.SVG_URI, "path");
        const right = document.createElementNS(TrackMap.SVG_URI, "path");

        left.setAttribute("class", Pointer.CLASS_ARROW);
        left.setAttribute("fill", color.getStyle());
        left.setAttributeNS(null, "d",
            "M 0 " + (-Pointer.ARROW_RAISE) +
            "L " + (Pointer.ARROW_WIDTH * -.5) + " " + (-Pointer.ARROW_HEIGHT - Pointer.ARROW_RAISE) +
            "L 0 " + (-Pointer.ARROW_HEIGHT - Pointer.ARROW_RAISE + Pointer.ARROW_DENT) +
            "L " + (Pointer.ARROW_WIDTH * .5) + " " + (-Pointer.ARROW_HEIGHT - Pointer.ARROW_RAISE) +
            "Z");

        right.setAttribute("class", Pointer.CLASS_ARROW);
        right.setAttribute("opacity", "0.3");
        right.setAttribute("fill", "black");
        right.setAttributeNS(null, "d",
            "M 0 " + (-Pointer.ARROW_RAISE) +
            "L " + (Pointer.ARROW_WIDTH * .5) + " " + (-Pointer.ARROW_HEIGHT - Pointer.ARROW_RAISE) +
            "L 0 " + (-Pointer.ARROW_HEIGHT - Pointer.ARROW_RAISE + Pointer.ARROW_DENT) +
            "Z");

        svg.appendChild(left);
        svg.appendChild(right);

        return svg;
    }

    /**
     * Create the name tag element
     * @param {string} name The name
     * @param {THREE.Color} color The color
     * @param {string} avatar The URL to the avatar image
     * @returns {HTMLDivElement} The name tag element
     */
    static createNameTag(name, color, avatar) {
        const element = document.createElement("div");

        element.className = Pointer.CLASS_NAME_TAG;
        element.style.borderColor = color.getStyle();
        element.appendChild(document.createTextNode(name));

        return element;
    }

    /**
     * Create the lead element
     * @param {THREE.Color} color The color
     * @returns {HTMLDivElement} The lead element
     */
    static createLead(color) {
        const element = document.createElement("div");

        element.className = Pointer.CLASS_LEAD;
        element.style.background = "linear-gradient(to right,transparent," + color.getStyle() + ")";
        element.style.transform = "rotate(" + Pointer.ANGLE + "rad)";

        return element;
    }

    /**
     * Construct a racer pointer
     * @param {Racer} racer The racer to follow
     * @param {number} index The index of this pointer
     * @param {boolean} [hideAtDistance] True if the pointer should be hidden at a distance
     */
    constructor(racer, index, hideAtDistance = true) {
        this.racer = racer;
        this.element = document.createElement("div");
        this.element.className = Pointer.CLASS;
        this.screenPosition = new THREE.Vector3();
        this.position = new THREE.Vector2();
        this.leadDistance = Pointer.LEAD_DISTANCE;
        this.leadDistancePrevious = this.leadDistance;
        this.hidden = false;
        this.width = 0;
        this.height = 0;
        this.index = index;
        this.hideAtDistance = hideAtDistance;
        this.first = false;
        this.tries = 0;

        // this.element.appendChild(Pointer.createArrow(racer.data.color));
        this.element.appendChild(this.nameTag = Pointer.createNameTag(racer.data.racerName, racer.data.color, racer.data.urlAvatar));
        this.nameTag.appendChild(this.lead = Pointer.createLead(racer.data.color));

        this.updateLead();
    }

    /**
     * Update the lead element
     */
    updateLead() {
        this.lead.style.width = (this.leadDistance * Pointer.LEAD_SIZE) + "px";
    }

    /**
     * Check whether this pointer overlaps another pointer
     * @param {Pointer} other The other pointer
     * @returns {boolean} True if the pointers overlap
     */
    overlaps(other) {
        return this.position.x + this.width > other.position.x && this.position.x < other.position.x + other.width &&
            this.position.y + this.height > other.position.y && this.position.y < other.position.y + other.height;
    }

    /**
     * Calculate the vertical delta between this pointer and another one
     * @param {Pointer} other The other pointer
     * @returns {number} The difference in pixels
     */
    delta(other) {
        return this.position.y - (other.position.y - other.height);
    }

    /**
     * Calculate the screen position of this pointer
     * @param {number} width The screen width in pixels
     * @param {number} height The screen height in pixels
     */
    calculateScreenPosition(width, height) {
        this.position.set(
            (this.screenPosition.x + 1) * .5 * width + Math.cos(Pointer.ANGLE) * this.leadDistance,
            (1 - this.screenPosition.y) * .5 * height + Math.sin(Pointer.ANGLE) * this.leadDistance - this.nameTag.clientHeight);
    }

    /**
     * Move the pointer up by increasing its diagonal line
     * @param {number} width The screen width in pixels
     * @param {number} height The screen height in pixels
     * @param {number} delta The vertical delta
     */
    moveUp(width, height, delta) {
        this.leadDistance += -delta / Math.sin(Pointer.ANGLE) + .5;

        this.calculateScreenPosition(width, height);
    }

    /**
     * Update the state
     * @param {number} delta The time delta
     */
    update(delta) {
        this.leadDistancePrevious = this.leadDistance;
    }

    /**
     * Update before rendering
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {Camera} camera The camera
     * @param {number} width The screen width in pixels
     * @param {number} height The screen height in pixels
     * @param {number} index The sorted index
     */
    render(time, camera, width, height, index) {
        if (!this.racer.group)
            return;

        const cameraDistance = this.racer.group.position.distanceTo(camera.position);
        let hide = !this.first &&
            (this.hideAtDistance && (cameraDistance < Pointer.HIDE_DISTANCE.x || cameraDistance > Pointer.HIDE_DISTANCE.y));

        if (!hide) {
            this.screenPosition.setFromMatrixPosition(this.racer.group.matrixWorld);
            this.screenPosition.y += Pointer.RAISE;
            this.screenPosition.project(camera);

            if (this.screenPosition.z > 1)
                hide = true;
        }

        if (hide) {
            if (!this.hidden) {
                this.hidden = true;
                this.element.classList.add(Pointer.CLASS_HIDDEN);
            }
        }
        else if (this.hidden) {
            this.hidden = false;
            this.leadDistance = this.leadDistancePrevious = Pointer.LEAD_DISTANCE;
            this.element.classList.remove(Pointer.CLASS_HIDDEN);
        }

        this.updateLead();

        const leadDistance = this.leadDistancePrevious + (this.leadDistance - this.leadDistancePrevious) * time;

        this.position.set(
            (this.screenPosition.x + 1) * .5 * width + Math.cos(Pointer.ANGLE) * leadDistance,
            (1 - this.screenPosition.y) * .5 * height + Math.sin(Pointer.ANGLE) * leadDistance - this.nameTag.clientHeight);

        this.element.style.zIndex = index.toString();
        this.element.style.transform = "translate(" +
            this.position.x.toFixed(2) + "px," +
            this.position.y.toFixed(2) + "px)";

        this.width = this.nameTag.clientWidth;
        this.height = this.nameTag.clientHeight;
    }
}