import { SectionTextWithBlock } from "../../Sections/SectionTextWithBlock";
import { SectionHeroWithImage } from "../../Sections/SectionHeroWithImage";

import merc from "../../../assets/images/merc.jpg";
import mercAircraft from "../../../assets/images/merc-aircraft.jpg";
import augs from "../../../assets/images/augs.jpg";
import augsAircraft from "../../../assets/images/augs-aircraft.jpg";
import serf from "../../../assets/images/serf.jpg";
import serfAircraft from "../../../assets/images/serf-aircraft.jpg";

import serfLogo from "../../../assets/images/EXR_FactionIcons-02_Serf.png";
import mercLogo from "../../../assets/images/EXR_FactionIcons-03_Mercenary.png";
import augLogo from  "../../../assets/images/EXR_FactionIcons-04_Augement.png";

import pilot1 from '../../../assets/images/pilot1.jpg'
import pilot2 from "../../../assets/images/pilot2.jpg";
import pilot3 from "../../../assets/images/pilot3.jpg";
import pilot4 from "../../../assets/images/pilot4.jpg";
import pilot5 from "../../../assets/images/pilot5.jpg";



import rarity from "../../../assets/images/rarity.jpg";

import exrClasses from "../../../assets/images/exr-classes.png";
import { SectionGallery, SectionImageGrid } from "../../Sections";

export const LearnFactions = () => {
  return (
    <div>
      <SectionHeroWithImage
        title="Factions"
        subtitle="Rare EXR factions Discovered"
        description="Exiled racers consist of 3 known factions: mercs, augs and serfs. Each Faction has a core strength and an important role to play. Learn more below."
        content={{
          src: exrClasses,
          title: "exr classes",
        }}
      />
      <SectionTextWithBlock
        title="Mercs"
        block={{
          type: "image",
          content: {
            src: merc,
            title: "pilot serf",
          },
        }}
        description="Military faction sent in to take over planets. Aggressive killers destroying all in their path."
        blockPositionOnDesktop="right"
        links={[
          {
            name: "See more",
            url: "https://view.exiledracers.com/",
          },
          {
            name: "Learn more",
            url: "https://blog.exiledracers.com/breaking-the-mercs-revealed-c14f32f53988",
          },
        ]}
        additionalNote={{
          label: "Boosts",
          text: "Aggression",
        }}
        textImage={{
          src: mercLogo,
          title: "merc",
        }}
      />
      <SectionImageGrid
        images={[
          {
            src: pilot1,
            title: "pilot",
          },
          {
            src: pilot2,
            title: "pilot",
          },
          {
            src: pilot3,
            title: "pilot",
          },
          {
            src: pilot4,
            title: "pilot",
          },
          {
            src: pilot5,
            title: "pilot",
          },
        ]}
      />
      <SectionGallery
        images={[
          {
            original: mercAircraft,
            thumbnail: mercAircraft,
          },
          {
            original: serfAircraft,
            thumbnail: serfAircraft,
          },
          {
            original: augsAircraft,
            thumbnail: augsAircraft,
          },
        ]}
      />
      <SectionTextWithBlock
        title="Augs"
        block={{
          type: "image",
          content: {
            src: augs,
            title: "augs",
          },
        }}
        description="Bionic beings. The result of interplanetary destruction. slaves to harumi."
        blockPositionOnDesktop="right"
        links={[
          {
            name: "See more",
            url: "https://view.exiledracers.com/",
          },
          {
            name: "Learn more",
            url: "https://blog.exiledracers.com/the-augs-revealed-a-marvel-of-modern-biosynthetic-engineering-3e5c36c04c2c",
          },
        ]}
        additionalNote={{
          label: "Boosts",
          text: "Intelligence",
        }}
        textImage={{
          src: augLogo,
          title: "augs",
        }}
      />
      <SectionGallery
        images={[
          {
            original: augsAircraft,
            thumbnail: augsAircraft,
          },
          {
            original: mercAircraft,
            thumbnail: mercAircraft,
          },
          {
            original: serfAircraft,
            thumbnail: serfAircraft,
          },
        ]}
      />
      <SectionTextWithBlock
        title="Serfs"
        block={{
          type: "image",
          content: {
            src: serf,
            title: "pilot serf",
          },
        }}
        description="Mechanical workforce. Miner & transporters of Rare processed metal. Gaining sentience & uprising."
        blockPositionOnDesktop="right"
        links={[
          {
            name: "See more",
            url: "https://view.exiledracers.com/",
          },
          {
            name: "Learn more",
            url: "https://blog.exiledracers.com/serfs-gain-consciousness-and-form-ranks-d3dbbf11fa48",
          },
        ]}
        additionalNote={{
          label: "Boosts",
          text: "Stamina",
        }}
        textImage={{
          src: serfLogo,
          title: "serf",
        }}
      />
      <SectionGallery
        images={[
          {
            original: serfAircraft,
            thumbnail: serfAircraft,
          },
          {
            original: augsAircraft,
            thumbnail: augsAircraft,
          },
          {
            original: mercAircraft,
            thumbnail: mercAircraft,
          },
        ]}
      />
      <SectionTextWithBlock
        title="Rarity"
        subtitle="Summary"
        block={{
          type: "image",
          content: {
            src: rarity,
            title: "rarity",
          },
        }}
        description="Each faction has 5 ranks PLUS a legendary rank."
        blockPositionOnDesktop="left"
        links={[
          {
            name: "See more",
            url: "https://view.exiledracers.com/",
          },
        ]}
      />
    </div>
  );
}