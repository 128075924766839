import * as THREE from "three";
import {Random} from "../../../../exr-webgl-hub/math/random.js";

export class RacerGlitcher {
    static SHADER_VERTEX = `
        varying vec2 iUv;
        
        void main() {
            iUv = uv;
            
            gl_Position = vec4(position, 1.);
        }
        `;
    static SHADER_FRAGMENT = `
        #define DIVISIONS_X 30
        #define DIVISIONS_Y 150
        #define HUE_SHIFT 0.2
        #define SHIFT_MAGNITUDE 0.05
        #define SHIFT_POWER 8.
        #define INVERT_CHANCE 0.05
        #define LIGHTING_INTENSITY 0.0
        
        uniform sampler2D source;
        uniform float n;
    
        varying vec2 iUv;
        
        uint hash(uint x) {
            x ^= x >> 16;
            x *= 0x7feb352du;
            x ^= x >> 15;
            x *= 0x846ca68bu;
            x ^= x >> 16;
            
            return x;
        }
        
        vec3 hueShift (in vec3 Color, in float Shift) {
            vec3 P = vec3(0.55735) * dot(vec3(0.55735), Color);
            vec3 U = Color - P;
            vec3 V = cross(vec3(0.55735),U);    
        
            Color = U*cos(Shift*6.2832) + V*sin(Shift*6.2832) + P;
            
            return vec3(Color);
        }
        
        float rand(vec2 co){
            return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
        }
        
        void main() {
            uint x = uint(iUv.x * float(DIVISIONS_X));
            uint y = uint(iUv.y * float(DIVISIONS_Y));
            float hue = pow(rand(vec2(float(x + y * uint(DIVISIONS_X)), n * 40.)), 1.5) * HUE_SHIFT;
            float lighting = 1. + sqrt(rand(vec2(float(x + y * uint(DIVISIONS_X)), n * -25.))) * LIGHTING_INTENSITY;
            float shift = SHIFT_MAGNITUDE * pow(rand(vec2(float(x + y * uint(DIVISIONS_X)), n * 30. - 61.)), SHIFT_POWER);
            float invertChance = rand(vec2(float(x + y * uint(DIVISIONS_X)), n * -15. + 55.));
            vec2 shiftedUv = vec2(fract(iUv.x + shift), iUv.y);
            
            gl_FragColor = vec4(hueShift(texture(source, shiftedUv).xyz, hue) * lighting, 1.);
            
            if (invertChance < INVERT_CHANCE)
                gl_FragColor.xyz = 1. - gl_FragColor.xyz;
        }
        `;

    /**
     * Construct a glitch texture maker
     * @param {THREE.Texture} texture The texture to glitch
     */
    constructor(texture) {
        this.texture = texture;
    }

    /**
     * Create glitched textures
     * @param {WebGLRenderer} renderer The renderer
     * @param {number} [count] The number of textures to generate
     * @returns {Promise} A promise that resolves when textures are generated
     */
    glitch(renderer, count = 7) {
        return new Promise(resolve => {
            const random = new Random();
            const material = new THREE.ShaderMaterial({
                vertexShader: RacerGlitcher.SHADER_VERTEX,
                fragmentShader: RacerGlitcher.SHADER_FRAGMENT,
                uniforms: {
                    source: {
                        type: "t",
                        value: this.texture
                    },
                    n: {
                        value: 0
                    },
                    seed: {value: random.float}
                }
            });

            const mesh = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), material);
            const camera = new THREE.OrthographicCamera();
            const targets = [];

            for (let i = 0; i < count; ++i)
                targets.push(new THREE.WebGLRenderTarget(
                    this.texture.image.width,
                    this.texture.image.height,
                    {
                        format: THREE.RGBAFormat,
                        wrapS: THREE.ClampToEdgeWrapping,
                        wrapT: THREE.ClampToEdgeWrapping,
                        depthBuffer: false
                    }));

            const textures = [];

            for (const target of targets) {
                const scene = new THREE.Scene();

                material.uniforms.n.value = Math.random();

                scene.add(mesh);

                renderer.setRenderTarget(target);
                renderer.render(scene, camera);

                textures.push(target.texture);
            }

            renderer.setRenderTarget(null);

            resolve(textures);
        });
    }
}