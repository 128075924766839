import * as THREE from "three";
import {Howl, Howler} from "howler";
import {AudioShuffler} from "./audioShuffler.js";

export class AudioBase {
    #cameraDirection = new THREE.Vector3();
    #howls = {};
    #orders = {};
    #initialized = false;
    #volume = 1;
    #sources;
    #paths;
    #camera;

    /**
     * Construct the audio object
     * @param {{
     *     file: string,
     *     [loop]: boolean,
     *     [variations]: number
     * }[]} sources The sources
     * @param {[string, string][]} paths An array of paths and extensions for each available file type
     */
    constructor(sources, paths) {
        this.#sources = sources;
        this.#paths = paths;

        const trigger = () => {
            if (this.#initialized) {
                this.start();

                window.removeEventListener("mousedown", trigger);
                window.removeEventListener("touchstart", trigger);
                window.removeEventListener("keydown", trigger);
            }
        };

        window.addEventListener("mousedown", trigger);
        window.addEventListener("touchstart", trigger);
        window.addEventListener("keydown", trigger);
    }

    /**
     * Make an array of file names, one for every configured path
     * @param {string} file The file name
     * @returns {string[]} The array of file names
     */
    makeFiles(file) {
        const files = [];

        for (let path = 0, pathCount = this.#paths.length; path < pathCount; ++path)
            files.push(this.#paths[path][0] + file + this.#paths[path][1]);

        return files;
    }

    /**
     * Get the number of sources to load
     * @returns {number} The number of sources to load
     */
    get sourceCount() {
        let sources = 0;

        for (let source = 0, sourceCount = this.#sources.length; source < sourceCount; ++source)
            sources += this.#sources[source].variations || 1;

        return sources;
    }

    /**
     * Check if the audio is initialized
     * @returns {boolean} True if it is
     */
    get initialized() {
        return this.#initialized;
    }

    /**
     * Load all audio sources
     */
    load() {
        return new Promise(resolve => {
            let loading = this.sourceCount;

            const onLoad = () => {
                if (!--loading)
                    resolve();
            };

            const onLoadError = file => {
                console.error("Error loading " + file);
            }

            for (const source of this.#sources) {
                const subFiles = [];

                if (source.variations) {
                    for (let variation = 0; variation < source.variations; ++variation)
                        subFiles.push(source.file + "_" + (variation + 1).toString().padStart(2, "0"));
                }
                else
                    subFiles.push(source.file);

                this.#howls[source.file] = [];

                for (const file of subFiles)
                    this.#howls[source.file].push(new Howl({
                        src: this.makeFiles(file),
                        loop: !!source.loop,
                        preload: true,
                        onload: onLoad,
                        onloaderror: () => {
                            onLoadError(file);
                        }
                    }));

                if (source.variations > 1)
                    this.#orders[source.file] = new AudioShuffler(source.variations);
            }
        });
    }

    /**
     * Initialize
     * @param {THREE.Camera} camera The camera
     */
    initialize(camera) {
        this.#camera = camera;
        this.#initialized = true;
    }

    /**
     * Update the state
     */
    update() {
        if (!this.initialized)
            return;

        this.#camera.getWorldDirection(this.#cameraDirection);

        Howler.pos(
            this.#camera.position.x,
            this.#camera.position.y,
            this.#camera.position.z);
        Howler.orientation(
            this.#cameraDirection.x,
            this.#cameraDirection.y,
            this.#cameraDirection.z,
            0, 1, 0);
    }

    /**
     * Get an audio sample
     * @param {string} name The name
     * @returns {Howl} The audio
     */
    getAudio(name) {
        const howls = this.#howls[name];

        if (howls.length > 1)
            return howls[this.#orders[name].index];

        return howls[0];
    }

    /**
     * Start audio
     */
    start() {

    }

    /**
     * Set the volume
     * @param {number} volume The volume in the range [0, 1]
     */
    setVolume(volume) {
        Howler.volume(volume);

        return this.#volume = volume;
    }

    /**
     * Get the volume
     * @returns {number} The volume
     */
    getVolume() {
        return this.#volume;
    }
}