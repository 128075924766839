export const GAS_PRICE_API = "https://ethgasstation.info/json/ethgasAPI.json";

// TODO: move this to .env based on NODE_ENV
export const racerContractAddresses = {
  //1337: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
  1337: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
  1287: "0x3bc57179E6d24B98a43D11415c870e8B544Ffa31",
  1284: "0x104b904e19fBDa76bb864731A2C9E01E6b41f855",
};

export const mintPackContractAddress = {
  //1337: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
  1337: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
  1287: "0x184A9af1E11BfDa39953741c6eD6435B8c50166a",
  1284: "0x184A9af1E11BfDa39953741c6eD6435B8c50166a",
};

export const pilotContractAddress = {
  //1337: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
  1337: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
  1287: "0x4A7B774D959B0ED6D7b2F475e394a7AE7367eB21",
  1284: "0x515e20e6275CEeFe19221FC53e77E38cc32b80Fb",
};

export const boosterContractAddress = {
  //1337: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
  1337: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
  1287: "0x41030D2B6E64c7Ab9AF6BA45DB68ab59c5e8F5De",
  1284: "0xa0B48010Ff75136bBb5c8a12fEdFa37160819834",
};

// TODO: confiirm these chain Ids
export const ETH_NETWORK_IDS = {
  1: "MainNet",
  3: "Rinkeby",
  4: "Ropsten",
  1337: "LocalHost",
  1287: "Moonbase Alpha",
  1284: "Moonbeam",
};

// Moonbase Primary Constant
const MOONBASE = 1287;
