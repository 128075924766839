import {RaceDesign} from "./raceDesign.js";

const testData = `{"track":{"gates":{"spacing":384.1796875,"model":"https://media.exiledracers.com/assets/game/builder/Hex_Gate_1661543906281.glb",
"modelFinish":"assets/portal.glb"},
"asteroids":{"seed":905969663.789063,"fieldCount":1.921875,"fieldSize":36.904296875,"spacing":54.666015625,"asset1":"https://media.exiledracers.com/assets/game/builder/Asteroid_Grey_1_1661801662068.glb","asset2":"https://media.exiledracers.com/assets/game/builder/Asteroid_Grey_2_1661801678166.glb","asset3":"https://media.exiledracers.com/assets/game/builder/Asteroid_Grey_3_1661801691182.glb"},"props":[]},"environment":{
"planet":{"lighting":1,"model":null,"texture":"https://media.exiledracers.com/assets/game/builder/Mars_1664903604022.jpg",
"seed":43,"distance":500,"radius":1000,"angle":0.4,"pitch":-0.712890625,"atmosphere":0.04,"rotation":0.00033203125,"tilt":0.5,
"color1":"62709D","color2":"8994C6","color3":"9AA8DD","color4":"8D9FDB","color5":"7F8EC1","color6":"69789C"},
"equirectangularHDR": "assets/skyAugs.exr",
"cubemap":{"left":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Left_1661801976319.png","right":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Right_1661801989958.png","top":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Up_1661801897758.png","bottom":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Down_1661801916291.png","front":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Front_1661801941725.png","back":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Back_1661801958941.png"}}}`;


export class RaceDesignLocal extends RaceDesign {
    /**
     * A local race design
     * @param {string} [data] The race design as a string
     */
    constructor(data = testData) {
        super();

        this.data = data;
    }

    /**
     * Load data
     * @returns {Promise} A promise
     */
    load() {
        return new Promise((resolve, reject) => {
            if (typeof this.data === 'string' || this.data instanceof String) {
                this.receiveData(JSON.parse(this.data));
            } else {
                this.receiveData(this.data);
            }

            if (this.track && this.environment)
                resolve();
            else
                reject();
        });
    }
}