import {AudioBase} from "../../../exr-webgl-hub/audio/audioBase.js";

export class Audio extends AudioBase {
    static AMB_SPACE = "AMB_SpaceAmbience_LP";
    static AMB_CHASE = "AMB_ShipFollowAdd_LP";
    static AMB_START_GATE = "AMB_StartGate_LP";
    static AMB_CROWD_CHEER = "AMB_CrowdCheer_LP";

    static SFX_CROWD_CHEER_FINAL = "SFX_CrowdCheerFinal";
    static SFX_FINISH_LASER = "SFX_FinishLaser";
    static SFX_RACE_START = "SFX_RaceStart";
    static SFX_PASS_BY = "SFX_PassByObj";
    static SFX_LAP_MARKER = "SFX_LapMarker";
    static SFX_LAP_MARKER_FINAL = "SFX_LapMarkerFinal";
    static SFX_LAP_MARKER_FINISH = "SFX_LapMarkerFinish";
    static SFX_SHIP_ENGINE_SERF = "SFX_ShipEngine01_LP";
    static SFX_SHIP_ENGINE_AUG = "SFX_ShipEngine02_LP";
    static SFX_SHIP_ENGINE_MERC = "SFX_ShipEngine03_LP";
    static SFX_BOOST = "SFX_BoostRace";
    static SFX_SHIP_FLY_BY_FOCUS = "SFX_ShipFlyByFocus";
    static SFX_SHIP_FLY_BY = "SFX_ShipFlyByOther";

    static #PATHS = [
        // ["assets/audio/Ogg/", ".ogg"],
        ["assets/audio/Wav/", ".wav"]];
    static #SOURCES = [
        {
            file: Audio.AMB_SPACE,
            loop: true
        },
        {
            file: Audio.AMB_CHASE,
            loop: true
        },
        {
            file: Audio.AMB_START_GATE,
            loop: true
        },
        {
            file: Audio.AMB_CROWD_CHEER,
            loop: true
        },
        {
            file: Audio.SFX_CROWD_CHEER_FINAL
        },
        {
            file: Audio.SFX_FINISH_LASER,
            variations: 3
        },
        {
            file: Audio.SFX_RACE_START
        },
        {
            file: Audio.SFX_PASS_BY,
            variations: 7
        },
        {
            file: Audio.SFX_SHIP_ENGINE_SERF,
            loop: true
        },
        {
            file: Audio.SFX_SHIP_ENGINE_AUG,
            loop: true
        },
        {
            file: Audio.SFX_SHIP_ENGINE_MERC,
            loop: true
        },
        {
            file: Audio.SFX_LAP_MARKER
        },
        {
            file: Audio.SFX_LAP_MARKER_FINAL
        },
        {
            file: Audio.SFX_LAP_MARKER_FINISH
        },
        {
            file: Audio.SFX_BOOST,
            variations: 6
        },
        {
            file: Audio.SFX_SHIP_FLY_BY_FOCUS,
            variations: 14
        },
        {
            file: Audio.SFX_SHIP_FLY_BY,
            variations: 11
        }
    ];

    /** @type {Racers} */
    #racers;

    /**
     * Construct the audio object
     */
    constructor() {
        super(
            Audio.#SOURCES,
            Audio.#PATHS);
    }

    /**
     * Start audio
     */
    start() {
        super.start();

        this.getAudio(Audio.AMB_SPACE).play();
        this.getAudio(Audio.AMB_CROWD_CHEER).play();
        
        const startGate = this.getAudio(Audio.AMB_START_GATE);
        const startGateID = startGate.play();

        startGate.pos(0, 0, 0, startGateID);
        startGate.orientation(0, 1, 0, startGateID);
        startGate.pannerAttr({
            refDistance: 15,
            rolloffFactor: 4
        }, startGateID);

        this.#racers.startEngineSounds(this);
    }

    /**
     * Start the engine sounds
     * @param {Racers} racers The racers to play engine sounds fpr
     */
    initializeRacers(racers) {
        this.#racers = racers;
    }
}
