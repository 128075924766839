import * as THREE from "three";
import {Interpolation} from "../../../../exr-webgl-hub/math/interpolation.js";
import {RacerMovementSampler} from "./movement/racerMovementSampler.js";
import {RacerTurbulence} from "./turbulence/racerTurbulence.js";
import {RacerTurbulenceComponentHover} from "./turbulence/component/racerTurbulenceComponentHover.js";
import {RacerTrail} from "./trail/racerTrail.js";
import {RacerTrailProperties} from "./trail/racerTrailProperties.js";
import {RaceRenderOrder} from "../../raceRenderOrder.js";
import {StyleUtils} from "../../utils/styleUtils.js";
import {Globals} from "../../../global/globals.js";
import {CameraTargetable} from "../../camera/cameraTargetable.js";
import {lerp} from "three/src/math/MathUtils.js";
import {RacerGlitcher} from "./racerGlitcher.js";
import {TrackProp} from "../../track/props/trackProp.js";
import {Audio} from "../../audio/audio.js";

/**
 * A racer
 */
export default class Racer extends CameraTargetable {
    static SCALE_BIGGEST = StyleUtils.getFloat("--racer-model-scale-biggest");
    static SCALE_BIG = StyleUtils.getFloat("--racer-model-scale-big");
    static SCALE_SMALL = StyleUtils.getFloat("--racer-model-scale-small");
    static EPSILON = .0001;
    static LOOK_AHEAD_TURN = StyleUtils.getFloat("--racer-look-ahead-turn");
    static LOOK_AHEAD_BANK = StyleUtils.getFloat("--racer-look-ahead-bank");
    static BANK_MAGNITUDE = StyleUtils.getFloat("--racer-bank-magnitude");
    static TRAIL_STARTINGX = StyleUtils.getFloat("--racer-trail-x");
    static TRAIL_STARTINGY = StyleUtils.getFloat("--racer-trail-y");
    static ALTITUDE_SHIFT = .7;
    static ALTITUDE_PHASE = .0003;
    static ENV_MAP_INTENSITY = TrackProp.ENV_MAP_INTENSITY;
    static RATE_SPEED = 1;
    static ROLL_TURN_THRESHOLD = .5;

    #turbulence = new RacerTurbulence([
        new RacerTurbulenceComponentHover(.61, .015),
        new RacerTurbulenceComponentHover(.483, .02)
    ]);
    #position = new THREE.Vector3();
    #positionPrevious = new THREE.Vector3();
    #positionTarget = new THREE.Vector3();
    #direction = new THREE.Vector3(1, 0, 0);
    #directionPrevious = this.#direction.clone();
    #directionRender = this.#direction.clone();
    #altitudeShiftOffset = Math.random();
    #group = new THREE.Group();
    #yaw = 0;
    #yawPrevious = this.#yaw;
    #roll = 0;
    #rollPrevious = this.#roll;
    #rotation = new THREE.Euler();
    #trailColorUpdate = 0;
    #ship = null;
    #moved = 0;
    #movedPrevious = this.#moved;
    #movedLast = this.#moved;
    #speed = 0;
    #speedPrevious = this.#speed;
    #acceleration = 0;
    #accelerationPrevious = this.#acceleration;
    #finished = -1;
    #material = null;
    #glitchedTextures = null;
    #aggressionBoost = 0;
    #engineSound = null;
    #engineSoundID = -1;
    #engineSoundRate = 1;
    #turning = false;
    #soundFlyBy = null;
    #soundBoost = null;
    
    #data;
    #tracer;
    #offset;
    #movementSampler;
    #trail;

    /**
     * Construct a racer
     * @param {RacerData} data Racer data
     * @param {TrackTracer} tracer A track tracer
     * @param {Vector3} offset The relative offset on the track
     * @param {number} [racerCount] The number of racers
     */
    constructor(data, tracer, offset, racerCount=2) {
        super();

        this.#data = data;
        this.#movementSampler = new RacerMovementSampler(data.movement);
        this.#tracer = tracer;
        this.#offset = offset;
        this.#position.copy(offset);
        this.#positionPrevious.copy(offset);
        this.#positionTarget = new THREE.Vector3();

        let ship_scale = Racer.SCALE_SMALL;
        let trailx = Racer.TRAIL_STARTINGX ;
        let traily = Racer.TRAIL_STARTINGY ;
        if(racerCount > 6) {
            this.ship_scale = Racer.SCALE_SMALL;
            trailx = Racer.TRAIL_STARTINGX / 2.45;
            traily = Racer.TRAIL_STARTINGY / 2.45;
        } else if (racerCount > 1) {
            ship_scale = Racer.SCALE_BIG;
            trailx = Racer.TRAIL_STARTINGX / 1.45;
            traily = Racer.TRAIL_STARTINGY / 1.45;
        } else {
            ship_scale = Racer.SCALE_BIGGEST;
        }

        this.#group.scale.multiplyScalar(ship_scale);
        this.#trail = new RacerTrail(
            new RacerTrailProperties(data.racerSpecial,data.sponsor),
            new THREE.Vector3(trailx, traily, 0));
        this.#trail.teleport(this.#position);
    }

    /**
     * Get the movement
     * @returns {number} The amount of movement
     */
    get moved() {
        return this.#moved;
    }

    /**
     * Get the aggression boost value
     * @returns {number} The aggression boost
     */
    get aggressionBoost() {
        return this.#aggressionBoost;
    }

    /**
     * Get the finished index
     * @returns {number} The finished index
     */
    get finished() {
        return this.#finished;
    }

    /**
     * Get the group
     * @returns {THREE.Group} The group
     */
    get group() {
        return this.#group;
    }

    /**
     * Get the ship model
     * @returns {THREE.Group} The ship
     */
    get ship() {
        return this.#ship;
    }
    
    /**
     * Get the trail
     * @returns {RacerTrail} The racer trail
     */
    get trail() {
        return this.#trail;
    }

    /**
     * Get the position
     * @returns {THREE.Vector3} The position
     */
    get position() {
        return this.#position;
    }

    /**
     * Get the racer data
     * @returns {RacerData} The data
     */
    get data() {
        return this.#data;
    }

    /**
     * Update the engine sound
     * @param {boolean} straight True if the racer is on a straight track
     * @param {number} rateSpeed The speed to change the rate with
     */
    updateEngineSound(straight, rateSpeed) {
        if (!this.#engineSound)
            return;

        this.#engineSound.pos(
            this.#group.position.x,
            this.#group.position.y,
            this.#group.position.z,
            this.#engineSoundID);
        this.#engineSound.orientation(
            this.#directionRender.x,
            this.#directionRender.y,
            this.#directionRender.z,
            this.#engineSoundID);

        const ratePrevious = this.#engineSoundRate;

        if (straight)
            this.#engineSoundRate = Math.min(1, this.#engineSoundRate + rateSpeed);
        else
            this.#engineSoundRate = Math.max(.5, this.#engineSoundRate - rateSpeed);

        if (ratePrevious !== this.#engineSoundRate)
            this.#engineSound.rate(
                this.#engineSoundRate,
                this.#engineSoundID);
    }

    /**
     * Start the engine sound loop
     * @param {Object} audio The audio object
     */
    startEngineSound(audio) {
        switch (this.#data.faction) {
            case "Mercenary":
                this.#engineSound = audio.getAudio(Audio.SFX_SHIP_ENGINE_MERC);

                break;
            case "Serf":
                this.#engineSound = audio.getAudio(Audio.SFX_SHIP_ENGINE_SERF);

                break;
            case "Augment":
            default:
                this.#engineSound = audio.getAudio(Audio.SFX_SHIP_ENGINE_AUG);

                break;
        }

        this.#engineSoundID = this.#engineSound.play();
        this.#engineSound.pannerAttr({
            refDistance: 25,
            rolloffFactor: 2
        }, this.#engineSoundID);
        this.#engineSound.seek(
            Math.random() * this.#engineSound.duration,
            this.#engineSoundID);

        this.updateEngineSound(true, 0);
    }

    /**
     * Get the number of seconds to the finish
     * @param {number} length The track length
     * @returns {number} The number of seconds
     */
    getTimeToFinish(length) {
        for (let anchor = 0, anchorCount = this.#data.movement.anchors.length; anchor < anchorCount; ++anchor)
            if (this.#data.movement.anchors[anchor].movement > length)
                return this.#data.movement.anchors[Math.max(0, anchor - 1)].time;

        return this.#data.movement.anchors[this.#data.movement.anchors.length - 1].time;
    }

    /**
     * Get the position of the targetable
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {Vector3} The position
     */
    getPosition(time) {
        return this.#group.position;
    }

    /**
     * Get the quaternion of this targetable
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {Quaternion} The quaternion
     */
    getQuaternion(time) {
        return this.#group.quaternion;
    }

    /**
     * Get the direction of the targetable
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {Vector3} The normalized direction
     */
    getDirection(time) {
        return this.#directionRender;
    }

    /**
     * Get the distance of the targetable on the track\
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {number} The distance
     */
    getDistance(time) {
        return lerp(this.#movedPrevious, this.#moved, time);
    }

    /**
     * Get the index of the track piece this targetable is on
     * @returns {number} The index
     */
    getTrackPieceIndex() {
        return this.#tracer.piece.index;
    }

    /**
     * Get the index of the lap this targetable is on
     * @returns {number} The index
     */
    getLap() {
        return this.#tracer.lap;
    }

    /**
     * Set this racer to finished
     */
    setFinished() {
        this.#finished = this.#data.finalStanding;
    }

    /**
     * Make this racers model
     * @returns {Promise<THREE.Scene>} A promise that resolves with the model
     */
    makeModel() {
        return new Promise(resolve => {
            Globals.MODEL_CACHE.load(this.#data.urlShipModel).then(ship => {
                ship = ship.scene.clone();

                ship.traverse(node => {
                    if (node instanceof THREE.Mesh) {
                        node.castShadow = true;
                        node.rotateY(Math.PI * -.5);

                        if (this.#material)
                            node.material = this.#material;
                        else {
                            node.material.envMapIntensity = Racer.ENV_MAP_INTENSITY;
                            node.material.aoMap = null; // Some AO maps seem invalid!

                            this.#material = node.material;
                        }
                    }
                });

                ship.renderOrder = RaceRenderOrder.ORDER_SHIPS;

                resolve(ship);
            });
        });
    }

    /**
     * Load the model for this racer
     * @param {WebGLRenderer} renderer The renderer
     * @returns {Promise} A promise which resolves when the racer was loaded
     */
    load(renderer) {
        return new Promise(resolve => {
            this.makeModel().then(ship => {
                this.#ship = ship;
                this.#group.add(this.#ship);

                if (this.#material && this.#material.map && this.#data.glitched)
                    new RacerGlitcher(this.#material.map).glitch(renderer).then(glitched => {
                        this.#glitchedTextures = glitched;

                        resolve();
                    });
                else
                    resolve();
            });
        });
    }

    /**
     * Glitch the texture
     */
    glitch() {
        this.#material.map = this.#glitchedTextures[Math.floor(Math.random() * this.#glitchedTextures.length)];
    }

    /**
     * Seek to a specific race time
     * @param {number} time The time in seconds
     */
    seek(time) {
        this.#finished = -1;

        this.#moved = this.#movementSampler.sample(time);
        this.#tracer.set(this.#moved);
    }

    /**
     * Update the racer state
     * @param {number} time The time
     */
    update(time) {
        this.#positionPrevious.copy(this.#position);
        this.#yawPrevious = this.#yaw;
        this.#rollPrevious = this.#roll;

        /* Setup Aggression Boost Check */
        this.boostPrevious = this.#aggressionBoost;
        this.#aggressionBoost = this.#movementSampler.boostingCheck(time);

        if (this.#aggressionBoost !== this.boostPrevious)
            this.#trailColorUpdate = 1;

        this.#accelerationPrevious = this.#acceleration;
        this.#speedPrevious = this.#speed;
        this.#movedPrevious = this.#moved;
        
        this.#moved = this.#movementSampler.sample(time);

        if (this.#moved !== -1) {
            this.#movedLast = this.#moved;
            this.#speed = this.#moved - this.#movedPrevious;
            this.#acceleration = this.#speed - this.#speedPrevious;
        }

        this.#tracer.advance(this.#speed);

        this.#yaw = this.#tracer.trace(this.#position, this.#offset);
        this.#position.y += Math.sin(Math.PI * 2 * ((this.#movedLast * Racer.ALTITUDE_PHASE + this.#altitudeShiftOffset) % 1)) * Racer.ALTITUDE_SHIFT;
        this.#tracer.trace(
            this.#positionTarget,
            this.#offset,
            Math.max(Racer.EPSILON, this.#speed * Racer.LOOK_AHEAD_TURN));

        this.#yaw = Math.atan2(
            this.#position.z - this.#positionTarget.z,
            this.#positionTarget.x - this.#position.x);

        this.#tracer.trace(
            this.#positionTarget,
            this.#offset,
            Math.max(Racer.EPSILON, this.#speed * Racer.LOOK_AHEAD_BANK));

        this.#roll = -Interpolation.radiansDelta(
            Math.atan2(
                this.#position.z - this.#positionTarget.z,
                this.#positionTarget.x - this.#position.x),
            this.#yaw) * Racer.BANK_MAGNITUDE;

        this.#directionPrevious.copy(this.#direction);
        this.#direction.copy(this.#position).sub(this.#positionPrevious).normalize();
    }

    /**
     * Check if the racer just entered a turn
     * @returns {boolean} True if the racer just entered a turn
     */
    get enteredTurn() {
        if (Math.abs(this.#roll) > Math.abs(this.#rollPrevious)) {
            if (!this.#turning && Math.abs(this.#roll) > Racer.ROLL_TURN_THRESHOLD) {
                this.#turning = true;

                return true;
            }
        }
        else
            this.#turning = false;

        return false;
    }

    /**
     * Check if this racer finished this frame
     * @param {number} trackLength The total track length
     * @returns {Boolean} True if it did
     */
    hasJustFinished(trackLength) {
        return this.#movedPrevious < trackLength && this.#moved > trackLength;
    }

    /**
     * Play boost audio
     * @param {Audio} audio The audio object
     */
    playBoost(audio) {
        const sound = audio.getAudio(Audio.SFX_BOOST);
        const id = sound.play();

        sound.pannerAttr({
            refDistance: 25,
            rolloffFactor: 2
        }, id);
        sound.pos(
            this.#group.position.x,
            this.#group.position.y,
            this.#group.position.z,
            id);
        sound.orientation(
            this.#directionRender.x,
            this.#directionRender.y,
            this.#directionRender.z,
            id);

        this.#soundBoost = [sound, id];

        setTimeout(() => {
            this.#soundBoost = null;
        }, sound.duration(id));
    }

    /**
     * Play positional turn audio
     * @param {Audio} audio The audio object
     */
    playTurnAudio(audio) {
        const sound = audio.getAudio(Audio.SFX_SHIP_FLY_BY);
        const id = sound.play();

        sound.pannerAttr({
            refDistance: 25,
            rolloffFactor: 2
        }, id);
        sound.pos(
            this.#group.position.x,
            this.#group.position.y,
            this.#group.position.z,
            id);
        sound.orientation(
            this.#directionRender.x,
            this.#directionRender.y,
            this.#directionRender.z,
            id);

        this.#soundFlyBy = [sound, id];

        setTimeout(() => {
            this.#soundFlyBy = null;
        }, sound.duration(id));
    }

    /**
     * Update sounds
     * @param {number} delta The time delta
     */
    updateSounds(delta) {
        if (this.#soundFlyBy) {
            this.#soundFlyBy[0].pos(
                this.#group.position.x,
                this.#group.position.y,
                this.#group.position.z,
                this.#soundFlyBy[1]);
            this.#soundFlyBy[0].orientation(
                this.#directionRender.x,
                this.#directionRender.y,
                this.#directionRender.z,
                this.#soundFlyBy[1]);
        }

        if (this.#soundBoost) {
            this.#soundBoost[0].pos(
                this.#group.position.x,
                this.#group.position.y,
                this.#group.position.z,
                this.#soundBoost[1]);
            this.#soundBoost[0].orientation(
                this.#directionRender.x,
                this.#directionRender.y,
                this.#directionRender.z,
                this.#soundBoost[1]);
        }

        this.updateEngineSound(
            this.#direction.dot(this.#directionPrevious) > .999999,
            Racer.RATE_SPEED * delta);
    }

    /**
     * Render a frame
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {number} delta The time delta
     * @param {Blip} blip The blip to update
     * @param {Audio} audio The audio object
     */
    render(time, delta, blip, audio) {
        if (this.#glitchedTextures)
            this.glitch();

        Interpolation.lerpVector(this.#group.position, this.#positionPrevious, this.#position, time);
        Interpolation.lerpVector(this.#directionRender, this.#directionPrevious, this.#direction, time);

        const yaw = Interpolation.lerpRadians(this.#yawPrevious, this.#yaw, time);

        blip?.setPosition(this.#group.position.x, this.#group.position.z, -180 * yaw / Math.PI);

        this.#rotation.set(
            Interpolation.lerpRadians(this.#rollPrevious, this.#roll, time),
            Math.PI + yaw,
            0,
            "YXZ");

        this.#group.quaternion.setFromEuler(this.#rotation);

        this.#turbulence.apply(this.#group.position, this.#group.quaternion, Interpolation.lerp(this.#movedPrevious, this.#moved, time));

        /* Update Trail Color If Boosting Changing On of Off */
        if(this.#trailColorUpdate === 1) {
            if (this.#aggressionBoost)
                this.playBoost(audio);

            this.#trail.showAggressionBoost(this.#aggressionBoost);
            this.#trailColorUpdate = 0;
        }

        this.#trail.moveHead(this.#group.position, this.#group.quaternion);
        this.#trail.flare.setIntensity(
            Interpolation.lerp(this.#speedPrevious, this.#speed, time),
            Interpolation.lerp(this.#accelerationPrevious, this.#acceleration, time));

        this.updateSounds(delta);
    }
}