export class RaceElements {
    /**
     * Construct the race HTML element to render in
     * @param {HTMLCanvasElement} canvas The canvas element to render in
     * @param {HTMLElement} [minimap] The minimap element, or null if this is the track designer
     * @param {HTMLElement} [players] The player pointers element, or null if this is the track designer
     * @param {HTMLElement} [leaderboard] The leaderboard element, or null if this is the track designer
     * @param {HTMLElement} [lapInfo] The lap info element, or null if this is the track designer
     * @param {HTMLElement} [timeInfo] The time info element, or null if this is the track designer
     */
    constructor(
        canvas,
        minimap = null,
        players = null,
        leaderboard = null,
        lapInfo = null,
        timeInfo = null) {
        this.canvas = canvas;
        this.minimap = minimap;
        this.players = players;
        this.leaderboard = leaderboard;
        this.lapInfo = lapInfo;
        this.timeInfo = timeInfo;
    }
}