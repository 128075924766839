import { React, useEffect, useState} from "react";
import "./App.css";

import { ethers } from "ethers";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { updateLocation, signupLoading, updatePageHash } from "./redux/App/actions";
import { 
  WalletHeader, 
  GlobalHeaderGrey, 
  GlobalHeaderAdmin, 
  GlobalHeaderPublic,
  GlobalHeaderBar,
  GlobalHeaderLearn,
  MusicPlayer,
  LoginLoading, 
  LoginCheck, 
  NotificationsGlobal,
  GlobalInfoboxes } from "./components/Organisms/";
import {
  Landing,
  Staging,
  WorkshopHome,
  Race,
  RaceTrack,
  TrackBuilder,
  TrackUploader,
  ShipViewer,
  ShipChecker,
  Admin,
  MintPack,
  Mint,
  MintShow,
  WalletSync,
  PreLaunchSplash,
  MintLaunchSplash,
  PretrainingSplash,
  TrainingSplash,
  SignupFlow,
  SingleSignonFlow,
  SignupPickem,
  Splash,
  SplashEvent,
  RacesHome,
  RaceLanding,
  Sportsbook,
  LeaderboardOpen,
  PubRaceLanding,
  PickemHost,
  TrophyCase,
  Terms,
  RaceTrackRetro,
  LearnGameplay,
  LearnFactions,
  LearnItems
} from "./components/Pages";
import $ from "jquery";
function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

let backbutton = 'N';
let pagehash = '';
let hashcheckfunc = 0;
let finallocation = '';
let pageReloading = 0;

function App() {
  const dispatch = useDispatch();
  const { active, chainId, library, error, account, deactivate, activate } = useWeb3React();
  const { location, page_hash, signup_loading, game_loading } = useSelector((state) => state.app);
  const [finalPage, setFinalPage] = useState(location);
  
  var hashcheck = window.location.href.split("/");

  useEffect(() => {  
    window.addEventListener("hashchange",
    function(){ 
      if(pagehash !== window.location.hash) {
        reloadPage();
      }
    }, false);
    window.onpopstate = e => {
      if(pagehash !== window.location.hash) {
        reloadPage();
      }
    }
  }, []);

  /* Check For Changes in Signup Loading */
  useEffect(() => {
    if (window !== undefined) {
      if(signup_loading === true) {
        window.$("#signuploadingscreen").fadeIn();
      } else {
        window.$("#signuploadingscreen").hide();
      }
    }
  }, [signup_loading]);

  if((!location)||(location == '')||(hashcheck[hashcheck.length-1] == 'reload')||(window.pageRefresh == 1)) {
    reloadPage();
  }

  function checkForNewHash() {
    var hashcheck2 = window.location.hash;
    var locationcheck = hashToPageRouter();
    if (hashcheck2.length == 0) {
      hashcheck2 = '';
    }
    if(pageReloading == 0) {
      if(hashcheck2 != pagehash) {
        pageReloading = 1;
        console.log('Hash Mismatch');
        setTimeout(function(){
          reloadPage();
        },2500);
      } else if(locationcheck != finallocation) {
        pageReloading = 1;
        //console.log('Location Mismatch');
        setTimeout(function(){
          reloadPage();
        },2500);
      }
    }
  }
  if(hashcheckfunc == 0) {
    hashcheckfunc = 1;
    setInterval(checkForNewHash,50);
  }

  function reloadPage() {
    pageReloading = 1;
    finallocation = hashToPageRouter();
    if(finallocation != '') {
      if(window.location.href.indexOf("reload") >= 0) {
        window.location.href= window.location.href.replace("reload","");
      }
      dispatch(updateLocation(finallocation));
      window.pageRefresh = 0;
    }
    setTimeout(function(){pageReloading=0;},2000);
  }

  function hashToPageRouter() {
    var page_name = "";
    pagehash = window.location.hash;
    if (pagehash.length > 1) {
      page_name = pagehash.replace("#", "");
    } else {
      pagehash = '';
    }

    let finallocation2 = '';
    if(page_name.includes("workshop")) {
      finallocation2 = "workshop";
    } else if (page_name.includes("watch")) {
      finallocation2 = "race_track";
    } else if (page_name.includes("race_track")) {
      finallocation2 = "race_track";
    } else if (page_name.includes("raceway")) {
      finallocation2 = "raceway";
    } else if (page_name.includes("sportsbook")) {
      finallocation2 = "raceway";
    } else if (page_name.includes("arena")) {
      finallocation2 = "raceway";
    } else if  (page_name.includes("open_leaderboard")) {
      finallocation2 = "open_leaderboard";
    } else if  (page_name.includes("pickem")) {
      finallocation2 = "pickem";
    } else if  (page_name.includes("rewards")) {
      finallocation2 = "rewards";
    } else if  (page_name.includes("trophy_case")) {
      finallocation2 = "rewards";
    } else if  (page_name.includes("achievements")) {
      finallocation2 = "rewards";
    } else if (page_name.includes("race/invite")) {
      finallocation2 = "race";
    } else if (page_name.includes("invite")) {
      finallocation2 = "invite";
    } else if (page_name.includes("register")) {
      finallocation2 = "register";
    } else if (page_name.includes("signin")) {
      finallocation2 = "signin";
    } else if(page_name.includes("gameplay")) {
      finallocation2 = "gameplay";
    } else if(page_name.includes("factions")) {
      finallocation2 = "factions";
    } else if(page_name.includes("gameitems")) {
      finallocation2 = "gameitems";
    } else if (page_name.includes("pub_race")) {
      finallocation2 = "pub_race";
    } else if (page_name.includes("test_track")) {
      finallocation2 = "test_track";
    } else if (page_name.includes("track_builder")) {
      finallocation2 = "track_builder";
    } else if (page_name.includes("track_uploader")) {
      finallocation2 = "track_uploader";
    } else if (page_name.includes("ship_viewer")) {
      finallocation2 = "ship_viewer";
    } else if (page_name.includes("ship_checker")) {
      finallocation2 = "ship_checker";
    } else if (page_name.includes("retro_track")) {
      finallocation2 = "retro_track";
    } else if (page_name.includes("racer_home")) {
      finallocation2 = "racer_home";
    } else if (page_name.includes("races_home")) {
      finallocation2 = "races_home";
    } else if (page_name.includes("race_landing")) {
      finallocation2 = "race_landing";
    } else if (
      page_name.includes("race") ||
      page_name.includes("race_details") ||
      page_name.includes("race_entry") ||
      page_name.includes("race_lineup") ||
      page_name.includes("race_results") ||
      page_name.includes("race_predict")
    ) {
      finallocation2 = "race";
    } else if (page_name.includes("send_mintpack")) {
      finallocation2 = "admin";
    } else if (page_name.includes("mint_launch")) {
      finallocation2 = "mint_launch";
    } else if (page_name.includes("mint")) {
      finallocation2 = "mint_show";
    } else if (page_name.includes("prelaunch")) {
      finallocation2 = "prelaunch";
      finallocation2 = "splash";
    } else if (page_name.includes("terms")) {
      finallocation2 = "terms";
    } else if (page_name.includes("pretraining")) {
      finallocation2 = "pretraining";
    } else if (page_name.includes("training_splash")) {
      finallocation2 = "training_splash";
    } else if (page_name.includes("single_signon")) {
      finallocation2 = "single_signon";
    } else if (page_name.includes("signup")) {
      finallocation2 = "signup";
    } else if (page_name.includes("splash")) {
      finallocation2 = "splash";
    } else {
      finallocation2 = "splash";
    }

    return finallocation2;
  }

  // TODO: replace with proper navigation
  const router_training = {
    landing: <Landing />,
    test_track: <RaceTrack />,
    retro_track: <RaceTrackRetro />,
    staging: <Staging />,
    track_builder: <TrackBuilder />,
    track_uploader: <TrackUploader />,
    ship_viewer: <ShipViewer />,
    ship_checker: <ShipChecker />,
    mint_pack: <MintPack />,
    mint: <Mint />,
    admin: <Admin />,
    mint_show: <MintShow />,
    wallet_sync: <WalletSync />,
    prelaunch: <PreLaunchSplash />,
    pretraining: <PretrainingSplash />,
    training_splash: <TrainingSplash />,
    mint_launch: <MintLaunchSplash />,
    single_signon: <SingleSignonFlow />,
    signin: <SingleSignonFlow />,
    register: <SingleSignonFlow />,
    invite: <SingleSignonFlow />,
    signup: <SignupFlow />,
    splash: <Splash />,
    workshop: <WorkshopHome />,
    racer_home: <WorkshopHome />,
    race: <RaceLanding />,
    race_track: <RaceTrack />,
    races_home: <RacesHome />,
    race_landing: <RaceLanding />,
    raceway: <Sportsbook />,
    sportsbook: <Sportsbook />,
    arena: <Sportsbook />,
    open_leaderboard: <LeaderboardOpen />,
    pub_race: <PubRaceLanding />,
    pickem: <PickemHost />,
    trophy_case: <TrophyCase />,
    rewards: <TrophyCase />,
    watch: <RaceTrack />,
    terms: <Terms />,
    gameplay: <LearnGameplay />,
    gameitems: <LearnItems />,
    factions: <LearnFactions />
  };

  const router_prod = {
    wallet_sync: <WalletSync />,
    single_signon: <SingleSignonFlow />,
    signin: <SingleSignonFlow />,
    register: <SingleSignonFlow />,
    invite: <SingleSignonFlow />,
    splash: <Splash />,
    workshop: <WorkshopHome />,
    racer_home: <WorkshopHome />,
    race: <RaceLanding />,
    track_builder: <TrackBuilder />,
    track_uploader: <TrackUploader />,
    race_track: <RaceTrack />,
    races_home: <RacesHome />,
    race_landing: <RaceLanding />,
    raceway: <Sportsbook />,
    sportsbook: <Sportsbook />,
    arena: <Sportsbook />,
    open_leaderboard: <LeaderboardOpen />,
    pub_race: <PubRaceLanding />,
    pickem: <PickemHost />,
    trophy_case: <TrophyCase />,
    rewards: <TrophyCase />,
    watch: <RaceTrack />,
    terms: <Terms />,
    gameplay: <LearnGameplay />,
    gameitems: <LearnItems />,
    factions: <LearnFactions />
  };

  const router_pickem = {
    signup: <SignupPickem />,
    signin: <SignupPickem />,
    splash: <Splash />,
    race_track: <RaceTrack />,
    pub_race: <PubRaceLanding />,
    watch: <RaceTrack />,
    terms: <Terms />,
    gameplay: <LearnGameplay />,
    gameitems: <LearnItems />,
    factions: <LearnFactions />
  };

  let wallet_pages = ['splash','prelaunch','signup','mint_launch','admin']
  let music_pages = ['workshop','racer_home','races_home','race_landing','race_track','race','watch'];
  let grey_pages = ['workshop','racer_home','races_home','race_landing','ship_checker','race'];
  let spectator_pages = ['arena','sportsbook','open_leaderboard','raceway','trophy_case','achievements','rewards'];
  let bar_pages = ['pub_race','pub_leaderboard'];
  let learn_pages = ['gameplay','factions','gameitems'];
  
  return (
    <div>
      <LoginLoading />
      <LoginCheck />
      {(() => {
        
        if(spectator_pages.indexOf(location) >= 0) {
          return(<><GlobalHeaderPublic currpage={location} /></>);
        } else if (bar_pages.indexOf(location) >= 0) {
          return(<><GlobalHeaderBar currpage={location} /></>);
        } else if(grey_pages.indexOf(location) >= 0) {
          // return(<><GlobalHeaderGrey currpage={location} /><NotificationsGlobal currpage={location} /></>);
          return(<><GlobalHeaderGrey currpage={location} /></>);
        } else if(learn_pages.indexOf(location) >= 0) {
            return(<><GlobalHeaderLearn currpage={location} /></>);
        } else if(location === 'track_uploader') {
            return(<GlobalHeaderAdmin currpage={location} />);
        }
        // if (bar_pages.indexOf(location) >= 0) {
        //   return(<><GlobalHeaderBar currpage={location} /></>);
        // } 
      })()}
      {/* {router_training[location]} */}
      {/* {router_pickem[location]} */}
      {router_prod[location]}
      {/* <PretrainingSplash /> */}
      {/* <RaceTrack />  */}
      {(() => {
        if(music_pages.indexOf(location) >= 0) {
          return(<MusicPlayer volume={"50"} />);
        }
      })()}
      <GlobalInfoboxes />
    </div>
  );
}

export default App;