import React, { useState, forwardRef } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  display: block;
  width: 300px;
  height: 40px;
  margin: 20px 0px;
  border: 1px solid lightblue;
`;

// TODO: should do this without using a ref
export const TextInput = forwardRef((props, ref) => {
  const [value, setValue] = useState("");
  const { placeholder } = props;

  const handleChange = (e) => {
    setValue(e.target.value);
    if (e.keyCode === 13) console.log("enter");
  };

  return (
    <StyledInput
      ref={ref}
      onChange={handleChange}
      placeholder={placeholder}
      type="text"
      value={value}
    />
  );
});
