import {CameraControllerOrientation} from "./camera/controllers/orientation/cameraControllerOrientation.js";
import {CameraControllerFinish} from "./camera/controllers/finish/cameraControllerFinish.js";
import * as THREE from "three";
import {CameraControllerLeaderboard} from "./camera/controllers/leaderboard/cameraControllerLeaderboard.js";
import {CameraControllerOrbit} from "./camera/cameraControllerOrbit.js";
import {CameraControllerCinematic} from "./camera/controllers/cinematic/cameraControllerCinematic.js";
import {CameraControllerGenerator} from "./camera/controllers/cinematic/generator/cameraControllerGenerator.js";
import {CameraControllerChase} from "./camera/controllers/chase/cameraControllerChase.js";
import {CameraAnchorRails} from "./camera/controllers/cinematic/anchors/cameraAnchorRails.js";
import {Random} from "../../exr-webgl-hub/math/random.js";
import {CameraControllerIntroduction} from "./camera/controllers/introduction/cameraControllerIntroduction.js";
import {CameraControllerTransition} from "./camera/controllers/transition/cameraControllerTransition.js";
import {CameraMode} from "./camera/cameraMode.js";
import {Interpolation} from "../../exr-webgl-hub/math/interpolation.js";
import {CameraControllerStage} from "./camera/controllers/stage/cameraControllerStage.js";
import {CameraControllerLineup} from "./camera/controllers/lineup/cameraControllerLineup.js";
import {EffectStats} from "./effectStats.js";
import {Audio} from "./audio/audio.js";

export class RaceCameras {
    static CAMERA_INTRODUCTION_SPEED = 6;
    static CAMERA_TRANSITION_SPEED = 1.45;
    static CAMERA_INTRO_DURATION = RaceCameras.CAMERA_INTRODUCTION_SPEED + RaceCameras.CAMERA_TRANSITION_SPEED;
    static CAMERA_INTRO_COUNTDOWN = 5;
    static CAMERA_CLOSE_UP_FADE = 500;
    static CAMERA_CLOSE_UP_THRESHOLD = 32;
    static CAMERA_TURN_SOUND_THRESHOLD = 75;
    static FLY_BY_COOL_DOWN = .8;

    #audio;
    #audioChase = false;
    #flyByCoolDown = 0;

    /**
     * Construct the race cameras
     * @param {Camera} camera The camera to control
     * @param {Environment} environment The environment
     * @param {Track} track The track
     * @param {HTMLElement} element The element to listen for input on
     * @param {Racers} racers The racers, or null if this is a track builder
     * @param {Stage} stage The stage
     * @param {Audio} audio The audio
     */
    constructor(
        camera,
        environment,
        track,
        element,
        racers,
        stage,
        audio) {
        const random = new Random();

        this.#audio = audio;

        this.ended = false;
        this.lineup = false;
        this.intro = true; // TODO: Enum this
        this.racers = racers;
        this.stage = stage;
        this.track = track;
        this.camera = camera;
        this.environment = environment;
        this.cameraIndex = 0;
        this.cameraStart = new CameraControllerOrientation(camera, environment);
        this.cameraFinish = new CameraControllerFinish(
            camera,
            new THREE.Vector3(10, 10, 0),
            new THREE.Vector3(),
            60);
        this.cameraLeaderboard = new CameraControllerLeaderboard(
            camera,
            track,
            environment);
        this.cameraLineup = racers ? new CameraControllerLineup(camera, racers) : null;
        this.cameraStage = new CameraControllerStage(camera, stage);
        this.cameraOrbit = racers ? null : new CameraControllerOrbit(camera, element);
        this.cameraControllers = [
            new CameraControllerCinematic(
                camera,
                new CameraControllerGenerator(
                    track,
                    new THREE.Vector3(),
                    random).generate()),
            new CameraControllerChase(camera),
            new CameraControllerCinematic(
                camera,
                [
                    new CameraAnchorRails(
                        track,
                        new THREE.Vector3(10, 40, 10), false)
                ],
                false),
            new CameraControllerOrientation(camera, environment)];

        this.cameraControllerIntroduction = new CameraControllerIntroduction(camera, racers);
        this.cameraControllerTransition = new CameraControllerTransition(camera, this.cameraControllerIntroduction, this.cameraControllers[0]);
        this.cameraTransition = this.cameraTransitionPrevious = 0;
        this.cameraMode = CameraMode.NONE;
        this.onEndIntro = null;
        this.firstFinished = false;
        this.teleportCamera = true;
        this.forcedCamera = null;

        this.addInputListeners();
    }

    /**
     * Set a forced camera controller
     * @param {CameraController | null} controller The forced camera controller, or null if none should be forced
     */
    setForcedCamera(controller) {
        this.forcedCamera = controller;
    }

    /**
     * Get an array of named selectable cameras
     * @returns {{name: string, controller: CameraController}[]}
     */
    getSelectableCameras() {
        return [
            {
                name: "Chase",
                controller: this.cameraControllers[1]
            },
            {
                name: "Helicopter",
                controller: this.cameraControllers[2]
            },
            {
                name: "Overview",
                controller: this.cameraControllers[3]
            },
            {
                name: "Backwards",
                controller: new CameraControllerCinematic(
                    this.camera,
                    [
                        new CameraAnchorRails(
                            this.track,
                            new THREE.Vector3(10, 20, 0), true)
                    ],
                    true)
            },
            {
                name: "Side",
                controller: new CameraControllerCinematic(
                    this.camera,
                    [
                        new CameraAnchorRails(
                            this.track,
                            new THREE.Vector3(0, 4, 14), true)
                    ],
                    true)
            }
        ];
    }

    /**
     * Update the state
     * @param {number} delta The time delta
     */
    update(delta) {
        this.cameraTransitionPrevious = this.cameraTransition;

        let introProgress = 0;

        switch (this.cameraMode) {
            case CameraMode.INTRO:
                introProgress = this.cameraTransition * RaceCameras.CAMERA_INTRODUCTION_SPEED;

                if ((this.cameraTransition += delta / RaceCameras.CAMERA_INTRODUCTION_SPEED) > 1) {
                    this.cameraTransition = this.cameraTransitionPrevious = 0;

                    this.cameraMode = CameraMode.TRANSITION;

                    this.#audio.getAudio(Audio.SFX_RACE_START).play();
                }

                break;

            case CameraMode.TRANSITION:
                introProgress = RaceCameras.CAMERA_INTRODUCTION_SPEED + this.cameraTransition * RaceCameras.CAMERA_TRANSITION_SPEED;

                if ((this.cameraTransition += delta / RaceCameras.CAMERA_TRANSITION_SPEED) > 1) {
                    this.onEndIntro();

                    this.cameraMode = CameraMode.GAME;
                }

                break;
            default:
                introProgress = RaceCameras.CAMERA_INTRO_DURATION;

                break;
        }

        EffectStats.startCountdown = 1 - Math.min(
            1,
            Math.max(
                0,
                introProgress - (RaceCameras.CAMERA_INTRO_DURATION - RaceCameras.CAMERA_INTRO_COUNTDOWN)) / RaceCameras.CAMERA_INTRO_COUNTDOWN);

        this.cameraOrbit?.update();

        if (this.racers)
            this.cameraController.update(delta);
    }

    /**
     * Start chase audio
     */
    startChaseAudio() {
        this.#audio.getAudio(Audio.AMB_CHASE).fade(0, 1, RaceCameras.CAMERA_CLOSE_UP_FADE);
        this.#audio.getAudio(Audio.AMB_CHASE).play();

        this.#audioChase = true;
    }

    /**
     * Stop chase audio
     */
    stopChaseAudio() {
        if (this.#audioChase) {
            this.#audio.getAudio(Audio.AMB_CHASE).fade(1, 0, RaceCameras.CAMERA_CLOSE_UP_FADE);

            setTimeout(() => {
                if (!this.#audioChase)
                    this.#audio.getAudio(Audio.AMB_CHASE).stop();
            }, RaceCameras.CAMERA_CLOSE_UP_FADE);

            this.#audioChase = false;
        }
    }

    /**
     * Render
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {number} delta The time delta
     */
    render(time, delta) {
        this.#flyByCoolDown = Math.max(this.#flyByCoolDown - delta, 0);

        switch (this.cameraMode) {
            case CameraMode.INTRO:
                this.cameraControllerIntroduction.setTime(Interpolation.lerp(
                    this.cameraTransitionPrevious,
                    this.cameraTransition,
                    time));

                break;
            case CameraMode.TRANSITION:
                this.cameraControllerTransition.setInterpolation(Interpolation.lerp(
                    this.cameraTransitionPrevious,
                    this.cameraTransition,
                    time));

                break;
        }

        if (this.cameraOrbit)
            this.cameraOrbit.render(time);
        else if (this.racers && this.racers.player.group) {
            const controller = this.cameraController;
            const target = controller.targetPlayer ? this.racers.tracking : this.racers.leader;
            const targetDistance = target.getPosition(time).distanceTo(this.camera.position);

            controller.render(time);
            controller.setTarget(
                target,
                this.racers.racers,
                this.track,
                time,
                this.teleportCamera);

            for (let racer = 0, racerCount = this.racers.racers.length; racer < racerCount; ++racer) {
                const current = this.racers.racers[racer];
                const distance = current.getPosition(time).distanceTo(this.camera.position);

                if (current === target) {
                    if (distance < RaceCameras.CAMERA_CLOSE_UP_THRESHOLD) {
                        if (!this.#audioChase)
                            this.startChaseAudio();
                    }
                    else if (this.#audioChase)
                        this.stopChaseAudio();

                    if (target.enteredTurn && distance < RaceCameras.CAMERA_TURN_SOUND_THRESHOLD)
                        this.#audio.getAudio(Audio.SFX_SHIP_FLY_BY_FOCUS).play();
                } else if (
                    distance < RaceCameras.CAMERA_TURN_SOUND_THRESHOLD &&
                    this.#flyByCoolDown === 0 &&
                    distance > targetDistance &&
                    current.enteredTurn) {
                    current.playTurnAudio(this.#audio);

                    this.#flyByCoolDown = RaceCameras.FLY_BY_COOL_DOWN;
                }
            }

            this.teleportCamera = false;

            if (this.racers.racers[0].group !== null)
                this.environment.lights.setShadowFocus(this.racers.racers[0].group.position);
        }
    }

    /**
     * Add input listeners
     */
    addInputListeners() {
        window.addEventListener("keydown", event => {
            switch (event.key) {
                case "v":
                    switch (this.cameraMode) {
                        case CameraMode.INTRO:
                        case CameraMode.TRANSITION:
                            this.cameraTransitionPrevious = this.cameraTransition = 1;

                            break;
                        case CameraMode.GAME:
                            this.nextCamera();

                            break;
                    }

                    break;
            }
        });
    }

    /**
     * Move to the next camera
     */
    nextCamera() {
        this.cameraIndex = (this.cameraIndex + 1) % this.cameraControllers.length;
        this.teleportCamera = true;

        if (this.lineup)
            this.cameraLineup.next();
    }

    /**
     * Start the intro
     * @param {function} onEndIntro A function to call when the intro has ended
     */
    startIntro(onEndIntro) {
        this.onEndIntro = onEndIntro;
        this.cameraMode = CameraMode.INTRO;
        this.lineup = false;
    }

    /**
     * End the intro mode
     */
    endIntro() {
        this.intro = false;
    }

    /**
     * Set the race to ended
     */
    setEnded() {
        this.ended = true;
    }

    /**
     * Active the lineup cam
     */
    setLineup() {
        this.lineup = true;
    }

    /**
     * Set a different camera type
     * @param {string} name The name of the camera type
     */
    userCameraSelection(name) {
        if ((name === 'Chase') || (name === 'chase')) {
            this.cameraTransitionPrevious = this.cameraTransition = 1;
            this.cameraIndex = 1;
        } else if((name === 'Leader') || (name === 'leader')) {
            this.cameraTransitionPrevious = this.cameraTransition = 2;
            this.cameraIndex = 2;
        } else
            this.cameraIndex = 0;
    }

    /**
     * Get the current camera controller
     * @returns {CameraController} The current camera controller
     */
    get cameraController() {
        // TODO: Use switch & state
        if (this.lineup)
            return this.cameraLineup;

        if (this.ended)
            return this.cameraStage;

        if (this.intro) {
            switch (this.cameraMode) {
                case CameraMode.INTRO:
                    return this.cameraControllerIntroduction;
                case CameraMode.TRANSITION:
                case CameraMode.GAME:
                    return this.cameraControllerTransition;
                default:
                    return this.cameraStart;
            }
        }

        if (this.firstFinished || this.racers.first.moved === -1 || this.racers.first.moved > this.track.length * this.track.laps - this.cameraFinish.distance) {
            this.firstFinished = true;

            return this.cameraFinish;
        }

        if (this.forcedCamera)
            return this.forcedCamera;

        return this.cameraControllers[this.cameraIndex];
    }
}