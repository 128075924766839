import Web3 from "web3";

import { ETH_NETWORK_IDS } from "../constants";
import { convertHexToNumber } from "./web3.helper";

export * from "./localStorage";
export * from "./web3.helper";
export * from "./axios.helper"
export * from "./login.helper"
export * from "./tokenGating.helper"
export * from "./indexer.helper"

//export * from "./exiled"

export const shortenAddress = (address) => {
  return `${address.slice(0, 4)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
};

export const humanReadableNetwork = (network) => {
  const networkNumber = convertHexToNumber(network);
  return ETH_NETWORK_IDS[networkNumber];
};

export function closeAllModals() {
  var modalelems = document.querySelectorAll('.modal');
  for(var i = 0; i < modalelems.length;i++) {
      var modal_instance = window.M.Modal.getInstance(modalelems[i]);
      if(modal_instance) {
          modal_instance.close();
      }
  }
}