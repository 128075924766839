import * as THREE from "three";
import {RaceRenderOrder} from "../../../raceRenderOrder.js";

/**
 * The racer engine flare
 */
export class RacerFlare {
    static RADIUS_SPEED = .85;
    static RADIUS_ACCELERATION = 16;
    static RADIUS_MAX = 5;
    static RANDOM_SCALE = .1;
    static SHADER_VERTEX = `
        #define DISTANCE_FACTOR 20.
        
        uniform float radius;
        uniform vec3 center;
        
        varying vec2 vUv;

        void main() {
            vUv = uv;

            vec4 finalPosition = modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0);
            float distanceScale = sqrt(length(center - cameraPosition) / DISTANCE_FACTOR);
            
            finalPosition.xy += position.xy * radius * distanceScale;

            gl_Position = projectionMatrix * finalPosition;
        }

        `;
    static SHADER_FRAGMENT = `
        #define GLOW_POWER .8
        #define CORE_RADIUS_POWER 1.7
        #define TRANSPARENCY .65
        
        uniform vec3 color;
    
        varying vec2 vUv;
        
        void main() {
            vec2 delta = vUv - .5;
            float radius = length(delta);
            float spike = 1.;
            float alpha = radius * -2. + 1. + pow(radius * -2. + 1., CORE_RADIUS_POWER);
        
            gl_FragColor = vec4(mix(vec3(1.), color, pow(radius + radius, GLOW_POWER)), alpha * alpha * TRANSPARENCY);
        }
        `;

    #color = 0xff0000;
    #radius = {value: 0};
    
    /**
     * Construct a racer engine flare
     */
    constructor() {
        this.position = new THREE.Vector3();
        this.sprite = new THREE.Sprite(new THREE.ShaderMaterial({
            uniforms: {
                radius: this.#radius,
                color: new THREE.Uniform(new THREE.Color(this.#color)),
                center: new THREE.Uniform(this.position)
            },
            vertexShader: RacerFlare.SHADER_VERTEX,
            fragmentShader: RacerFlare.SHADER_FRAGMENT,
            transparent: true,
            depthWrite: false,
            blending: THREE.AdditiveBlending,
        }));

        this.sprite.renderOrder = RaceRenderOrder.ORDER_FLARES;
    }

    /**
     * Set the flare intensity
     * @param {number} speed The racer speed
     * @param {number} acceleration The racer acceleration
     */
    setIntensity(speed, acceleration) {
        this.#radius.value = Math.min(RacerFlare.RADIUS_MAX, Math.max(
            0,
            (speed * RacerFlare.RADIUS_SPEED + Math.max(acceleration, 0) * RacerFlare.RADIUS_ACCELERATION) *
                (1 + Math.random() * RacerFlare.RANDOM_SCALE)));

        this.position.copy(this.sprite.position);
    }
}