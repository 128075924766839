/**
 * An object targetable by a camera controller
 */
export class CameraTargetable {
    /**
     * Get the position of the targetable
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {Vector3} The position
     */
    getPosition(time) {
        return null;
    }

    /**
     * Get the quaternion of this targetable
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {Quaternion} The quaternion
     */
    getQuaternion(time) {
        return null;
    }

    /**
     * Get the direction of the targetable
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {Vector3} The normalized direction
     */
    getDirection(time) {
        return null;
    }

    /**
     * Get the distance of the targetable on the track
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {number} The distance
     */
    getDistance(time) {
        return -1;
    }

    /**
     * Get the index of the track piece this targetable is on
     * @returns {number} The index
     */
    getTrackPieceIndex() {
        return -1;
    }

    /**
     * Get the index of the lap this targetable is on
     * @returns {number} The index
     */
    getLap() {
        return -1;
    }
}