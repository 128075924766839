import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import "../../../../splash_event.css";
import { setUserSignOn, setLoginWallet } from "../../../../../../../redux/Account/actions";
import {validateEmailText, sendEventEmailCode, checkEventEmailCode, addSpectatorInfo, addWalletInfo, connectTalisman, connectMetamask} from "../../../../Utilities"
import {getEventUserInfo} from "../../../../Utilities"
import {axiosInstance } from "../../../../../../../utils";

import $ from "jquery";

let current_user_id = 0; 
let current_wallet_address = '';
let signup_racer = 'spectator';

let total_pilots_sync = 0;
let total_racecrafts_sync = 0;
let total_boosters_sync = 0;
let pilots_checked = 0;
let racecrafts_checked = 0;
let boosters_checked = 0;

export const BaseLeagueWeeklyReveal = (props) => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { chainId, account } = useWeb3React();

    /* EXR Event ID */
    var exreventid = 3;
    let league_permalink_id = 'pink-racing-league'
    let signup_user_id = 0;
    const [entrySet, setEntry] = useState(false);
    const [racecraft_choice_html, setRacecraftChoiceHTML] = useState('');
    const [pilot_choice_html, setPilotChoiceHTML] = useState('');
    const [booster_choice_html, setBoosterChoiceHTML] = useState('');

    const [item_list, setItemList] = useState([]);

    function grabStackItems() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        
        addData.raceID = encodeURIComponent(props.race_id);
        addData.sharedGarageID = encodeURIComponent(2);

        axiosInstance.post('member/items_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_item_list = result.data;
                setItemList(new_item_list); 
                var pilot_arr = []
                var racecraft_arr = []
                var booster_arr = []
                for (var z = 0; z < new_item_list.length;z++) {
                    if (new_item_list[z].item_type == 'booster') {
                        booster_arr.push(new_item_list[z]);
                    } else if (new_item_list[z].item_type == 'pilot') {
                        pilot_arr.push(new_item_list[z]);
                    } else if (new_item_list[z].item_type == 'ship') {
                        racecraft_arr.push(new_item_list[z]);
                    } else if (new_item_list[z].item_type == 'racecraft') {
                        racecraft_arr.push(new_item_list[z]);
                    }
                }

                createPageCarousels('racecraft', racecraft_arr);
                createPageCarousels('pilot', pilot_arr);
                createPageCarousels('booster', booster_arr);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function createPageCarousels(itemtype, itemarr) {
        var section_div_id = itemtype+'_selection_carousel';
        var section_html = [];
        var racer_attributes = ['speed', 'handling', 'acceleration', 'strength'];
        var pilot_attributes = ['focus', 'stamina', 'aggression', 'intelligence'];
        var booster_attributes = ['speed', 'handling', 'acceleration', 'strength', 'focus', 'stamina', 'aggression', 'intelligence'];

        for(var z = 0; z < itemarr.length;z++) {
            var item_div_id = itemtype+'_'+itemarr[z].racer_item_id;
            var item_image = itemarr[z].item_image_location;
            var item_name = itemarr[z].item_name.replace("-"," ").replace("-"," ").replace("-"," ").toUpperCase();
            if(itemtype == 'booster') {
                var item_name_split = item_name.replace("EXR ","").split(" [");
                item_name = item_name_split[0];
            }
            var item_subtitle = itemarr[z].item_subname;
            if(item_subtitle != null) {
                item_subtitle = item_subtitle.toUpperCase();
            }
            var item_attributes = itemarr[z].stats;

            var itemdata = '';
            for(var m = 0; m < item_attributes.length;m++) {
                if(m > 0) {
                    itemdata = itemdata+',';
                }
                itemdata = itemdata+item_attributes[m].stat_name.toLowerCase()+'_'+item_attributes[m].stat_value;
            }
            var item_faction_name = itemarr[z].item_faction.toUpperCase();
            if(item_faction_name == 'AUGMENT') {
                item_faction_name = 'AUG';
            } else {
                item_faction_name = item_faction_name.slice(0, 4);
            }
            var item_faction_icon = '';
            if(item_faction_name != 'ANY') {
                item_faction_icon = <img src={"https://media.exiledracers.com/global/icons/exr_"+item_faction_name.toLowerCase()+"_icon.png"} height="50px" />
            }

            var lender_racer_name = itemarr[z]['lender_racer_name'];

            var item_img_bg = "url('"+item_image+"')";

            var tmp_html = <div key={z} className="col s12 l3" id={item_div_id} style={{marginBottom:'20px'}} data-itemtype={itemtype} data-scores={itemdata} data-faction={itemarr[z].item_faction.toLowerCase()} >
                                <div className="left stack-selection-caro-box" style={{height:'47vh',width:'100%'}}>
                                    <div className="stack-selection-caro-image-box" style={{background:item_img_bg,backgroundSize:'cover',backgroundPosition:'center'}}>
                                    </div>
                                    <div className="stack-selection-caro-icon" title={itemarr[z].item_faction}>
                                        {item_faction_icon}
                                    </div>
                                </div>
                                <div className="left center-align" style={{width:'100%',marginTop:'20px'}}>
                                    <div className="row headline-text" style={{margin:'0px',padding:'0px 20px'}}>
                                        {item_name.replace("EXR ","")}
                                    </div>
                                    <div className="row subheadline-text exr-base-blue-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px'}}>
                                        {item_subtitle} &nbsp;&nbsp;///&nbsp;&nbsp;{item_faction_name}
                                    </div>
                                    <div className="row subheadline-text grey-text" style={{fontSize:'12px',margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'10px',overflow:'hidden'}}>
                                        SHARED BY: {lender_racer_name.toUpperCase()}
                                    </div>
                                </div>
                            </div>
            section_html.push(tmp_html);
        }

        if(itemtype == 'booster') {
            setBoosterChoiceHTML(section_html);
        } else if(itemtype == 'pilot') {
            setPilotChoiceHTML(section_html);
        } else if(itemtype == 'racecraft') {
            setRacecraftChoiceHTML(section_html);
        }
    }

    if(entrySet == false) {
        setEntry(true);
        grabStackItems();
    }

    return(<><div className="row" style={{display:'block',marginBottom:'10px'}}>
                {pilot_choice_html}
                {racecraft_choice_html}
                {booster_choice_html}
        </div></>);
}