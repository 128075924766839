import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

export const PilotNft = ({ address, index }) => {
  const { pilotContract } = useSelector((state) => state.web3);
  const [tokenUri, setTokenUri] = useState("");
  const [tokenId, setTokenId] = useState(null);
  const { data, error } = useSWR(tokenUri, fetcher);

  useEffect(() => {
    console.log("loading NFT for pilot index: ", index);
    getNft();
  }, [index]);

  async function getNft() {
    try {
      const id = await pilotContract.tokenOfOwnerByIndex(address, index);
      setTokenId(id.toNumber());
      console.log("PilotID: "+id.toNumber());
      const uri = await pilotContract.tokenUri(id.toNumber());
      console.log("pilot uri: ", uri);
      setTokenUri(uri);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="center-align" style={{height:'300px',width:'100%',overflow:'hidden',border:'1px solid #141414'}}>
      <img src={data?.image} width="100%"/>
    </div>
  );
};
