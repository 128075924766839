import {ClipRate} from "./clipRate.js";

export class Clip {
    start;
    end;
    cameraController;
    target;
    rate;
    onFinish;

    /**
     * Construct a clip
     * @param {number} start The absolute time the clip starts at
     * @param {number} end The absolute time the clip ends at
     * @param {CameraController} cameraController The camera controller
     * @param {CameraTargetable} target The target for the camera controller
     * @param {ClipRate} [rate] The playback rate
     * @param {function} [onFinish] A callback on finish
     */
    constructor(
        start,
        end,
        cameraController,
        target,
        rate = new ClipRate(),
        onFinish = () => {}) {
        this.start = start;
        this.end = end;
        this.cameraController = cameraController;
        this.target = target;
        this.rate = rate;
        this.onFinish = onFinish;
    }

    /**
     * Get the clip duration
     * @returns {number} The clip duration in seconds
     */
    get duration() {
        return this.end - this.start;
    }

    /**
     * Update the state
     * @param {number} delta The time delta
     */
    update(delta) {
        this.cameraController.update(delta);
    }

    /**
     * Render a frame
     * @param {Racers} racers The racers
     * @param {Track} track The track
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(racers, track, time) {
        this.cameraController.render(time);
        this.cameraController.setTarget(
            this.target,
            racers,
            track,
            time,
            false);
    }
}