import * as THREE from "three";
import {TrackPropOverride} from "./trackPropOverride.js";
import {effectForceField} from "../../../../../../exr-webgl-hub/effects/2d/effectForceField.js";

export class TrackPropOverrideForceField extends TrackPropOverride {
    static REPLACE = true;
    static NAME = "@portalForcefield";
    static SPEED = .0005;

    material = effectForceField.clone();
    time = 0;

    constructor(mesh, track, trackWidth, sponsor) {
        super(mesh);

        if (sponsor == 'pink') {
            this.material.uniforms.color = new THREE.Uniform(new THREE.Color("#c7167d"));
        } else if (sponsor == 'base') {
            this.material.uniforms.color = new THREE.Uniform(new THREE.Color("#5b9af8"));
        }

        mesh.material = this.material;

        this.group.add(mesh);
    }

    update() {
        this.time += TrackPropOverrideForceField.SPEED;
    }

    render(time) {
        this.material.uniforms.time.value = this.time + time * TrackPropOverrideForceField.SPEED;
    }
}