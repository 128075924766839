import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerXP, setRacerCoins } from "../../../redux/Account/actions";
import { setRacerLogin } from "../../../redux/Account/actions";

import {axiosInstance} from "../../../utils";
import {SettingsModal} from "../../Pages/Settings"
let web3;

export const GlobalHeaderAdmin = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     window.location.href='/';
    // }

    if((racerID.length == 0)||(accountAddress.length == 0)) {
        dispatch(setRacerLogin('',''));
        dispatch(updateLocation("prelaunch"));
        window.location.href= '/';
    }

    const [headerSet, setHeader] = useState(false);
    const [racerXP, setXP] = useState(0);
    const [racerCoins, setCoins] = useState(0);
    const [racerFirstName, setFirstName] = useState('Racer');
    const [racerHandle, setRacerHandle] = useState('Racer');

    function grabRacerInfo() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        
        axiosInstance.post('member/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;

                // Set Racer XP, Coins and Name
                setXP(racer_details[0].racer_xp);
                setCoins(racer_details[0].racer_coins);
                setFirstName(racer_details[0].first_name);
                setRacerHandle(racer_details[0].racer_name);
            }
        }).catch(error => {
            console.log(error);
            dispatch(updateLocation("prelaunch"));
            window.location.href = '/';
        });
    }

    function goToNewRace() {
        //window.location.href= 'race';
        dispatch(updateLocation("race"));
    }
    function goToWorkshop() {
        window.location.href= '/#/workshop';
        dispatch(updateLocation("workshop"));
    }
    function goToRacerHome() {
        window.location.href= '/#/racer_home';
        dispatch(updateLocation("racer_home"));
    }
    function goToRacesHome() {
        window.location.href= '/#/races_home';
        dispatch(updateLocation("races_home"));
    }
    function goToTrackBuilder() {
        dispatch(updateLocation("track_builder"));
        window.location.href= '/#/track_builder/xcCxZlxHYx';
    }
    function goToTrackUploader() {
        dispatch(updateLocation("track_uploader"));
        window.location.href= '/#/track_uploader/';
    }

    function logoutGame() {
        dispatch(setRacerLogin('',''));
        dispatch(updateLocation("prelaunch"));
        window.location.href= '/';
    }

    if(headerSet == false) {
        setHeader(true);
        grabRacerInfo();
        
    }

    window.$(document).ready(function(){
        window.startNavigationDropdown();
        window.startPageModals();
        var tooltips = document.querySelectorAll('.tooltipped');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    });

    var xp_tooltip = '<div style="width:250px;padding:10px;"><div class="row" style="padding-bottom:10px;font-size:18px;border-bottom:1px solid white;">RACER XP</div>';
    xp_tooltip = xp_tooltip +'<div class="row" style="margin-bottom:0px;">XP (or Experience Points) are earned everytime you race. XP will be needed to gain access to weekly tournaments and unlock features in game.</div></div>';

    var exr_tooltip = '<div style="width:250px;padding:10px;"><div class="row" style="padding-bottom:10px;font-size:18px;border-bottom:1px solid white;">EXR COIN</div>';
    exr_tooltip = exr_tooltip +'<div class="row" style="margin-bottom:0px;">EXR Coin is an in game currency earned when you race. EXR can be used to recharge boosters, enter weekly tournaments, unlock workshop space and stake in private races.</div></div>';

    var workshoplink = '';
    var raceslink = '';
    if((props.currpage == 'workshop')||(props.currpage == 'racer_home')) {
        workshoplink = ' white-fill-link-filled';
    } else if((props.currpage == 'race')||(props.currpage == 'races_home')) {
        raceslink = ' white-fill-link-filled';
    }

    return(<div className="row white-text page-width-wide top-nav-header-full exr-new-dark-grey">
                <nav className="transparent z-depth-0">
                <div className="col s12 transparent nav-wrapper" style={{marginTop:'10px'}}>
                        <div className="left top-nav-left-logo-full brand-logo">
                            <img src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" height="50px" className="cursor-pointer" onClick={()=>goToTrackUploader()} />
                        </div>
                        <div className="left top-nav-header-left-section hide-on-med-and-down">
 
                        </div>
                        <div className="right top-nav-header-right-section hide-on-med-and-down" style={{marginTop:'15px'}}>
                            <div className="top-nav-header-right-item-full white-fill-link" style={{lineHeight:'1.5'}} onClick={()=> logoutGame()}>
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Logout
                                </div>
                            </div>
                            <div className="top-nav-header-right-item-full white-fill-link" style={{lineHeight:'1.5'}}  onClick={()=>goToTrackUploader()} >
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Uploader
                                </div>
                            </div>
                            <div className="top-nav-header-right-item-full white-fill-link" style={{lineHeight:'1.5'}}  onClick={()=>goToTrackBuilder()}>
                                <div className="top-nav-header-item-bubble">
                                    <div className="bubble-button"></div>
                                </div>
                                <div className="top-nav-header-item-text">
                                    Track Builder
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <ul id="settings-dropdown" className="dropdown-content transparent subheadline-text">
                    <li className="white center-align white-red-button" style={{borderRadius:'2px',marginTop:'12px'}}>
                        <a className="modal-trigger black-text white-red-button center-align" data-target="settingsbox" style={{borderRadius:'2px',fontSize:'14px',fontWeight:'900'}}>SETTINGS</a>
                    </li>
                    <li className="white-red-button center-align" style={{borderRadius:'2px',fontSize:'12px',marginTop:'10px'}}>
                        <a className="black-text white-red-button center-align" onClick={()=>logoutGame()} style={{borderRadius:'2px',fontSize:'14px',fontWeight:'900'}}>LOGOUT</a>
                    </li>
                </ul>
                <SettingsModal />
        </div>);
};