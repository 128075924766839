import api from "../../adapters/api";

const initialState = {
  loggedIn: false,
  authToken: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOG_IN":
      return {
        ...state,
        loggedIn: true,
      };

    case "LOG_OUT":
      return {
        ...state,
        loggedIn: false,
      };

    case "SET_AUTH_TOKEN":
      return {
        ...state,
        authToken: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
