import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../../race_landing.css";
import $ from "jquery";

export const StackCarouselSelect = (props) => {

    const [entrySet, setEntry] = useState(false);
    const [racecraft_choice_html, setRacecraftChoiceHTML] = useState('');
    const [pilot_choice_html, setPilotChoiceHTML] = useState('');
    const [booster_choice_html, setBoosterChoiceHTML] = useState('');

    const [item_list, setItemList] = useState(props.item_list);

    function grabStackItems() {
        
        var new_item_list = item_list;
        
        var pilot_arr = []
        var racecraft_arr = []
        var booster_arr = []
        for (var z = 0; z < new_item_list.length;z++) {
            if (new_item_list[z].item_type == 'booster') {
                booster_arr.push(new_item_list[z]);
            } else if (new_item_list[z].item_type == 'pilot') {
                pilot_arr.push(new_item_list[z]);
            } else if (new_item_list[z].item_type == 'ship') {
                racecraft_arr.push(new_item_list[z]);
            } else if (new_item_list[z].item_type == 'racecraft') {
                racecraft_arr.push(new_item_list[z]);
            }
        }


        createPageCarousels('racecraft', racecraft_arr);
        createPageCarousels('pilot', pilot_arr);
        createPageCarousels('booster', booster_arr);
    }

    function startEntrySliders() {
        props.startSliders();
    }

    function createPageCarousels(itemtype, itemarr) {
        var section_div_id = itemtype+'_selection_carousel';
        var section_html = [];
        var racer_attributes = ['speed', 'handling', 'acceleration', 'strength'];
        var pilot_attributes = ['focus', 'stamina', 'aggression', 'intelligence'];
        var booster_attributes = ['speed', 'handling', 'acceleration', 'strength', 'focus', 'stamina', 'aggression', 'intelligence'];

        for(var z = 0; z < itemarr.length;z++) {
            var item_div_id = itemtype+'_'+itemarr[z].racer_item_id;
            var item_image = itemarr[z].item_image_location;
            var item_lender_name = itemarr[z].lender_racer_name;
            var item_name = itemarr[z].item_name.replace("-"," ").replace("-"," ").replace("-"," ").toUpperCase();
            if(itemtype == 'booster') {
                var item_name_split = item_name.replace("EXR ","").split(" [");
                item_name = item_name_split[0];
            }
            var item_subtitle = itemarr[z].item_subname;
            if(item_subtitle != null) {
                item_subtitle = item_subtitle.toUpperCase();
            }
            var item_attributes = itemarr[z].item_attributes;

            var itemdata = '';
            for(var m = 0; m < item_attributes.length;m++) {
                if(m > 0) {
                    itemdata = itemdata+',';
                }
                itemdata = itemdata+item_attributes[m].attribute.toLowerCase()+'_'+item_attributes[m].score;
            }
            var item_faction_name = itemarr[z].item_faction.toUpperCase();
            if(item_faction_name == 'AUGMENT') {
                item_faction_name = 'AUG';
            } else {
                item_faction_name = item_faction_name.slice(0, 4);
            }
            var item_faction_icon = '';
            if(item_faction_name != 'ANY') {
                item_faction_icon = <img src={"https://media.exiledracers.com/global/icons/exr_"+item_faction_name.toLowerCase()+"_icon.png"} height="50px" />
            }

            var item_img_bg = "url('"+item_image+"')";

            var sharedby_subtitle = <></>;
            if(item_lender_name != null) {
                if (item_lender_name.length > 2) {
                    var sectiontextcolor = 'grey-text';
                    sharedby_subtitle = <div className={"row subheadline-text grey-text"} style={{fontSize:'12px',margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px',overflow:'hidden',marginTop:'-10px'}}>
                                            SHARED BY: <span className={sectiontextcolor} >{item_lender_name.toUpperCase()}</span>
                                        </div>;
                }
            }

            

            var tmp_html = <div key={z} className="carousel-item" id={item_div_id} style={{minHeight:'100%'}} data-itemtype={itemtype} data-scores={itemdata} data-faction={itemarr[z].item_faction.toLowerCase()} >
                                <div className="stack-selection-caro-box">
                                    <div className="stack-selection-caro-image-box" style={{background:item_img_bg,backgroundSize:'cover',backgroundPosition:'center'}}>
                                    </div>
                                    <div className="stack-selection-caro-icon" title={itemarr[z].item_faction}>
                                        {item_faction_icon}
                                    </div>
                                    <div className="exr-trans-grey-bg center-align stack-selection-caro-text-box">
                                        <div className="row headline-text" style={{margin:'0px',padding:'0px 20px'}}>
                                            {item_name}
                                        </div>
                                        <div className="row subheadline-text exr-light-green-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'10px'}}>
                                            {item_subtitle} &nbsp;&nbsp;///&nbsp;&nbsp;{item_faction_name}
                                        </div>
                                        {sharedby_subtitle}
                                    </div>
                                </div>
                            </div>
            section_html.push(tmp_html);
        }

        if(itemtype == 'booster') {
            setBoosterChoiceHTML(section_html);
            setTimeout(function() {
                startEntrySliders();
            },10);
        } else if(itemtype == 'pilot') {
            setPilotChoiceHTML(section_html);
        } else if(itemtype == 'racecraft') {
            setRacecraftChoiceHTML(section_html);
        }
    }

    if(entrySet == false) {
        setEntry(true);
        grabStackItems();
    }

    return(<>
            <div className="col s12 m4 l3" style={{padding:'0px',paddingRight:'8px'}}>
                <div className="stack-selection-box" style={{width:"100%"}}>
                    <div className="row white-text stack-selection-col" style={{margin:"0px",padding:"0px"}}>
                        <i id="pilot_slider_nav_left" className="material-icons white-text stack-selection-caro-left-button">chevron_left</i>
                        <i id="pilot_slider_nav_right" className="material-icons white-text stack-selection-caro-right-button">chevron_right</i>
                        <div id="pilot_slider_slide" className="carousel carousel-slider stack-selection-caro">
                            {pilot_choice_html}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col s12 m4 l3" style={{padding:'0px',paddingRight:'8px'}}>
                <div className="stack-selection-box" style={{width:"100%"}}>
                    <div className="row white-text stack-selection-col" style={{margin:"0px",padding:"0px"}}>
                        <i id="racecraft_slider_nav_left" className="material-icons white-text stack-selection-caro-left-button">chevron_left</i>
                        <i id="racecraft_slider_nav_right" className="material-icons white-text stack-selection-caro-right-button">chevron_right</i>
                        <div id="racecraft_slider_slide" className="carousel carousel-slider stack-selection-caro">
                            {racecraft_choice_html}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="col s12 m4 l3" style={{padding:'0px',paddingRight:'8px'}}>
                <div className="stack-selection-box" style={{width:"100%"}}>
                    <div className="row white-text stack-selection-col" style={{margin:"0px",padding:"0px"}}>
                        <i id="booster_slider_nav_left" className="material-icons white-text stack-selection-caro-left-button">chevron_left</i>
                        <i id="booster_slider_nav_right" className="material-icons white-text stack-selection-caro-right-button">chevron_right</i>
                        <div id="booster_slider_slide" className="carousel carousel-slider stack-selection-caro">
                            {booster_choice_html}
                        </div>
                    </div>
                </div>
            </div>
    </>);
}