import * as THREE from "three";
import ColorPicker from "simple-color-picker";

export class GuiPanel {
    static ASSET_GET = "https://data.exiledracers.com/api/v1/builder/asset_list/?layout=grouped";
    static CLASS_HIDDEN = "hidden";

    static assets = null;
    static assetsLoading = false;
    static assetsCallbacks = [];

    /**
     * Get all available assets
     * @returns {Promise} A promise that resolves when assets are available
     */
    static getAssets() {
        return new Promise(resolve => {
            if (GuiPanel.assets)
                resolve(GuiPanel.assets);
            else if (GuiPanel.assetsLoading)
                GuiPanel.assetsCallbacks.push(() => {
                    resolve(GuiPanel.assets);
                });
            else {
                GuiPanel.assetsLoading = true;
                GuiPanel.assetsCallbacks.push(() => {
                    resolve(GuiPanel.assets);
                });

                const request = new XMLHttpRequest();

                request.onloadend = () => {
                    GuiPanel.assets = JSON.parse(request.responseText);

                    for (const callback of GuiPanel.assetsCallbacks)
                        callback();
                };

                request.open("GET", GuiPanel.ASSET_GET, true);
                request.setRequestHeader("Content-Type", "application/json");
                request.send();
            }
        });
    }

    /**
     * Construct a GUI panel
     * @param {HTMLElement} popupElement The element to place popups on
     */
    constructor(popupElement) {
        this.element = document.createElement("table");
        this.popupElement = popupElement;
        this.hidden = [];
    }

    /**
     * Show a category if it is hidden
     * @param {string} category The category name
     */
    showCategory(category) {
        if (this.hidden.indexOf(category) !== -1)
            this.hidden.splice(this.hidden.indexOf(category), 1);

        for (const row of this.element.children) if (row.classList.contains(category))
            row.classList.remove(GuiPanel.CLASS_HIDDEN);
    }

    /**
     * Hide a category if it was shown
     * @param {string} category The category name
     */
    hideCategory(category) {
        if (this.hidden.indexOf(category) === -1)
            this.hidden.push(category);

        for (const row of this.element.children) if (row.classList.contains(category))
            row.classList.add(GuiPanel.CLASS_HIDDEN);
    }

    /**
     * Add a checkbox field
     * @param {string} title The field title
     * @param {boolean} value The initial value
     * @param {function} [onChange] A function to call when the value changed
     * @param {string | null} [category] The toggle category
     */
    addFieldCheckbox(
        title,
        value = false,
        onChange = () => {},
        category = null) {
        const row = document.createElement("tr");
        const cellLabel = document.createElement("td");
        const cellInput = document.createElement("td");
        const label = document.createElement("label");

        label.appendChild(document.createTextNode(title + ":"));

        const input = document.createElement("input");

        input.type = "checkbox";
        input.checked = value;
        input.oninput = () => {
            onChange(input.checked);
        };

        cellLabel.appendChild(label);
        cellInput.appendChild(input);

        row.appendChild(cellLabel);
        row.appendChild(cellInput);

        if (category) {
            row.classList.add(category);

            if (this.hidden.indexOf(category) !== -1)
                row.classList.add(GuiPanel.CLASS_HIDDEN);
        }

        this.element.appendChild(row);
    }

    /**
     * Add a slider field
     * @param {string} title The field title
     * @param {THREE.Vector2} range The value range
     * @param {number} [value] The default value
     * @param {function} [onChange] A function to call when the value changed
     * @param {string | null} [category] The toggle category
     */
    addFieldSlider(
        title,
        range = new THREE.Vector2(0, 1),
        value = (range.x + range.y) * .5,
        onChange = () => {},
        category = null) {
        const row = document.createElement("tr");
        const cellLabel = document.createElement("td");
        const cellInput = document.createElement("td");
        const label = document.createElement("label");

        label.appendChild(document.createTextNode(title + ":"));

        const input = document.createElement("input");

        input.type = "range";
        input.min = range.x.toString();
        input.max = range.y.toString();
        input.step = ((range.y - range.x) / 512).toString();
        input.value = value.toString();
        input.oninput = () => {
            onChange(Number.parseFloat(input.value));
        };

        cellLabel.appendChild(label);
        cellInput.appendChild(input);

        row.appendChild(cellLabel);
        row.appendChild(cellInput);

        if (category) {
            row.classList.add(category);

            if (this.hidden.indexOf(category) !== -1)
                row.classList.add(GuiPanel.CLASS_HIDDEN);
        }

        this.element.appendChild(row);
    }

    /**
     * Add a color field
     * @param {string} title The field title
     * @param {number} value The color value as an integer
     * @param {function} [onChange] A function to call when the value changed
     * @param {string | null} [category] The toggle category
     */
    addFieldColor(
        title,
        value,
        onChange = () => {},
        category = null) {
        const row = document.createElement("tr");
        const cellLabel = document.createElement("td");
        const cellInput = document.createElement("td");
        const label = document.createElement("label");

        label.appendChild(document.createTextNode(title + ":"));

        const input = document.createElement("button");

        input.style.backgroundColor = "#" + value.toString(16);
        input.onclick = () => {
            let entered = false;
            const picker = new ColorPicker({
                color: "#" + value.toString(16)
            });

            picker.$el.addEventListener("mouseenter", () => {
                entered = true;
            });

            picker.$el.addEventListener("mouseleave", () => {
                if (entered)
                    picker.remove();
            });

            picker.appendTo(this.popupElement);
            picker.onChange(() => {
                input.style.backgroundColor = picker.getHexString();
                value = picker.getHexNumber();

                onChange(value);
            });
        };

        cellLabel.appendChild(label);
        cellInput.appendChild(input);

        row.appendChild(cellLabel);
        row.appendChild(cellInput);

        if (category) {
            row.classList.add(category);

            if (this.hidden.indexOf(category) !== -1)
                row.classList.add(GuiPanel.CLASS_HIDDEN);
        }

        this.element.appendChild(row);
    }

    addFieldOptions(
        title,
        options,
        current,
        onChange = () => {},
        dependent = [],
        category = null) {
        const row = document.createElement("tr");
        const cellLabel = document.createElement("td");
        const cellInput = document.createElement("td");
        const label = document.createElement("label");
        const select = document.createElement("select");

        const currentIndex = options.indexOf(current);

        select.selectedIndex = currentIndex + 1;

        for (const category of dependent) {
            if (category !== dependent[options.indexOf(current)])
                this.hideCategory(category);
            else
                this.showCategory(category);
        }

        for (const option of options) {
            const optionElement = document.createElement("option");

            if (option === current)
                optionElement.selected = "selected";

            [optionElement.value, optionElement.text] = [option, option];

            select.appendChild(optionElement);
        }

        select.onchange = () => {
            onChange(select.value);

            for (const category of dependent)
                if (category[0] === "!") {
                    if (category.slice(1) === dependent[options.indexOf(select.value)])
                        this.hideCategory(category);
                    else
                        this.showCategory(category);
                }
                else {
                    if (category === dependent[options.indexOf(select.value)])
                        this.showCategory(category);
                    else
                        this.hideCategory(category);
                }
        };

        cellInput.appendChild(select);

        label.appendChild(document.createTextNode(title + ":"));

        cellLabel.appendChild(label);

        row.appendChild(cellLabel);
        row.appendChild(cellInput);

        if (category) {
            row.classList.add(category);

            if (this.hidden.indexOf(category) !== -1)
                row.classList.add(GuiPanel.CLASS_HIDDEN);
        }

        this.element.appendChild(row);
    }

    /**
     * Create a field for choosing assets
     * @param {string} title The field title
     * @param {string} current The current asset url
     * @param {function} [onChange] A function to call when the value changed
     * @param {string | null} [filter] The asset category filter
     * @param {string | null} [category] The toggle category
     * @param {string[]} [dependent] Categories dependent on this asset not being null, categories starting with ! are inverted
     */
    addFieldAsset(
        title,
        current,
        onChange = () => {},
        filter = null,
        category = null,
        dependent = []) {
        const row = document.createElement("tr");
        const cellLabel = document.createElement("td");
        const cellInput = document.createElement("td");
        const label = document.createElement("label");

        GuiPanel.getAssets().then(assets => {
            const select = document.createElement("select");
            const emptyOption = document.createElement("option");
            const sorted = [];
            const filtered = [];

            emptyOption.value = "null";
            emptyOption.text = "No asset";

            select.appendChild(emptyOption);

            for (const category of assets) if (!filter || category["asset_category"] === filter)
                for (const asset of category["assets"])
                    sorted.push([asset["asset_location"], asset["asset_name"]]);

            sorted.sort((a, b) => a[1].localeCompare(b[1]));

            for (const pair of sorted) {
                const option = document.createElement("option");

                [option.value, option.text] = pair;

                filtered.push(option.value);

                select.appendChild(option);
            }

            const currentIndex = filtered.indexOf(current);

            if (currentIndex !== -1) {
                select.selectedIndex = currentIndex + 1;

                for (const category of dependent) if (category[0] === "!")
                    this.hideCategory(category.slice(1));
            }
            else
                for (const category of dependent)
                    this.hideCategory(category);

            select.onchange = () => {
                if (select.value === "null") {
                    onChange(null);

                    for (const category of dependent)
                        if (category[0] === "!")
                            this.showCategory(category.slice(1));
                        else
                            this.hideCategory(category);
                }
                else {
                    onChange(select.value);

                    for (const category of dependent)
                        if (category[0] === "!")
                            this.hideCategory(category.slice(1));
                        else
                            this.showCategory(category);
                }
            };

            cellInput.appendChild(select);
        });

        label.appendChild(document.createTextNode(title + ":"));

        cellLabel.appendChild(label);

        row.appendChild(cellLabel);
        row.appendChild(cellInput);

        if (category) {
            row.classList.add(category);

            if (this.hidden.indexOf(category) !== -1)
                row.classList.add(GuiPanel.CLASS_HIDDEN);
        }

        this.element.appendChild(row);
    }
}