import { SectionTextWithBlock } from "../../Sections/SectionTextWithBlock";
import { SectionHeroWithImage } from "../../Sections/SectionHeroWithImage";

import itemImage1 from "../../../assets/images/itempage-image-1.jpg";
import itemImage2 from "../../../assets/images/itempage-image-2.jpg";
import itemImage3 from "../../../assets/images/itempage-image-3.jpg";
import mintKey from "../../../assets/images/mint-key.jpg";
import mintPhase from "../../../assets/images/mint-phase.png";

import exrClasses from "../../../assets/images/exr-classes.png";

export const LearnItems = () => {
  return (
    <div>
      <SectionHeroWithImage
        title="Items"
        subtitle="AVAILABLE EXR GAME ITEMS"
        description="The Items needed for the bonus EXR game are a Pilot, Racecraft & Boosters. There are also mint keys to get these items, all NFTS are available."
        content={{
          src: exrClasses,
          title: "exr classes",
        }}
        additionalNote={{
          label: "2 Types of items",
          text: "Art Pieces / Game Items + Mint KEYS",
        }}
      />
      <SectionTextWithBlock
        title="Pilot"
        subtitle="ART Piece // Game ITEM"
        block={{
          type: "image",
          content: {
            src: itemImage1,
            title: "pilot serf",
          },
        }}
        description="Pilots were the first art piece and game item to be unlocked from the Pilot Mint key. Pilots have the following attributes;focus, stamina, aggRession & intelligence."
        blockPositionOnDesktop="left"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/exr-pilot-attributes-details-revealed-daaaf811d12",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Type",
          text: "ERC-721 NFT",
        }}
      />
      <SectionTextWithBlock
        title="Racecrafts"
        subtitle="ART Piece // Game ITEM"
        block={{
          type: "image",
          content: {
            src: itemImage2,
            title: "race craft",
          },
        }}
        description="Racecrafts were the 2nd art piece and game item to be unlocked from the Pilot Mint key. Racecrafts have the following attributes; speed, Strength, handling, & acceleration."
        blockPositionOnDesktop="right"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/racecraft-stats-attributes-revealed-c6ae976ab79e",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Type",
          text: "ERC-721 NFT",
        }}
      />
      <SectionTextWithBlock
        title="Boosters"
        subtitle="ART Piece // Game ITEM"
        block={{
          type: "image",
          content: {
            src: itemImage3,
            title: "booster",
          },
        }}
        description="Boosters were the Final art piece and game item to be unlocked from the Pilot Mint key. boosters are needed for the bonus game."
        blockPositionOnDesktop="left"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/boosters-mint-phase-4-live-custom-1-1-partner-boosters-to-be-won-a2b52e3bde78",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Type",
          text: "ERC-1155 NFT",
        }}
      />
      <SectionTextWithBlock
        title="Pilots"
        subtitle="Mint Keys"
        block={{
          type: "video",
          content: {
            src: "https://media.exiledracers.com/assets/game/builder/exr_pilot_key_rotating_1680648564143.mp4",
            title: "mint key",
          },
        }}
        description="Pilot MINT Keys are the first of their kind. 1 Pilot key = 1 PILOT, 1 RaceCraft & 2x Boosters."
        blockPositionOnDesktop="right"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/announcing-exr-mint-details-and-mysterious-free-nft-access-pass-e9b716cc4f3a",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Type",
          text: "ERC-721 NFT",
        }}
      />
      <SectionTextWithBlock
        title="Racecraft"
        subtitle="Mint keys"
        block={{
          type: "video",
          content: {
            src: "https://media.exiledracers.com/assets/game/builder/exr_racecraft_key_rotating_1680648751092.mp4",
            title: "mint key",
          },
        }}
        description="Racecraft mint keys, once burned will unlock 1 x racecraft — which will be a uniuqe game item."
        blockPositionOnDesktop="left"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/announcing-exr-mint-details-and-mysterious-free-nft-access-pass-e9b716cc4f3a",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Type",
          text: "ERC-721 NFT",
        }}
      />
      <SectionTextWithBlock
        title="Booster"
        subtitle="Mint keys"
        block={{
          type: "video",
          content: {
            src: "https://media.exiledracers.com/assets/game/builder/exr_booster_key_rotating_1680648844585.mp4",
            title: "mint key",
          },
        }}
        description="BOOSTER MINT KEYS are the final keys that were made available. 1 x Booster key unlocks 2 x booster game items. These are available. "
        blockPositionOnDesktop="right"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/announcing-exr-mint-details-and-mysterious-free-nft-access-pass-e9b716cc4f3a",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Type",
          text: "ERC-1155 NFT",
        }}
      />
      <SectionTextWithBlock
        title="SUMMARY"
        subtitle="Mint keys"
        block={{
          type: "image",
          content: {
            src: mintPhase,
            title: "mint phase",
          },
        }}
        description="We've designed A DETAILED summary of the mint keys & what ART PIECES / GAMING items they unlock after burning."
        blockPositionOnDesktop="left"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/announcing-exr-mint-details-and-mysterious-free-nft-access-pass-e9b716cc4f3a",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Type",
          text: "ERC-1155 NFT",
        }}
      />
    </div>
  );
}