export const setReady = () => {
  console.log("setting ready");
  return async (dispatch) => {
    dispatch({
      type: "SET_IS_READY",
    });
  };
};

export const updateLocation = (location) => {
  localStorage.setItem('exr_last_page', location);
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_LOCATION",
      payload: location,
    });
  };
};

export const updatePageHash = (hash) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_PAGE_HASH",
      payload: hash,
    });
  };
};

export const pageLoaded = () => {
  return async (dispatch) => {
    dispatch({
      type: "PAGE_LOADED",
    });
  };
};

export const signupLoading = (loadingstatus) => {
  return async (dispatch) => {
    dispatch({
      type: "SIGNUP_LOADING",
      payload: loadingstatus,
    });
  };
};

export const gameLoading = (loadingstatus) => {
  return async (dispatch) => {
    dispatch({
      type: "GAME_LOADING",
      payload: loadingstatus,
    });
  };
};