import * as THREE from "three";
import {TrackProp} from "./trackProp.js";
import {TrackPropInstanceOnTrackInterval} from "./instance/trackPropInstanceOnTrackInterval.js";
import {TrackPropOrb} from "./trackPropOrb.js";
import {TrackPropInstanceOnTrack} from "./instance/trackPropInstanceOnTrack.js";
import {Designed} from "../../design/designed.js";
import {TrackPropInstanceSingle} from "./instance/trackPropInstanceSingle.js";
import {Random} from "../../../../exr-webgl-hub/math/random.js";
import {TrackTracer} from "../trackTracer.js";
import {PropGeneratorScatterer} from "./generators/scatterer/propGeneratorScatterer.js";
import {PropGeneratorExcluder} from "./generators/propGeneratorExcluder.js";
import {TrackModel} from "../model/trackModel.js";
import {TrackCrossSection} from "../model/trackCrossSection.js";
import {TrackSurface} from "../model/trackSurface.js";
import {effectGlow} from "../../../../exr-webgl-hub/effects/2d/effectGlow.js";
import {TrackPropPassAudioTrigger} from "./trackPropPassAudioTrigger.js";

/**
 * Track props
 */
export class TrackProps extends Designed {
    static ORB_PADDING = 2.5;
    static ASTEROID_FIELD_RAISE = 12;
    static ASTEROID_FIELD_ROTATION = .005;

    /**
     * Construct the track props
     * @param {Object} design The track design
     * @param {Track} track The track
     * @param {number} trackWidth The track width
     * @param {THREE.Scene} scene The scene to add props to
     * @param {Function} onLoaded A callback to run when the props all loaded
     */
    constructor(design, track, trackWidth, scene, onLoaded) {
        super();

        this.track = track;
        this.props = [];
        this.scene = scene;
        this.trackWidth = trackWidth;
        this.onLoaded = onLoaded;
        this.passTriggers = [];
        this.sponsor = 'none';
        if (design.hasOwnProperty("race_sponsor")) {
            this.sponsor = design.race_sponsor;
        }

        this.hasOrbs = false;
        this.orbColor = -1;
        this.orbSpacing = -1;
        this.orbRadius = -1;

        this.trackModels = [];

        this.gateModel = null;
        this.gateModelFinish = null;
        this.gateSpacing = 100;

        this.asteroidsSeed = 0;
        this.asteroidsFieldCount = 0;
        this.asteroidsFieldSize = 0;
        this.asteroidsSpacing = 5;
        this.asteroidsAsset1 = null;
        this.asteroidsAsset2 = null;
        this.asteroidsAsset3 = null;
    }

    /**
     * Unload the current design, if any
     */
    unload() {
        super.unload();

        this.asteroidsAsset1 = this.asteroidsAsset2 = this.asteroidsAsset3 = null;

        this.clearProps();
    }

    /**
     * Find a prop by asset name
     * @param {string} asset The asset name
     * @returns {TrackProp | null} The track prop, or null if it couldn't be found
     */
    findPropWithAsset(asset) {
        for (const prop of this.props) if (prop.model === asset)
            return prop;

        return null;
    }

    /**
     * Add a prop
     * @param {TrackProp} prop The prop to add
     */
    addProp(prop) {
        this.props.push(prop);
    }

    /**
     * Load a track design
     * @param {Object} data Track data
     */
    load(data) {
        super.load(data);

        /**
         * Parse a 3d vector from json data
         * @param {Object} data The data
         * @param {string} fieldX The name of the X field
         * @param {string} fieldY The name of the Y field
         * @param {string} fieldZ The name of the Z field
         * @returns {Vector3} The vector
         */
        const parseVector3 = (data, fieldX = "x", fieldY = "y", fieldZ = "z") => {
            const x = data.hasOwnProperty(fieldX) ? parseFloat(data[fieldX]) : 0;
            const y = data.hasOwnProperty(fieldY) ? parseFloat(data[fieldY]) : 0;
            const z = data.hasOwnProperty(fieldZ) ? parseFloat(data[fieldZ]) : 0;

            return new THREE.Vector3(x, y, z);
        };

        /**
         * Parse a 2d vector from json data
         * @param {Object} data The data
         * @param {string} fieldX The name of the X field
         * @param {string} fieldY The name of the Y field
         * @returns {Vector2} The vector
         */
        const parseVector2 = (data, fieldX = "x", fieldY = "y") => {
            const x = data.hasOwnProperty(fieldX) ? parseFloat(data[fieldX]) : 0;
            const y = data.hasOwnProperty(fieldY) ? parseFloat(data[fieldY]) : 0;

            return new THREE.Vector2(x, y);
        };

        const excluder = new PropGeneratorExcluder(this.track);

        // Load orbs
        if (data.hasOwnProperty("orbs") &&
            data["orbs"].hasOwnProperty("spacing") &&
            data["orbs"].hasOwnProperty("color") &&
            data["orbs"].hasOwnProperty("radius")) {
            this.hasOrbs = true;
            this.orbSpacing = data["orbs"]["spacing"];
            this.orbColor = parseInt(data["orbs"]["color"], 16);
            this.orbRadius = data["orbs"]["radius"];

            this.addProp(new TrackPropOrb(this.orbColor, this.orbRadius, [
                new TrackPropInstanceOnTrackInterval(
                    this.track,
                    this.orbSpacing,
                    new THREE.Vector3(0, 0, this.trackWidth * .5 + TrackProps.ORB_PADDING)),
                new TrackPropInstanceOnTrackInterval(
                    this.track,
                    this.orbSpacing,
                    new THREE.Vector3(0, 0, this.trackWidth * -.5 - TrackProps.ORB_PADDING))
            ]));
        }
        else
            this.hasOrbs = false;

        // Load gates
        if (data.hasOwnProperty("gates") &&
            data["gates"].hasOwnProperty("spacing") &&
            data["gates"].hasOwnProperty("model") &&
            data["gates"]["model"] !== "null" &&
            data["gates"]["model"]) {
            let hasFinish = false;

            this.gateModel = data["gates"]["model"];
            this.gateSpacing = data["gates"]["spacing"];

            if (data["gates"].hasOwnProperty("modelFinish") &&
                data["gates"]["modelFinish"] !== "null" &&
                data["gates"]["modelFinish"]) {
                hasFinish = true;

                this.gateModelFinish = data["gates"]["modelFinish"];

                this.addProp(new TrackProp(
                    this.gateModelFinish,
                    [
                        new TrackPropInstanceOnTrack(this.track, 0, new THREE.Vector3())
                    ],
                    true,
                    true));
            }
            else
                this.gateModelFinish = null;

            this.addProp(new TrackProp(
                this.gateModel,
                [
                    new TrackPropInstanceOnTrackInterval(this.track, this.gateSpacing, new THREE.Vector3(), hasFinish)
                ],
                true,
                true));
        }

        // Load asteroids
        if (data.hasOwnProperty("asteroids")) {
            if (data["asteroids"].hasOwnProperty("seed") &&
                data["asteroids"].hasOwnProperty("fieldCount") &&
                data["asteroids"].hasOwnProperty("fieldSize") &&
                data["asteroids"].hasOwnProperty("spacing") &&
                data["asteroids"].hasOwnProperty("asset1") &&
                data["asteroids"].hasOwnProperty("asset2") &&
                data["asteroids"].hasOwnProperty("asset3") &&
                data["asteroids"]["asset1"] !== "null" &&
                data["asteroids"]["asset2"] !== "null" &&
                data["asteroids"]["asset3"] !== "null") {
                this.asteroidsSeed = data["asteroids"]["seed"];
                this.asteroidsFieldCount = data["asteroids"]["fieldCount"];
                this.asteroidsFieldSize = data["asteroids"]["fieldSize"];
                this.asteroidsSpacing = data["asteroids"]["spacing"];
                this.asteroidsAsset1 = data["asteroids"]["asset1"];
                this.asteroidsAsset2 = data["asteroids"]["asset2"];
                this.asteroidsAsset3 = data["asteroids"]["asset3"];

                const random = new Random(this.asteroidsSeed);
                const tracer = new TrackTracer(this.track);
                const tracedPosition = new THREE.Vector3();

                for (let field = 0; field < this.asteroidsFieldCount; ++field) {
                    tracer.trace(tracedPosition, new THREE.Vector3(), this.track.length * random.float);

                    tracedPosition.y += TrackProps.ASTEROID_FIELD_RAISE;

                    new PropGeneratorScatterer(
                        random,
                        tracedPosition,
                        new THREE.Vector2(
                            this.asteroidsSpacing,
                            this.asteroidsSpacing * 1.5),
                        new THREE.Vector2(
                            this.asteroidsFieldSize,
                            Math.ceil(this.asteroidsFieldSize * 1.5)),
                        [
                            this.asteroidsAsset1,
                            this.asteroidsAsset2,
                            this.asteroidsAsset3
                        ],
                        TrackProps.ASTEROID_FIELD_ROTATION).generate(this.props, excluder);
                }
            }
        }

        // Load props
        if (data.hasOwnProperty("props")) {
            for (const prop of data["props"]) {
                if (prop.hasOwnProperty("asset") &&
                    prop.hasOwnProperty("position") &&
                    prop["position"].hasOwnProperty("type")) {
                    const instances = [];

                    switch (prop["position"]["type"]) {
                        case "on-track":
                            instances.push(new TrackPropInstanceOnTrack(this.track, prop["position"]["distance"]));

                            break;
                        case "track-interval":
                            const interval = this.track.length / Math.round(this.track.length / prop["position"]["interval"]);

                            instances.push(new TrackPropInstanceOnTrackInterval(this.track, interval));

                            break;
                        case "free":
                            const position = parseVector3(prop["position"]["position"]);
                            const rotation = parseVector3(prop["position"]["rotation"]);

                            instances.push(new TrackPropInstanceSingle(position, rotation));
                    }

                    const existingProp = this.findPropWithAsset(prop["asset"]);

                    if (existingProp)
                        existingProp.instances.push(...instances);
                    else
                        this.addProp(new TrackProp(prop["asset"], instances));
                }
            }
        }

        this.instantiateProps(this.onLoaded);

        if (!this.hasOrbs) {
            const model = new TrackModel(this.track, new TrackCrossSection([
                new TrackSurface([
                    new THREE.Vector3(0, 0, -13),
                    new THREE.Vector3(0, 0, -11.5)]),
                new TrackSurface([
                    new THREE.Vector3(0, 0, 11.5),
                    new THREE.Vector3(0, 0, 13)]),
            ]), effectGlow.clone());

            model.mesh.renderOrder = -1000;

            this.scene.add(model.mesh);
            this.trackModels.push(model);
        }

        // Glass track
        {
            // const model = new TrackModel(this.track, new TrackCrossSection([
            //     new TrackSurface([
            //         new THREE.Vector3(0, 0, -13),
            //         new THREE.Vector3(0, 0, 13),
            //     ])
            // ]), effectGlass);
            //
            // model.mesh.renderOrder = 1000;
            //
            // this.scene.add(model.mesh);
            // this.trackModels.push(model);
        }
    }

    /**
     * Get the design in JSON format
     * @returns {Object} The design in JSON format
     */
    getDesign() {
        const design = {};

        if (this.hasOrbs)
            design["orbs"] = {
                "spacing": this.orbSpacing,
                "color": this.orbColor.toString(16),
                "radius": this.orbRadius
            };

        if (this.gateModel)
            design["gates"] = {
                "spacing": this.gateSpacing,
                "model": this.gateModel,
                "modelFinish": this.gateModelFinish
            };

        if (this.asteroidsAsset1 && this.asteroidsAsset2 && this.asteroidsAsset3)
            design["asteroids"] = {
                "seed": this.asteroidsSeed,
                "fieldCount": this.asteroidsFieldCount,
                "fieldSize": this.asteroidsFieldSize,
                "spacing": this.asteroidsSpacing,
                "asset1": this.asteroidsAsset1,
                "asset2": this.asteroidsAsset2,
                "asset3": this.asteroidsAsset3,
            };

        if (this.props.length !== 0) {
            const props = [];

            for (const prop of this.props) if (!(prop instanceof TrackPropOrb) && !prop.generated) {
                for (const instance of prop.instances) {
                    if (instance instanceof TrackPropInstanceOnTrack)
                        props.push({
                            "asset": prop.model,
                            "position": {
                                "type": "on-track",
                                "distance": instance.distance
                            }
                        });
                    else if (instance instanceof TrackPropInstanceOnTrackInterval)
                        props.push({
                            "asset": prop.model,
                            "position": {
                                "type": "track-interval",
                                "interval": instance.interval
                            }
                        });
                    else if (instance instanceof TrackPropInstanceSingle)
                        props.push({
                            "asset": prop.model,
                            "position": {
                                "type": "free",
                                "position": {
                                    "x": instance.position.x,
                                    "y": instance.position.y,
                                    "z": instance.position.z
                                },
                                "rotation": {
                                    "x": instance.rotation.x,
                                    "y": instance.rotation.y,
                                    "z": instance.rotation.z
                                }
                            }
                        });
                }
            }

            design["props"] = props;
        }

        return design;
    }

    /**
     * Update audio triggers
     * @param {THREE.Vector3} position The camera position
     * @param {THREE.Vector3} velocity The camera velocity
     * @param {Audio} audio The audio object
     */
    updateTriggers(position, velocity, audio) {
        for (let trigger = 0, triggerCount = this.passTriggers.length; trigger < triggerCount; ++trigger)
            this.passTriggers[trigger].update(position, velocity, audio);
    }

    /**
     * Update the state
     */
    update() {
        for (let prop = 0, propCount = this.props.length; prop < propCount; ++prop)
            this.props[prop].update();
    }

    /**
     * Render a frame
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        for (let prop = 0, propCount = this.props.length; prop < propCount; ++prop)
            this.props[prop].render(time);
    }

    /**
     * Instantiate props
     * @param {Function} onLoaded A callback to run when the props all loaded
     */
    instantiateProps(onLoaded) {
        let remaining = this.props.length;

        for (const prop of this.props)
            prop.load().then(() => {
                for (const instance of prop.instances) {
                    
                    instance.instantiate(this.scene, prop.scene, this.track, this.trackWidth, this.sponsor);

                    if (prop.passAudio) for (const object of instance.objects)
                        this.passTriggers.push(new TrackPropPassAudioTrigger(
                            object.position,
                            70));
                }

                if (!--remaining)
                    onLoaded();
            });
    }

    /**
     * Clear all props
     */
    clearProps() {
        for (const prop of this.props)
            for (const instance of prop.instances)
                instance.delete(this.scene);

        for (const trackModel of this.trackModels)
            this.scene.remove(trackModel.mesh);

        this.trackModels.length = 0;
        this.props.length = 0;
    }
}