/**
 * Random range samplers
 */
export class RandomRange {
    /**
     * Sample a range linearly
     * @param {THREE.Vector2} range The range
     * @param {number} t The sampling position in the range [0, 1]
     * @returns {number} The sampled value
     */
    static linear(range, t) {
        return range.x + (range.y - range.x) * t;
    }
}