import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import useSWR from "swr";

import { useMetaMask } from "metamask-react";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import { isCommunityResourcable } from "@ethersproject/providers";
import {closeAllModals} from "../../../utils";
import $ from "jquery";
import {
  InjectedConnector,
  UserRejectedRequestError,
  NoEthereumProviderError,
} from "@web3-react/injected-connector";

let web3;

const fetcher = (url) => fetch(url).then((res) => res.json());

let ext_account_id = "";
let total_pilots_sync = 0;
let total_racecrafts_sync = 0;
let total_boosters_sync = 0;

export const EmailRegistration = (props) => {
  const dispatch = useDispatch();
  const { page_loaded }= useSelector((state) => state.app);
  const { racerID, accountAddress } = useSelector((state) => state.account);
  const { active, chainId, library, error, activate, account } = useWeb3React();
  const { pilotContract, racerContract, boosterContract } = useSelector((state) => state.web3);
  const [racer_handle, setRacerHandle] = useState('');
  const [racer_email, setRacerEmail] = useState('');
  const [accountID, setAccountID] = useState(null);
  const [chainID, setChainID] = useState(null);
  const [currentLoginStatus,setCurrentStatus] = useState('SYNCING NFT ASSETS...');
  let logo_image = 'https://media.exiledracers.com/global/exr_logo_white_stacked.png';
  let bg_image = 'https://media.exiledracers.com/assets/game/builder/Splash_Background_-_Serf_Centered_1_1665676555319.jpeg';

    function trainingContinue(page) {
        if(page == 1){
            $("#page"+page+"submit").hide();
            $("#page"+page+"loading").show();
            setTimeout(function(){
                $("#signuppage"+page).fadeOut(2000);
            },500);
            setTimeout(function(){
                $("#signuppage"+(page+1)).fadeIn(2000);
            },2500);
        } else if (page == 3) {
            $("#page2submit").hide();
            $("#page2skip").hide();
            $("#page2loading").show();
            setTimeout(function(){
                $("#signuppage2").fadeOut(2000);
            },500);
            setTimeout(function(){
                $("#signuppage5").fadeIn(2000);
            },2500);
        } else {
            var raceremail = document.getElementById("settingsEmail").value.trim();
            var specSource = 'homepage';

            var addData = new Object();
            addData.specEmail = encodeURIComponent(raceremail);
            addData.specSource = specSource;

            if((raceremail.length < 3)) {
                alert('Add Your Email Address.');
            } else {
                $("#page"+page+"submit").hide();
                $("#page"+page+"loading").show();
                axiosInstance.post('spectator/demo_new/',addData).then(result => {
                    if(Object.keys(result.data).length > 0) {
                        var save_result = result.data;
                        if(save_result.length > 0) {
                            let new_spectator_id = save_result[0]['spectator_id'];
                            trainingContinue(3);
                        } else {
                            $("#page"+page+"submit").show();
                            $("#page"+page+"loading").hide();
                            alert('Whoops! Something Went Wrong. Update and Try Again');
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }

    function onEmailTextChange(e){
        setRacerEmail(e.target.value.trim());
    }

    function onHandleTextChange(e){
        setRacerHandle(e.target.value.replace("@","").trim());
    }


  

  return (<><div id="register-main-section" className="col s12 transparent" style={{marginTop:'5vh',position:'relative',zIndex:'10',display:'none'}}>
            <div className="row center-align" style={{marginBottom:'10px'}}>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                    <img id="exr-logo-button" style={{cursor:'pointer',marginLeft:'-20px',marginTop:'3vh',minWidth:'250px'}} title="Exiled Racers" width="50%" src={logo_image} />
                </div>
            </div>
            <div className="row white-text" style={{position:'relative'}}>
              <div id="signuppage2" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                  <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                      <div className="left headline-text" style={{marginLeft:'5px'}}>EXR TRAINING</div>
                  </div>
                  <div className="col s12" style={{padding:"0px 5px"}}>
                      <div className="divider-thin"></div>
                  </div>
                  <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                      Add Your Email Address to register and receive key information for the next launch of EXR Race Training. 
                  </div>
                  <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                      <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                          Email Address:
                      </div>
                      <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                          <input value={racer_email} 
                                  onChange={onEmailTextChange} 
                                  id="settingsEmail" 
                                  type="email" 
                                  className="browser-default white-bubble-input"
                                  style={{width:'98%'}} />
                      </div>
                  </div>
                  <div className="col s12" style={{marginTop:'50px',marginBottom:'10px'}}>
                      <div id="page2submit" className="right" title="Continue to Training">
                          <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(2)}>
                              SAVE & CONTINUE
                          </span>
                      </div>
                      <div id="page2loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                          <div className="spinner-layer spinner-red-only">
                              <div className="circle-clipper left">
                                  <div className="circle"></div>
                              </div>
                              <div className="gap-patch">
                                  <div className="circle"></div>
                              </div>
                              <div className="circle-clipper right">
                                  <div className="circle"></div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
              <div id="signuppage5" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                  <div className="col s12" style={{fontSize:"20px",marginTop:"0px",padding:"0px"}}>
                      <div className="left headline-text" style={{marginLeft:'5px'}}>REGISTRATION COMPLETE</div>
                  </div>
                  <div className="col s12" style={{padding:"0px 5px"}}>
                      <div className="divider-thin"></div>
                  </div>
                  <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                      Congratulations you're now registered. 
                      <br/><br/>We'll be in touch with more details before the launch of the next training phase. 
                      Follow us on <a style={{cursor:'pointer'}} className="exr-light-green-text" target="_blank" href="https://twitter.com/exiledracers">Twitter</a> and turn notifications on. 
                      Join our <a style={{cursor:'pointer'}} className="exr-light-green-text" target="_blank" href="https://discord.gg/exiledracers">Discord</a> to meet other pilots and take part in the banter.
                      <br/><br/>
                      Fly Safe Recruit...
                  </div>
              </div>
            </div>
          </div>
        </>
  );
};
