import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import "../../../RaceLanding/race_landing.css";
import {genetic_tooltip, genetic_max_tooltip, genetic_track_tooltip, genetic_tooltip_wtrack, genetic_max_tooltip_wtrack} from "../../race_landing_tooltips";
import $ from "jquery";

let predictDisplay = 'none';
let racerList = [];

export const LineupViewSection = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    const [entrySet, setEntry] = useState(false);
    const [inviteSet, setInvite] = useState(false);
    const [current_racer_remove, setRacerRemove] = useState(0);
    const [race_results_html, setResultsHTML] = useState('');
    const [lineupheadertext, setLineupHeader] = useState('');
    const [lineuprightheadertext, setLineupRightHeader] = useState('');
    const [simulate_button_html, setSimulateButtonHTML] = useState('');
    const [predictbannertext, setPredictionsBanner] = useState('');
    const [favorite_prediction_html, setFavoritePredictionHTML] = useState('');
    const [selected_prediction_html, setSelectedPredictionHTML] = useState('');
    const [close_prediction_html, setClosePredictionHTML] = useState('');
    // const [predictDisplay, setPredictDisplay] = useState('none');
    const [current_predicted_racer_name,setCurrentPredictedRacerName] = useState('None Selected');
    const [current_predicted_totals, setCurrentPredictedTotals] = useState(0);
    const [current_total_racers, setCurrentTotalRacers] = useState(0);
    const [current_first_pick,setCurrentFirstPick] = useState('');
    const [current_second_pick,setCurrentSecondPick] = useState('');
    const [final_pick_summary,setFinalPickSummary] = useState('');

    let [inviteList,setInviteList] = useState(props.inviteList);

    const race_creator_types = ['training','bot-race','daily','alpha','invite'];

    let predict_bg_image = 'https://media.exiledracers.com/assets/banners/EXR_PLANET_01.jpg';

    let items_checked = 'N';
    var racer_items = [];
    let predicted_racer_id = 0;
    let predicted_racer_id2 = 0;
    let predicted_racer_id3 = 0;
    let invitechipsloaded = 0;
    let refreshtimeron = 0;
    let predictions_closed = 0;

    useEffect(() => {
        if(entrySet == true) {
            racerList = props.racerList;
            setInviteList(props.inviteList);
            updateLineupResults();
        }
    }, [props.inviteList, props.racerList]);

    function showRaceLineup() {

        createPageResults(racerList,inviteList);
        //setGlobalButtons();
    }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function updateLineupResults() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);
        addData.predictorID = racerID;
        if((props.raceDetails.race_simulated == 1)&&(props.raceDetails.results_viewable == 1)) {
            addData.sortBY = 'standings';
        }
        axiosInstance.post('race/racer_list/',addData).then(result => {
            racerList = result.data;
            grabRacersInvitedLineup(result.data);
            //props.refreshFullPage();
        }).catch(error => {
            console.log(error);
        });
    }

    function grabRacersInvitedLineup(racerList) {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);
        axiosInstance.post('race/invite_list/',addData).then(result => {
            var invitedDetails = result.data;
            setInviteList(result.data);
            createPageResults(racerList,invitedDetails);
        }).catch(error => {
            console.log(error);
        });
    }

    function createPageResults(itemarr,inviteitemarr) {
        var section_html = [];
        var section_html_final = [];
        var racer_attributes = ['speed', 'handling', 'acceleration', 'strength'];
        var pilot_attributes = ['focus', 'stamina', 'aggression', 'intelligence'];
        var booster_attributes = ['speed', 'handling', 'acceleration', 'strength', 'focus', 'stamina', 'aggression', 'intelligence'];
        
        let total_predicted_points_earned = 0;
        let total_picks_made = 0;
        let total_picks_correct = 0;
        let predicted_racer_id = 0;
        let predicted_racer_id2 = 0;
        setCurrentFirstPick('Pick Not Made');
        setCurrentSecondPick('Pick Not Made');

        var item_col_size = "col s12 m6";
        var item_image_height = '200px';
        var stats_box_height = '407px';
        if(((itemarr.length+inviteitemarr.length) > 2)&&($("#race-landing-shell").width() > 800)) {
            item_col_size = "col s12 m6 l4";
            item_image_height = '200px';
            stats_box_height = '407px';
        } else if ($("#race-landing-shell").width() < 550) {
            var item_col_size = "col s12";
            var item_image_height = '200px';
            var stats_box_height = '407px';
        }

        item_image_height = '350px';

        let total_xp_points = 20+(20*(itemarr.length-2));
        if((itemarr.length >= 4)||(props.raceDetails.open_invite_race == 0)) {
            total_xp_points = 100;
        }
        let current_max_pct = 0;
        let favorite_racer_id = 0;
        var tmp_predicted_totals = 0;
        var favorite_racer_adjustment = 0;
        
        /* CALCULATE BEST ODDS */
        for(var z = 0; z < itemarr.length;z++) {
            var racer_racer_id = itemarr[z].racer_id;
            var racer_bot_racer = itemarr[z].bot_racer;
            var racer_win_pct = itemarr[z].racer_start_win_pct;
            if(racer_win_pct > current_max_pct) {
                current_max_pct = racer_win_pct;
                favorite_racer_id = racer_racer_id
            }

            /* Update Prediction */
            if(itemarr[z].racer_predicted == 1) {
                if(itemarr[z].racer_predicted_place == 1) {
                    predicted_racer_id = racer_racer_id;
                } else if(itemarr[z].racer_predicted_place == 2) {
                    predicted_racer_id2 = racer_racer_id;
                }
            }
        }

        setCurrentTotalRacers(itemarr.length);

        for(var z = 0; z < itemarr.length;z++) {
            var racer_racer_id = itemarr[z].racer_id;
            var race_participant_id = itemarr[z].race_participant_id;
            var pilot_image = itemarr[z].pilot_image_location;
            var racer_image = itemarr[z].ship_image_location;
            var booster_image = itemarr[z].boosters[6];
            var racer_name = itemarr[z].racer_name;
            var racer_bot_racer = itemarr[z].bot_racer;
            var race_track_faction = itemarr[z].race_track_galaxy;
            
            var score_speed = 0;
            var score_handling = 0;
            var score_acceleration = 0;
            var score_strength = 0;
            var score_focus = 0;
            var score_stamina = 0;
            var score_intelligence = 0;
            var score_aggression = 0;
            var score_speed2 = 0;
            var score_handling2 = 0;
            var score_acceleration2 = 0;
            var score_strength2 = 0;
            var score_focus2 = 0;
            var score_stamina2 = 0;
            var score_intelligence2 = 0;
            var score_aggression2 = 0;
            var score_handling3 = 0;
            var score_focus3 = 0;
            var score_speed3 = 0;
            var score_intelligence3 = 0;

            /* If Bot Racer, Then Use Override Attribute Stats and Booster Stats */
            if(racer_bot_racer == 1) {
                for(var m = 0; m < itemarr[z].override_attributes.length;m++) {
                    itemarr[z].override_attributes[m].attribute = itemarr[z].override_attributes[m].attribute.toLowerCase();
                    if(itemarr[z].override_attributes[m].attribute == 'speed') {
                        score_speed = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'handling') {
                        score_handling = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'acceleration') {
                        score_acceleration = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'strength') {
                        score_strength = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'focus') {
                        score_focus = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'stamina') {
                        score_stamina = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'aggression') {
                        score_aggression = itemarr[z].override_attributes[m].score;
                    } else if(itemarr[z].override_attributes[m].attribute == 'intelligence') {
                        score_intelligence = itemarr[z].override_attributes[m].score;
                    } 
                }

                var booster_attribute_score = itemarr[z].boosters[9];
                var booster_attribute_name = itemarr[z].boosters[4].toLowerCase();
                if(booster_attribute_name == 'focus') {
                    score_focus2 = booster_attribute_score;
                } else if(booster_attribute_name == 'stamina') {
                    score_stamina = booster_attribute_score;
                } else if(booster_attribute_name == 'aggression') {
                    score_aggression2 = booster_attribute_score;
                } else if(booster_attribute_name == 'intelligence') {
                    score_intelligence2 = booster_attribute_score;
                } else if(booster_attribute_name == 'speed') {
                    score_speed2 = booster_attribute_score;
                } else if(booster_attribute_name == 'handling') {
                    score_handling2 = booster_attribute_score;
                } else if(booster_attribute_name == 'acceleration') {
                    score_acceleration2 = booster_attribute_score;
                }  else if(booster_attribute_name == 'strength') {
                    score_strength2 = booster_attribute_score;
                } 

            } else {
                
                for(var m = 0; m < itemarr[z].ship_attributes.length;m++) {
                    itemarr[z].ship_attributes[m].attribute = itemarr[z].ship_attributes[m].attribute.toLowerCase();

                    if(itemarr[z].ship_attributes[m].attribute == 'speed') {
                        score_speed = itemarr[z].ship_attributes[m].score;
                    } else if(itemarr[z].ship_attributes[m].attribute == 'handling') {
                        score_handling = itemarr[z].ship_attributes[m].score;
                    } else if(itemarr[z].ship_attributes[m].attribute == 'acceleration') {
                        score_acceleration = itemarr[z].ship_attributes[m].score;
                    }  else if(itemarr[z].ship_attributes[m].attribute == 'strength') {
                        score_strength = itemarr[z].ship_attributes[m].score;
                    } 
                }

                for(var m = 0; m < itemarr[z].pilot_attributes.length;m++) {
                    itemarr[z].pilot_attributes[m].attribute = itemarr[z].pilot_attributes[m].attribute.toLowerCase();
                    
                    if(itemarr[z].pilot_attributes[m].attribute == 'focus') {
                        score_focus = itemarr[z].pilot_attributes[m].score;
                    } else if(itemarr[z].pilot_attributes[m].attribute == 'stamina') {
                        score_stamina = itemarr[z].pilot_attributes[m].score;
                    } else if(itemarr[z].pilot_attributes[m].attribute == 'aggression') {
                        score_aggression = itemarr[z].pilot_attributes[m].score;
                    } else if(itemarr[z].pilot_attributes[m].attribute == 'intelligence') {
                        score_intelligence = itemarr[z].pilot_attributes[m].score;
                    } 
                }
                
                var booster_attributes = itemarr[z].boosters[8];
                if(booster_attributes != null) {
                    for(var m = 0; m < booster_attributes.length;m++) {
                        booster_attributes[m].attribute = booster_attributes[m].attribute.toLowerCase();
                        if(booster_attributes[m].attribute == 'focus') {
                            score_focus2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'stamina') {
                            score_stamina2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'aggression') {
                            score_aggression2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'intelligence') {
                            score_intelligence2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'speed') {
                            score_speed2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'handling') {
                            score_handling2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'acceleration') {
                            score_acceleration2 = booster_attributes[m].score;
                        }  else if(booster_attributes[m].attribute == 'strength') {
                            score_strength2 = booster_attributes[m].score;
                        } 
                    }
                }
                
            }

            var genetic_boost_text = <></>;
            var track_faction_pilot_match = 0;
            var track_faction_racecraft_match = 0;
            if(itemarr[z].pilot_faction.toLowerCase() == race_track_faction.toLowerCase()) {
                track_faction_pilot_match = 1;
                score_intelligence3 = 2;
            }
            if(itemarr[z].ship_faction.toLowerCase() == race_track_faction.toLowerCase()) {
                track_faction_racecraft_match = 1;
                score_speed3 = 2;
            }
            if(itemarr[z].boosters[5] == null) {
                itemarr[z].boosters[5] = '';
            }

            if((itemarr[z].pilot_faction.toLowerCase() == itemarr[z].ship_faction.toLowerCase())&&
                (((itemarr[z].pilot_faction.toLowerCase() == itemarr[z].boosters[5].toLowerCase())||(itemarr[z].boosters[5].toLowerCase() == 'any'))||
                    (race_track_faction.toLowerCase() == itemarr[z].pilot_faction.toLowerCase()))) {
                score_handling3 = 4;
                score_focus3 = 4;
                var finalboosttip = genetic_max_tooltip;
                if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                    finalboosttip = genetic_max_tooltip_wtrack;
                }
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={finalboosttip}>GENETIC MAX</span>;
            } else if(itemarr[z].pilot_faction.toLowerCase() == itemarr[z].ship_faction.toLowerCase()) {
                score_handling3 = 3;
                score_focus3 = 3;
                var finalboosttip = genetic_tooltip;
                if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                    finalboosttip = genetic_tooltip_wtrack;
                }
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={finalboosttip}>GENETIC BOOST</span>;
            } else if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={genetic_track_tooltip}>GENETIC TRACK</span>;
            }

            var win_chance = 0;
            var pred_xp = 0; 
            if(itemarr[z].racer_start_win_pct != 0) {
                win_chance = Math.floor(100*(itemarr[z].racer_start_win_pct));
                pred_xp = Math.round(total_xp_points*(1-(itemarr[z].racer_start_win_pct)));
            }

            if (win_chance == 0) {
                win_chance = 1;
                favorite_racer_adjustment = favorite_racer_adjustment + 1;
            }
            if(racer_racer_id == favorite_racer_id) { 
                win_chance = win_chance - favorite_racer_adjustment;
            }

            racerList[z]['win_chance'] = win_chance; 
            racerList[z]['pred_xp'] = pred_xp;

            var total_predicted_amount = itemarr[z].total_predicted_amount;
            var total_predictors = itemarr[z].total_predictors;
            var tmp_predicted_totals = tmp_predicted_totals + parseInt(total_predictors);
            
            /* Update Prediction */
            if(itemarr[z].racer_predicted == 1) {
                total_picks_made = total_picks_made+1;
                var picktext = <span class="exr-yellow-text">{itemarr[z].racer_name.toUpperCase()}</span>;
                if(props.raceDetails.results_viewable == 1) {
                    if(itemarr[z].racer_predicted_win == 1) {
                        picktext = <>{itemarr[z].racer_name.toUpperCase()} (<span class="exr-light-green-text">CORRECT</span>)</>;
                        total_predicted_points_earned = total_predicted_points_earned+itemarr[z].racer_predicted_win_xp;
                        total_picks_correct = total_picks_correct+1;
                    } else if(props.raceDetails.race_simulated == 1) {
                        picktext = <>{itemarr[z].racer_name.toUpperCase()} <span class="exr-yellow-text">[MISS]</span></>;
                    }
                }

                if(itemarr[z].racer_predicted_place == 1) {
                    predicted_racer_id = racer_racer_id;
                    setCurrentFirstPick(picktext);
                } else if(itemarr[z].racer_predicted_place == 2) {
                    predicted_racer_id2 = racer_racer_id;
                    setCurrentSecondPick(picktext);
                }
            }

            var score_html = <div className="col s12 white-text" style={{fontSize:'14px'}}>
                                <div className="row headline-text" style={{marginTop:'0px',marginBottom:'0px',padding:'0px',position:'relative',fontSize:'12px'}}>
                                    <span className="left white-green-link" title="Learn About Racing Stacks" style={{marginLeft:'15px'}} onClick={()=>showStackInfoBox()}>
                                        RACE STACK
                                    </span>
                                    {genetic_boost_text}
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row" style={{marginTop:'15px',marginBottom:'5px'}}>
                                        <div className="col s6">
                                            <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Speed</div>
                                                    <div className="right">{(score_speed+score_speed2+score_speed3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_speed+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_speed2+'%',left:score_speed+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_speed3+'%',left:(score_speed+score_speed2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Handling</div>
                                                    <div className="right">{(score_handling+score_handling2+score_handling3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_handling+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_handling2+'%',left:score_handling+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_handling3+'%',left:(score_handling+score_handling2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Acceleration</div>
                                                    <div className="right">{(score_acceleration+score_acceleration2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_acceleration+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_acceleration2+'%',left:score_acceleration+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Strength</div>
                                                    <div className="right">{(score_strength+score_strength2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_strength+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_strength2+'%',left:score_strength+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col s6">
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Focus</div>
                                                    <div className="right">{(score_focus+score_focus2+score_focus3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_focus+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_focus2+'%',left:score_focus+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_focus3+'%',left:(score_focus+score_focus2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Stamina</div>
                                                    <div className="right">{(score_stamina+score_stamina2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_stamina+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_stamina2+'%',left:score_stamina+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Aggression</div>
                                                    <div className="right">{(score_aggression+score_aggression2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_aggression+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_aggression2+'%',left:score_aggression+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Intelligence</div>
                                                    <div className="right">{(score_intelligence+score_intelligence2+score_intelligence3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_intelligence+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_intelligence2+'%',left:score_intelligence+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_intelligence3+'%',left:(score_intelligence+score_intelligence2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;

            
            var base_predict_button = <span className="left round-border-button predict-open" style={{fontSize:'10px',marginTop:'5px',marginBottom:'10px'}} 
                                                    title="Predict This Racer to Win" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}
                                                    onClick={(e)=>openPredictRacerBox(e)}>
                                                PREDICT
                                            </span>;
            
            if ((props.raceDetails.open_invite_race == 1)&&(props.raceDetails.entry_window_over == 0)) {
                base_predict_button = <></>;
            } else if ((props.raceDetails.open_invite_race == 0)&&(props.raceDetails.predictions_window_over == 1)) {
                base_predict_button = <></>;
            } else if ((predicted_racer_id > 0)&&(predicted_racer_id == racer_racer_id)) {
                base_predict_button = <span className="left round-border-button-green" style={{fontSize:'10px',marginTop:'5px',marginBottom:'10px'}} 
                                                    title="You Predicted This Racer To Win" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id} 
                                                    onClick={(e)=>openPredictRacerBox(e)}>
                                                PICKED 1ST
                                            </span>; 
                if(props.raceDetails.race_simulated == 1) {
                    base_predict_button = <span className="left round-border-button-green" style={{fontSize:'10px',marginTop:'5px',marginBottom:'10px'}} 
                                                    title="You Predicted This Racer To Win" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}>
                                                PICKED 1ST
                                            </span>; 
                }
            } else if ((predicted_racer_id2 > 0)&&(predicted_racer_id2 == racer_racer_id)) {
                base_predict_button = <span className="left round-border-button-green" style={{fontSize:'10px',marginTop:'5px',marginBottom:'10px'}} 
                                                    title="You Predicted This Racer To Win" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id} 
                                                    onClick={(e)=>openPredictRacerBox(e)}>
                                                PICKED 2ND
                                            </span>;
                if(props.raceDetails.race_simulated == 1) {
                    base_predict_button = <span className="left round-border-button-green" style={{fontSize:'10px',marginTop:'5px',marginBottom:'10px'}} 
                                                    title="You Predicted This Racer To Win" 
                                                    data-racerid={racer_racer_id}
                                                    data-pracerid={race_participant_id}>
                                                PICKED 2ND
                                            </span>;
                }
            } else if (((predicted_racer_id > 0)&&(predicted_racer_id != racer_racer_id)&&(total_predicted_amount > 0))||(props.raceDetails.race_simulated == 1)) {
                base_predict_button = <></>;
            } else if ((predicted_racer_id > 0)&&(predicted_racer_id2 > 0)&&(predicted_racer_id != racer_racer_id)&&(predicted_racer_id2 != racer_racer_id)) {
                base_predict_button = <span className="left round-border-button predict-open" style={{fontSize:'10px',marginTop:'5px',textAlign:'center',marginBottom:'10px'}} 
                                                title="Change Prediction to This Racer" 
                                                data-racerid={racer_racer_id}
                                                data-pracerid={race_participant_id}
                                                onClick={(e)=>openPredictRacerBox(e)}>
                                                CHANGE PICK
                                            </span>;
            } 

            var total_predictors_text = total_predictors+' Predictor';
            if(total_predictors != 1) {
                total_predictors_text = total_predictors_text+'s';
            }

            var bonus_html = '';
            var underdog_bonus = itemarr[z].racer_underdog_bonus;
            if (underdog_bonus > 0){
                bonus_html = ' // '+underdog_bonus+' RPM Bonus';
            }

            let racernamenumber = 'RACER '+(z+1);
            var predict_html = <>{win_chance}% Win&nbsp;&nbsp;//&nbsp;&nbsp;
                                            {pred_xp} XP Reward</>
            if((props.raceDetails.race_simulated == 1)&&(props.raceDetails.results_viewable == 1)) {
                racernamenumber = ordinal_suffix_of((z+1)).toUpperCase()+" PLACE";
                if(itemarr[z].racer_predicted == 1) {
                    predict_html = <>{win_chance}% Win&nbsp;&nbsp;//&nbsp;&nbsp;
                                            {itemarr[z].racer_predicted_win_xp} XP Reward</>
                } else {
                    predict_html = <>{win_chance}% Win&nbsp;&nbsp;</>
                }
                
            }
            // var predict_html = <></>;

            if((props.raceDetails.open_invite_race == 1)&&(props.raceDetails.entry_window_over == 0)) {
                predict_html = <></>;
            }

            if((itemarr.length == 1)||(inviteitemarr.length > 0)) {
                predict_html = <></>;
            }

            
            // if(racer_racer_id == racerID){
            //     racernamenumber = 'RACER '+(z+1)+' (YOU):';
            // }

            var tmp_html = <div key={z} className={"col s12 m6 finish-screen-position"} style={{margin:'0px',padding:'5px',borderRadius:'10px',marginBottom:'0px',overflow:'hidden'}}>
                <div id={"racer-row-"+racer_racer_id} data-racerrow={racer_racer_id} 
                    onClick={(e)=>toggleRacerRow(e)}
                    className="row exr-new-light-grey" style={{display:'flex',flexDirection:'row',margin:'0px',padding:'0px 7px',paddingTop:'7px',borderRadius:'10px'}}>
                    <div className="left" style={{width:'25%'}}>
                        <img src={pilot_image} width="100%" style={{borderRadius:'5px'}} />
                    </div>
                    <div className="left white-text" style={{flex:'1',marginLeft:'10px',position:'relative'}}>
                        <div className="row" style={{margin:'0px',fontSize:"12px"}}>
                            <span className="left exr-light-green-text">{racernamenumber}</span>
                            
                        </div>
                        <div className="row headline-text" style={{margin:'0px',fontSize:"16px",marginTop:'5px'}}>
                            {racer_name.toUpperCase()}
                        </div>
                        <div className="row" style={{margin:'0px',fontSize:"16px"}}>
                            {predict_html}
                        </div>
                        <div className="row" style={{margin:'0px',fontSize:"16px"}}>
                            {base_predict_button}
                        </div>
                        <div id={"racer-stack-show-icon-"+racer_racer_id} 
                            className="material-symbols-outlined" 
                            style={{position:'absolute',right:'5px',top:'0px',fontSize:'20px'}}>expand_more</div>
                    </div>
                </div>
                <div id={"racer-stack-show-"+racer_racer_id} className="row exr-new-light-grey" style={{display:'none',margin:'0px',padding:'5px',marginTop:'-10px',
                borderRadius:'10px',borderTopRightRadius:'0px',borderTopLeftRadius:'0px'}}>
                    <div className="col s12 exr-new-dark-grey" style={{padding:"2px",paddingTop:'15px',marginTop:'10px',borderRadius:"10px",height:'auto',marginBottom:'0px'}}>
                            {score_html}
                    </div>
                </div>
            </div>;
            // if(racer_racer_id == racerID){
            //     section_html_final.push(tmp_html);
            // } else {
            //     section_html.push(tmp_html);
            // }
            section_html.push(tmp_html);
        }

        for(var m = 0; m < section_html.length;m++) {
            section_html_final.push(section_html[m]);
        }


        predictDisplay = 'block';
        $("#predictbanner").show();

        /* Set Close Predictions Text */
        setCurrentPredictedTotals(tmp_predicted_totals);
        var closepredtxt = <></>;
        closepredtxt = <>There are <a className="exr-light-green-text">{tmp_predicted_totals} of {itemarr.length} Racer Predictions</a> made on this race. Are you sure you want to close predictions to all racers and spectators?</>;
        setClosePredictionHTML(closepredtxt);
        setResultsHTML(section_html_final);

        if((props.raceDetails.race_simulated == 1)&&(props.raceDetails.results_viewable == 1)) {
            var tmpsummarytext = <><div className="row exr-light-green-text" style={{marginTop:'15px',fontSize:'18px',fontFamily:"helvetica",marginBottom:'5px'}}>
                                    <b>Race Summary</b>
                                </div>
                                <div className="row" style={{fontSize:'16px',fontFamily:"helvetica",marginBottom:'5px'}}>
                                    <b>Total Points:</b>&nbsp;{total_predicted_points_earned} XP Won
                                </div>
                                <div className="row" style={{fontSize:'16px',fontFamily:"helvetica",marginBottom:'5px'}}>
                                    <b>Picks:</b>&nbsp;{total_picks_correct} of {total_picks_made} Correct
                                </div>
                                </>
            setFinalPickSummary(tmpsummarytext);
        }

        var tooltips = document.querySelectorAll('.lineuptips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    }

    function showStackInfoBox() {
        var sinfobox = document.getElementById('stackinfobox');
        var info_instance = window.M.Modal.getInstance(sinfobox);
        info_instance.open();
    }

    function closePredictionsBox() {
        window.startPageModals();
        var predictionclosebox = document.getElementById('closepredictionsbox');
        var pred_instance = window.M.Modal.getInstance(predictionclosebox);
        pred_instance.open();
    }

    function closePredictions() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);

        document.getElementById("predclosechecksubmit").style.display = 'none';
        document.getElementById("predclosecheckloading").style.display = 'block';

        predictions_closed = 1;
        setTimeout(function() {
            showRaceLineup();
            var lineuptext = <><a className="exr-light-green-text">NEXT:</a> SIMULATE RACE</>;
            setLineupHeader(lineuptext);
            var predictionclosebox = document.getElementById('closepredictionsbox');
            var pred_instance = window.M.Modal.getInstance(predictionclosebox);
            pred_instance.close();
        },2000);

        // axiosInstance.post('race/close_predictions/',addData).then(result => {
        //     if(Object.keys(result.data).length > 0) {
        //         setTimeout(function() {
        //             showRaceLineup();
        //         },2000);
        //     }
        // }).catch(error => {
        //     console.log(error);
        // });
    }

    function simulateRaceResults() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);

        axiosInstance.post('race/simulate_race/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var sim_details = result.data;
                setTimeout(function() {
                    watchInitialRace();
                },2000);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function startRaceSimulator() {
        var generatingSimulation = <div id="sim-status" className="left">Running Race Algorithm....</div>;
        setSimulateButtonHTML(generatingSimulation);
        window.$("#sim-progress-tracker").show();
        setTimeout(function() {
            simulateRaceResults();
        },3000);
    }

    function finishRaceSimulator() {
        
        var simulationComplete = <div id="sim-status" className="left">Saving Results....</div>
        setSimulateButtonHTML(simulationComplete);

        var tmp_simulate_button = <span className="round-border-button" style={{fontSize:'16px'}} title="Stake Stack to Enter Race"  onClick={() => watchInitialRace() }>
                WATCH RACE
            </span>;
        
        setTimeout(function() {
            updateSimulationBar(100);
            window.$("#sim-progress-tracker").fadeOut(1000);
        },2000);

        setTimeout(function() {
            setSimulateButtonHTML(tmp_simulate_button);
        },3000);
    }

    function updateSimulationBar(simpct) {
        $("#simulator-complete-pct").html(simpct+'%');
    }

    function addRacePrediction(pred_racer_id, pred_participant_id, pred_finish, pred_amount) {
        var addData = new Object();
        addData.raceID = props.raceDetails.race_id;
        addData.userID = userID;
        addData.racerID = racerID;
        addData.predRacerID = pred_racer_id;
        addData.predParticipantID = pred_participant_id;
        addData.predFinish = pred_finish;
        addData.predAmount = pred_amount;

        if(pred_finish == 2) {
            $("#freepredcheckbutton2").hide();
            $("#freepredcheckloading2").show();
        } else {
            $("#freepredcheckbutton").hide();
            $("#freepredcheckloading").show();
        }
        axiosInstance.post('race/racer_predict/',addData).then(result => {
            updateLineupResults();
            setTimeout(function(){
                if(pred_finish == 2) {
                    $("#freepredcheckbutton2").show();
                    $("#freepredcheckloading2").hide();
                } else {
                    $("#freepredcheckbutton").show();
                    $("#freepredcheckloading").hide();
                }
                
                var predictbox = document.getElementById('predictracerbox');
                var predict_instance = window.M.Modal.getInstance(predictbox);
                predict_instance.close();
            },1500);
        }).catch(error => {
            console.log(error);
        });
    }

    function addRacePredictionClick(e) {
        e.preventDefault();
        let pred_elem = e.target;
        if(pred_elem.dataset.predracerid) {
            var pred_racer_id = pred_elem.dataset.predracerid;
            var pred_participant_id = pred_elem.dataset.predparticipantid;
            var pred_finish = pred_elem.dataset.predfinish;
            var free_pred_amount = pred_elem.dataset.freepredamount;
            addRacePrediction(pred_racer_id, pred_participant_id, pred_finish, free_pred_amount);
        }
    }

    if(entrySet == false) {
        setEntry(true);
        showRaceLineup();
        setTimeout(function(){window.startPageModals();},500);
        setTimeout(function(){updateLineupResults(); },1500);
    }

    function watchInitialRace() {
        var addData = new Object();
        addData.raceID = encodeURIComponent(props.raceDetails.race_id);
        addData.racerID = encodeURIComponent(racerID);
        axiosInstance.post('race/racer_watched/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                window.location.href= '/#/watch/'+props.raceDetails.race_unique_id;
                dispatch(updateLocation("race_track"));
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function toggleRacerRow(e) {
        e.preventDefault();

        var racer_tmp_id = 0;
        let race_elem = e.target;
        while(racer_tmp_id == 0) {
            if(race_elem.dataset.racerrow) {
                racer_tmp_id = race_elem.dataset.racerrow;
            } else {
                race_elem = race_elem.parentElement;
            }
        }
        if($("#racer-stack-show-"+racer_tmp_id).is(':visible')) {
            $("#racer-stack-show-"+racer_tmp_id).slideUp();
            $("#racer-stack-show-icon-"+racer_tmp_id).html('expand_more');
        } else {
            $("#racer-stack-show-"+racer_tmp_id).slideDown();
            $("#racer-stack-show-icon-"+racer_tmp_id).html('expand_less');
        }
    }

    function openPredictRacerBox(e) {
        e.preventDefault();

        var pred_racer_id = e.target.dataset.racerid;
        var pred_participant_id = e.target.dataset.pracerid;
        var pred_finish = 1;
        var free_pred_amount = 0;
        var paid_pred_amount = 10;
        var racer_number = 1;
        var racer_name = 'Racer';
        var pilot_image = '';
        var pred_xp = 5;
        var win_chance = 50;

        for(var i = 0; i < racerList.length;i++) {
            if(racerList[i].racer_id == pred_racer_id) {
                racer_number = i+1;
                racer_name = racerList[i].racer_name;
                pilot_image = racerList[i].pilot_image_location;
                pred_xp = racerList[i].pred_xp;
                win_chance = racerList[i].win_chance;
            }
        }
        var firstpredbox = 'round-border-button';
        var secondpredbox = 'round-border-button';
        if(pred_racer_id == predicted_racer_id) {
            firstpredbox = 'round-border-button-green';
        } else if(pred_racer_id == predicted_racer_id2) {
            secondpredbox = 'round-border-button-green';
        }


        var selected_prediction_html = <><div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div class="row exr-new-dark-grey" style={{display:'flex',flexDirection:'row',position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="left" style={{position:'relative',zIndex:'10px'}}>
                                                    <div className="left" style={{padding:'10px',maxWidth:'80px',lineHeight:'0.75'}}>
                                                        <img src={pilot_image} style={{width:'100%',borderRadius:'5px'}} />
                                                    </div>
                                                    
                                                </div>
                                                
                                                
                                                <div className="left subheadline-text" style={{flex:'1',padding:'10px',marginTop:'0px',position:'relative',zIndex:'10px',fontSize:'14px',fontFamily:"helvetica"}}>
                                                    <div className="row" style={{margin:'0px',marginTop:'0px',marginBottom:'0px'}}>
                                                        <b>RACER {racer_number}:</b> {racer_name.toUpperCase()}
                                                    </div>
                                                    <div className="row" style={{margin:'0px',width:'100%',fontSize:'12px',marginBottom:'0px'}}>
                                                        This racer won {win_chance}% of the time across 1,000 test races.
                                                    </div>
                                                </div>
                                                <div className="left" style={{marginLeft:'0px',marginTop:'0px',width:"25vw",minWidth:'100px',fontSize:'16px',fontFamily:"helvetica"}}>
                                                    <div className="center center-align headline-text" style={{width:'100%',fontSize:'20px',marginTop:'10px',marginBottom:'0px'}}>
                                                        {win_chance}%
                                                    </div>
                                                    <div className="center center-align" style={{fontSize:'12px',width:'100%',marginTop:'-5px'}}>
                                                        WIN CHANCE
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div id="free-prediction-select-box" class="row exr-stats-light-grey predict-hover" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{fontSize:"14px",marginTop:"0px",padding:"5px 10px 0px 10px"}}>
                                                    <div className="left headline-text" style={{marginLeft:'5px'}}>FREE PREDICTIONS</div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    Play our free predictions game to build your reputation and earn XP for predicting winners.
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px",marginBottom:'15px'}}>
                                                    <div className="col s6 center-align" style={{padding:"0px"}}>
                                                        <b>Predict 1st Place</b><br/>
                                                        {pred_xp} XP<br/>
                                                        <div id="freepredcheckbutton" className={firstpredbox} style={{fontSize:'12px',marginTop:'10px',marginRight:'10px'}} 
                                                                title="Place Free Race Prediction" data-predposition="1"
                                                                data-predracerid={pred_racer_id}
                                                                data-predparticipantid={pred_participant_id}
                                                                data-predfinish="1"
                                                                data-freepredamount={free_pred_amount}
                                                                onClick={(e)=>addRacePredictionClick(e)}>
                                                            1ST PLACE
                                                        </div>
                                                        <div id="freepredcheckloading" className="preloader-wrapper small active splash-modal-loader right" 
                                                                style={{height:"25px",width:"25px",marginRight:'60px'}}>
                            
                                                            <div className="spinner-layer spinner-red-only">
                                                                <div className="circle-clipper left">
                                                                    <div className="circle"></div>
                                                                </div>
                                                                <div className="gap-patch">
                                                                    <div className="circle"></div>
                                                                </div>
                                                                <div className="circle-clipper right">
                                                                    <div className="circle"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col s6 center-align">
                                                        <b>Predict 2nd Place</b><br/>
                                                        {pred_xp/2} XP<br/>
                                                        <div id="freepredcheckbutton2" className={secondpredbox} style={{fontSize:'12px',marginTop:'10px'}} 
                                                                title="Place Free Race Prediction" 
                                                                data-predracerid={pred_racer_id}
                                                                data-predparticipantid={pred_participant_id}
                                                                data-predfinish="2"
                                                                data-freepredamount={free_pred_amount}
                                                                onClick={(e)=>addRacePredictionClick(e)}>
                                                            2ND PLACE
                                                        </div>
                                                        <div id="freepredcheckloading2" className="preloader-wrapper small active splash-modal-loader right" 
                                                                style={{height:"25px",width:"25px",marginRight:'60px'}}>
                                                                    
                                                            <div className="spinner-layer spinner-red-only">
                                                                <div className="circle-clipper left">
                                                                    <div className="circle"></div>
                                                                </div>
                                                                <div className="gap-patch">
                                                                    <div className="circle"></div>
                                                                </div>
                                                                <div className="circle-clipper right">
                                                                    <div className="circle"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div class="row" style={{position:'relative',margin:'0px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{marginTop:'5px',padding:"0px 15px",fontSize:'12px'}}>
                                                    * Note: XP rewards determined by the number of racers and the odds to win the race. Final winner rewards are handed out after the race ends. 
                                                </div>
                                            </div>
                                        </div></>;

        setSelectedPredictionHTML(selected_prediction_html);

        var predictbox = document.getElementById('predictracerbox');
        var predict_instance = window.M.Modal.getInstance(predictbox);
        predict_instance.open();

        setTimeout(function(){
            var freepredboxheight = $("#free-prediction-select-box").outerHeight();
            var paidpredboxheight = $("#paid-prediction-select-box").outerHeight();
            if(freepredboxheight > paidpredboxheight) {
                $("#paid-prediction-select-box").css({'min-height':freepredboxheight+'px'});
            }else {
                $("#free-prediction-select-box").css({'height':paidpredboxheight+'px'});
            }
        },100);
    }

    function showRaceHowToInfobox() {
        var infobox = document.getElementById('training_box_race_howto');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    return(<div className="row" style={{marginBottom:'10px'}}>
        <div id="predictbanner" className="col s12" style={{marginTop:"5px"}}>
            <div className="row exr-stats-light-grey" style={{position:'relative',margin:"5px",padding:"20px",borderRadius:'10px'}}>
                <div className="col s12" style={{position:'relative',zIndex:'10px',marginBottom:'10px',position:'relative'}}>
                    <div className="row headline-text" style={{fontSize:'20px',marginBottom:'5px'}}>
                        EXR PICK 'EM
                    </div>
                    <div className="row" style={{fontSize:'16px'}}>
                        Pick which racers you think will come in 1st and 2nd Place. 
                        Earn Prophet XP points for predicting correctly. Top prophets awarded prizes after each race.
                    </div>
                    <div className="row exr-light-green-text" style={{fontSize:'18px',fontFamily:"helvetica",marginBottom:'5px'}}>
                        <b>Your Predictions</b>
                    </div>
                    <div className="row" style={{fontSize:'16px',fontFamily:"helvetica",marginBottom:'5px'}}>
                        <b>1st Place:</b>&nbsp;{current_first_pick}
                    </div>
                    <div className="row" style={{fontSize:'16px',fontFamily:"helvetica",marginBottom:'5px'}}>
                        <b>2nd Place:</b>&nbsp;{current_second_pick}
                    </div>
                    {final_pick_summary}
                    <span className="material-icons material-symbols-outlined white-red-link" 
                            style={{position:'absolute', top:'0px',right:'-5px',fontSize:"24px"}} 
                            onClick={()=>showRaceHowToInfobox()} 
                            title="Learn How To Race">info</span>
                </div>
            </div>
        </div>
        <div className="col s12" style={{marginTop:'5px'}}>
            <div className="row" style={{margin:"0px",padding:"0px"}}>
                {race_results_html}
            </div>
        </div>
        <div id="predictracerbox" className="modal page-modal page-modal-full">
            <div className="modal-content" style={{paddingBottom:'5px'}}>
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>PREDICT WINNER</div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    {selected_prediction_html}
                </div>
            </div>
        </div>
            
    </div>);
};