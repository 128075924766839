class WebSocketService {
    constructor() {
        this.connection = null;
        this.listeners = new Map();
        this.connected = false;
        this.connectionCallback = null;
        this.reconnectDecay = 1000;
    }

    connect(url) {
        console.log(`[WS] connecting to: ${url}`);

        this.connection = new WebSocket(url);

        this.connection.onmessage = (message) => {
            const { action, data } = JSON.parse(message.data);
            // TODO: should use a JWT or similar
            // console.log('Message Sent: '+JSON.stringify(data));
            try {
                if (this.listeners.has(action)) {
                    const listeners = this.listeners.get(action);
                    for (const listener of listeners) {
                        listener(data);
                    }
                }
            } catch (error) {
                console.error("Error parsing message!", error);
            }
        };

        this.connection.onopen = () => {
            console.log("[WS] connection opened");
            this.connected = true;
            this.connectionCallback(true);
        };

        this.connection.onerror = (error) => {
            console.error("[WS] error:", error);
        };

        this.connection.onclose = () => {
            console.log("[WS] connection closed");
            this.connected = false;
            // simple retry
            if (this.url) {
                setTimeout(() => this.connect(this.url), this.reconnectDecay); // Reconnect after 3 seconds.
                this.reconnectDecay = this.reconnectDecay * 1.25;
            }
        };
    }

    disconnect() {
        if (this.connection) {
            this.connection.onclose = null; // Prevent reconnection attempt when disconnecting intentionally.
            this.connection.close();
            this.listeners.clear();
            this.connectionCallback(false);
            this.connectionCallback = null;
        }
    }

    send(data) {
        if (this.connection.readyState === WebSocket.OPEN) {
            this.connection.send(JSON.stringify(data));
        } else {
            console.error("[WS] connection is not open");
        }
    }

    addListener(event, callback) {
        console.log("adding listener for event: ", event);
        if (!this.listeners.has(event)) {
            this.listeners.set(event, []);
        }
        this.listeners.get(event).push(callback);
    }

    removeListener(event) {
        this.listeners.delete(event);
    }

    onConnectionChange(callback) {
        this.connectionCallback = callback;
    }
}

const wssManager = new WebSocketService();
export default wssManager;
