import {CameraAnchor} from "./cameraAnchor.js";

/**
 * A fixed position camera anchor
 */
export class CameraAnchorFixed extends CameraAnchor {
    /**
     * Construct a fixed position camera anchor
     * @param {Vector3} position The position for this anchor
     */
    constructor(position) {
        super();

        this.position.copy(position);
    }
}