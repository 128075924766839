import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import {ShareResultsBox, ShareResultsGraphic} from "../../../../Organisms";
import "../../race_landing.css";
import $ from "jquery";
import html2canvas from 'html2canvas';

let shareButtonDisplay = 'block';
export const ResultsViewSection = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    const [entrySet, setEntry] = useState(false);
    const [race_results_html, setResultsHTML] = useState('');
    const [resultsGraphic, setResultsGraphic] = useState('');
    let resultshareimage = React.createRef();

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    let items_checked = 'N';
    var racer_items = [];
    function showRaceResults() {
        let orderedList = new Array();
        let listnum = 0;
        // if(props.raceDetails.race_scoring == 'reverse') {
        //     for(var i = props.racerList.length; i > 0;i--) {
        //         for(var z = 0; z < props.racerList.length;z++) {
        //             if(i == props.racerList[z].race_final_position) {
        //                 orderedList[listnum] = props.racerList[z];
        //                 listnum = listnum+1;
        //             }
        //         }
        //     }
        // } else {
        //     for(var i = 0; i < props.racerList.length;i++) {
        //         for(var z = 0; z < props.racerList.length;z++) {
        //             if((i+1) == props.racerList[z].race_final_position) {
        //                 orderedList[listnum] = props.racerList[z];
        //                 listnum = listnum+1;
        //             }
        //         }
        //     }
        // }
        for(var i = 0; i < props.racerList.length;i++) {
            for(var z = 0; z < props.racerList.length;z++) {
                if((i+1) == props.racerList[z].race_final_position) {
                    orderedList[listnum] = props.racerList[z];
                    listnum = listnum+1;
                }
            }
        }
        
        createPageResults(orderedList);
    }

    function createPageResults(itemarr) {
        var section_html = [];
        var racer_attributes = ['speed', 'handling', 'acceleration', 'strength'];
        var pilot_attributes = ['focus', 'stamina', 'aggression', 'intelligence'];
        var booster_attributes = ['speed', 'handling', 'acceleration', 'strength', 'focus', 'stamina', 'aggression', 'intelligence'];
        shareButtonDisplay = 'block';
        for(var z = 0; z < itemarr.length;z++) {
            var pilot_image = itemarr[z].pilot_image_location;
            var racer_image = itemarr[z].ship_image_location;
            if(itemarr[z].racer_id == 2) {
                shareButtonDisplay = 'none';
            }
            // if(z < pilot_sample.length) {
            //     pilot_image = pilot_sample[z];
            // } else {
            //     pilot_image = pilot_sample[z-pilot_sample.length];
            // }
            // if(z < racer_sample.length) {
            //     racer_image = racer_sample[z];
            // } else {
            //     racer_image = racer_sample[z-racer_sample.length];
            // }

            var racer_name = itemarr[z].racer_name;
            var item_subtitle = 'SERF FLEET';
            
            var item_col_size = "col s12 l6";
            var item_image_height = '350px';
            var stats_box_height = '250px';
            if((itemarr.length > 2)&&($("#race-landing-shell").width() > 800)) {
                item_col_size = "col s12 m6 l4";
                item_image_height = '300px';
                stats_box_height = '200px';
            } else if ($("#race-landing-shell").width() < 550) {
                var item_col_size = "col s12";
                var item_image_height = '350px';
                var stats_box_height = '250px';
            }
            
            var final_place = itemarr[z].race_final_position
            var placetext = ordinal_suffix_of((z+1));
            var placecolor = 'white-text';
            if(z == 0) {
                placecolor = 'exr-red-text';
                placetext = 'Winner'
            }
            var racer_final_xp = itemarr[z].race_final_xp;
            var racer_final_exr = itemarr[z].race_final_coins;

            var exrcolor = '';
            if(racer_final_exr > 0) {
                exrcolor = ' exr-light-green-text';
            }

            /* Calculate Race Time */
            var racer_final_seconds = itemarr[z].race_final_time.toString();
            var millisplit = racer_final_seconds.split(".");
            if(millisplit.length == 1) {
                var millicount = '00';
                var totalseconds = parseInt(millisplit[0]);
            } else {
                var millicount = millisplit[1];
                var totalseconds = parseInt(millisplit[0]);
            }
            if(millicount.length == 1) {
                millicount = millicount+'0';
            }
            var totalmins = Math.floor(totalseconds / 60);
            var finalseconds = totalseconds - (totalmins*60);
            var finaltimetxt = '';
            if(totalmins < 10) {
                finaltimetxt = finaltimetxt+'0';
            }
            finaltimetxt = finaltimetxt+totalmins+'m ';
            if(finalseconds < 10) {
                finaltimetxt = finaltimetxt+'0';
            }
            finaltimetxt = finaltimetxt+finalseconds+'s ';
            
            finaltimetxt = finaltimetxt+millicount+'ms';

            var total_racer_predictors = itemarr[z].total_predictors;
            var predicted_html = <></>;
            var predicted_html_row = <></>;
            if(itemarr[z].racer_predicted > 0) {
                if(itemarr[z].racer_predicted_win > 0) {
                    predicted_html = <><div className="col s7">
                                            Your Prediction: <b className="exr-light-green-text">Correct</b>
                                        </div>
                                        <div className="col s5 exr-light-green-text right-align">
                                            {itemarr[z].racer_predicted_win_xp} XP Won
                                        </div></>;
                    predicted_html_row = <span className="exr-light-green-text" style={{fontFamily: 'NBInternationalPro-Light',fontSize:'16px'}}>(Predicted +{itemarr[z].racer_predicted_win_xp} XP)</span>;

                } else {
                    predicted_html = <><div className="col s7">
                                            Your Prediction: <b className="exr-red-text">Missed</b>
                                        </div>
                                        <div className="col s5 exr-red-text right-align">
                                            0 XP Won
                                        </div></>;
                    predicted_html_row = <span className="exr-red-text" style={{fontFamily: 'helvetica',fontSize:'14px'}}><b>(PREDICTION MISSED)</b></span>;
                }
            } else {
                predicted_html = <><div className="col s6">
                                            Total Predictors: {total_racer_predictors}
                                        </div>
                                        <div className="col s6 right-align">
                                            0 XP Won
                                        </div></>;
            }


            var tmp_html_div = <div className="col s12" style={{margin:"0px",padding:"0px",paddingRight:"10px"}}>
                                    <div className="divider-thin"></div>
                                </div>;
            
            /* Add Initial Line Seperator */
            var tmp_html_div2 = <><div className="col s12" style={{margin:"0px",padding:"0px",marginTop:'20px',fontSize:"16px"}}>
                                    <div className="col s12 m5">
                                        &nbsp;
                                    </div>
                                    <div className="col s12 m2 center-align">
                                        RPM Won
                                    </div>
                                    <div className="col s12 m2 center-align">
                                        XP Earned
                                    </div>
                                    <div className="col s12 m3 center-align">
                                        Finish Time
                                    </div>
                                </div>
                                <div className="col s12" style={{margin:"0px",padding:"0px",paddingRight:"10px"}}>
                                    <div className="divider-thin"></div>
                                </div></>;
            if((z == 3)&&($("#race-landing-shell").width() >= 700)) {
                section_html.push(tmp_html_div2);
            }
            if((z >= 3)&&($("#race-landing-shell").width() >= 700)) {
                var tmp_html_row = <div key={z} className="col s12" style={{margin:"0px",padding:"0px",marginTop:'8px'}}>
                                <div className="row white-text" style={{marginBottom:'0px',fontSize:"16px"}}>
                                    <div className="col s12 m5">
                                        <div className="left headline-text center-align" style={{fontSize:"24px",marginRight:"20px",letterSpacing:"-4px",width:"35px",marginTop:"10px"}}>
                                            {final_place}
                                        </div>
                                        <div className="left" style={{marginRight:"20px",width:"50px"}}>
                                            <img src={pilot_image} width="50px" style={{borderRadius:"5px"}} crossOrign='anonymous' />
                                        </div>
                                        <div className="left headline-text" style={{marginTop:"15px"}}>
                                            {racer_name.toUpperCase().substring(0,15)} {predicted_html_row}
                                        </div>
                                    </div>
                                    <div className="col s12 m2 center-align" style={{marginTop:"15px"}}>
                                        {racer_final_exr} RPM
                                    </div>
                                    <div className="col s12 m2 center-align" style={{marginTop:"15px"}}>
                                        {racer_final_xp} XP
                                    </div>
                                    <div className="col s12 m3 center-align" style={{marginTop:"15px"}}>
                                        {finaltimetxt}
                                    </div>
                                </div>
                            </div>;
                section_html.push(tmp_html_row);

                /* Add Next Line Seperator */
                section_html.push(tmp_html_div);

            } else {
                var tmp_html = <div key={z} className={item_col_size+" finish-screen-position"} style={{margin:'0px',padding:'0px',paddingLeft:"4px",paddingRight:"4px",minWidth:'250px',minHeight:'300px'}}><div id={"fboard"+z} className="final-results-col exr-new-light-grey">
                                <div className="row finish-screen-racer-name white-text" style={{fontSize:"16px"}}>
                                    <span className={placecolor}><b>{placetext.toUpperCase()}:</b> </span>
                                    <span className="white-text">&nbsp;{racer_name.toUpperCase().substring(0,15)}</span>
                                </div>
                                <div className="row finish-screen-racer-divider"></div>
                                <div className="row finish-screen-racer-image" style={{height:item_image_height,background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}>
                                </div>
                                <div className="row finish-screen-racer-sub">
                                    <div className="left finish-screen-racer-ship"  style={{height:stats_box_height,background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
                                    </div>
                                    <div className="left finish-screen-racer-stats exr-new-dark-grey" style={{minHeight:stats_box_height}}>
                                        <div className="finish-screen-stats-row">
                                            RPM Won
                                        </div>
                                        <div className={"finish-screen-stats-subrow"+exrcolor}>
                                            {racer_final_exr}
                                        </div>
                                        <div className="finish-screen-stats-row">
                                            XP Earned
                                        </div>
                                        <div className="finish-screen-stats-subrow">
                                            {racer_final_xp}
                                        </div>
                                        <div className="finish-screen-stats-row">
                                            Finish Time
                                        </div>
                                        <div className="finish-screen-stats-subrow">
                                            {finaltimetxt}
                                        </div>
                                    </div>
                                </div>
                                <div className="row finish-screen-racer-sub exr-new-dark-grey" style={{borderRadius:'5px',padding:'7px 5px'}}>
                                    {predicted_html}
                                </div>
                            </div>
                        </div>;
                section_html.push(tmp_html);
            }
        }

        setResultsHTML(section_html);
        setResultsGraphic(<ShareResultsGraphic raceID={props.raceDetails.race_id} raceHash={props.raceDetails.race_unique_id}  resultData={itemarr} />);

        setTimeout(function(){
            window.startPageModals();
            resizeResultsImages();
        },50);
    }

    async function generateResultsImage() {
        // Temporarily Show Element to Screenshot
        var timg = document.getElementById("shareablelogo");
        timg.crossOrigin = 'anonymous';
        $("#resultsimagebox").show();
        var imageboundaryelement = document.getElementById("resultsimagebox");
        var final_image = await html2canvas(imageboundaryelement,{
                windowWidth: imageboundaryelement.scrollWidth,
                windowHeight: imageboundaryelement.scrollHeight,
                backgroundColor: '#000000',
                // width: $("resultshareimage").innerWidth(),
                allowTaint: true,
                useCORS: true
            }).then(function(canvas) {
                // Hide Screenshot Element
                $("#resultsimagebox").hide();
                //$("resultshareimage").html(canvas);
                // Update Download Link With Canvas
                //resultshareimage = canvas;
                var downloadbutton = document.getElementById('share_download_link');
                downloadbutton.addEventListener('click', function (e) {
                    var dataURL = canvas.toDataURL('image/png');
                    downloadbutton.href = dataURL;
                });
                
                setTimeout(function(){showShareBox()},750);
            });
    }

    function showShareBox() {
        // Start Modals
        var shareimagebox = document.getElementById('result-share-box');
        var shareimage_instance = window.M.Modal.getInstance(shareimagebox);
        shareimage_instance.open();
    }

    if(entrySet == false) {
        setEntry(true);
        showRaceResults();
    }

    function resizeResultsImages() {
        $(".finish-screen-racer-image").each(function(){
            var newheight=$(this).width();
            $(this).css({'height':newheight+'px'});
        });
        var shipheight = 100;
        $(".finish-screen-racer-ship").each(function(){
            var newheight=$(this).width();

            if(newheight < 165) {
                newheight = 165;
            }
            shipheight = newheight;
            $(this).css({'height':newheight+'px'});
        });

        $(".finish-screen-racer-stats").each(function(){
            var newheight=$(this).width();
            $(this).css({'height':shipheight+'px','minHeight':shipheight+'px'});
        });
    }

    window.addEventListener("resize", () => {
        resizeResultsImages();
    });

    function goToWorkshop() {
        window.location.href= '/#/workshop';
    }

    function viewRacePlayback() {
        if((props.raceDetails.race_simulated == 1)&& ((props.raceDetails.admin_gated == 0)||(racerID == 1)||(racerID == 3)||(racerID == 5)) ) {
            window.location.href= '/#/watch/'+props.raceDetails.race_unique_id;
            dispatch(updateLocation("race_track"));
        } else {
            alert('Race Not Ready To View Yet');
        }
    }

    return(<div className="row">
        <div className="col s12 headline-text" style={{fontSize:"24px",marginTop:"15px"}}>
            <div className="left" style={{marginLeft:'5px'}}>RACE RESULTS</div>
            <div className="right" style={{marginRight:'10px',display:shareButtonDisplay}}>
                <span className="round-border-button" style={{fontSize:'16px'}} onClick={() => generateResultsImage() }>
                    SHARE
                </span>
            </div>
            <div className="right" style={{marginRight:'10px'}}>
                <span className="round-border-button" style={{fontSize:'16px'}} title="Watch Race"  onClick={() => viewRacePlayback() }>
                    WATCH RACE
                </span>
            </div>
        </div>
        <div className="col s12">
            <div className="divider-thin"></div>
        </div>

        <div className="col s12" style={{marginTop:'15px'}}>
            <div className="row" style={{margin:"0px",padding:"0px",marginBottom:"20px"}}>
                {race_results_html}
            </div>
        </div> 
        {resultsGraphic}
        <ShareResultsBox raceID={props.raceDetails.race_id} raceHash={props.raceDetails.race_unique_id} />
        <div id="resultshareimage">
        </div>
    </div>);
};