const ethers = require("ethers");
require("dotenv").config();

const abi721 = require("./config/abi721.json");
const abi1155 = require("./config/abi1155.json");

const ERC_721_INTERFACE_ID = "0x80ac58cd";
const ERC_1155_INTERFACE_ID = "0xd9b67a26";

// Mapping of Chain IDs to RPC URLs.
const RPC_URLS = {
    1: "https://eth.llamarpc.com",
    1284: "https://moonbeam.api.onfinality.io/public",
    // Add other networks as needed
};

export const tokenGate721 = async (contractAddress, userAddress, chainId) => {
    // Check if the chainId exists in our mapping
    if (!RPC_URLS[chainId]) {
        throw new Error("Unsupported chain ID");
    }

    const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[chainId]);

    // assume the contract is 721
    let contract = new ethers.Contract(contractAddress, abi721, provider);

    // if it doesn't support the interface for 721, throw an error
    try {
        if (!contract.supportsInterface(ERC_721_INTERFACE_ID)) {
            throw new Error("Contract address doesn't support ERC721 interface");
        }
    } catch (err) {
        console.error(`Call to supportsInterface failed: ${err}`);
        return false;
    }

    // check if the user owns a token
    try {
        const tokenBalance = await contract.balanceOf(userAddress);
        if (tokenBalance > 0) {
            return true;
        } else return false;
    } catch (error) {
        console.error(`Failed to check ownership: ${error}`);
        return false;
    }
}

export const tokenGate1155 = async (contractAddress, userAddress, tokenId, chainId) => {
    // Check if the chainId exists in our mapping
    if (!RPC_URLS[chainId]) {
        throw new Error("Unsupported chain ID");
    }

    const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[chainId]);

    // assume the contract is 1155
    let contract = new ethers.Contract(contractAddress, abi1155, provider);

    // if it doesn't support the interface for 1155, throw an error
    try {
        if (!contract.supportsInterface(ERC_1155_INTERFACE_ID)) {
            throw new Error("Contract address doesn't support ERC1155 interface");
        }
    } catch (err) {
        console.error(`Call to supportsInterface failed: ${err}`);
        return false;
    }

    // check if the user owns a token
    try {
        const tokenBalance = await contract.balanceOf(userAddress, tokenId);
        if (tokenBalance > 0) {
            return true;
        } else return false;
    } catch (error) {
        console.error(`Failed to check ownership: ${error}`);
        return false;
    }
}