import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import "../../league_landing.css";
import $ from "jquery";

let total_page_results = 25;
let leaderboardList = [];
let current_leaderboard_index = 0;
let current_leaderboard_id = 0;
let current_leaderboard_type = 'racing';
let current_tiebreaker = '';
let current_leaderboard_whitelisted = 0;
let current_leaderboard_pagenum = 0;
let racerSectionHTMLArray = [];

export const LeagueLeaderboard = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [leaderboard_primary_title, setLeaderboardPrimaryTitle] = useState('Leaderboard');
    const [currentWeekThrough, setWeekThrough] = useState('');
    const [customLeaderboardNavigation, setCustomLeaderboardNavigation] = useState('');
    const [leaderboardSectionHTML, setLeaderboardSectionHTML] = useState('');
    const [leaderboardExplainerHTML, setLeaderboardExplainerHTML] = useState('');
    const [showMoreRacersHTML, setShowMoreRacersHTML] = useState('');
    const [leagueID, setLeagueID] = useState(props.league_id);
    const [leagueSeasonID, setLeagueSeasonID] = useState(props.league_season_id);

    const [leaderboardDisplay, setLeaderboardDisplay] = useState(props.display_type);
    const [leaderboardDisplaySource, setLeaderboardDisplaySource] = useState(props.view_source);
    const [leagueGroupCount, setLeagueGroupCount] = useState(props.group_count);
    const [leaderboardCustom, setLeaderboardCustom] = useState(props.custom_boards);
    const [leaderboardTiebreaker, setLeaderboardTiebreaker] = useState(props.board_tiebreaker);
    const [leaderboardClosed, setLeaderboardClosed] = useState(props.leaderboard_final);
    const [leagueAccentColor, setLeagueAccentColor] = useState(props.accent_color);
    const [paddingSource,setPaddingSource] = useState('5px');
    
    const results_per_page = 25;

    const bot_pilots = ['https://media.exiledracers.com/assets/bots/PILOT_AUGMENT_BOT.png',
    'https://media.exiledracers.com/assets/bots/PILOT_MERCENARY_BOT.png',
    'https://media.exiledracers.com/assets/bots/PILOT_SERF_BOT.png'];

    function grabSeasonLeaderboardsList() {
        if(props.view_source != 'league_home') {
            setPaddingSource('5px 0px');
        }
        if(leaderboardCustom == 0) {
            current_leaderboard_index = 0;
            current_leaderboard_id = 0;
            current_leaderboard_type = 'racing';
            current_tiebreaker = '';
            current_leaderboard_whitelisted = 0;
            grabSeasonLeaderboard(0);
        } else {
            current_leaderboard_index = 0;
            current_leaderboard_id = 0;
            current_leaderboard_type = 'racing';
            current_tiebreaker = '';
            current_leaderboard_whitelisted = 0;

            var addData = new Object();
            addData.leagueID = encodeURIComponent(leagueID);
            addData.leagueSeasonID = encodeURIComponent(leagueSeasonID);
            addData.userID = encodeURIComponent(userID);

            axiosInstance.post('league/season_leaderboard_list/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var leaderboard_list = result.data; 
                    leaderboardList = leaderboard_list;
                    if (leaderboard_list.length > 0) {
                        current_leaderboard_index = 0;
                        current_leaderboard_id = leaderboard_list[0]['league_leaderboard_id'];
                        current_leaderboard_type = leaderboard_list[0]['leaderboard_type'];
                        current_tiebreaker = leaderboard_list[0]['tiebreaker_rule'];
                        current_leaderboard_whitelisted = leaderboard_list[0]['leaderboard_whitelisted'];
                        if((leaderboard_list.length > 1)&&(props.view_source != 'league_home')) {
                            setLeaderboardPrimaryTitle('Leaderboards');
                            loadCustomLeaderboardNavigation(leaderboard_list);
                        }
                    }
                    grabSeasonLeaderboard(0);
                } else {
                    grabSeasonLeaderboard(0);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function grabSeasonLeaderboard(pagenum) {
        current_leaderboard_pagenum = pagenum;

        if(pagenum == 0) {
            setLeaderboardSectionHTML(<></>);
        }

        setLeaderboardExplainerHTML(<></>);
        if(current_leaderboard_id == 2) {
            loadLeaderboardUnlock(6);
        }

        var addData = new Object();
        addData.leagueID = encodeURIComponent(leagueID);
        addData.leagueSeasonID = encodeURIComponent(leagueSeasonID);
        addData.userID = encodeURIComponent(userID);
        addData.leaderboardID = encodeURIComponent(current_leaderboard_id);
        addData.leaderboardType = encodeURIComponent(current_leaderboard_type);
        addData.tiebreaker = encodeURIComponent(current_tiebreaker);
        addData.whitelisted = encodeURIComponent(current_leaderboard_whitelisted);

        addData.page = encodeURIComponent(current_leaderboard_pagenum)
        addData.max_results = encodeURIComponent(results_per_page);

        axiosInstance.post('league/season_leaderboard/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var leaderboard_details = result.data; 
                loadSeasonLeaderboard(leaderboard_details,pagenum);
            } else {
                loadSeasonLeaderboard([],pagenum);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadCustomLeaderboardNavigation(boardlist) {
        var board_list_html = [];
        for(var z = 0; z < boardlist.length;z++) {
            var leaderboard_id = boardlist[z]['league_leaderboard_id'];
            var leaderboard_name = boardlist[z]['leaderboard_name'];
            var leaderboard_type = boardlist[z]['leaderboard_type'];
            var primary_leaderboard = boardlist[z]['primary_leaderboard'];
            var leaderboard_order = boardlist[z]['display_order'];
            var tiebreaker_rule = boardlist[z]['tiebreaker_rule'];
            var total_auto_winners = boardlist[z]['total_auto_winners'];
            var total_random_winners = boardlist[z]['total_random_winners'];
            var sponsored_list = boardlist[z]['sponsored_by'];
            var whitelisted_board = boardlist[z]['leaderboard_whitelisted'];
            var whitelist_exclude_board = boardlist[z]['leaderboard_whitelist_exclude'];
            var game_unlock_id = boardlist[z]['leaderboard_game_unlock_id'];
            var unlock_title = boardlist[z]['unlock_title'];
            var unlock_description = boardlist[z]['unlock_description'];
            var unlock_method = boardlist[z]['unlock_method'];
            var unlock_image_url = boardlist[z]['unlock_image_url'];
            var backgroundlinkcolor = leagueAccentColor+'-grey-background-link';
            if(z == 0) {
                backgroundlinkcolor = leagueAccentColor+'-'+leagueAccentColor+'-background-link';
            }
            var tmp_html = <>
                <span key={"boardbutton_"+leaderboard_id} id={"boardbutton_"+leaderboard_id}  data-boardindex={z} 
                    className={"round-border-button left black white-text leaderboard_switch "+backgroundlinkcolor}
                    style={{marginTop:'20px',marginLeft:'0px',marginRight:'20px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'5px',padding:'5px 15px',height:'32px',position:'relative'}} 
                    title="Register For Exiled Racers" onClick={(e)=>switchLeaderboards(e)}>
                    <span className="subheadline-text" style={{margin:"2px 5px 0px 5px",fontSize:'14px'}}>
                        {leaderboard_name}
                    </span>
                </span>
            </>;

            board_list_html.push(tmp_html);
        }

        setCustomLeaderboardNavigation(board_list_html);
    }

    function switchLeaderboards(e) {
        e.preventDefault();
        var boardindex = -1;
        let list_elem = e.target;
        while(boardindex == -1) {
            if(list_elem.dataset.boardindex) {
                boardindex = list_elem.dataset.boardindex;
            } else {
                list_elem = list_elem.parentElement;
            }
        }
        
        current_leaderboard_id = leaderboardList[boardindex]['league_leaderboard_id'];
        current_leaderboard_type = leaderboardList[boardindex]['leaderboard_type'];
        current_tiebreaker = leaderboardList[boardindex]['tiebreaker_rule'];
        current_leaderboard_whitelisted = leaderboardList[boardindex]['leaderboard_whitelisted'];

        $(".leaderboard_switch").each(function(){
            $(this).removeClass(leagueAccentColor+"-"+leagueAccentColor+"-background-link").addClass(leagueAccentColor+"-grey-background-link");
        });
        $("#boardbutton_"+current_leaderboard_id).removeClass(leagueAccentColor+"-grey-background-link").addClass(leagueAccentColor+"-"+leagueAccentColor+"-background-link");

        grabSeasonLeaderboard(0);
    }

    function showMoreResultsRacers(pagenum) {
        $("#showmoreracersbutton").hide();
        $("#showmoreracersloading").show();
        grabSeasonLeaderboard(pagenum);
    }

    function loadSeasonLeaderboard(memberarr,pagenum) {
        var ranking_section_html = [];
        var group_section_html = [];
        var pilotcnt = 0;
        var current_group_number = 0;
        var current_member_standing = 1;
        var current_group_size = 0;
        for(var z = 0; z < memberarr.length;z++) {
            var racer_user_id = memberarr[z]['user_id'];
            var member_status = memberarr[z]['member_status'];
            var racer_name = memberarr[z]['racer_name'];
            var pilot_photo = memberarr[z]['pilot_image_thumbnail'];

            var group_number = memberarr[z]['group_number'];
            var custom_group_name = memberarr[z]['custom_group_name'];
            var league_division_number = memberarr[z]['league_division_number'];

            var current_group_standing = memberarr[z]['current_group_standing'];
            var current_group_underdog = memberarr[z]['current_group_underdog'];
            var current_group_wins = memberarr[z]['current_group_wins'];
            var current_group_xp = memberarr[z]['current_group_xp'];
            var current_group_rpm = memberarr[z]['current_group_rpm'];
            var current_group_underdog = memberarr[z]['current_group_underdog'];
            var current_group_pop = memberarr[z]['current_group_pop'];
            var current_group_pup = memberarr[z]['current_group_pup'];

            var new_group_seperator = 0;
            if (current_group_number != group_number) {
                current_group_number = group_number;
                new_group_seperator = 1;
                current_member_standing = 1;
                current_group_size = 0;
                for(var p = z; p < memberarr.length;p++) {
                    if(current_group_number == memberarr[p]['group_number']) {
                        current_group_size = current_group_size+1;
                    } else {
                        p = memberarr.length;
                    }
                }
            } else {
                current_member_standing = current_member_standing+1;
            }

            var leaderboard_winner_type = memberarr[z].leaderboard_winner_type;

            /* Calculate Race Time */
            // var total_races = memberarr[z].total_races;
            // var total_wins = memberarr[z].total_wins;
            // var win_pct = Math.round((total_wins / total_races)*100);

            var star_icon = '';
            var rowcolor = 'white-text';
            var rowlinkcolor = 'white-green-link';
            var rowbgcolor = '';
            var invite_icon = '';
            if(racer_user_id == userID) {
                //star_icon = <i className="material-icons exr-red-text">star</i>
                rowcolor = 'exr-light-green-text';
                rowbgcolor = 'exr-red-bg';
                rowlinkcolor = 'exr-light-green-text';
            } 
            
            var pilot_image = <div className="exr-stats-light-grey" data-invitename={racer_name} style={{width:'40px',height:'40px',borderRadius:'5px',marginBottom:'7px'}}></div>;
            if(pilot_photo !== null) {
                pilot_image = <img src={pilot_photo} width="40px" data-invitename={racer_name} style={{borderRadius:"5px",lineHeight:'1'}} />;
            }

            var showbattles = 'Y';
            var invite_name_button = <><div className="left" style={{marginLeft:"5px",marginRight:"10px",width:"40px"}}>
                                            {pilot_image}
                                        </div>
                                        <div className="left" style={{marginLeft:"0px",marginTop:"12px"}}>
                                        {racer_name}
                                        </div></>

            var leaderboardcolor = '';
            if(leaderboard_winner_type == 'top_racer') {
                leaderboardcolor = ' exr-new-light-grey';
                rowcolor = 'exr-'+leagueAccentColor+'-text';
            } else if(leaderboard_winner_type == 'random_draw') {
                leaderboardcolor = ' exr-new-light-grey';
                rowcolor = 'exr-'+leagueAccentColor+'-text';
            }

            

            if(new_group_seperator == 1) {

                if (group_section_html.length > 0) {
                    var col_class = "col s12";
                    if(props.display_type == 'grid') {
                        col_class = "col s12 l6";
                    }

                    var section_margin_top = '30px';
                    if(((group_number-1) == 1)&&(props.display_type == 'vertical')) {
                        
                        section_margin_top = '10px';
                    }
                    var tmp_group_wrapper = <div key={"leaderboardgroup"+(group_number-1)} className={col_class} style={{marginTop:section_margin_top}}>
                        {group_section_html}
                    </div>;
                    ranking_section_html.push(tmp_group_wrapper);

                }
                var final_group_name = 'GROUP '+group_number;
                if(props.group_count <= 1) {
                    final_group_name = '';
                } else if(custom_group_name !== null) {
                    if(custom_group_name.length > 0) {
                        final_group_name = final_group_name + '// '+custom_group_name.toUpperCase();
                    }
                }

                var show_group_names = 'block';
                if(props.group_count <= 1) {
                    show_group_names = 'none';
                }

                group_section_html = [];

                var tmp_group_start = <div className="row exr-dark-grey-bg" style={{margin:'0',padding:'7px 7px',paddingBottom:'15px',borderRadius:'5px'}}>
                            <div className="row exr-new-light-grey" style={{padding:'5px', margin:'0px',borderRadius:'5px'}}>
                                <div className="row" style={{fontSize:'14px',marginTop:'10px',marginBottom:'10px'}}>
                                    <div className="col s12 headline-text white-text center-align">
                                         {final_group_name}
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom:'0px',marginTop:'0px',fontSize:'12px'}}>
                                    <div className="left headline-text white-text" style={{paddingLeft:'20px',width:'10%'}}>
                                        #
                                    </div>
                                    <div className="left headline-text white-text" style={{width:'37%'}}>
                                        RACER
                                    </div>
                                    <div className="left headline-text center-align" title="Racing RPM Points" style={{width:'10%'}} >
                                        RPM
                                    </div>
                                    <div className="left headline-text white-green-link racer-row-sort-link center-align" title="Prediction XP Points" 
                                        style={{width:'10%'}} >
                                        XP
                                    </div>
                                    <div className="left headline-text white-green-link racer-row-sort-link center-align" title="Race Wins" 
                                        style={{width:'10%'}} >
                                        WIN
                                    </div>
                                    <div className="left headline-text white-green-link racer-row-sort-link center-align" title="Underdog Win Points" 
                                        style={{width:'10%'}} >
                                        DOG
                                    </div>
                                    <div className="left headline-text white-green-link racer-row-sort-link center-align" title="Position Over Predicted" 
                                        style={{width:'10%'}}  >
                                        POP
                                    </div>
                                </div>
                            </div>
                        </div>;
                if(current_leaderboard_type == 'garage') {
                    tmp_group_start = <div className="row exr-dark-grey-bg" style={{margin:'0',padding:'7px 7px',paddingBottom:'15px',borderRadius:'5px'}}>
                            <div className="row exr-new-light-grey" style={{padding:'5px', margin:'0px',borderRadius:'5px'}}>
                                <div className="row" style={{fontSize:'14px',marginTop:'10px',marginBottom:'10px'}}>
                                    <div className="col s12 headline-text white-text center-align">
                                         {final_group_name}
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom:'0px',marginTop:'0px',fontSize:'12px'}}>
                                    <div className="left headline-text white-text" style={{paddingLeft:'20px',width:'10%'}}>
                                        #
                                    </div>
                                    <div className="left headline-text white-text" style={{width:'42%'}}>
                                        RACER
                                    </div>
                                    <div className="left headline-text center-align" title="Items Used Points" style={{width:'15%'}} >
                                        ITEM PTS
                                    </div>
                                    <div className="left headline-text white-green-link racer-row-sort-link center-align" title="Item Wins Points" 
                                        style={{width:'15%'}} >
                                        WIN PTS
                                    </div>
                                    <div className="left headline-text white-green-link racer-row-sort-link center-align" title="Total Points" 
                                        style={{width:'15%'}} >
                                        TOTAL PTS
                                    </div>
                                </div>
                            </div>
                        </div>;
                } else if(current_leaderboard_type == 'prediction') {
                    tmp_group_start = <div className="row exr-dark-grey-bg" style={{margin:'0',padding:'7px 7px',paddingBottom:'15px',borderRadius:'5px'}}>
                            <div className="row exr-new-light-grey" style={{padding:'5px', margin:'0px',borderRadius:'5px'}}>
                                <div className="row" style={{fontSize:'14px',marginTop:'10px',marginBottom:'10px'}}>
                                    <div className="col s12 headline-text white-text center-align">
                                         {final_group_name}
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom:'0px',marginTop:'0px',fontSize:'12px'}}>
                                    <div className="left headline-text white-text" style={{paddingLeft:'20px',width:'10%'}}>
                                        #
                                    </div>
                                    <div className="left headline-text white-text" style={{width:'42%'}}>
                                        RACER
                                    </div>
                                    <div className="left headline-text center-align" title="Items Used Points" style={{width:'15%'}} >
                                        XP
                                    </div>
                                    <div className="left headline-text white-green-link racer-row-sort-link center-align" title="Item Wins Points" 
                                        style={{width:'15%'}} >
                                        WINS
                                    </div>
                                    <div className="left headline-text white-green-link racer-row-sort-link center-align" title="Total Points" 
                                        style={{width:'15%'}} >
                                        PICKS
                                    </div>
                                </div>
                            </div>
                        </div>;
                }
                group_section_html.push(tmp_group_start);
            }
            var racer_margin_top = '-10px';
            var racer_padding_bottom = '0px';
            if(current_member_standing == 1) {
                racer_margin_top = '-15px';
            }
            if((z+1) >= memberarr.length) {
                racer_padding_bottom = '20px';
            } else if(memberarr[(z+1)]['group_number'] != current_group_number) {
                racer_padding_bottom = '20px';
            }

            var rowkey = z+1+(pagenum*total_page_results)+'_'+current_leaderboard_id;
            var tmp_html = <div key={rowkey} className={"row exr-dark-grey-bg"} id={'leader_row_'+racer_user_id} 
                                style={{margin:'0',padding:'5px',marginBottom:'0px',paddingBottom:racer_padding_bottom,marginTop:racer_margin_top,borderRadius:'5px'}}>
                            <div className={"row "+rowcolor} style={{marginTop:'0px',marginBottom:'0px',fontSize:'14px',fontWeight:'900',paddingTop:'5px'}}>

                                <div className="left headline-text white-text center-align" style={{paddingLeft:'5px',width:'10%',fontSize:"14px",letterSpacing:"-1px",marginTop:"14px"}}>
                                    {current_member_standing}
                                </div>
                                <div className="left white-text" style={{width:'37%',overflow:'hidden'}}>
                                    <div className="left" style={{width:'200%'}}>
                                        {invite_name_button}
                                    </div>
                                </div>
                                <div className="left center-align" title="RPM" 
                                        style={{width:'10%',marginTop:'12px'}} >
                                    {current_group_rpm.toLocaleString("en-US")}
                                </div>
                                <div className="left center-align" title="XP" 
                                    style={{width:'10%',marginTop:'12px'}} >
                                    {current_group_xp.toLocaleString("en-US")}
                                </div>
                                <div className="left center-align" title="Wins" 
                                    style={{width:'10%',marginTop:'12px'}}  >
                                    {current_group_wins}
                                </div>
                                <div className="left center-align" title="Underdog Win Points" 
                                    style={{width:'10%',marginTop:'12px'}} >
                                    {current_group_underdog}
                                </div>
                                <div className="left center-align" title="Position Over Predicted" 
                                    style={{width:'10%',marginTop:'12px'}} >
                                    {current_group_pop}
                                </div>
                                
                            </div>
                        </div>;
            if(current_leaderboard_type == 'garage') {
                var current_garage_item_points = memberarr[z]['garage_item_points'];
                var current_garage_win_points = memberarr[z]['garage_win_points'];
                var current_garage_total_points = current_garage_item_points+current_garage_win_points;
                tmp_html = <div key={rowkey} className={"row exr-dark-grey-bg"} id={'leader_row_'+racer_user_id} 
                                style={{margin:'0',padding:'5px',marginBottom:'0px',paddingBottom:racer_padding_bottom,marginTop:racer_margin_top,borderRadius:'5px'}}>
                            <div className={"row "+rowcolor} style={{marginTop:'0px',marginBottom:'0px',fontSize:'14px',fontWeight:'900',paddingTop:'5px'}}>

                                <div className="left headline-text white-text center-align" style={{paddingLeft:'5px',width:'10%',fontSize:"14px",letterSpacing:"-1px",marginTop:"14px"}}>
                                    {current_member_standing}
                                </div>
                                <div className="left white-text" style={{width:'42%',overflow:'hidden'}}>
                                    <div className="left" style={{width:'200%'}}>
                                        {invite_name_button}
                                    </div>
                                </div>
                                <div className="left center-align" title="Items Used Points"
                                        style={{width:'15%',marginTop:'12px'}} >
                                    {current_garage_item_points.toLocaleString("en-US")}
                                </div>
                                <div className="left center-align" title="Item Win Points"
                                    style={{width:'15%',marginTop:'12px'}} >
                                    {current_garage_win_points.toLocaleString("en-US")}
                                </div>
                                <div className="left center-align" title="Total Points"
                                    style={{width:'15%',marginTop:'12px'}}  >
                                    {current_garage_total_points}
                                </div>
                            </div>
                        </div>;
            } else if (current_leaderboard_type == 'prediction') {
                var current_prediction_xp = memberarr[z]['total_xp_won'];
                var current_prediction_wins = memberarr[z]['total_wins'];
                var current_prediction_predictions = memberarr[z]['total_predictions'];
                tmp_html = <div key={rowkey} className={"row exr-dark-grey-bg"} id={'leader_row_'+racer_user_id} 
                                style={{margin:'0',padding:'5px',marginBottom:'0px',paddingBottom:racer_padding_bottom,marginTop:racer_margin_top,borderRadius:'5px'}}>
                            <div className={"row "+rowcolor} style={{marginTop:'0px',marginBottom:'0px',fontSize:'14px',fontWeight:'900',paddingTop:'5px'}}>

                                <div className="left headline-text white-text center-align" style={{paddingLeft:'5px',width:'10%',fontSize:"14px",letterSpacing:"-1px",marginTop:"14px"}}>
                                    {current_member_standing}
                                </div>
                                <div className="left white-text" style={{width:'42%',overflow:'hidden'}}>
                                    <div className="left" style={{width:'200%'}}>
                                        {invite_name_button}
                                    </div>
                                </div>
                                <div className="left center-align" title="Prediction XP Earned"
                                        style={{width:'15%',marginTop:'12px'}} >
                                    {current_prediction_xp.toLocaleString("en-US")}
                                </div>
                                <div className="left center-align" title="Predictions Correct"
                                    style={{width:'15%',marginTop:'12px'}} >
                                    {current_prediction_wins.toLocaleString("en-US")}
                                </div>
                                <div className="left center-align" title="Total Predictions"
                                    style={{width:'15%',marginTop:'12px'}}  >
                                    {current_prediction_predictions}
                                </div>
                            </div>
                        </div>;
                
            }
            if(userID == memberarr[z]['user_id']) {
                group_section_html.push(tmp_html);
            } else if(((z+1) > 20)&&(props.group_count <= 1)&&(props.display_type == 'vertical')) {
                
            } else if(((z+1) > 20)&&(props.group_count <= 1)&&(props.display_type == 'grid')) {
                
            } else {
                group_section_html.push(tmp_html);
            }

            if(current_member_standing == props.playoff_line_top) {
                var rowkey = z+1+(pagenum*total_page_results)+'break';
                var tmp_html = <div key={rowkey} className="row exr-dark-grey-bg center-align"
                                style={{height:'15px',margin:'0',marginBottom:'10px',marginTop:racer_margin_top,borderBottom:'1px dashed #AAE167'}}>
                                    <div className={"row "+rowcolor} style={{marginTop:'5px',marginBottom:'0px',fontSize:'16px',fontWeight:'900',paddingTop:'7px'}}>
                                        <div className="col s12" style={{overflow:'hidden',fontSize:'10px'}}>
                                            <span className="exr-dark-grey-bg exr-light-green-text" style={{padding:'5px'}}>PLAYOFF LINE</span>
                                        </div>
                                    </div>
                        </div>;
                group_section_html.push(tmp_html);
            }
            
            if((props.playoff_line_bottom > 0)&&((current_member_standing+props.playoff_line_bottom) == current_group_size)) {
                
                var rowkey = z+1+(pagenum*total_page_results)+'bbreak';
                var tmp_html = <div key={rowkey} className="row exr-dark-grey-bg center-align"
                                style={{height:'15px',margin:'0',marginBottom:'10px',marginTop:racer_margin_top,borderBottom:'1px dashed #606060'}}>
                                    <div className={"row "+rowcolor} style={{marginTop:'5px',marginBottom:'0px',fontSize:'16px',fontWeight:'900',paddingTop:'7px'}}>
                                        <div className="col s12" style={{overflow:'hidden',fontSize:'10px'}}>
                                            <span className="exr-dark-grey-bg exr-light-grey-text" style={{padding:'5px'}}>BOTTOM LINE</span>
                                        </div>
                                    </div>
                        </div>;
                group_section_html.push(tmp_html);
            }

        }

        if (group_section_html.length > 0) {
            var col_class = "col s12";
            if((props.display_type == 'grid')&&(props.group_count > 1)) {
                col_class = "col s12 l6";
            }
            var section_margin_top = '30px';
            if((group_number == 1)&&(props.display_type != 'grid')) {
                section_margin_top = '10px';
            }
            var tmp_group_wrapper = <div key={"leaderboardgroup"+group_number} className={col_class} style={{marginTop:section_margin_top}}>
                {group_section_html}
            </div>;
            ranking_section_html.push(tmp_group_wrapper);

        }

        

        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = racerSectionHTMLArray.concat(ranking_section_html);
        } else {
            final_section_html_array = ranking_section_html;
        }
        racerSectionHTMLArray = final_section_html_array;
        setLeaderboardSectionHTML(final_section_html_array);

        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        // if(ranking_section_html.length == total_page_results) {
        //     showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
        //                         <div id="showmoreracersbutton" className="center-align" style={{display:'table',margin:'0 auto'}} title="Show More Racers">
        //                             <span className="round-border-button" style={{fontSize:'16px'}} title="Show More Racers"  onClick={() => showMoreResultsRacers(nextpage) }>
        //                                 SHOW MORE RACERS
        //                             </span>
        //                         </div>
        //                         <div id="showmoreracersloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
        //                             <div className="spinner-layer spinner-red-only">
        //                                 <div className="circle-clipper left">
        //                                     <div className="circle"></div>
        //                                 </div>
        //                                 <div className="gap-patch">
        //                                     <div className="circle"></div>
        //                                 </div>
        //                                 <div className="circle-clipper right">
        //                                     <div className="circle"></div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>;
        // }
        setShowMoreRacersHTML(showmorebutton);
        
        setTimeout(function(){
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
        },500);
        setTimeout(function(){
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
        },2000);
    }

    function loadLeagueRacedayCompleted() {
        var weekthrough = 1;
        var racedayarr = props.racedays;
        for(var z = 0; z < racedayarr.length;z++) {
            var raceday_number = racedayarr[z]['raceday_number'];
            var date_entry_close = racedayarr[z]['date_race_entry_close'];
            var date_predictions_start = racedayarr[z]['date_race_prediction_start'];
            var date_predictions_close = racedayarr[z]['date_race_prediction_close'];
            var date_simulation_close = racedayarr[z]['date_race_simulation_start'];
            var date_viewing_open = racedayarr[z]['date_race_viewable'];
            var completed_flag = racedayarr[z]['race_completed'];
            var race_next_flag = racedayarr[z]['race_next'];
            var race_ondeck_flag = racedayarr[z]['race_ondeck'];
            var race_track_id = racedayarr[z]['race_track_id'];
            var race_track_galaxy = racedayarr[z]['race_track_galaxy'];
            var race_scoring = racedayarr[z]['race_scoring'];
            var race_laps = racedayarr[z]['race_laps'];
            var race_rpm = racedayarr[z]['race_rpm'];
            var raceday_races = racedayarr[z]['raceday_races'];

            if(completed_flag == 1) {
                weekthrough = z+1;
            } else {
                z = racedayarr.length;
            }
        }

        setWeekThrough(weekthrough);
    }


    window.$(document).ready(function(){
        var sel_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(sel_elems);
    });

    function submitUnlockWallet(unlockid) {
        var bifrost_wallet = $("#bifrostWallet").val();

        if(bifrost_wallet.length > 5) {
            
            var addData = new Object();
            addData.unlockID = encodeURIComponent(unlockid);
            addData.userID = encodeURIComponent(userID);
            addData.keyValue = encodeURIComponent(bifrost_wallet);

            $("#bifrost-send-button").hide();
            $("#bifrost-send-loading").show();

            axiosInstance.post('member/game_unlocks_request/',addData).then(result => {
                
                if(Object.keys(result.data).length > 0) {
                    setTimeout(function() {
                        $("#bifrost-send-button").show();
                        $("#bifrost-send-loading").hide();
                        $("#bifrostWallet").val('');
                        $("#update-unlock-wallet-button").show();
                        $("#add-unlock-wallet-section").hide();
                        loadLeaderboardUnlock(unlockid);
                    },3000);
                    
                } else {
                    alert('Whoops! Something went wrong, try again with your wallet.');
                    $("#bifrost-send-button").show();
                    $("#bifrost-send-loading").hide();
                }
            }).catch(error => {
                console.log(error);
                alert('Whoops! Something went wrong, try again with your wallet.');
                $("#bifrost-send-button").show();
                $("#bifrost-send-loading").hide();
            });
        } else {
            alert('Add A Valid Wallet');
        }
    }

    function loadLeaderboardUnlock(unlockid) {
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);

        axiosInstance.post('member/game_unlocks_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var unlock_details = result.data; 
                loadUnlocksList(unlock_details,unlockid)               
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function showWalletUpdate() {

        $("#update-unlock-wallet-button").hide();
        $("#add-unlock-wallet-section").slideDown();
    }

    function loadUnlocksList(unlockarr,unlockid) {
        var unlock_section_html = [];
        var unlockcnt = 0;
        var unlockstatus = '';
        var unlockwallet = '';
        var unlockimage = '';
        for(var z = 0; z < unlockarr.length;z++) {
            let unlock_id = unlockarr[z]['game_unlock_id'];
            let private_flag = unlockarr[z]['private_flag'];
            let unlock_badge_url = unlockarr[z]['unlock_badge_url'];
            let unlock_description = unlockarr[z]['unlock_description'];
            let unlock_image_url = unlockarr[z]['unlock_image_url'];
            let unlock_method = unlockarr[z]['unlock_method'];
            let unlock_title = unlockarr[z]['unlock_title'];
            let user_unlocked = unlockarr[z]['user_unlocked'];
            let user_unlock_status = unlockarr[z]['user_unlock_status'];
            let user_unlock_id = unlockarr[z]['user_unlock_id'];
            if((unlock_id == unlockid)&&(user_unlocked == userID)) {
                unlockstatus = user_unlock_status;
                unlockwallet = user_unlock_id;
                unlockimage = unlock_image_url;
            }
        }
        var unlockstatustxt = 'Not Connected';
        var unlockwallettxt = <></>;
        var unlockwallet_obf = unlockwallet.slice(0,6)+'...'+unlockwallet.slice(-15);
        if(unlockstatus == 'P') {
            unlockstatustxt = 'Added to Snapshot Check';
            unlockwallettxt = <><br/><span className="pink-dark-pink-text">Wallet:</span>{unlockwallet_obf}</>;
        } else if (unlockstatus == 'A') {
            unlockstatustxt = 'Confirmed';
            unlockwallettxt = <><br/><span className="pink-dark-pink-text">Wallet:</span>{unlockwallet_obf}</>;
        } else if (unlockstatus == 'R') {
            unlockstatustxt = 'vDOT Not Found On Wallet';
            unlockwallettxt = <><br/><span className="pink-dark-pink-text">Wallet:</span>{unlockwallet_obf}</>;
        }

        var bifrostsquare = 'https://media.exiledracers.com/assets/game/builder/Bifrost_Unlock_Badge_1707581792691.png';
        var tmp_bifrost_instructions = <><div key={"leaderboardgroup_instructions_"+unlockid} className="col s12" style={{marginTop:'20px'}}>
                <div className="row exr-dark-grey-bg" style={{fontSize:'16px',margin:'0',padding:'7px 7px',paddingBottom:'15px',borderRadius:'5px'}}>
                            <div className="row " style={{padding:'20px', marginBottom:'0px',borderRadius:'5px'}}>
                                <div className="col s12 l7" style={{marginBottom:'10px',padding:'20px'}}>
                                    <div className="row headline-text" style={{fontSize:'18px',marginTop:'-20px',marginBottom:'10px'}}>
                                        BIFROST RACING LEADERBOARD
                                    </div>
                                    <div className="row" style={{marginBottom:'0px',marginTop:'0px',width:'95%'}}>
                                        Join the Bifrost Racing Leaderboard to gain access to exclusive prize pool in the Pink Racing League. 
                                        This is an optional prize pool to join and as always DYOR about the risks of liquid staking before deciding to mint.
                                        <br/><br/>
                                        <span className="headline-text pink-dark-pink-text">ELIGIBILITY</span><br/>
                                        1. Hold the EXR Pink Racing Pass as a current racer in the Pink Racing League. Predictors not eligible.<br/>
                                        2. Mint vDOT using a Polkadot wallet on Bifrost: <a href="https://bifrost.app/vstaking/vDOT?network=polkadot"  target="_blank" className="white-pink-link-active">
                                            https://bifrost.app/
                                        </a>
                                        <br/>3. Connect the wallet you used on Bifrost to EXR by <span className="pink-dark-pink-text">Feb 23rd at 3PM EST</span>. 
                                        <br/>4. Hold the vDOT on Bifrost-Polkadot (under any form: free, single/LP farming) until the end of the Pink Racing League. vDOT ownership on Bifrost-Polkadot will be confirmed by snapshots.
                                        <br/><br/>
                                        Questions? Reach out directly to Bifrost on <a href="https://t.me/bifrost_finance"  className="white-pink-link-active" target="_blank">Telegram</a> for help and view more details on how to mint vDOT here: 
                                        <a href="https://twitter.com/BifrostFinance/status/1754475480172945632" className="white-pink-link-active" target="_blank">
                                        &nbsp;Minting Instructions
                                        </a>
                                   </div>
                                </div>
                                <div className="col s12 l5 exr-new-light-grey" style={{borderRadius:'10px',marginBottom:'10px',padding:'10px'}}>
                                    <div key={'unlock_leaderboard_'+unlockstatus+'_'+unlockwallet_obf} lassName="row" style={{marginBottom:'10px',display:'flex',flexDirection:'row'}}>
                                        <div style={{width:'125px',marginTop:"0px",margin:'0px 10px'}}>
                                            <div className="exr-new-light-grey center-align" style={{width:'100%',height:'125px',borderRadius:'10px',background:'url('+bifrostsquare+')',backgroundSize:'cover',backgroundPosition:'center'}}>
                                            </div>
                                        </div>
                                        <div style={{flex:'1',marginTop:"0px",padding:"0px 5px",margin:'0px 5px'}}>
                                            <div className="row" style={{margin:'0px',marginTop:'5px',marginBottom:'0px'}}>
                                                <div className="headline-text" style={{marginBottom:'5px',fontSize:'14px'}}>
                                                    LEADERBOARD ACCESS
                                                </div>
                                            </div>
                                            <div className="row" style={{margin:'0px',fontSize:'14px'}}>
                                                <span className="pink-dark-pink-text">Status:</span> {unlockstatustxt}
                                                {unlockwallettxt}
                                            </div>
                                            <div className="row" style={{margin:'0px',marginTop:'10px',fontSize:'14px',paddingRight:'5px'}}>
                                                Requires entry of Bifrost Wallet Address used on Bifrost to mint vDOT.
                                            </div>
                                            <div id="update-unlock-wallet-button" className="row white-pink-link-active" 
                                                style={{margin:'0px',marginTop:'10px',fontSize:'14px',paddingRight:'5px',display:'none'}} 
                                                onClick={()=>showWalletUpdate()}>
                                                Update Your Wallet
                                            </div>
                                            <div id="add-unlock-wallet-section" className="row" style={{width:'97%',display:'none',marginBottom:'0px'}}>
                                                <span className="left" style={{marginTop:'10px',width:'100%',fontSize:'14px',lineHeight:'1em !important',height:'calc(1em + 10px)'}}>
                                                    <span className="left" style={{marginLeft:'10px'}}>Bifrost Wallet:</span>
                                                </span>
                                                <span className="left" style={{marginTop:'2px',marginLeft:'2%',width:'98%',fontSize:'14px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Add Bifrost Wallet Address">
                                                    <input id="bifrostWallet" className="grey-bubble-input-round-small" 
                                                        style={{fontSize:'20px',marginLeft:'0px',margin:'0px',marginRight:'0px',border:'0px'}} />
                                                </span>
                                                <span id="bifrost-send-button" className="round-border-button right black white-text pink-dark-pink-background" 
                                                    style={{marginTop:'25px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'14px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'35px',position:'relative'}} 
                                                    title="Add Bifrost Wallet Address" onClick={()=>submitUnlockWallet(6)}>
                                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'30px'}}>
                                                        expand_circle_right
                                                    </span>
                                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'14px',letterSpacing:'1px'}}>
                                                        ADD WALLET
                                                    </span>
                                                </span>
                                                <span id="bifrost-send-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                                    style={{marginTop:'10px',display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                                    <div className="spinner-layer spinner-pink-only">
                                                        <div className="circle-clipper left">
                                                            <div className="circle"></div>
                                                        </div>
                                                        <div className="gap-patch">
                                                            <div className="circle"></div>
                                                        </div>
                                                        <div className="circle-clipper right">
                                                            <div className="circle"></div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </>;
        setLeaderboardExplainerHTML(tmp_bifrost_instructions);
    }

    if(entrySet == false) {
        setEntry(true);
        grabSeasonLeaderboardsList();
        loadLeagueRacedayCompleted();
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row white-text " style={{padding:'5px',margin:'0px',padding:paddingSource}}>
            <div className="col s12 exr-new-dark-grey" style={{borderRadius:'10px',margin:'0px',padding:'0px',minHeight:'100vh'}}>
                <div className="row" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px',minHeight:'70vh'}}>
                    <div className="row" style={{margin:'0px',marginBottom:'0px'}}>
                        <div className="col s6 m6 headline-text white-text left-align" style={{marginTop:'0px',fontSize:'20px'}}>
                            {leaderboard_primary_title.toUpperCase()}
                        </div>
                        <div className={"col s6 m6 white-text right-align exr-"+leagueAccentColor+"-text"} style={{fontSize:'14px',marginTop:'4px'}}>
                            THROUGH WEEK {currentWeekThrough}
                        </div>
                    </div>
                    <div className="row" style={{margin:'0px',marginBottom:'0px'}}>
                        <div className="col s12">
                            {customLeaderboardNavigation}
                        </div>
                    </div>
                    
                    {leaderboardSectionHTML}
                    {leaderboardExplainerHTML}
                </div>
            </div>
        </div>
        <div id="training_box_leaderboard" className="modal page-modal page-modal-large">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                        LEADERBOARDS
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                            Welcome to the EXR Leaderboards! Here you can find the top Racers by RPM and XP as well as the top Prophets correctly predicting races. 
                            <br/><br/><a className="exr-light-green-text">Training Rewards: </a> 
                            Your RPM and XP are your tickets to win rewards in training. 
                            The more RPM and XP you collect by the end of training the higher likelihood you have to win our training rewards game.
                            <br/><br/><a className="exr-light-green-text">Starblazer Badge: </a> 
                            The racers with the most RPM collected at the close of the EXR Training phase will earn the badge of Starblazer and have access to exclusive prizes. 
                            <br/><br/><a className="exr-light-green-text">Leaderboard Tiers: </a> 
                            Where you finish on the leaderboard may or may not determine which <a style={{color:'silver'}}>[REDACTED]</a> you are placed in once the EXR Training Phase is over and <a style={{color:'silver'}}>[REDACTED]</a> starts. 
                            <br/><br/><a className="exr-light-green-text">Prophet Rewards: </a> 
                            The Prophet status is one to be admired. Collect XP for correctly predicting races which give you a higher chance to get Prophet rewards. Top Prophets will earn badges for bragging rights.
                            <br/><br/>
                        </div>
                </div>
            </div>
        </div>
    </div>);
};