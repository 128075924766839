import { React, useEffect, useState} from "react";
import "./App.css";

import { ethers } from "ethers";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { updateLocation, signupLoading, updatePageHash } from "./redux/App/actions";
import { HashRouter, Routes, Route } from "react-router-dom";

import { 
  WalletHeader, 
  GlobalHeaderGrey, 
  GlobalHeaderAdmin, 
  GlobalHeaderPublic,
  GlobalHeaderBar,
  GlobalHeaderLearn,
  MusicPlayer,
  LoginLoading, 
  LoginCheck, 
  NotificationsGlobal,
  GlobalInfoboxes } from "./components/Organisms";
import {
  Landing,
  Staging,
  WorkshopHome,
  Race,
  RaceTrack,
  TrackBuilder,
  TrackUploader,
  ShipViewer,
  ShipChecker,
  Admin,
  MintPack,
  Mint,
  MintShow,
  WalletSync,
  PreLaunchSplash,
  MintLaunchSplash,
  PretrainingSplash,
  TrainingSplash,
  SignupFlow,
  SingleSignonFlow,
  SignupPickem,
  Splash,
  SplashEvent,
  RacesHome,
  RaceLanding,
  Sportsbook,
  Predictbook,
  LeaderboardOpen,
  PubRaceLanding,
  PubRaceHost,
  PickemHost,
  TrophyCase,
  Terms,
  RaceTrackRetro,
  LearnGameplay,
  LearnFactions,
  LearnItems,
  LeagueLanding
} from "./components/Pages";
import $ from "jquery";
function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

let backbutton = 'N';
let pagehash = '';
let hashcheckfunc = 0;
let finallocation = '';
let pageReloading = 0;

function AppHash() {
  const dispatch = useDispatch();
  const { active, chainId, library, error, account, deactivate, activate } = useWeb3React();
  const { location, page_hash, signup_loading, game_loading } = useSelector((state) => state.app);
  const [finalPage, setFinalPage] = useState(location);
  const [headerHTML,setHeaderHTML] = useState('');
  const [musicHTML, setMusicHTML] = useState('');
  
  var hashcheck = window.location.href.split("/");

  useEffect(() => {  
    if(window.location.href.indexOf('/trackmeet') >= 0) {
        window.location.href = 'https://trackmeet.exiledracers.com';
    }
    window.addEventListener("hashchange",
    function(){ 
      reloadPage();
    }, false);
    window.onpopstate = e => {
      reloadPage();
    }
    updatePageHeader();
  }, []);

  /* Check For Changes in Signup Loading */
  useEffect(() => {
    if (window !== undefined) {
      if(signup_loading === true) {
        window.$("#signuploadingscreen").fadeIn();
      } else {
        window.$("#signuploadingscreen").hide();
      }
    }
  }, [signup_loading]);

  function reloadPage() {
    pageReloading = 1;
    var page_name = "";
    pagehash = window.location.hash;
    if (pagehash.length > 1) {
      page_name = pagehash.replace("#", "");
    } else {
      pagehash = '';
    }
    if(page_name != '') {
      if(window.location.href.indexOf("reload") >= 0) {
        window.location.href= window.location.href.replace("reload","");
      }
      window.pageRefresh = 0;
    }
    setFinalPage(page_name);
    updatePageHeader();
  }

  function hashToPageRouter() {
    var page_name = "";
    pagehash = window.location.hash;
    if (pagehash.length > 1) {
      page_name = pagehash.replace("#", "");
    } else {
      pagehash = '';
    }

    let finallocation2 = '';
    if(page_name.includes("redirect")) {
      finallocation2 = "redirect";
    } else if(page_name.includes("workshop")) {
      finallocation2 = "workshop";
    } else if (page_name.includes("watch")) {
      finallocation2 = "race_track";
    } else if (page_name.includes("race_track")) {
      finallocation2 = "race_track";
    } else if (page_name.includes("raceway")) {
      finallocation2 = "raceway";
    } else if (page_name.includes("sportsbook")) {
      finallocation2 = "raceway";
    } else if (page_name.includes("arena")) {
      finallocation2 = "raceway";
    } else if (page_name.includes("predict")) {
      finallocation2 = "predict";
    } else if  (page_name.includes("open_leaderboard")) {
      finallocation2 = "open_leaderboard";
    } else if  (page_name.includes("pickem")) {
      finallocation2 = "pickem";
    } else if  (page_name.includes("rewards")) {
      finallocation2 = "rewards";
    } else if  (page_name.includes("trophy_case")) {
      finallocation2 = "rewards";
    } else if  (page_name.includes("achievements")) {
      finallocation2 = "rewards";
    } else if (page_name.includes("race/invite")) {
      finallocation2 = "race";
    } else if (page_name.includes("invite")) {
      finallocation2 = "invite";
    } else if (page_name.includes("register")) {
      finallocation2 = "register";
    } else if (page_name.includes("signin")) {
      finallocation2 = "signin";
    } else if(page_name.includes("gameplay")) {
      finallocation2 = "gameplay";
    } else if(page_name.includes("factions")) {
      finallocation2 = "factions";
    } else if(page_name.includes("gameitems")) {
      finallocation2 = "gameitems";
    } else if (page_name.includes("pub_race")) {
      finallocation2 = "pub_race";
    } else if (page_name.includes("test_track")) {
      finallocation2 = "test_track";
    } else if (page_name.includes("track_builder")) {
      finallocation2 = "track_builder";
    } else if (page_name.includes("track_uploader")) {
      finallocation2 = "track_uploader";
    } else if (page_name.includes("ship_viewer")) {
      finallocation2 = "ship_viewer";
    } else if (page_name.includes("ship_checker")) {
      finallocation2 = "ship_checker";
    } else if (page_name.includes("retro_track")) {
      finallocation2 = "retro_track";
    } else if (page_name.includes("racer_home")) {
      finallocation2 = "racer_home";
    } else if (page_name.includes("races_home")) {
      finallocation2 = "races_home";
    } else if (page_name.includes("race_landing")) {
      finallocation2 = "race_landing";
    } else if (
      page_name.includes("race") ||
      page_name.includes("race_details") ||
      page_name.includes("race_entry") ||
      page_name.includes("race_lineup") ||
      page_name.includes("race_results") ||
      page_name.includes("race_predict")
    ) {
      finallocation2 = "race";
    } else if (page_name.includes("send_mintpack")) {
      finallocation2 = "admin";
    } else if (page_name.includes("mint_launch")) {
      finallocation2 = "mint_launch";
    } else if (page_name.includes("mint")) {
      finallocation2 = "mint_show";
    } else if (page_name.includes("prelaunch")) {
      finallocation2 = "prelaunch";
      finallocation2 = "splash";
    } else if (page_name.includes("terms")) {
      finallocation2 = "terms";
    } else if (page_name.includes("pretraining")) {
      finallocation2 = "pretraining";
    } else if (page_name.includes("training_splash")) {
      finallocation2 = "training_splash";
    } else if (page_name.includes("single_signon")) {
      finallocation2 = "single_signon";
    } else if (page_name.includes("league")) {
      finallocation2 = "league";
    } else if (page_name.includes("signup")) {
      finallocation2 = "signup";
    } else if (page_name.includes("splash")) {
      finallocation2 = "splash";
    } else {
      finallocation2 = "splash";
    }

    return finallocation2;
  }

  // TODO: replace with proper navigation
  const router_training = {
    landing: <Landing />,
    test_track: <RaceTrack />,
    retro_track: <RaceTrackRetro />,
    staging: <Staging />,
    track_builder: <TrackBuilder />,
    track_uploader: <TrackUploader />,
    ship_viewer: <ShipViewer />,
    ship_checker: <ShipChecker />,
    mint_pack: <MintPack />,
    mint: <Mint />,
    admin: <Admin />,
    mint_show: <MintShow />,
    wallet_sync: <WalletSync />,
    prelaunch: <PreLaunchSplash />,
    pretraining: <PretrainingSplash />,
    training_splash: <TrainingSplash />,
    mint_launch: <MintLaunchSplash />,
    single_signon: <SingleSignonFlow />,
    signin: <SingleSignonFlow />,
    register: <SingleSignonFlow />,
    invite: <SingleSignonFlow />,
    signup: <SignupFlow />,
    splash: <Splash />,
    workshop: <WorkshopHome />,
    racer_home: <WorkshopHome />,
    race: <RaceLanding />,
    race_track: <RaceTrack />,
    races_home: <RacesHome />,
    race_landing: <RaceLanding />,
    raceway: <Sportsbook />,
    sportsbook: <Sportsbook />,
    arena: <Sportsbook />,
    open_leaderboard: <LeaderboardOpen />,
    pub_race: <PubRaceLanding />,
    pickem: <PickemHost />,
    trophy_case: <TrophyCase />,
    rewards: <TrophyCase />,
    watch: <RaceTrack />,
    terms: <Terms />,
    gameplay: <LearnGameplay />,
    gameitems: <LearnItems />,
    factions: <LearnFactions />
  };

  const router_prod = {
    wallet_sync: <WalletSync />,
    single_signon: <SingleSignonFlow />,
    signin: <SingleSignonFlow />,
    register: <SingleSignonFlow />,
    invite: <SingleSignonFlow />,
    splash: <Splash />,
    workshop: <WorkshopHome />,
    racer_home: <WorkshopHome />,
    race: <RaceLanding />,
    track_builder: <TrackBuilder />,
    track_uploader: <TrackUploader />,
    race_track: <RaceTrack />,
    races_home: <RacesHome />,
    race_landing: <RaceLanding />,
    raceway: <Sportsbook />,
    sportsbook: <Sportsbook />,
    arena: <Sportsbook />,
    open_leaderboard: <LeaderboardOpen />,
    pub_race: <PubRaceLanding />,
    pickem: <PickemHost />,
    trophy_case: <TrophyCase />,
    rewards: <TrophyCase />,
    watch: <RaceTrack />,
    terms: <Terms />,
    gameplay: <LearnGameplay />,
    gameitems: <LearnItems />,
    factions: <LearnFactions />
  };

  const router_pickem = {
    signup: <SignupPickem />,
    signin: <SignupPickem />,
    splash: <Splash />,
    race_track: <RaceTrack />,
    pub_race: <PubRaceLanding />,
    watch: <RaceTrack />,
    terms: <Terms />,
    gameplay: <LearnGameplay />,
    gameitems: <LearnItems />,
    factions: <LearnFactions />
  };

  let wallet_pages = ['splash','prelaunch','signup','mint_launch','admin']
  let music_pages = ['workshop','racer_home','races_home','race_landing','race_track','race','watch'];
  let grey_pages = ['workshop','racer_home','races_home','race_landing','ship_checker','race'];
  let spectator_pages = ['arena','sportsbook','open_leaderboard','raceway','trophy_case','achievements','rewards','predict','league'];
  let bar_pages = ['pub_race','pub_leaderboard'];
  let learn_pages = ['gameplay','factions','gameitems'];

  function updatePageHeader() {

    let currentpage = hashToPageRouter();

    if(spectator_pages.indexOf(currentpage) >= 0) {
      setHeaderHTML(<GlobalHeaderPublic currpage={currentpage} />);
    } else if (bar_pages.indexOf(currentpage) >= 0) {
      setHeaderHTML(<GlobalHeaderBar currpage={currentpage} />);
    } else if(grey_pages.indexOf(currentpage) >= 0) {
      // setHeaderHTML(<GlobalHeaderGrey currpage={currentpage} /><NotificationsGlobal currpage={currentpage} />);
      setHeaderHTML(<GlobalHeaderGrey currpage={currentpage} />);
    } else if(learn_pages.indexOf(currentpage) >= 0) {
      setHeaderHTML(<GlobalHeaderLearn currpage={currentpage} />);
    } else if(currentpage === 'track_uploader') {
      setHeaderHTML(<GlobalHeaderAdmin currpage={currentpage} />);
    } else {
      setHeaderHTML('');
    }
    // if (bar_pages.indexOf(currentpage) >= 0) {
    //   setHeaderHTML(<GlobalHeaderBar currpage={currentpage} />);
    // } 

    if(music_pages.indexOf(currentpage) >= 0) {
      setMusicHTML(<MusicPlayer volume={"50"} />);
    } else {
      setMusicHTML('');
    }
  }
  
  return (
    <div>
      <LoginLoading />
      <LoginCheck />
      {headerHTML}
      <HashRouter basename="/">
        <Routes>
          <Route path="wallet_sync" element={<WalletSync />} />
          <Route path="single_signon" element={<SingleSignonFlow />} />
          <Route path="signin" element={<SingleSignonFlow />} />
          <Route path="signin&p=login" element={<SingleSignonFlow />} />
          <Route path="signin/:ptype" element={<SingleSignonFlow />} />
          <Route path="register" element={<SingleSignonFlow />} />
          <Route path="register/:ptype" element={<SingleSignonFlow />} />
          <Route path="invite" element={<SingleSignonFlow />} />
          <Route path="invite/:invitername" element={<SingleSignonFlow />} />
          <Route path="splash_event" element={<SplashEvent />} />
          <Route path="splash" element={<Splash />} />
          
          <Route path="workshop" element={<WorkshopHome />} />
          <Route path="workshop/:homesection" element={<WorkshopHome />} />
          <Route path="racer_home" element={<WorkshopHome />} />
          <Route path="racer_home/:homesection" element={<WorkshopHome />} />
          
          <Route path="races_home" element={<RacesHome />} />
          <Route path="races_home/:homesection" element={<RacesHome />} />

          <Route path="race/:raceid" element={<RaceLanding />} />
          <Route path="race/:raceid/:racepage" element={<RaceLanding />} />
          <Route path="race_landing/:raceid" element={<RaceLanding />} />
          <Route path="race_landing/:raceid/:racepage" element={<RaceLanding />} />

          <Route path="race_track" element={<RaceTrack />} />
          <Route path="race_track/:raceid" element={<RaceTrack />} />
          <Route path="watch" element={<RaceTrack />} />
          <Route path="watch/:raceid" element={<RaceTrack />} />
          <Route path="watchlive" element={<RaceTrack />} />
          <Route path="watchlive/:raceid" element={<RaceTrack />} />
          
          <Route path="predict" element={<Predictbook />} />
          <Route path="raceway" element={<Sportsbook />} />
          <Route path="sportsbook" element={<Sportsbook />} />
          <Route path="arena" element={<Sportsbook />} />
          <Route path="open_leaderboard" element={<LeaderboardOpen />} />

          <Route path="trophy_case" element={<TrophyCase />} />
          <Route path="rewards" element={<TrophyCase />} />

          <Route path="league/:leagueid" element={<LeagueLanding />} />
          <Route path="league/:leagueid/:leaguepage" element={<LeagueLanding />} />

          <Route path="terms" element={<Terms />} />
          <Route path="gameplay" element={<LearnGameplay />} />
          <Route path="gameitems" element={<LearnItems />} />
          <Route path="factions" element={<LearnFactions />} />
          <Route path="redirect" element={<Splash />} />

          {/* PICKEM PAGES  */}
          <Route path="pub_race" element={<PubRaceLanding />} />
          <Route path="pickem" element={<PickemHost />} />
          <Route path="pubhost" element={<PubRaceHost />} />
          <Route path="pub/:pubid" element={<PubRaceHost />} />

          {/* ADMIN ONLY PAGES FOR TESTER PAGES  */}
          <Route path="track_builder/:raceid" element={<TrackBuilder />} />
          <Route path="track_uploader" element={<TrackUploader />} />

          <Route path="/" element={<Splash />} />


          {/* Tournament Splash Pages 
          <Route path="splash" element={<SplashEvent />} />
          <Route path="signin&p=login" element={<SingleSignonFlow />} />
          <Route path="signin/:ptype" element={<SingleSignonFlow />} />
          <Route path="register" element={<SingleSignonFlow />} />
          <Route path="register/:ptype" element={<SingleSignonFlow />} />
          <Route path="invite" element={<SingleSignonFlow />} />
          <Route path="invite/:invitername" element={<SingleSignonFlow />} />
          <Route path="/" element={<SplashEvent />} />
          <Route path="*" element={<SplashEvent />} />
          */}

          {/* WATCH PAGES
            <Route path="/" element={<RaceTrack />} />
            <Route path="*" element={<RaceTrack />} />
          */}
        </Routes>
      </HashRouter>

      {/* {musicHTML} */}
      
      <GlobalInfoboxes />
    </div>
  );
}

export default AppHash;