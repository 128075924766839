import { v4 as uuidv4 } from "uuid";
import socketManager from "../../../../../services/websocket";
import { useEffect, useState, useRef } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import "../../sportsbook.css";
import $ from "jquery";

let total_page_results = 25;
let current_pagenum = 0;
let sectionHTMLArray = [];
let chatoptionactive = '';

const Item = ({ children, onClick, type }) => {
    var finalFontSize = '14px';
    if (type == 'emoji') {
        finalFontSize = '20px';
    }
    return (
        <div onClick={onClick} className="left stats-light-grey-white-link" style={{fontSize:finalFontSize,padding:'5px 10px',borderRadius:'5px',marginRight:'5px',marginBottom:'5px'}}>
            {children}
        </div>
    );
};

export const RaceChat = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));`
    // }

    const [entrySet, setEntry] = useState(false);
    const [connected, setConnected] = useState(false);
    const [messages, setMessages] = useState([]);
    const [raceId, setRaceID] = useState(props.raceID);
    const [selectedRace, setSelectedRace] = useState(undefined);

    const [uuid, setUuid] = useState(window.localStorage.getItem("exr-chat-id") ? JSON.parse(window.localStorage.getItem("exr-chat-id")):undefined);

    useEffect(() => {
        if (selectedRace && connected && uuid && props.chatActive == 1) {
            (async () => {
                socketManager.send({
                    action: "join",
                    data: {
                        user: uuid,
                        raceId: props.raceID
                    },
                });
                console.log('socket joined');
            })();
        }
    }, [selectedRace, connected, uuid]);

    // reconnect every time the account changes
    useEffect(() => {
        if(props.chatActive == 1) {
            if (!uuid) {
                var uuidstring = uuidv4();
                setUuid(uuidstring);
                return;
            } else {
                // const websocketUrl = `${
                //     process.env.REACT_APP_SOCKET_ENV === "local"
                //         ? `ws://localhost:4545/${uuid}`
                //         : `wss://dr7cnz4yv6.execute-api.us-east-1.amazonaws.com/production?userId=${uuid}` // TODO: update DNS
                // }`;

                // craigs old socket - wss://6t5rbft8qb.execute-api.us-east-1.amazonaws.com/dev
                setTimeout(function() {
                    var currenthandle = document.getElementById("current-racer-handle").value;
                    var websocketUrl = 'wss://dr7cnz4yv6.execute-api.us-east-1.amazonaws.com/production?userId='+uuid+'&userName='+encodeURIComponent(currenthandle)
                    var webSocketDev = 'wss://6t5rbft8qb.execute-api.us-east-1.amazonaws.com/dev?userId='+uuid+'&userName='+encodeURIComponent(currenthandle)

                    
                    socketManager.disconnect();
                    socketManager.connect(webSocketDev);
                    socketManager.addListener("connections", handleConnections);
                    socketManager.addListener("invites", handleInvites);
                    socketManager.addListener("player-joined-race", handlePlayerJoined);
                    socketManager.addListener("player-left-race", handlePlayerLeft);
                    socketManager.addListener("race-chat", handleRaceChatMessage);
                    socketManager.addListener("race-chat-restricted", handleRaceChatRestrictedMessage);
                    socketManager.addListener("populate-recent-messages", handlePopulate);
                    socketManager.onConnectionChange((isConnected) => setConnected(isConnected));

                },500);
                
            }
        }
    }, [uuid]);


    const scrollTarget = useRef(null);
    const [selections, setSelections] = useState([]);

    const emojiList = ["🚀", "🛸", "🔥", "☄"];
    const phraseList = ["LFG", "Ship go BRRRR", "Smoke 'em"];
    const racerList = props.racerNames;

    function getHighlightColor(sender, name) {
        if (sender === name) {
            return "text-green-400";
        } else if (sender === "server") {
            return "text-rose-600";
        }
        return "";
    }

    function send() {
        if(props.chatActive == 1) {
            if (selections.length === 0) return;
            console.log(selections.join(" "));
            const msg2 = {
                action: "chat-restricted",
                data: {
                    user: uuid,
                    raceId: props.raceID,
                    msg: selections.join(" ")
                },
            };
            console.log({ msg2 });
            socketManager.send(msg2);
            setMessages([...messages, msg2]);

            setSelections([]);
            chatoptionactive = '';
            $("#exr-viewer-chat-emoji").removeClass("white-yellow-link-active");
            $("#exr-viewer-chat-phrase").removeClass("white-yellow-link-active");
            $("#exr-viewer-chat-name").removeClass("white-yellow-link-active");
            $("#chat-emoji-options").slideUp();
            $("#chat-phrase-options").slideUp();
            $("#chat-name-options").slideUp();
            $("#chat-option-divider").hide();
            $("#chat-send-box").slideUp();
        } else {
            alert('This Chat is Offline');
        }
    }

    useEffect(() => {
        // If the component has not been rendered yet, scrollTarget.current will be null
        if (scrollTarget.current) {
            scrollTarget.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages.length]);
    
    function handleChatTextAdd(index,texttype) {
        var finaladd = '';
        if(texttype == 'emoji') {
            finaladd = emojiList[index];
        } else if(texttype == 'phrase') {
            finaladd = phraseList[index];
        } else if(texttype == 'name') {
            finaladd = racerList[index];
        }
        if(finaladd.length > 0) {
            const updatedArr = [...selections, finaladd];
            setSelections(updatedArr);
            console.log(updatedArr);
        }
    }

    function handleChatTextRemove(e) {
        e.preventDefault();
        let text_id = '';
        let text_elem = e.target;
        while(text_id == '') {
            if(text_elem.dataset.textid) {
                text_id = text_elem.dataset.textid;
            }
        }

        if(text_id.length > 0) {
            let updatedSelections = [];
            for(var j = 0; j < selections.length; j++) {
                if(j !== parseInt(text_id)) {
                    updatedSelections.push(selections[j]);
                }
            }
            setSelections(updatedSelections);
        }
        console.log(text_id);
    }

    function handleConnections({ connections }) {
        console.log("new connections");
        console.log(connections);
    }

    function handleInvites({ invites }) {
        console.log("invites received");
    }

    function handlePlayerJoined(message) {
        console.log("player-joined received");
        console.log({ message });
        setMessages([...messages, message]);
    }

    function handlePlayerLeft(message) {
        console.log("[player-left-race] received");
        console.log({ message });
        setMessages([...messages, message]);
    }

    function handleRaceChatMessage(message) {
        console.log("[received][race-chat]");
        setMessages([...messages, message]);
    }

    function handleRaceChatRestrictedMessage(message) {
        console.log("[received][race-chat-restricted]");
        setMessages([...messages, message]);
    }

    function handlePopulate({ recentMessages }) {
        const recentMessagesArr = JSON.parse(recentMessages);
        const recentMessageObjs = recentMessagesArr.map((str) => JSON.parse(str));
        const recents = recentMessageObjs.map(({ action, data }) => data);
        setMessages([...messages, ...recents]);
    }

    function handleJoinRaceRoom(raceId) {
        console.log("[sending] joinRace");
        setSelectedRace(raceId);
    }

    function toggleChatOptions(optionname) {
        $("#exr-viewer-chat-emoji").removeClass("white-yellow-link-active");
        $("#exr-viewer-chat-phrase").removeClass("white-yellow-link-active");
        $("#exr-viewer-chat-name").removeClass("white-yellow-link-active");
        
        if(chatoptionactive != optionname) {
            $("#chat-emoji-options").hide();
            $("#chat-phrase-options").hide();
            $("#chat-name-options").hide();
            $("#chat-option-divider").show();
            $("#chat-send-box").slideDown();
            $("#exr-viewer-chat-"+optionname).addClass("white-yellow-link-active");
            if(chatoptionactive == '') {
                $("#chat-"+optionname+"-options").slideDown();
            } else {
                $("#chat-"+optionname+"-options").show();
            }
            
            chatoptionactive = optionname;
        } else {
            chatoptionactive = '';
            $("#chat-emoji-options").slideUp();
            $("#chat-phrase-options").slideUp();
            $("#chat-name-options").slideUp();
            $("#chat-option-divider").hide();
            if(selections.length == 0) {
                $("#chat-send-box").slideUp();
            }
        }
    }

    if(selectedRace != props.raceID) {
        setSelectedRace(props.raceID);
    }

    return (
        <div className="row" id="chat-message-display" style={{margin:'0px',padding:'0px',height:'100%',borderRadius:'10px',display: 'flex',flexDirection: 'column'}}>
            <div className="row exr-new-light-grey"  style={{margin:'0px',position:'relative',borderRadius:'10px',flex:'1',padding:'10px',overflowY:'scroll'}}>
                    {
                        
                    messages.map((msg, i) => (
                        <div key={`${msg?.sender}-${msg?.sentAt}`} className="col s12">
                            <div className="row" style={{margin:'0px',fontSize:'12px'}}>
                                    {new Date(msg?.sentAt).toLocaleTimeString(undefined, {
                                        timeStyle: "short",
                                    })} - 
                                    {msg?.sender === uuid
                                        ?'You'
                                        : msg?.sender}
                            </div>
                            <div className="row left-align" style={{margin:'0px',marginBottom:'10px'}}>{msg?.body}</div>
                        </div>
                    ))}
                    {/* <div ref={scrollTarget} /> */}
                    
            </div>

            <div className="row" style={{margin:'0px',padding:'0px',lineHeight:'1',borderRadius:'10px',marginBottom:'0px',fontSize:'12px'}}>
                
                <div className="row" style={{margin:'0px',position:'relative',padding:'10px 10px',borderRadius:'10px'}}>
                    <span id="exr-viewer-chat-emoji" 
                        className="left material-icons material-symbols-outlined white-yellow-link" 
                        style={{marginRight:'15px',fontSize:'20px'}}
                        onClick={()=>toggleChatOptions('emoji')}>add_reaction</span>
                    <span id="exr-viewer-chat-phrase" 
                        className="left material-icons material-symbols-outlined white-yellow-link" 
                        style={{marginRight:'15px',fontSize:'20px'}} 
                        onClick={()=>toggleChatOptions('phrase')}>quickreply</span>
                    <span id="exr-viewer-chat-name" 
                        className="left material-icons material-symbols-outlined white-yellow-link" 
                        style={{marginRight:'15px',fontSize:'20px'}}
                        onClick={()=>toggleChatOptions('name')}>group</span>
                    <span id="exr-viewer-chat-fakesend" className="right" style={{marginTop:'5px'}}>
                        <span>
                            <span className={"left material-icons "+(connected?"exr-light-green-text":"white-text")} style={{fontSize:'12px',marginRight:'5px',marginTop:'-1px'}}>circle</span>
                            {connected?<span className="left exr-light-green-text">ACTIVE</span>:<span className="white-text">OFFLINE</span>}
                        </span>
                    </span>
                </div>
                <div id="chat-option-divider" className="divider-thin" style={{display:'none',width:'96%',marginLeft:'2%',backgroundColor:'#606060',marginBottom:'0px'}}></div>
                <div id="chat-emoji-options" className="row" style={{display:'none',margin:'0px',position:'relative',padding:'0px 10px 5px',borderRadius:'10px',marginTop:'10px'}}>
                    {emojiList.map((item, i) => (
                        <Item  type="emoji" onClick={() => handleChatTextAdd(i,'emoji')}>{item}</Item>
                    ))}
                </div>
                <div id="chat-phrase-options" className="row" style={{display:'none',margin:'0px',position:'relative',padding:'0px 10px 5px',borderRadius:'10px',marginTop:'10px'}}>
                    {phraseList.map((item, i) => (
                        <Item  type="phrase" onClick={() => handleChatTextAdd(i,'phrase')}>{item}</Item>
                    ))}
                </div>
                <div id="chat-name-options" className="row" style={{display:'none',margin:'0px',position:'relative',padding:'0px 10px 5px',borderRadius:'10px',marginTop:'10px'}}>
                    {racerList.map((item, i) => (
                        <Item  type="racer" onClick={() => handleChatTextAdd(i,'name')}>{item}</Item>
                    ))}
                </div>                
                <div className="row" id="chat-send-box" style={{display:'none',margin:'0px',borderRadius:'5px',marginTop:'-1px',padding:'10px 0px',border:'1px solid #606060'}}>
                    <div className="col s9">
                        {selections.map((item, i) => (
                            <div className="left stats-trans-grey-link" style={{fontSize:'14px',padding:'5px',cursor:'pointer',marginTop:'0px',borderRadius:'5px'}} title="Click to Remove"
                            data-textid={i} onClick={(e) => handleChatTextRemove(e)}>{item}&nbsp;</div>
                        ))}
                    </div>
                    <div className="col s3">
                        <span className="right" style={{marginTop:'2px'}}>
                            <span onClick={send} className="material-icons white-yellow-link" style={{fontSize:'18px',cursor:'pointer'}}>
                                send
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};