import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance} from "../../../utils";
import "./constants.css";
import "./race_track.css";

import $ from "jquery";

import {Track} from "../../../webgl/race/track/track.js";
import {RaceLoader} from "../../../webgl/race/utils/raceLoader.js";
import {RacersData} from "../../../webgl/race/racers/racersData.js";
import {Random} from "../../../exr-webgl-hub/math/random.js";
import {RaceDesignLocal} from "../../../webgl/race/design/raceDesignLocal.js";
import {FinalResultScreen, FinalResultEmbedScreen, FinalResultMobileScreen} from "./Sections";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {RaceElements} from "../../../webgl/race/raceElements.js";
import {baseTrackEnvironment, augmentTrackEnvironment, serfTrackEnvironment, mercenaryTrackEnvironment} from "./track_environments";
let raceloadingstarted = false;
let race_loaded = false;
let race_load_pct = 0;
let race_id = 0
let race = null;
let trackEnvironment = baseTrackEnvironment;
let raceTrackGalaxy = '';
let hash = '';
let sportsbook = 0;
let racestarted = false;
let streamingadmin = false;
let cameras_available = [];
let starttime = '';
let starttimedate = '';
let countdownseconds = 1000;

export const RaceTrack = () => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const [pageSet, setPage] = useState(false);
    const [racersSet, setRacers] = useState(false);
    const [finalResultScreenHTML, setFinalResultScreenHTML] = useState('');
    const [streamCameraButtons, setStreamCameraButtons] = useState('');
    const [countdowntimer,setCountdownTimer] = useState('');
    const [settings_right_text,setSettingsRightText] = useState('');

    if(window.location.href) {
        if(window.location.host.indexOf("watch.exiled") >= 0) {
            hash = window.location.href.split("#/")[1].split("/")[0];
            sportsbook = 1;
        } else {    
            if(window.location.href.indexOf("#/") >= 0) {
                hash = window.location.href.split("#/")[1].split("/")[1];
            }
            
            if(window.location.href.indexOf("watchlive") >= 0) {
                sportsbook = 1;
            }
        }

        if (hash)
            var hasharr = hash.split("&");
            hash = hasharr[0];
            if(hasharr.length > 1) {
                for(var j = 0; j < hasharr.length;j++) {
                    if (hasharr[j] == 'streamAdmin') {
                        streamingadmin = true;
                    } else if (hasharr[j].indexOf("startTime") >= 0) {
                        var startsplit = hasharr[j].split("=");
                        var currentdate = new Date();
                        var timestamptxt = currentdate.getUTCFullYear() + "-" 
                        + ('0' + (currentdate.getUTCMonth()+1)).slice(-2) + '-'
                        + ('0' + currentdate.getUTCDate()).slice(-2)+ "T"
                        + startsplit[1] + ":00-04:00"

                        starttime = timestamptxt;
                        starttimedate = new Date(Date.parse(starttime));

                        updateCountdownTimer();
                    }
                }
            }
    }

    // else if(window.location.host.indexOf("3000") >= 0) {
    //     //hash = window.location.href.split("#/")[1].split("/")[0];
    //     sportsbook = 1;
    // } 

    if(hash == undefined) {
        hash = '';
    }
    const canvas = React.createRef();
    const container = React.createRef();
    const players = React.createRef();
    const minimap = React.createRef();
    const leaderboard = React.createRef();
    const lapInfo = React.createRef();
    const timeInfo = React.createRef();
    let currentcamera = 'cinema';
    let currentmusic = false;
    let currentsfx = false;

    
    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(setRacerLogin('',''));
    //     dispatch(updateLocation("splash"));
    //     window.location.href= '/';
    // }
    
    if((hash.length < 5)||(hash == '')) {
        if(window.location.host.indexOf("watch") >= 0) {
            window.location.href= 'https://wwww.exiledracers.com/';
        } else {
            window.location.href= '/#/workshop';
        }
    }

    /* Page Initialization - Ensures Only One Load */
    if(pageSet === false) {
        setPage(true);
        setTimeout(function(){
            if ((new URLSearchParams(window.location.href).get("skipIntro") !== null)||(new URLSearchParams(window.location.href).get("streamAdmin") !== null)) {
                slowLoadSimulator(80);
            } else {
                startRaceLoadingScreen();
                currentmusic = false;
                // setTimeout(function(){
                //     if(currentmusic === false) {
                //         window.$("#soundtogglebutton").click();
                //     }
                // },4000);
            }
        },1000);
    }
    
    window.addEventListener("resize", () => {
        updateCompleteDisplaySize();
    });

    function updateCompleteDisplaySize() {
        if (canvas.current && container.current) {
            canvas.current.width = container.current.clientWidth;
            canvas.current.height = container.current.clientHeight;

            race?.setSize(container.current.clientWidth, container.current.clientHeight);
            race?.resizeMinimap(minimap.current);
            updateDisplaySizes();
        }
    }

    function updateDisplaySizes() {
        let lbwidth = $("#leaderboardbox").width();
        let avatarheight = (lbwidth/10);
        let namesize = avatarheight/1.8;
        if (namesize < 12) {
            namesize = 12;
        } else if (namesize > 18) {
            namesize = 18;
        }
        let lbtitlesize =  avatarheight/1.5;
        if (lbtitlesize < 14) {
            lbtitlesize = 14;
        } else if (lbtitlesize > 20) {
            lbtitlesize = 20;
        }
        if (avatarheight < 20) {
            avatarheight = 0;
        }

        let lbnumber = namesize+2;
        if (lbnumber > 18) {
            lbnumber = 18;
        }
        $(".avatar").css({'height':avatarheight+'px'});
        $(".leaderboardrow h2").css({'font-size':'calc(10px + 0.35vw)'});
        $(".leaderboardrow i").css({'font-size':'calc(10px + 0.25vw)'});

        $(".leaderboardrow .number").css({'font-size':'calc(12px + 0.25vw)'});
        $("#leaderboardtitle").css({'font-size':'calc(14px + 0.25vw)'});
        if(window.innerWidth < 900) {
            var trackfactionname = $("#trackfactionname").html();
            $("#trackfactionname").html(trackfactionname.replace(" Planetary",""));
            $("#trackfactionname").css({'font-size':'calc(10px + 0.25vw)'});
        }
    }

    useEffect(() => {
        if(raceloadingstarted == false) {
            if(streamingadmin == true) {
                $("#game-container").addClass("streaminggamesize");
                $("#mobile-container").addClass("streamingmenuposition");
                $("#finalboardbox").addClass("streamingmenuposition");
                $("#finalboardbox").css({width:'40%'});
                $("#finalstandingstopleft").removeClass("m6");
                //$("#gamesettingsmenu").hide();
                $("#streamingmenu").show();
            }
            canvas.current.width = container.current.clientWidth;
            canvas.current.height = container.current.clientHeight;

            if((canvas.current !== null)&&(hash !== undefined)) {
                grabRaceDetails();
            }
            loadDropdowns();
            loadTooltips();

            // return () => {
            //     if (race)
            //         race.stopRendering();
            //     else
            //         raceLoader.abort();

            // };
        }
    }, [canvas]);

    function loadRaceResultsScreen() {
        if(window.location.host.indexOf("watch.exiledracers") >= 0) {
            setFinalResultScreenHTML(<FinalResultEmbedScreen race_unique_id={hash} />);
        } else {
            setFinalResultScreenHTML(<FinalResultScreen race_unique_id={hash} />);
        }
    }

    function grabRaceDetails() {
        if(racersSet == false) {
            var addData = new Object();
            addData.raceUniqueID = encodeURIComponent(hash);
            axiosInstance.post('race/details_list/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var race_details = result.data;

                    var league_admin_arr = race_details[0]['league_admins'].split(",");
                    var league_admin = 0;
                    for(var m = 0; m < league_admin_arr.length;m++) {
                        if ((league_admin_arr[m] > 0)&&(league_admin_arr[m] == userID)) {
                            league_admin = 1;
                        }
                    }

                    if((race_details[0]['admin_gated'] == 1)&&(racerID != 1)&&(racerID != 3)&&(racerID != 5)&&(streamingadmin == false)&&(league_admin != 1)) {
                        window.location.href= '/#/raceway';
                    } else {
                        race_id = race_details[0]['race_id'];
                        if (race_details[0].race_track_galaxy == 'serf') {
                            trackEnvironment = serfTrackEnvironment;
                        } else if (race_details[0].race_track_galaxy == 'augment') {
                            trackEnvironment = augmentTrackEnvironment;
                        } else if (race_details[0].race_track_galaxy == 'mercenary') {
                            trackEnvironment = mercenaryTrackEnvironment;
                        }
                        $("#game-settings-right-text").html('EXR RACING');
                        trackEnvironment['track']['race_sponsor'] = '';
                        if(race_details[0].league_season_id == 2) {
                            trackEnvironment['track']['race_sponsor'] = 'pink';
                            trackEnvironment['environment']['equirectangularHDR'] = 'https://media.exiledracers.com/assets/game/builder/skybox_hdr_augs_pink_1705521701495.exr';
                            $("#game-settings-right-text").html('EXR IS PINK').removeClass('white-text').addClass('exr-dark-pink-text');

                        } else if(race_details[0].league_season_id == 3) {
                            trackEnvironment['track']['race_sponsor'] = 'base';
                            trackEnvironment['environment']['equirectangularHDR'] = 'https://media.exiledracers.com/assets/game/builder/Skybox_HDR_Mercs_Base_1711640409603.exr';
                            //trackEnvironment['environment']['equirectangularHDR'] = 'https://media.exiledracers.com/assets/game/builder/Skybox_HDR_Augs_Base_1711640436903.exr';
                            $("#game-settings-right-text").html('BASE RACING').removeClass('white-text').addClass('exr-base-blue-text');
                        }

                        

                        raceTrackGalaxy = race_details[0].race_track_galaxy;
                        loadInitialRaceData();
                        updateRaceTrackFactionUI(raceTrackGalaxy);

                        /* If Results Not Viewable and In Sportsbook Start Loop To Wait For Leaderboard To Show */
                        if((race_details[0].results_viewable == 1)&&(sportsbook == 1)) {

                        } else if((race_details[0].results_viewable == 0)&&(sportsbook == 1)) {
                            updateResultsViewableLoop(race_details[0].race_id);
                        }
                        $("#globalmusicplayer").hide();
                    }
                } else {
                    window.location.href= '/#/workshop';
                }
            }).catch(error => {
                console.log(error);
                //window.location.href= '/#/workshop';
            });
        }
    }

    function updateResultsViewableLoop(traceid) {
        /* If Leaderboard Shows Mark Races As Viewable Otherwise Loop Around */
        if($("#finalboardbox").is(':visible')) {
            var addData = new Object();
            addData.raceID = encodeURIComponent(traceid);
            addData.collectionID = encodeURIComponent(2);
            axiosInstance.post('race/results_open/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    console.log('Race Results Open To View');
                }
            }).catch(error => {
                console.log(error);
            });
        } else {
            setTimeout(function(){
                updateResultsViewableLoop(traceid);
            },5000);
        }
    }

    function loadInitialRaceData() {
        if(canvas.current !== null) {
            //console.log('Starting Load');
            raceloadingstarted = true;
            const random = new Random(Random.hashToSeed(hash));
            const raceLoader = new RaceLoader(
                new Track(hash,'hash'),
                new RacersData(hash,racerID,trackEnvironment['track']['race_sponsor']),
                new RaceDesignLocal(trackEnvironment),
                new RaceElements(
                    canvas.current,
                    minimap.current,
                    players.current,
                    leaderboard.current,
                    lapInfo.current,
                    timeInfo.current),
                random);

            raceLoader.load(progress => {
                race_load_pct = progress;
                //console.log(race_load_pct);
            }).then(loadedRace => {
                race = loadedRace;
                race_loaded = true;
                $("#globalmusicplayer").hide();
                loadStreamingCameras();
            }).catch(() => {});  

            setRacers(true);
        }
    }

    function startRace() {
        //console.log('Starting Race');
        if(sportsbook == 0) {
            //updateLeadboardWidthCanvas();
        }
        if(race) {
            window.$("#startgamebutton").fadeOut();
            window.$("#startlineupbutton").fadeOut();
            window.$("#raceprogress").fadeIn();
            window.$("#playmusicbutton").click();
            
            race.startIntro(() => {
                racestarted = true;
                race.startRace();
                
            });
            if(sportsbook == 1) {
                setTimeout(function() {
                    updateCameraView('Leader');
                },20000);
            }
        }
    }

    

    function startLineup() {
        race?.startLineup();
    }

    function showStartBanner() {
        updateRaceBannerText('GET READY!',1000);
        setTimeout(function() {
            updateRaceBannerText('GO!',3000);
        },3000);
    }

    function showSportbookBanner() {
        updateRaceBannerText('GET READY!',1000);
        setTimeout(function() {
            updateRaceBannerText('STARTING IN',1000);
        },3000);
        setTimeout(function() {
            startBannerLoop(5);
        },5000);
    }

    function startBannerLoop(num) {
        if(num == 0) {
            startRace();
        } else {
            updateRaceBannerText(num.toString(),1000);
            setTimeout(function() {
                startBannerLoop((num-1))
            },2000);
        }
    }

    function updateLeadboardWidthCanvas() {
        var totalracers = 0;
        $(".finish-screen-position").each(function(){
            totalracers = totalracers+1;
        });

        if(sportsbook == 1) {
            var tmpwidth = '100%';
            var tmpmargin ='0%';
        } else {
            var tmpwidth = '100%';
            var tmpmargin ='0%';
            if(($("#gui").width() > 1000)&&(totalracers > 2)) {
                tmpwidth = '1000px';
                tmpmargin = (($("#gui").width() - 1000) / 2)+'px';
            } else if(($("#gui").width() > 700)&&(totalracers == 2)) {
                tmpwidth = '700px';
                tmpmargin = (($("#gui").width() - 700) / 2)+'px';
                $()
            }
        }
        //$("#finalboardbox").css({'width':tmpwidth,'left':tmpmargin});

        
        var item_col_size = "m6";
        var subimage_height = '200px';
        if((totalracers > 2)&&($("#gui").width() > 800)) {
            item_col_size = "m4";
            subimage_height = '160px';
        } else if ($("#gui").width() < 550) {
            item_col_size = "";
            subimage_height = '100px';
        }
        updateRaceTableSizes();
        $(".finish-screen-position").each(function(){
            $(this).removeClass("m6").removeClass("m4").addClass(item_col_size);
        });
        
    }

    function updateRaceTableSizes() {
        if(window.innerWidth < 600) {
            $("#finalracetable").css({height:'84vh'});
        } else {
            $("#finalracetable").css({height:$("#finalracewinner").height()+'px'});
        }
        if(window.innerWidth < 1000) {
            $(".finish-screen-racer-ship").hide();
            $(".finish-screen-racer-stats").each(function(){
                $(this).css({width:'100%',height:'15.5vw'});
            });
            $("#racebanner").css({top:'30%'});
        } else {
            $(".finish-screen-racer-ship").show();
            $(".finish-screen-racer-stats").each(function(){
                $(this).css({width:'49%',height:'10.5vw'});
            });
            $("#racebanner").css({top:'20px'});
        }
        
        var newflexheight = $(".final-result-flex-row").height();
        var newtextmargin = ((newflexheight-18)/2);
        $(".final-result-flex-row-text").each(function(){
            $(this).css({marginTop:newtextmargin+'px'});
        });
        $(".final-result-flex-row-number").each(function(){
            $(this).css({marginTop:newtextmargin-5+'px'});
        });
    }

    function updateRaceBannerText(bannertext, fadetime) {
        window.$("#racerbanner").fadeIn(1000);
        var finaltxt = bannertext;
        window.$("#racerbannertxt").html(finaltxt);
        window.$("#racerbanner").fadeOut(fadetime);
    }

    function updateRaceTrackFactionUI(factioname) {
        if (factioname == 'serf') {
            var track_faction_icon = '<img title="Serf Planetary System" src="https://media.exiledracers.com/global/icons/exr_serf_icon.png" height="30px" />';
            var track_faction_name = 'Serf';
        } else if (factioname == 'augment') {
            var track_faction_icon = '<img title="Augment Planetary System" src="https://media.exiledracers.com/global/icons/exr_aug_icon.png" height="30px" />';
            var track_faction_name = 'Aug';
        } else if (factioname == 'mercenary') {
            var track_faction_icon = '<img title="Mercenary Planetary System" src="https://media.exiledracers.com/global/icons/exr_merc_icon.png" height="30px" />';
            var track_faction_name = 'Merc';
        } else {
            var track_faction_icon = '';
            var track_faction_name = 'EXR';
        }

        $(".trackfactionicon").each(function(){
            $(this).html(track_faction_icon);
        });
        $("#trackfactionname").html(track_faction_name+' Planetary System');
        updateDisplaySizes();
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    function loadDropdowns() {
        var ddelems = document.querySelectorAll('.dropdown-trigger');
        var ddinstances = window.M.Dropdown.init(ddelems, {hover:true,'alignment':'left','coverTrigger':false});
    }

    function loadTooltips() {
        var ttelems = document.querySelectorAll('.tooltipped');
        var ttinstances = window.M.Tooltip.init(ttelems,{html:true});
        setTimeout(function(){ updateMenuTooltipCSS();},500);
    }

    function updateCameraView(cameraname) {
        if((currentcamera != cameraname)&&(racestarted == true)) {
            currentcamera = cameraname;
            window.$("#gsettingscameraname").html(currentcamera);
            if(cameraname == 'Helicam') {
                race?.cameras.setForcedCamera(cameras_available[3].controller);
            } else if(cameraname == 'Sideline') {
                race?.cameras.setForcedCamera(cameras_available[4].controller);
            } else if(cameraname == 'Chase') {
                race?.cameras.setForcedCamera(cameras_available[0].controller);
            } else if(cameraname == 'Leader') {
                race?.cameras.setForcedCamera(cameras_available[1].controller);
            } else {
                race?.cameras.setForcedCamera(null);
                race?.userCameraSelection(currentcamera);
            }
        }
    }

    function showCameraOptions() {
        var ddelems = document.getElementById("gsettingscameraname");
        var ddinstances = window.M.Dropdown.getInstance(ddelems);
        ddinstances.open();
    }

    function toggleMusic() {
        if(currentmusic === true) {
            currentmusic = false;
            window.$("#gsettingsmusicname").html('Off');
        } else {
            currentmusic = true;
            
            window.$("#gsettingsmusicname").html('On');
        }
        window.$("#soundtogglebutton").click();
    }
    function toggleSFX() {
        if(currentsfx === true) {
            currentsfx = false;
            window.$("#gsettingssfxname").html('Off');
        } else {
            currentsfx = true;
            window.$("#gsettingssfxname").html('On');
        }
        race?.toggleSoundEffects();
    }

    function loadStreamingCameras() {
        cameras_available = race?.cameras.getSelectableCameras();
        //console.log(cameras_available);

        var camerabuttonhtml = [];
        var tmphtml = <></>
        for(var m = 0; m < cameras_available.length;m++) {
            tmphtml = <div className="row" key={"streamcamera"+m}>
                                <div className="gamemodule streamcamerabutton" 
                                    id={"streamcamera_"+cameras_available[m]['name']} 
                                    data-cameraname={cameras_available[m]['name']} 
                                    data-cameraid={m} 
                                    onClick={(e)=> switchStreamingCameras(e)}>
                                    {cameras_available[m]['name'].toUpperCase()}
                                </div>
                        </div>;

            camerabuttonhtml.push(tmphtml);
        }

        tmphtml = <div className="row" key={"streamcamera"+100}>
                                <div className="gamemodule streamcamerabutton" 
                                    id="streamcamera_Cinema"
                                    data-cameraname="Cinema" 
                                    data-cameraid="100" 
                                    onClick={(e)=> switchStreamingCameras(e)}>
                                    CINEMA
                                </div>
                        </div>;

        //camerabuttonhtml.push(tmphtml);

        setStreamCameraButtons(camerabuttonhtml);
        
        setTimeout(function(){
            updateCompleteDisplaySize();
        },500);
    }

    function switchStreamingCameras(e) {
        e.preventDefault();

        let buttontarget = e.target;
        let cameraswitch = '';
        let cameraid = 0;
        if(buttontarget.dataset.cameraname) {
            cameraswitch = buttontarget.dataset.cameraname;
            cameraid = buttontarget.dataset.cameraid;
        }
        $(".streamcamerabutton").each(function(){
            $(this).removeClass("gamemodulehighlight");
        });
        $("#streamcamera_"+cameraswitch).addClass("gamemodulehighlight");

        if(cameraswitch == 'Cinema') {
            updateCameraView(cameraswitch);
        } else {
            race?.cameras.setForcedCamera(cameras_available[cameraid].controller);
        }
    }

    function startRaceLoadingScreen() {
        if((racerID != 1)&&(racerID != 3)&&(racerID != 5)) {
            $("#startlineupbutton").hide();
        }
        updateSimulationText('SETTING UP RACE ENVIRONMENT');
        updateSimulationBar(15);
        setTimeout(function() {
            window.$("#race-loading-text-status").fadeOut(250);
            updateSimulationBar(30);
        },1750);
        setTimeout(function() {
            updateSimulationText('GENERATING TRACK');
            window.$("#race-loading-text-status").fadeIn(250);
        },2000);

        setTimeout(function() {
            window.$("#race-loading-text-status").fadeOut(250);
            updateSimulationBar(50);
        },3750);
        setTimeout(function() {
            updateSimulationText('CREATING TRACK ASSETS');
            window.$("#race-loading-text-status").fadeIn(250);
        },4000);
        setTimeout(function() {
            window.$("#race-loading-text-status").fadeOut(250);
            updateSimulationBar(65);
        },5750);
        setTimeout(function() {
            updateSimulationText('LOADING RACECRAFTS');
            window.$("#race-loading-text-status").fadeIn(250);
            updateSimulationBar(80);
            updateDisplaySizes();
            if(currentmusic === false) {
                window.$("#soundtogglebutton").click();
            }
            if(currentsfx === false) {
                race?.toggleSoundEffects();
            }
        },6000);
        setTimeout(function() {
            slowLoadSimulator(80);
        },8000);
    }

    function updateSimulationBar(progress_level) {
        document.getElementById("race-loading-bar-inner").style.width = progress_level+"%";
    }

    function updateSimulationText(progress_text) {
        $("#race-loading-text-status").html(progress_text);
        $("#race-loading-text-status2").html(progress_text);
    }

    function slowLoadSimulator(progress_level) {
        if(race_loaded === false) {
            if((race_load_pct > .86)&&(progress_level >= 82)) {
                updateSimulationBar(100);
                updateSimulationText('READY TO RACE');
                setTimeout(function() {
                    $("#race-loading-status").fadeOut();
                },2000);
                setTimeout(function() {
                    $("#loadingscreen").fadeOut(5000);
                },3000);
                if(sportsbook == 1) {
                    if(window.location.href.indexOf("watchlive") >= 0) {
                        $("#exrprimarylogo").show();
                    } else {
                        $("#exrprimarylogo").hide();
                        $("#raceprogress").css({'left': '15px'});
                    }
                    $("#raceprogress").show();
                    $("#startgamebutton").hide();
                    $("#startlineupbutton").hide();
                    setTimeout(function(){
                        showSportbookBanner();
                    },8000);
                }
                
            } else {
                if(progress_level < 98) {
                    var new_level = progress_level+1;
                    setTimeout(function(){
                        updateSimulationBar(new_level);
                        slowLoadSimulator(new_level);
                    },2000);
                } else {
                    setTimeout(function(){
                        slowLoadSimulator(progress_level);
                    },2000);
                }
            }
        } else {
            const skip = new URLSearchParams(window.location.href).get("skipIntro") !== null;

            updateSimulationBar(100);
            updateSimulationText('READY TO RACE');
            setTimeout(function() {
                $("#race-loading-status").fadeOut();
            },1);
            setTimeout(function() {
                $("#loadingscreen").fadeOut(skip ? 1 : 5000);
            },skip ? 1 : 3000);
            if(sportsbook == 1) {
                if(window.location.href.indexOf("watchlive") >= 0) {
                    $("#exrprimarylogo").show();
                } else {
                    $("#exrprimarylogo").hide();
                    $("#raceprogress").css({'left': '15px'});
                }
                $("#startgamebutton").hide();
                $("#startlineupbutton").hide();
                $("#raceprogress").show();
                setTimeout(function(){
                    showSportbookBanner();
                },8000);
            }
        } 
    }

    function updateCountdownTimer() {
        if(starttime.length > 0) {
            if(countdownseconds > 0) {
                $("#startgamebutton").hide();
                $("#startlineupbutton").hide();
                window.$("#racecountdown").show();
                var recenttime = new Date();
                var timestamptxt = recenttime.getUTCFullYear() + "-" 
                        + ('0' + (recenttime.getUTCMonth()+1)).slice(-2) + '-'
                        + ('0' + recenttime.getUTCDate()).slice(-2)+ "T"
                        + ('0' + (recenttime.getUTCHours()-4)).slice(-2)+ ":"
                        + ('0' + recenttime.getUTCMinutes()).slice(-2)+ ":"
                        + ('0' + recenttime.getUTCSeconds()).slice(-2);
                var recenttimedate = new Date(Date.parse(timestamptxt));
                var timediff = (starttimedate - recenttimedate);
                countdownseconds = timediff/1000;
                var countdownminutes = Math.floor(countdownseconds/60);
                var countdownfinalseconds = countdownseconds - (countdownminutes*60);
                
                var countdowntimerfinal = '';
                    
                if(countdownminutes >= 10) {
                    countdowntimerfinal = countdowntimerfinal+countdownminutes;
                } else if(countdownminutes > 0) {
                    countdowntimerfinal = countdowntimerfinal+'0'+countdownminutes;
                } else {
                    countdowntimerfinal = countdowntimerfinal+'00';
                }

                if(countdownfinalseconds >= 10) {
                    countdowntimerfinal = countdowntimerfinal+":"+countdownfinalseconds;
                } else if(countdownfinalseconds > 0) {
                    countdowntimerfinal = countdowntimerfinal+':0'+countdownfinalseconds;
                } else {
                    countdowntimerfinal = countdowntimerfinal+':00';
                }

                $("#countdowninfo").html(countdowntimerfinal);

                if(countdownseconds <= 0) {
                    startRace();
                    window.$("#racecountdown").fadeOut();
                }

                setTimeout(function(){
                    updateCountdownTimer();
                },1000);
                
            } else {
                window.$("#racecountdown").hide();
            }
        } else {
            window.$("#racecountdown").hide();
        }
    }

    function goToRaceResults() {
        if((racerID.length == 0)||(accountAddress.length == 0)) {
            dispatch(setRacerLogin('',''));
            dispatch(updateLocation("splash"));
            window.location.href= '/';
        } else {
            if(window.location.href.indexOf("watchlive") >= 0) {
                if(window.location.href.indexOf("bashconsensus") >= 0) {
                    window.location.href= '/#/pub/bashconsensus';
                    dispatch(updateLocation("pickem"));
                } else if(window.location.href.indexOf("oaknetwork") >= 0) {
                    window.location.href= '/#/pub/oaknetwork';
                    dispatch(updateLocation("pickem"));
                } else if(window.location.href.indexOf("bashdenver") >= 0) {
                    window.location.href= '/#/pub/bashdenver';
                    dispatch(updateLocation("pickem"));
                } else if(window.location.href.indexOf("puntclub") >= 0) {
                    window.location.href= '/#/pub/puntclub';
                    dispatch(updateLocation("pickem"));
                } else if(window.location.href.indexOf("apillon") >= 0) {
                    window.location.href= '/#/pub/apillon';
                    dispatch(updateLocation("pickem"));
                } else if(window.location.href.indexOf("thailand") >= 0) {
                    window.location.href= '/#/pub/polkadot-thailand';
                    dispatch(updateLocation("pickem"));
                } else if(window.location.href.indexOf("parity") >= 0) {
                    window.location.href= '/#/pub/parity';
                    dispatch(updateLocation("pickem"));
                } else {
                    window.location.href= '/#/pickem';
                    dispatch(updateLocation("pickem"));
                }
            } else {
                window.location.href= '/#/race/'+hash;
                dispatch(updateLocation("race_landing"));
            }
            
            setTimeout(function(){
                $("#globalmusicplayer").fadeIn();
            },3000);
        }
    }

    function updateMenuTooltipCSS() {

        window.$(".material-tooltip").each(function() {
            window.$(this).css({'background':'transparent','box-shadow': 'none','padding':'0px','margin':'0px'});
        });
        
        window.$(".tooltip-content").each(function() {
            window.$(this).css({'background':'transparent','box-shadow': 'none','padding':'0px','margin-top':'100px'});
        });
    }

    function rewatchRace() {
        window.location.reload();
    }

    $("#globalmusicplayer").hide();
    $("body").css({'overflow-x':'hidden','overflow-y':'scroll'});


    var toggle_tooltip_music = '<div class="gamemodule" style="font-size:12px;padding:5px 10px;border:0px;">Music Toggle On/Off</div>';
    var toggle_tooltip_fx = '<div class="gamemodule" style="font-size:12px;padding:5px 10px;border:0px;">Sound FX Toggle On/Off</div>';
    return(
    <div style={{position:'relative',width:'auto',height:'auto'}}>
        <div id="game-container" ref={container}>
            <canvas ref={canvas}/>
            <div id="exrprimarylogo" title="Go Back to Race Results">
                <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" width="100%" alt="EXR" onClick={()=> goToRaceResults()} />
            </div>
            <div id="players" ref={players}></div>
            <div id="raceprogress">
                <span id="lapinfo" ref={lapInfo}/>
                <span>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
                <span id="timeinfo" ref={timeInfo}/>
            </div>
            <div id="racecountdown">
                <span>STARTING IN</span>
                <span>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
                <span id="countdowninfo"></span>
            </div>
            <div id="minimap" ref={minimap}></div>
            <div id="startbuttons">
                <div className="gamemodule" id="startgamebutton" onClick={()=> startRace()}>
                    START RACE
                </div>
                <div className="gamemodule" id="startlineupbutton" onClick={() => startLineup()}>
                    LINEUP
                </div>
                <div className="gamemodule podiumbuttons" onClick={()=> goToRaceResults()}>
                    FULL RESULTS
                </div>
                <div className="gamemodule podiumbuttons replaybutton" onClick={()=> rewatchRace()}>
                    REPLAY RACE
                </div>
            </div>
            <div id="racerbanner">
                <span id="racerbannertxt">GET READY</span>
            </div>
        </div>
        <div id="mobile-container">
            <div id="leaderboardbox">
                <div className="row leaderboardtop">
                    <div className="white-text left" id="leaderboardtitle">
                        LEADERBOARD
                    </div>
                    <div className="white-text right exr-yellow-text" id="leaderboardreversed" style={{fontSize:'14px',marginTop:'5px',display:'none'}}>
                        <span className="material-symbols-outlined exr-yellow-text left" style={{fontSize:'16px',marginTop:'2px',marginRight:'3px'}}>
                        history
                        </span><span className="left">INVERSE</span>
                    </div>
                </div>
                <div className="row" id="leaderboardracers" ref={leaderboard}>
                </div>
                <div className="row leaderboardbottom" id="planetfactionname">
                    <div className="trackfactionicon" style={{left:'5px'}}></div>
                    <div className="trackfactionicon" style={{right:'5px'}}></div>
                    <div id="trackfactionname">EXR System</div>
                </div>
            </div>
            <FinalResultMobileScreen race_unique_id={hash} />
            <div className="gamemodule" id="gamesettingsmenu">
                <div id="gsettingscamera" className="gsettingsbutton" onClick={()=>showCameraOptions()}>
                    <div className="gsettingsbuttonname">
                        <i className="material-icons material-symbols-outlined" style={{fontSize:'16px',marginLeft:'-10px',marginTop:'-1px'}}>videocam</i>
                    </div>
                    <div id="gsettingscameraname" className="gsettingsbuttonvalue dropdown-trigger" data-target="camerapicker">
                        Cinema 
                    </div>
                    <ul id="camerapicker" className="dropdown-content gameddmenu">
                        <li className="gameddmenuoption" onClick={()=>updateCameraView('Leader')}>Leader</li>
                        <li className="gameddmenuoption" onClick={()=>updateCameraView('Cinema')}>Cinema</li>
                        <li className="gameddmenuoption" onClick={()=>updateCameraView('Chase')}>Chase</li>
                        <li className="gameddmenuoption" onClick={()=>updateCameraView('Helicam')}>Helicam</li>
                        <li className="gameddmenuoption" onClick={()=>updateCameraView('Sideline')}>Sideline</li>
                    </ul>
                </div>
                <div className="gsettingsdivider"></div>
                <div id="game-settings-right-text" className="gsettingsbutton white-text">
                    EXR IS PINK
                </div>
                
                {/* <div className="gsettingsdivider"></div>
                <div className="gsettingsbutton tooltipped" data-position="top" title="Music Toggle On / Off" data-tooltip={toggle_tooltip_music}>
                    <div className="gsettingsbuttonname">
                        <i className="material-icons material-symbols-outlined" style={{fontSize:'16px',marginLeft:'-5px',marginTop:'-1px'}}>genres</i>
                    </div>
                    <div className="gsettingsbuttonname hide-on-med-and-down show-on-large-only">
                        Music
                    </div>
                    <div id="gsettingsmusicname" className="gsettingsbuttonvalue" onClick={()=>toggleMusic()}>
                        Off 
                    </div>
                </div>
                <div className="gsettingsdivider"></div>
                <div className="gsettingsbutton tooltipped" style={{float:'right'}} data-position="top" title="Sound FX Toggle On / Off" data-tooltip={toggle_tooltip_fx}>
                    <div className="gsettingsbuttonname">
                        <i className="material-icons material-symbols-outlined" style={{fontSize:'16px',marginLeft:'-5px',marginTop:'-1px'}}>spatial_audio</i>
                    </div>
                    <div className="gsettingsbuttonname hide-on-med-and-down show-on-large-only">
                        FX
                    </div>
                    <div id="gsettingssfxname" className="gsettingsbuttonvalue" onClick={()=>toggleSFX()}>
                        Off 
                    </div>
                </div> */}
            </div>
        </div>
        <div id="streamingmenu" style={{display:'none'}}>
            <div className="row white-text center headline-text" style={{padding:"20px 10%",lineHeight:'1.1',marginBottom:'0px'}}>
                <div className="row" style={{paddingBottom:'10px',borderBottom:"1px solid white"}}>
                    STREAMING CAMERAS
                </div>
            </div>
            <div className="row" id="streamcamerasracers" style={{padding:"0px 15%"}}>
                {streamCameraButtons}
            </div>
        </div>
        <FinalResultEmbedScreen race_unique_id={hash} />
        <div id="loadingscreen" style={{width:'100%',height:'100vh',position:'fixed',top:'0px',left:'0px',zIndex:'10000',background:'black url(https://media.exiledracers.com/assets/game/builder/loading_screen_background.png)',backgroundSize:'cover',backgroundPosition:'center'}}>
            <div id="race-loading-status">
                <div className="row center-align" style={{marginTop:'13%'}}>
                    <img src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" height="100px" className="cursor-pointer" onClick={()=>goToRaceResults()} />
                </div>
                <div id="race_sponsors" className="row">
                    
                </div>
                <div className="row white-text" id="sim-progress-bar" style={{width:'100%',position:'absolute',top:'50%',left:'0px'}}>
                    <div className="center-align section-title-text col s10 offset-s1 l6 offset-l3  hide-on-small show-on-medium-and-up" 
                            style={{fontSize:'14px'}}>
                        <span className="left headline-text" style={{fontSize:'18px',fontWeight:'900'}}>&nbsp;RACE SIMULATOR</span>
                        <span id="race-loading-text-status" className="right hide-on-small-only show-on-medium-and-up">&nbsp;LOADING ALGORITHMIC RESULT</span>
                    </div>
                    <div className="col s10 offset-s1 l6 offset-l3">
                        <div className="progress exr-light-grey-bg" style={{height:'10px'}}>
                            <div className="determinate exr-red-bg" id="race-loading-bar-inner" style={{width:'5%'}}></div>
                        </div>
                    </div>
                    <div className="center-align section-title-text col s10 offset-s1 offset-l3" 
                            style={{fontSize:'14px',marginTop:'0px'}}>
                        <span id="race-loading-text-status2" className="right hide-on-med-and-up show-on-small">&nbsp;LOADING ALGORITHMIC RESULT</span>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};
