/**
 * The scene background
 */
export class Background {
    texture = null;
    textureHigh = null;

    /**
     * Generate a texture for this background
     * @param {WebGLRenderer} renderer The renderer
     * @returns {Promise} A promise which resolves when loading finishes
     */
    generate(renderer) {
        return new Promise(resolve => {
            resolve();
        });
    }
}