import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../../race_landing.css";
import $ from "jquery";

export const StackAccordianSelect = (props) => {
    const [entrySet, setEntry] = useState(false);
    const [racecraft_choice_html, setRacecraftChoiceHTML] = useState('');
    const [pilot_choice_html, setPilotChoiceHTML] = useState('');
    const [booster_choice_html, setBoosterChoiceHTML] = useState('');
    const [racecraft_selected_html, setRacecraftSelectedHTML] = useState('');
    const [pilot_selected_html, setPilotSelectedHTML] = useState('');
    const [booster_selected_html, setBoosterSelectedHTML] = useState('');

    const [racecraft_selected, setRacecraftSelected] = useState(0);
    const [pilot_selected, setPilotSelected] = useState(0);
    const [booster_selected, setBoosterSelected] = useState(0);

    const [item_list, setItemList] = useState(props.item_list);

    function grabStackItems() {
        
        var new_item_list = item_list;
        
        var pilot_arr = []
        var racecraft_arr = []
        var booster_arr = []
        for (var z = 0; z < new_item_list.length;z++) {
            if (new_item_list[z].item_type == 'booster') {
                booster_arr.push(new_item_list[z]);
            } else if (new_item_list[z].item_type == 'pilot') {
                pilot_arr.push(new_item_list[z]);
            } else if (new_item_list[z].item_type == 'ship') {
                racecraft_arr.push(new_item_list[z]);
            } else if (new_item_list[z].item_type == 'racecraft') {
                racecraft_arr.push(new_item_list[z]);
            }
        }

        if(pilot_arr.length > 0) {
            updateStackSelection('pilot',pilot_arr[0]['racer_item_id']);
        }
        if(racecraft_arr.length > 0) {
            updateStackSelection('racecraft',racecraft_arr[0]['racer_item_id']);
        }
        if(booster_arr.length > 0) {
            updateStackSelection('booster',booster_arr[0]['racer_item_id']);
        }

        setTimeout(function() {
            createPageAccordians('racecraft', 'list', racecraft_arr);
            createPageAccordians('pilot', 'list', pilot_arr);
            createPageAccordians('booster', 'list', booster_arr);
        },100);

        setTimeout(function() {
            if(pilot_arr.length > 0) {
                updateStackSelection('pilot',pilot_arr[0]['racer_item_id']);
            }
            if(racecraft_arr.length > 0) {
                updateStackSelection('racecraft',racecraft_arr[0]['racer_item_id']);
            }
            if(booster_arr.length > 0) {
                updateStackSelection('booster',booster_arr[0]['racer_item_id']);
            }
        },1000);
        
    }

    function startEntrySelects() {
        props.startSelects();
    }

    function createPageAccordians(itemtype, itemdisplay, itemarr) {
        var section_div_id = itemtype+'_selection_carousel';
        var section_html = [];
        var racer_attributes = ['speed', 'handling', 'acceleration', 'strength'];
        var pilot_attributes = ['focus', 'stamina', 'aggression', 'intelligence'];
        var booster_attributes = ['speed', 'handling', 'acceleration', 'strength', 'focus', 'stamina', 'aggression', 'intelligence'];

        for(var z = 0; z < itemarr.length;z++) {
            var item_id = itemarr[z].racer_item_id;
            var item_div_id = 'acc_'+itemtype+'_'+itemarr[z].racer_item_id;
            var item_image = itemarr[z].item_image_location;
            var item_name = itemarr[z].item_name.replace("-"," ").replace("-"," ").replace("-"," ").replace("EXR ","").toUpperCase();
            if(itemtype == 'booster') {
                var item_name_split = item_name.replace("EXR ","").split(" [");
                item_name = item_name_split[0];
            }
            var item_subtitle = itemarr[z].item_subname;
            if(item_subtitle != null) {
                item_subtitle = item_subtitle.toUpperCase();
            }
            var item_attributes = itemarr[z].item_attributes;

            var itemdata = '';
            for(var m = 0; m < item_attributes.length;m++) {
                if(m > 0) {
                    itemdata = itemdata+',';
                }
                itemdata = itemdata+item_attributes[m].attribute.toLowerCase()+'_'+item_attributes[m].score;
            }
            var item_faction_name = itemarr[z].item_faction.toUpperCase();
            if(item_faction_name == 'AUGMENT') {
                item_faction_name = 'AUG';
            } else {
                item_faction_name = item_faction_name.slice(0, 4);
            }
            var item_faction_icon = '';
            if(item_faction_name != 'ANY') {
                item_faction_icon = <img src={"https://media.exiledracers.com/global/icons/exr_"+item_faction_name.toLowerCase()+"_icon.png"} height="22px" style={{verticalAlign: 'middle'}} />
            }

            var item_img_bg = "url('"+item_image+"')";

            var item_stats_row = [];
            for(var m = 0; m < item_attributes.length;m++) {
                var tmp_stats_row = <>
                    <div className={"col s12 m3 center-align"}>
                        <div style={{fontSize:'10px',width:'100%'}}>
                            {item_attributes[m]['attribute']}
                        </div>
                        <div style={{fontSize:'14px',width:'100%'}}>
                            {item_attributes[m]['score']}
                        </div>
                    </div>
                </>;
                if(itemtype == 'booster') {
                    tmp_stats_row = <>
                            <div className="center-align" style={{width:"100px"}}>
                                <div style={{fontSize:'10px',width:'100%'}}>
                                    {item_attributes[m]['attribute']}
                                </div>
                                <div style={{fontSize:'14px',width:'100%'}}>
                                    {item_attributes[m]['score']}
                                </div>
                            </div>
                        </>;
                }
                
                item_stats_row.push(tmp_stats_row);
            }

            var itembuttonclass = itemtype+'-select-button';

            var item_select_button = <span id={'select-'+itemtype+'-'+item_id} className={itembuttonclass+" light-grey-green-button-link headline-text"} 
                                            data-itemid={item_id}
                                            data-itemtype={itemtype}
                                            style={{borderRadius:'15px',border:'1px solid white'}} 
                                            title="You Predicted This Racer To Win"
                                            onClick={(e)=>selectStackItem(e)} >
                                            <span style={{padding:'10px 10px 10px 10px',fontSize:'12px',verticalAlign:'bottom'}}
                                                    data-itemid={item_id}
                                                    data-itemtype={itemtype}>
                                                SELECT
                                            </span>
                                        </span>;
            if(itemdisplay == 'selected_item') {
                item_select_button = <span className={"light-grey-green-button-link headline-text"} 
                                                data-itemid={item_id}
                                                data-itemtype={itemtype}
                                                style={{borderRadius:'15px',border:'1px solid white'}} 
                                                title="You Predicted This Racer To Win" 
                                                onClick={(e)=>toggleStackAccordian(e)}>
                                                <span id={itemtype+"_expand_button"} style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign:'bottom'}}
                                                        data-itemid={item_id}
                                                        data-itemtype={itemtype}>
                                                    VIEW {itemtype.toUpperCase()}S
                                                </span>
                                            </span>;
            } else if ((item_id == racecraft_selected)||(item_id == pilot_selected)||(item_id == booster_selected)) {
                item_select_button = <span className={itembuttonclass+" light-grey-green-button-link-active headline-text"} 
                                                data-itemid={item_id}
                                                data-itemtype={itemtype}
                                                style={{borderRadius:'15px',border:'1px solid white'}} 
                                                title="You Predicted This Racer To Win" >
                                                <span style={{padding:'20px 10px 10px 10px',fontSize:'12px',verticalAlign:'bottom'}}
                                                        data-itemid={item_id}
                                                        data-itemtype={itemtype}>
                                                    PICKED
                                                </span>
                                            </span>;
            }

            var bottomdivider=<></>;
            if((z+1) < itemarr.length) {
                bottomdivider=<div className="divider-thin"></div>
            }

            var itemleftsize = 'col s12 m6';
            var itemrightsize = 'col s12 m6';
            if(itemtype == 'booster') {
                var tmpname = item_name;
                item_name = item_subtitle;
                item_subtitle = tmpname;
                itemleftsize = 'col s12 m8';
                itemrightsize = 'col s12 m4';
            }

            var image_size = '50px';
            var row_top = '5px';
            if(itemdisplay == 'selected_item') {
                item_div_id = 'selected_'+item_div_id;
                image_size = '50px';
                row_top = '10px';
            }

            var tmp_html = <div key={item_div_id} className="col s12 accordian-item" id={item_div_id} data-itemtype={itemtype} data-scores={itemdata} data-faction={itemarr[z].item_faction.toLowerCase()} >
                                <div className="row" style={{marginTop:'10px',marginBottom:'0px'}}>
                                    <div className={itemleftsize} style={{display:'flex',flexDirection:'row'}}>
                                        <div className="left" style={{width:image_size,height:image_size,borderRadius:'10px',overflow:'hidden',marginRight:'5px'}}>
                                            <img src={item_image} style={{width:'100%'}} />
                                        </div>
                                        <div className="left" style={{marginTop:row_top,flex:'1'}}>
                                            <div className="row headline-text" style={{margin:'0px',padding:'0px 20px'}}>
                                                {item_subtitle}
                                            </div>
                                            <div className="row subheadline-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'10px'}}>
                                                {item_faction_icon}
                                                <span style={{verticalAlign:'middle'}}>&nbsp;&nbsp;{item_name}&nbsp;&nbsp;///&nbsp;&nbsp;{item_faction_name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={itemrightsize} style={{marginTop:row_top,display:'flex',flexDirection:'row'}}>
                                        
                                        <div className="right right-align" style={{flex:'1'}}>
                                            {item_stats_row}
                                        </div>
                                        <div className="right right-align" style={{marginTop:'10px',marginLeft:'20px'}}>
                                            {item_select_button}
                                        </div>
                                    </div>
                                </div>
                                {bottomdivider}
                            </div>;
            if(itemdisplay == 'selected_item') {
                tmp_html = <div key={item_div_id} className="col s12 selected-accordian-item" id={item_div_id} data-itemtype={itemtype} data-scores={itemdata} data-faction={itemarr[z].item_faction.toLowerCase()} >
                                <div className="row" style={{position:'relative',marginTop:'0px',marginBottom:'0px',height:'15vw'}}>
                                    <div style={{position:'absolute',left:'0px',top:'0px',height:'100%',width:'100%',zIndex:'1',
                                            background:item_img_bg,backgroundSize:'cover',backgroundPosition:'center'}}>
                                    </div>
                                    <div className="col s12 exr-trans-black-bg-subtle" style={{position:'relative',display:'flex',flexDirection:'row',zIndex:'10'}}>
                                        <div className="left" style={{marginTop:'10px',flex:'1'}}>
                                            <div className="row headline-text" style={{margin:'0px',padding:'0px 20px'}}>
                                            {item_name}
                                            </div>
                                            <div className="row subheadline-text" style={{margin:'0px',padding:'0px 20px',paddingBottom:'10px',marginBottom:'0px'}}>
                                                <span style={{verticalAlign:'middle'}}>{item_subtitle}&nbsp;&nbsp;///&nbsp;&nbsp;{item_faction_name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
            }
                            
            section_html.push(tmp_html);
        }

        if(itemdisplay == 'selected_item') {
            if(itemtype == 'booster') {
                setBoosterSelectedHTML(section_html);
            } else if(itemtype == 'pilot') {
                setPilotSelectedHTML(section_html);
            } else if(itemtype == 'racecraft') {
                setRacecraftSelectedHTML(section_html);
            }
        } else {
            if(itemtype == 'booster') {
                setBoosterChoiceHTML(section_html);
                setTimeout(function() {
                    startEntrySelects();
                },10);
            } else if(itemtype == 'pilot') {
                setPilotChoiceHTML(section_html);
            } else if(itemtype == 'racecraft') {
                setRacecraftChoiceHTML(section_html);
            }
            
        }
    }

    function selectStackItem(e) {
        e.preventDefault();
        var itemtype = e.target.dataset.itemtype;
        var itemid = e.target.dataset.itemid;
        updateStackSelection(itemtype,itemid);
    }

    function updateStackSelection(itemtype,itemid) {
        var new_item_list = item_list;
        var item_arr = [];
        for (var z = 0; z < new_item_list.length;z++) {
            if ((new_item_list[z].item_type == itemtype)&&(new_item_list[z].racer_item_id == itemid)) {
                item_arr.push(new_item_list[z]);
            }
        }

        $("."+itemtype+"-select-button").each(function() {
            $(this).removeClass("light-grey-green-button-link-active").addClass("light-grey-green-button-link");
            $(this).find("span").html("SELECT");
        });
        $("#select-"+itemtype+"-"+itemid).removeClass("light-grey-green-button-link").addClass("light-grey-green-button-link-active");
        $("#select-"+itemtype+"-"+itemid).find("span").html("PICKED");

        if(itemtype == 'booster') {
            setBoosterSelected(itemid);
        } else if(itemtype == 'pilot') {
            setPilotSelected(itemid);
        } else if(itemtype == 'racecraft') {
            setRacecraftSelected(itemid);
        }
        createPageAccordians(itemtype,'selected_item',item_arr);
    }

    function toggleStackAccordian(e) {
        e.preventDefault();
        var itemtype = e.target.dataset.itemtype;
        if($("#"+itemtype+"_item_list").is(":visible")) {
            
        } else {
            
            $("#pilot_item_list").hide();
            $("#racecraft_item_list").hide();
            $("#booster_item_list").hide();
            $("#"+itemtype+"_item_list").show();
            
            $("#pilot_expand_button").removeClass("exr-light-green-text");
            $("#racecraft_expand_button").removeClass("exr-light-green-text");
            $("#booster_expand_button").removeClass("exr-light-green-text");
            $("#"+itemtype+"_expand_button").addClass("exr-light-green-text");
        }
    }

    if(entrySet == false) {
        setEntry(true);
        grabStackItems();
    }

    return(<>
            <div className="col s12 m12 l9" style={{padding:'0px 10px 0px 0px'}}>
                <div className="row white-text" style={{margin:"0px",padding:"0px",borderRadius:'10px',overflow:'hidden',marginBottom:'10px'}}>
                    <div className="col s12 m4" style={{margin:'0px',padding:'5px'}}>
                        <div className="row exr-new-light-grey white-text" style={{margin:"0px",padding:"0px",borderRadius:'10px',overflow:'hidden',marginBottom:'10px'}}>
                            <div className="row" style={{padding:'0px',marginBottom:'0px'}}>
                                {pilot_selected_html}
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m4" style={{margin:'0px',padding:'5px'}}>
                        <div className="row exr-new-light-grey white-text" style={{margin:"0px",padding:"0px",borderRadius:'10px',overflow:'hidden',marginBottom:'10px'}}>
                            {racecraft_selected_html}
                        </div>
                    </div>
                    <div className="col s12 m4" style={{margin:'0px',padding:'5px'}}>
                        <div className="row exr-new-light-grey white-text" style={{margin:"0px",padding:"0px",borderRadius:'10px',overflow:'hidden',marginBottom:'10px'}}>
                            {booster_selected_html}
                        </div>
                    </div>
                </div>
                <div className="row exr-new-light-grey white-text" style={{margin:"0px",padding:"0px",borderRadius:'10px',overflow:'hidden',marginBottom:'10px'}}>
                    <div className="col s12" style={{display:'block',padding:'15px 20px',marginTop:'10px'}}>
                        <div className="left" style={{marginRight:'20px'}}>
                            <span id="pilot_expand_button" className="exr-light-green-text headline-text"
                                data-itemtype="pilot"
                                title="View Your Pilots" 
                                style={{cursor:'pointer'}}
                                onClick={(e)=>toggleStackAccordian(e)}>
                                <span style={{padding:'20px 10px 10px 10px',fontSize:'14px',verticalAlign:'bottom'}} data-itemtype="pilot">
                                    PILOTS
                                </span>
                            </span>
                        </div>
                        <div className="left" style={{marginRight:'20px'}}>
                            <span id="racecraft_expand_button" className="headline-text"
                                data-itemtype="racecraft"
                                title="View Your Racecrafts" 
                                style={{cursor:'pointer'}} 
                                onClick={(e)=>toggleStackAccordian(e)}>
                                <span style={{padding:'20px 10px 10px 10px',fontSize:'14px',verticalAlign:'bottom'}} data-itemtype="racecraft">
                                    RACECRAFTS
                                </span>
                            </span>
                        </div>
                        <div className="left">
                            <span id="booster_expand_button" className="headline-text"
                                data-itemtype="booster"
                                title="View Your Boosters" 
                                style={{cursor:'pointer'}} 
                                onClick={(e)=>toggleStackAccordian(e)}>
                                <span style={{padding:'20px 10px 10px 10px',fontSize:'14px',verticalAlign:'bottom'}} data-itemtype="booster">
                                    BOOSTERS
                                </span>
                            </span>
                        </div>
                        <div className="right">
                            <span className="left" style={{marginRight:'10px'}}>Sort By:</span>
                            <span className="left">
                                <select id="sortitems" className="browser-default" style={{fontSize:'14px',padding:'2px',height:'24px',marginRight:'20px'}}>
                                    <option value="tokenid">Token ID</option>
                                    <option value="focus">Focus</option>
                                </select>
                            </span>
                        </div>
                    </div>
                    <div className="col s12" style={{display:'block'}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div id="pilot_item_list" className="col s12" style={{display:'block'}}>
                        {pilot_choice_html}
                    </div>
                    <div id="racecraft_item_list" className="col s12" style={{display:'none'}}>
                        {racecraft_choice_html}
                    </div>
                    <div id="booster_item_list" className="col s12" style={{display:'none'}}>
                        {booster_choice_html}
                    </div>
                </div>
            </div>
    </>);
}