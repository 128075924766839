import React, { useEffect, useState } from "react";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import $ from "jquery";

export const RaceTrainingHomeBox = (props) => {

  return (<div id="training_box_race_home" className="modal page-modal page-modal-medium">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                          RACE TRAINING
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    {/* <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                        Welcome to Race Training recruit! We don't have much time — Harmumi is tracking. 
                        For 24 hours — you have training races available to you. 
                        So get in, move fast and sharpen your skills before it's too late. 
                        We're tracking top racers.
                        <br/><br/><a className="exr-light-green-text">Daily Training Race: </a> 
                        <br/>Race against an EXR Race Trainer up to <a className="exr-light-green-text">3 times</a> to collect RPM (Rare Processed Metals) and XP while testing your skills. 
                        Beat your race trainer and collect <a className="exr-light-green-text">50 RPM</a>. If 
                        you lose, you still collect <a className="exr-light-green-text">25 RPM</a>. 50 XP awarded each time you race.  
                        <br/><br/><a className="exr-light-green-text">Invite Race Battle: </a>
                        <br/>Race against other EXR Racers up to <a className="exr-light-green-text">5 times</a> once completing your <a className="exr-light-green-text">Daily Training Races</a>. 
                        &nbsp;Join a private invite race with your friends or enter an Open Race Battle to be assigned a race against other racers. 
                        Invite Races allow you to collect larger amounts of RPM but require up to a <a className="exr-light-green-text">25 RPM</a> entry fee. 
                        Win the race and keep the entire pot. 50 XP awarded each time you race. 
                        <br/><br/>
                    </div> */}
                    {/* <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                        Welcome to Race Training recruit! We don't have much time — Harmumi is tracking. 
                        You have races available to you. So get in, move fast and sharpen your skills before it's too late. 
                        We're tracking top racers.
                        <br/><br/><a className="exr-light-green-text">PHASE 1: RACING</a> // ENTRY CLOSES APR 17 @ 5PM EST
                        <br/>Registration is open and will close on April 17th at 5pm EST (you have 3 days to enter) — 
                        This phase is for EXR Racers only, who can enter up to 4 races found in the Races tab. 
                        You earn XP and RPM no matter where you finish, but you'll get more for finishing higher. 
                        There's a 5th bonus banter battle Race for Exiled & Legendaries 👀👀 
                        Racers will also be able to train against the EXR training Bot to get prepared. 
                        <br/><br/><a className="exr-light-green-text">PHASE 2: PREDICTING</a> // PICKS CLOSE APR 19 @ 5PM EST
                        <br/>Once the Racing registrations windows close — the prediction window will open at 5 pm EST Monday (you'll have 2 days to predict) 
                        — then you'll be able to view each race and pick 1 winner for up to 4 of the different races. 
                        You'll earn points for predicting winners only — underdog selections get more.
                        <br/><br/>
                        <a className="exr-light-green-text">PHASE 3: SPECTATING</a> // RACES LIVE APR 19 @ 5:30PM EST
                        <br/>After the prediction window has closed, you'll be able to watch the races LIVE together or on demand later.
                        <br/><br/>
                    </div> */}
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                        Welcome to Race Training recruit! We don't have much time — Harmumi is tracking. 
                        You have races available to you. So get in, move fast and sharpen your skills before it's too late. 
                        We're tracking top racers and prophets.
                        {/* <br/><br/><a className="exr-light-green-text">HOURLY OPEN RACES</a> // OFFICIAL LEADERBOARD RACES
                        <br/>Race against other EXR Racers in a Hourly Open Races up to <a className="exr-light-green-text">12 times</a>. 
                        Collect RPM (Rare Processed Metals) and XP for wining races or predicting correctly. 
                        Your <a className="exr-light-green-text">Top 6 Results</a> for racing and top 12 for predicting count towards the leaderboard. */}
                        <br/><br/><a className="exr-light-green-text">TRAINING RACES </a> // PRACTICE AGAINST THE BOT
                        <br/>Race against an EXR Race Trainer to build your skills and learn about your racing stacks. No leaderboard RPM or XP points awarded during training races.  
                        <br/><br/><a className="exr-light-green-text">INVITE RACE BATTLE</a> // FRIENDLY CHALLENGES
                        <br/>Race against other EXR Racers for bragging rights. 
                        &nbsp;Join a private invite race with your friends for see who's the top racer. Invite Races do not give you leaderboard RPM or XP points but are a fun way to earn bragging rights 
                        in between races.  
                        <br/><br/><a className="exr-light-green-text">LEAGUE RACES</a> // COMPETITION
                        <br/>Race against other EXR Racers in regularly scheduled league races. Join private and public leagues to get into these races. Eligibility, prizes and leaderboards for leagues are set by the league owners.
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
  );
};