import * as THREE from "three";
import {Globals} from "../../global/globals.js";
import {TrackPropInstance} from "../track/props/instance/trackPropInstance.js";
import {StageHologram} from "./stageHologram.js";
import {Audio} from "../audio/audio.js";

export class Stage {
    static MODEL = "assets/models/stage.glb";
    static MODEL_HOLOGRAMS = "assets/models/stage_holograms.glb";
    static MODEL_CAMERAS = "assets/models/stage_cameras.glb";
    static CAMERA_SPEED_MULTIPLIER = .5;
    static CAMERA_FIRST = 2;
    static COLOR_BEAMS = new THREE.Color("#e861e2");
    static COLOR_NEON = new THREE.Color("#ff141d");
    static COLOR_LIGHT = new THREE.Color("#c882f3");
    static COLOR_HOLOGRAM_1 = new THREE.Color("#5479ec");
    static COLOR_HOLOGRAM_2 = Stage.COLOR_BEAMS;
    static COLOR_HOLOGRAM_3 = new THREE.Color("#e77538");
    static COLOR_CONTRAST_1 = new THREE.Color("#e1d55a");
    static COLOR_CONTRAST_2 = new THREE.Color("#4fdcdc");
    static COLOR_CONTRAST_3 = Stage.COLOR_BEAMS;
    static MATERIAL_BEAM = new THREE.ShaderMaterial({
        // language=GLSL
        vertexShader: `
            varying float intensity;
            
            void main() {
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
                intensity = clamp(0., 1., 2. - length(position.xy) * .06);
            }`,
        // language=GLSL
        fragmentShader: `
            uniform vec3 color;
            
            varying float intensity;
            
            void main() {
                gl_FragColor = vec4(color, intensity);
            }`,
        uniforms: {
            color: new THREE.Uniform(Stage.COLOR_BEAMS)
        },
        transparent: true,
        depthWrite: false,
        blendMode: THREE.AdditiveBlending,
    });

    #audio;

    scene = new THREE.Scene();
    instance = new TrackPropInstance();
    cameras = [];
    camera = -1;
    visible = false;
    cameraMixer = null;
    hologram1 = null;
    hologram2 = null;
    hologram3 = null;

    /**
     * Make a suitable position for this stage
     * @returns {THREE.Vector3} The position
     */
    static makePosition() {
        return new THREE.Vector3(0, 0, 120);
    }

    /**
     * Construct the stage
     * @param {Racers} racers The racers
     * @param {Track} track The track
     * @param {number} trackWidth The track width
     * @param {Audio} audio The audio
     */
    constructor(racers, track, trackWidth, audio) {
        this.#audio = audio;

        this.racers = racers;
        this.track = track;
        this.trackWidth = trackWidth;

        this.scene.position.copy(Stage.makePosition());

        Globals.MODEL_CACHE.load(Stage.MODEL).then(scene => {
            scene = scene.scene;

            const materialLasers = Stage.MATERIAL_BEAM.clone();
            const materialNeon = new THREE.MeshBasicMaterial({
                color: Stage.COLOR_NEON,
                transparent: true,
                depthWrite: false,
                blendMode: THREE.AdditiveBlending,
                opacity: .6
            });

            this.scene.add(scene);

            scene.traverse(node => {
                node.name = node.name.replace("podiumBanner", "portalBanner");

                switch (node.name) {
                    case "podiumPeople":
                        node.visible = false;

                        break;
                    case "@podiumLaserShow":
                        node.material = materialLasers;

                        break;
                    case "@podiumNeon":
                        node.material = materialNeon;

                        break;
                    case "@podiumGlas":

                        break;
                    case "@podiumProjectorsLights":

                        break;
                    case "@podiumLitPodiums":

                        break;
                    case "@podiumLitPodiumsBeams":

                        break;
                }
            });

            Globals.MODEL_CACHE.load(Stage.MODEL_CAMERAS).then(cameras => {
                this.cameraMixer = new THREE.AnimationMixer(cameras.scene);

                this.scene.add(cameras.scene);
                cameras.scene.traverse(node => {
                    if (node instanceof THREE.Object3D && !node.name.includes("uv"))
                        for (let animation = 0, animationCount = cameras.animations.length; animation < animationCount; ++animation)
                            if (cameras.animations[animation].name.includes(node.name))
                                this.cameras.push([
                                    this.cameraMixer.clipAction(cameras.animations[animation]),
                                    node,
                                    cameras.animations[animation]
                                ]);
                });
            });
        });

        Globals.MODEL_CACHE.load(Stage.MODEL_HOLOGRAMS).then(scene => {
            this.scene.add(scene.scene);

            this.hologram1 = new StageHologram(
                Stage.COLOR_HOLOGRAM_1,
                Stage.COLOR_CONTRAST_1,
                Stage.COLOR_CONTRAST_2,
                scene.scene.getObjectByName("PLACE1"),
                scene.scene.getObjectByName("holo_@bgshade"),
                scene.scene.getObjectByName("holo_@name"),
                scene.scene.getObjectByName("holo_@stats"),
                scene.scene.getObjectByName("holo_@grad"),
                scene.scene.getObjectByName("holo_@number"),
                scene.scene.getObjectByName("holo_@portrait"),
                scene.scene.getObjectByName("holo_@factionAug"),
                scene.scene.getObjectByName("holo_@factionMerc"),
                scene.scene.getObjectByName("holo_@factionSerfs"));
            this.hologram2 = new StageHologram(
                Stage.COLOR_HOLOGRAM_2,
                Stage.COLOR_CONTRAST_2,
                Stage.COLOR_CONTRAST_3,
                scene.scene.getObjectByName("PLACE2"),
                scene.scene.getObjectByName("holo_@bgshade_2"),
                scene.scene.getObjectByName("holo_@name_2"),
                scene.scene.getObjectByName("holo_@stats_2"),
                scene.scene.getObjectByName("holo_@grad_2"),
                scene.scene.getObjectByName("holo_@number_2"),
                scene.scene.getObjectByName("holo_@portrait_2"),
                scene.scene.getObjectByName("holo_@factionAug_2"),
                scene.scene.getObjectByName("holo_@factionMerc_2"),
                scene.scene.getObjectByName("holo_@factionSerfs_2"));
            this.hologram3 = new StageHologram(
                Stage.COLOR_HOLOGRAM_3,
                Stage.COLOR_CONTRAST_3,
                Stage.COLOR_CONTRAST_1,
                scene.scene.getObjectByName("PLACE3"),
                scene.scene.getObjectByName("holo_@bgshade_3"),
                scene.scene.getObjectByName("holo_@name_3"),
                scene.scene.getObjectByName("holo_@stats_3"),
                scene.scene.getObjectByName("holo_@grad_3"),
                scene.scene.getObjectByName("holo_@number_3"),
                scene.scene.getObjectByName("holo_@portrait_3"),
                scene.scene.getObjectByName("holo_@factionAug_3"),
                scene.scene.getObjectByName("holo_@factionMerc_3"),
                scene.scene.getObjectByName("holo_@factionSerfs_3"));
        });

        const sun = new THREE.DirectionalLight(Stage.COLOR_LIGHT, 4);

        this.scene.add(sun);
    }

    /**
     * Update the state
     */
    update() {
        if (this.visible) {
            this.instance.update();
        }
    }

    /**
     * Render a frame
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {number} delta The time delta
     */
    render(time, delta) {
        if (this.visible) {
            this.instance.render(time);

            if (this.cameraMixer && this.camera !== -1) {
                this.cameraMixer.update(delta * Stage.CAMERA_SPEED_MULTIPLIER);

                if (this.cameraMixer.time > this.cameras[this.camera][2].duration)
                    this.newCamera();
            }
        }
    }

    /**
     * Choose a new camera
     */
    newCamera() {
        let oldCamera = this.camera;

        if (oldCamera === -1)
            this.camera = Stage.CAMERA_FIRST;
        else
            while (oldCamera === (this.camera = Math.floor(Math.random() * this.cameras.length)));

        this.cameraMixer.time = 0;
        this.cameras[this.camera][0].time = 0;
        this.cameras[this.camera][0].play();
    }

    /**
     * Get the current camera
     * @returns {THREE.Object3D} The current camera
     */
    getCamera() {
        if (this.camera === -1)
            this.newCamera();

        return this.cameras[this.camera][1];
    }

    /**
     * Play the crowd cheer audio
     */
    playCrowdCheer() {
        this.#audio.getAudio(Audio.AMB_CROWD_CHEER).play();
    }

    /**
     * Show the stage
     * @param {THREE.Scene} scene The scene to show the stage on
     */
    show(scene) {
        if (this.visible)
            return;

        this.instance.instantiate(scene, this.scene, this.track, this.trackWidth);

        scene.add(this.scene);

        this.playCrowdCheer();

        const first = this.racers.getRacerWithStanding(0);
        const second = this.racers.getRacerWithStanding(1);
        const third = this.racers.getRacerWithStanding(2);

        if (first)
            this.hologram1.racer = first;

        if (second)
            this.hologram2.racer = second;

        if (third)
            this.hologram3.racer = third;

        this.visible = true;
    }
}