import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerXP, setRacerCoins } from "../../../redux/Account/actions";
import {axiosInstance} from "../../../utils";
import "./race_header.css";
import { NoToneMapping } from "three";

let web3;

export const RaceFlowHeader = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [headerSet, setHeader] = useState(false);
    const [raceID, setRaceID] = useState('');
    const [raceUniqueID, setRaceUniqueID] = useState('');
    const [raceDetails2, setRaceDetails] = useState(props.raceDetails);
    const [raceRacers, setRaceRacers] = useState([]);
    const [delayResults, setDelayResults] = useState('N');

    function grabRacerInfo() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;
            }
        }).catch(error => {
            console.log(error);
            dispatch(updateLocation("staging"));
        });
    }

    function grabRaceParticipantsInfo() {
        if(props.raceDetails == undefined) {
            setTimeout(function() {
                grabRaceParticipantsInfo();
            },1000);
        } else {
            if(props.raceDetails.race_unique_id !== undefined) {
                var addData = new Object();
                addData.raceUniqueID = encodeURIComponent(props.raceDetails.race_unique_id);
                axiosInstance.post('race/racer_list/',addData).then(result => {
                    if(Object.keys(result.data).length > 0) {
                        //setRaceRacers(result.data);
                        if(props.raceDetails.race_simulated == 1) {
                            var racers = result.data;
                            for(var r = 0;r < result.data.length;r++) {
                                if(racers[r]['racer_id'] == racerID) {
                                    if(racers[r]['racer_sim_watched'] == 0) {
                                        setDelayResults('Y');
                                    }
                                }
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
            
        }
    }

    if(headerSet == false) {
        setHeader(true);
        grabRacerInfo();
        grabRaceParticipantsInfo();
    }

    function toggleRacePage(pagename) {
        props.navToggle(pagename);
    }
    function toggleDetailsPage() {
        var tmp_track_id = window.$("#entry_track_id").val();
        props.detailsToggle(tmp_track_id);
    }
    var raceName = 'Loading...';
    if(props.raceDetails.race_name !== undefined) {
        var raceName = props.raceDetails.race_name+" #"+props.raceDetails.race_id;
    }
    var totalEXR = props.raceDetails.race_coin_prize;
    var totalXP = props.raceDetails.race_xp_prize;
    var days_ended = props.raceDetails.days_since_race_ended;
    var days_started = props.raceDetails.days_since_race_starts;
    var race_time = 'TODAY';
    var resultsDisplay = 'none';
    var entryDisplay = 'none';
    var lineupDisplay = 'block';
    var invitingDisplay = 'none';
    var simstatustext = 'none';
    var racers_invited = '';
    var race_simulated = window.$("#race-simulated-header").val();
    var racer_entered = window.$("#racer-entry-header").val();
    var racer_watched = window.$("#racer-simulation-watched").val();
    var racer_invited = window.$("#racer-invite-status").val();

    if((racer_watched == 'N')&&(race_simulated == 'Y')) {
        entryDisplay = 'none';
        race_time = 'READY';
        simstatustext = 'block';
    } else if((days_ended !== undefined)&&(days_ended !== 'null')&&(days_ended !== '')&&(days_ended !== null)) {
        if(days_ended == 0) {
            race_time = "Ended Today";
        } else if (days_ended == 1) {
            race_time = "Ended Yesterday";
        } else {
            race_time = "Ended "+days_ended+" Days Ago";
        }
        entryDisplay = 'none';
        resultsDisplay = 'block';
    } else if ((days_started !== undefined)&&(days_started !== 'null')&&(days_started !== '')&&(days_started !== null)) {
        race_time = "In "+days_ended+" Days";
    }

    if(props.inviteList.length > 2) {
        var invited_names = props.inviteList.replace(/,/g,", ");
        var challengercount = props.inviteList.split(",");
        var challengerword = 'INVITING';
        racers_invited = <span><span className="exr-red-text">{challengerword}:</span><span>&nbsp;&nbsp;{invited_names}</span></span>;
    }

    if((racer_entered == 'N')&&(race_simulated == 'N')) {
        var entryDisplay = 'block';
        var lineupDisplay = 'block';
    }

    var total_racers = 2;
    var race_type = 'SINGLE RACE';
    if(props.hashRaceUniqueID == 'daily') {
        raceName = 'DAILY RACE';
        race_time = 'TODAY';
        entryDisplay = 'block';
        lineupDisplay = 'none';
        resultsDisplay = 'none';
    } else if(props.hashRaceUniqueID == 'weekly') {
        raceName = 'WEEKLY RACE';
        race_time = 'FRIDAY';
        total_racers = 750;
        race_type = 'TOURNAMENT';
        entryDisplay = 'block';
        lineupDisplay = 'none';
        resultsDisplay = 'none';
    } else if(props.hashRaceUniqueID == 'alpha') {
        raceName = 'ALPHA RACE';
        race_time = 'TODAY';
        entryDisplay = 'block';
        lineupDisplay = 'none';
        resultsDisplay = 'none';
    } else if(props.hashRaceUniqueID == 'invite') {
        raceName = 'RACE BATTLE';
        race_time = 'TODAY';
        entryDisplay = 'block';
        lineupDisplay = 'none';
        resultsDisplay = 'none';
        invitingDisplay = 'block';
        var challengercount = props.inviteList.split(",");
        total_racers = challengercount.length+1;
        totalEXR = total_racers*25;
    } 

    var bigEntryButtonDisplay = 'none';
    if((racer_invited == 'Y')&&(racer_entered == 'N')) {
        bigEntryButtonDisplay = 'block';
    }
        
    return(<div className="row page-width" style={{marginTop:'20px',marginBottom:'0px'}}>
        <div className="col s12 exr-dark-grey-bg white-text race-header-main">
            <div className="cornercutout top-left"></div>
            <div className="cornercutout top-right"></div>
            <div className="row" style={{margin:'0px 0px 20px 0px'}}>
                <div className="headline-text left race-header-title">
                    {raceName}
                </div>
                <div className="right">
                    <span className="left race-header-top-details">
                        <span className="right race-header-top-details-text" title="Race Time">{race_time}</span>
                        <span className="right race-header-top-details-icon" title="Coins Awarded">
                            <i className="material-icons right race-header-top-details-icon-time">access_time</i>
                        </span>
                    </span>
                    <span className="left race-header-top-details">
                        <span className="right race-header-top-details-text" title="Coins Awarded">{totalEXR} EXR</span>
                        <span className="right race-header-top-details-icon" title="Winner: EXR Awarded">
                            <img src="https://media.exiledracers.com/global/coin_icon_white2.png" height="25px" />
                        </span>
                    </span>
                    <span className="left race-header-top-details">
                        <span className="right race-header-top-details-text" style={{marginLeft:'2px'}} title="XP Earned">{totalXP} XP</span>
                        <span className="right race-header-top-details-icon" title="All Racers: XP Earned">
                            <img src="https://media.exiledracers.com/global/xp_icon_white.png" height="25px" />
                        </span>
                    </span>
                </div>
            </div>
            <div className="row" style={{margin:'0px'}}>
                <div className="left">
                    <button id="race_details_button" className="btn-flat transparent waves-effect black-text racenavtoggle" onClick={()=>toggleDetailsPage('details')}>
                        RACE DETAILS
                    </button>
                </div>
                <div className="left" style={{display:entryDisplay}}>
                    <button id="race_entry_button" className="btn-flat transparent waves-effect black-text racenavtoggle" onClick={()=>toggleRacePage('entry')}>
                        RACE ENTRY
                    </button>
                </div>
                <div className="left" style={{display:lineupDisplay}}>
                    <button id="race_lineup_button" className="btn-flat transparent waves-effect black-text racenavtoggle" onClick={()=>toggleRacePage('lineup')}>
                        RACE LINEUP
                    </button>
                </div>
                <div className="left" style={{display:resultsDisplay}}>
                    <button id="race_results_button" className="btn-flat transparent waves-effect black-text racenavtoggle" onClick={()=>toggleRacePage('results')}>
                        RESULTS
                    </button>
                </div>
                <div id="raceaction" className="right center-align white-text race-header-right-action-section">
                    <div className="left" style={{width:'100%',display:bigEntryButtonDisplay}} title="Enter Race">
                        <button onClick={()=>toggleRacePage('entry')} className="btn-flat csr-button-white bracket-button" title="Enter Race">
                            <span className="bracket-button-bracket">[</span>
                            <span className="headline-text">ENTER RACE</span>
                            <span className="bracket-button-bracket">]</span>
                        </button>
                    </div>
                    <div id="race-simulated-status" className="left" style={{width:'100%',marginTop:'10px',display:simstatustext}}>
                        <div className="headline-text">
                            RACE SIMULATED
                        </div>
                    </div>
                    <div id="race-simulated-status" className="left" style={{width:'100%',marginTop:'10px',display:invitingDisplay}}>
                        <div className="headline-text">
                            {racers_invited}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};