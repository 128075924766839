import {DataLoader} from "../utils/dataLoader.js";
import {RacerData} from "./racer/racerData.js";
import {current_endpoint} from "../../../utils/axios.helper.js"

/**
 * Racers data
 */
export class RacersData extends DataLoader {
    //static URL = "https://data.exiledracers.com/api/v1/race/racer_list_admin_simple/";
    // static URL = "https://data.exiledracers.com/api/v1/race/racer_list_game/";
    static URL = current_endpoint+"race/racer_list_game/";
    static KEY = "raceUniqueID";
    static KEY2 = "predictorID";

    // pilot_image_thumbnail
    // racer_pilot_faction
    // race_final_rpm
    // race_final_time

    /**
     * Construct racers data
     * @param {string} id The racer id
     */
    constructor(id, predictorid, sponsor) {
        super(RacersData.URL, RacersData.KEY, id, RacersData.KEY2, predictorid);

        this.racers = [];
        this.predictor_id = predictorid;
        this.sponsor = sponsor;
    }

    /**
     * Receive loaded data
     * @param {Object} data The loaded data
     */
    receiveData(data) {
        if (data.length == 0) {
            //window.location.href= '/#/workshop';
        } else {
            for(var i = 0; i < data.length;i++) {
                if (data[i].racer_id == this.predictor_id) {
                    data[i].current_racer = 1;
                } else {
                    data[i].current_racer = 0;
                }
            }
            for (const racer of data)
                this.racers.push(new RacerData(racer,this.sponsor));
        }
    }
}