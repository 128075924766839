import * as THREE from "three";
import {Lights} from "./lights.js";
import {Designed} from "../design/designed.js";
import {BackgroundHDR} from "./background/backgroundHDR.js";

/**
 * Environment map lighting
 */
export class Environment extends Designed {
    static #SKY_SPHERE_PRECISION = 32;
    static #SKY_SPHERE_RADIUS = 10000;

    /**
     * Construct an environment map
     * @param {WebGLRenderer} renderer The renderer
     * @param {Scene} scene The scene
     * @param {Scene} sceneBackground The background scene
     * @param {boolean} [greenScreen] True if green screen mode should be enabled
     */
    constructor(renderer, scene, sceneBackground, greenScreen = false) {
        super();

        this.renderer = renderer;
        this.greenScreen = greenScreen;
        this.scene = scene;
        this.sceneBackground = sceneBackground;
        this.lights = new Lights(renderer, scene, sceneBackground);

        this.equirectangularHDR = null;
        this.rotationSpeed = 0.00005;
    }

    /**
     * Unload the current design
     */
    unload() {
        super.unload();

        this.sceneBackground.background = null;
        this.scene.environment = null;
        this.asteroidsAsset1 = this.asteroidsAsset2 = this.asteroidsAsset3 = null;
    }

    /**
     * Load an HDR background
     * @param {string} file The .exr file
     */
    loadHDR(file) {
        if (this.greenScreen)
            return;

        const background = new BackgroundHDR(file);

        background.generate(this.renderer).then(() => {
            this.scene.environment = background.texture;

            if (background.textureHigh) {
                const geometry = new THREE.SphereGeometry(
                    Environment.#SKY_SPHERE_RADIUS,
                    Environment.#SKY_SPHERE_PRECISION << 1,
                    Environment.#SKY_SPHERE_PRECISION);

                geometry.scale(1, 1, -1);

                const sphere = new THREE.Mesh(
                    geometry,
                    new THREE.MeshBasicMaterial({
                        map: background.textureHigh
                    }));

                this.sceneBackground.add(sphere);
            }
            else
                this.sceneBackground.background = background.texture;
        });
    }

    /**
     * Load an environment
     * @param {Object} data Environment data
     */
    load(data) {
        super.load(data);

        if (data.hasOwnProperty("equirectangularHDR"))
            this.loadHDR(this.equirectangularHDR = data["equirectangularHDR"]);
        else
            this.loadHDR(this.equirectangularHDR = "assets/skyAugs.exr");
    }

    /**
     * Get the design in JSON format
     * @returns {Object} The design in JSON format
     */
    getDesign() {
        const design = {
            "seed": this.seed
        };

        if (this.equirectangularHDR)
            design["equirectangularHDR"] = this.equirectangularHDR;

        return design;
    }

    /**
     * Update
     * @param {number} delta The time delta
     */
    update(delta) {
        this.planetSurface?.update(delta);
        this.planet?.update();
    }

    /**
     * Update the environment before rendering
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        this.planetSurface?.render(this.renderer, time);
        this.planet?.render(time);
    }
}