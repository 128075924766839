import "./sectionHeroWithImage.css";

export const SectionHeroWithImage = ({
  title,
  subtitle,
  description,
  content,
  additionalNote,
}) => {
  return (
    <div className="section-hero-with-image">
      <div style={{ maxWidth: `600px`, margin: `0 auto` }}>
        <h1 className="section-hero-with-image-title">{title}</h1>
        <p className="section-hero-with-image-subtitle">{subtitle}</p>
        <div className="section-hero-with-image-description">
          <p style={{ margin: 0 }}>{description}</p>
          <div style={{ textTransform: `uppercase`}}>
            {additionalNote && (
              <p>
                {additionalNote.label && <span style={{ marginRight: `4px` }}>{additionalNote.label}: </span>}
                {additionalNote.text && (
                  <span className="exr-red-text">{additionalNote.text}</span>
                )}
              </p>
            )}
          </div>
        </div>
        <div>
          {content && content.src && (
            <img
              className="section-hero-with-image-img"
              src={content.src}
              alt={content.title}
            />
          )}
        </div>
      </div>
    </div>
  );
};
