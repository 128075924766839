import * as THREE from "three";
import {effectGlow} from "./effectGlow.js";

export const effectGlowSprite = new THREE.ShaderMaterial({
    // language=GLSL
    vertexShader: `
        uniform float radius;

        varying vec2 vUv;

        void main() {
            vUv = uv;

            vec4 finalPosition = modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0);

            finalPosition.xy += position.xy * radius;

            gl_Position = projectionMatrix * finalPosition;
        }
        `,
    fragmentShader: effectGlow.fragmentShader,
    uniforms: {
        radius: {
            value: 1
        },
        ...effectGlow.uniforms
    },
    transparent: effectGlow.transparent,
    side: effectGlow.side,
    blending: effectGlow.blending,
    depthWrite: effectGlow.depthWrite
});