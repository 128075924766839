import React, { useState } from "react";
import "./accordion.css"

export const LearnAccordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={handleClick}>
        <span
          style={{ width: `24px`, display: `flex`, justifyContent: `center` }}
        >
          {isOpen ? "-" : "+"}
        </span>
        <p style={{ margin: 0 }}>
          <span>{title}</span>
        </p>
      </div>
      <div className={`${isOpen && "accordion-open"} accordion-content`}>
        <p style={{ color: `white` }}>{content}</p>
      </div>
    </div>
  );
}
