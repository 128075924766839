import {Background} from "./background.js";
import {Globals} from "../../../global/globals.js";

export class BackgroundHDR extends Background {
    /**
     * Construct an HDR background
     * @param {string} hdr The HDR file
     */
    constructor(hdr) {
        super();

        this.hdr = hdr;
    }

    /**
     * Generate a texture for this background
     * @param {WebGLRenderer} renderer The renderer
     * @returns {Promise} A promise which resolves when loading finishes
     */
    generate(renderer) {
        return new Promise(resolve => {
            Globals.HDR_CACHE.load(this.hdr).then((loaded) => {
                [this.texture, this.textureHigh] = loaded;

                resolve();
            })
        });
    }
}