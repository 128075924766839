import "./gameplay.css";
import heroImg from "../../../assets/images/gameplay-hero-img.png";
import pilotSerf from "../../../assets/images/exr-pilot-serf.jpg";
import sectionPlane from '../../../assets/images/section-plane.jpg';
import gameplayImage3 from '../../../assets/images/gameplay-image-3.jpg'
import { SectionTextWithBlock } from "../../Sections/SectionTextWithBlock";

export const LearnGameplay = () => {
  return (
    <>
      <div className="hero-container">
        <div>
          <img className="hero-image" src={heroImg} alt="Hero background" />
        </div>
        <div className="container hero-text">
          <div
            className="row"
            style={{
              display: `flex`,
              justifyContent: `center`,
            }}
          >
            <div
              className="col s12 m8 l7 center-align white-text"
              style={{
                margin: `0 auto`,
              }}
            >
              <h1 className="hero-title">Gameplay</h1>
              <div style={{ fontSize: `1.2rem`, textAlign: `left` }}>
                <p>
                  EXR is an algorithmic NFT based racing and prediction manager.
                  For racers — pick your racing stack (pilot, racecraft &
                  Booster) and enter races. Compete for top spots on the
                  Leaderboard and collect all kinds of rewards. For prophets
                  (predictors) pick who you think will win based on performance
                  Attributes and odds.
                </p>
                <p>
                  3 TYPE OF GAMES:{" "}
                  <span style={{ color: `#FF1616` }}>
                    TRAINING, BATTLES & OPEN RACES — PREDICT ON ALL.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SectionTextWithBlock
        title="HOW TO PLAY"
        subtitle="WHAT IS EXR // GET STARTED"
        block={{
          type: "youtube",
          content: {
            youTubeID: "2NFZJcgezow",
            title: "EXR - How to Play",
          },
        }}
        description="LEARN ABOUT HOW TO GET STARTED ON EXILED RACERS. DISCOVER HOW TO RACE, PREDICT AND WIN."
        blockPositionOnDesktop="right"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/live-24-hour-exr-race-training-f0134570d3e9",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
      />
      <SectionTextWithBlock
        title="HOW TO RACE"
        subtitle="COLLECT AND RACE"
        block={{
          type: "youtube",
          content: {
            youTubeID: "nBTONq2SrCY",
            title: "EXR - How to Race",
          },
        }}
        description="DISCOVER THE INS AND OUTS OF BEING A RACER ON EXR. DISCOVER HOW TO COLLECT ASSETS, CHOOSE A RACE STACK AND RACE ALGORITHMICALLY."
        blockPositionOnDesktop="left"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/live-24-hour-exr-race-training-f0134570d3e9",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
      />
      <SectionTextWithBlock
        title="HOW TO PREDICT"
        subtitle="MAKE PREDICTIONS AND WIN PRIZES"
        block={{
          type: "youtube",
          content: {
            youTubeID: "XiRaongbSR8",
            title: "EXR - How to Predict",
          },
        }}
        description="LEARN ABOUT HOW TO PREDICT AND BE A PROPHET ON EXILED RACERS. DISCOVER HOW TO JOIN, MAKE PICKS AND WIN."
        blockPositionOnDesktop="right"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/live-24-hour-exr-race-training-f0134570d3e9",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
      />
      <SectionTextWithBlock
        title="TRAINING"
        subtitle="TRAINER // BOT RACING"
        block={{
          type: "image",
          content: {
            src: pilotSerf,
            title: "pilot serf",
          },
        }}
        description="RACE AGAINST AN EXR RACE TRAINER
UP TO COLLECT RPM (RARE PROCESSED
METALS) AND XP (EXPERIENCE POINTS)
WHILE TESTING YOUR SKILLS."
        blockPositionOnDesktop="left"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/live-24-hour-exr-race-training-f0134570d3e9",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Game type",
          text: "Strategy // Algorithmic",
        }}
      />
      <SectionTextWithBlock
        subtitle="INVITE RACING"
        title="BATTLES"
        block={{
          type: "image",
          content: {
            src: sectionPlane,
            title: "pilot serf",
          },
        }}
        description="Race against other exr members
Once completing your daily race
Training. Invite races allow you to
Collect rpm but require a rpm
Entry fee."
        blockPositionOnDesktop="right"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/live-24-hour-exr-race-training-f0134570d3e9",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Game type",
          text: "Strategy // Algorithmic",
        }}
      />
      <SectionTextWithBlock
        title="OPEN RACES"
        subtitle="OPEN RACE ENTRY"
        block={{
          type: "image",
          content: {
            src: gameplayImage3,
            title: "pilot serf",
          },
        }}
        description="The open invite race type allows for
Anyone to hop into a race when they
Are open — great for if your friends
Aren’t around or you feel like a new
Challenge. Join, wait for entry closure,
Then make predictions on the race."
        blockPositionOnDesktop="left"
        links={[
          {
            name: "Read blog",
            url: "https://blog.exiledracers.com/live-24-hour-exr-race-training-f0134570d3e9",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
        additionalNote={{
          label: "Game type",
          text: "Strategy // Algorithmic",
        }}
      />
      <section className="container white-text" style={{ padding: `64px 0` }}>
        <div>
          <h2 className="section-content-title">Algorithm</h2>
          <p className="section-content-description">
            THE EXR Race algorithm allows for a skilled based probability game.
            Final race results are impacted by elements of the track, racer,
            pilot and other racers together in a logical way.
          </p>
          <hr
            style={{
              backgroundColor: `var(--red-brand)`,
              height: `1px`,
              border: `none`,
            }}
          />
          <p className="section-content-description">
            Every position of every racer IS ALGORITHMICALLY DETERMINED based on
            a random number of steps moved forward. THESE steps will be
            constrained at a min & max based on the racer and pilot attributes.
            The sum of these steps determine the final race results which is the
            order the racers cross the finish line.
          </p>
        </div>
        <div className="section-content-steps-group">
          <div className="section-content-step">
            <p className="section-content-step-number">1</p>
            <p style={{ margin: 0 }}>Max Distance (Racer Attributes)</p>
          </div>
          <div className="section-content-step">
            <p className="section-content-step-number">2</p>
            <p style={{ margin: 0 }}>Probability Range (Pilot Attributes)</p>
          </div>
          <div className="section-content-step">
            <p className="section-content-step-number">3</p>
            <p style={{ margin: 0 }}>
              Final Distance Moved (Random Number)
            </p>
          </div>
        </div>
      </section>
      <SectionTextWithBlock
        title="Racecrafts"
        subtitle="ATTRIBUTES"
        block={{
          type: "accordion",
          content: {
            items: [
              {
                title: "SPEED // STRAIGHTS",
                description:
                  "The Racecraft Speed attribute determines the max distance a race craft will move in a straight track segment. This attribute depletes over time based on the ship's strength.",
              },
              {
                title: "HANDLING // TURNS",
                description:
                  "Racecraft Handling attribute controls the max distance traveled on a turn segment. This attribute depletes over time based on the ship's strength.",
              },
              {
                title: "ACCELERATION // SPEED SHIFTS",
                description:
                  "The Acceleration attribute determines how fast a ship shifts from its starting or turn speed to its straight line speed. The higher your acceleration score, the faster your Racecraft can shift into a higher speed on the track.",
              },
              {
                title: "STRENGTH // LAPS",
                description:
                  "As the race goes on, the racecrafts max speed and handling will decrease based on the racecrafts Strength attribute. Higher levels of Strength mean that a racecraft will be able to hit its max Speed and Handling for longer in the race.",
              },
            ],
          },
        }}
        description="Racecrafts have a number of different attributes. These attributes play an important role in your performance in the big races. Select wisely."
        blockPositionOnDesktop="left"
        links={[
          {
            name: "Learn more",
            url: "https://blog.exiledracers.com/racecraft-stats-attributes-revealed-c6ae976ab79e",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
      />
      <SectionTextWithBlock
        title="Pilots"
        subtitle="ATTRIBUTES"
        block={{
          type: "accordion",
          content: {
            items: [
              {
                title: "FOCUS // ALL",
                description:
                  "The Focus attribute controls the randomness of your Racecrafts movements. The more Focus your pilot has, the lower the randomness, ensuring that your Racecraft will move at close to its maximum potential.",
              },
              {
                title: "STAMINA // LAPS",
                description:
                  "Throughout the race your Pilot's focus decreases based on how much stamina you have left. The lower your stamina, the more your focus depletes meaning other racers might be able to pass you in the final stages of the race.",
              },
              {
                title: "AGGRESSION // RACERS",
                description:
                  "Aggression controls a racers ability to overtake other racers or create separation. Using aggression temporarily increases your Pilots focus levels to allow your ship to burst past others but reduces your Pilot's stamina. The more aggression your pilot has, the longer the overtake burst lasts.",
              },
              {
                title: "INTELLIGENCE // RACERS",
                description:
                  "Intelligence controls when and how your Pilot uses its aggression bursts. Pilots with higher intelligence will be able to recognize an overtake opportunity and choose the best time to use aggression.",
              },
            ],
          },
        }}
        description="Pilots also have a number of different attributes. These attributes also play an important role in your performance in the big races. Click the + button to learn."
        blockPositionOnDesktop="right"
        links={[
          {
            name: "Learn more",
            url: "https://blog.exiledracers.com/exr-pilot-attributes-details-revealed-daaaf811d12",
          },
          {
            name: "Buy stack",
            url: "https://moonbeans.io/storefront/exr",
          },
        ]}
      />
    </>
  );
};

