import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Globals } from "../global/globals.js";
import MeshReflectorMaterial from "./MeshReflectorMaterial";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

export default class Viewer {
  static FOV = 80;
  static Z_NEAR = 0.5;
  static Z_FAR = 8000;
  // static UPDATES_PER_SECOND = 40;
  // static UPDATE_RATE = 1 / Viewer.UPDATES_PER_SECOND;
  // static FRAME_TIME_MAX = .1;
  static CAMERA_RADIUS = 2000;
  static Y_SHIFT = -250;
  static Z_SHIFT = 0;
  static X_SHIFT = 0;
  static FRICTION = 0.1;
  static MIN_SPEED = 0.01;
  static PITCH_RANGE = new THREE.Vector2(0.05, Math.PI * 0.45);
  static SX = 0;
  static SY = 200;
  static SHIPS = [
    "https://media.exiledracers.com/assets/game/builder/Racer_1_1661802047728.glb",
    "https://media.exiledracers.com/assets/game/builder/Racer_2_1661802080736.glb",
    "https://media.exiledracers.com/assets/game/builder/Racer_3_1661802107627.glb",
    "https://media.exiledracers.com/assets/game/builder/Racer_4_1661802132666.glb",
  ];

  /**
   * Construct a standalone ship model viewer
   * @param {HTMLCanvasElement} canvas The canvas to render on
   * @param {number} [sensitivity] Mouse controls sensitivity
   */
  constructor(canvas, sensitivity = 7) {
    this.sensitivity = sensitivity;
    this.pitch = Math.PI * 0.12;
    this.angle = Math.PI * 0.25;
    this.pitchSpeed = 0;
    this.angleSpeed = 0;
    this.mouseDown = false;
    this.ship = null;
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
      outputEncoding: THREE.sRGBEncoding,
    });
    this.count = 0

    //Globals.CUBE_MAP_CACHE.setRenderer(this.renderer);

    this.camera = new THREE.PerspectiveCamera(
      Viewer.FOV,
      canvas.width / canvas.height,
      Viewer.Z_NEAR,
      Viewer.Z_FAR
    );

    this.camera.position.set(
      Viewer.CAMERA_RADIUS,
      Viewer.CAMERA_RADIUS,
      Viewer.CAMERA_RADIUS
    );

    //testing orbit controls w/ other controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.listenToKeyEvents(window); // optional
    this.autorotate = false;
    this.controls.screenSpacePanning = false;
    this.controls.minDistance = 800;
    this.controls.maxDistance = 2000;
    this.controls.maxPolarAngle = (5 * Math.PI) / 12; //11/20
    this.controls.update();

    //booleans for view options
    this.wing = false;
    this.propulsion = false;
    this.fuse = false;
    this.plane = null;
    this.spotLight1 = null;
    this.light = true;

    // TODO: Test model
    // this.loadAsset("assets/racers/racer4.glb");
    this.loadNextShip();
    this.animate();
    // this.createReflection();
    // this.animate();

    // const loader = new RGBELoader();
    // loader.load("https://media.exiledracers.com/assets/game/builder/Viewer_Background_1668700921459.hdr", (tex) => {
    //   const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    //   pmremGenerator.compileEquirectangularShader();

    //   this.scene.environment = this.scene.background =
    //     pmremGenerator.fromEquirectangular(tex).texture;
    // });

    //add black floor
    // const floorGeo = new THREE.CircleBufferGeometry(5000, 5000);
    // const floorMat = new THREE.MeshBasicMaterial({ color: 0xc0c0c0 });
    // const floor = new THREE.Mesh(floorGeo, floorMat);
    // this.scene.add(floor);
    // floor.position.y = -250;
    // floor.rotation.x = -Math.PI / 2;

    // this.scene.translateX(10000);

    // TODO: Test lights
    // this.scene.background = new THREE.Color().setHSL( 0.5, 0, 0.8 );
    //this.scene.fog = new THREE.Fog( 0xCBCBCB, 100, 7000 );

    this.scene.add(new THREE.AmbientLight(0xffffff, 1));

    const sun = new THREE.DirectionalLight(0xffffff, 1);
    sun.position.set(0, 1, 0);
    this.scene.add(sun);

    const ambientIntensity = 1;
    const ambientColor = 0xFFFFFF;
    const directIntensity = 0.8 * Math.PI;
    const directColor = 0xFFFFFF;
    
    // // const hemiLight = new THREE.HemisphereLight();
    // // hemiLight.name = 'hemi_light';
    // // this.scene.add(hemiLight);

    // const light1  = new THREE.AmbientLight(ambientColor, ambientIntensity);
    // light1.name = 'ambient_light';
    // //this.scene.add(light1);

    const light2  = new THREE.DirectionalLight(0xffffff, 1);
    light2.position.set(0.5, 0, 0.866); // ~60º
    //ålight2.name = 'main_light';
    this.scene.add(light2);

    const light3  = new THREE.DirectionalLight(0xffffff, 1);
    light3.position.set(-0.5, 0, -0.866); // ~60º
    //light3.name = 'main_light';
    this.scene.add(light3);

    // this.renderer.toneMapping = Number(THREE.ACESFilmicToneMapping);
    // this.renderer.toneMappingExposure = Math.pow(2, 0.5);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.textureEncoding = THREE.sRGBEncoding;

    /*
     *Adding UI interaction to keys here remember to add rotation button?
     */
    window.addEventListener("keydown", (event) => {
      //turns off auto-rotate

      if (event.key === "r") {
        this.autorotate = !this.autorotate;
      }
    });
  }

  /*
    Toggle Skyboxes
    */
  toggleSkybox() {
    this.light = !this.light;

    if (this.light) {
      const loader = new RGBELoader();
      loader.load(
        "https://media.exiledracers.com/assets/game/builder/ShipViewer_Skybox_Medium_1_1664404778419.pic",
        (tex) => {
          const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
          pmremGenerator.compileEquirectangularShader();

          this.scene.environment = this.scene.background =
            pmremGenerator.fromEquirectangular(tex).texture;
        }
      );
    } else {
      const loader = new RGBELoader();
      loader.load(
        "https://media.exiledracers.com/assets/game/builder/ShipViewer_Skybox_Dark_4_1664404696953.pic",
        (tex) => {
          const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
          pmremGenerator.compileEquirectangularShader();

          this.scene.environment = this.scene.background =
            pmremGenerator.fromEquirectangular(tex).texture;
        }
      );
    }
  }

  loadMaterialReflection() {
    return new Promise((resolve) => {
      this.plane = new THREE.Mesh(new THREE.CircleGeometry(5000, 5000));
      this.plane.position.y = -100;
      this.plane.position.x = 100;
      this.plane.rotation.x = -Math.PI / 2;

      this.plane.material = new MeshReflectorMaterial(
        this.renderer,
        this.camera,
        this.scene,
        this.plane,
        {
          resolution: 1024,
          blur: [512, 128],
          mixBlur: 0.8,
          mixContrast: 1,
          mirror: 1,
        }
      );
      this.plane.material.setValues({
        // roughnessMap: new THREE.TextureLoader().load("assets/viewer/roughness_grad.jpg"),
        normalMap: new THREE.TextureLoader().load("assets/viewer/normal.png"),
        normalScale: new THREE.Vector2(0.3, 0.3),
      });

      resolve();
    });
  }

  createReflection() {
    this.loadMaterialReflection().then(() => {
      this.scene.add(this.plane);
    });
  }

  /* 
    different camera options based on racecraft part selection
    */
  //not using this one as much (create a separate function for rotation)
  switchViews() {
    window.addEventListener("mousedown", (event) => {
      this.wing = false;
      this.propulsion = false;
      this.fuse = false;
    });

    if (this.autorotate) {
      this.controls.autoRotate = true;
    } else {
      this.controls.autoRotate = false;
    }
  }

  showWingView() {
    // this.wing = !this.wing
    this.wing = true;
    this.controls.autoRotate = false;
    // this.camera.position.set(0, 300, 1000);

    // const wingP = new THREE.Vector3(0, 300, 1000);
    // this.camera.position.lerp(wingP, 0.5);
  }

  showFuseView() {
    this.fuse = true;
    this.controls.autoRotate = false;

    // this.camera.position.set(-200, 800, 0);
  }

  showPropView() {
    this.prop = true;

    this.controls.autoRotate = false;
    // this.camera.position.set(1500, 300, 0);
  }

  /*
  load different ship
  */
  loadNextShip() {
    
    this.count += 1;
    
    if (this.count >= Viewer.SHIPS.length) {
      this.count = 0;
    }
    
    this.loadAsset(Viewer.SHIPS[this.count]);
    this.removeShip();
  }

  loadPreviousShip() {
    
    this.count -=1;
    
    if (this.count === 0) {
      this.count = Viewer.SHIPS.length-1
    }

    
    this.loadAsset(Viewer.SHIPS[this.count - 1]);
    this.removeShip();
  }

  loadSameShip() {
    
    if (this.count >= Viewer.SHIPS.length) {
      this.count = 0;
    }
    
    this.loadAsset(Viewer.SHIPS[this.count]);
    this.removeShip();
  }

  /**
   * Load an asset into the scene
   * @param {string} url The URL of the GLB model to load
   */
  loadAsset(url) {
    this.removeShip();
    const loader = new GLTFLoader();
    const finalurl = url+"?"+Date.now();
    if(this.ship) {
      this.scene.remove(this.ship.scene)
    }
    loader.load(url, (ship) => {
      this.ship = ship;
      this.ship.scene.traverse((node) => {
        if (node instanceof THREE.Mesh) {
          node.castShadow = true;
          node.material.roughness = 1;
          node.material.transparent = true;
          node.material.alphaTest = 0.5;
          node.material.metalness = 1;
          node.rotateY(Math.PI * -0.5);
          node.translateY(Viewer.Y_SHIFT);
          node.translateZ(Viewer.Z_SHIFT);
          node.translateX(Viewer.X_SHIFT);
          node.scale.multiplyScalar(0.9);
        }
        
      });
      let new_children = [];
      for(var i = 0; i < this.ship.scene.children.length;i++){
        if (this.ship.scene.children[i] instanceof THREE.Mesh ) {
          if((this.ship.scene.children[i].name.indexOf('stickermesh') >= 0)) {
            this.ship.scene.children[i].material.castShadow = false;
            this.ship.scene.children[i].material.side = THREE.DoubleSide;
          }
          this.ship.scene.children[i].material.side = THREE.DoubleSide;
          new_children.push(this.ship.scene.children[i]);
        }
      }
      this.ship.scene.children = new_children;
      this.scene.add(this.ship.scene);
    });
  }

  removeShip() {
    // this.scene.remove(this.scene.children[4]);

    // if (this.scene.children.length >= 5) {
    //   this.scene.remove(this.scene.children[4]);
    //   this.scene.remove(this.scene.children[5])
    // }
    if(this.ship) {
      this.scene.remove(this.ship.scene)
    }
  }

  /* usual animate way */
  animate() {
    requestAnimationFrame(this.animate.bind(this));

    if (this.wing) {
      this.fuse = false;
      this.prop = false;
      const wingP = new THREE.Vector3(0, 300, 1000);
      this.camera.position.lerp(wingP, 0.3);
    } else if (this.fuse) {
      this.wing = false;
      this.prop = false;
      const fuseP = new THREE.Vector3(-200, 800, 0);
      this.camera.position.lerp(fuseP, 0.3);
    } else if (this.prop) {
      this.wing = false;
      this.fuse = false;
      const propP = new THREE.Vector3(1500, 300, 0);
      this.camera.position.lerp(propP, 0.3);
    } else {
      window.addEventListener("mousemove", (event) => {
        this.wing = false;
        this.fuse = false;
        this.prop = false;
      });
    }

    this.controls.update();


    //this.plane.material.update();

    // this.switchViews();

    this.render();
  }

  /**
   * Render a frame
   * @param {number} time The time interpolation in the range [0, 1]
   */
  render() {
    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);
  }
}


