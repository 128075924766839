import * as THREE from "three";
import {CameraTargetable} from "../../camera/cameraTargetable.js";
import {lerp} from "three/src/math/MathUtils.js";

/**
 * A ghost racer to target which approximates the leading racer
 */
export class LeaderGhost extends CameraTargetable {
    static TRANSITION = .8;

    /**
     * Construct the leader ghost
     */
    constructor() {
        super();

        this.target = this.targetPrevious = null;
        this.transition = this.transitionPrevious = 1;

        this.positionInterpolated = new THREE.Vector3();
        this.quaternionInterpolated = new THREE.Quaternion();
        this.directionInterpolated = new THREE.Vector3();
    }

    /**
     * Update the leader ghost
     * @param {number} delta The time delta
     * @param {CameraTargetable} target The first targetable to track
     */
    update(delta, target) {
        if (this.transition !== 1) {
            this.transitionPrevious = this.transition;

            if ((this.transition += LeaderGhost.TRANSITION * delta) > 1)
                this.transition = this.transitionPrevious = 1;
        }

        if (this.target !== target && this.transition === this.transitionPrevious) {
            this.targetPrevious = this.target || target;
            this.target = target;
            this.transition = this.transitionPrevious = 0;
        }
    }

    /**
     * Get the interpolation factor
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {number} The interpolation in the range [0, 1]
     */
    getInterpolation(time) {
        const t = lerp(this.transitionPrevious, this.transition, time);

        return t * t * (3 - t - t);
    }

    /**
     * Get the position of the targetable
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {Vector3} The position
     */
    getPosition(time) {
        this.positionInterpolated.copy(this.targetPrevious.getPosition(time)).lerp(
            this.target.getPosition(time), this.getInterpolation(time));

        return this.positionInterpolated;
    }

    /**
     * Get the quaternion of this targetable
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {Quaternion} The quaternion
     */
    getQuaternion(time) {
        this.quaternionInterpolated.slerpQuaternions(
            this.targetPrevious.getQuaternion(time),
            this.target.getQuaternion(time),
            this.getInterpolation(time));

        return this.quaternionInterpolated;
    }

    /**
     * Get the direction of the targetable
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {Vector3} The normalized direction
     */
    getDirection(time) {
        this.directionInterpolated.copy(this.targetPrevious.getDirection(time)).lerp(
            this.target.getDirection(time), this.getInterpolation(time));

        return this.directionInterpolated;
    }

    /**
     * Get the distance of the targetable on the track
     * @param {number} time The time interpolation in the range [0, 1]
     * @returns {number} The distance
     */
    getDistance(time) {
        return lerp(
            this.targetPrevious.getDistance(time),
            this.target.getDistance(time),
            this.getInterpolation(time));
    }

    /**
     * Get the index of the track piece this targetable is on
     * @returns {number} The index
     */
    getTrackPieceIndex() {
        return this.target.getTrackPieceIndex();
    }

    /**
     * Get the index of the lap this targetable is on
     * @returns {number} The index
     */
    getLap() {
        return this.target.getLap();
    }
}