import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import "../../races_home.css";
import $ from "jquery";

let total_page_results = 25;
let current_racer_pagenum = 0;
let current_racer_timeseries = 'all-time';
let current_racer_sort = 'rpm';
let current_prophet_pagenum = 0;
let current_prophet_timeseries = 'all-time';
let current_prophet_sort = 'xp';
let racerSectionHTMLArray = [];
let prophetSectionHTMLArray = [];

export const LeaderboardsPage = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [racersSectionHTML, setRacerSectionHTML] = useState('');
    const [showMoreRacersHTML, setShowMoreRacersHTML] = useState('');
    const [prophetSectionHTML, setProphetSectionHTML] = useState('');
    const [showMoreProphetsHTML, setShowMoreProphetsHTML] = useState('');
    const [racerTimeSeries, setRacerTimeSeries] = useState('all-time');
    const [prophetTimeSeries, setProphetTimeSeries] = useState('all-time');
    const [invite_racer_name, setInviteRacerName] = useState('');

    var racer_items = [];
    function grabRacerLeaderboard(timeSort,pagenum) {
        current_racer_pagenum = pagenum;
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_racer_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('leaderboard/racers_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data; 
                loadRacerLeaderboard(racer_details,pagenum);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabProphetLeaderboard(timeSort,pagenum) {
        current_prophet_pagenum = pagenum;
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_prophet_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);
        
        axiosInstance.post('leaderboard/prophets_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data; 
                loadProphetLeaderboard(racer_details,pagenum);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function updateRacerLeaderBoardTime(newtime) {
        current_racer_timeseries = newtime;
        setRacerTimeSeries(newtime);
        window.$(".racer-board-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#racer-lb-time-"+newtime).removeClass("white-green-link").addClass("white-green-link-active");
        grabRacerLeaderboard(newtime,0);
    }

    function updateProphetBoardTime(newtime) {
        current_prophet_timeseries = newtime;
        setProphetTimeSeries(newtime);
        window.$(".prophet-board-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#prophet-lb-time-"+newtime).removeClass("white-green-link").addClass("white-green-link-active");
        grabProphetLeaderboard(newtime,0);
    }

    function updateRacerLeaderBoardSort(newsort) {
        current_racer_sort = newsort;
        window.$(".racer-row-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#racer-lb-sort-"+newsort).removeClass("white-green-link").addClass("white-green-link-active");
        grabRacerLeaderboard(racerTimeSeries,0);
    }

    function updateProphetLeaderBoardSort(newsort) {
        current_prophet_sort = newsort;
        window.$(".prophet-row-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-red-link");
        });
        window.$("#prophet-lb-sort-"+newsort).removeClass("white-green-link").addClass("white-green-link-active");
        grabProphetLeaderboard(prophetTimeSeries,0);
    }

    function showMoreResultsRacers(pagenum) {
        $("#showmoreracersbutton").hide();
        $("#showmoreracersloading").show();
        grabRacerLeaderboard(current_racer_timeseries,pagenum);
    }

    function showMoreResultsProphet(pagenum) {
        $("#showmoreprophetsbutton").hide();
        $("#showmoreprophetsloading").show();
        grabProphetLeaderboard(current_prophet_timeseries,pagenum);
    }

    function openInviteModal() {
        var invitebox = document.getElementById('invite-single-box');
        var invite_instance = window.M.Modal.getInstance(invitebox);
        invite_instance.open();
    }

    function inviteToRaceSingle(e) {
        var racer_name = e.target.dataset.invitename;
        window.startPageModals();
        setInviteRacerName(racer_name);
        window.$("#invitesinglesubmit").click(function() {
            window.location.href="/#/race_landing/invite/"+encodeURIComponent(racer_name);
        });
        openInviteModal();
    }

    function loadRacerLeaderboard(racearr,pagenum) {
        var ranking_section_html = [];
        var pilotcnt = 0;
        for(var z = 0; z < racearr.length;z++) {
            var racer_id = racearr[z].racer_id;
            var racer_unique_id = racearr[z].racer_unique_id;
            var racer_name = racearr[z].racer_name;
            var racer_coins = racearr[z].racer_coins;
            var racer_xp = racearr[z].racer_xp;
            var racer_photo = racearr[z].racer_photo;

            /* Calculate Race Time */
            var total_races = racearr[z].total_races;
            var total_wins = racearr[z].total_wins;
            var win_pct = Math.round((total_wins / total_races)*100);

            var star_icon = '';
            var rowcolor = 'white-text';
            var rowlinkcolor = 'white-green-link';
            var rowbgcolor = '';
            var invite_icon = <i id={"invite-"+racer_name} className="material-icons topnavtoggle race-board-row-launch-icon">launch</i>
            if(racer_id == racerID) {
                //star_icon = <i className="material-icons exr-red-text">star</i>
                rowcolor = 'exr-light-green-text';
                rowbgcolor = 'exr-red-bg';
                rowlinkcolor = 'exr-light-green-text';
                invite_icon = '';
            } 
            if(racer_coins < 25) {
                invite_icon = '';
            }
            
            var pilot_image = <div className="exr-stats-light-grey" data-invitename={racer_name} style={{width:'50px',height:'50px',borderRadius:'5px',marginBottom:'7px'}}></div>;
            if(racer_photo !== null) {
                pilot_image = <img src={racer_photo} width="50px" data-invitename={racer_name} style={{borderRadius:"5px",lineHeight:'1'}} />;
            }

            var showbattles = 'Y';
            var invite_name_button = <><div className="left" style={{marginRight:"10px",width:"50px"}}>
                                            {pilot_image}
                                        </div>
                                        <div className="left" style={{marginTop:"12px"}}>
                                        {racer_name}
                                        </div></>
            if(racer_id == racerID) {
                showbattles = 'N';
            }
            if(showbattles == 'Y') {
                invite_name_button = <><div className={"left "+rowlinkcolor} title="Invite To Race" 
                                            style={{marginRight:"10px",width:"50px"}} onClick={(e)=>inviteToRaceSingle(e)}>
                                        {pilot_image}
                                    </div>
                                    <div className={"left "+rowlinkcolor} title="Invite To Race" data-invitename={racer_name} 
                                        style={{marginTop:"12px"}} onClick={(e)=>inviteToRaceSingle(e)}>
                                        {racer_name}
                                    </div></>
            }
            
            var rowkey = z+1+(pagenum*total_page_results);
            var tmp_html = <div key={rowkey} className="row race-board-row" id={'leader_row_'+racer_id} style={{cursor:'auto',marginBottom:'0px',paddingTop:'7px',paddingBottom:'0px'}}>
                            <div className={"row "+rowcolor} style={{marginTop:'0px',marginBottom:'0px',fontSize:'16px',fontWeight:'900'}}>
                                <div className="col s6" style={{overflow:'hidden'}}>
                                    <div className="left" style={{width:'200%'}}>
                                        <div className="left headline-text center-align" style={{fontSize:"16px",marginRight:"10px",letterSpacing:"-1px",width:"32px",marginTop:"15px"}}>
                                            {rowkey}
                                        </div>
                                        {invite_name_button}
                                    </div>
                                </div>
                                <div className="col s2 center-align" title="RPM Awarded" style={{marginTop:"12px"}}>
                                    {racer_coins.toLocaleString("en-US")}
                                </div>
                                <div className="col s2 center-align" title="XP Earned" style={{marginTop:"12px"}}>
                                    {racer_xp.toLocaleString("en-US")}
                                </div>
                                <div className="col s2 center-align" style={{marginTop:"12px"}}>
                                    {total_wins}
                                </div>
                            </div>
                        </div>;
            ranking_section_html.push(tmp_html);
            
        }

        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = racerSectionHTMLArray.concat(ranking_section_html);
        } else {
            final_section_html_array = ranking_section_html;
        }
        racerSectionHTMLArray = final_section_html_array;
        setRacerSectionHTML(final_section_html_array);

        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(ranking_section_html.length == total_page_results) {
            showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                <div id="showmoreracersbutton" className="center-align" title="Show More Racers">
                                    <span className="round-border-button" style={{fontSize:'16px'}} title="Show More Racers"  onClick={() => showMoreResultsRacers(nextpage) }>
                                        SHOW MORE RACERS
                                    </span>
                                </div>
                                <div id="showmoreracersloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreRacersHTML(showmorebutton);
        
        setTimeout(function(){
            props.sizeAdjust();
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },500);
        setTimeout(function(){
            props.sizeAdjust();
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },2000);
    }

    function loadProphetLeaderboard(racearr,pagenum) {
        var ranking_section_html = [];
        var pilotcnt = 0;
        for(var z = 0; z < racearr.length;z++) {
            var racer_id = racearr[z].racer_id;
            var racer_unique_id = racearr[z].racer_unique_id;
            var racer_name = racearr[z].racer_name;
            var total_rpm = racearr[z].rpm_earned;
            var total_xp = racearr[z].xp_earned;
            var racer_photo = racearr[z].racer_photo;

            /* Calculate Race Time */
            var total_predicted = racearr[z].total_predictions;
            var total_correct = racearr[z].total_predictions_correct;
            var correct_pct = Math.round((total_correct / total_predicted)*100);

            var star_icon = '';
            var rowcolor = 'white-text';
            var rowbgcolor = '';
            if(racer_id == racerID) {
                //star_icon = <i className="material-icons exr-red-text">star</i>
                rowcolor = 'exr-light-green-text';
                rowbgcolor = 'exr-red-bg';
            } 

            var pilot_image = <div className="exr-stats-light-grey" style={{width:'50px',height:'50px',borderRadius:'5px',marginBottom:'7px'}}></div>;
            if(racer_photo !== null) {
                pilot_image = <img src={racer_photo} width="50px" style={{borderRadius:"5px",lineHeight:'1'}} />;
            }
            
            var rowkey = z+1+(pagenum*total_page_results);
            var tmp_html = <div key={rowkey} className="row race-board-row" id={'leader_row_'+racer_id} style={{cursor:'auto',marginBottom:'0px',paddingTop:'7px',paddingBottom:'0px'}}>
                            <div className={"row "+rowcolor} style={{marginTop:'0px',marginBottom:'0px',fontSize:'16px'}}>
                                <div className="col s6" style={{overflow:'hidden'}}>
                                    <div className="left" style={{width:'200%'}}>
                                        <div className="left headline-text center-align" style={{fontSize:"16px",marginRight:"10px",letterSpacing:"-1px",width:"32px",marginTop:"15px",fontWeight:'900'}}>
                                            {rowkey}
                                        </div>
                                        <div className="left" style={{marginRight:"10px",width:"50px"}}>
                                            {pilot_image}
                                        </div>
                                        <div className="left" style={{marginTop:"12px"}}>
                                            {racer_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="col s2 center-align" title="XP Earned" style={{marginTop:"12px"}}>
                                    {total_xp.toLocaleString("en-US")}
                                </div>
                                <div className="col s2 center-align" style={{marginTop:"12px"}}>
                                    {total_correct}
                                </div>
                                <div className="col s2 center-align" style={{marginTop:"12px"}}>
                                    {correct_pct+'%'}
                                </div>
                            </div>
                        </div>;
            ranking_section_html.push(tmp_html);
            
        }

        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = prophetSectionHTMLArray.concat(ranking_section_html);
        } else {
            final_section_html_array = ranking_section_html;
        }
        prophetSectionHTMLArray = final_section_html_array;
        setProphetSectionHTML(final_section_html_array);

        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(ranking_section_html.length == total_page_results) {
            showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                <div id="showmoreprophetsbutton" className="center-align" title="Show More Prophets">
                                    <span className="round-border-button" style={{fontSize:'16px'}} title="Show More Prophets"  onClick={() => showMoreResultsProphet(nextpage) }>
                                        SHOW MORE PROPHETS
                                    </span>
                                </div>
                                <div id="showmoreprophetsloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreProphetsHTML(showmorebutton);
        
        setTimeout(function(){
            props.sizeAdjust();
            $("#prophetshowmorebutton").show();
            $("#prophetshowmoreloading").hide();
        },500);
        setTimeout(function(){
            props.sizeAdjust();
            $("#prophetshowmorebutton").show();
            $("#prophetshowmoreloading").hide();
        },2000);
    }

    function showLeaderboardInfobox() {
        var infobox = document.getElementById('training_box_leaderboard');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    window.$(document).ready(function(){
        var sel_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(sel_elems);
    });

    if(entrySet == false) {
        setEntry(true);
        grabRacerLeaderboard(racerTimeSeries,0);
        grabProphetLeaderboard(prophetTimeSeries,0);
    }

    function goToWorkshop() {
        window.location.href= '/#/workshop';
    }

    return(<><div className="col s12 headline-text" style={{fontSize:"30px"}}>
                <span className="left">LEADERBOARDS</span>
                <span className="right material-icons material-symbols-outlined white-red-link" 
                style={{fontSize:"30px",marginLeft:"10px",marginTop:'4px'}} 
                onClick={()=>showLeaderboardInfobox()} 
                title="Learn About The EXR Leaderboards">info</span>
            </div>
            <div className="col s12" style={{marginBottom:"10px"}}>
                <div className="divider-thin"></div>
            </div>
            <div className="col s12">
                <div className="row" style={{marginTop:'0px',marginBottom:'0px',fontSize:'16px'}}>
                    <div className="col s12 l6" style={{padding:'5px'}}>
                        <div className="row exr-new-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div className="col s4 m6 headline-text white-text left-align" style={{marginTop:'0px'}}>
                                    TOP RACERS
                                </div>
                                <div className="col s8 m6 white-text right-align" style={{fontSize:'14px'}}>
                                    <div id="racer-lb-time-weekly" title="This Week: Starting Monday 12AM EST" className="right white-green-link racer-board-sort-link" style={{marginRight:'0px'}} 
                                        onClick={()=>updateRacerLeaderBoardTime('weekly')}>
                                        WEEKLY
                                    </div>
                                    <div id="racer-lb-time-all-time" className="right white-green-link-active racer-board-sort-link" style={{marginRight:'20px'}} 
                                        onClick={()=>updateRacerLeaderBoardTime('all-time')}>
                                        ALL TIME
                                    </div>
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            <div className="row" style={{marginBottom:'5px',marginTop:'5px',fontSize:'14px'}}>
                                <div className="col s1 headline-text white-text center-align">
                                    #
                                </div>
                                <div className="col s5 headline-text white-text">
                                    RACER
                                </div>
                                <div className="col s2 headline-text white-green-link-active racer-row-sort-link center-align" title="Sort by Most RPM" 
                                    onClick={()=>updateRacerLeaderBoardSort('rpm')} id="racer-lb-sort-rpm" >
                                    RPM
                                </div>
                                <div className="col s2 headline-text white-green-link racer-row-sort-link center-align" title="Sort by Most XP" 
                                    onClick={()=>updateRacerLeaderBoardSort('xp')} id="racer-lb-sort-xp" >
                                    XP
                                </div>
                                <div className="col s2 headline-text white-green-link racer-row-sort-link center-align" title="Sort by Most Wins" 
                                    onClick={()=>updateRacerLeaderBoardSort('wins')} id="racer-lb-sort-wins" >
                                    WINS
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            {racersSectionHTML}
                            <div className="col s12">
                                {showMoreRacersHTML}
                            </div>
                        </div>
                    </div>
                    <div className="col s12 l6" style={{padding:'5px'}}>
                        <div className="row exr-new-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div className="col s4 m6 headline-text white-text left-align" style={{marginTop:'0px'}}>
                                    TOP PROPHETS
                                </div>
                                <div className="col s8 m6 white-text right-align" style={{fontSize:'14px'}}>
                                    <div id="prophet-lb-time-weekly" title="This Week: Starting Monday 12AM EST" className="right white-green-link prophet-board-sort-link" style={{marginRight:'0px'}} 
                                        onClick={()=>updateProphetBoardTime('weekly')}>
                                        WEEKLY
                                    </div>
                                    <div id="prophet-lb-time-all-time" className="right white-green-link-active prophet-board-sort-link" style={{marginRight:'20px'}} 
                                        onClick={()=>updateProphetBoardTime('all-time')}>
                                        ALL TIME
                                    </div>
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            <div className="row" style={{marginBottom:'5px',marginTop:'5px',fontSize:'14px'}}>
                                <div className="col s1 headline-text white-text">
                                    &nbsp;&nbsp;#
                                </div>
                                <div className="col s5 headline-text white-text">
                                    PROPHET
                                </div>
                                <div className="col s2 headline-text white-green-link-active prophet-row-sort-link center-align" title="Sort by Most XP" 
                                    onClick={()=>updateProphetLeaderBoardSort('xp')} id="prophet-lb-sort-xp">
                                    XP
                                </div>
                                <div className="col s2 headline-text white-green-link prophet-row-sort-link center-align" title="Sort by Most Correct Picks" 
                                    onClick={()=>updateProphetLeaderBoardSort('wins')} id="prophet-lb-sort-wins">
                                    WINS
                                </div>
                                <div className="col s2 headline-text white-green-link prophet-row-sort-link center-align" title="Sort by Highest Percent Correct" 
                                    onClick={()=>updateProphetLeaderBoardSort('win_pct')} id="prophet-lb-sort-win_pct">
                                    PCT
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            {prophetSectionHTML}
                            <div className="col s12">
                                {showMoreProphetsHTML}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="invite-single-box" className="modal page-modal page-modal-smedium">
                <div className="modal-content">
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>INVITE TO RACE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'18px'}}>
                            Challenge <span className="exr-light-green-text"><b>{invite_racer_name}</b></span> to a winner takes all race!
                            <br/><br />EXR Challenge Races 
                            require a <span className="exr-light-green-text"><b>25 EXR</b></span> entry fee from each racer with the winner of the race taking the whole pot. Click 
                            Continue to setup your race stack and send your battle invite!
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'50px'}}>
                            <div id="invitesinglesubmit" className="center-align modal-trigger right" title="Continue to Setup Invite Race">
                                <span className="round-border-button" style={{fontSize:'20px'}} title="Continue to Setup Invite Race">
                                    CONTINUE
                                </span>
                            </div>
                            <div id="invitesingleloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>);
};