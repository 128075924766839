import Web3 from "web3";
import axios from "axios";

let web3;

const init = async () => {
  //console.log("initialzing web3");
  if (!window.ethereum) {
    window.alert("Please install MetaMask first.");
    return;
  }

  if (!web3) {
    try {
      // Request account access if needed
      await window.ethereum.enable();

      // We don't know window.web3 version, so we use our own instance of Web3
      // with the injected provider given by MetaMask
      web3 = new Web3(window.ethereum);
    } catch (error) {
      window.alert("You need to allow MetaMask.");
      return;
    }
  }
};

export const tryWeb3 = async (eth) => {
  // @ts-ignore
  if (window.ethereum) {
    // Modern dapp browsers...
    // @ts-ignore
    eth.web3 = new Web3(window.ethereum);
    try {
      // Request account access if needed
      // @ts-ignore
      await window.ethereum.enable();
    } catch (error) {
      console.log("dapp error, user denied access", error);
      // User denied account access...
    }
  } else {
    // Legacy dapp browsers...
    // @ts-ignore
    if (window.web3) {
      // @ts-ignore
      eth.web3 = new Web3(window.web3.currentProvider);
    }
    // Non-dapp browsers...
    else {
      console.log(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }
  if (eth.web3) await loadAccount(eth);
};

export const convertHexToNumber = (hex) => {
  if (!web3) init();
  return web3.utils.toDecimal(hex);
};

export const loadAccount = async () => {
  if (!web3) await init();
  const accounts = await web3.eth.getAccounts();
  return accounts[0];
};

export const syncAccountWallet = async () => {
  if(!web3) {
    if (!window.ethereum) {
      window.alert("Please Install MetaMask First.");
      return;
    }

    if (!web3) {
      try {
        // Request account access if needed
        await window.ethereum.enable();

        // We don't know window.web3 version, so we use our own instance of Web3
        // with the injected provider given by MetaMask
        web3 = new Web3(window.ethereum);
      } catch (error) {
        window.alert("You Need to Allow MetaMask.");
        return;
      }
    }
  }

  if(web3) {
    const accounts = await web3.eth.getAccounts();
    return accounts[0];
  }

}

