import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

export const RacerNft = ({ index, address }) => {
  const { racerContract } = useSelector((state) => state.web3);
  const [tokenId, setTokenId] = useState(null);
  const [tokenUri, setTokenUri] = useState("");
  const { data, error } = useSWR(tokenUri, fetcher);

  useEffect(() => {
    getNft();
  }, [index]);

  async function getNft() {
    try {
      const id = await racerContract.tokenOfOwnerByIndex(address, index);
      setTokenId(id.toNumber());
      console.log("RacerID: "+id.toNumber());
      const uri = await racerContract.tokenUri(id.toNumber());
      console.log("racer uri: ", uri);
      setTokenUri(uri);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div style={{height:'300px',width:'100%',overflow:'hidden',border:'1px solid #141414',whiteSpace:'nowrap',textAlign: 'center'}}>
        <img src={data?.image} width="100%" style={{verticalAlign:'middle'}} />
    </div>
  );
};
