import React, { useEffect, useState } from "react";
import Viewer from "../../../webgl/viewer/viewer.js";
import {axiosInstance, closeAllModals} from "../../../utils";
import "./ship_checker.css";
import $ from "jquery";

let current_token_id = 0;
let last_token_id = 0;
let next_token_filter = 0;
let previous_token_filter = 0;
let viewer = null;
let initial_model_load = 0;
let current_token_info = [];

export const ShipChecker = () => {
  const [pageSet, setPage] = useState(false);
  const [typefilterswitch, setTypeFilterSwitch] = useState(null);
  const [racecraftID, setRacecraftID] = useState('');
  const [racecraft_image_path,setRacecraftImage] = useState('');
  const [racecraftModel, setRacecraftModel] = useState('');
  let resultcnt = 1;
  let currentpage = 0;
  
  const canvas = React.createRef();
  let wingSelection = true;
  let fuseSelection = true;
  let propSelection = true;
  let light = true;

  useEffect(() => {
    if (canvas.current) {
      canvas.current.width = $("#canvascontainer").width();
      canvas.current.height = $("#canvascontainer").width();
      if(initial_model_load == 0) {
        initial_model_load = 1;
        viewer = new Viewer(canvas.current);
      }
      
    }
    loadDropdowns();
  }, [canvas]);


  window.addEventListener("hashchange",
  function(){ 
      window.location.reload(); 
  }, false);

  function loadDropdowns() {
    var ddelems = document.querySelectorAll('.dropdown-trigger');
    var ddinstances = window.M.Dropdown.init(ddelems, {hover:true, alignment:'left',coverTrigger:false});
}

  function toggleWing() {
    if (wingSelection === true) {
      wingSelection = false;
      fuseSelection = false;
      propSelection = false;
    } else {
      wingSelection = true;
    }
    window.$("#gsettingsviewname").html("Wing")
    viewer?.showWingView();
  }

  function toggleFuse() {
    if (fuseSelection === true) {
      fuseSelection = false;
      propSelection = false;
      wingSelection = false;
    } else {
      fuseSelection = true;
    }
    window.$("#gsettingsviewname").html("Fuselage")
    viewer?.showFuseView();
  }

  function toggleProp() {
    if (propSelection === true) {
      propSelection = false;
      wingSelection = false;
      fuseSelection = false;
    } else {
      propSelection = true;
    }
    viewer?.showPropView();
    window.$("#gsettingsviewname").html("Propulsion")
  }

  function toggleNextShip() {
    viewer?.loadNextShip();
  }

  function togglePreviousShip() {
    viewer?.loadPreviousShip();
  }

  function toggleLight() {
    if (light === true) {
      light = false;
      window.$("#skybox").html("Dark");
    } else {
      light = true;
    }
    viewer?.toggleSkybox();
  }

  function showNextRacecraft() {
    next_token_filter = 1;
    last_token_id = current_token_id-1;
    grabNFTResults(0);
  }

  function showPreviousRacecraft() {
    previous_token_filter = 1;
    grabNFTResults(0);
  }

  function reloadShip() {
    showRacecraftDisplay(current_token_info);
  }


  var racer_items = [];
  function grabNFTResults(pagenum) {
    var addData = returnCurrentQueryString();
    //
    //addData.tokenList = encodeURIComponent(tokenList);
    addData.pNum = pagenum;
    addData.resultCount = resultcnt;
    axiosInstance
      .post("mint/viewer_list/", addData)
      .then((result) => {
        if (Object.keys(result.data).length > 0) {
          var view_result = result.data;
          current_token_id = view_result[0]['item_token_id'];
          current_token_info = view_result[0];
          next_token_filter = 0;
          previous_token_filter = 0;
          showRacecraftDisplay(view_result[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function returnCurrentQueryString() {
    var addData = new Object();
    addData.nType = 'racecraft';
    addData.sortBy = 'oldest';

    var currfaction = $("#filter-sel-faction").val();
    var currtier = $("#filter-sel-tier").val();
    var filterlist = "";
    if (currfaction != "all") {
      filterlist = filterlist + "faction_" + currfaction;
    }
    if (currtier != "all") {
      if (filterlist.length > 1) {
        filterlist = filterlist + "|";
      }
      filterlist = filterlist + "tier_" + currtier;
    }
    if((next_token_filter == 1)||(previous_token_filter == 1)) {
      if (filterlist.length > 1) {
        filterlist = filterlist + "|";
      }
      if (previous_token_filter == 1) {
        filterlist = filterlist + "lasttoken_" + last_token_id;
      } else {
        filterlist = filterlist + "lasttoken_" + current_token_id;
      }
    }
    addData.filterList = filterlist;

    var tokensrch = $("#filter-sel-token").val();
    var finaltokensrch = $.trim(tokensrch.replace("#",""));
    if (!isNaN($.trim(finaltokensrch))) {
      finaltokensrch = parseInt($.trim(finaltokensrch));
    }
    if(finaltokensrch >= 0) {
      addData.tokenList = finaltokensrch;
    }

    return addData;
  }

  function updateResultsChange() {
    currentpage = 0;
    grabNFTResults(0);
  }

  function showRacecraftDisplay(iteminfo) {
    var racecraftid = iteminfo['item_token_id'];
    var racecraft_image = iteminfo['image'];
    var racecraft_model = iteminfo['item_model'];
    var racecraft_model_game = iteminfo['item_model_game'];
    var name_split = iteminfo['item_model'].split("/");
    var model_name = name_split[(name_split.length-1)].replace(".glb","").toUpperCase();
    viewer.loadAsset(racecraft_model);
    viewer.removeShip();
    setRacecraftID(racecraftid);
    setRacecraftModel(model_name);
    setRacecraftImage(racecraft_image);
  }

  function toggleMobileFilters() {
    $("#filter-row-count").hide();
    $(".filter-row-filter-shell").each(function () {
      $(this).removeClass("left").removeClass("right");
      $(this).css({ display: "flex", "justify-content": "center" });
      $(this).find(".ftitle").css({ width: "50px", textAlign: "right" });
      $(this).find(".ftxt").css({ width: "135px"});
      $(this).find(".filter-submit-button").hide();
    });
    $("#filterbarlong").slideDown();
  }

  function closeMobileFilters() {
    $("#filterbarlong").slideUp();
  }

  function startPageSelects() {
    var select_elems = document.querySelectorAll('select');
    var instances = window.M.FormSelect.init(select_elems,{classes:'exr-select'});
  }

  if (pageSet == false) {
    setPage(true);
    window.startPageModals();
    window.resetBodyWidth();
    setTimeout(function () {
      startPageSelects();
      grabNFTResults(0);
      if (window.innerWidth < 850) {
        $("#filterbarlong").hide();
      }
    }, 1000);
  }

  var primary_image_path = 'https://exr.mypinata.cloud/ipfs/QmdiSYeA16GE4VAPyxkvLGh2douk39a2SLP6mwPvZAbkYy';

  return (<div id="workshop-main-page" style={{position:'relative',width:'auto',height:'auto'}}>
            
              <div className="row page-width-wide page-clean-body">
                <div className="row border-section-full exr-new-dark-grey white-text" style={{position:'relative'}}>
                  <div id="filterbarmobile" className="col s12 exr-filter-bar hide-on-med-and-up show-on-small-only" style={{ height: "50px" }} >
                    <div id="filter-row-count-mobile" className="col s6 right-align filter-row-title-mobile"></div>
                      <div className="col s6 left-align">
                        <span className="left" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggleMobileFilters()}>
                        <span className="left" style={{ marginTop: "12px" }}>
                          <i className="material-icons">filter_list</i>
                        </span>
                        <span className="left" style={{ marginTop: "15px" }}>
                          &nbsp;Filters
                        </span>
                      </span>
                    </div>
                  </div>
                  <div id="filterbarlong" className="col s12 exr-filter-bar">
                    <div className="filter-row-filter-shell left">
                      <div className="headline-text" style={{fontSize:'18px',marginTop:'2px',marginLeft:'10px'}}>SHIP VIEWER</div>
                    </div>
                    <div className="filter-row-filter-shell right" style={{paddingBottom:'0px'}}>
                      <span className="left ftitle" style={{ marginTop: "4px" }}>
                        Token:
                      </span>
                      <span className="left ftxt">
                        <input id="filter-sel-token" type="text" className="input-field viewer-search" />
                      </span>
                      <div className="filter-submit-button" title="Search by Token #" onClick={() => updateResultsChange()}>
                        <i className="material-icons filter-submit-button-icon">arrow_right</i>
                      </div>
                    </div>
                    <div className="filter-row-filter-shell right" style={{ marginRight: "10px",paddingBottom:'0px' }}>
                      <span className="left ftitle" style={{ marginTop: "4px" }}>
                        Tier:
                      </span>
                      <span className="left">
                        <select id="filter-sel-tier" className="input-field"  onChange={() => updateResultsChange()}>
                          <option value="all" selected>
                            All Tiers
                          </option>
                          <option value="0">Tier 0 {"(Legendary)"}</option>
                          <option value="1">Tier 1 {"(Exiled)"}</option>
                          <option value="2">Tier 2</option>
                          <option value="3">Tier 3</option>
                          <option value="4">Tier 4</option>
                          <option value="5">Tier 5</option>
                        </select>
                      </span>
                    </div>
                    <div className="filter-row-filter-shell right" style={{ marginRight: "20px",paddingBottom:'0px' }}>
                      <span className="left ftitle" style={{ marginTop: "4px" }}>
                        Faction:
                      </span>
                      <span className="left">
                        <select
                          id="filter-sel-faction"
                          className="input-field"
                          onChange={() => updateResultsChange()}
                        >
                          <option value="all" selected>
                            All Factions
                          </option>
                          <option value="mercenary">Mercenary</option>
                          <option value="augment">Augment</option>
                          <option value="serf">Serf</option>
                        </select>
                      </span>
                    </div>
                    <div className="filter-row-filter-shell hide-on-med-and-up show-on-small-only">
                      <span className="left" style={{ marginTop: "-5px", marginBottom: "15px" }} >
                        <button className="side-nav-button waves-effect waves-light btn" style={{ fontSize: "12px", height: "auto" }} onClick={() => closeMobileFilters()}>
                          Close Filters
                        </button>
                      </span>
                    </div>
                  </div>
              </div>
              <div className="row border-section-full exr-new-dark-grey white-text" style={{minHeight:'90vh',position:'relative',padding:"25px"}}>
              <div className="col s12" style={{marginTop:"5px"}}>
                  <div className="left headline-text" style={{fontSize:"20px",marginLeft:'5px'}}>
                      RACECRAFT #{racecraftID}
                  </div>
                  <div className="left" style={{marginLeft:'20px',fontSize:'16px',marginTop:'3px'}}>
                      [{racecraftModel}]
                  </div>
                  <div className="right white-red-link" title="Show Next Racecraft" style={{marginRight:'10px',marginTop:'4px',fontSize:'16px'}} onClick={()=>showNextRacecraft()}>
                      <span>Next</span>
                  </div>
                  <div className="right white" style={{marginRight:'10px',marginTop:'9px',fontSize:'14px',width:'1px',height:'14px',borderRight:'1px solid white'}}></div>
                  <div className="right white-red-link" title="Show Previous Racecraft" style={{marginRight:'10px',marginTop:'4px',fontSize:'16px'}} onClick={()=>showPreviousRacecraft()}>
                      <span>Previous</span>
                  </div>
                  <div className="right white" style={{marginRight:'10px',marginTop:'9px',fontSize:'14px',width:'1px',height:'14px',borderRight:'1px solid white'}}></div>
                  <div className="right white-red-link" title="Show Next Racecraft" style={{marginRight:'10px',marginTop:'4px',fontSize:'16px'}} onClick={()=>reloadShip()}>
                      <span>Reload</span>
                  </div>
                  
              </div>
              <div className="col s12" style={{marginBottom:'30px'}}>
                  <div className="divider-thin"></div>
              </div>
                <div className="col s12 l6" style={{fontSize:'16px',padding:'2.5px'}}>
                  <div className="exr-nviewer-segment" style={{position:'relative',marginLeft:'5%',width:'90%',overflow:'hidden',padding:'0px',lineHeight:'.5'}}>
                    <img id="nviewer-img-large" src={racecraft_image_path} width="100%" style={{borderRadius:'10px'}} />
                  </div>
                </div>
                <div className="col s12 l6" style={{fontSize:'16px',padding:'2.5px'}}>
                  <div id="gui">
                    <div id="canvascontainer" className="exr-nviewer-segment" style={{position:'relative',marginLeft:'2.5%',width:'90%',overflow:'hidden',padding:'0px',lineHeight:'.5'}}>
                      <canvas id="renderer" ref={canvas}  style={{ backgroundColor: "black"}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
  );
};