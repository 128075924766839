import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import {
  setRacerMintPrice,
  setTransactionError,
  setTxHash,
} from "../../../redux/Web3/actions";

import { Button } from "../../Atoms";

import { RacerNft } from "..";

export const RacerSection = () => {
  const dispatch = useDispatch();
  const [numTokens, setNumTokens] = useState(0);
  const { account } = useWeb3React();

  const { racerContract, racerMintPrice, mintIds, pilotContract } = useSelector(
    (state) => state.web3
  );

  useEffect(() => {
    if (!racerContract) return;
    const interval = setInterval(() => {
      checkBalance(() => clearInterval(interval));
    }, 5000);
    checkBalance();
    return () => clearInterval();
  }, [racerContract]);

  function checkBalance(callback) {
    racerContract
      .balanceOf(account)
      .then((qty) => {
        console.log("num pilots: ", qty.toNumber());
        setNumTokens(qty.toNumber());
        if (qty.toNumber() > 0) callback();
      })
      .catch((err) => console.log(err));
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {[...Array(numTokens)].map((e, i) => (
        <RacerNft index={i} address={account} key={`racer-${i}`} />
      ))}
    </div>
  );
};
