import * as THREE from "three";
import Racer from "./racer/racer.js";
import {TrackTracer} from "../track/trackTracer.js";
import {StyleUtils} from "../utils/styleUtils.js";
import {LeaderGhost} from "./racer/leaderGhost.js";

/**
 * All racers
 */
export class Racers {
    static WIDTH_PER_RACER = StyleUtils.getFloat("--racer-spacing-min");
    static WIDTH_PER_RACER_MAX = StyleUtils.getFloat("--racer-spacing-max");
    static TRACK_LANES_MINIMUM = StyleUtils.getInt("--racer-min-lanes");
    static TRACK_LANES_MAXIMUM = StyleUtils.getInt("--racer-max-lanes");
    static TRACK_WIDTH_DEFAULT = Racers.TRACK_LANES_MINIMUM * Racers.WIDTH_PER_RACER;
    static TRACK_WIDTH_MAX = Racers.TRACK_LANES_MAXIMUM * Racers.WIDTH_PER_RACER_MAX;

    #racers = [];
    #finishedRacers = [];
    #finishedOrder = [];
    #orderFinal = null;
    #time = 0;
    #started = false;
    #trackingRacer = null;
    #leader = new LeaderGhost();
    #cachedOrder = null;
    #player = null;

    #scene;
    #track;
    #onFinish;
    #race_type;

    /**
     * Construct the racers
     * @param {Scene} scene The scene
     * @param {RacersData} data The racers data
     * @param {Track} track The track
     * @param {Function} onFinish A function to call when a racer crosses the finish line
     */
    constructor(scene, data, track, onFinish) {
        this.#scene = scene;
        this.#track = track;
        this.#onFinish = onFinish;
        this.#race_type = track.race_type;
        this.#cachedOrder = null;
        this.#player = null;

        const racerCount = data.racers.length;
        let trackWidth = 0;
        if(racerCount > 6) {
            trackWidth = Racers.WIDTH_PER_RACER_MAX * racerCount;
        } else {
            trackWidth = Racers.WIDTH_PER_RACER * racerCount;
        }

        for (let racer = 0; racer < racerCount; ++racer) {
            this.#racers.push(new Racer(
                data.racers[racer],
                new TrackTracer(track),
                new THREE.Vector3(
                    0,
                    .3,
                    trackWidth * (racer / (racerCount - 1) - .5)
                ), racerCount));

            if (data.racers[racer].currentRacer)
                this.#player = this.#racers[this.#racers.length - 1];
        }

        this.#player = this.#player || this.#racers[0];
    }

    /**
     * Get the number of seconds to the first finish
     * @param {number} length The track length
     * @returns {number} The number of seconds
     */
    getTimeToFinish(length) {
        let time = Number.POSITIVE_INFINITY;

        for (const racer of this.#racers)
            time = Math.min(time, racer.getTimeToFinish(length));

        return time;
    }

    /**
     * Set the racer to track
     * @param {Racer} racer A racer
     */
    set trackingRacer(racer) {
        this.#trackingRacer = racer;
    }

    /**
     * Get the time
     * @returns {number} The time in seconds
     */
    get time() {
        return this.#time;
    }

    /**
     * Get the targetable leader
     * @returns {CameraTargetable} The leader
     */
    get leader() {
        return this.#leader;
    }

    /**
     * Get the player racer
     * @returns {Racer} The player
     */
    get player() {
        return this.#player;
    }

    /**
     * Get the array of racers
     * @returns {Racer[]} The array of racers
     */
    get racers() {
        return this.#racers;
    }

    /**
     * Get the required track width for the racers
     * @returns {number} The required track width
     */
    get trackWidth() {
        if(this.racers.length > 6) {
            return Math.max(Racers.TRACK_LANES_MAXIMUM, this.racers.length) * Racers.WIDTH_PER_RACER_MAX;
        } else {
            return Math.max(Racers.TRACK_LANES_MINIMUM, this.racers.length) * Racers.WIDTH_PER_RACER;
        }
        
    }

    /**
     * Get the number of racers
     * @returns {number} The number of racers
     */
    get racerCount() {
        return this.#racers.length;
    }

    /**
     * Get the racer that's being tracked
     * @returns {Racer} The racer
     */
    get tracking() {
        return this.#trackingRacer || this.#player;
    }

    /**
     * Get the racers ordered according to their final standing
     * @returns {number[]} The order of the racers
     */
    get orderFinal() {
        return this.#orderFinal || (this.#orderFinal = this.makeOrderFinal());
    }

    /**
     * Start the engine sounds
     * @param {Audio} audio The audio object
     */
    startEngineSounds(audio) {
        for (const racer of this.#racers)
            racer.startEngineSound(audio);
    }

    /**
     * Get a racer with a specific standing
     * @param {number} standing The standing, starting at 0
     * @returns {Racer} The racer with the final standing, or null if it does not exist
     */
    getRacerWithStanding(standing) {
        for (const racer of this.#racers)
            if (racer.data.finalStanding === standing)
                return racer;

        return null;
    }

    /**
     * Get the order of the racers
     * @returns {number[]} The order of the racers
     */
    order(rtype='standard') {
        if (this.#cachedOrder)
            return this.#cachedOrder;

        const racers = [];

        for (let racer = 0, racerCount = this.#racers.length; racer < racerCount; ++racer)
            if (this.#racers[racer].finished === -1)
                racers.push(this.#racers[racer]);

        racers.sort((a, b) => b.moved - a.moved);

        const order = [];

        for (let racer = 0, racerCount = racers.length; racer < racerCount; ++racer) {
            const index = this.#racers.indexOf(racers[racer]);

            if (index !== -1)
                order.push(index);
        }

        let combined_order = [...this.#finishedOrder, ...order];
        
        if ((rtype === 'reverse') && (racers.length > 0)) {
            combined_order = combined_order.reverse();
        }

        return this.#cachedOrder = combined_order;
    }

    /**
     * Make the final order
     * @returns {number[]} The final order of the racers
     */
    makeOrderFinal() {
        const order = [];
        const racers = [];

        for (let racer = 0, racerCount = this.#racers.length; racer < racerCount; ++racer)
            racers.push(this.#racers[racer]);

        racers.sort((a, b) => b.data.finalStanding - a.data.finalStanding);

        for (let racer = 0, racerCount = racers.length; racer < racerCount; ++racer)
            order.push(this.#racers.indexOf(racers[racer]));

        return order;
    }

    /**
     * Get the first racer
     * @returns {Racer} The racer currently in the first place
     */
    get first() {
        let furthest = 0;
        let shortest = Number.MAX_SAFE_INTEGER;
        let first = null;

        for (let racer = 0, racerCount = this.#racers.length; racer < racerCount; ++racer) {
            if (this.#race_type === 'reverse') {
                if (first === null || this.#racers[racer].moved < shortest) {
                    first = this.#racers[racer];
                    shortest = this.#racers[racer].moved;
                }
            } else {
                if (first === null || this.#racers[racer].moved > furthest) {
                    first = this.#racers[racer];
                    furthest = this.#racers[racer].moved;
                }
            }
        }

        return first;
    }

    /**
     * Get the last racer
     * @returns {Racer} The racer currently in the last place
     */
    get last() {
        let nearest = Number.POSITIVE_INFINITY;
        let last = null;

        for (let racer = 0, racerCount = this.#racers.length; racer < racerCount; ++racer) {
            if (last === null || this.#racers[racer].moved < nearest) {
                last = this.#racers[racer];
                nearest = this.#racers[racer].moved;
            }
        }

        return last;
    }
    
    /**
     * Get the current_racer_info
     * @returns {Racer[]} The player racer
     */
     get boostingRacers() {
        const racers = [];

        for (let racer = 0, racerCount = this.#racers.length; racer < racerCount; ++racer)
            racers.push(this.#racers[racer].aggressionBoost);

        return racers;
    }
    
    /**
     * Load racers
     * @param {WebGLRenderer} renderer The renderer
     */
    load(renderer) {
        for (const racer of this.#racers)
            racer.load(renderer).then(() => {
                this.#scene.add(racer.group);
                this.#scene.add(racer.trail.mesh);
            });
    }

    /**
     * Seek to a specific race time
     * @param {number} time The time in seconds
     */
    seek(time) {
        for (let racer = 0, racerCount = this.#racers.length; racer < racerCount; ++racer)
            this.#racers[racer].seek(time);

        this.#time = time;
    }

    /**
     * Update the racers state
     * @param {number} delta The time delta
     * @param {function | null} spawnFinalStanding A function to spawn final standing effects
     */
    update(delta, spawnFinalStanding) {
        if (!this.#started)
            return;

        this.#cachedOrder = null;
        this.#time += delta;

        for (let racer = 0, racerCount = this.#racers.length; racer < racerCount; ++racer) {
            this.#racers[racer].update(this.#time);

            if (spawnFinalStanding !== null &&
                this.#racers[racer].hasJustFinished(this.#track.length * this.#track.laps))
                spawnFinalStanding(this.#racers[racer].position, this.#racers[racer].data.finalStanding);

            if (this.#racers[racer].finished === -1 &&
                this.#racers[racer].moved === -1) {
                this.#racers[racer].setFinished();

                this.#finishedRacers.push(this.#racers[racer]);
                this.#finishedRacers.sort((a, b) => a.finished - b.finished);

                this.#finishedOrder = [];

                for (let finished = 0, finishedCount = this.#finishedRacers.length; finished < finishedCount; ++finished)
                    this.#finishedOrder.push(this.#racers.indexOf(this.#finishedRacers[finished]));

                this.#onFinish(this.#racers[racer], this.#finishedRacers.length === this.#racers.length);
            }
        }

        this.#leader.update(delta, this.first);
    }

    /**
     * Render a frame
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {number} delta The time delta
     * @param {Blip[]} blips The blips to update
     * @param {Audio} audio The audio object
     */
    render(time, delta, blips, audio) {
        for (let racer = 0, racerCount = this.#racers.length; racer < racerCount; ++racer)
            this.#racers[racer].render(time, delta, blips ? blips[racer] : null, audio);
    }

    /**
     * Start the race
     */
    start() {
        if (this.#started)
            return;

        this.#started = true;
    }
}