/**
 * The final result displayed in the leaderboard
 */
export class LeaderResult {
    static CLASS = "result";
    static CLASS_VISIBLE = "visible";

    /**
     * Get the string for a certain result
     * @param {number} place The result, starting at one
     */
    static getResultString(place) {
        switch (place) {
            case 1:
                return "1st";
            case 2:
                return "2nd";
            case 3:
                return "3rd";
            default:
                return place.toString() + "th";
        }
    }

    /**
     * Construct a leaderboard result
     */
    constructor() {
        this.element = document.createElement("div");
        //this.element.className = LeaderResult.CLASS;
    }

    /**
     * Activate the result
     * @param {number} place The place in the race, starting at one
     */
    activate(place) {
        // this.element.appendChild(document.createTextNode(LeaderResult.getResultString(place)));
        // this.element.classList.add(LeaderResult.CLASS_VISIBLE);
    }
}