import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import "../../racerhome.css";
import $ from "jquery";

export const ForgePage = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [sectionHTML, setSectionHTML] = useState('');

    var racer_items = [];
    function grabForgeInfo() {

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/races_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var forge_details = result.data;
                loadForgePage(forge_details);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    if(entrySet == false) {
        setEntry(true);
        grabForgeInfo();
    }

    function loadForgePage(forgearr) {
        var past_section_html = 'Coming Soon';
        setSectionHTML(past_section_html);
    }


    return(<>
                <div className="col s12 headline-text" style={{fontSize:"30px"}}>
                    THE FORGE
                </div>
                <div className="col s12" style={{marginBottom:"20px"}}>
                    <div className="divider-thin"></div>
                </div>
                <div className="col s12">
                    {sectionHTML}
                </div>
            </>);
};