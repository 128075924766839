import * as THREE from "three";
import {TrackPropInstance} from "./trackPropInstance.js";
import {TrackTracer} from "../../trackTracer.js";

/**
 * An instance of a track prop placed along a track
 */
export class TrackPropInstanceOnTrack extends TrackPropInstance {
    /**
     * Construct a prop on a track
     * @param {Track} track The track to place the prop on
     * @param {number} distance The distance on the track to place it on
     * @param {Vector3} [offset] The offset from the track center
     */
    constructor(track, distance, offset = new THREE.Vector3()) {
        super();

        this.track = track;
        this.distance = distance;
        this.offset = offset;
    }

    /**
     * Instantiate this instance
     * @param {Scene} parent The parent scene to add the prop to
     * @param {Scene} scene The scene containing the meshes
     * @param {Track} track The track
     * @param {number} trackWidth The track width
     */
    instantiate(parent, scene, track, trackWidth, sponsor) {
        const copy = scene.clone();

        super.instantiate(parent, copy, track, trackWidth, sponsor);

        this.objects.push(copy);

        copy.quaternion.setFromEuler(new THREE.Euler(
            0,
            -new TrackTracer(this.track).trace(copy.position, this.offset, this.distance),
            0,
            "YXZ"));

        parent.add(copy);
    }
}