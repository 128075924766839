import { useState, useEffect } from "react";
import { MintRacer } from "../../Organisms";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { Nft } from "../../Organisms";
import { Button } from "../../Atoms/Button";
import useSWR from "swr";
import { toast } from "react-toastify";

const fetcher = (url) => fetch(url).then((res) => res.json());

const Token = ({ address, index }) => {
  const [id, setId] = useState(null);
  const [isAlreadyOpen, setIsAlreadyOpen] = useState(false);
  const { mintPackContract, pilotContract, racerContract, boosterContract } =
    useSelector((state) => state.web3);
  const [tokenUri, setTokenUri] = useState("");
  const { data, error } = useSWR(tokenUri, fetcher);
  const { account } = useWeb3React();

  useEffect(() => {
    console.log("mintpack");
    console.log({ address, index });
    (async () => {
      try {
        const tempId = await mintPackContract.tokenOfOwnerByIndex(
          address,
          index
        );
        setId(tempId.toNumber());
        const uri = await mintPackContract.tokenUri(tempId.toNumber());
        console.log("mintpack uri: ", uri);
        setTokenUri(uri);

        const hasBeenOpened = await mintPackContract.checkPackOpenState(
          tempId.toNumber()
        );
        console.log({ hasBeenOpened });
        setIsAlreadyOpen(hasBeenOpened);
      } catch (err) {
        console.log(err);
      }
    })();
    mintPackContract.tokenOfOwnerByIndex(address, index).then((id) => {
      console.log("got mint pack with id: ", id.toNumber());
      setId(id.toNumber());
    });
  }, []);

  function mint() {
    if (!id) throw new Error("No Valid ID");

    pilotContract.subscribeToTransferEvent(account, (id) => {
      console.log("received a token: ", id);
      setIsAlreadyOpen(true);
      displayToastSuccess(`You received Pilot ID: ${id}`, id);
    });

    racerContract.subscribeToTransferEvent(account, (id) => {
      console.log("received a token: ", id);
      displayToastSuccess(`You Racer Pilot ID: ${id}`, id);
    });

    boosterContract.subscribeToTransferEvent(account, (id) => {
      console.log("received a token: ", id);
      displayToastSuccess(`You Racer Pilot ID: ${id}`, id);
    });

    mintPackContract
      .openPack(id)
      .then(({ hash }) => {
        console.log(hash);
        displayToastInfo("Transaction Submitted", hash);
      })
      .catch((err) => console.log(err));
  }

  function displayToastInfo(msg, identifier) {
    toast.info(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: identifier,
    });
  }

  function displayToastSuccess(msg, identifier) {
    toast.success(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: identifier,
    });
  }

  var viewMintPackButton = (
    <div id="mint-pack-action-button">
      <div className="white-text headline-text splash-button-bullet-top center-align">
        &#8226;
      </div>
      <div
        className="splash-main-button-wrapper center-align"
        title="Alpha Login"
      >
        <button
          className="btn-flat csr-button-white splash-main-button"
          style={{ fontSize: "18px" }}
          onClick={mint}
          title="View Mint Pack"
        >
          <span className="splash-main-button-bracket">[ </span>
          <span className="headline-text">OPEN MINT PACK</span>
          <span className="splash-main-button-bracket"> ]</span>
        </button>
      </div>
      <div className="white-text headline-text splash-button-bullet-bottom center-align">
        &#8226;
      </div>
    </div>
  );

  var openMintPackButton = (
    <div id="mint-pack-action-button">
      <div className="white-text headline-text splash-button-bullet-top center-align">
        &#8226;
      </div>
      <div
        className="splash-main-button-wrapper center-align"
        title="Alpha Login"
      >
        <button
          className="btn-flat csr-button-white splash-main-button"
          style={{ fontSize: "18px" }}
          onClick={() => (window.location.href = "/")}
          title="View Mint Pack"
        >
          <span className="splash-main-button-bracket">[ </span>
          <span className="headline-text">ALREADY MINTED</span>
          <span className="splash-main-button-bracket"> ]</span>
        </button>
      </div>
      <div className="white-text headline-text splash-button-bullet-bottom center-align">
        &#8226;
      </div>
    </div>
  );

  return (
    <div className="col s12 center-align" style={{ marginBottom: "50px" }}>
      <div
        className="row center-align"
        style={{
          textAlign: "center",
          height: "300px",
          width: "100%",
          overflow: "hidden",
          border: "1px solid #141414",
          marginBottom: "0px",
        }}
      >
        <img src={data?.image} width="100%" />
      </div>
      {isAlreadyOpen && openMintPackButton}
      {!isAlreadyOpen && viewMintPackButton}
    </div>
  );
};

export const MintPack = () => {
  const [tokenQty, setTokenQty] = useState(0);
  const { mintPackContract } = useSelector((state) => state.web3);

  const { account } = useWeb3React();

  useEffect(() => {
    console.log("loading mintPack");
    if (!mintPackContract) return;
    mintPackContract
      .balanceOf(account)
      .then((qty) => {
        console.log({ qty: qty.toNumber() });
        setTokenQty(qty.toNumber());
      })
      .catch((err) => {
        console.log(err);
      });
  }, [account, mintPackContract]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {[...Array(tokenQty)].map((e, i) => (
        <Token address={account} index={i} />
      ))}
    </div>
  );
};
