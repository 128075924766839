import {CameraController} from "../cameraController.js";
import * as THREE from "three";

/**
 * A transition between two camera controllers
 */
export class CameraControllerTransition extends CameraController {
    /**
     * Construct a transition camera controller
     * @param {Camera} camera The camera
     * @param {CameraController} a The first controller
     * @param {CameraController} b The second controller
     */
    constructor(camera, a, b) {
        super(camera);

        this.pa = new THREE.Vector3();
        this.pb = new THREE.Vector3();
        this.t = 0;
        this.a = a;
        this.b = b;
    }

    /**
     * Set the interpolation
     * @param {number} t The interpolation in the range [0, 1]
     */
    setInterpolation(t) {
        this.t = t;
    }

    /**
     * Set the target for this camera controller
     * @param {CameraTargetable} target The target to track
     * @param {CameraTargetable[]} targets All targets that can be tracked
     * @param {Track} track The track
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {boolean} [instant] True if the change should be instant
     */
    setTarget(target, targets, track, time, instant = true) {
        this.a.setTarget(target, targets, track, time, true);
        this.pa.copy(this.camera.position);
        this.b.setTarget(target, targets, track, time, true);
        this.pb.copy(this.camera.position);
        this.camera.position.lerpVectors(this.pa, this.pb, .5 - .5 * Math.cos(Math.PI * this.t));

        super.setTarget(target, targets, track, time);
    }

    /**
     * Update before rendering
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        this.a.render(time);
        this.b.render(time);
    }
}