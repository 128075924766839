import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "./mintlaunch.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import {WalletSync} from "../../Pages"
// import Terminal from 'terminal-in-react';
import { NoToneMapping } from "three";
let web3;

export const MintLaunchSplash = () => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, accountAddress } = useSelector((state) => state.account);

    if((racerID.length > 1)&&(accountAddress.length > 1)) {
        // dispatch(updateLocation('workshop'));
    }

    const [pageSet, setPage] = useState(false);

    async function syncWalletAddress() {
        let wallet_address = await syncAccountWallet();
        if(wallet_address) {
            if(wallet_address.length > 5) {
                console.log(wallet_address);
            }
        }
    }

    function flipBetweenRotatingVideos(phasenum,videonum) {
        var playingvid = 1;
        var nextvid = 2;
        if (videonum == 2) {
            var playingvid = 2;
            var nextvid = 1;
        }
        window.$("#mint-phase"+phasenum+"rotate-video"+playingvid).fadeOut();
        window.$("#mint-phase"+phasenum+"rotate-video"+nextvid).fadeIn();
        window.$("#phase"+phasenum+"-rotate-video"+nextvid).trigger('play');
        setTimeout(function() {
            flipBetweenRotatingVideos(phasenum,nextvid);
        },5500);
    }

    function startPhase1Video() {
        
        setTimeout(function() {
            window.$("#mint-initial-video").fadeOut();
            window.$("#mint-phase1show-video").fadeIn();
            window.$("#mint-terminal").fadeOut();
            window.$("#phase1-show-video").trigger('play');
        },2000);

        setTimeout(function() {
            window.$("#mint-phase1show-video").fadeOut(2000);
            window.$("#mint-phase1rotate-video1").fadeIn();
            window.$("#phase1-rotate-video1").trigger('play');
        },8100);

        setTimeout(function() {
            flipBetweenRotatingVideos(1,1);
        },13600);
    }

    function startPhase2Video() {
        
        setTimeout(function() {
            window.$("#mint-initial-video").fadeOut();
            window.$("#mint-phase2show-video").fadeIn();
            window.$("#mint-terminal").fadeOut();
            window.$("#phase2-show-video").trigger('play');
        },2000);

        setTimeout(function() {
            window.$("#mint-phase2show-video").fadeOut(2000);
            window.$("#mint-phase2rotate-video1").fadeIn();
            window.$("#phase2-rotate-video1").trigger('play');
        },8100);

        setTimeout(function() {
            flipBetweenRotatingVideos(2,1);
        },13600);
    }

    function startPhase3Video() {
        
        setTimeout(function() {
            window.$("#mint-initial-video").fadeOut();
            window.$("#mint-phase3show-video").fadeIn();
            window.$("#mint-terminal").fadeOut();
            window.$("#phase3-show-video").trigger('play');
        },2000);

        setTimeout(function() {
            window.$("#mint-phase3show-video").fadeOut(2000);
            window.$("#mint-phase3rotate-video1").fadeIn();
            window.$("#phase3-rotate-video1").trigger('play');
        },8100);

        setTimeout(function() {
            flipBetweenRotatingVideos(3,1);
        },13600);
    }

    function startPhase4Video() {
        
        setTimeout(function() {
            window.$("#mint-initial-video").fadeOut();
            window.$("#mint-phase4show-video").fadeIn();
            window.$("#mint-terminal").fadeOut();
            window.$("#phase4-show-video").trigger('play');
        },2000);

        setTimeout(function() {
            window.$("#mint-phase4show-video").fadeOut(2000);
            window.$("#mint-phase4rotate-video1").fadeIn();
            window.$("#phase4-rotate-video1").trigger('play');
        },8100);

        setTimeout(function() {
            flipBetweenRotatingVideos(4,1);
        },13600);
    }

    async function submitWhitelistSignup(e) {
        e.preventDefault();

        var firstname = document.getElementById("fname").value;
        var lastname = document.getElementById("lname").value;
        var email = document.getElementById("email").value;
        var walletID = document.getElementById("walletID").value;
        var invitecode = document.getElementById("invitecode").value;

        var addData = new Object();
        addData.fname= encodeURIComponent(firstname);
        addData.lname = encodeURIComponent(lastname);
        addData.email = encodeURIComponent(email);
        addData.walletID = encodeURIComponent(walletID);
        addData.invite_code = encodeURIComponent(invitecode);

        if(walletID.length < 10) {
            alert('Connect Your Metamask Wallet to Register');
        } else {
            document.getElementById("registersubmit").style.display = 'none';
            document.getElementById("registerloading").style.display = 'block';
            axiosInstance.post('whitelist/register/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var signup_result = result.data;
                        
                    if(signup_result.register_status.includes("whitelist")) {
                        setTimeout(function() {
                            document.getElementById("registerform").style.display = 'none';
                            document.getElementById("signupheader").style.display = 'none';
                            document.getElementById("confirmheader").style.display = 'block';
                            document.getElementById("confirmsignupwhitelist").style.display = 'block';
                        }, 3000);
                    } else {
                        setTimeout(function() {
                            document.getElementById("registerform").style.display = 'none';
                            document.getElementById("signupheader").style.display = 'none';
                            document.getElementById("confirmheader").style.display = 'block';
                            document.getElementById("confirmsignuplottery").style.display = 'block';
                        }, 3000);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    async function checkWhitelistSignup(e) {
        e.preventDefault();
        var walletID = document.getElementById("walletID2").value;
        var addData = new Object();
        addData.walletID= encodeURIComponent(walletID);

        document.getElementById("regchecksubmit").style.display = 'none';
        document.getElementById("regcheckloading").style.display = 'block';
        axiosInstance.post('whitelist/user_check/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var signup_result = result.data;
                if(signup_result.register_status == 'whitelist') {
                    setTimeout(function() {
                        document.getElementById("registercheckform").style.display = 'none';
                        document.getElementById("regcheckheader").style.display = 'none';
                        document.getElementById("whitelistheader").style.display = 'block';
                        document.getElementById("confirmwhitelist").style.display = 'block';
                    }, 3000);
                } else if(signup_result.register_status == 'lottery') {
                    setTimeout(function() {
                        document.getElementById("registercheckform").style.display = 'none';
                        document.getElementById("regcheckheader").style.display = 'none';
                        document.getElementById("mintpassheader").style.display = 'block';
                        document.getElementById("confirmlottery").style.display = 'block';
                    }, 3000);
                } else {
                    setTimeout(function() {
                        document.getElementById("registercheckform").style.display = 'none';
                        document.getElementById("regcheckheader").style.display = 'none';
                        document.getElementById("noconfirmheader").style.display = 'block';
                        document.getElementById("confirmnone").style.display = 'block';
                    }, 3000);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    document.addEventListener('DOMContentLoaded', function() {
        window.resetCenterHeight();
        window.startPageModals();
        window.resetBodyWidth();
        resizeBGVideo();
    });

    
    function resizeBGVideo() {
        var console_offset = window.innerWidth / 8.47;
        window.$("#mint-terminal").css({'top':console_offset+'px'});
        if((window.innerWidth / window.innerHeight) >= 1.77) {
            window.$("#splash-video-container").show();
            window.$("#splash-image-container").hide();
            window.$("#splash-image-container-horizontal").hide();
            var bgvideo = document.getElementById("main-splash-video");
            bgvideo.play();
        } else if(window.innerWidth > 450) {
            window.$("#splash-video-container").hide();
            window.$("#splash-image-container").show();
            window.$("#splash-image-container-horizontal").hide();
        } else {
            window.$("#splash-video-container").hide();
            window.$("#splash-image-container").hide();
            window.$("#splash-image-container-horizontal").show();
        }
    }

    window.addEventListener('resize', function() {
        window.resetCenterHeight();
        resizeBGVideo();
    });

    if(setPage === false) {
        pageSet(true);
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'100%',height:'auto'}}>
        <div className="row" id="main-landing-page">
            {/* <div id="splash-image-container" style={{display:'none',width:'100%',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-20',background:'url(https://media.exiledracers.com/global/splash/EXR_LandingPage_Clean.jpg)',backgroundSize:'cover',backgroundPosition:'center'}}>
            </div>
            <div id="splash-image-container-horizontal" style={{width:'100%',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-20',background:'url(https://media.exiledracers.com/global/splash/exr_prelaunch_splash_half_dark.png)',backgroundSize:'cover',backgroundPosition:'left'}}>
            </div> */}
            <div id="mint-initial-video" style={{width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-10'}}>
                <video id="main-splash-video" loop autoPlay muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/frozen_screen_access_pass.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase1show-video" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-9'}}>
                <video id="phase1-show-video" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/1st_phase_mint_key_pilot.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase1rotate-video1" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-8'}}>
                <video id="phase1-rotate-video1" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/1st_phase_mint_key_pilot_loop.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase1rotate-video2" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-8'}}>
                <video id="phase1-rotate-video2" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/1st_phase_mint_key_pilot_loop.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase2show-video" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-9'}}>
                <video id="phase2-show-video" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/2nd_phase_mint_key.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase2rotate-video1" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-8'}}>
                <video id="phase2-rotate-video1" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/2nd_phase_mint_key_pilot_loop.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase2rotate-video2" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-8'}}>
                <video id="phase2-rotate-video2" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/2nd_phase_mint_key_pilot_loop.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase3show-video" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-9'}}>
                <video id="phase3-show-video" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/3rd_phase_mint_key.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase3rotate-video1" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-8'}}>
                <video id="phase3-rotate-video1" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/3rd_phase_mint_key_pilot_loop.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase3rotate-video2" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-8'}}>
                <video id="phase3-rotate-video2" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/3rd_phase_mint_key_pilot_loop.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase4show-video" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-9'}}>
                <video id="phase4-show-video" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/3rd_phase_mint_key_pilot.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase4rotate-video1" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-8'}}>
                <video id="phase4-rotate-video1" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/3rd_phase_mint_key_pilot_loop.mp4" type="video/mp4" />
                </video>
            </div>
            <div id="mint-phase4rotate-video2" style={{display:'none',width:'100%',height:'auto',position:'absolute',top:'0px',left:'0px', zIndex:'-8'}}>
                <video id="phase4-rotate-video2" muted width="100%" height="auto" style={{lineHeight:'0px'}}>
                    <source src="https://media.exiledracers.com/global/mint_videos/3rd_phase_mint_key_pilot_loop.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="col s12 transparent" style={{position:'absolute',top:'0px',left:'0px', zIndex:'10'}}>
                <div id="mint-terminal" style={{top:'170px',left:'0px',width:'100%',position:'relative'}}>
                    <div style={{margin:"0 auto",width:"60%",height: "500px",minHeight:"200px",overflow:"hidden"}}>
                        {/* <Terminal
                            color='grey'
                            prompt='grey'
                            backgroundColor='black'
                            barColor='black'
                            hideTopBar='true' 
                            allowTabs='false' 
                            startState='maximised'
                            style={{ fontWeight:"bold",fontSize: "18px",height:"500px",maxWidth:"100%",overflow:"hidden"}}
                            commands={{
                                'connect': (args, print, runCommand) => {
                                    const text = args.slice(1).join(' ');
                                    print('Connecting to Metamask...');
                                    syncWalletAddress();
                                  },
                                'phase1': (args, print, runCommand) => {
                                    //runCommand('clear');
                                    print('Loading Phase 1 Mint Passes...');
                                    startPhase1Video();
                                },
                                'phase2': (args, print, runCommand) => {
                                    //runCommand('clear');
                                    print('Loading Phase 2 Mint Passes...');
                                    startPhase2Video();
                                },
                                'phase3': (args, print, runCommand) => {
                                    //runCommand('clear');
                                    print('Loading Phase 3 Mint Passes...');
                                    startPhase3Video();
                                },
                                'phase4': (args, print, runCommand) => {
                                    //runCommand('clear');
                                    print('Loading Phase 4 Mint Passes...');
                                    startPhase4Video();
                                },
                            }}
                            descriptions={{
                                'connect': 'Connect Your MetaMask Wallet',
                                'phase1': 'View Your Pilot Mint Pass',
                                'phase2': 'View Your Pilot NFT and Racecraft and Booster Mint Passes',
                                'phase3': 'View Your Pilot and Racecraft NFTs and Booster Mint Pass',
                                'phase4': 'View Your Pilot, Racecraft and Booster NFTs',
                                color: false, 
                                show: false, 
                                clear: false
                            }}
                            msg="Exiled Racers - Mint System 2000"
                        /> */}
                    </div>
                </div>
            </div>
        </div>
        <div id="alphabox" className="modal">
            <WalletSync />
        </div>
    </div>);
};
