/**
 * Race time info
 */
export class TimeInfo {
    static SEPARATOR = ":";

    /**
     * Construct the time info element
     * @param {HTMLElement} element The element to display time info in
     */
    constructor(element) {
        this.element = element;
        this.seconds = 0;

        this.update();
    }

    /**
     * Increment the elapsed time
     * @param {number} delta The number of seconds
     */
    increment(delta) {
        this.seconds += delta;

        this.update();
    }

    /**
     * Pad a number to two symbols
     * @param {number} number The number to pad
     * @returns {string} The padded number
     */
    pad(number) {
        let string = Math.round(number).toString();

        if (string.length === 1)
            return "0" + string;

        return string;
    }

    /**
     * Update the element
     */
    update() {
        const minutes = Math.floor(this.seconds / 60);
        const seconds = Math.floor(this.seconds - minutes * 60);
        const fraction = Math.floor((this.seconds - Math.floor(this.seconds)) * 100);

        this.element.innerText =
            this.pad(minutes) + TimeInfo.SEPARATOR +
            this.pad(seconds) + TimeInfo.SEPARATOR +
            this.pad(fraction);
    }
}