import * as THREE from "three";
import {GuiPanel} from "./guiPanel.js";

export class GuiPanelTrack extends GuiPanel {
    /**
     * Construct the track gui panel
     * @param {HTMLElement} popupElement The element to place popups on
     * @param {Race} race The race to make changes on
     */
    constructor(popupElement, race) {
        super(popupElement);

        this.addFieldOptions(
            "Track edge",
            ["Orbs", "Lines"],
            race.trackProps.hasOrbs ? "Orbs" : "Lines",
            value => {race.trackProps.hasOrbs = (value === "Orbs")},
            ["orbs", "lines"]);
        this.addFieldColor(
            "Orb color",
            race.trackProps.orbColor,
            value => race.trackProps.orbColor = value,
            "orbs");

        this.addFieldSlider(
            "Orb spacing",
            new THREE.Vector2(1, 30),
            race.trackProps.orbSpacing,
            value => race.trackProps.orbSpacing = value,
            "orbs");

        this.addFieldSlider(
            "Orb radius",
            new THREE.Vector2(.5, 16),
            race.trackProps.orbRadius,
            value => race.trackProps.orbRadius = value,
            "orbs");

        this.addFieldAsset(
            "Gate asset",
            race.trackProps.gateModel,
            model => race.trackProps.gateModel = model,
            "gate");

        this.addFieldAsset(
            "Finish gate asset",
            race.trackProps.gateModelFinish,
            model => race.trackProps.gateModelFinish = model,
            "gate");

        this.addFieldSlider(
            "Gate spacing",
            new THREE.Vector2(100, 1600),
            race.trackProps.gateSpacing,
            value => race.trackProps.gateSpacing = value);

        this.addFieldSlider(
            "Asteroids seed",
            new THREE.Vector2(0, 0xFFFFFFFF),
            race.trackProps.asteroidsSeed,
            value => race.trackProps.asteroidsSeed = value);

        this.addFieldSlider(
            "Asteroids field count",
            new THREE.Vector2(0, 8),
            race.trackProps.asteroidsFieldCount,
            value => race.trackProps.asteroidsFieldCount = value);

        this.addFieldSlider(
            "Asteroids field size",
            new THREE.Vector2(5, 60),
            race.trackProps.asteroidsFieldSize,
            value => race.trackProps.asteroidsFieldSize = value);

        this.addFieldSlider(
            "Asteroids spacing",
            new THREE.Vector2(5, 64),
            race.trackProps.asteroidsSpacing,
            value => race.trackProps.asteroidsSpacing = value);

        this.addFieldAsset(
            "Asteroids asset 1",
            race.trackProps.asteroidsAsset1,
            model => race.trackProps.asteroidsAsset1 = model,
            "asteroid");

        this.addFieldAsset(
            "Asteroids asset 2",
            race.trackProps.asteroidsAsset2,
            model => race.trackProps.asteroidsAsset2 = model,
            "asteroid");

        this.addFieldAsset(
            "Asteroids asset 3",
            race.trackProps.asteroidsAsset3,
            model => race.trackProps.asteroidsAsset3 = model,
            "asteroid");
    }
}