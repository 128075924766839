import {LeaderLine} from "./leaderLine.js";

/**
 * A live updating leaderboard
 */
export class Leaderboard {
    static UPDATE_RATE = .7;

    /**
     * Construct a leaderboard
     * @param {HTMLElement} element The element to create the leaderboard in
     * @param {Racers} racers The racers
     */
    constructor(element, racers, race_type) {
        this.lines = [];
        this.racers = racers;
        this.updateTime = 0;
        this.element = element;
        this.race_type = race_type;
        this.tracked = null;

        if(this.race_type == 'reverse') {
            document.getElementById("leaderboardreversed").style.display = 'block';
        }

        for (let racer = 0, racerCount = racers.racerCount; racer < racerCount; ++racer) {
            const line = new LeaderLine(
                this,
                racers,
                racers.racers[racer],
                racers.racers[racer].data.racerName,
                racers.racers[racer].data.color,
                racers.racers[racer].data.urlAvatar,
                racer,
                racers.racers[racer].data.currentRacer,
                racers.racers[racer].data.viewerPredicted,
                racers.racers[racer].data.racerSpecial);

            this.lines.push(line);

            element.appendChild(line.element);
        }
    }

    /**
     * Find the leaderboard line corresponding to a racer
     * @param {Racer} racer The racer to find the line for
     * @returns {LeaderLine} The leader line for this racer
     */
    findLine(racer) {
        return this.lines[this.racers.racers.indexOf(racer)];
    }

    /**
     * Indicate that a racer crossed the finish line
     * @param {Racer} racer The racer that finished
     */
    finish(racer) {
        this.findLine(racer).finish(racer.finished);
    }

    /**
     * Update the order according to racer positions
     * @param {boolean} ended True if the race has ended
     */
    updateOrder(ended) {
        let order = ended ? this.racers.orderFinal : this.racers.order(this.race_type);

        const boosting = this.racers.boostingRacers;
        for (let line = 0, lineCount = this.lines.length; line < lineCount; ++line) {
            this.lines[order[line]].setIndex(line);
            this.lines[order[line]].setRanking(line);
            this.lines[order[line]].setBoosting(boosting[line]);
        }
    }

    /**
     * Update the state
     * @param {number} delta The time delta
     * @param {boolean} ended True if the race has ended
     */
    update(delta, ended) {
        while ((this.updateTime -= delta) < 0) {
            this.updateTime += Leaderboard.UPDATE_RATE;

            this.updateOrder(ended);
        }
    }

    /**
     * Clear all spawned elements
     */
    clear() {
        for (const line of this.lines)
            this.element.removeChild(line.element);
    }
}