import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "./staging.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import {WalletSync} from "../../Pages"
import { NoToneMapping } from "three";
let web3;

export const Staging = () => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, accountAddress } = useSelector((state) => state.account);

    if((racerID.length > 1)&&(accountAddress.length > 1)) {
        // dispatch(updateLocation('workshop'));
    }

    const [pageSet, setPage] = useState(false);

    document.addEventListener('DOMContentLoaded', function() {
        window.resetCenterHeight();
        window.startPageModals();
        window.resetBodyWidth();
    });
    
    window.addEventListener('resize', function() {
        window.resetCenterHeight();
    });

    if(setPage === false) {
        pageSet(true);
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="show-on-medium-and-up hide-on-small-only splash-discord-top">
            <div style={{padding:'20px'}}>
                &nbsp;
            </div>
        </div>
        <div className="row" id="main-landing-page">
            <div className="col s12 transparent" style={{position:'relative'}}>
                <div id="splash-main">
                    <div className="show-on-medium-and-up hide-on-small-only splash-logo">
                        <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" height="200px" />
                    </div>
                    <div className="show-on-small hide-on-med-and-up splash-logo">
                        <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" width="75%" />
                    </div>
                    <div className="white-text subheadline-text splash-headline">
                        The world's first play-to-earn fantasy NFT<br/>
                        racing & prediction club built on Moonbeam.
                    </div>
                    <div className="white-text headline-text splash-button-bullet-top">
                        &#8226;
                    </div>
                    <div className="splash-main-button-wrapper" title="Alpha Login">
                        <button className="btn-flat csr-button-white modal-trigger splash-main-button" 
                                data-target="registerbox"
                                type="submit" title="Submit Registration">
                                <span className="splash-main-button-bracket">[ </span>
                                <span className="headline-text">ALPHA LOGIN</span>
                                <span className="splash-main-button-bracket"> ]</span>
                            </button>
                        
                            <script>window.startPageModals();</script>
                    </div>
                    <div className="white-text headline-text splash-button-bullet-bottom">
                        &#8226;
                    </div>
                </div>
            </div>
        </div>
        <div id="registerbox" className="modal">
            <WalletSync />
        </div>
    </div>);
};
