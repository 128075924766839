import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { clearUserSignOn, setUserSignOn, setLoginWallet } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals, getIndexerUserTokens} from "../../../utils";
import $ from "jquery";

let web3;    
let racer_wallet_address = '';
let total_pilots_sync = 0;
let total_racecrafts_sync = 0;
let total_boosters_sync = 0;
let racer_wallet_syncing = 0;
let wallet_duplicate = 0;
let racer_wallet_type = '';

export const SettingsModal = () => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    const [pageSet, setPage] = useState(false);
    const [racer_handle, setRacerHandle] = useState('');
    const [racer_email, setRacerEmail] = useState('');
    const [racer_discord, setRacerDiscord] = useState('');
    const [start_email, setStartRacerEmail] = useState('');
    const [start_handle, setStartRacerHandle] = useState('');
    const [start_discord, setStartRacerDiscord] = useState('');
    const [racer_metamask,setRacerMetamask] = useState('');
    const [racer_talisman,setRacerTalisman] = useState('');
    const [racer_wallet, setRacerWallet] = useState(accountAddress);


    function grabRacerInfo() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.userID = encodeURIComponent(userID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;
                setRacerHandle(result.data[0].racer_name);
                setRacerEmail(result.data[0].racer_email);
                setRacerDiscord(result.data[0].racer_discord);
                setStartRacerHandle(result.data[0].racer_name);
                setStartRacerEmail(result.data[0].racer_email);
                setStartRacerDiscord(result.data[0].racer_discord);
                window.M.updateTextFields();
                if((accountAddress !== null)&&(accountAddress != 'null')) {
                    if(accountAddress.length > 5) {
                        $("#game-asset-settings-sync").show();
                        //$("#racer-wallet-settings-sync").show();
                    }
                } else {
                    $("#racer-wallet-settings-sync").show();
                    setRacerWallet('');
                }
            }
        }).catch(error => {
            console.log(error);
            //dispatch(updateLocation("staging"));
        });
    }

    function saveSettings(e) {
        e.preventDefault();

        var raceremail = document.getElementById("settingsEmail").value;
        var racerhandle = document.getElementById("settingsRacerHandle").value;
        var racerdiscord = document.getElementById("settingsRacerDiscord").value;

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.userID = encodeURIComponent(userID);
        addData.walletID = encodeURIComponent(accountAddress);
        if(start_email !== raceremail) {
            addData.racerEmail = encodeURIComponent(raceremail);
        }
        if(start_handle !== racerhandle) {
            addData.racerHandle = encodeURIComponent(racerhandle);
        }
        if(start_discord !== racerdiscord) {
            addData.racerDiscord = encodeURIComponent(racerdiscord);
        }
        if((start_handle !== racerhandle)&&(racerhandle.length < 3)) {
            alert('Pick a Longer Racer Handle. Handles Must be atleast 3 Letters or Numbers Long.');
        } else {
            document.getElementById("settingssubmit").style.display = 'none';
            document.getElementById("settingsloading").style.display = 'block';
            axiosInstance.post('member/settings_update/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var save_result = result.data;
                    setTimeout(function(){
                        document.getElementById("settingssubmit").style.display = 'block';
                        document.getElementById("settingsloading").style.display = 'none';
                        if(save_result.save_status.includes("success")) {
                            window.M.toast({html: 'Settings Saved!', displayLength: 2000});
                        } else if(save_result.save_status.includes("error_email")) {
                            alert('This Email is Already Taken. Update and Try Again.');
                        } else if(save_result.save_status.includes("error_handle")) {
                            alert('This Racer Handle is Already Taken. Update and Try Again.');
                        } else if(save_result.save_status.includes("error_discord")) {
                            alert('This Discord Handle is Already Signed Up. Update and Try Again.');
                        } else {
                            alert('Whoops! Something Went Wrong. Update and Try Again');
                        }
                    },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function addWalletInfo() {
        racer_wallet_syncing = 0;
        wallet_duplicate = 0;
        if(racer_wallet_address.length < 5) {
            alert('Connect Your Wallet');
        } else {
            var raceremail = document.getElementById("settingsEmail").value;
            var signup_racer = 'spectator';
            if((total_pilots_sync > 0)&&(total_racecrafts_sync > 0)&&(total_boosters_sync > 0)) {
                signup_racer = 'racer';
            }

            var addData = new Object();
            addData.specEmail = encodeURIComponent(raceremail);
            addData.specID = encodeURIComponent(userID);
            addData.specWallet = encodeURIComponent(racer_wallet_address);
            addData.walletStatus = encodeURIComponent(signup_racer);

            if(raceremail.length < 3) {
                alert('Add a Valid Email Address');
            } else if (validateEmailText(raceremail) == 'fail') {
                alert('Add a Valid Email Address');
            } else if(userID== 0) {
                alert('Signup or Login To Update');
            } else {
                axiosInstance.post('member/waitroom_wallet_add/',addData).then(result => {
                    if(Object.keys(result.data).length > 0) {
                        var save_result = result.data;
                        if(save_result.length > 0) {
                            if(save_result[0]['status'] == 'duplicate') {
                                alert('This Wallet Is Already Registered. Connect a Different Wallet Later or Login to your Account.');
                                $("#game-asset-sync-text").html('...Refreshing');
                                $("#racer-wallet-settings-sync-text").html('...Refreshing');
                                wallet_duplicate = 1;
                                checkRacerAssetsIndexer(racer_wallet_address);
                            } else if(save_result[0]['status'] == 'pending') {
                                alert('This Email Account is not Registered. Go Back and Complete the Signup Process.');
                                $("#game-asset-sync-text").html('...Refreshing');
                                $("#racer-wallet-settings-sync-text").html('...Refreshing');
                                setTimeout(function(){
                                    window.location.reload();
                                },3000);
                            } else {
                                let userid = save_result[0]['user_id'];
                                let racerid = save_result[0]['racer_id'];
                                let spectatorid = save_result[0]['spectator_id'];
                                let logintime = save_result[0]['time'];
                                let logintoken = save_result[0]['token'];
                                dispatch(setLoginWallet(racer_wallet_address));
                                $("#game-asset-sync-text").html('Game Assets Synced...Refreshing');
                                $("#racer-wallet-settings-sync-text").html('Wallet Synced...Refreshing');
                                setTimeout(function(){
                                    window.location.reload();
                                },3000);
                            }
                        } else {
                            alert('Whoops! Something Went Wrong. Update and Try Again');
                            $("#game-asset-sync-text").html('...Refreshing');
                            $("#racer-wallet-settings-sync-text").html('...Refreshing');
                            setTimeout(function(){
                                window.location.reload();
                            },3000);
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }

    function startWalletSync(wallettype) {
        if(wallettype == 'talisman') {
            racer_wallet_syncing = 1;
            connectTalisman();
        } else {
            racer_wallet_syncing = 1;
            connectMetamask();
        }
    }

    // async function connectWeb3Contracts() {
    //     if (!active) {
    //       try {
    //         await activate(INJECTED_CONNECTOR, null, true);
    //       } catch (err) {
    //         console.log(err);
    //       }
    //     }
    // }

    async function connectMetamask() {
        
        const isMetaMaskInstalled = () => {
            const { ethereum } = window;
            return Boolean(ethereum && ethereum.isMetaMask);
        };

        if(!isMetaMaskInstalled) {
            alert('Install Metamask on this Browser');
        } else {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if(accounts[0]) {
                $("#metamask-result").show();
                $("#talisman-result").hide();
                $("#metamask-result-login").show();
                // $("#talisman-description").show();
                setRacerMetamask(accounts[0]);
                setRacerWallet(accounts[0]);
                racer_wallet_address = accounts[0];
                racer_wallet_type = 'metamask';
                //connectWeb3Contracts();
                checkRacerAssetsIndexer(racer_wallet_address);
            } else {
                alert('Unable To Get Your Accounts');
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if(accounts[0]) {
                    $("#metamask-result").show();
                    $("#talisman-result").hide();
                    $("#metamask-result-login").show();
                    // $("#talisman-description").show();
                    setRacerMetamask(accounts[0]);
                    setRacerWallet(accounts[0]);
                    racer_wallet_address = accounts[0];
                    racer_wallet_type = 'metamask';
                    //connectWeb3Contracts();
                    checkRacerAssetsIndexer(racer_wallet_address);
                }
            }
        }
    }

    async function connectTalisman() {
        const isTalismanInstalled = () => {
            return Boolean(window && window.talismanEth);
        };

        if((!isTalismanInstalled)||(window.talismanEth === undefined)) {
            alert('Install Talisman on this Browser');
        } else {
            const accounts = await window.talismanEth.request({ method: 'eth_requestAccounts'});
            if(accounts[0]) {
                $("#talisman-result").show();
                // $("#talisman-description").hide();
                $("#metamask-result").hide();
                setRacerTalisman(accounts[0]);
                setRacerWallet(accounts[0]);
                racer_wallet_address = accounts[0];
                racer_wallet_type = 'talisman';
                checkRacerAssetsIndexer(racer_wallet_address);
            } else {
                alert('Unable To Get Your Accounts');
            }
        }
    }

    function createTalismanWallet() {
        window.open('https://www.talisman.xyz/download');
    }

    function createMetamaskWallet() {
        window.open('https://metamask.io/download/');
    }

    async function checkRacerAssetsIndexer(walletaddress) {
        $("#game-asset-sync-text").html('...Syncing Game Assets');
        $("#racer-wallet-settings-sync-text").html('...Syncing Wallet');
        var racer_tokens = await getIndexerUserTokens(walletaddress);
        if(racer_tokens != null) {
            var pilot_token_list = [];
            var racecraft_token_list = [];
            var booster_token_list = [];
            if(racer_tokens['ownedTokens'] !== undefined) {
                for(var i = 0; i < racer_tokens['ownedTokens'].length;i++) {
                    var indexer_token_text_id = racer_tokens['ownedTokens'][i]['id'];
                    var indexer_token_text_split = indexer_token_text_id.split("-");
                    var indexer_token_type = indexer_token_text_split[0];
                    var indexer_token_id = indexer_token_text_split[1];
                    if(indexer_token_type == 'pilot') {
                        pilot_token_list.push(indexer_token_id);
                    } else if(indexer_token_type == 'racecraft') {
                        racecraft_token_list.push(indexer_token_id);
                    }
                }
            }
            if(racer_tokens['ownedBoosters'] !== undefined) {
                for(var i = 0; i < racer_tokens['ownedBoosters'].length;i++) {
                    var indexer_token_text_id = racer_tokens['ownedBoosters'][i]['id'];
                    var indexer_token_quantity = racer_tokens['ownedBoosters'][i]['qty'];
                    var indexer_token_text_split = indexer_token_text_id.split("-");
                    var indexer_token_type = indexer_token_text_split[0];
                    var indexer_token_walletsub = indexer_token_text_split[1];
                    var indexer_token_id = indexer_token_text_split[2];
                    for(var j = 0; j < indexer_token_quantity;j++) {
                        booster_token_list.push(indexer_token_id);
                    }
                }
            }
            updateWalletTokenOwner(walletaddress,pilot_token_list,'pilot');
            updateWalletTokenOwner(walletaddress,racecraft_token_list,'racecraft');
            updateWalletTokenOwner(walletaddress,booster_token_list,'booster');

            total_pilots_sync = pilot_token_list.length;
            total_racecrafts_sync = racecraft_token_list.length;
            total_boosters_sync = booster_token_list.length;
        }

        if((racerID == 0)&&(wallet_duplicate == 0)&&(total_pilots_sync > 0)&&(total_racecrafts_sync > 0)&&(total_boosters_sync > 0)) {
            racer_wallet_syncing = 1;
        }

        if(racer_wallet_syncing == 1) {
            setTimeout(function(){
                addWalletInfo();
            },3000);
        } else {
            setTimeout(function(){
                updateRacerNFTSync();
            },3000);
        }
    }

    function updateRacerNFTSync() {
        $("#game-asset-sync-text").html('Game Assets Synced...Refreshing');
        if(wallet_duplicate == 1) {
            $("#racer-wallet-settings-sync-text").html('...Refreshing');
        } else {
            $("#racer-wallet-settings-sync-text").html('Wallet Synced...Refreshing');
        }
        
        setTimeout(function(){
            window.location.reload();
        },3000);
    }

    function updateWalletTokenOwner(walletaddress,tokenIds,tokenType) {
        var tokenstring = '';
        for(var i = 0; i < tokenIds.length;i++) {
          if(i > 0) {
            tokenstring = tokenstring+',';
          }
          tokenstring = tokenstring+tokenIds[i];
        }
        var addData = new Object;
        addData.walletID = encodeURIComponent(walletaddress);
        addData.tokenList = tokenstring;
        addData.nType = tokenType;
    
        if(walletaddress !== null) {
          if(walletaddress.length > 3) {
              axiosInstance
                .post("https://data.exiledracers.com/api/v1/mint/token_owner_check/", addData)
                .then((result) => {
                  if (Object.keys(result.data).length > 0) {
                    var view_result = result.data;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
          }
        }
    }

    function validateEmailText(emailtext) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (emailtext.match(validRegex)) {
          return 'pass';
        } else {
          return 'fail';
        }
    }

    if(pageSet == false) {
        setPage(true);
        grabRacerInfo();
    }

    function onEmailTextChange(e){
        setRacerEmail(e.target.value);
    }

    function onHandleTextChange(e){
        setRacerHandle(e.target.value);
    }

    function onDiscordTextChange(e){
        setRacerDiscord(e.target.value);
    }

    function goToTerms() {
        window.location.href= '/#/terms';
        dispatch(updateLocation("terms"));
    }

    function logoutGame() {
        dispatch(clearUserSignOn());
        dispatch(updateLocation("splash"));
        window.location.href= '/';
    }

    return(<div id="settingsbox" className="col s12 modal page-modal page-modal-smedium page-modal-right">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>MY ACCOUNT</div>
                        
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                        <div className="row" style={{display:'flex',flexDirection:'row',padding:'10px',marginBottom:'10px'}}>
                            <div style={{order:'1',flexBasis:'130px',marginTop:'3px',textAlign:'right'}}>
                                Wallet Address:&nbsp;&nbsp;
                            </div>
                            <div style={{order:'2',flex:'10'}} title="Log Back In With A Different Wallet to Change">
                                <input value={racer_wallet} 
                                        id="walletAddress" 
                                        type="text" 
                                        className="browser-default light-grey-bubble-input"
                                        style={{width:'98%',cursor:'not-allowed'}}
                                        title="Log Back In With A Different Wallet to Change"
                                        disabled />
                            </div>
                        </div>
                        <div id="game-asset-settings-sync" className="row" style={{display:'none',paddingRight:'20px',marginTop:'-20px',marginBottom:'0px'}}>
                            <div style={{textAlign:'right'}}>
                                <a id="game-asset-sync-text" style={{fontSize:'12px'}} className="white-green-link" onClick={()=>checkRacerAssetsIndexer(accountAddress)}>Sync Game Assets</a>
                            </div>
                        </div>
                        <div id="racer-wallet-settings-sync" className="row" style={{display:'none',paddingRight:'20px',marginTop:'-20px',marginBottom:'0px'}}>
                            <div style={{textAlign:'right'}}>
                                <a id="racer-wallet-settings-sync-text" style={{fontSize:'12px'}} className="white-green-link" onClick={()=>startWalletSync()}>Add Wallet</a>
                            </div>
                        </div>
                        <div className="row" style={{display:'flex',flexDirection:'row',padding:'10px',marginBottom:'10px'}}>
                            <div style={{order:'1',flexBasis:'130px',marginTop:'3px',textAlign:'right'}}>
                                Email Address:&nbsp;&nbsp;
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <input value={racer_email} 
                                        onChange={onEmailTextChange} 
                                        id="settingsEmail" 
                                        type="email" 
                                        className="browser-default light-grey-bubble-input"
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="row" style={{display:'flex',flexDirection:'row',padding:'10px',marginBottom:'10px'}}>
                            <div style={{order:'1',flexBasis:'130px',marginTop:'3px',textAlign:'right'}}>
                                Racer Handle:&nbsp;&nbsp;
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <input value={racer_handle} 
                                    onChange={onHandleTextChange} 
                                    id="settingsRacerHandle" 
                                    type="text" 
                                    className="browser-default light-grey-bubble-input" 
                                    style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="row" style={{display:'flex',flexDirection:'row',padding:'10px',marginBottom:'30px'}}>
                            <div style={{order:'1',flexBasis:'130px',marginTop:'3px',textAlign:'right'}}>
                                Discord Handle:&nbsp;&nbsp;
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <input value={racer_discord} 
                                    onChange={onDiscordTextChange} 
                                    id="settingsRacerDiscord" 
                                    type="text" 
                                    className="browser-default light-grey-bubble-input" 
                                    style={{width:'98%'}} />
                            </div>
                        </div>
                    </div>
                    <div className="col s12" style={{marginTop:'-20px'}}>
                        <div id="settingssubmit" className="right" title="Save Account Settings">
                            <span type="submit" className="round-border-button" style={{fontSize:'16px'}} title="SAVE SETTINGS" onClick={(e)=>saveSettings(e)}>
                                SAVE PROFILE
                            </span>
                        </div>
                        <div id="settingsloading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                            <div className="spinner-layer spinner-red-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px",marginTop:'20px'}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12" style={{marginTop:'10px'}}>
                        <div id="logoutbutton" className="left" style={{marginTop:'5px'}} title="Logout From Game">
                            <span className="round-border-button" style={{fontSize:'14px'}} title="Logout From Game" onClick={()=>logoutGame()}>
                                LOGOUT
                            </span>
                        </div>
                        <div className="right-align" style={{marginTop:'5px'}} title="Terms of Service">
                            <a className="white-red-link" style={{fontSize:'14px',paddingTop:'6px'}} target="_blank" href="/#/terms">
                               Terms
                            </a>&nbsp;&nbsp;|
                            &copy; 2023, Exiled Studios Inc
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};