import { useEffect, useState, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance} from "../../../utils";
import "./music.css";
import { NoToneMapping } from "three";

import loadscript from 'load-script'
import $ from 'jquery';
let web3;
let currentvolume = true;

export const MusicPlayer = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { location, admin_racers } = useSelector((state) => state.app);

    const [headerSet, setHeader] = useState(false);
    if(headerSet == false) {
        setHeader(true);
    }
    
     // used to communicate between SC widget and React hook example taken from https://taylor.callsen.me/integrating-react-functional-components-with-a-soundcloud-widget/
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSoundOn, setIsSoundOn] = useState(true);
    const [playlistIndex, setPlaylistIndex] = useState(0);
    let currentTrackDuration = 0;
    let currentSongTitle = '';
    let currentPlayStatus = 0;


     // populated once SoundCloud Widget API is loaded and initialized
    const [player, setPlayer] = useState(false)
    let volume = props.volume
    volume = 0; // TODO: Off by default

    // ref for iframe element - https://reactjs.org/docs/refs-and-the-dom.html
    const iframeRef = createRef()

    let eqgif1 = 'https://media3.giphy.com/media/yFKokXsr5Bc6xVqpTt/giphy.gif?cid=ecf05e47l1fmf32frt0ft0o34cbq394p1odpzn8cylgouqp2&rid=giphy.gif&ct=s';
    let eqgif2 = 'https://media3.giphy.com/media/h4OQjJLelp7IxQjN2Y/giphy.gif?cid=ecf05e47ir8pj48l9mu510kwg22zejcrpzxwdmtltcacyt48&rid=giphy.gif&ct=s';
    
    // initialization - load soundcloud widget API and set SC event listeners
    useEffect(() => {
        adjustPlayerSize();
        loadscript('https://w.soundcloud.com/player/api.js', () => {
              // initialize player and store reference in state
            const player = window.SC.Widget("sc-iframe");
            setPlayer(player);

            const { READY, PLAY, PLAY_PROGRESS, PAUSE, FINISH, ERROR } = window.SC.Widget.Events

            //can't access react state or props from within SC callback functions

            player.bind( PLAY, () => {
                setIsPlaying(true);
                if(currentvolume === true) {
                    player.setVolume(volume);
                } else {
                    player.setVolume(0);
                }

                //if song has changed, update state to the next SC index
                player.getCurrentSoundIndex((playerPlaylistIndex) => {
                    setPlaylistIndex(playerPlaylistIndex)
                });

                //if song has changed, update state to the next SC index
                player.getCurrentSound((playerSong) => {
                    currentSongTitle = playerSong.title;
                    rotateCurrentMarquee(1);
                });

                player.getDuration((durationMs) => {
                    if(durationMs != currentTrackDuration) {
                        currentTrackDuration = durationMs;
                    }
                });
                currentPlayStatus = 1;
                $("#music-player-gif").fadeIn();
            });

            player.bind(PLAY_PROGRESS, (sobject) => {
                var timeremaining_ms = currentTrackDuration - sobject.currentPosition;
                var timeremaining_sec = Math.floor(timeremaining_ms / 1000); 
                var timeremaining_min = Math.floor(timeremaining_sec / 60);
                var timeremaining_digits = timeremaining_sec - (timeremaining_min*60);
                var finaltimetxt = '';
                if(timeremaining_min < 10) {
                    finaltimetxt = finaltimetxt + '0';
                }
                finaltimetxt = finaltimetxt + timeremaining_min+":";
                if(timeremaining_digits < 10) {
                    finaltimetxt = finaltimetxt + '0';
                }
                finaltimetxt = finaltimetxt + timeremaining_digits;

                $("#music-player-time").html(finaltimetxt);
            });

            player.bind( PAUSE, () => {
                $("#music-player-gif").fadeOut();
                $("#music-player-marquee").html("Paused: EXR Sound System");
                currentPlayStatus = 0;
            });

            player.bind( READY, () => {
                if((location != 'race_track')&&(location != 'prelaunch')) {
                    $("#globalmusicplayer").fadeIn();
                    setTimeout(function(){
                        adjustPlayerSize();
                    },1000);
                }
            });
        });
    }, []);

    //update SC player based on play/pause state
    useEffect(() => {
        if (!player) return

        player.isPaused( (playerIsPaused) => {
            if (isPlaying && playerIsPaused) {
                player.play()
            } else if (!isPlaying && !playerIsPaused) {
                player.pause()
            }
        })
    }, [isPlaying])

    // adjust seleted song in SC player playlist if playlistIndex state has changed
    useEffect(() => {
        if (!player) return // makes sure the player is available

        player.getCurrentSoundIndex( (playerPlaylistIndex) => {
            if (playerPlaylistIndex !== playlistIndex) player.skip(playlistIndex)
        })
    }, [playlistIndex])

    // React section button click event handlers (play/next/previous)
  //  - adjust React component state based on click events

  const togglePlayback = () => {
      setIsPlaying(!isPlaying)
    //   console.log(!isPlaying)
    //   console.log(playlistIndex)
  }

  const musicPlayerSoundToggle = () => {
    var tmpSoundState = !isSoundOn;
    setIsSoundOn(tmpSoundState);
    currentvolume = tmpSoundState;
    if(player) {
        if(tmpSoundState) {
            player.setVolume(volume);
        } else {
            player.setVolume(0);
        }
    } 
  }

  function adjustPlayerSize() {
    var itemcnt = 0;
    $(".side-nav-button-section").each(function() {
        if(itemcnt == 0) {
            var newplayerwidth = $(this).width();
            var newplayeroffset = $(this).offset();
            $("#music-player-container").css({'width':newplayerwidth+'px'});
            $("#globalmusicplayer").css({'left':(newplayeroffset.left)+'px'});
        }
        itemcnt = itemcnt + 1;
    });
  }

  function rotateCurrentMarquee(textnum) {
    if(currentPlayStatus == 1) {
        var songtitlesplit = currentSongTitle.split("-");
        $("#music-player-marquee").html('Playing: '+songtitlesplit[0]+' - '+songtitlesplit[1]);
        if(textnum == 2) {
            $("#music-player-marquee").html('Now Playing: by '+songtitlesplit[0]).fadeIn();
        } else if(textnum == 1) {
            $("#music-player-marquee").html('Now Playing: '+songtitlesplit[1]).fadeIn();
        } else {
            $("#music-player-marquee").html('Now Playing: ').fadeIn();
        }
        var nextnum = textnum+1;
        if(nextnum == 3) {
            nextnum = 1;
        }
        setTimeout(function(){
            rotateCurrentMarquee(nextnum);
        },5000);
    } else {
        $("#music-player-gif").fadeOut();
        $("#music-player-marquee").html("Paused: EXR Sound System");
    }
  }

  window.addEventListener("resize",
    function(){ 
        adjustPlayerSize();
    }, false);


  //handles skipping tracks in playlist
  
  const changePlaylistIndex = (skipForward = true) => {
      //list of songs from widget

      player.getSounds( (playerSongList) => {
          let nextIndex = (skipForward) ? playlistIndex + 1 : playlistIndex - 1

          //ensure index is not set to less than 0 or greater than total tracks in playlist

          if (nextIndex < 0) nextIndex = playerSongList.length
          else if (nextIndex >= playerSongList.length) nextIndex = 0
        //   if (nextIndex < 0) nextIndex = 0
        //   else if (nextIndex >= playerSongList.length) nextIndex = playerSongList.length - 1

          setPlaylistIndex(nextIndex)

        //   console.log(playlistIndex)
      })
  }

    // function pushMusicPlayerButton(buttonname) {
    //     window.handleMusicPlayerButton(buttonname);
    // }


    // const soundcloud_playlist_id = 'https://soundcloud.com/kiernan-laveaux/sets/mixes';

    const soundcloud_playlist_id = 'https://soundcloud.com/obriylabel/sets/dubplanet-x-xnode-obr010';
    const soundcloud_playlist_url = 'https://w.soundcloud.com/player/?url='+soundcloud_playlist_id+'&amp';

    
        
    return(<div className="row white-text" id="globalmusicplayer">
        <div className="col s12 hide-on-med-and-down show-on-large" id="music-player-container">
            <div className="row" id="music-player-window">
                <div id="music-player-marquee" className="code-text">EXR Sound System</div>
                <div id="music-player-gif"><img height="25px" src={eqgif1} /></div>
            </div>
            <div className="row center-align" style={{marginTop:'10px',marginBottom:'10px'}}>
                <span className="left white-red-link" id="playmusicbutton" style={{marginLeft:'15px',height:'20px',lineHeight:'1',cursor:'pointer'}} onClick={togglePlayback}>
                    {(isPlaying) ? <i style={{fontSize:'24px'}} className="material-icons">pause_circle_outline</i> : <i style={{fontSize:'24px'}} className="material-icons">play_circle_outline</i> }
                </span>
                <span className="left white-red-link" style={{marginLeft:'5px',height:'20px',lineHeight:'1',cursor:'pointer',display:'none'}} onClick={ () => changePlaylistIndex(false)}>
                    <i style={{fontSize:'20px',verticalAlign:'top',marginTop:'3px'}} className="material-icons">skip_previous</i>
                </span>
                <span className="left white-red-link" style={{marginLeft:'5px',height:'20px',lineHeight:'1',cursor:'pointer'}} onClick={ () => changePlaylistIndex(true)}>
                    <i style={{fontSize:'20px',verticalAlign:'top',marginTop:'3px'}} className="material-icons">skip_next</i>
                </span>
                <span className="right" id="music-player-time" style={{marginRight:'15px',marginTop:'3px'}}>
                    00:00
                </span>
            </div>
        </div>
        <div className="left white-red-link" id="soundtogglebutton" style={{display:'none'}} onClick={musicPlayerSoundToggle}>
            <span className="left" style={{marginLeft:'5px'}}>{ (isSoundOn) ? 'On' : 'Off' }</span>
            <span className="left">{(musicPlayerSoundToggle) ? <i style={{fontSize:'18px'}} className="material-icons">sound_on</i> : <i style={{fontSize:'18px'}} className="material-icons">sound_off</i> }</span>
        </div>
        <div className="col s12" style={{display:'none'}}>
            <iframe ref={iframeRef} id="sc-iframe" width="100%" height="166" scrolling="no" allow="autoplay" frameBorder="no" src={soundcloud_playlist_url}></iframe>
        </div>
    </div>);
};