import { Contract, Signer, utils, providers, ethers } from "ethers";

import inventoryABI from "./abis/EXRInventory.json";
import { boosterContractAddress } from "../constants";
import { Interface } from "@ethersproject/abi";

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

class BoosterContract {
  contract;
  provider;
  contractAddress;
  initialized;
  interface;
  name;
  symbol;

  constructor(chainId, signer) {
    //console.log({ chainId });
    this.contractAddress = boosterContractAddress[chainId];
    if(this.contractAddress) {
      this.contract = new Contract(this.contractAddress, inventoryABI, signer);
      this.interface = new ethers.utils.Interface(inventoryABI);
      this.initialized = true;
    } else {
      return;
    }
  }

  balanceOf(address, tokenId) {
    return this.contract.balanceOf(address, tokenId);
  }

  balanceOfBatch(address, tokenIdStart, tokenIdEnd) {
    let address_array = [];
    let token_array = [];
    for(var i = tokenIdStart; i < tokenIdEnd;i++) {
      address_array.push(address);
      token_array.push(i);
    }
    return this.contract.balanceOfBatch(address_array, token_array);
  }

  tokenURI(tokenId) {
    //console.log("getting token URI for inventory Item");
    return this.contract.uri(tokenId);
  }

  subscribeToTransferEvent(recipient, callback) {
    console.log("subscribing to Transfer for acc: ", recipient);
    const filter = this.contract.filters.TransferBatch(
      null,
      null,
      recipient,
      null,
      null
    );

    this.contract.on(filter, (operator, from, to, ids, values) => {
      console.log("received event");
      console.log({ operator, from, to, ids, values });
      if (from === ethers.constants.AddressZero && to === recipient) {
        console.log("received");

        let tokenIds = ids.map((id) => id.toNumber());
        callback(tokenIds);
      }
    });
  }

  decodeError(errData) {
    console.log(`decoding error data ${errData}`);
    if (errData.hasOwnProperty("data")) {
      try {
        const errorFragment = this.interface.getError(`0x${errData.data}`);
        console.log({ errorFragment });
        return errorFragment?.name || "Unknown Error";
      } catch (e) {
        return "Unknown Error";
      }
    } else {
      return errData?.message ? errData.message : "Unknown Error";
    }
  }

  setNameAndSymbol() {
    this.contract
      .name()
      .then((name) => (this.name = name))
      .catch((err) => console.log(err));
    this.contract
      .symbol()
      .then((symbol) => (this.symbol = symbol))
      .catch((err) => console.log(err));
  }
}

export default BoosterContract;
