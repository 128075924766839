import { React, useEffect, useState} from "react";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { updateLocation, signupLoading, updatePageHash } from "./redux/App/actions";
import { HashRouter, Routes, Route } from "react-router-dom";

import { 
  GlobalHeaderGrey, 
  GlobalHeaderAdmin, 
  GlobalHeaderPublic,
  GlobalHeaderBar,
  GlobalHeaderLearn,
  MusicPlayer,
  LoginLoading, 
  LoginCheck, 
  GlobalInfoboxes } from "./components/Organisms";
import {
  SingleSignonFlow,
  Splash,
  SplashEvent,
} from "./components/Pages";
import $ from "jquery";

let backbutton = 'N';
let pagehash = '';
let hashcheckfunc = 0;
let finallocation = '';
let pageReloading = 0;

function AppEvent() {
  const dispatch = useDispatch();
  const { location, page_hash, signup_loading, game_loading } = useSelector((state) => state.app);
  const [finalPage, setFinalPage] = useState(location);
  var hashcheck = window.location.href.split("/");

  useEffect(() => {  
    window.addEventListener("hashchange",
    function(){ 
      reloadPage();
    }, false);
    window.onpopstate = e => {
      reloadPage();
    }
  }, []);

  /* Check For Changes in Signup Loading */
  useEffect(() => {
    if (window !== undefined) {
      // if(signup_loading === true) {
      //   window.$("#signuploadingscreen").fadeIn();
      // } else {
      //   window.$("#signuploadingscreen").hide();
      // }
    }
  }, [signup_loading]);

  function reloadPage() {
    pageReloading = 1;
    var page_name = "";
    pagehash = window.location.hash;
    if (pagehash.length > 1) {
      page_name = pagehash.replace("#", "");
    } else {
      pagehash = '';
    }
    if(page_name != '') {
      if(window.location.href.indexOf("reload") >= 0) {
        window.location.href= window.location.href.replace("reload","");
      }
      window.pageRefresh = 0;
    }
    setFinalPage(page_name);
    
  }
  
  return (
    <div>
      <LoginLoading />
      <LoginCheck />
      <HashRouter basename="/">
        <Routes>
          {/* Tournament Splash Pages 
          <Route path="splash" element={<SplashEvent />} />
          <Route path="signin&p=login" element={<SingleSignonFlow />} />
          <Route path="signin/:ptype" element={<SingleSignonFlow />} />
          <Route path="register" element={<SingleSignonFlow />} />
          <Route path="register/:ptype" element={<SingleSignonFlow />} />
          <Route path="invite" element={<SingleSignonFlow />} />
          <Route path="invite/:invitername" element={<SingleSignonFlow />} />
          <Route path="/" element={<SplashEvent />} />
          <Route path="*" element={<SplashEvent />} />
          */}

          {/* Non-Standard  Event Registration Pages */}
          <Route path="/results" element={<SplashEvent currentPage="results" />} />
          {/* <Route path="/register" element={<SplashEvent currentPage="register" />} /> */}
          <Route path="/" element={<SplashEvent />} />
          <Route path="*" element={<SplashEvent />} />
          
        </Routes>
      </HashRouter>
      <GlobalInfoboxes />
    </div>
  );
}

export default AppEvent;