import {CameraController} from "../cameraController.js";
import {Interpolation} from "../../../../../exr-webgl-hub/math/interpolation.js";

export class CameraControllerLeaderboard extends CameraController {
    static PITCH_CENTER = Math.PI * .125;
    static PITCH_AMPLITUDE = .05;
    static PITCH_SPEED = .002;
    static DISTANCE = 1.5;

    /**
     * Construct a leaderboard camera controller
     * @param {Camera} camera The camera to control
     * @param {Track} track The track to focus on
     * @param {Environment} environment The environment
     */
    constructor(camera, track, environment) {
        super(camera);

        this.target = track.center;
        this.focus = environment.planetPosition;
        this.pitch = this.pitchPrevious = CameraControllerLeaderboard.PITCH_CENTER;
        this.pitchPhase = 0;
        this.distance = this.target.length() * CameraControllerLeaderboard.DISTANCE;
    }

    /**
     * Get the angle of the leaderboard camera
     * @returns {number} The angle in radians
     */
    get angle() {
        return Math.atan2(-this.focus.z, -this.focus.x);
    }

    /**
     * Update
     * @param {number} delta The time delta
     */
    update(delta) {
        if ((this.pitchPhase += CameraControllerLeaderboard.PITCH_SPEED) > Math.PI * 2)
            this.pitchPhase -= Math.PI * 2;

        this.pitchPrevious = this.pitch;
        this.pitch = CameraControllerLeaderboard.PITCH_CENTER + CameraControllerLeaderboard.PITCH_AMPLITUDE * Math.sin(this.pitchPhase);
    }

    /**
     * Update before rendering
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        const pitch = Interpolation.lerp(this.pitchPrevious, this.pitch, time);

        this.camera.position.copy(this.target);
        this.camera.position.x += Math.cos(this.angle) * this.distance * Math.cos(pitch);
        this.camera.position.y = Math.sin(pitch) * this.distance;
        this.camera.position.z += Math.sin(this.angle) * this.distance * Math.cos(pitch);
        this.camera.lookAt(this.target.x, this.target.y, this.target.z);
    }
}