import {CameraController} from "../cameraController.js";
import * as THREE from "three";

export class CameraControllerStage extends CameraController {
    /**
     * Construct the stage camera
     * @param {Camera} camera The camera to control
     * @param {Stage} stage The finish stage
     */
    constructor(camera, stage) {
        super(camera);

        this.stage = stage;
    }

    /**
     * Set the target for this camera controller
     * @param {CameraTargetable} target The target to track
     * @param {CameraTargetable[]} targets All targets that can be tracked
     * @param {Track} track The track
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {boolean} [instant] True if the change should be instant
     */
    setTarget(target, targets, track, time, instant = false) {
        const camera = this.stage.getCamera();
        const q = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI * .5);
        const q2 = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI);

        q.multiply(q2);

        camera.getWorldPosition(this.camera.position);
        camera.getWorldQuaternion(this.camera.quaternion)

        this.camera.quaternion.multiply(q);
    }
}