import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import path from "path";
import "./index.css";
import App from "./App";
import AppHash from "./AppHash";
import AppEvent from "./AppEvent";
import store from "./redux/store";
import { ethers } from "ethers";
import { Web3ReactProvider } from "@web3-react/core";

import { config as dotEnvConfig } from "dotenv";

dotEnvConfig({
  path: path.join(__dirname, "..", ".env"),
});


function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

// ReactDOM.render(
//   /*<React.StrictMode>*/
//     <Web3ReactProvider {...{ getLibrary }}>
//       <Provider store={store}>
//         <AppStaging />
//       </Provider>
//     </Web3ReactProvider>
//   /*</React.StrictMode>*/,
//   document.getElementById("root")
// );

ReactDOM.render(
    <Web3ReactProvider {...{ getLibrary }}>
      <Provider store={store}>
        <AppHash />
      </Provider>
    </Web3ReactProvider>,
  document.getElementById("root")
);

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<Web3ReactProvider {...{ getLibrary }}>
//   <Provider store={store}>
//     <AppHash />
//   </Provider>
// </Web3ReactProvider>);