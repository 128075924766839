import {RacersEventClustering} from "./racersEventClustering.js";
import {RacersEventTakeovers} from "./racersEventTakeovers.js";
import {RacersEventBoost} from "./racersEventBoost.js";

export class RacersEvents {
    /**
     * Construct a class containing events derived from the racers data
     * @param {RacersData} data The racers data
     */
    constructor(data) {
        this.takeovers = [];
        this.clusterings = [];
        this.boosts = [];

        if (data && data.hasOwnProperty("racers"))
            this.analyze(data);

        //console.log(this);
    }

    /**
     * Analyze race data
     * @param {RacersData} data The racers data
     */
    analyze(data) {
        let frames = Number.MAX_SAFE_INTEGER;

        for (const racer of data.racers)
            frames = Math.min(frames, racer.movement.anchors.length);

        const racers = data.racers.slice();
        const ranks = new Array(data.racers.length).fill(-1);
        const newRanks = new Array(data.racers.length);

        const indexOfName = name => {
            for (let racer = 0, racerCount = data.racers.length; racer < racerCount; ++racer)
                if (racers[racer].racerName === name)
                    return racer;

            return -1;
        };

        for (let frame = 0; frame < frames; ++frame) {
            const boosting = [];
            let density = 0;
            let movementAverage = 0;

            racers.sort((a, b) => a.movement.anchors[frame].movement - b.movement.anchors[frame].movement);

            for (let racer = 0, racerCount = data.racers.length; racer < racerCount; ++racer) {
                for (let other = racer + 1; other < racerCount; ++other)
                    density += Math.abs(
                        data.racers[racer].movement.anchors[frame].movement -
                        data.racers[other].movement.anchors[frame].movement);

                movementAverage += data.racers[racer].movement.anchors[frame].movement / racerCount;
                newRanks[racer] = indexOfName(data.racers[racer].racerName);

                if (data.racers[racer].movement.anchors[frame].boosting)
                    boosting.push(racer);
            }

            this.clusterings.push(new RacersEventClustering(
                frame,
                density,
                Math.abs(movementAverage - data.racers[0].movement.anchors[frame].movement)));

            const rankChanges = [];
            let rankChangeDelta = 0;

            for (let racer = 0, racerCount = data.racers.length; racer < racerCount; ++racer) {
                const delta = ranks[racer] === -1 ? 0 : newRanks[racer] - ranks[racer];

                rankChangeDelta += Math.abs(delta);

                rankChanges[racer] = delta;
                ranks[racer] = newRanks[racer];
            }

            this.takeovers.push(new RacersEventTakeovers(
                frame,
                rankChanges));

            this.boosts.push(new RacersEventBoost(
                frame,
                boosting));
        }

        this.clusterings.sort((a, b) => a.density - b.density);
        this.takeovers.sort((a, b) => b.changeDelta - a.changeDelta);
        this.boosts.sort((a, b) => b.boosting.length - a.boosting.length);
    }
}