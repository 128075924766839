var baseTrack = {
    "track":{
        // "orbs":{
        //     "spacing":3.322265625,
        //     "color":"ff0000",
        //     "radius":1.9
        // },
        // "gates":{
        //     "spacing":384.1796875,
        //     "model":"https://media.exiledracers.com/assets/game/builder/Gate_04A_1666021878868.glb",
        //     "modelFinish":"https://media.exiledracers.com/assets/game/builder/Gate_02A_1666021128749.glb"
        // },
        "gates":{
            "spacing":384.1796875,
            "model":"https://media.exiledracers.com/assets/game/builder/Gate_04A_1666021878868.glb",
            "modelFinish":"https://media.exiledracers.com/assets/game/builder/hs_portal_mercs_1691681743377.glb"
        },
        "asteroids":{
            "seed":905969663.789063,
            "fieldCount":1.921875,
            "fieldSize":36.904296875,
            "spacing":54.666015625,
            "asset1":"https://media.exiledracers.com/assets/game/builder/Asteroid_Grey_1_1661801662068.glb",
            "asset2":"https://media.exiledracers.com/assets/game/builder/Asteroid_Grey_2_1661801678166.glb",
            "asset3":"https://media.exiledracers.com/assets/game/builder/Asteroid_Grey_3_1661801691182.glb"
        },
        "props":[],
        "race_sponsor":''
    },
    "environment":{
        "planet": {
            "lighting":1,
            "model":null,
            "texture":"https://media.exiledracers.com/assets/game/builder/Mars_1664903604022.jpg",
            "seed":43,
            "distance":500,
            "radius":1000,
            "angle":0.4,
            "pitch":-0.712890625,
            "atmosphere":0.04,
            "rotation":0.00033203125,
            "tilt":0.5,
            "color1":"62709D",
            "color2":"8994C6",
            "color3":"9AA8DD",
            "color4":"8D9FDB",
            "color5":"7F8EC1",
            "color6":"69789C"
        },
        "cubemap":{
            "left":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Left_1661801976319.png",
            "right":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Right_1661801989958.png",
            "top":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Up_1661801897758.png",
            "bottom":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Down_1661801916291.png",
            "front":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Front_1661801941725.png",
            "back":"https://media.exiledracers.com/assets/game/builder/Rich_Blue_Stars_-_Back_1661801958941.png"
        },
        "equirectangularHDR": "https://media.exiledracers.com/assets/game/builder/skybox_hdr_mercs_1691681616102.exr"
        //"equirectangularHDR": "https://media.exiledracers.com/assets/game/builder/skybox_hdr_augs_1691681583706.exr"
        //"equirectangularHDR": "https://media.exiledracers.com/assets/game/builder/skybox_hdr_augs_pink_1705521701495.exr"
    }
};

// Setup Augment Track
var augmentTrackTemp = JSON.parse(JSON.stringify(baseTrack));
augmentTrackTemp['environment']['planet']['texture'] = 'https://media.exiledracers.com/assets/game/builder/Neptune_1664903813437.jpg';
augmentTrackTemp['environment']['cubemap'] = {
    "left":"https://media.exiledracers.com/assets/game/builder/Skybox-01_right_1663938491737.png",
    "right":"https://media.exiledracers.com/assets/game/builder/Skybox-01_left_1663938367928.png",
    "top":"https://media.exiledracers.com/assets/game/builder/Skybox-01_top_1663938382962.png",
    "bottom":"https://media.exiledracers.com/assets/game/builder/Skybox-01_bottom_1663938308098.png",
    "front":"https://media.exiledracers.com/assets/game/builder/Skybox-01_front_1663938331235.png",
    "back":"https://media.exiledracers.com/assets/game/builder/Skybox-01_back_1663938290909.png"
};
augmentTrackTemp['environment']['equirectangularHDR'] = 'https://media.exiledracers.com/assets/game/builder/skybox_hdr_augs_1691681583706.exr';
//augmentTrackTemp['environment']['equirectangularHDR'] = 'https://media.exiledracers.com/assets/game/builder/skybox_hdr_augs_pink_1705521701495.exr';
augmentTrackTemp['track']['gates']['modelFinish'] = 'https://media.exiledracers.com/assets/game/builder/hs_portal_augs_1691681720352.glb';


// Setup Serf Track
var serfTrackTemp = JSON.parse(JSON.stringify(baseTrack));
serfTrackTemp['environment']['planet']['texture'] = 'https://media.exiledracers.com/assets/game/builder/Ceres_1664903758311.jpg';
//serfTrackTemp['environment']['planet']['radius'] = 0;
serfTrackTemp['environment']['equirectangularHDR'] = 'https://media.exiledracers.com/assets/game/builder/skybox_hdr_serfs_1691681632754.exr';
// serfTrackTemp['environment']['equirectangularHDR'] = 'https://media.exiledracers.com/assets/game/builder/skybox_hdr_mercs_pink_1705521296416.exr';
//serfTrackTemp['environment']['equirectangularHDR'] = 'https://media.exiledracers.com/assets/game/builder/skybox_hdr_augs_pink_1705521701495.exr';
serfTrackTemp['track']['gates']['modelFinish'] = 'https://media.exiledracers.com/assets/game/builder/hs_portal_serfs_1691681793187.glb';

export const baseTrackEnvironment = JSON.parse(JSON.stringify(baseTrack));
export const augmentTrackEnvironment = augmentTrackTemp;
export const serfTrackEnvironment = serfTrackTemp;
export const mercenaryTrackEnvironment = JSON.parse(JSON.stringify(baseTrack));