import React, { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import { RaceChat } from "../RaceChat";
import "../../../RacesHome/races_home.css";
import $ from "jquery";

import {genetic_tooltip, genetic_max_tooltip, genetic_track_tooltip, genetic_tooltip_wtrack, genetic_max_tooltip_wtrack} from "../../race_landing_tooltips";
import {TrackLoader} from "../../../../../webgl/race/utils/trackLoader.js"
import { Track } from "../../../../../webgl/race/track/track.js";
import { TrackMap } from "../../../../../webgl/race/track/map/trackMap.js";
import { Minimap } from "../../../../../webgl/race/minimap/minimap.js";
import {laps_tooltip, turns_tooltip, straights_tooltip, shifts_tooltip} from "../../race_landing_tooltips";

import {TournamentTimeline, GeneralTimeline} from "./Timelines";

let trackloading = 0;
let initialtrackloaded = 0;
let unlock_track_id = 0;

let total_page_results = 25;
let current_pagenum = 0;
let sectionHTMLArray = [];
let current_racer_global = 0;
let racer_sim_watched_global = 0;
let current_track_id = 0;
let current_race_id = 0;
let current_tourney_num = 0;
let selectedRaceID = '';
let selectedCollectionID = '';
let current_timer_collection = '';
let race_details_global = [];
let racer_details_global = [];

let track_data_display = [];
let chat_active = 0;

export const RaceViewer = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [sectionHTML, setSectionHTML] = useState('');
    const [showMoreHTML, setShowMoreHTML] = useState('');
    
    const [current_video_html, setVideoHTML] = useState('');
    const [race_details, setRaceDetails] = useState([]);
    const [racer_details, setRacerDetails] = useState([]);
    const [racer_status, setRacerStatus] = useState({});
    const [racer_creator_id, setRacerCreator] = useState(0);
    const [racer_invite_status, setRacerInviteStatus] = useState('N');
    const [racer_entry_status, setRacerEntryStatus] = useState('N');
    const [race_simulated, setRaceSimStatus] = useState('N');
    const [racer_sim_watched, setRacerSimWatched] = useState('N');
    const [race_landing_html, setRaceLandingHTML] = useState('');
    const [race_name, setRaceName] = useState('');
    const [race_subtitle_details, setRaceSubtitleDetails] = useState('');
    const [race_sponsor_html, setRaceSponsorHTML] = useState('');
    const [predictRacersLineup,setPredictRacersLineup] = useState('');
    const [predictRacersLineupFooter, setPredictRacersLineupFooter] = useState('');
    const [racer_handles, setRacerHandles] = useState([]);
    const [race_game_type, setRaceType] = useState('');
    const [race_entry_close_text, setRaceEntryCloseText] = useState('');
    const [race_predictions_close_text, setRacePredictionCloseText] = useState('');
    const [race_exr, setRaceEXR] = useState('');
    const [race_xp, setRaceXP] = useState('');
    const [race_entry_text, setRaceEntryText] = useState('');
    const [racer_count,setRacerCount] = useState('');
    const [raceTime, setRaceTime] = useState('');
    const [race_prize_type,setRacePrizeType] = useState('');
    const [race_prize_dist,setRacePrizeDist] = useState('');
    const [final_tier_tooltip,setFinalTierTooltop] = useState('');
    const [currentTimelineView, setCurrentTimelineView] = useState('');

    const track_viewer_container = React.createRef();
    const track_data_container = React.createRef();



    if (props.raceID != selectedRaceID) {
        selectedRaceID = props.raceID;
        racer_details_global = [];
        race_details_global = [];
        setEntry(false);
    }

    if(props.collectionID != selectedCollectionID) {
        current_tourney_num = current_tourney_num+1;
        selectedCollectionID = props.collectionID;
        updateTimelineView();
        setEntry(false);
    }

    useEffect(() => {
        if (window) {
            selectedCollectionID = props.collectionID;
            updateTimelineView();
            setEntry(false);
        }
      }, []);
    
    var racer_items = [];
    function updateTimelineView() {
        // if(selectedCollectionID == 6) {
        //     setCurrentTimelineView(<TournamentTimeline key={Date.now()} ctime={Date.now()} collectionID={selectedCollectionID} />);
        // } else {
        //     setCurrentTimelineView(<GeneralTimeline />);
        // }
        setCurrentTimelineView(<GeneralTimeline />);
    }
    
    
    
    function grabRaceDetails() {
        current_track_id = 0;
        current_race_id = 0;
        track_data_display = [];
        racer_details_global = [];
        race_details_global = [];
        if((selectedRaceID == 'waitroom')||(selectedRaceID == '')) {
            loadWaitroom();
        } else {
            var addData = new Object();
            var tmp_uniqud_id = selectedRaceID;
            addData.raceUniqueID = encodeURIComponent(tmp_uniqud_id);
            adjustRightBoxHeights();
            axiosInstance.post('race/details_list/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var race_details = result.data;          
                    current_race_id = race_details[0]['race_id'];
                    current_track_id = race_details[0]['race_track_id'];
                    setRaceDetails(race_details[0]);
                    showTrackSummary();
                    setTimeout(function(){
                        grabRaceParticipants();
                    },1000);
                    race_details_global = race_details;
                    chat_active = 1;
                    chat_active = 0;
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function grabRaceParticipants() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(selectedRaceID);
        addData.predictorID = userID;
        current_racer_global = 0;
        racer_sim_watched_global = 0;
        adjustRightBoxHeights();
        axiosInstance.post('race/prediction_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_list = result.data;
                var current_racer_status = {};
                for(var i = 0;i < racer_list.length;i++) {
                    if(racer_list[i].racer_id == racerID) {
                        current_racer_status = racer_list[i];
                        current_racer_global = 1;
                        setRacerEntryStatus('Y');
                        if(racer_list[i].racer_sim_watched == 1) {
                            setRacerSimWatched('Y');
                            racer_sim_watched_global = 1;
                        }
                    }
                }
                setRacerStatus(current_racer_status);
            }
            setRacerDetails(result.data);  
            racer_details_global = result.data;
            setTimeout(function(){
                loadRaceInformation();
            },500); 
            //console.log(racer_details_global);         
        }).catch(error => {
            console.log(error);
        });
    }

    function loadWaitroom() {
        setTimeout(function() {
            adjustRightBoxHeights();
        },50);

        let videolink = 'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExZmVhNGFlZTY5YWMxM2UwNDJlZTBmNDA1ZDdjMDhhNTIyNTJjM2E3MSZjdD1n/WUyQbeKHhpaHrrKJu6/giphy.mp4';
        if(props.collectionID == 6) {
            videolink = 'https://static.vecteezy.com/system/resources/previews/020/819/566/mp4/slow-motion-of-white-smoke-fog-mist-vapor-on-a-black-background-free-video.mp4';
        }

        let new_video_html = <video id="waitingroom-bg" src={videolink} 
        style={{border:'0px',overflow:'hidden',borderRadius:'10px'}} width="100%" autoPlay loop muted />;
        setVideoHTML(new_video_html);
        setTimeout(function(){
            toggleViewerSections('predict');
        },100);

        $("#race-countdown-registered").hide();

        var current_collection_id = props.collectionID;
        var collection_list = props.collectionList;

        var waiting_race_name = 'EXR RACE PRESHOW';
        var waiting_race_details = 'STANDARD // LONG DISTANCE';
        var waiting_race_title = 'RACES NOW LIVE TO WATCH';
        var waiting_race_date_timer = '';

        // a.date_race_day_starts,
        // a.date_race_day_ends,
        // MONTHNAME(a.date_race_day_starts) as start_monthname,
        // MONTH(a.date_race_day_starts) as start_month,
        // DAY(a.date_race_day_starts) as start_day,
        // YEAR(a.date_race_day_starts) as start_year,
        // HOUR(a.date_race_day_starts) as start_hour,
        // MINUTE(a.date_race_day_starts) as start_min,

        // e.date_race_entry_close, f.date_race_prediction_close, g.date_race_simulation_start,
        // CASE WHEN e.date_race_entry_close is not null then 'upcoming_entry'
        //     WHEN f.date_race_prediction_close is not null then 'upcoming_prediction'
        //     WHEN g.date_race_simulation_start is not null then 'upcoming_simulation'
        //     ELSE 'upcoming_none' END AS race_timer_status,
        
        // MIN(b.race_group_id) as primary_race_group_id, 
        // COUNT(distinct b.race_group_id) as total_race_groups,
        // COUNT(distinct d.race_id) as total_races,
        // SUM(CASE WHEN d.date_race_ended is null and d.race_id is not null then 1 else 0 end) as upcoming_races,
        // SUM(CASE WHEN d.date_race_ended is not null and d.race_id is not null and d.date_race_ended >= DATE_SUB(NOW(), INTERVAL 1 HOUR) then 1 else 0 end) as recent_races, 
        // SUM(CASE WHEN d.date_race_ended is not null and d.race_id is not null and d.date_race_ended < DATE_SUB(NOW(), INTERVAL 1 HOUR) then 1 else 0 end) as past_races,
        // SUM(CASE WHEN d.date_race_ended is not null and d.race_id is not null and d.date_race_ended >= DATE_SUB(NOW(), INTERVAL 10 MINUTE) then 1 else 0 end) as recent_races_show
        
        for (var i = 0; i < collection_list.length;i++) {
            if(current_collection_id == collection_list[i]['race_group_collection_id']) {
                // collection_list[i]['races_status'] = 'inprogress';
                // collection_list[i]['race_timer_status'] = 'upcoming_entry';
                // collection_list[i]['date_race_entry_close'] = "2023-06-15T16:00:00";
                // collection_list[i]['recent_races_show'] = 1;
                waiting_race_date_timer = collection_list[i]['start_monthname']+' '+collection_list[i]['start_day']+' // '+collection_list[i]['race_collection_name'].toUpperCase();
                $("#race-countdown-date").html(waiting_race_date_timer);
                
                if(collection_list[i]['races_status'] == 'upcoming') {
                    waiting_race_title = 'RACES STARTING';
                    let race_time_number = collection_list[i]['start_monthname']+' '+collection_list[i]['start_day']+', '+collection_list[i]['start_year']+' '+collection_list[i]['start_hour']+':'+collection_list[i]['start_min'];
                    let race_time_unix = collection_list[i]['date_race_day_starts_unix'];
                    current_timer_collection = selectedCollectionID;
                    loadCountdownTimer('wait',race_time_unix,'RACES STARTING');
                    if(racerID > 0) {
                        $("#race-countdown-registered").html('YOU\'RE REGISTERED TO RACE<br>');
                        $("#race-countdown-registered").show();
                    } else if(userID > 0) {
                        $("#race-countdown-registered").html('YOU\'RE REGISTERED TO PREDICT<br>');
                        $("#race-countdown-registered").show();
                    }
                    
                    
                } else if(collection_list[i]['races_status'] == 'inprogress') {
                    $("#race-countdown-date").hide();
                    if(racerID > 0) {
                        $("#race-countdown-registered").html('YOU\'RE ELIGIBLE TO RACE<br>');
                        $("#race-countdown-registered").show();
                    } else if(userID > 0) {
                        $("#race-countdown-registered").html('YOU\'RE ELIGIBLE TO PREDICT<br>');
                        $("#race-countdown-registered").show();
                    }

                    if(collection_list[i]['recent_races_show'] > 0) {
                        
                        waiting_race_title = 'NEW RACES READY TO WATCH';
                        
                        if(collection_list[i]['race_timer_status'] == 'upcoming_entry') {
                            let race_time_number = collection_list[i]['date_race_entry_close'].replace("T"," ");
                            let race_time_unix = collection_list[i]['date_race_entry_close_unix'];
                            current_timer_collection = selectedCollectionID;
                            loadCountdownTimer('wait',race_time_unix,waiting_race_title);

                            if(racerID > 0) {
                                $("#race-countdown-date").html('NEXT RACE ENTRIES CLOSE');
                            } else if(userID > 0) {
                                $("#race-countdown-date").html('NEXT PREDICTIONS OPEN');
                            }
                            $("#race-countdown-date").show();
                        }

                    } else if(collection_list[i]['race_timer_status'] == 'upcoming_entry') {
                        let race_time_number = collection_list[i]['date_race_entry_close'].replace("T"," ");
                        let race_time_unix = collection_list[i]['date_race_entry_close_unix'];
                        if(racerID > 0) {
                            waiting_race_title = 'NEXT RACE ENTRIES CLOSE';
                            current_timer_collection = selectedCollectionID;
                            loadCountdownTimer('wait',race_time_unix,waiting_race_title);
                        } else if(userID > 0) {
                            waiting_race_title = 'NEXT PREDICTIONS OPEN';
                            current_timer_collection = selectedCollectionID;
                            loadCountdownTimer('wait',race_time_unix,waiting_race_title);
                        }
                    } else if(collection_list[i]['race_timer_status'] == 'upcoming_prediction') {
                        waiting_race_title = 'NEXT PREDICTIONS CLOSE';
                        let race_time_number = collection_list[i]['date_race_prediction_close'].replace("T"," ");
                        let race_time_unix = collection_list[i]['date_race_prediction_close_unix'];
                        current_timer_collection = selectedCollectionID;
                        loadCountdownTimer('wait',race_time_unix,waiting_race_title);

                    } else if(collection_list[i]['race_timer_status'] == 'upcoming_simulation') {
                        waiting_race_title = 'NEXT RACES STARTING SOON';
                        $("#race-countdown-timer").html('');
                        setTimeout(function(){
                            window.location.reload();
                        },10000);

                    } else {
                        waiting_race_title = 'RACES NOW LIVE TO WATCH';
                        $("#race-countdown-timer").html('');
                    }
                    
                    
                } else {
                    $("#race-countdown-timer").html('');
                }
            }
        }

        setRaceName(waiting_race_name);
        setRaceSubtitleDetails(waiting_race_details);
        $("#race-countdown-title").html(waiting_race_title);
        
        
        //loadSponsorGrid();
        loadPredictionBoxBottom();
        loadWaitingroomPredictBanner();
    }

    function loadRaceInformation() {
        setTimeout(function() {
            adjustRightBoxHeights();
        },50);
        var new_race_name = race_details_global[0]['race_name'].toUpperCase()+" #"+race_details_global[0]['race_id'];
        var new_racers_count = racer_details_global.length;
        var new_race_laps = race_details_global[0]['race_laps'];
        var new_race_type = race_details_global[0]['race_scoring'];
        
        var new_race_subtitle_details = new_racers_count+' RACERS // ';
        new_race_subtitle_details = new_race_subtitle_details+new_race_laps+' LAPS // ';
        new_race_subtitle_details = new_race_subtitle_details+new_race_type.toUpperCase()+' RACE';

        setRaceName(new_race_name);
        setRaceSubtitleDetails(new_race_subtitle_details);
        loadRacerLineup();

        //loadSponsorGrid();
        loadPredictionBoxBottom();

        // if((race_details_global[0]['race_simulated'] == 1)&&(race_details_global[0]['results_viewable'] == 0)) {
        if((race_details_global[0]['race_simulated'] == 1)&&(race_details_global[0]['admin_gated'] == 0)) {
            $("#race-countdown-timer-shell").hide();
            $("#race-viewer-block").hide();
            let new_video_html = <iframe src={"https://watch.exiledracers.com/#/"+props.raceID} style={{border:'0px',overflow:'hidden',borderRadius:'10px'}} width="100%" height="100%" scrolling="no" />;
            setVideoHTML(new_video_html);
        }

        $("html, body").animate({ scrollTop: 0 }, "slow");
    }

    function loadPredictionBoxBottom() {
        var tmp_footer = <div className="row exr-new-dark-grey" style={{margin:'0px',padding:'0px',width:'100%',marginBottom:'0px',marginTop:'10px',borderRadius:'10px'}}>
                                <div className="col s12 headline-text center-align white-yellow-link" 
                                    style={{margin:'0px',padding:'0px',fontSize:'12px',lineHeight:'1',marginBottom:'8px',marginTop:'10px'}}>
                                    LEARN MORE
                                </div>
                            </div>;
        tmp_footer = <></>;
        if((selectedRaceID != 'waitroom')&&(selectedRaceID != '')) {
            tmp_footer = <div className="row exr-new-dark-grey" style={{margin:'0px',padding:'0px',width:'100%',marginBottom:'0px',marginTop:'10px',borderRadius:'10px'}}>
                                <div className="col s12 headline-text center-align white-yellow-link" 
                                    style={{margin:'0px',padding:'0px',fontSize:'12px',lineHeight:'1',marginBottom:'8px',marginTop:'10px'}}>
                                    <a href={"https://www.exiledracers.com/#/watch/"+race_details_global[0]['race_unique_id']} className="white-yellow-link headline-text" target="_blank">
                                        WATCH LINK
                                    </a>
                                </div>
                            </div>
        }
        setPredictRacersLineupFooter(tmp_footer);
    }

    // function loadRacerLineup() {
    //     var racer_handle_arr = [];
    //     var racerrows = [];
    //     var tmp_racer_rows = [];
    //     for (var i = 0; i < racer_details_global.length; i++) {
    //         racer_handle_arr.push(racer_details_global[i]['racer_name']);
    //         var total_xp_points = 100;
    //         var userid = racer_details_global[i]['user_id'];
    //         var raceid = racer_details_global[i]['race_id'];
    //         var pracerid = racer_details_global[i]['racer_id'];
    //         var racername = racer_details_global[i]['racer_name'].toUpperCase();
    //         var pilot_image = racer_details_global[i]['pilot_image_location'];
    //         var racecraft_image = racer_details_global[i]['ship_image_location'];
    //         var user_predicted = racer_details_global[i]['racer_predicted'];
    //         var win_pct = Math.floor(racer_details_global[i]['racer_start_win_pct']*100);
    //         var xpchance = Math.round(total_xp_points*(1-(racer_details_global[i]['racer_start_win_pct'])));
    //         var totalpreds = racer_details_global[i]['total_predictors'];
    //         var pilot_image_bg = "url('"+pilot_image+"')";
    //         var margintop = "0px 0px 8px 0px";

    //         var predictbutton = <></>;
    //         if(user_predicted == 1) {
    //             predictbutton = <div className="round-border-button-green viewer-racer-pred-button" 
    //                     style={{fontSize:'0.6vw',minHeight:'1.15vw'}}
    //                     data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
    //                     data-racername={racername} data-pilotimage={pilot_image} data-racecraftimage={racecraft_image}
    //                     data-predxp={xpchance} 
    //                     data-winchance={win_pct}
    //                     title="Predicted"
    //                     >PREDICTED</div>;
    //         } else {
    //             // predictbutton = <div className="round-border-button viewer-racer-pred-button" 
    //             //         style={{fontSize:'0.6vw',lineHeight:'1',padding:'5px',paddingBottom:'1px'}}
    //             //         data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
    //             //         data-racername={racername} data-pilotimage={pilot_image}
    //             //         data-predxp={xpchance} 
    //             //         data-winchance={win_pct}
    //             //         onClick={(e)=>openPredictRacerBox(e)} 
    //             //         >PREDICT</div>;
    //         }
            
    //         var tmp_row = <div key={i+10000} className="row exr-new-dark-grey" style={{margin:margintop,width:'100%',borderRadius:'10px'}}>
    //                             <div className="col s12" style={{display: 'flex',flexDirection: 'row',width:'100%',padding:'2.5px',position:'relative'}}>
    //                                 <div style={{width:'17%',lineHeight:'.5'}}>
    //                                     <img src={racecraft_image} width="100%" style={{margin:'0px',borderRadius:'5px'}} />
    //                                 </div>
    //                                 <div style={{flex:'1',padding:'10px',paddingBottom:'0px'}}>
    //                                     <div className="row headline-text viewer-racer-name" style={{margin:'0px',fontSize:'0.85vw',lineHeight:'1.25',overflow:'hidden',marginBottom:'5px'}}>
    //                                         {racername.toUpperCase()}
    //                                     </div>
    //                                     <div className="row" style={{margin:'0px',fontSize:'12px',lineHeight:'1'}}>
                                            
    //                                         <div className="left" style={{fontSize:'16px',marginTop:'2px',marginRight:'5px',lineHeight:'1'}}>
    //                                             {win_pct}%
    //                                         </div>
    //                                         <div className="left" style={{fontSize:'10px',marginTop:'6px',lineHeight:'1'}}>
    //                                             WIN
    //                                         </div>
    //                                         <div className="right" style={{fontSize:'12px',marginTop:'6px',lineHeight:'1'}}>
    //                                             {xpchance} XP // {totalpreds} PICKED
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 <div className="exr-new-dark-grey" style={{position:'absolute',top:'10px',right:'10px'}}>
    //                                     {predictbutton}
    //                                 </div>
    //                             </div>
    //                         </div>;
            
    //         if((userid == userID)||(user_predicted == 1)) {
    //             racerrows.push(tmp_row);
    //         } else {
    //             tmp_racer_rows.push(tmp_row);
    //         }
    //     }

    //     for(var z = 0; z < tmp_racer_rows.length;z++) {
    //         racerrows.push(tmp_racer_rows[z]);
    //     }

    //     setRacerHandles(racer_handle_arr);

    //     var finalSection = <div id="chat-predict-display" style={{marginTop:'10px',borderRadius:'10px',width:'100%'}}>{racerrows}</div>
    //     setPredictRacersLineup(finalSection);
    //     toggleViewerSections('predict');

    //     if(racer_details_global.length > 6) {
    //         $("#chat-predict-display").css({'overflow-y':'scroll','overflow-x':'hidden'});
    //     } else {
    //         $("#chat-predict-display").css({'overflow-y':'hidden','overflow-x':'hidden'});
    //     }
    // }

    // function loadPredictionBoxBottom() {
    //     if((selectedRaceID != 'waitroom')&&(selectedRaceID != '')) {
    //         tmp_footer = <div className="row exr-new-dark-grey" style={{margin:'0px',padding:'0px',width:'100%',marginBottom:'0px',marginTop:'10px',borderRadius:'10px'}}>
    //                             <div className="col s12 headline-text center-align white-yellow-link" 
    //                                 style={{margin:'0px',padding:'0px',fontSize:'0.75vw',lineHeight:'1',marginBottom:'8px',marginTop:'10px'}}>
    //                                 VIEW DETAILED LINEUP
    //                             </div>
    //                         </div>
    //     }
    //     setPredictRacersLineupFooter(tmp_footer);
    // }

    function loadRacerLineup() {
        var racer_handle_arr = [];
        var racerrows = [];
        var tmp_racer_rows = [];
        var predict_last_place = race_details_global[0]['predict_last_place'];

        var predicted_racer_id = 0;
        var predicted_racer_id_last = 0;
        for (var i = 0; i < racer_details_global.length; i++) {
            var pracerid_check = racer_details_global[i]['racer_id'];
            var user_predicted_check = racer_details_global[i]['racer_predicted'];
            var user_predicted_place = racer_details_global[i]['racer_predicted_place'];
            if(user_predicted_check == 1) {
                if(user_predicted_place == 1) {
                    predicted_racer_id = pracerid_check;
                } else if(user_predicted_place == 5) {
                    predicted_racer_id_last = pracerid_check;
                }
            }
        }

        if(predict_last_place == 1) {
            var additionalpredinstructions = <div key={"racewaypredictrowinstructions"} className="row exr-new-dark-grey" style={{margin:'0px',marginBottom:'10px',width:'100%',borderRadius:'10px',padding:'7px 5px',overflow:'hidden'}}>
            <div className="left" style={{flex:'1',marginLeft:'10px',position:'relative',marginTop:'2px'}}>
                PREDICT <span className="exr-yellow-text">1ST PLACE</span> AND <span className="exr-yellow-text">LAST PLACE</span>
            </div>
            </div>;
            tmp_racer_rows.push(additionalpredinstructions);
        }


        for (var i = 0; i < racer_details_global.length; i++) {
            racer_handle_arr.push(racer_details_global[i]['racer_name']);
            var total_xp_points = 100;
            var userid = racer_details_global[i]['user_id'];
            var raceid = racer_details_global[i]['race_id'];
            var pracerid = racer_details_global[i]['racer_id'];
            var racername = racer_details_global[i]['racer_name'].toUpperCase();
            var pilot_image = racer_details_global[i]['pilot_image_location'];
            var racecraft_image = racer_details_global[i]['ship_image_location'];
            var user_predicted = racer_details_global[i]['racer_predicted'];
            var win_pct = Math.floor(racer_details_global[i]['racer_start_win_pct']*100);
            var xpchance = Math.round(total_xp_points*(1-(racer_details_global[i]['racer_start_win_pct'])));
            var totalpreds = racer_details_global[i]['total_predictors'];
            var pilot_image_bg = "url('"+pilot_image+"')";
            var margintop = "0px 0px 8px 0px";

            var racer_name = racer_details_global[i].racer_name;
            var racer_bot_racer = racer_details_global[i].bot_racer;
            var race_track_faction = racer_details_global[i].race_track_galaxy;
            
            var score_speed = 0;
            var score_handling = 0;
            var score_acceleration = 0;
            var score_strength = 0;
            var score_focus = 0;
            var score_stamina = 0;
            var score_intelligence = 0;
            var score_aggression = 0;
            var score_speed2 = 0;
            var score_handling2 = 0;
            var score_acceleration2 = 0;
            var score_strength2 = 0;
            var score_focus2 = 0;
            var score_stamina2 = 0;
            var score_intelligence2 = 0;
            var score_aggression2 = 0;
            var score_handling3 = 0;
            var score_focus3 = 0;
            var score_speed3 = 0;
            var score_intelligence3 = 0;

            /* If Bot Racer, Then Use Override Attribute Stats and Booster Stats */
            if(racer_bot_racer == 1) {
                for(var m = 0; m < racer_details_global[i].override_attributes.length;m++) {
                    racer_details_global[i].override_attributes[m].attribute = racer_details_global[i].override_attributes[m].attribute.toLowerCase();
                    if(racer_details_global[i].override_attributes[m].attribute == 'speed') {
                        score_speed = racer_details_global[i].override_attributes[m].score;
                    } else if(racer_details_global[i].override_attributes[m].attribute == 'handling') {
                        score_handling = racer_details_global[i].override_attributes[m].score;
                    } else if(racer_details_global[i].override_attributes[m].attribute == 'acceleration') {
                        score_acceleration = racer_details_global[i].override_attributes[m].score;
                    } else if(racer_details_global[i].override_attributes[m].attribute == 'strength') {
                        score_strength = racer_details_global[i].override_attributes[m].score;
                    } else if(racer_details_global[i].override_attributes[m].attribute == 'focus') {
                        score_focus = racer_details_global[i].override_attributes[m].score;
                    } else if(racer_details_global[i].override_attributes[m].attribute == 'stamina') {
                        score_stamina = racer_details_global[i].override_attributes[m].score;
                    } else if(racer_details_global[i].override_attributes[m].attribute == 'aggression') {
                        score_aggression = racer_details_global[i].override_attributes[m].score;
                    } else if(racer_details_global[i].override_attributes[m].attribute == 'intelligence') {
                        score_intelligence = racer_details_global[i].override_attributes[m].score;
                    } 
                }

                var booster_attribute_score = racer_details_global[i].boosters[9];
                var booster_attribute_name = racer_details_global[i].boosters[4].toLowerCase();
                if(booster_attribute_name == 'focus') {
                    score_focus2 = booster_attribute_score;
                } else if(booster_attribute_name == 'stamina') {
                    score_stamina2 = booster_attribute_score;
                } else if(booster_attribute_name == 'aggression') {
                    score_aggression2 = booster_attribute_score;
                } else if(booster_attribute_name == 'intelligence') {
                    score_intelligence2 = booster_attribute_score;
                } else if(booster_attribute_name == 'speed') {
                    score_speed2 = booster_attribute_score;
                } else if(booster_attribute_name == 'handling') {
                    score_handling2 = booster_attribute_score;
                } else if(booster_attribute_name == 'acceleration') {
                    score_acceleration2 = booster_attribute_score;
                }  else if(booster_attribute_name == 'strength') {
                    score_strength2 = booster_attribute_score;
                } 

            } else {
                
                for(var m = 0; m < racer_details_global[i].ship_attributes.length;m++) {
                    racer_details_global[i].ship_attributes[m].attribute = racer_details_global[i].ship_attributes[m].attribute.toLowerCase();

                    if(racer_details_global[i].ship_attributes[m].attribute == 'speed') {
                        score_speed = racer_details_global[i].ship_attributes[m].score;
                    } else if(racer_details_global[i].ship_attributes[m].attribute == 'handling') {
                        score_handling = racer_details_global[i].ship_attributes[m].score;
                    } else if(racer_details_global[i].ship_attributes[m].attribute == 'acceleration') {
                        score_acceleration = racer_details_global[i].ship_attributes[m].score;
                    }  else if(racer_details_global[i].ship_attributes[m].attribute == 'strength') {
                        score_strength = racer_details_global[i].ship_attributes[m].score;
                    } 
                }

                for(var m = 0; m < racer_details_global[i].pilot_attributes.length;m++) {
                    racer_details_global[i].pilot_attributes[m].attribute = racer_details_global[i].pilot_attributes[m].attribute.toLowerCase();
                    
                    if(racer_details_global[i].pilot_attributes[m].attribute == 'focus') {
                        score_focus = racer_details_global[i].pilot_attributes[m].score;
                    } else if(racer_details_global[i].pilot_attributes[m].attribute == 'stamina') {
                        score_stamina = racer_details_global[i].pilot_attributes[m].score;
                    } else if(racer_details_global[i].pilot_attributes[m].attribute == 'aggression') {
                        score_aggression = racer_details_global[i].pilot_attributes[m].score;
                    } else if(racer_details_global[i].pilot_attributes[m].attribute == 'intelligence') {
                        score_intelligence = racer_details_global[i].pilot_attributes[m].score;
                    } 
                }
                
                var booster_attributes = racer_details_global[i].boosters[8];
                if(booster_attributes != null) {
                    for(var m = 0; m < booster_attributes.length;m++) {
                        booster_attributes[m].attribute = booster_attributes[m].attribute.toLowerCase();
                        if(booster_attributes[m].attribute == 'focus') {
                            score_focus2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'stamina') {
                            score_stamina2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'aggression') {
                            score_aggression2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'intelligence') {
                            score_intelligence2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'speed') {
                            score_speed2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'handling') {
                            score_handling2 = booster_attributes[m].score;
                        } else if(booster_attributes[m].attribute == 'acceleration') {
                            score_acceleration2 = booster_attributes[m].score;
                        }  else if(booster_attributes[m].attribute == 'strength') {
                            score_strength2 = booster_attributes[m].score;
                        } 
                    }
                }
                
            }

            var genetic_boost_text = <></>;
            var track_faction_pilot_match = 0;
            var track_faction_racecraft_match = 0;
            if(racer_details_global[i].pilot_faction.toLowerCase() == race_track_faction.toLowerCase()) {
                track_faction_pilot_match = 1;
                score_intelligence3 = 2;
            }
            if(racer_details_global[i].ship_faction.toLowerCase() == race_track_faction.toLowerCase()) {
                track_faction_racecraft_match = 1;
                score_speed3 = 2;
            }
            if(racer_details_global[i].boosters[5] == null) {
                racer_details_global[i].boosters[5] = '';
            }

            if((racer_details_global[i].pilot_faction.toLowerCase() == racer_details_global[i].ship_faction.toLowerCase())&&
                (((racer_details_global[i].pilot_faction.toLowerCase() == racer_details_global[i].boosters[5].toLowerCase())||(racer_details_global[i].boosters[5].toLowerCase() == 'any'))||
                    (race_track_faction.toLowerCase() == racer_details_global[i].pilot_faction.toLowerCase()))) {
                score_handling3 = 4;
                score_focus3 = 4;
                var finalboosttip = genetic_max_tooltip;
                if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                    finalboosttip = genetic_max_tooltip_wtrack;
                }
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={finalboosttip}>GENETIC MAX</span>;
            } else if(racer_details_global[i].pilot_faction.toLowerCase() == racer_details_global[i].ship_faction.toLowerCase()) {
                score_handling3 = 3;
                score_focus3 = 3;
                var finalboosttip = genetic_tooltip;
                if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                    finalboosttip = genetic_tooltip_wtrack;
                }
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={finalboosttip}>GENETIC BOOST</span>;
            } else if ((track_faction_pilot_match > 0)||(track_faction_racecraft_match > 0)) {
                genetic_boost_text = <span className="right exr-yellow-text lineuptips" style={{marginRight:'15px',cursor:'context-menu'}} data-position="bottom" data-tooltip={genetic_track_tooltip}>GENETIC TRACK</span>;
            }
            var pstatusbutton = 'A';
            var buttonpredicteddisp = 'none';
            var buttonchangedisp = 'none';
            var buttondonedisp = 'none';
            var buttonpredictdisp = 'none';
            var buttonnumdisp = 'none';

            var predictbutton = <></>;
            var predictedfinish = 0;
            var predictedbuttontxt = 'PREDICTED';
            var changebuttontxt = 'CHANGE PICK';
            var donebuttontext = 'PICKS DONE';
            if(user_predicted == 1) {
                buttonpredicteddisp = 'block';
                pstatusbutton = 'S';
                predictbutton = <div className="right round-border-button-green viewer-racer-pred-button" 
                        style={{fontSize:'0.4vw',minHeight:'1.15vw'}} key={"racewaypredictbutton"+raceid+pracerid+pstatusbutton}
                        data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
                        data-racername={racername} data-pilotimage={pilot_image} data-racecraftimage={racecraft_image} 
                        data-predxp={xpchance} 
                        data-winchance={win_pct} 
                        data-userpredplace={predictedfinish} 
                        data-predictlast={predict_last_place} 
                        onClick={(e)=>openPredictRacerBox(e)} 
                        title="Predicted"
                        >PREDICTED</div>;

                predictedfinish = racer_details_global[i]['racer_predicted_place'];
                if(predict_last_place == 1) {
                    if(racer_details_global[i]['racer_predicted_place'] == 5) {
                        predictedbuttontxt = 'PICKED LAST';
                    } else if (racer_details_global[i]['racer_predicted_place'] == 1) {
                        predictedbuttontxt = 'PICKED 1ST';
                    }
                }
                
            } else if((race_details_global[0]['race_simulated'] == 0)&&(race_details_global[0]['entry_window_over'] == 1)&&(race_details_global[0]['predictions_window_over'] == 0)&&(race_details_global[0]['predictions_window_open'] == 1)) {
                if (((predicted_racer_id > 0)||(predicted_racer_id_last > 0))&&(predicted_racer_id != pracerid)&&(predicted_racer_id_last != pracerid)) {
                    buttonchangedisp = 'block';
                    pstatusbutton = 'C';
                    predictbutton = <div className="right round-border-button viewer-racer-pred-button" 
                        style={{fontSize:'0.4vw',minHeight:'1.15vw'}} key={"racewaypredictbutton"+raceid+pracerid+pstatusbutton}
                        data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
                        data-racername={racername} data-pilotimage={pilot_image} data-racecraftimage={racecraft_image} 
                        data-predxp={xpchance}  
                        data-winchance={win_pct} 
                        data-userpredplace={predictedfinish} 
                        data-predictlast={predict_last_place}
                        onClick={(e)=>openPredictRacerBox(e)} 
                        >CHANGE PICK</div>;

                        
                    if(predict_last_place == 1) {
                        if((predicted_racer_id_last == 0)||(predicted_racer_id == 0)) {
                            changebuttontxt = 'MAKE PICK';
                        }
                    }
                } else if((race_details_global[0]['open_invite_race'] == 1)&&($("#current-racer-preds").val() <= 0)) {
                    buttondonedisp = 'block';
                    pstatusbutton = 'D';
                    predictbutton = <div className="right round-border-button" 
                        style={{fontSize:'0.4vw',minHeight:'1.15vw',cursor:'not-allowed'}} key={"racewaypredictbutton"+raceid+pracerid+pstatusbutton}
                        data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
                        data-racername={racername} data-pilotimage={pilot_image} data-racecraftimage={racecraft_image} 
                        data-predxp={xpchance}  
                        data-winchance={win_pct} 
                        data-userpredplace={predictedfinish} 
                        data-predictlast={predict_last_place}
                        title="You Are Out Of Predictions For This Race Day" 
                        >PICKS DONE</div>;
                    if((racerID == 0)&&(userID == 0)) {
                        donebuttontext = 'LOGIN TO PICK';
                    }
                } else {
                    buttonpredictdisp = 'block';
                    pstatusbutton = 'P';
                    predictbutton = <div className="right round-border-button viewer-racer-pred-button" 
                        style={{fontSize:'0.4vw',minHeight:'1.15vw'}} key={"racewaypredictbutton"+raceid+pracerid+pstatusbutton}
                        data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
                        data-racername={racername} data-pilotimage={pilot_image} data-racecraftimage={racecraft_image} 
                        data-predxp={xpchance}  
                        data-winchance={win_pct} 
                        data-userpredplace={predictedfinish} 
                        data-predictlast={predict_last_place}
                        onClick={(e)=>openPredictRacerBox(e)} 
                        >PREDICT</div>;
                    
                }
            } else {
                buttonnumdisp = 'block';
                predictbutton = <div key={"racewaypredictbutton"+raceid+pracerid+pstatusbutton} className="right" style={{fontSize:'14px',minHeight:'1.15vw',marginTop:'2px',marginRight:'5px'}}>RACER {i+1}</div>;
            }

            var allpredictbuttons = <><div className="right round-border-button-green viewer-racer-pred-button exr-new-light-grey" 
                                            style={{fontSize:'0.4vw',minHeight:'1.15vw',display:buttonpredicteddisp}} key={"racewaypredictbutton"+raceid+pracerid+'S'}
                                            data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
                                            data-racername={racername} data-pilotimage={pilot_image} data-racecraftimage={racecraft_image} 
                                            data-predxp={xpchance} 
                                            data-winchance={win_pct} 
                                            data-userpredplace={predictedfinish} 
                                            data-predictlast={predict_last_place} 
                                            title="Predicted"
                                            onClick={(e)=>openPredictRacerBox(e)} 
                                        >{predictedbuttontxt}</div>
                                        <div className="right round-border-button viewer-racer-pred-button exr-new-light-grey" 
                                            style={{fontSize:'0.4vw',minHeight:'1.15vw',display:buttonchangedisp}} key={"racewaypredictbutton"+raceid+pracerid+'C'}
                                            data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
                                            data-racername={racername} data-pilotimage={pilot_image} data-racecraftimage={racecraft_image} 
                                            data-predxp={xpchance}  
                                            data-winchance={win_pct} 
                                            data-userpredplace={predictedfinish}  
                                            data-predictlast={predict_last_place} 
                                            onClick={(e)=>openPredictRacerBox(e)} 
                                            >{changebuttontxt}</div>
                                        <div className="right round-border-button exr-new-light-grey" 
                                            style={{fontSize:'0.4vw',minHeight:'1.15vw',cursor:'not-allowed',display:buttondonedisp}} key={"racewaypredictbutton"+raceid+pracerid+'D'}
                                            data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
                                            data-racername={racername} data-pilotimage={pilot_image} data-racecraftimage={racecraft_image} 
                                            data-predxp={xpchance}  
                                            data-winchance={win_pct} 
                                            data-userpredplace={predictedfinish}  
                                            data-predictlast={predict_last_place} 
                                            title="You Are Out Of Predictions For This Race Day" 
                                            >{donebuttontext}</div>
                                        <div className="right round-border-button viewer-racer-pred-button exr-new-light-grey" 
                                            style={{fontSize:'0.4vw',minHeight:'1.15vw',display:buttonpredictdisp}} key={"racewaypredictbutton"+raceid+pracerid+'P'}
                                            data-raceid={raceid} data-pracerid={pracerid}  data-racernumber={i+1} 
                                            data-racername={racername} data-pilotimage={pilot_image} data-racecraftimage={racecraft_image} 
                                            data-predxp={xpchance}  
                                            data-winchance={win_pct} 
                                            data-userpredplace={predictedfinish} 
                                            data-predictlast={predict_last_place} 
                                            onClick={(e)=>openPredictRacerBox(e)} 
                                            >PREDICT</div>
                                        <div key={"racewaypredictbutton"+raceid+pracerid+'A'} className="right" style={{display:buttonnumdisp,fontSize:'14px',minHeight:'1.15vw',marginTop:'2px',marginRight:'5px'}}>RACER {i+1}</div>
                                        </>;
            // if(race_details_global[0]['race_simulated'] == 1) {
            //     predictbutton = <span className="headline-text">{ordinal_suffix_of((i+1)).toUpperCase()}</span>;
            //     if(i == 1) {
            //         xpchance = Math.round((xpchance/2));
            //     } else if(i > 1) {
            //         xpchance = 0;
            //     }
            // } else if (i < 2) {
            //     if(totalpreds > 0) {
            //         predictbutton = 'Fan Favorite // '+ordinal_suffix_of((i+1))+' Place';
            //     } else {
            //         predictbutton = 'Odds Favorite // '+ordinal_suffix_of((i+1))+' Place';
            //     }
                
            // }

            

            var score_html = <div className="col s12 white-text" style={{fontSize:'14px'}}>
                                <div className="row headline-text" style={{marginTop:'0px',marginBottom:'0px',padding:'0px',position:'relative',fontSize:'12px'}}>
                                    <span className="left white-green-link" title="Learn About Racing Stacks" style={{marginLeft:'15px'}} onClick={()=>showStackInfoBox()}>
                                        RACE STACK
                                    </span>
                                    {genetic_boost_text}
                                </div>
                                <div className="divider-thin"></div>
                                <div className="row" style={{marginTop:'15px',marginBottom:'5px'}}>
                                        <div className="col s6">
                                            <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Speed</div>
                                                    <div className="right">{(score_speed+score_speed2+score_speed3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_speed+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_speed2+'%',left:score_speed+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_speed3+'%',left:(score_speed+score_speed2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Handling</div>
                                                    <div className="right">{(score_handling+score_handling2+score_handling3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_handling+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_handling2+'%',left:score_handling+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_handling3+'%',left:(score_handling+score_handling2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Acceleration</div>
                                                    <div className="right">{(score_acceleration+score_acceleration2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_acceleration+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_acceleration2+'%',left:score_acceleration+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Strength</div>
                                                    <div className="right">{(score_strength+score_strength2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_strength+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_strength2+'%',left:score_strength+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col s6">
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Focus</div>
                                                    <div className="right">{(score_focus+score_focus2+score_focus3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_focus+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_focus2+'%',left:score_focus+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Booster" style={{width:score_focus3+'%',left:(score_focus+score_focus2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Stamina</div>
                                                    <div className="right">{(score_stamina+score_stamina2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_stamina+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_stamina2+'%',left:score_stamina+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Aggression</div>
                                                    <div className="right">{(score_aggression+score_aggression2)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_aggression+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_aggression2+'%',left:score_aggression+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:'5px',marginBottom:'5px'}}>
                                            <div className="col s12">
                                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                                    <div className="left">Intelligence</div>
                                                    <div className="right">{(score_intelligence+score_intelligence2+score_intelligence3)}</div>
                                                </div>
                                                <div style={{width:'100%'}}>
                                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'5px',marginTop:'2px'}}>
                                                        <div className="determinate white left" style={{width:score_intelligence+'%'}}></div>
                                                        <div className="determinate exr-stats-light-green2 left" title="Attribute Booster" style={{width:score_intelligence2+'%',left:score_intelligence+'%'}}></div>
                                                        <div className="determinate exr-stats-yellow left" title="Genetic Track Booster" style={{width:score_intelligence3+'%',left:(score_intelligence+score_intelligence2)+'%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;

            var predict_html = <><div className="left" style={{fontSize:'16px',marginTop:'2px',marginRight:'5px',lineHeight:'1'}}>
                                    {win_pct}%
                                </div>
                                <div className="left" style={{fontSize:'12px',marginTop:'4px',lineHeight:'1'}}>
                                    WIN
                                </div>
                                
                                <div className="right" style={{fontSize:'12px',marginTop:'2px',lineHeight:'1',marginRight:'5px'}}>
                                    <span style={{fontSize:'16px'}}>{totalpreds}</span>&nbsp;&nbsp;PICKED
                                </div>
                                <div className="right" style={{fontSize:'12px',marginTop:'2px',lineHeight:'1',marginRight:'15px'}}>
                                    <span style={{fontSize:'16px'}}>{xpchance}</span>&nbsp;&nbsp;XP
                                </div>
                                </>;
            if((i+10000+pracerid) == 11155) {
                //console.log(pracerid, racername);
            }
            var tmp_row = <div key={"racewaypredictrow"+raceid+pracerid+pstatusbutton} className="row exr-new-dark-grey" style={{margin:margintop,width:'100%',borderRadius:'10px',padding:'5px',overflow:'hidden'}}>
                                <div id={"racer-row-"+pracerid} data-racerrow={pracerid} 
                                    onClick={(e)=>toggleRacerRow(e)}
                                    className="row exr-new-light-grey" style={{display:'flex',flexDirection:'row',margin:'0px',padding:'0px 7px',paddingTop:'7px',borderRadius:'10px',cursor:'pointer'}}>
                                    <div className="left" style={{width:'17%',lineHeight:'.5',marginBottom:'7px'}}>
                                        <img src={racecraft_image} width="100%" style={{borderRadius:'5px'}} />
                                    </div>
                                    <div className="left white-text" style={{flex:'1',marginLeft:'10px',position:'relative'}}>
                                        <div className="row headline-text" style={{margin:'0px',fontSize:"14px",marginTop:'5px'}}>
                                            {racername.toUpperCase()}
                                        </div>
                                        <div className="row" style={{margin:'0px',fontSize:"16px",marginTop:'10px'}}>
                                            {predict_html}
                                        </div>
                                        <div style={{position:'absolute',top:'2px',right:'3px'}}>
                                            {allpredictbuttons}
                                        </div>
                                        <div style={{position:'absolute',bottom:'5px',right:'10px'}}>
                                            <div id={"racer-stack-show-icon-"+pracerid} className="right material-symbols-outlined white-text" style={{fontSize:'20px',marginTop:'0px',lineHeight:'1'}}>
                                                expand_more
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div id={"racer-stack-show-"+pracerid} className="row exr-new-light-grey" style={{display:'none',margin:'0px',padding:'5px',marginTop:'-10px',
                                borderRadius:'10px',borderTopRightRadius:'0px',borderTopLeftRadius:'0px'}}>
                                    <div className="col s12 exr-new-dark-grey" style={{padding:"2px",paddingTop:'15px',marginTop:'10px',borderRadius:"10px",height:'auto',marginBottom:'0px'}}>
                                            {score_html}
                                    </div>
                                </div>
        </div>;

            // if((userid == userID)||(user_predicted == 1)) {
            //     racerrows.push(tmp_row);
            // } else {
            //     tmp_racer_rows.push(tmp_row);
            // }
            tmp_racer_rows.push(tmp_row);
        }

        for(var z = 0; z < tmp_racer_rows.length;z++) {
            racerrows.push(tmp_racer_rows[z]);
        }

        // Setup Track Information
        var tmptrack = <div key={i+100000} className="row exr-new-dark-grey" style={{margin:margintop,width:'100%',borderRadius:'10px',padding:'5px',overflow:'hidden'}}>
            <div className="col s12" style={{marginTop:'10px'}}>
                <div className="col s12 headline-text" style={{margin:"0px",padding:"0px",fontSize:'18px'}}>
                    TRACK INFO
                </div>
                <div className="col s12" style={{margin:"0px",padding:"0px"}}>
                    <div className="border-section-full exr-new-dark-grey" style={{position:'relative',width:"99%",marginRight:"1%",overflow:"hidden"}}>
                        <div className="transparent" id="track-viewer" ref={track_viewer_container} style={{width:'95%',marginLeft:'2.5%',marginTop:'20px',marginBottom:'15px',height:"250px",borderRadius:'10px'}}></div>
                    </div>
                </div>
                <div className="col s12 exr-new-light-grey " style={{position:'relative',margin:"0px",padding:"10px",borderRadius:'10px',marginTop:'0px',marginBottom:'10px'}}>
                    {track_data_display}
                </div>
            </div>
        </div>;

        racerrows.push(tmptrack);

        // if((race_details_global[0]['race_simulated'] == 1)&&(race_details_global[0]['results_viewable'] == 0)) {
        if((race_details_global[0]['race_simulated'] == 1)) {
            var tmp_footer = <div className="row exr-new-dark-grey" style={{margin:'0px',padding:'0px',width:'100%',marginBottom:'0px',marginTop:'10px',borderRadius:'10px'}}>
                                <div className="col s12 headline-text center-align white-yellow-link" 
                                    style={{margin:'0px',padding:'0px',fontSize:'0.75vw',lineHeight:'1',marginBottom:'8px',marginTop:'10px'}}>
                                    <a href={"https://www.exiledracers.com/#/watch/"+race_details_global[0]['race_unique_id']} className="white-yellow-link headline-text" target="_blank">
                                        WATCH LINK
                                    </a>
                                </div>
                            </div>;
            racerrows.push(tmp_footer);

        }

        setRacerHandles(racer_handle_arr);
        var finalSection = racerrows;
        setPredictRacersLineup(finalSection);
        // if(initialload == 0) {
        //     initialload = 1;
        //     toggleViewerSections('predict');
        // }
        

        if(racer_details_global.length > 2) {
            $("#chat-predict-display").css({'overflow-y':'scroll','overflow-x':'hidden'});
        } else {
            $("#chat-predict-display").css({'overflow-y':'hidden','overflow-x':'hidden'});
        }

        setTimeout(function() {
            loadTrack(current_track_id);
        },1000);

        // if(race_details_global[0]['race_simulated'] == 0) {
        //     setTimeout(function() {
        //         grabRaceParticipants();
        //     },2000);
        // }
    }

    function showTrackSummary() {
        
        var addData = new Object();
        addData.trackID = encodeURIComponent(current_track_id);
        addData.raceID = encodeURIComponent(current_race_id);
        var tmp_html = <></>;
        
        axiosInstance.post('race/track_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_track_list = result.data;
                var track_info = {};
                for(var z = 0; z < new_track_list.length;z++) {
                    if(new_track_list[z]['race_track_id'] == current_track_id) {
                        track_info = new_track_list[z];
                    }
                }
                var trackUnlocked = track_info.track_unlocked;
                var raceTrackID = track_info.race_track_id;
                var raceTrackGalaxy = track_info.race_track_galaxy;
                var raceLaps = track_info.race_laps;
                var raceTurns = track_info.total_turns;
                var raceStraights = track_info.total_straights;
                var raceShifts = track_info.total_shifts;
                var raceSegments = track_info.total_segments;
                var lappct = Math.round(100*(raceLaps / (10)));
                var turnpct = Math.round(100*(raceTurns / raceSegments));
                var straightpct = Math.round(100*(raceStraights / raceSegments));
                var shiftpct = Math.round(100*(raceShifts / raceSegments));

                var galaxy_faction = '';
                if(raceTrackGalaxy.length > 0) {
                    if(raceTrackGalaxy == 'serf') {
                        galaxy_faction = 'Serf';
                    } else if(raceTrackGalaxy == 'augment') {
                        galaxy_faction = "Aug";
                    } else {
                        galaxy_faction = "Merc";
                    }
                }

                var galaxy_faction_icon = '';
                    if(galaxy_faction != 'ANY') {
                        galaxy_faction_icon = <img title={galaxy_faction+" System Track - Match Stack Factions For Genetic Boost"} src={"https://media.exiledracers.com/global/icons/exr_"+galaxy_faction.toLowerCase()+"_icon.png"} height="50px" />
                    }
                tmp_html = <>
                            <div className="stats-viewer-stats-headline center-align" style={{marginTop:'0px',marginBottom:'10px',position:'relative'}}>
                                <span style={{width:'100%',fontSize:'16px',lineHeight:'1.25'}}>
                                    EXR Track #{raceTrackID}&nbsp;&nbsp;//&nbsp;&nbsp;
                                    <span className="exr-light-green-text" title={galaxy_faction+" System Track - Match Stack Factions For Genetic Boost"}>
                                        {galaxy_faction} System
                                    </span>
                                </span>
                            </div>
                            <div className="divider-thin"></div>
                            <div className="row white-text" style={{fontSize:'14px',marginTop:'15px',marginBottom:'5px'}}>
                                <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={laps_tooltip}>
                                    <div style={{order:'1',flexBasis:'40px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_strength.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                    </div>
                                    <div style={{order:'2',flex:'10',lineHeight:'1.25'}}>
                                        <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                            <div className="left">Laps</div>
                                            <div className="right">{raceLaps}</div>
                                        </div>
                                        <div style={{width:'100%'}}>
                                            <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'5px'}}>
                                                <div className="determinate white" style={{width:lappct+'%',backgroundColor: 'white'}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="divider-thin"></div>
                            <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                                <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={turns_tooltip}>
                                    <div style={{order:'1',flexBasis:'40px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_handling.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                    </div>
                                    <div style={{order:'2',flex:'10',lineHeight:'1.25'}}>
                                        <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                            <div className="left">Turns</div>
                                            <div className="right">{raceTurns}</div>
                                        </div>
                                        <div style={{width:'100%'}}>
                                            <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                <div className="determinate white" style={{width:turnpct+'%',backgroundColor: 'white'}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="divider-thin"></div>
                            <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                                <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={straights_tooltip}>
                                    <div style={{order:'1',flexBasis:'40px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_speed.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                    </div>
                                    <div style={{order:'2',flex:'10',lineHeight:'1.25'}}>
                                        <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                            <div className="left">Straights</div>
                                            <div className="right">{raceStraights}</div>
                                        </div>
                                        <div style={{width:'100%'}}>
                                            <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                <div className="determinate white" style={{width:straightpct+'%',backgroundColor: 'white'}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="divider-thin"></div>
                            <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                                <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={shifts_tooltip}>
                                    <div style={{order:'1',flexBasis:'40px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_acceleration.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                                    </div>
                                    <div style={{order:'2',flex:'10',lineHeight:'1.25'}}>
                                        <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                            <div className="left">Speed Shifts</div>
                                            <div className="right">{raceShifts}</div>
                                        </div>
                                        <div style={{width:'100%'}}>
                                            <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                                <div className="determinate white" style={{width:shiftpct+'%',backgroundColor: 'white'}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="divider-thin" style={{marginBottom:'25px'}}></div>
                        </>;

                        track_data_display = tmp_html;

            }
        }).catch(error => {
            console.log(error);
        });

        
        var tooltips = document.querySelectorAll('.tracktips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    }

    function loadWaitingroomPredictBanner() {
        let predictbanner = <></>;
        var finalSection = <div id="chat-predict-display" className="exr" style={{marginTop:'10px',borderRadius:'10px',width:'100%'}}>
            <div className="row exr-new-dark-grey"  style={{height:'auto',margin:'0px',padding:'20px 10px',position:'relative',borderRadius:'10px'}}>
                <div className="col s12" style={{marginTop:'15px'}}>
                    <span className="left headline-text exr-light-green-text" style={{width:'10%',fontSize:'30px',marginRight:'10px'}}>1</span>
                    <span className="left" style={{fontSize:'18px',width:'85%'}}>
                        <span className="left" style={{width:'100%',fontSize:'18px',marginBottom:'5px'}}>
                        <a href="/#/gameplay" className="white-yellow-link" title="Learn How To Race & Predict">Learn To Race & Predict</a>
                        </span>
                        <span className="left" style={{width:'100%',fontSize:'14px',lineHeight:'1.25'}}>
                            Watch our <a href="/#/gameplay" className="yellow-yellow-link" title="Learn How To Race & Predict">tutorial videos</a> and learn how to Race and Predict on EXR.
                        </span>
                    </span>
                </div>
                <div className="col s12" style={{marginTop:'38px'}}>

                    <span className="left headline-text exr-light-green-text" style={{width:'10%',fontSize:'30px',marginRight:'10px'}}>2</span>
                    <span className="left" style={{fontSize:'18px',width:'85%'}}>
                        <span className="left" style={{width:'100%',fontSize:'18px',marginBottom:'5px'}}>Make Predictions</span>
                        <span className="left" style={{width:'100%',fontSize:'14px',lineHeight:'1.25'}}>
                            Once Race Entries close, there will be a 15 minute prediction window open. Discover open races below, click the Predict button to make a Free Prediction on a race. 
                        </span>
                    </span>
                </div>
                <div className="col s12" style={{marginTop:'38px'}}>
                    <span className="left headline-text exr-light-green-text" style={{width:'10%',fontSize:'30px',marginRight:'10px'}}>3</span>
                    <span className="left" style={{fontSize:'18px',width:'85%'}}>
                        <span className="left" style={{width:'100%',fontSize:'18px',marginBottom:'5px'}}>Watch Races Live</span>
                        <span className="left" style={{width:'100%',fontSize:'14px',lineHeight:'1.25'}}>
                        Check out the Race Viewer to see when Predictions end and Races will be live. 
                        </span>
                    </span>
                </div>
                <div className="col s12" style={{marginTop:'38px'}}>
                    <span className="left headline-text exr-light-green-text" style={{width:'10%',fontSize:'30px',marginRight:'10px'}}>4</span>
                    <span className="left" style={{fontSize:'18px',width:'85%'}}>
                        <span className="left" style={{width:'100%',fontSize:'18px',marginBottom:'5px'}}>Collect Rewards</span>
                        <span className="left" style={{width:'100%',fontSize:'14px',lineHeight:'1.25'}}>
                            The more XP you earn by predicting correctly, the higher chance you are to win a prize.&nbsp;
                        </span>
                    </span>
                </div>
                <div className="col s12">
                    
                </div>
            </div>
        </div>;
        setPredictRacersLineup(finalSection);

        $("#chat-predict-display").css({'overflow-y':'scroll','overflow-x':'hidden'});
        setTimeout(function() {
            adjustRightBoxHeights();
        },100);
    }

    async function loadTrack(trackid) {
        if((track_viewer_container.current)&&(initialtrackloaded == 0)) {
            
            let track_data = await new Track(trackid,'id');
            new TrackLoader(track_data,
                () => {
                    if(track_viewer_container.current) {
                        $("#track-viewer").html('');
                        let track_minimap = new Minimap(new TrackMap(track_data),track_viewer_container.current).populate(null);
                        initialtrackloaded = 1;
                    }
                }
            );
        } else if(track_viewer_container.current === false) {
            setTimeout(function() {
                loadTrack(trackid);
            },1000);
        }
    }


    function openPredictRacerBox(e) {
        e.preventDefault();
        let race_elem = e.target;
        let raceid = race_elem.dataset.raceid;
        let predracerid = race_elem.dataset.pracerid;
        let racernumber = race_elem.dataset.racernumber;
        let racername = race_elem.dataset.racername;
        let pilotimage = race_elem.dataset.pilotimage;
        let racecraftimage = race_elem.dataset.racecraftimage;
        let predxp = race_elem.dataset.predxp;
        let winchance = race_elem.dataset.winchance;
        let userpredplace = race_elem.dataset.userpredplace;
        let predictlast = race_elem.dataset.predictlast;

        if((race_details_global[0]['race_simulated'] == 0)&&(race_details_global[0]['predictions_window_over'] == 0)&&(race_details_global[0]['predictions_window_open'] == 1)) {
            props.loadPredictionBox(raceid, predracerid, racernumber, racername, pilotimage, racecraftimage, predxp, winchance, predictlast, userpredplace);
        }
    }

    function loadSponsorGrid() {
        let tmp_grid = <div id="race_sponsors" className="row" style={{marginTop:'80px'}}>
                            <div className="col s8 offset-s2" style={{padding:'20px',border:'1px solid grey',borderRadius:'10px'}}>
                                <div className="row center-align white-text headline-text" style={{fontSize:'18px',}}>
                                    RACE SPONSORS
                                </div>
                                <div className="row">
                                    <div className="col s12 m4 center-align" style={{padding:'5px'}}>
                                        <img src="https://media.exiledracers.com/partners/stellaswap_logo.svg" height="35px" />
                                    </div>
                                    <div className="col s12 m4 center-align" style={{padding:'5px',marginTop:'-5px'}}>
                                        <img src="https://media.exiledracers.com/partners/moonbeam_logo.png" height="50px" />
                                    </div>
                                    <div className="col s12 m4 center-align" style={{padding:'5px',marginTop:'0px'}}>
                                        <img src="https://media.exiledracers.com/partners/prime_protocol_logo.webp" height="50px" />
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom:'10px'}}>
                                    
                                    <div className="col s12 m4 center-align" style={{padding:'5px'}}>
                                        <img src="https://media.exiledracers.com/partners/dam_logo.svg" height="35px" />
                                    </div>
                                    <div className="col s12 m4 center-align" style={{padding:'5px',marginTop:'-10px'}}>
                                        <img src="https://media.exiledracers.com/assets/game/builder/Zeitgeist_Logo_1665706100333.png" height="45px" />
                                    </div>
                                    <div className="col s12 m4 center-align" style={{padding:'5px'}}>
                                        <img src="https://media.exiledracers.com/partners/biconomy_logo.svg" height="35px" />
                                    </div>
                                </div>
                            </div>
                        </div>;
        setRaceSponsorHTML(tmp_grid);
    }

    function adjustRightBoxHeights() {
        let leftsideheight = $("#sb-viewer-left-box").height();
        
        if((window.innerWidth < 1000)) {
            leftsideheight = leftsideheight*1.55;
            var totalHeight = 0;
            $("#chat-predict-display").children().each(function(){
                totalHeight = totalHeight + $(this).outerHeight(true);
            });

            if(totalHeight >= leftsideheight) {
                leftsideheight = totalHeight + 100;
            }
        }
        $("#sb-viewer-right-box").css({'height':leftsideheight+2.5+'px'});
        $("#chat-message-display").css({'height':(leftsideheight-140)+'px'});
        $("#chat-predict-display").css({'height':(leftsideheight-140)+'px'});

        if((window.innerWidth < 600)) {
            $("#race-countdown-title").css({'font-size':'20px'});
            $("#race-countdown-timer").css({'font-size':'18px'});
            $("#race-countdown-date").css({'font-size':'20px'});
            $("#right-side-viewer-shell").css({'padding-left':'0px','padding-right':'0px'});
            $(".viewer-racer-name").each(function() {
                $(this).css({'font-size':'16px'});
            });
        } else {
            $("#race-countdown-title").css({'font-size':'2.5vw'});
            $("#race-countdown-timer").css({'font-size':'2.5vw'});
            $("#race-countdown-date").css({'font-size':'2.5vw'});
            $("#right-side-viewer-shell").css({'padding-left':'7px','padding-right':'2.5px'});
            $(".viewer-racer-name").each(function() {
                $(this).css({'font-size':'0.85vw'});
            });
        }

        if((window.innerWidth < 1000)) {
            $(".viewer-racer-name").each(function() {
                $(this).css({'font-size':'16px'});
            });
            $(".viewer-racer-pred-button").each(function() {
                $(this).css({'font-size':'14px'});
            });
            $(".viewer-racer-number").each(function() {
                $(this).css({'font-size':'20px'});
            });
            $(".viewer-racer-number-text").each(function() {
                $(this).css({'font-size':'12px'});
            });
            
            
        } else {
            $(".viewer-racer-name").each(function() {
                $(this).css({'font-size':'0.85vw'});
            });
            $(".viewer-racer-pred-button").each(function() {
                $(this).css({'font-size':'0.6vw'});
            });
            $(".viewer-racer-number").each(function() {
                $(this).css({'font-size':'1.1vw'});
            });
            $(".viewer-racer-number-text").each(function() {
                $(this).css({'font-size':'0.65vw'});
            });
            
            
        }
        
    }

    function toggleRacerRow(e) {
        e.preventDefault();

        var racer_tmp_id = 0;
        let race_elem = e.target;
        while(racer_tmp_id == 0) {
            if(race_elem.dataset.racerrow) {
                racer_tmp_id = race_elem.dataset.racerrow;
            } else {
                race_elem = race_elem.parentElement;
            }
        }
        if($("#racer-stack-show-"+racer_tmp_id).is(':visible')) {
            $("#racer-stack-show-"+racer_tmp_id).slideUp();
            $("#racer-stack-show-icon-"+racer_tmp_id).html('expand_more');
        } else {
            $("#racer-stack-show-"+racer_tmp_id).slideDown();
            $("#racer-stack-show-icon-"+racer_tmp_id).html('expand_less');
        }
    }

    function showStackInfoBox() {
        var sinfobox = document.getElementById('stackinfobox');
        var info_instance = window.M.Modal.getInstance(sinfobox);
        info_instance.open();
    }

    if(entrySet == false) {
        setEntry(true);
        setTimeout(function(){
            grabRaceDetails();
        },100);
        $(window).resize(function() {
            adjustRightBoxHeights();
        });
        // let new_video_html = <video id="waitingroom-bg" src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExZmVhNGFlZTY5YWMxM2UwNDJlZTBmNDA1ZDdjMDhhNTIyNTJjM2E3MSZjdD1n/WUyQbeKHhpaHrrKJu6/giphy.mp4" 
        // style={{border:'0px',overflow:'hidden',borderRadius:'10px'}} width="100%" autoPlay loop muted />;
        // setVideoHTML(new_video_html);
    }

    // $(window).resize(function(){
    //     adjustRightBoxHeights();
    // });

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function toggleViewerSections(sectionname) {
        $("#exr-viewer-predict-toggle").removeClass("white-yellow-link-active")
        $("#exr-viewer-banter-toggle").removeClass("white-yellow-link-active")
        $("#exr-viewer-"+sectionname+"-toggle").addClass("white-yellow-link-active");

        $("#exr-viewer-predict-box").hide();
        $("#exr-viewer-banter-box").hide();
        $("#exr-viewer-"+sectionname+"-box").show();
    }

    function loadCountdownTimer(action,currenttime,sectiontitle) {
        // Set the date we're counting down to
        // var countDownDate = new Date(
        //     currenttime
        // ).getTime();
        // if(sectiontitle != 'RACES STARTING') {
        //     countDownDate = new Date(
        //         currenttime+" GMT-0400 (Eastern Daylight Time)"
        //     ).getTime();
        // }
        var countDownDate = currenttime;

        // Update the count down every 1 second
        // Get today's date and time
        var now = Date.now();
        // Find the distance between now and the count down date
        var distance = (countDownDate*1000) - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        var finaldays = "";
        var finalhours = "";
        var finalminutes = "";
        var finalseconds = "";
        if (days == 0) {
            finaldays = "";
        } else {
            finaldays = days + "d ";
        }
        if ((hours == 0)&&(finaldays == 0)) {
            finalhours = "";
        } else if (hours < 10) {
            finalhours = "0" + hours + "h ";
        } else {
            finalhours = hours + "h ";
        }
        if ((minutes == 0)&&(finalhours == 0)) {
            finalminutes = "";
        } else if (minutes < 10) {
            finalminutes = "0" + minutes + "m ";
        } else {
            finalminutes = minutes + "m ";
        }
        if (seconds < 10) {
            finalseconds = "0" + seconds + "s ";
        } else {
            finalseconds = seconds + "s ";
        }

        // Display the result in the element with id="demo"
        
        // If the count down is finished, write some text
        // if((action == 'play')&&(race_details_global[0]['results_viewable'] == 0)) {
        if((action == 'play')) {
            $("#race-countdown-timer-shell").hide();
            let new_video_html = <iframe src={"https://watch.exiledracers.com/#/"+props.raceID} style={{border:'0px',overflow:'hidden',borderRadius:'10px'}} width="100%" height="100%" scrolling="no" />;
            setVideoHTML(new_video_html);
        } else {
            if(distance <= 0) {
                $("#race-countdown-timer").html('---');
                // setTimeout(function(){ window.location.reload(); },10000);
            } else {
                if(current_timer_collection == selectedCollectionID) {
                    $("#race-countdown-title").html(sectiontitle);
                    $("#race-countdown-timer").html(finaldays + finalhours + finalminutes + finalseconds);
                    setTimeout(function() {
                        loadCountdownTimer(action,currenttime,sectiontitle);
                    },1000);
                }
            }
            
        }
    }

    return(<div className="row" style={{borderRadius:'10px',padding:'0px 0px 5px 0px',marginBottom:'0px'}}>
        <div className="col s12 l9 exr-dark-grey-bg" id="sb-viewer-left-box" style={{fontSize:'16px',padding:'0px',paddingTop:'0px',borderRadius:'10px',overflow:'hidden',marginBottom:'10px'}}>
            
            <div id="viewervbox" style={{position:'relative',width:'100%',overflow:'hidden',padding:'0px',borderRadius:'0px',height:'60vw',maxHeight:'72vh'}}>
                {/* <img src="css/images/loading_screen_blue_planet.gif" width="100%" height="100%"/> */}
                
                
                {/* <iframe src="https://watch.exiledracers.com/#/PW922J5EHx" style={{border:'0px',overflow:'hidden',borderRadius:'10px'}} width="100%" height="100%" scrolling="no" /> */}
                {/* <video id="waitingroom-bg" src="https://media0.giphy.com/media/xU5eJlXYuSC23SmrYL/giphy480p.mp4?cid=ecf05e47g5ptmo211rm18x5imi2bk3i2osnz5jx6gvhkpa8e&rid=giphy480p.mp4&ct=v" 
                style={{border:'0px',overflow:'hidden',borderRadius:'10px'}} width="100%" autoPlay loop muted /> */}
                

                <div id="race-video-player" style={{height:'100%',width:'100%'}}>
                    {current_video_html}
                </div>
                    {currentTimelineView}
                
                <div id="race-sponsor-shell" className="headline-text" style={{position:'absolute',zIndex:'100',bottom:'5%',left:'0%',width:'100%',fontSize:'20px'}}>
                    {race_sponsor_html}
                </div>
                <div id="race-viewer-block" style={{position:'absolute',zIndex:'90',top:'0px',left:'0px',width:'100%',height:'100%',background:'rgb(0,0,0,0.1)'}}>
                </div>
            </div>
            <div className="row" style={{margin:'0px',padding:'20px 30px 7px 25px',marginBottom:'5px'}}>
                <div className="row" style={{margin:'0px',marginBottom:'10px',lineHeight:'1',fontSize:'14px'}}>
                    <div className="left exr-light-green-text" style={{lineHeight:'1'}}>
                        NOW PLAYING
                    </div>
                    <div className="right headline-text hide-on-med-and-down show-on-large" style={{fontSize:'14px',lineHeight:'1'}}>
                        RACE DETAILS
                    </div>
                </div>
                <div className="row hide-on-med-and-down show-on-large" style={{margin:'0px',padding:'0px',marginBottom:'5px',lineHeight:'1'}}>
                    <div className="left headline-text" style={{fontSize:'20px',lineHeight:'1',height:'22px'}}>
                        {race_name}
                    </div>
                    <div className="right" style={{fontSize:'14px',lineHeight:'1',marginTop:'2px'}}>
                        {race_subtitle_details}
                        
                    </div>
                </div>
                <div className="row show-on-medium-and-down hide-on-large-only" style={{margin:'0px',padding:'0px',marginBottom:'10px',lineHeight:'1'}}>
                    <div className="left headline-text" style={{fontSize:'18px'}}>
                        {race_name}
                    </div>
                </div>
                <div className="row show-on-medium-and-down hide-on-large-only" style={{margin:'0px',padding:'0px',marginBottom:'0px',lineHeight:'1'}}>
                    <div className="left" style={{fontSize:'14px',lineHeight:'1',marginTop:'0px'}}>
                        {race_subtitle_details}
                        
                    </div>
                </div>
            </div>
        </div>
        <div className="col s12 l3" id="right-side-viewer-shell" style={{fontSize:'16px',padding:'2.5px',paddingLeft:'7px',paddingTop:'0px'}}>
            <div className="row exr-dark-grey-bg" id="sb-viewer-right-box" style={{margin:'0px',position:'relative',padding:'10px',borderRadius:'10px'}}>
                <div className="col s12 exr-new-dark-grey" style={{margin:'0px',padding:'0px 10px',lineHeight:'1',fontSize:'14px',marginTop:'0px',marginBottom:'0px',borderRadius:'10px'}}>
                    <div className="row" id="sb-viewer-right-box" style={{margin:'0px',position:'relative',padding:'20px 5px 7px 5px',borderRadius:'10px'}}>
                        <div className="left headline-text" style={{fontSize:'18px',lineHeight:'1',marginBottom:'0px'}}>
                            THE RACEWAY
                        </div>
                    </div>
                    <div className="row" style={{margin:'0px',marginBottom:'5px',padding:'0px',lineHeight:'1',fontSize:'14px'}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="row" style={{padding:'15px 15px',lineHeight:'1',borderRadius:'10px',marginBottom:'0px',fontSize:'12px'}}>
                        <div className="row" style={{margin:'0px',position:'relative',padding:'0px',borderRadius:'10px'}}>
                            <span id="exr-viewer-predict-toggle" 
                                className="left headline-text white-yellow-link white-yellow-link-active" style={{marginRight:'10px'}} 
                                onClick={()=>toggleViewerSections('predict')}>PREDICT</span>
                            <span id="exr-viewer-banter-toggle" 
                                className="left headline-text white-yellow-link" 
                                onClick={()=>toggleViewerSections('banter')}>BANTER</span>
                        </div>
                    </div>
                </div>
                <div className="col s12" id="exr-viewer-predict-box"  style={{display:'none',padding:'0px',lineHeight:'1',borderRadius:'10px',marginBottom:'0px',fontSize:'12px'}}>
                    <div id="chat-predict-display" style={{marginTop:'10px',borderRadius:'10px',width:'100%'}}>{predictRacersLineup}</div>
                    {/* {predictRacersLineupFooter} */}
                </div>
                <div className="col s12 exr-new-dark-grey" id="exr-viewer-banter-box"  style={{display:'none',marginTop:'10px',padding:'10px',lineHeight:'1',borderRadius:'10px',marginBottom:'0px',fontSize:'12px'}}>
                    <RaceChat raceID={selectedRaceID} racerNames={racer_handles} chatActive={chat_active} />
                </div>
                <div className="row right" style={{display:'none',position:'absolute',bottom:'30px',right:'30px',margin:'0px',marginBottom:'0px',lineHeight:'1',fontSize:'14px'}}>
                    <span className="exr-light-green-text">UP NEXT:</span> RACE #5678 // LONG DISTANCE 
                </div>
            </div>
        </div>            
    </div>);
};     