import {RacerMovementAnchor} from "./racerMovementAnchor.js";

/**
 * Racer movement data
 */
export class RacerMovement {
    static KEY_STRIDE = "stride";
    static KEY_ANCHORS = "anchors";
    static KEY_ANCHOR_DISTANCE = "total_distance";
    static KEY_ANCHOR_BOOSTING = "overtake_step"

    /**
     * Construct racer movement data
     * @param {Object} data Racer movement data
     */
    constructor(data) {
        const stride = data[RacerMovement.KEY_STRIDE];
        const anchors = data[RacerMovement.KEY_ANCHORS];

        this.anchors = [];

        for (let anchor = 0, anchorCount = anchors.length; anchor < anchorCount; ++anchor)
            this.anchors.push(new RacerMovementAnchor(
                anchor * stride,
                anchors[anchor][RacerMovement.KEY_ANCHOR_DISTANCE],
                anchors[anchor][RacerMovement.KEY_ANCHOR_BOOSTING]));
    }

    /**
     * Get the total length of recorded movement
     * @returns {number} The length
     */
    get length() {
        return this.anchors[this.anchors.length - 1].movement;
    }
}