import * as THREE from "three";
import $ from "jquery";
import {Racers} from "./racers/racers.js";
import {Minimap} from "./minimap/minimap.js";
import {TrackMap} from "./track/map/trackMap.js";
import {Leaderboard} from "./leaderboard/leaderboard.js";
import {LapInfo} from "./info/lapInfo.js";
import {TimeInfo} from "./info/timeInfo.js";
import {TrackProps} from "./track/props/trackProps.js";
import {Environment} from "./environment/environment.js";
import {Pointers} from "./pointers/pointers.js";
import {RaceDesignLocal} from "./design/raceDesignLocal.js";
import {Designed} from "./design/designed.js";
import {Globals} from "../global/globals.js";
import {RaceCameras} from "./raceCameras.js";
import {RacersEvents} from "./racers/racersEvents/racersEvents.js";
import {Stage} from "./stage/stage.js";
import {Clip} from "./clips/clip.js";
import {CameraControllerFinish} from "./camera/controllers/finish/cameraControllerFinish.js";
import {ClipRate} from "./clips/clipRate.js";
import {Effects} from "./effects/effects.js";
import {EffectFinishFlare} from "./effects/effectFinishFlare.js";
import {Audio} from "./audio/audio.js";

/**
 * The 3d race view
 */
export default class Race extends Designed {
    static FOV = 80;
    static Z_NEAR = .5;
    static Z_FAR = 12000;
    static UPDATES_PER_SECOND = 40;
    static UPDATE_RATE = 1 / Race.UPDATES_PER_SECOND;
    static FRAME_TIME_MAX = .1;
    static LEADERBOARD_DELAY = 500;

    static #CAMERA_FOCUS_SPRING = .2;
    static #GREEN_SCREEN_KEY = new THREE.Color("#29cc29");

    #clip = null;
    #replayingFinish = false;
    #hasReplayedFinish = false;
    #stageVisible = false;
    #stop = false;
    #blips = null;
    #playbackRate = 1;
    #soundEffects = true;
    #scene = new THREE.Scene();
    #sceneBackground = new THREE.Scene();
    #effects = new Effects(this.#scene);
    #camera = new THREE.PerspectiveCamera(
        Race.FOV,
        1,
        Race.Z_NEAR,
        Race.Z_FAR);
    #cameraBackground = this.#camera.clone();
    #cameraPosition = new THREE.Vector3();
    #cameraPositionPrevious = new THREE.Vector3();
    #cameraVelocity = new THREE.Vector3();
    #started = false;
    #ended = false;
    #cameraFocus = new THREE.Vector2();
    #cameraFocusTarget = this.#cameraFocus.clone();

    #renderer;
    #audio;
    #track;
    #minimap;
    #racers;
    #racersEvents;
    #pointers;
    #leaderboard;
    #lapInfo;
    #timeInfo;
    #environment;
    #trackProps;
    #stage;
    #cameras;
    #clipFinish;

    /**
     * Construct a race renderer
     * @param {RaceElements} elements The race elements
     * @param {RaceLoader} raceLoader The loaded race loader
     * @param {Random} random A randomizer
     * @param {boolean} [greenScreen] True if green screen mode should be enabled
     */
    constructor(elements, raceLoader, random, greenScreen = false) {
        super();

        this.#renderer = new THREE.WebGLRenderer({
            canvas: elements.canvas,
            antialias: !greenScreen,
            alpha: true,
            preserveDrawingBuffer: true
        });

        this.#renderer.autoClear = false;
        this.#renderer.outputColorSpace = THREE.SRGBColorSpace;

        if (greenScreen)
            this.#renderer.setClearColor(Race.#GREEN_SCREEN_KEY);

        Globals.CUBE_MAP_CACHE.setRenderer(this.#renderer);
        Globals.HDR_CACHE.setRenderer(this.#renderer);

        this.#track = raceLoader.track;
        this.#minimap = new Minimap(new TrackMap(raceLoader.track), elements.minimap);
        this.#racers = raceLoader.racers ? new Racers(this.#scene, raceLoader.racers, raceLoader.track, this.finishRacer.bind(this)) : null;
        this.#racersEvents = new RacersEvents(raceLoader.racers);
        this.#pointers = elements.players ? new Pointers(elements.players, this.#racers) : elements.players;
        this.#leaderboard = elements.leaderboard ? new Leaderboard(elements.leaderboard, this.#racers, raceLoader.track['race_type']) : elements.leaderboard;
        this.#timeInfo = elements.timeInfo ? new TimeInfo(elements.timeInfo) : elements.timeInfo;
        this.#environment = new Environment(this.#renderer, this.#scene, this.#sceneBackground, greenScreen);

        this.#racers?.load(this.#renderer);

        this.#audio = raceLoader.audio;
        this.#audio.initialize(this.#camera);
        this.#audio.initializeRacers(this.#racers);

        this.#lapInfo = elements.lapInfo ? new LapInfo(elements.lapInfo, this.#racers.player, raceLoader.track.length, raceLoader.track.laps, this.#audio) : elements.lapInfo;
        
        this.#trackProps = new TrackProps(
            raceLoader.design.track,
            raceLoader.track,
            this.#racers ? this.#racers.trackWidth : Racers.TRACK_WIDTH_DEFAULT,
            this.#scene,
            this.preRender.bind(this));
        this.#stage = new Stage(this.#racers, this.#track, this.#trackProps.trackWidth, this.#audio);
        this.#cameras = new RaceCameras(
            this.#camera,
            this.#environment,
            this.#track,
            elements.canvas,
            this.#racers,
            this.#stage,
            this.#audio);

        this.startRendering();
        this.load(raceLoader.design);

        const clipFinishRate = new ClipRate();

        clipFinishRate.addAnchor(0, 1);
        clipFinishRate.addAnchor(2, .4);
        
        this.#clipFinish = this.#racers ? new Clip(
            this.#racers.getTimeToFinish(this.#track.length * this.#track.laps),
            this.#racers.getTimeToFinish(this.#track.length * this.#track.laps) + 3,
            new CameraControllerFinish(
                this.#camera,
                this.#track.finishLineCameraPosition,
                new THREE.Vector3(),
                60),
            this.#racers.player,
            clipFinishRate,
            () => {
                this.#replayingFinish = false;
                this.#stageVisible = true;
                $("#racerbanner").fadeOut();
                this.endRace();
            }) : null;

        this.setSize(elements.canvas.width, elements.canvas.height);

        const selectableCameras = this.cameras.getSelectableCameras();

        window.addEventListener("keydown", event => {
            switch (event.key) {
                case "l":
                    this.toggleSoundEffects();

                    break;
                case "e":
                    this.endRace();

                    break;
                case "s":
                    this.setPlaybackRate(1 / 3);

                    break;
                case "a":
                    this.showPhotoFinish();

                    break;
                case "d":
                    this.setPlaybackRate(4);

                    break;
                case "f":
                    this.setFocusTarget(.5, 0);

                    break;
                case "1":
                    this.cameras.setForcedCamera(selectableCameras[4].controller);

                    break;
            }
        });
    }

    /**
     * Get the cameras object
     * @returns {RaceCameras} The race cameras object
     */
    get cameras() {
        return this.#cameras;
    }

    /**
     * Get the environment
     * @returns {Environment} The environment
     */
    get environment() {
        return this.#environment;
    }

    /**
     * Get the track props
     * @returns {TrackProps} The track props
     */
    get trackProps() {
        return this.#trackProps;
    }

    /**
     * Set the camera focus target
     * @param {number} dx The X target in the range [-1, 1]
     * @param {number} dy The Y target in the range [-1, 1]
     */
    setFocusTarget(dx, dy) {
        this.#cameraFocusTarget.set(dx, dy);
    }

    /**
     * Show the photo finish
     */
    showPhotoFinish() {
        this.#replayingFinish = true;
        $("#racerbanner").fadeIn(1000);
        var finaltxt = 'FINISH REPLAY';
        $("#racerbannertxt").html(finaltxt);

        setTimeout(function() {
            $("#leaderboardbox").fadeOut();
        },4000);

        this.activateClip(this.#clipFinish);
    }

    /**
     * Activate a clip
     * @param {Clip} clip The clip to activate
     */
    activateClip(clip) {
        this.#clip = clip;

        this.#racers.start();
        this.#started = true;

        this.seek(clip.start);
    }

    /**
     * Unload the current design, if any
     */
    unload() {
        super.unload();

        this.#environment.unload();
        this.#trackProps.unload();
    }

    /**
     * Update the design
     * @param {Object} [design] An new design
     */
    load(design) {
        super.load(design);

        if (design instanceof RaceDesignLocal) {
            design.load().then(() => {
                this.#environment.load(design.environment);
                this.#trackProps.load(design.track);

                if (this.#racers)
                    this.setBlips(this.#minimap.populate(this.#racers));
            });
        }
    }

    resizeMinimap(mmelement) {
        this.#minimap.depopulate();
        this.#minimap = new Minimap(new TrackMap(this.#track), mmelement);

        if (this.#racers)
            this.setBlips(this.#minimap.populate(this.#racers));
    }

    /**
     * Get the design in JSON format
     * @returns {Object} The design in JSON format
     */
    getDesign() {
        return {
            "track": this.#trackProps.getDesign(),
            "environment": this.#environment.getDesign()
        };
    }

    /**
     * Pre render the scene
     */
    preRender() {
        this.#camera.position.set(1, 0, 0);
        this.#camera.lookAt(0, 0, 0);
        this.#renderer.render(this.#scene, this.#camera);

        this.#camera.position.set(-1, 0, 0);
        this.#camera.lookAt(0, 0, 0);
        this.#renderer.render(this.#scene, this.#camera);

        this.#camera.position.set(0, 0, 1);
        this.#camera.lookAt(0, 0, 0);
        this.#renderer.render(this.#scene, this.#camera);

        this.#camera.position.set(0, 0, -1);
        this.#camera.lookAt(0, 0, 0);
        this.#renderer.render(this.#scene, this.#camera);
    }

    /**
     * Set the playback rate
     * @param {number} rate The playback rate factor
     */
    setPlaybackRate(rate) {
        this.#playbackRate = rate;
    }

    /**
     * Seek to a specific race time
     * @param {number} time The time in seconds
     */
    seek(time) {
        this.#racers.seek(Math.max(0, time));
        this.#cameras.teleportCamera = true;

        this.restartRace();
    }

    /**
     * A racer crosses the finish line
     * @param {Racer} racer The racer that finished
     * @param {boolean} last True if this is the last racer to finish
     */
    finishRacer(racer, last) {
        if (racer === this.#racers.player)
            this.#audio.getAudio(Audio.SFX_LAP_MARKER_FINISH).play();

        this.#leaderboard.finish(racer);

        if (last)
            setTimeout(this.endRace.bind(this), Race.LEADERBOARD_DELAY);
    }

    /**
     * Start the race intro
     * @param {function} onEndIntro A function to call when the intro has ended
     */
    startIntro(onEndIntro) {
        this.#cameras.startIntro(onEndIntro);
    }

    /**
     * Start lineup mode
     */
    startLineup() {
        this.#cameras.setLineup();
    }
    
    /**
     * Start the race
     */
    startRace() {
        this.#racers.start();
        this.#cameras.endIntro();
        this.#started = true;
        this.#audio.getAudio(Audio.SFX_CROWD_CHEER_FINAL).pause();
    }

    /**
     * End the race
     */
    endRace() {
        if (!this.#hasReplayedFinish) {
            this.#hasReplayedFinish = true;
            this.showPhotoFinish();

            return;
        }

        if (this.#stageVisible) {
            this.spawnFinalLeaderboard();
        }

        this.#ended = true;
        this.#cameras.setEnded();
        this.#pointers.setVisible(false);
        this.#audio.getAudio(Audio.SFX_CROWD_CHEER_FINAL).play();
        this.#stage.show(this.#scene);

        $("#raceprogress").fadeOut();
        if((window.innerWidth < 950 && window.innerHeight < 500)||(window.innerWidth < 750)) {
            $("#leaderboardbox").fadeOut();
            $("#finalboardboxmobile").fadeIn();
        } else {
            $("#leaderboardbox").fadeOut();

            setTimeout(() => {
                // $("#leaderboardbox").fadeIn();
                this.setFocusTarget(.2, 0);
            }, 6000);
        }
        $("#minimap").fadeOut();

        // this.showLeaderboard();
    }

    /**
     * Restart the race
     */
    restartRace() {
        this.#ended = false;
        this.#pointers.setVisible(true);
    }

    /**
     * Set the render target size
     * @param {number} width The width in pixels
     * @param {number} height The height in pixels
     */
    setSize(width, height) {
        this.#renderer.setSize(width, height);

        this.#camera.aspect = this.#cameraBackground.aspect = width / height;
        this.#camera.updateProjectionMatrix();
        this.#cameraBackground.updateProjectionMatrix();
    }

    /**
     * Set the blips to update
     * @param {Blip[]} blips The blips to update
     */
    setBlips(blips) {
        this.#blips = blips;
    }

    /**
     * Spawn a final standing GUI element
     * @param {THREE.Vector3} position The ship position while finishing
     * @param {number} standing The final standing
     */
    spawnFinalStanding(position, standing) {
        this.#audio.getAudio(Audio.SFX_FINISH_LASER).play();
        this.#effects.add(new EffectFinishFlare(position));
    }

    /**
     * Show Final Leaderboard
     */
    spawnFinalLeaderboard() {
        setTimeout(function(){
            if((window.location.href.indexOf("watch.exiled") >= 0)||(window.location.href.indexOf("watchlive") >= 0)) {
                $(".podiumbuttons").each(function(){
                    $(this).fadeOut();
                });
            } else {
                $(".podiumbuttons").each(function(){
                    $(this).fadeIn();
                });
            }
        },5000);

        if ((window.innerWidth < 950 && window.innerHeight < 500) || (window.innerWidth < 750)) {
            var mobilescreen = true;
            $("#leaderboardbox").fadeOut();
            $("#finalboardboxmobile").fadeIn();
        } else {
            setTimeout(function(){
                $("#finalboardbox").fadeIn();
            },5000);
            setTimeout(function(){
                var newflexheight = $(".final-result-flex-row").height();
                var newtextmargin = ((newflexheight-18)/2);
                $(".final-result-flex-row-text").each(function(){
                    $(this).css({marginTop:newtextmargin+'px'});
                });
                $(".final-result-flex-row-number").each(function(){
                    $(this).css({marginTop:newtextmargin-5+'px'});
                });
            },5100);
        }
        
    }

    /**
     * Update the state
     * @param {number} delta The time delta
     */
    update(delta) {
        if (this.#clip) {
            if (this.#racers.time > this.#clip.end) {
                this.#clip.onFinish();
                this.#clip = null;
            }
            else
                this.setPlaybackRate(this.#clip.rate.sample(this.#racers.time));
        }

        this.#stage.update();
        this.#trackProps.update();
        this.#racers?.update(
            delta,
            this.#replayingFinish ? this.spawnFinalStanding.bind(this) : null);
        this.#pointers?.update(
            this.#renderer.getContext().canvas.width,
            this.#renderer.getContext().canvas.height,
            delta);

        if (this.#clip)
            this.#clip.update(delta);
        else
            this.#cameras.update(delta);

        this.#leaderboard?.update(delta, this.#ended);
        this.#environment.update(delta);
        this.#effects.update();

        if (this.#started) {
            this.#timeInfo.increment(delta);
            this.#lapInfo.update(this.#soundEffects);
        }
    }

    /**
     * Render
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {number} delta The time delta
     */
    render(time, delta) {
        this.#cameraPositionPrevious.copy(this.#cameraPosition);
        this.#cameraPosition.copy(this.#camera.position);
        this.#cameraVelocity.copy(this.#cameraPosition).sub(this.#cameraPositionPrevious);

        this.#trackProps.updateTriggers(
            this.#cameraPosition,
            this.#cameraVelocity,
            this.#audio);

        this.#cameraFocus.lerp(this.#cameraFocusTarget, Race.#CAMERA_FOCUS_SPRING);
        this.#stage.render(time, delta);
        this.#trackProps.render(time);
        this.#environment.render(time);
        this.#effects.render(time);
        this.#racers?.render(time, delta, this.#blips, this.#audio);
        this.#audio.update();

        this.#pointers?.render(
            time,
            this.#camera,
            this.#renderer.getContext().canvas.width,
            this.#renderer.getContext().canvas.height);

        if (this.#clip)
            this.#clip.render(this.#racers, this.#track, time);
        else
            this.#cameras.render(time, delta);

        if (this.#cameraFocus.x !== 0 || this.#cameraFocus.y !== 0) {
            this.#camera.projectionMatrix.elements[8] = this.#cameraFocus.x;
            this.#camera.projectionMatrix.elements[9] = this.#cameraFocus.y;
        }

        this.#cameraBackground.copy(this.#camera);
        this.#cameraBackground.position.set(0, 0, 0);

        this.#renderer.clear();
        this.#renderer.render(this.#sceneBackground, this.#cameraBackground);
        this.#renderer.clearDepth();
        this.#renderer.render(this.#scene, this.#camera);
    }

    /**
     * Start rendering frames
     */
    startRendering() {
        let lastTime = performance.now();
        let updateTime = 0;

        const loop = time => {
            this.render(updateTime / Race.UPDATE_RATE, (time - lastTime) * .001);

            updateTime += Math.min(Race.FRAME_TIME_MAX, this.#playbackRate * .001 * Math.max(0, time - lastTime));

            while (updateTime > Race.UPDATE_RATE) {
                this.update(Race.UPDATE_RATE);

                updateTime -= Race.UPDATE_RATE;
            }

            lastTime = time;

            if (!this.#stop)
                requestAnimationFrame(loop);
        }

        requestAnimationFrame(loop);
    }

    /**
     * Stop rendering
     */
    stopRendering() {
        if (!this.#stop) {
            this.#stop = true;
            if(this.#minimap) {
                this.#minimap.depopulate();
            }
            if(this.#leaderboard) {
                this.#leaderboard.clear();
            }    
            if(this.#pointers) {
                this.#pointers.clear();
            }
        }
    }

    /**
     * Show the leaderboard
     */
    showLeaderboard() {
        setTimeout(function() {
            $("#raceprogress").fadeOut();
            $("#leaderboardbox").fadeOut();
            $("#minimap").fadeOut();
        },1000);

        setTimeout(function() {
            $("#finalboardbox").fadeIn(2000);
            // var fboxnum = 1;
            // $(".finish-screen-position").each(function() {
            //     fboxnum = fboxnum+1;
            // });

            // for(var z = 0;z < fboxnum;z++) {
            //     if(fboxnum <= 2) {
            //         $("#fboard"+z).fadeIn(500);
            //     } else {
            //         var fadeInTime = (z*1200)+1000;
            //         if(z > 2) {
            //             var fadeInTime = 5000;
            //             $("#fboard"+z).delay(fadeInTime).fadeIn(1500);
            //         } else {
            //             $("#fboard"+z).delay(fadeInTime).fadeIn(1500);
            //         }
            //     }
            // }

            if(window.innerWidth < 600) {
                $("#finalracetable").css({height:'84vh'});
            } else {
                $("#finalracetable").css({height:$("#finalracewinner").height()+'px'});
            }
            if(window.innerWidth < 1000) {
                $(".finish-screen-racer-ship").hide();
                $(".finish-screen-racer-stats").each(function(){
                    $(this).css({width:'100%',height:'15.5vw'});
                });
            } else {
                $(".finish-screen-racer-ship").show();
                $(".finish-screen-racer-stats").each(function(){
                    $(this).css({width:'49%',height:'10.5vw'});
                });
            }

            var newflexheight = $(".final-result-flex-row").height();
            var newtextmargin = ((newflexheight-18)/2);
            $(".final-result-flex-row-text").each(function(){
                $(this).css({marginTop:newtextmargin+'px'});
            });
            $(".final-result-flex-row-number").each(function(){
                $(this).css({marginTop:newtextmargin-5+'px'});
            });
        },1000);
    }

    /**
     * Set a different camera type
     * @param {string} name The name of the camera type
     */
    userCameraSelection(name) {
        this.#cameras.userCameraSelection(name);
    }

    /**
     * Toggle the sound effects
     */
    toggleSoundEffects() {
        this.#soundEffects = !this.#soundEffects;

        // TODO: Use new implementation
    }
}