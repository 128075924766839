import React, { useEffect, useState } from "react";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import $ from "jquery";

export const RaceTrainingLeaderboardBox = (props) => {

  return (<div id="training_box_leaderboard" className="modal page-modal page-modal-large">
        <div className="modal-content">
            <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                    X
                </div>
                <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                    <div className="left headline-text" style={{marginLeft:'5px'}}>
                      LEADERBOARDS
                    </div>
                </div>
                <div className="col s12" style={{padding:"0px 5px"}}>
                    <div className="divider-thin"></div>
                </div>
                <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                        Welcome to the EXR Leaderboards! Here you can find the top Racers by RPM and XP as well as the top Prophets correctly predicting races. 
                        <br/><br/><a className="exr-light-green-text">Training Rewards: </a> 
                        Your RPM and XP are your tickets to win rewards in training. 
                        The more RPM and XP you collect by the end of training the higher likelihood you have to win our training rewards game.
                        <br/><br/><a className="exr-light-green-text">Starblazer Badge: </a> 
                        The racers with the most RPM collected at the close of the EXR Training phase will earn the badge of Starblazer and have access to exclusive prizes. 
                        <br/><br/><a className="exr-light-green-text">Leaderboard Tiers: </a> 
                        Where you finish on the leaderboard may or may not determine which <a style={{color:'silver'}}>[REDACTED]</a> you are placed in once the EXR Training Phase is over and <a style={{color:'silver'}}>[REDACTED]</a> starts. 
                        <br/><br/><a className="exr-light-green-text">Prophet Rewards: </a> 
                        The Prophet status is one to be admired. Collect XP for correctly predicting races which give you a higher chance to get Prophet rewards. Top Prophets will earn badges for bragging rights.
                        <br/><br/>
                    </div>
            </div>
        </div>
      </div>
  );
};