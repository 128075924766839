import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import "../../league_landing.css";
import $ from "jquery";
import {LeagueRacedays, LeagueLeaderboard} from "../../Sections";

import {TrackLoader} from "../../../../../webgl/race/utils/trackLoader.js"
import { Track } from "../../../../../webgl/race/track/track.js";
import { TrackMap } from "../../../../../webgl/race/track/map/trackMap.js";
import { Minimap } from "../../../../../webgl/race/minimap/minimap.js";


let total_page_results = 25;
let current_pagenum = 0;
let sectionHTMLArray = [];

export const LeagueHome = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [league_id, setLeagueID] = useState(0);
    const [league_season_id, setLeagueSeasonID] = useState(0);
    const [leagueRacedaysHTML, setLeagueRacedaysHTML] = useState('');
    const [leagueTableHTML, setLeagueTableHTML] = useState('');

    var racer_items = [];
    function grabLeagueHome() {
        var league_info = props.league_info;
        var current_league_id = league_info[0]['league_id'];
        var current_season_id = league_info[0]['league_season_id'];
        let current_season_racedays = league_info[0]['season_racedays'];
        let current_season_image = league_info[0]['season_header_photo'];
        let current_season_name = league_info[0]['season_custom_name'];
        let current_season_number = league_info[0]['season_number'];
        let league_name = league_info[0]['league_name'];
        let current_season_members = league_info[0]['season_members'];
        let group_playoff_line_top = league_info[0]['playoffs_group_top'];
        let group_playoff_line_bottom = league_info[0]['playoffs_group_bottom'];
        var leaderboard_custom = league_info[0]['leaderboard_custom'];
        var leaderboard_tiebreaker = league_info[0]['leaderboard_tiebreaker']; 
        var leaderboard_final = league_info[0]['final_leaderboard_flag'];
        var league_accent_color = league_info[0]['display_accent_color'];

        var seasongroups = league_info[0]['season_groups'];
        var season_group_count = 1;
        if(seasongroups != null) {
            season_group_count = seasongroups.length;
        }

        setLeagueID(current_league_id);
        setLeagueSeasonID(current_season_id);
        loadSeasonLeaderboard(current_league_id,current_season_id,current_season_racedays,group_playoff_line_top,group_playoff_line_bottom,season_group_count,
                                    leaderboard_custom,leaderboard_tiebreaker,leaderboard_final,league_accent_color);
        loadSeasonRacedays(current_league_id,current_season_id,current_season_racedays,current_season_members,season_group_count);
    }

    function loadSeasonRacedays(leagueid,leagueseasonid,racedayarr,memberarr,groupcount) {
        setLeagueRacedaysHTML(<LeagueRacedays league_id={leagueid} league_season_id={leagueseasonid} 
            racedays={racedayarr} season_members={memberarr} 
            display_type='vertical'   view_source='league_home' group_count={groupcount} />);
    }

    function loadSeasonLeaderboard(leagueid,leagueseasonid,racedayarr,linetop,linebottom,groupcount,
                                        leaderboardcustom,leaderboardtiebreaker,leaderboardfinal,leagueaccentcolor) {
        setLeagueTableHTML(<LeagueLeaderboard league_id={leagueid} league_season_id={leagueseasonid} 
            racedays={racedayarr} playoff_line_top={linetop} playoff_line_bottom={linebottom} 
            display_type='vertical' view_source='league_home' group_count={groupcount} 
            custom_boards={leaderboardcustom} board_tiebreaker={leaderboardtiebreaker} board_final={leaderboardfinal}
            accent_color={leagueaccentcolor} />);
    }

    if(entrySet == false) {
        setEntry(true);
        grabLeagueHome();
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
                <div className="col s12 l7" style={{fontSize:"24px",margin:'0px',marginTop:"0px",padding:"0px"}}>
                    {leagueRacedaysHTML}
                </div>
                <div className="col s12 l5" style={{fontSize:"24px",margin:'0px',marginTop:"0px",padding:"0px"}}>
                    {leagueTableHTML}
                </div>
            </div>);
};