import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../splash_event.css";
import {BaseLeagueSplash} from "./Sections";

export const SplashBaseLeague = (props) => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, accountAddress } = useSelector((state) => state.account);

    const [pageSet, setPage] = useState(false);
    const [currentPage, setCurrentPage] = useState('');

    document.addEventListener('DOMContentLoaded', function() {
        
        if(props.currentPage == 'register') {
            setCurrentPage(<BaseLeagueSplash />);
        } else if(props.currentPage == 'results') {
            setCurrentPage(<BaseLeagueSplash />);
        } else {
            setCurrentPage(<BaseLeagueSplash />);
        }

        setTimeout(function(){
            window.startPageModals();
        },1000);
    });

    window.addEventListener('resize', function() {
        window.resetCenterHeight();
    });

    if(setPage === false) {
        pageSet(true);

        if(props.currentPage == 'register') {
            setCurrentPage(<BaseLeagueSplash />);
        } else {
            setCurrentPage(<BaseLeagueSplash />);
        }
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            {currentPage}
            </div>);
};