import React, { useEffect, useState } from "react";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import $ from "jquery";

export const RaceHowToBox = (props) => {
    if(props.open_race == 1) {
        return (<div id="training_box_race_howto" className="modal page-modal page-modal-large">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                          HOW TO RACE: OPEN RACES
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'10px',fontSize:'14px'}}>
                        <div className="row" style={{margin:'10px 0px',fontSize:'16px',marginBottom:'15px'}}>
                        Welcome to the races recruit! Here's a crash course on how to successfully setup a race.
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>1.&nbsp;&nbsp;Review The Open Race Details </div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                Learn whether the Race Type 
                                is a <a className="exr-light-green-text">Standard (first place wins)</a> or <a className="exr-light-green-text">Inverse (last place wins)</a> race and 
                                how many laps the race is. 
                                Examine the Race Track to help you pick your stack attributes. Review details on entry times, prediction times, race times and prize pool.
                            </div>
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>2.&nbsp;&nbsp;Enter Your Stack </div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                Choose your best combination of Pilot, Racecraft and Booster according to the Track Selection and Race Type. 
                                Gain <a className="exr-light-green-text">Genetic Boosts</a> for choosing items 
                                that belong to the same faction or the track's faction. 
                            </div>
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>3.&nbsp;&nbsp;Discover Your Assigned Race Group</div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                Once the entry time period ends, you'll 
                                <a className="exr-light-green-text">automatically</a> be placed into a group of up to 
                                <a className="exr-light-green-text">5 racers</a> who have stacks with a <a className="exr-light-green-text">similar performance level</a> on this track.
                                You'll be racing against this group for RPM and XP and also placing predictions on this race. 
                            </div>
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>4.&nbsp;&nbsp;Make a Prediction </div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                The prediction window for racers and spectators will open after entries close. 
                                Discover the odds of each racer's chances of winning the race. Place a prediction on who you think will win to earn XP and Prophet status.
                            </div>
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>5.&nbsp;&nbsp;Watch Simulated Race </div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                Once the prediction window closes, <a className="exr-light-green-text">the race is simulated one final time automatically</a> at the time outlined in your race details. 
                                Watch the race and banter with others live or on demand in the EXR Raceway. 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    } else {
        return (<div id="training_box_race_howto" className="modal page-modal page-modal-large">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                          HOW TO RACE
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'10px',fontSize:'14px'}}>
                        <div className="row" style={{margin:'10px 0px',fontSize:'16px',marginBottom:'15px'}}>
                        Welcome to the races recruit! Here's a crash course on how to successfully setup a race.
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>1.&nbsp;&nbsp;Setup Race Details </div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                Choose whether the you want to go Fast/Standard (first place wins) or Slow/Inverse (last place wins). Select options for the race prize pool and distribution.
                            </div>
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>2.&nbsp;&nbsp;Select Your Track </div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                Pick from your <a className="exr-light-green-text">4 Training Tracks</a> that were autogenerated and renew each 24 hour training period. 
                                {/* Your first <a className="exr-light-green-text">2 tracks are unlocked</a>. Unlock the remaining 2 tracks for <a className="exr-light-green-text">25 RPM</a>.  */}
                            </div>
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>3.&nbsp;&nbsp;Enter Your Stack </div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                Choose your best combination of Pilot, Racecraft and Booster according to your Track Selection and Race Type. 
                                Gain <a className="exr-light-green-text">Genetic Boosts</a> for choosing items 
                                that belong to the same faction or the track's faction. 
                            </div>
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>4.&nbsp;&nbsp;Make a Prediction </div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                Discover the odds of each racer's chances of winning the race. Place a prediction on who you think will win to earn XP and Prophet status.
                            </div>
                        </div>
                        <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                            <div style={{width:'100%',fontSize:'18px'}}>5.&nbsp;&nbsp;Simulate Race </div> 
                            <div style={{width:'100%',marginTop:'10px'}}>
                                Once all predictions are in, use the <a className="exr-light-green-text">Simulate Race</a> button to run the final race simulation and watch 
                                the exciting results. For Open Racing, the race will auto-simulate at the time outlined by the race. You'll then be able to watch the race in the EXR Raceway.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
};