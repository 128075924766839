import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import "../../../RacesHome/races_home.css";
import $ from "jquery";

let total_page_results = 25;
let current_racer_pagenum = 0;
let current_racer_timeseries = 'all-time';
let current_racer_sort = 'rpm';
let current_prophet_pagenum = 0;
let current_prophet_timeseries = 'current';
let current_prophet_sort = 'xp';
let racerSectionHTMLArray = [];
let prophetSectionHTMLArray = [];

export const PickemLeaderboard = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [racersSectionHTML, setRacerSectionHTML] = useState('');
    const [showMoreRacersHTML, setShowMoreRacersHTML] = useState('');
    const [prophetSectionHTML, setProphetSectionHTML] = useState('');
    const [showMoreProphetsHTML, setShowMoreProphetsHTML] = useState('');
    const [racerTimeSeries, setRacerTimeSeries] = useState('all-time');
    const [prophetTimeSeries, setProphetTimeSeries] = useState('current');
    const [invite_racer_name, setInviteRacerName] = useState('');
    const [user_game_points, setUserGamePoints] = useState('');
    const [user_prophet_points, setUserProphetPoints] = useState('');
    const [total_game_points, setTotalGamePoints] = useState('');
    const [total_prophet_points, setTotalProphetPoints] = useState('');
    const [user_referral_points,setReferralPoints] = useState('');

    const bot_pilots = ['https://media.exiledracers.com/assets/bots/PILOT_AUGMENT_BOT.png',
    'https://media.exiledracers.com/assets/bots/PILOT_MERCENARY_BOT.png',
    'https://media.exiledracers.com/assets/bots/PILOT_SERF_BOT.png'];

    var racer_items = [];

    function grabProphetLeaderboard(timeSort,pagenum) {
        current_prophet_pagenum = pagenum;
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.userID = encodeURIComponent(userID);
        addData.collectionID = encodeURIComponent(2);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_prophet_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);
        if((timeSort == 'current')&&(props.raceID != 'waitroom')) {
            addData.raceID = props.raceID;
        }
        
        axiosInstance.post('leaderboard/prophets_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data; 
                loadProphetLeaderboard(racer_details,pagenum);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function updateProphetBoardTime(newtime) {
        current_prophet_timeseries = newtime;
        setProphetTimeSeries(newtime);
        window.$(".prophet-board-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#prophet-lb-time-"+newtime).removeClass("white-green-link").addClass("white-green-link-active");
        grabProphetLeaderboard(newtime,0);
    }

    function updateProphetLeaderBoardSort(newsort) {
        current_prophet_sort = newsort;
        window.$(".prophet-row-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-red-link");
        });
        window.$("#prophet-lb-sort-"+newsort).removeClass("white-green-link").addClass("white-green-link-active");
        grabProphetLeaderboard(prophetTimeSeries,0);
    }

    function showMoreResultsProphet(pagenum) {
        $("#showmoreprophetsbutton").hide();
        $("#showmoreprophetsloading").show();
        grabProphetLeaderboard(current_prophet_timeseries,pagenum);
    }


    function loadProphetLeaderboard(racearr,pagenum) {
        
        var ranking_section_html = [];
        var pilotcnt = 0;
        for(var z = 0; z < racearr.length;z++) {
            var racer_id = racearr[z].racer_id;
            var racer_unique_id = racearr[z].racer_unique_id;
            var racer_name = racearr[z].racer_name;
            var total_rpm = racearr[z].rpm_earned;
            var total_xp = racearr[z].xp_earned;
            var racer_photo = racearr[z].racer_photo;

            /* Calculate Race Time */
            var total_predicted = racearr[z].total_predictions;
            var total_correct = racearr[z].total_predictions_correct;
            var correct_pct = Math.round((total_correct / total_predicted)*100);

            var star_icon = '';
            var rowcolor = 'white-text';
            var rowbgcolor = '';
            if(racer_id == racerID) {
                //star_icon = <i className="material-icons exr-red-text">star</i>
                rowcolor = 'exr-light-green-text';
                rowbgcolor = 'exr-red-bg';
            } 

            if((racer_photo == '')||(racer_photo == null)) {
                racer_photo = bot_pilots[Math.floor(Math.random(3))];
            }

            var pilot_image = <div className="exr-stats-light-grey" style={{width:'60px',height:'60px',borderRadius:'5px',marginBottom:'7px'}}></div>;
            if(racer_photo !== null) {
                pilot_image = <img src={racer_photo} width="60px" style={{borderRadius:"5px",lineHeight:'1'}} />;
            }
            
            var rowkey = z+1+(pagenum*total_page_results);
            var tmp_html = <div key={rowkey} className="row exr-new-dark-grey" style={{margin:'0px',padding:'0px',borderRadius:'10px',marginTop:'10px'}}>
                                <div className="col s9" style={{overflow:'hidden',padding:'0px'}}>
                                    <div className="left" style={{width:'200%'}}>
                                        <div className="left headline-text center-align" style={{fontSize:"14px",marginRight:"5px",letterSpacing:"-1px",width:"25px",marginTop:"27px",fontWeight:'900'}}>
                                            {rowkey}
                                        </div>
                                        <div className="left" style={{marginRight:"10px",width:"60px"}}>
                                            {pilot_image}
                                        </div>
                                        <div className="left headline-text" style={{marginTop:"27px",fontSize:'12px'}}>
                                            {racer_name.toUpperCase()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col s3 center-align" title="XP Earned" style={{marginTop:"25px",paddingRight:'5px',fontSize:'10px'}}>
                                    
                                    <div className="right" style={{fontSize:'10px',marginTop:'5px',marginRight:'10px'}}>XP</div>
                                    <div className="right" style={{fontSize:'16px',marginRight:'5px'}}>{total_xp.toLocaleString("en-US")}</div>
                                </div>
                            </div>;
            ranking_section_html.push(tmp_html);
            
        }

        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = prophetSectionHTMLArray.concat(ranking_section_html);
        } else {
            final_section_html_array = ranking_section_html;
        }
        prophetSectionHTMLArray = final_section_html_array;
        setProphetSectionHTML(final_section_html_array);
        
        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(racearr.length >= total_page_results) {
            showmorebutton = <div className="row white-text" style={{margin:'0px',fontSize:'14px',marginTop:'25px',marginBottom:'5px'}}>
                                <div id="showmoreprophetsbutton" className="center-align" title="Show More Prophets">
                                    <span className="round-border-button" style={{fontSize:'12px'}} title="Show More Prophets"  onClick={() => showMoreResultsProphet(nextpage) }>
                                        SHOW MORE
                                    </span>
                                </div>
                                <div id="showmoreprophetsloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreProphetsHTML(showmorebutton);
        
        setTimeout(function(){
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },500);
        setTimeout(function(){
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },2000);
    }

    window.$(document).ready(function(){
        var sel_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(sel_elems);
    });

    if(entrySet == false) {
        setEntry(true);
        setTimeout(function(){
            grabProphetLeaderboard(prophetTimeSeries,0);
        },1000);
        
    }

    function goToWorkshop() {
        window.location.href= '/#/workshop';
    }

    return(<div id="chat-leaderboard-display" className="col s12" style={{marginTop:'10px',padding:'0px',overflowX:'scroll',borderRadius:'10px'}}>
                        <div className="row" style={{margin:'0px',marginBottom:'0px',padding:'0px'}}>
                            <div className="row exr-new-dark-grey" style={{margin:'0px',padding:'15px 10px 10px',marginBottom:'0px',borderRadius:'10px'}}>
                                <div className="col s4 m6 headline-text white-text left-align" style={{fontSize:'14px',padding:'0px',marginTop:'0px'}}>
                                    LIVE RESULTS
                                </div>
                                <div className="col s8 m6 white-text right-align" style={{padding:'0px',fontSize:'12px'}}>
                                    
                                    <div id="prophet-lb-time-all-time" className="right white-green-link prophet-board-sort-link" style={{marginRight:'0px'}} 
                                        onClick={()=>updateProphetBoardTime('all-time')}>
                                        OVERALL
                                    </div>
                                    <div id="prophet-lb-time-current" className="right  white-green-link-active white-green-link prophet-board-sort-link" style={{marginRight:'10px'}} 
                                        onClick={()=>updateProphetBoardTime('current')}>
                                        THIS RACE
                                    </div>
                                </div>
                            </div>
                            {prophetSectionHTML}
                            <div className="col s12">
                                {showMoreProphetsHTML}
                            </div>
                        </div>
                    </div>);
};