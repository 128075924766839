import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import $ from "jquery";

export const StackInfoBox = (props) => {
  const dispatch = useDispatch();
  function toggleStackInfo(infobox) {
    if($("#stackbutton_"+infobox).html() == 'add') {
      $("#stackbutton_"+infobox).html('remove');
      $("#stackaccordian_"+infobox).slideDown();
    } else {
      $("#stackbutton_"+infobox).html('add');
      $("#stackaccordian_"+infobox).slideUp();
    }
  }

  return (<div id="stackinfobox" className="modal page-modal page-modal-large">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                          RACING STACKS
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                        EXR Racing Stacks are combinations of Pilots, Racecrafts and Boosters that racers enter to race. Each stack's unique 
                        attribute scores are used along with the Race Tracks attributes (straights, turns, laps, etc) to simulate the race using the EXR Racing Algorithm. 
                        <br/><br/> Check out the full <a target="_blank" href="https://blog.exiledracers.com/exr-pilot-attributes-details-revealed-daaaf811d12" className="exr-light-green-text">
                        Pilot
                        </a> and <a target="_blank" href="https://blog.exiledracers.com/racecraft-stats-attributes-revealed-c6ae976ab79e" className="exr-light-green-text">
                          Racecraft</a> deep dive blog posts to learn more. Here's a cheat sheet on how racing stacks work.
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                      <div className="row" style={{marginBottom:'0px',position:'relative'}}>
                        <div className="col s12 m6" style={{padding:'0px 5px'}}>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_genetic" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('genetic')}>add</span>
                                GENETIC BOOST 
                              </div>
                              <div className="row" id="stackaccordian_genetic" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                                Genetic Boosts are applied when the Pilot and Racecraft are from the same faction or match the Track's faction. 
                                Genetic Boosts add <a className="exr-yellow-text">3 points</a> towards <a className="exr-yellow-text">Handling</a> and <a className="exr-yellow-text">Focus.</a>
                              </div>
                            </div>
                          </div>
                          <div className="col s12 m6" style={{padding:'0px 5px'}}>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_gmax" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('gmax')}>add</span>
                                GENETIC BOOST MAX
                              </div>
                              <div className="row" id="stackaccordian_gmax" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                              Genetic Boost Max is applied when the Pilot, Racecraft and Booster (or Track) are from the same faction. 
                              Genetic Boost Max adds <a className="exr-yellow-text">4 points</a> towards <a className="exr-yellow-text">Handling</a> and <a className="exr-yellow-text">Focus.</a>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                          <div className="col s12 m6" style={{padding:'0px 5px'}}>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_speed" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('speed')}>add</span>
                                SPEED // <a className="exr-light-green-text">STRAIGHTS</a> 
                              </div>
                              <div className="row" id="stackaccordian_speed" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                                The Racecraft Speed attribute determines the max distance a race craft will move in a straight track segment. 
                                This attribute depletes over time based on the ship's strength.
                              </div>
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_handling" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('handling')}>add</span>
                                HANDLING // <a className="exr-light-green-text">TURNS</a> 
                              </div>
                              <div className="row" id="stackaccordian_handling" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                              Racecraft Handling attribute controls the max distance traveled on a turn segment. 
                              This attribute depletes over time based on the ship's strength. 
                              </div>
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_acceleration" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('acceleration')}>add</span>
                                ACCELERATION // <a className="exr-light-green-text">SPEED SHIFTS</a> 
                              </div>
                              <div className="row" id="stackaccordian_acceleration" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                              The Acceleration attribute determines how fast a ship shifts from its starting or turn speed to its straight line speed. 
                              The higher your acceleration score, the faster your Racecraft can shift into a higher speed on the track.
                              </div>
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_strength" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('strength')}>add</span>
                                STRENGTH // <a className="exr-light-green-text">LAPS</a> 
                              </div>
                              <div className="row" id="stackaccordian_strength" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                              As the race goes on, the racecrafts max speed and handling will decrease based on the racecrafts Strength attribute. 
                              Higher levels of Strength mean that a racecraft will be able to hit its max Speed and Handling for longer in the race.
                              </div>
                            </div>
                          </div>
                          <div className="col s12 m6" style={{padding:'0px 5px'}}>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_focus" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('focus')}>add</span>
                                FOCUS // <a className="exr-light-green-text">ALL</a> 
                              </div>
                              <div className="row" id="stackaccordian_focus" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                              The Focus attribute controls the randomness of your Racecrafts movements. 
                              The more Focus your pilot has, the lower the randomness, ensuring that your Racecraft will move at close to its maximum potential.
                              </div>
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_stamina" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('stamina')}>add</span>
                                STAMINA // <a className="exr-light-green-text">LAPS</a> 
                              </div>
                              <div className="row" id="stackaccordian_stamina" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                              Throughout the race your Pilot's focus decreases based on how much stamina you have left. 
                              The lower your stamina, the more your focus depletes meaning other racers might be able to pass you in the final stages of the race.
                              </div>
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_aggression" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('aggression')}>add</span>
                                AGGRESSION // <a className="exr-light-green-text">RACERS</a> 
                              </div>
                              <div className="row" id="stackaccordian_aggression" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                                Aggression controls a racers ability to overtake other racers or create seperation.  
                                Using aggression temporarily increases your Pilots focus levels to allow your ship to burst past others but reduces your Pilot's stamina.
                                The more aggression your pilot has, the longer the overtake burst lasts.
                              </div>
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'0px',marginBottom:'10px',padding:'15px 20px',borderRadius:'10px'}}>
                              <div className="row" style={{margin:'0px'}}>
                                <span id="stackbutton_intel" style={{marginLeft:'-10px',marginRight:'10px',marginTop:'-1px'}} 
                                  className="left material-icons material-symbols-outlined white-green-link" 
                                  onClick={()=>toggleStackInfo('intel')}>add</span>
                                INTELLIGENCE // <a className="exr-light-green-text">TIME</a> 
                              </div>
                              <div className="row" id="stackaccordian_intel" style={{margin:'0px',fontSize:'14px',marginTop:'10px',display:'none'}}>
                                Intelligence controls when and how your Pilot uses its aggression bursts. Pilots with higher intelligence will be able to recognize an overtake opportunity and chooses the best time to use aggression.
                              </div>
                            </div>
                          </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};