import {LoadTracker} from "../../global/loadTracker.js";
import {Audio} from "../audio/audio.js";
import Race from "../race.js";

/**
 * The race loader
 */
export class RaceLoader {
    /**
     * Load a race
     * @param {Track} track Track data
     * @param {RacersData | null} racers Racer data, or null if there are no racers
     * @param {RaceDesign} design The race design
     * @param {RaceElements} elements The race elements
     * @param {Random} random The randomizer
     */
    constructor(track, racers, design, elements, random) {
        this.audio = new Audio();
        this.design = design;
        this.track = track;
        this.racers = racers;
        this.design = design;
        this.elements = elements;
        this.random = random;
        this.aborted = false;
    }

    /**
     * Abort loading progress
     */
    abort() {
        this.aborted = true;
    }

    /**
     * Start loading
     * @param {function} [onProgress] A function that reports loading progress
     * @returns {Promise} A promise that resolves when loading completes
     */
    load(onProgress = () => {}) {
        return new Promise((resolve, reject) => {
            let loading = this.racers ? 4 : 3;
            let race = null;

            const progressListener = progress => {
                onProgress(progress);

                if (progress === 1) {
                    if (this.aborted)
                        reject();
                    else
                        resolve(race);
                }
            };

            const load = () => {
                if (!--loading) {
                    if (this.aborted) {
                        LoadTracker.removeProgressListener(progressListener);

                        reject();
                    }
                    else
                        race = new Race(
                            this.elements,
                            this,
                            this.random,
                            new URLSearchParams(window.location.href).get("greenScreen") !== null);
                }
            }

            this.track.load().then(() => {
                load();
            });

            this.racers?.load().then(() => {
                load();
            });

            this.design.load().then(() => {
                load();
            });

            this.audio.load().then(() => {
                load();
            });

            LoadTracker.addProgressListener(progressListener);
        });
    }
}