import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import appReducer from "./App/reducer";
import accountReducer from "./Account/reducer";
import authReducer from "./Auth/reducer";
import Web3Reducer from "./Web3/reducer";

const reducer = combineReducers({
  app: appReducer,
  account: accountReducer,
  auth: authReducer,
  web3: Web3Reducer,
});

const composeEnhancers = composeWithDevTools({
  realtime: true,
  name: "EXR",
  hostname: "localhost",
  port: 3000, // the port your remotedev server is running at
});

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
export default store;