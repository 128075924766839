import {RacerTurbulenceComponent} from "./racerTurbulenceComponent.js";

/**
 * Hover turbulence
 */
export class RacerTurbulenceComponentHover extends RacerTurbulenceComponent {
    /**
     * Construct a hover turbulence component
     * @param {number} frequency The hover frequency
     * @param {number} amplitude The hover amplitude
     */
    constructor(frequency, amplitude) {
        super();

        this.frequency = frequency;
        this.amplitude = amplitude;
    }

    /**
     * Apply turbulence
     * @param {Vector3} vector A vector to apply turbulence to
     * @param {Quaternion} rotation A rotation to apply rotation to
     * @param {number} moved The amount the ship has moved
     */
    apply(vector, rotation, moved) {
        vector.y += Math.sin(moved * this.frequency) * this.amplitude;
    }
}