import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import "./signup_flow.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation, signupLoading } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import {LoginBox} from "../../Organisms"
import { NoToneMapping } from "three";
import {InjectedConnector, } from "@web3-react/injected-connector";
import $ from "jquery";

let web3;

export const SignupFlow = () => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { chainId, account } = useWeb3React();

    const [pageSet, setPage] = useState(false);
    const [racer_handle, setRacerHandle] = useState('');
    const [racer_email, setRacerEmail] = useState('');
    const [racer_discord, setRacerDiscord] = useState('');
    const [start_email, setStartRacerEmail] = useState('');
    const [start_handle, setStartRacerHandle] = useState('');
    const [start_discord, setStartRacerDiscord] = useState('');

    let bg_image_side = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
    let bg_image = 'https://media.exiledracers.com/assets/game/builder/Splash_Background_-_Serf_Centered_1_1665676555319.jpeg';
    let logo_image = 'https://media.exiledracers.com/global/exr_logo_white_stacked.png';

    function grabRacerInfo() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;
                setRacerHandle(result.data[0].racer_name);
                setRacerEmail(result.data[0].racer_email);
                setRacerDiscord(result.data[0].racer_discord);
                setStartRacerHandle(result.data[0].racer_name);
                setStartRacerEmail(result.data[0].racer_email);
                setStartRacerDiscord(result.data[0].racer_discord);
                window.M.updateTextFields();
            }
        }).catch(error => {
            console.log(error);
            //dispatch(updateLocation("staging"));
        });
    }

    function trainingContinue(page) {
        if((page == 1)||(page == 5)) {
            $("#page"+page+"submit").hide();
            $("#page"+page+"loading").show();
            setTimeout(function(){
                $("#signuppage"+page).fadeOut(2000);
            },3000);
            setTimeout(function(){
                $("#signuppage"+(page+1)).fadeIn(2000);
            },6000);
        } else {
            var raceremail = document.getElementById("settingsEmail").value;
            var racerhandle = document.getElementById("settingsRacerHandle").value;
            var racerdiscord = document.getElementById("settingsRacerDiscord").value;

            var addData = new Object();
            addData.racerID = encodeURIComponent(racerID);
            addData.walletID = encodeURIComponent(accountAddress);
            if((start_email !== raceremail)&&(page==4)) {
                addData.racerEmail = encodeURIComponent(raceremail);
            }
            if((start_handle !== racerhandle)&&(page==2)) {
                addData.racerHandle = encodeURIComponent(racerhandle);
            }
            if((start_discord !== racerdiscord)&&(page==3)) {
                addData.racerDiscord = encodeURIComponent(racerdiscord);
            }
            if((start_handle !== racerhandle)&&(racerhandle.length < 3)&&(page==2)) {
                alert('Pick a Longer Racer Handle. Handles Must be atleast 3 Letters or Numbers Long.');
            } else if(racerhandle.length < 3) {
                alert('Pick a Longer Racer Handle. Handles Must be atleast 3 Letters or Numbers Long.');
            } else {
                $("#page"+page+"submit").hide();
                $("#page"+page+"loading").show();
                axiosInstance.post('member/settings_update/',addData).then(result => {
                    if(Object.keys(result.data).length > 0) {
                        var save_result = result.data;
                        if(save_result.save_status.includes("success")) {
                            if(page == 2) {
                                updateSignupCompleted();
                                updateNewRacerTracks();
                            }
                            setTimeout(function(){
                                $("#signuppage"+page).fadeOut(2000);
                            },3000);
                            setTimeout(function(){
                                $("#signuppage"+(page+1)).fadeIn(2000);
                            },6000);
                        } else {
                            $("#page"+page+"submit").show();
                            $("#page"+page+"loading").hide();
                            if(save_result.save_status.includes("error_email")) {
                                alert('This Email is Already Taken. Update and Try Again.');
                            } else if(save_result.save_status.includes("error_handle")) {
                                alert('This Racer Handle is Already Taken. Update and Try Again.');
                            } else if(save_result.save_status.includes("error_discord")) {
                                alert('This Discord Handle is Already Signed Up. Update and Try Again.');
                            } else {
                                alert('Whoops! Something Went Wrong. Update and Try Again');
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }

    function updateSignupCompleted(){
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/signup_update/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                let signup_saved = true;
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function updateNewRacerTracks() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/signup_initial/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                let signup_saved = true;
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function skipTrainingContinue(page) {
        $("#signuppage"+page).fadeOut(1500);
        setTimeout(function(){
            $("#signuppage"+(page+1)).fadeIn(2000);
        },3000);
    }

    function startTraining(page) {
        $("#page"+page+"submit").hide();
        $("#page"+page+"loading").show();
        setTimeout(function(){
            $("#signuppage"+page).fadeOut(2000);
        },3000);
        
        setTimeout(function() {
            dispatch(signupLoading(true));
        }, 4000);
        setTimeout(function() {
            dispatch(updateLocation("races_home"));
            window.location.href = '/#/races_home';
        }, 5000);
    }

    function onEmailTextChange(e){
        setRacerEmail(e.target.value);
    }

    function onHandleTextChange(e){
        setRacerHandle(e.target.value);
    }

    function onDiscordTextChange(e){
        setRacerDiscord(e.target.value);
    }

    if(pageSet == false) {
        setPage(true);
        grabRacerInfo();
        setTimeout(function(){
            $("#signup-main-section").fadeIn(2000);
        });
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row" id="main-landing-page">
            <div id="splash-image-container" style={{width:'100%',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-20',background:'url('+bg_image+')',backgroundSize:'cover',backgroundPosition:'center'}}>
            </div>
            <div id="signup-main-section" className="col s12 transparent" style={{marginTop:'5vh',position:'relative',zIndex:'10',display:'none'}}>
                <div className="row center-align" style={{marginBottom:'10px'}}>
                    <div className="col s12 m10 offset-m1 l4 offset-l4">
                        <img id="exr-logo-button" style={{cursor:'pointer',marginLeft:'-20px',marginTop:'3vh',minWidth:'250px'}} title="Exiled Racers" width="50%" src={logo_image} />
                    </div>
                </div>
                <div className="row white-text" style={{position:'relative'}}>
                    <div id="signuppage1" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'10px'}}>CONFIDENTIAL | EXR TRAINING</div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            The Supercorps continue to tear civilizations apart across galaxies — there are too many of us that remain trapped in the Old World...war, slavery and destruction — all for Harumi profits. It has to stop. 
                            <br/><br/>
                            EXR recruit, you're joining an elite group of racers from the newly freed factions (Augs, Serfs, and Mercs) under the common banner of Exiled Racers. 
                            <br/><br/>
                            Over the coming months, you brave select few will be training with EXR trainers and other racers in a program designed to help break the chains of The Old World across the galaxy and save the many that remain helpless.
                            <br/><br/>
                            <i>— Knowing This, Do You Wish To Continue?</i>
                        </div>
                        <div className="col s12" style={{marginTop:'30px'}}>
                            <div id="page1submit" className="right" title="Continue to Training">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(1)}>
                                    CONTINUE
                                </span>
                            </div>
                            <div id="page1loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage2" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>CREATE RACING HANDLE</div>
                            <div className="right" style={{marginRight:'5px',fontSize:'14px',marginTop:'3px'}}>1 / 3</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Throughout your training 
                            and in future missions it will be important to have a call handle that everyone can know you by. Choose wisely
                            as this racing handle will be known to all in the program and be tied to your glory.
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Your Racer Handle:
                            </div>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                                <input value={racer_handle} 
                                        onChange={onHandleTextChange} 
                                        id="settingsRacerHandle" 
                                        type="text" 
                                        className="browser-default white-bubble-input" 
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'50px',marginBottom:'10px'}}>
                            <div id="page2submit" className="right" title="Continue to Training">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>trainingContinue(2)}>
                                    SAVE & CONTINUE
                                </span>
                            </div>
                            <div id="page2loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage3" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>ADD DISCORD HANDLE</div>
                            <div className="right" style={{marginRight:'5px',fontSize:'14px',marginTop:'3px'}}>2 / 3</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Without communication
                            Harumi and the evil megacorps will divide us and pick us off one by one. EXR has setup an encrypted Discord 
                            server undiscoverable to the Megacorps. Enter your EXR Discord handle to join the fight.
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Discord Handle:
                            </div>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                                <input value={racer_discord} 
                                        onChange={onDiscordTextChange} 
                                        id="settingsRacerDiscord" 
                                        type="text" 
                                        className="browser-default white-bubble-input" 
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div id="page3submit" title="Continue to Training" type="submit" className="round-border-button right" style={{fontSize:'18px'}} onClick={()=>trainingContinue(3)}>
                                    SAVE & CONTINUE
                            </div>
                            <div type="submit" className="headline-text white-red-link" style={{fontSize:'14px',paddingTop:'8px'}} title="Continue to Training" onClick={()=>skipTrainingContinue(3)}>
                                SKIP
                            </div>
                            <div id="page3loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage4" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>EMAIL ADDRESS</div>
                            <div className="right" style={{marginRight:'5px',fontSize:'14px',marginTop:'3px'}}>3 / 3</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            In case Harumi or one of the Megacorps
                            compromise us, we have to revert back to ancient technology that only a select few still use. Add 
                            your email address below to keep track of major announcements from the rebellion and for remote access to the races. 
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                                Email Address:
                            </div>
                            <div className="row" style={{padding:'0px 10px',marginBottom:'0px'}}>
                                <input value={racer_email} 
                                        onChange={onEmailTextChange} 
                                        id="settingsEmail" 
                                        type="email" 
                                        className="browser-default white-bubble-input"
                                        style={{width:'98%'}} />
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div id="page4submit" title="Continue to Training" type="submit" className="round-border-button right" style={{fontSize:'18px'}} onClick={()=>trainingContinue(4)}>
                                    SAVE & CONTINUE
                            </div>
                            <div type="submit" className="headline-text white-red-link" style={{fontSize:'14px',paddingTop:'8px'}} title="Continue to Training" onClick={()=>skipTrainingContinue(4)}>
                                SKIP
                            </div>
                            <div id="page4loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signuppage5" className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{display:'none',marginBottom:'5px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                        <div className="col s12" style={{fontSize:"20px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>REGISTRATION COMPLETE</div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                            Congratulations <a className="exr-light-green-text">{racer_handle}</a> you are now a trainee. 
                            <br/><br/>We've taken your belongings to <a className="exr-light-green-text">The Workshop</a> where you'll find your ships, boosters and 
                            other important assets. Check them out but don't take too long as it looks like there are open races for you to join. Challenge your EXR Trainer at&nbsp;
                            <a className="exr-light-green-text">The Racetrack</a> to build your skills.
                            <br/><br/>
                            Fly Safe Recruit...
                        </div>
                        <div className="col s12" style={{marginTop:'30px',marginBottom:'10px'}}>
                            <div id="page5submit" className="right" title="Continue to Training">
                                <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Continue to Training" onClick={()=>startTraining(5)}>
                                    START TRAINING
                                </span>
                            </div>
                            <div id="page5loading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};
