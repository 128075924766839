import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import {PubRaceLeaderboard} from "../RaceLeaderboard";
import {FinalResultEmbedScreen} from "../../../RaceTrack/Sections/FinalResultsEmbed";

import "../../../RacesHome/races_home.css";
import $ from "jquery";

let total_page_results = 25;
let current_pagenum = 0;
let sectionHTMLArray = [];
let current_racer_global = 0;
let racer_sim_watched_global = 0;
let selectedRaceID = '';
let race_details_global = [];
let racer_details_global = [];
let chat_active = 1;
let current_group_id = 6;
let countdown_timer_on = 0;
let countdown_currenttime = new Date().getTime();
let initialload = 0;

export const PubRaceInfo = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [sectionHTML, setSectionHTML] = useState('');
    const [showMoreHTML, setShowMoreHTML] = useState('');
    
    const [current_video_html, setVideoHTML] = useState('');
    const [race_details, setRaceDetails] = useState([]);
    const [racer_details, setRacerDetails] = useState([]);
    const [racer_status, setRacerStatus] = useState({});
    const [racer_creator_id, setRacerCreator] = useState(0);
    const [racer_invite_status, setRacerInviteStatus] = useState('N');
    const [racer_entry_status, setRacerEntryStatus] = useState('N');
    const [race_simulated, setRaceSimStatus] = useState('N');
    const [racer_sim_watched, setRacerSimWatched] = useState('N');
    const [race_landing_html, setRaceLandingHTML] = useState('');
    const [race_name, setRaceName] = useState('');
    const [race_subtitle_details, setRaceSubtitleDetails] = useState('');
    const [race_current_status,setRaceCurrentStatus] = useState('');
    const [race_sponsor_html, setRaceSponsorHTML] = useState('');
    const [race_result_html, setRaceResultHTML] = useState('');
    const [predictRacersLineup,setPredictRacersLineup] = useState('');
    const [predictRacersLineupFooter, setPredictRacersLineupFooter] = useState('');
    const [racer_handles, setRacerHandles] = useState([]);
    const [race_game_type, setRaceType] = useState('');
    const [race_entry_close_text, setRaceEntryCloseText] = useState('');
    const [race_predictions_close_text, setRacePredictionCloseText] = useState('');
    const [race_exr, setRaceEXR] = useState('');
    const [race_xp, setRaceXP] = useState('');
    const [race_entry_text, setRaceEntryText] = useState('');
    const [racer_count,setRacerCount] = useState('');
    const [raceTime, setRaceTime] = useState('');
    const [race_prize_type,setRacePrizeType] = useState('');
    const [race_prize_dist,setRacePrizeDist] = useState('');
    const [final_tier_tooltip,setFinalTierTooltop] = useState('');

    var tmpspinner = `<div class="preloader-wrapper small active">
                                <div class="spinner-layer spinner-red-only">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="gap-patch">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>`;

    if (props.raceID != selectedRaceID) {
        selectedRaceID = props.raceID;
        setEntry(false);
    }
    
    
    var racer_items = [];
    function grabRaceDetails() {
        if((selectedRaceID == 'waitroom')||(selectedRaceID == '')) {
            loadWaitroom();
        } else {
            var addData = new Object();
            var tmp_uniqud_id = selectedRaceID;
            addData.raceUniqueID = encodeURIComponent(tmp_uniqud_id);
            adjustRightBoxHeights();
            axiosInstance.post('race/details_list/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var race_details = result.data; 
                    race_details_global = race_details;               
                    setRaceDetails(race_details[0]);
                    grabRaceParticipants();
                    chat_active = 1;
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function grabRaceParticipants() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(selectedRaceID);
        addData.predictorID = userID;
        if(race_details_global[0].race_simulated == 1) {
            addData.sortBY = 'standings';
        } else {
            addData.sortBY = 'predicted';
        }
        current_racer_global = 0;
        racer_sim_watched_global = 0;
        adjustRightBoxHeights();
        axiosInstance.post('race/prediction_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_list = result.data;
                var current_racer_status = {};
                for(var i = 0;i < racer_list.length;i++) {
                    if(racer_list[i].racer_id == racerID) {
                        current_racer_status = racer_list[i];
                        current_racer_global = 1;
                        setRacerEntryStatus('Y');
                        if(racer_list[i].racer_sim_watched == 1) {
                            setRacerSimWatched('Y');
                            racer_sim_watched_global = 1;
                        }
                    }
                }
                setRacerStatus(current_racer_status);
            }
            setRacerDetails(result.data);  
            racer_details_global = result.data;
            setTimeout(function(){
                loadRaceInformation();
            },500);          
        }).catch(error => {
            console.log(error);
        });
    }

    function loadWaitroom() {
        setTimeout(function() {
            adjustRightBoxHeights();
        },50);
        setRaceName('EXR RACE PRESHOW');
        setRaceSubtitleDetails('STANDARD // REVERSE // LONG DISTANCE');
        let new_video_html = <video id="waitingroom-bg" src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExZmVhNGFlZTY5YWMxM2UwNDJlZTBmNDA1ZDdjMDhhNTIyNTJjM2E3MSZjdD1n/WUyQbeKHhpaHrrKJu6/giphy.mp4" 
        style={{border:'0px',overflow:'hidden',borderRadius:'10px'}} width="100%" autoPlay loop muted />;
        setVideoHTML(new_video_html);
        // setTimeout(function(){
        //     $("#waitingroom-bg").playbackRate = 0.5;
        // },100);
        // if(props.countdownInfo) {
        //     loadCountdownTimer(props.countdownInfo['action'],props.countdownInfo['time'],props.countdownInfo['title']);
        // } else {
        //     loadCountdownTimer('wait','19:30:00','RACE 1 STARTS IN');
        // }

        $("#race-countdown-title").html('LIVE PREDICTIONS OPEN NOW');
        $("#race-countdown-date").html('NEXT RACE STARTING');
        if(countdown_timer_on == 0) {
            var nextRaceIn = new Date().getTime();
            countdown_currenttime = nextRaceIn;
            //countdown_timer_on = 1;
        }
        
        
        //loadSponsorGrid();
        loadPredictionBoxBottom();
        loadWaitingroomPredictBanner();
    }

    function loadRaceInformation() {
        setTimeout(function() {
            adjustRightBoxHeights();
        },50);
        var new_race_name = race_details_global[0]['race_name'].toUpperCase()+" #"+race_details_global[0]['race_id'];
        var new_racers_count = racer_details_global.length;
        var new_race_laps = race_details_global[0]['race_laps'];
        var new_race_type = race_details_global[0]['race_scoring'];
        
        var new_race_subtitle_details = '';
        new_race_subtitle_details = new_race_subtitle_details+new_race_laps+' LAPS // ';
        new_race_subtitle_details = new_race_subtitle_details+new_race_type.toUpperCase()+' RACE';

        var new_race_preface = 'NEXT RACE';
        var new_race_status = <span className="exr-yellow-text">PREDICTIONS OPEN</span>;
        if(race_details_global[0]['race_simulated'] == 1) {
            new_race_status = <span className="exr-light-green-text">COMPLETED</span>;
            new_race_preface = 'LAST RACE';
        }

        setRaceCurrentStatus(new_race_status);
        setRaceName(new_race_name);
        setRaceSubtitleDetails(new_race_subtitle_details);
        loadRacerLineup();
        // if(props.countdownInfo) {
        //     loadCountdownTimer(props.countdownInfo['action'],props.countdownInfo['time'],props.countdownInfo['title']);
        // } else {
        //     loadCountdownTimer('wait','19:30:00','RACE 1 STARTS IN');
        // }
        //loadSponsorGrid();
        loadPredictionBoxBottom();

        // $("#race-countdown-timer-shell").hide();
        // $("#race-viewer-block").hide();
        // let new_video_html = <iframe src={"https://watch.exiledracers.com/#/"+props.raceID} style={{border:'0px',overflow:'hidden',borderRadius:'10px'}} width="100%" height="100%" scrolling="no" />;
        // setVideoHTML(new_video_html);

        if(race_details_global[0]['race_simulated'] == 1) {
            $("#race-result-shell").show();
            $("#race-countdown-timer-shell").hide();
            $("#race-sponsor-shell").hide();
            setRaceResultHTML(<FinalResultEmbedScreen race_unique_id={race_details_global[0]['race_unique_id']} />);
            setTimeout(function(){
                $("#finalboardbox").fadeIn();
                updateRaceTableSizes();
            },1000);
            setTimeout(function(){
                updateRaceTableSizes();
            },1100);

            /* Open Results To Everyone If Not Open And Results Showing Up */
            if(race_details_global[0]['results_viewable'] == 0) {
                markRaceResultsOpen(race_details_global[0]['race_id']);
            }
        } else {
            setRaceResultHTML(<></>);
            $("#race-result-shell").hide();
            $("#race-countdown-timer-shell").show();
            $("#race-countdown-title").html('LIVE PREDICTIONS OPEN NOW');
            $("#race-countdown-date").html('NEXT RACE STARTING');
            if(countdown_timer_on == 0) {
                var nextRaceIn = new Date().getTime();
                countdown_currenttime = nextRaceIn;
                //countdown_timer_on = 1;
            }
        }
    }

    function loadPredictionBoxBottom() {
        var tmp_footer = <div className="row exr-new-dark-grey" style={{margin:'0px',padding:'0px',width:'100%',marginBottom:'0px',marginTop:'10px',borderRadius:'10px'}}>
                                <div className="col s12 headline-text center-align white-yellow-link" 
                                    style={{margin:'0px',padding:'0px',fontSize:'0.75vw',lineHeight:'1',marginBottom:'8px',marginTop:'10px'}}>
                                    LEARN MORE
                                </div>
                            </div>
        if((selectedRaceID != 'waitroom')&&(selectedRaceID != '')) {
            tmp_footer = <div className="row exr-new-dark-grey" style={{margin:'0px',padding:'0px',width:'100%',marginBottom:'0px',marginTop:'10px',borderRadius:'10px'}}>
                                <div className="col s12 headline-text center-align white-yellow-link" 
                                    style={{margin:'0px',padding:'0px',fontSize:'0.75vw',lineHeight:'1',marginBottom:'8px',marginTop:'10px'}}>
                                    VIEW DETAILED LINEUP
                                </div>
                            </div>
        }
        setPredictRacersLineupFooter(tmp_footer);
    }

    function loadRacerLineup() {
        var racer_handle_arr = [];
        var racerrows = [];
        var racerrows2 = [];
        var tmp_racer_rows = [];
        var rowcutoff = 5;
        if(racer_details_global.length == 6) {
            rowcutoff = 3;
        } else if((racer_details_global.length == 7)||(racer_details_global.length == 8)) {
            rowcutoff = 4;
        }
        var maxshow = racer_details_global.length;
        if((race_details_global[0]['race_simulated'] == 1)&&(((props.raceGroupID == 19)||(props.raceGroupID == 21))) ) {
            maxshow = 3;
        }    
        for (var i = 0; i < maxshow; i++) {
            racer_handle_arr.push(racer_details_global[i]['racer_name']);
            var total_xp_points = 100;
            var userid = racer_details_global[i]['user_id'];
            var raceid = racer_details_global[i]['race_id'];
            var pracerid = racer_details_global[i]['racer_id'];
            var racername = racer_details_global[i]['racer_name'].toUpperCase();
            var pilot_image = racer_details_global[i]['pilot_image_location'];
            var racecraft_image = racer_details_global[i]['ship_image_location'];
            var user_predicted = racer_details_global[i]['racer_predicted'];
            var win_pct = Math.floor(racer_details_global[i]['racer_start_win_pct']*100);
            var xpchance = Math.round(total_xp_points*(1-(racer_details_global[i]['racer_start_win_pct'])));
            var totalpreds = racer_details_global[i]['total_predictors'];
            var pilot_image_bg = "url('"+pilot_image+"')";

            var margintop = "0px 0px 8px 0px";

            var predictbutton = <></>;
            if(race_details_global[0]['race_simulated'] == 1) {
                predictbutton = <span className="headline-text">{ordinal_suffix_of((i+1)).toUpperCase()}</span>;
                if(i == 1) {
                    xpchance = Math.round((xpchance/2));
                } else if(i > 1) {
                    xpchance = 0;
                }
            }
            // } else if (i < 2) {
            //     if(totalpreds > 0) {
            //         predictbutton = 'Fan Favorite // '+ordinal_suffix_of((i+1))+' Place';
            //     } else {
            //         predictbutton = 'Odds Favorite // '+ordinal_suffix_of((i+1))+' Place';
            //     }
            // }
            
            var racerkey = 'viewrace'+raceid+'racer'+pracerid;
            
            var tmp_row = <div key={racerkey} className="col s12 m6" style={{margin:'0px',marginBottom:'0px',padding:'5px 10px'}}>
                                <div className="row exr-new-dark-grey" style={{display:'flex',flexDirection: 'row',margin:'0px',padding:'2.5px',position:'relative',borderRadius:'10px'}}>
                                    <div style={{width:'25%',maxWidth:'150px',lineHeight:'.5'}}>
                                        <img src={racecraft_image} width="100%" style={{margin:'0px',borderRadius:'5px'}} />
                                    </div>
                                    <div style={{flex:'1',padding:'10px',paddingBottom:'0px'}}>
                                        <div className="row headline-text" style={{margin:'0px',fontSize:'18px',lineHeight:'1.25',overflow:'hidden',marginBottom:'5px'}}>
                                            {racername.toUpperCase()}
                                        </div>
                                        <div className="row" style={{margin:'0px',fontSize:'12px',lineHeight:'1'}}>
                                            
                                            <div className="left" style={{fontSize:'16px',marginTop:'2px',marginRight:'5px',lineHeight:'1'}}>
                                                {win_pct}%
                                            </div>
                                            <div className="left" style={{fontSize:'12px',marginTop:'4px',lineHeight:'1'}}>
                                                WIN
                                            </div>
                                            <div className="right" style={{fontSize:'12px',marginTop:'2px',lineHeight:'1',marginRight:'5px'}}>
                                                <span style={{fontSize:'16px'}}>{totalpreds}</span>&nbsp;&nbsp;PICKED
                                            </div>
                                            <div className="right" style={{fontSize:'12px',marginTop:'2px',lineHeight:'1',marginRight:'15px'}}>
                                                <span style={{fontSize:'16px'}}>{xpchance}</span>&nbsp;&nbsp;XP
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exr-new-dark-grey exr-yellow-text" style={{position:'absolute',top:'10px',right:'10px'}}>
                                        {predictbutton}
                                    </div>
                                </div>
                            </div>;

            var tmp_row = <div key={racerkey} className="left" style={{margin:'0px',marginBottom:'0px',padding:'2.5px',height:'100%',minWidth:'20%'}}>
                            <div className="row exr-new-dark-grey" style={{margin:'0px',padding:'0px',position:'relative',borderRadius:'10px',height:'100%'}}>
                                <div className="row" style={{margin:'0px',padding:'0px'}}>
                                    <img src={racecraft_image} width="100%" style={{borderRadius:'0px',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}} />
                                </div>
                                <div className="row" style={{margin:'0px',padding:'15px'}}>
                                    <div className="row headline-text" style={{margin:'0px',fontSize:'16px',lineHeight:'1.25',overflow:'hidden',marginBottom:'5px'}}>
                                        {racername.toUpperCase()}
                                    </div>
                                    <div className="row" style={{margin:'0px',fontSize:'12px',lineHeight:'1'}}>
                                        <div className="left" style={{fontSize:'16px',marginTop:'2px',marginRight:'5px',lineHeight:'1'}}>
                                            {win_pct}%
                                        </div>
                                        <div className="left" style={{fontSize:'12px',marginTop:'4px',lineHeight:'1',marginRight:'20px'}}>
                                            WIN
                                        </div>
                                        <div className="left" style={{fontSize:'12px',marginTop:'2px',lineHeight:'1'}}>
                                            <span style={{fontSize:'16px'}}>{xpchance}</span>&nbsp;&nbsp;XP
                                        </div>
                                        <div className="right" style={{fontSize:'12px',marginTop:'2px',lineHeight:'1',marginRight:'5px'}}>
                                            <span style={{fontSize:'16px'}}>{totalpreds}</span>&nbsp;&nbsp;PICKED
                                        </div>
                                    </div>
                                </div>
                                <div className="exr-yellow-text" style={{position:'absolute',top:'10px',right:'10px'}}>
                                    {predictbutton}
                                </div>
                            </div>
                        </div>;
            if(i >= rowcutoff) {
                racerrows2.push(tmp_row);
            } else {
                racerrows.push(tmp_row);
            }
        }
        if(racerrows2.length > 0) {
            for(var z = racerrows2.length; z < racerrows.length;z++) {
                var tmp_row = <div key={racerkey} className="left" style={{margin:'0px',marginBottom:'0px',padding:'2.5px',height:'100%',minWidth:(100*(1/rowcutoff))+'%'}}>
                            <div className="row exr-new-dark-grey" style={{margin:'0px',padding:'0px',position:'relative',borderRadius:'10px',height:'100%'}}>
                               &nbsp;
                            </div>
                        </div>;
                racerrows2.push(tmp_row);
            }
        }
        var finalSection = <div className="row" style={{margin:'0px',padding:'20px 5px 0px 5px',height:'100%',borderRadius:'10px',display:'flex',flexDirection: 'row'}}>{racerrows}</div>
        if(racerrows2.length > 0) {
            finalSection = <>
            <div className="row" style={{margin:'0px',padding:'20px 5px 0px 5px',height:'50%',borderRadius:'10px',display:'flex',flexDirection: 'row'}}>{racerrows}</div>
            <div className="row" style={{margin:'0px',padding:'0px 5px 0px 5px',height:'50%',borderRadius:'10px',display:'flex',flexDirection: 'row'}}>{racerrows2}</div>
            </>
        }
        
        setPredictRacersLineup(finalSection);
        if(initialload == 0) {
            initialload = 1;
        }
        

        // if(racer_details_global.length > 6) {
        //     $("#chat-predict-display").css({'overflow-y':'scroll','overflow-x':'hidden'});
        // } else {
        //     $("#chat-predict-display").css({'overflow-y':'hidden','overflow-x':'hidden'});
        // }

        if(race_details_global[0]['race_simulated'] == 0) {
            setTimeout(function() {
                grabRaceParticipants();
            },2000);
        }
    }

    function loadWaitingroomPredictBanner() {
        let predictbanner = <></>;
        var finalSection = <div id="chat-predict-display" className="exr" style={{marginTop:'10px',borderRadius:'10px',width:'100%'}}>
            <div className="row exr-new-dark-grey"  style={{height:'100%',margin:'0px',padding:'20px 10px',position:'relative',borderRadius:'10px'}}>
                <div className="col s12 center-align exr-new-light-grey" style={{marginTop:'-10px',padding:'10px',paddingTop:'15px',borderRadius:'10px',lineHeight:'1'}}>
                    <span className="headline-text" style={{fontSize:'14px',lineHeight:'1'}}>GET STARTED</span>
                </div>
                <div className="col s12" style={{marginTop:'25px'}}>
                    <span className="left headline-text exr-light-green-text" style={{width:'10%',fontSize:'30px',marginRight:'10px'}}>1</span>
                    <span className="left" style={{fontSize:'18px',width:'85%'}}>
                        <span className="left" style={{width:'100%',fontSize:'18px',marginBottom:'10px'}}>Access Races</span>
                        <span className="left" style={{width:'100%',fontSize:'14px'}}>
                        Go to the EXR Pickem website listed below and login to access the most current race.
                        </span>
                    </span>
                </div>
                <div className="col s12" style={{marginTop:'38px'}}>

                    <span className="left headline-text exr-light-green-text" style={{width:'10%',fontSize:'30px',marginRight:'10px'}}>2</span>
                    <span className="left" style={{fontSize:'18px',width:'85%'}}>
                        <span className="left" style={{width:'100%',fontSize:'18px',marginBottom:'10px'}}>Make Free Predictions</span>
                        <span className="left" style={{width:'100%',fontSize:'14px'}}>
                            Discover each racers odds and make a free prediction on winners. 
                        </span>
                    </span>
                </div>
                <div className="col s12" style={{marginTop:'38px'}}>
                    <span className="left headline-text exr-light-green-text" style={{width:'10%',fontSize:'30px',marginRight:'10px'}}>3</span>
                    <span className="left" style={{fontSize:'18px',width:'85%'}}>
                        <span className="left" style={{width:'100%',fontSize:'18px',marginBottom:'10px'}}>Watch Races Live</span>
                        <span className="left" style={{width:'100%',fontSize:'14px'}}>
                            Watch the final simulated race live on screens around you 
                        </span>
                    </span>
                </div>
                <div className="col s12" style={{marginTop:'38px'}}>
                    <span className="left headline-text exr-light-green-text" style={{width:'10%',fontSize:'30px',marginRight:'10px'}}>4</span>
                    <span className="left" style={{fontSize:'18px',width:'85%'}}>
                        <span className="left" style={{width:'100%',fontSize:'18px',marginBottom:'10px'}}>Win Prizes</span>
                        <span className="left" style={{width:'100%',fontSize:'14px'}}>
                            The more XP you earn by predicting correctly, the higher chance you are to win a prize.
                        </span>
                    </span>
                </div>
                <div className="col s12">
                    
                </div>
            </div>
        </div>;
        setPredictRacersLineup(finalSection);
        setTimeout(function() {
            adjustRightBoxHeights();
        },100);
    }

    function loadSponsorGrid() {
        let tmp_grid = <div id="race_sponsors" className="row" style={{marginTop:'80px'}}>
                            <div className="col s8 offset-s2" style={{padding:'20px',border:'1px solid grey',borderRadius:'10px'}}>
                                <div className="row center-align white-text headline-text" style={{fontSize:'18px',}}>
                                    RACE SPONSORS
                                </div>
                                <div className="row">
                                    <div className="col s12 m4 center-align" style={{padding:'5px'}}>
                                        <img src="https://media.exiledracers.com/partners/stellaswap_logo.svg" height="35px" />
                                    </div>
                                    <div className="col s12 m4 center-align" style={{padding:'5px',marginTop:'-5px'}}>
                                        <img src="https://media.exiledracers.com/partners/moonbeam_logo.png" height="50px" />
                                    </div>
                                    <div className="col s12 m4 center-align" style={{padding:'5px',marginTop:'0px'}}>
                                        <img src="https://media.exiledracers.com/partners/prime_protocol_logo.webp" height="50px" />
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom:'10px'}}>
                                    
                                    <div className="col s12 m4 center-align" style={{padding:'5px'}}>
                                        <img src="https://media.exiledracers.com/partners/dam_logo.svg" height="35px" />
                                    </div>
                                    <div className="col s12 m4 center-align" style={{padding:'5px',marginTop:'-10px'}}>
                                        <img src="https://media.exiledracers.com/assets/game/builder/Zeitgeist_Logo_1665706100333.png" height="45px" />
                                    </div>
                                    <div className="col s12 m4 center-align" style={{padding:'5px'}}>
                                        <img src="https://media.exiledracers.com/partners/biconomy_logo.svg" height="35px" />
                                    </div>
                                </div>
                            </div>
                        </div>;
        setRaceSponsorHTML(tmp_grid);
    }

    function loadSponsorGridSmall() {
        let tmp_grid = <div id="race_sponsors" className="row" style={{margin:'0px'}}>
                            <div className="col s12" style={{padding:'20px 10px',border:'0px solid grey',borderRadius:'10px'}}>
                                <div className="row center-align white-text headline-text" style={{fontSize:'18px',}}>
                                    RACE SPONSORS
                                </div>
                                <div className="row" style={{margin:'0px'}}>
                                    <div className="col s6 center-align" style={{marginTop:'-10px'}}>
                                        <img src="https://media.exiledracers.com/partners/moonbeam_logo.png" style={{height:'6vh'}} />
                                    </div>
                                    <div className="col s6 center-align" style={{padding:'0px',paddingRight:'20px'}}>
                                        <img src="https://media.exiledracers.com/partners/stellaswap_logo.svg" style={{height:'4.5vh'}} />
                                    </div>
                                </div>
                            </div>
                        </div>;
        setRaceSponsorHTML(tmp_grid);
    }

    function adjustRightBoxHeights() {
        let leftsideheight = $("#sb-viewer-left-box").height();
        
        if((window.innerWidth < 1000)) {
            leftsideheight = leftsideheight*1.55;
            var totalHeight = 0;
            $("#chat-predict-display").children().each(function(){
                totalHeight = totalHeight + $(this).outerHeight(true);
            });

            if(totalHeight >= leftsideheight) {
                leftsideheight = totalHeight + 100;
            }
        }
        $("#sb-viewer-right-box").css({'height':leftsideheight+'px'});
        $("#chat-message-display").css({'height':(leftsideheight-190)+'px'});
        $("#chat-predict-display").css({'height':(leftsideheight-190)+'px'});
        // $("#chat-leaderboard-display").css({'height':(leftsideheight-190)+'px'});

        if((window.innerWidth < 600)) {
            $("#race-countdown-title").css({'font-size':'20px'});
            $("#race-countdown-timer").css({'font-size':'18px'});
            $("#race-countdown-date").css({'font-size':'20px'});
            $("#right-side-viewer-shell").css({'padding-left':'0px','padding-right':'0px'});
            $(".viewer-racer-name").each(function() {
                $(this).css({'font-size':'16px'});
            });
        } else {
            $("#race-countdown-title").css({'font-size':'2.5vw'});
            $("#race-countdown-timer").css({'font-size':'2.5vw'});
            $("#race-countdown-date").css({'font-size':'2.5vw'});
            $("#right-side-viewer-shell").css({'padding-left':'7px','padding-right':'7px'});
            $(".viewer-racer-name").each(function() {
                $(this).css({'font-size':'0.85vw'});
            });
        }

        if((window.innerWidth < 1000)) {
            $(".viewer-racer-name").each(function() {
                $(this).css({'font-size':'16px'});
            });
            $(".viewer-racer-pred-button").each(function() {
                $(this).css({'font-size':'14px'});
            });
            $(".viewer-racer-number").each(function() {
                $(this).css({'font-size':'20px'});
            });
            $(".viewer-racer-number-text").each(function() {
                $(this).css({'font-size':'12px'});
            });
            
            
        } else {
            $(".viewer-racer-name").each(function() {
                $(this).css({'font-size':'0.85vw'});
            });
            $(".viewer-racer-pred-button").each(function() {
                $(this).css({'font-size':'0.6vw'});
            });
            $(".viewer-racer-number").each(function() {
                $(this).css({'font-size':'1.1vw'});
            });
            $(".viewer-racer-number-text").each(function() {
                $(this).css({'font-size':'0.65vw'});
            });
            
            
        }
        
    }

    if(entrySet == false) {
        setEntry(true);
        grabRaceDetails();
        //loadSponsorGridSmall();
        $(window).resize(function() {
            adjustRightBoxHeights();
        });
        countdownTimer();
        // let new_video_html = <video id="waitingroom-bg" src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExZmVhNGFlZTY5YWMxM2UwNDJlZTBmNDA1ZDdjMDhhNTIyNTJjM2E3MSZjdD1n/WUyQbeKHhpaHrrKJu6/giphy.mp4" 
        // style={{border:'0px',overflow:'hidden',borderRadius:'10px'}} width="100%" autoPlay loop muted />;
        // setVideoHTML(new_video_html);
    }

    // $(window).resize(function(){
    //     adjustRightBoxHeights();
    // });

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    

    function countdownTimer() {
        if (countdown_timer_on == 1) {
            // Set the date we're counting down to
            // var countDownDate = new Date(
            //     "Apr 19, 2023 "+currenttime+" GMT-0400 (Eastern Daylight Time)"
            // ).getTime();
            
            var minstostart = 15;
            var countDownDate = new Date(countdown_currenttime + minstostart*60000).getTime();
            
            // Update the count down every 1 second
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            var minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            var finaldays = "";
            var finalhours = "";
            var finalminutes = "";
            var finalseconds = "";
            if (days == 0) {
                finaldays = "";
            } else {
                finaldays = days + "d ";
            }
            if ((hours == 0)&&(finaldays == 0)) {
                finalhours = "";
            } else if (hours < 10) {
                finalhours = "0" + hours + "h ";
            } else {
                finalhours = hours + "h ";
            }
            if ((minutes == 0)&&(finalhours == 0)) {
                finalminutes = "";
            } else if (minutes < 10) {
                finalminutes = "0" + minutes + "m ";
            } else {
                finalminutes = minutes + "m ";
            }
            if (seconds < 10) {
                finalseconds = "0" + seconds + "s ";
            } else {
                finalseconds = seconds + "s ";
            }

            // Display the result in the element with id="demo"
            
            // If the count down is finished, write some text
            if(distance <= 0) {
                $("#race-countdown-timer").html('');
                countdown_timer_on = 0;
            } else {
                $("#race-countdown-timer").html(finaldays + finalhours + finalminutes + finalseconds);
            }        
        }
        setTimeout(function(){
            countdownTimer();
        },1000);
    }

    function generateNewRace() {
        var addData = new Object();
        addData.raceGroupID = encodeURIComponent(current_group_id);
        if(window.confirm('Are You Sure You Want To Create a New Race?')) {
            $("#start-new-race-button").html(tmpspinner);
            axiosInstance.post('sportsbook/new_pickem_live/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var sim_details = result.data;
                    setTimeout(function() {
                        window.location.reload();
                    },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function simulateRaceResults() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(props.raceID);
        addData.collectionID = encodeURIComponent(2);
        if(window.confirm('Are You Sure You Want To Simulate This Race?')) {
            $("#simulate-new-race-button").html(tmpspinner);
            axiosInstance.post('race/simulate_race/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var sim_details = result.data;
                    setTimeout(function() {
                        watchInitialRace();
                    },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function watchInitialRace() {
        var addData = new Object();
        addData.raceID = encodeURIComponent(props.raceID);
        addData.racerID = encodeURIComponent(racerID);
        axiosInstance.post('race/racer_watched/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                window.location.href= '/#/watchlive/'+props.raceID;
                dispatch(updateLocation("race_track"));
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function markRaceResultsOpen(traceid) {
        var addData = new Object();
        addData.raceID = encodeURIComponent(traceid);
        axiosInstance.post('race/results_open/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                console.log('Race Results Open To View');
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function updateRaceTableSizes() {
        $("#finalracetable").css({height:$("#finalracewinner").height()+'px'});
        
        if(window.innerWidth < 1000) {
            $(".finish-screen-racer-ship").hide();
            $(".finish-screen-racer-stats").each(function(){
                $(this).css({width:'100%',height:'16vw'});
            });
        } else {
            $(".finish-screen-racer-ship").show();
            $(".finish-screen-racer-stats").each(function(){
                $(this).css({width:'49%',height:'11vw'});
            });
        }
        
        var newflexheight = $(".final-result-flex-row").height();
        var newtextmargin = ((newflexheight-18)/2);
        $(".final-result-flex-row-text").each(function(){
            $(this).css({marginTop:newtextmargin+'px'});
        });
        $(".final-result-flex-row-number").each(function(){
            $(this).css({marginTop:newtextmargin-5+'px'});
        });



        $("#finalboardbox").css({position:'relative',height:$("#finalracewinner").height()+25+'px',maxHeight:($("#sb-viewer-video-shell").height()*.83)+'px',border:'0px'});
    }

    return(<div className="row exr-dark-grey-bg" id="left-side-viewer-shell" style={{display:'flex',flexDirection:'column',margin:'0px',position:'relative',padding:'10px',borderRadius:'10px',height:'100%'}}>
            
            <div className="row" style={{margin:'0px',padding:'10px 10px 0px 10px',marginBottom:'0px'}}>
                <div className="row" style={{margin:'0px',marginBottom:'5px',lineHeight:'1',fontSize:'14px'}}>
                    <div className="left headline-text" style={{fontSize:'20px',lineHeight:'1',height:'22px'}}>
                        {race_name}
                    </div>
                    <div className="right headline-text hide-on-med-and-down show-on-large" style={{fontSize:'14px',lineHeight:'1'}}>
                            RACE STATUS
                    </div>
                </div>
                <div className="row hide-on-med-and-down show-on-large" style={{margin:'0px',padding:'0px',marginBottom:'0px',lineHeight:'1'}}>
                    <div className="left" style={{lineHeight:'1'}}>
                        {race_subtitle_details}
                    </div>
                    <div className="right headline-text" style={{fontSize:'18px',lineHeight:'1',marginTop:'-5px'}}>
                        {race_current_status}
                    </div>
                </div>
                <div className="row show-on-medium-and-down hide-on-large-only" style={{margin:'0px',padding:'0px',marginBottom:'0px',lineHeight:'1'}}>
                    <div className="left headline-text" style={{fontSize:'18px'}}>
                        {race_name}
                    </div>
                </div>
                <div className="row show-on-medium-and-down hide-on-large-only" style={{margin:'0px',padding:'0px',marginBottom:'0px',lineHeight:'1'}}>
                    <div className="left headline-text" style={{fontSize:'14px',lineHeight:'1',marginTop:'0px'}}>
                        {race_subtitle_details}
                    </div>
                </div>
            </div>
            <div className="row" style={{flex:'1',marginBottom:'0px'}}>
                {predictRacersLineup}
            </div>
    </div>);
};     