/**
 * An automatic track prop generator
 */
export class PropGenerator {
    /**
     * Construct a track prop generator
     * @param {Random} random A randomizer
     */
    constructor(random) {
        this.random = random;
    }

    /**
     * Generate track props
     * @param {TrackProp[]} props The track prop array to append
     * @param {PropGeneratorExcluder} excluder An excluder
     */
    generate(props, excluder) {

    }
}