import {RacerMovement} from "./movement/racerMovement.js";
import * as THREE from "three";

/**
 * Racer data
 */
export class RacerData {
    static KEY_RACER_NAME = "racer_name";
    static KEY_URL_SHIP_MODEL = "ship_model";
    static KEY_URL_PILOT_IMAGE = "pilot_image_url";
    static KEY_URL_PILOT_IMAGE_SMALL = "pilot_image_thumbnail";
    static KEY_MOVEMENT = "movement";
    static KEY_FINAL_STANDING = "final_standing";
    static KEY_FINAL_TIME = "final_time";
    static KEY_FINAL_RPM = "race_final_rpm";
    static VIEWER_PREDICTED = "viewer_predicted";
    static KEY_CURRENT_RACER = "current_racer";
    static KEY_SPECIAL_RACER = "legendary_exiled_cnt";
    static KEY_FACTION = "racer_pilot_faction";

    /**
     * Data for a single racer
     * @param {Object} data The data to parse
     */
    constructor(data,sponsor) {
        this.movement = new RacerMovement(data[RacerData.KEY_MOVEMENT]);

        this.racerName = data[RacerData.KEY_RACER_NAME];
        this.urlShipModel = data[RacerData.KEY_URL_SHIP_MODEL];
        this.urlAvatar = data[RacerData.KEY_URL_PILOT_IMAGE];
        this.urlAvatarSmall = data[RacerData.KEY_URL_PILOT_IMAGE_SMALL];
        this.racerSpecial = parseInt(data[RacerData.KEY_SPECIAL_RACER]);
        this.viewerPredicted = data[RacerData.VIEWER_PREDICTED];
        this.currentRacer = data[RacerData.KEY_CURRENT_RACER];
        this.finalStanding = parseInt(data[RacerData.KEY_FINAL_STANDING]) - 1;
        this.faction = data[RacerData.KEY_FACTION];
        this.time = data[RacerData.KEY_FINAL_TIME];
        this.rpm = data[RacerData.KEY_FINAL_RPM];
        this.color = new THREE.Color().setHSL(Math.random(), .8, .6);
        this.glitched = false;
        this.sponsor = sponsor;

        const randomShip = Math.random() < .6 ?
            "assets/racers/RACECRAFT_MERCENARY_CAPTAIN_GAME-ASSET_" + (Math.floor(Math.random() * 12)).toString().padStart(4, "0") + "_Compressed.glb" :
            "assets/racers/RACECRAFT_AUGMENT_HC-AUG_XZ2000_GAME-ASSET_" + (Math.floor(Math.random() * 6)).toString().padStart(4, "0") + ".glb";

        const ships = ['https://media.exiledracers.com/assets/game/builder/Racer_1_1661802047728.glb',
                        'https://media.exiledracers.com/assets/game/builder/Racer_2_1661802080736.glb',
                        'https://media.exiledracers.com/assets/game/builder/Racer_3_1661802107627.glb',
                        'https://media.exiledracers.com/assets/game/builder/Racer_4_1661802132666.glb'];
        
        // TODO: Debug override
        // this.urlShipModel = ships[Math.floor(Math.random() * ships.length)];

        // this.urlShipModel = "assets/racers/alphatest.glb";
        // this.urlShipModel = ships[Math.floor(Math.random() * ships.length)];
        if ((this.urlShipModel == null)||(this.urlShipModel.indexOf('glitched') >= 0)) {
            this.urlShipModel = randomShip;
        }
    }
}