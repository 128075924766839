import * as THREE from "three";

/**
 * Properties for a racer trail
 */
export class RacerTrailProperties {
    static COLOR_BLUE = new THREE.Color(.5, .5, 1);
    static COLOR_GOLD = new THREE.Color(0xfcba03);
    static COLOR_GREEN = new THREE.Color(0x00ff00);
    static COLOR_WHITE = new THREE.Color(0xffffff);
    static COLOR_PINK = new THREE.Color(0xe61f93);
    static COLOR_BASE = new THREE.Color(0x3773f5);

    /**
     * Construct racer trail properties
     * @param {Number} specialFlag The special flag
     */
    constructor(specialFlag,sponsor) {
        this.sponsored = 0;
        if (specialFlag >= 10) {
            this.color = RacerTrailProperties.COLOR_GOLD;
            this.boostColor = RacerTrailProperties.COLOR_GOLD;
        } else if (specialFlag > 0) {
            this.color = RacerTrailProperties.COLOR_GREEN;
            this.boostColor = RacerTrailProperties.COLOR_GREEN;
        } else {
            this.color = RacerTrailProperties.COLOR_BLUE;
            this.boostColor = RacerTrailProperties.COLOR_BLUE;
        }


        if ((sponsor.length > 0)&&(sponsor != 'none')) {
            this.sponsored = 1;
            this.color = RacerTrailProperties.COLOR_WHITE;
            if(sponsor == 'pink') {
                this.boostColor = RacerTrailProperties.COLOR_PINK;
            } else if(sponsor == 'base') {
                this.boostColor = RacerTrailProperties.COLOR_BASE;
            }
        }
    }
}