import * as THREE from "three";
import {TrackProp} from "./trackProp.js";
import {effectGlowSprite} from "../../../../exr-webgl-hub/effects/2d/effectGlowSprite.js";

/**
 * An orb track prop, which has no model but emits light
 */
export class TrackPropOrb extends TrackProp {
    /**
     * Construct an orb prop
     * @param {number} color The orb color
     * @param {number} radius The orb radius
     * @param {TrackPropInstance[]} instances The instances for this prop
     */
    constructor(color, radius, instances) {
        super(null, instances);

        this.color = color;
        this.radius = radius;
    }

    /**
     * Load this prop
     * @returns {Promise} A promise which resolves when the prop was loaded
     */
    load() {
        return new Promise(resolve => {
            const material = effectGlowSprite.clone();

            material.uniforms.color = new THREE.Uniform(new THREE.Color(this.color));
            material.uniforms.radius.value = this.radius;

            const sprite = new THREE.Sprite(material);

            this.scene = new THREE.Scene();
            this.scene.add(sprite);

            resolve();
        });
    }
}