import {CameraController} from "../cameraController.js";
import * as THREE from "three";

/**
 * A cinematic camera controller
 */
export class CameraControllerCinematic extends CameraController {
    /**
     * Construct a cinematic camera controller
     * @param {Camera} camera The camera to control
     * @param {CameraAnchor[]} anchors The camera anchors for every track piece index
     * @param {boolean} [targetPlayer] True if this camera should target the player
     */
    constructor(camera, anchors, targetPlayer = true) {
        super(camera, targetPlayer);

        this.anchors = anchors;
        this.anchorCount = anchors.length;
        this.anchorPrevious = null;
        this.targetQuaternion = new THREE.Quaternion();
    }

    /**
     * Set the target for this camera controller
     * @param {CameraTargetable} target The target to track
     * @param {CameraTargetable[]} targets All targets that can be tracked
     * @param {Track} track The track
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {boolean} [instant] True if the change should be instant
     */
    setTarget(target, targets, track, time, instant = false) {
        const anchor = this.anchors[Math.min(target.getTrackPieceIndex(), this.anchorCount - 1)];

        if (anchor !== this.anchorPrevious) {
            instant = true;

            this.anchorPrevious = anchor;
        }

        anchor.update(
            target,
            targets,
            track.length,
            time);

        if (instant) {
            this.camera.position.copy(anchor.position);
            this.targetQuaternion.copy(anchor.quaternion);
        }
        else {
            this.camera.position.lerp(anchor.position, anchor.springPosition);
            this.targetQuaternion.slerpQuaternions(this.targetQuaternion, anchor.quaternion, anchor.springQuaternion);
        }

        this.camera.up.set(0, 1, 0).applyQuaternion(this.targetQuaternion);
        super.setTarget(target, targets, track, time);
        this.camera.up.set(0, 1, 0);
    }
}