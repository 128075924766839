import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "../../../RacesHome/races_home.css";
import $ from "jquery";

let currentsim = 0;
let nextstarttime;

export const PubRaceControllerParty = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    const [entrySet, setEntry] = useState(false);

    function startRaceTimer() {
        var now = new Date();
        var diff = 5;
        nextstarttime = new Date(now.getTime() + diff*60000);
        loadCountdownTimer();
        //alert(now+" "+nexttime);
    }

    function loadCountdownTimer() {
        // Set the date we're counting down to
        // var countDownDate = new Date(
        //     currenttime
        // ).getTime();
        // if(sectiontitle != 'RACES STARTING') {
        //     countDownDate = new Date(
        //         currenttime+" GMT-0400 (Eastern Daylight Time)"
        //     ).getTime();
        // }
        var countDownDate = nextstarttime;

        // Update the count down every 1 second
        // Get today's date and time
        var now = Date.now();
        // Find the distance between now and the count down date
        var distance = (countDownDate - now);

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        var finaldays = "";
        var finalhours = "";
        var finalminutes = "";
        var finalseconds = "";
        if (days == 0) {
            finaldays = "";
        } else {
            finaldays = days + "d ";
        }
        if ((hours == 0)&&(finaldays == 0)) {
            finalhours = "";
        } else if (hours < 10) {
            finalhours = "0" + hours + "h ";
        } else {
            finalhours = hours + "h ";
        }
        if ((minutes == 0)&&(finalhours == 0)) {
            finalminutes = "";
        } else if (minutes < 10) {
            finalminutes = "0" + minutes + "m ";
        } else {
            finalminutes = minutes + "m ";
        }
        if (seconds < 10) {
            finalseconds = "0" + seconds + "s ";
        } else {
            finalseconds = seconds + "s ";
        }

        // Display the result in the element with id="demo"
        
        // If the count down is finished, write some text
        // if((action == 'play')&&(race_details_global[0]['results_viewable'] == 0)) {
        if(distance <= 0) {
            if(currentsim == 0) {
                currentsim = 1;
                $("#countdown-timer").html('...');
                simulateRaceResults();
            }
        } else {
            $("#countdown-timer").html(finaldays + finalhours + finalminutes + finalseconds);
            setTimeout(function() {
                loadCountdownTimer();
            },1000);
        }
    }

    if(entrySet == false) {
        setEntry(true);
        startRaceTimer();
    }

    function generateNewRace() {
        props.newRaceButton();
    }

    function simulateRaceResults() {
        props.simRaceButton();
    }

    return(<div className="row " id="left-side-viewer-shell" style={{margin:'0px',position:'relative'}}>
            <div className="row exr-dark-grey-bg" style={{margin:'0px',padding:'20px 30px 7px 25px',marginBottom:'0px',padding:'10px',borderRadius:'10px',position:'relative',overflow:'hidden'}}>
                <div className="col s9" style={{paddingLeft:'40px',margin:'0px',marginBottom:'10px',lineHeight:'1',fontSize:'14px',marginTop:'2.25vh'}}>
                    <div className="row headline-text" style={{fontSize:'20px',lineHeight:'1',marginBottom:'5px',marginRight:'20px'}}>
                        <span className="left">PREDICT RACES &#8226; WATCH LIVE &#8226; WIN BIG</span>
                        <span className="right"></span>
                    </div>
                    <div className="row left-align" style={{fontSize:'18px',lineHeight:'1.5',marginTop:'12px',marginBottom:'30px',marginRight:'20px'}}>
                        Scan the QR Code to join the banter. Predict who you think will win 1st and 2nd place in the upcoming race. Watch the races happen live and 
                        see where you land on the leaderboard. Prizes awarded for the top of the board at the end of the night. 
                    </div>
                    <div className="row left-align headline-text exr-yellow-text" style={{paddingLeft:'0px',fontSize:'14px',lineHeight:'1',marginBottom:'5px'}}>
                        NEXT RACE STARTS IN
                    </div>
                    <div id="countdown-timer" className="row left-align" style={{paddingLeft:'0px',fontSize:'50px',lineHeight:'1',marginTop:'12px',marginBottom:'0px'}}>
                        0h 0m 0s
                    </div>
                </div>
                <div className="col s3 center-align" style={{margin:'0px',padding:'0px',marginBottom:'15px',lineHeight:'1',paddingRight:'30px',marginTop:'10px'}}>
                    <div className="row headline-text center-align" style={{fontSize:'14px',lineHeight:'1',marginBottom:'10px'}}>
                        <img src="css/images/qrcode_pickem.png" style={{height:'24vh',borderRadius:'10px'}} />
                    </div>
                    <div className="row headline-text center-align" style={{fontSize:'14px',lineHeight:'1',marginBottom:'5px'}}>
                        START PLAYING
                    </div>
                    <div className="row center-align" style={{fontSize:'18px',lineHeight:'1',marginTop:'2px',marginBottom:'0px'}}>
                        PICKEM.EXILEDRACERS.COM
                    </div>
                </div>
                
                
            </div>
    </div>);
};     