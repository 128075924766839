/**
 * A racer movement anchor
 */
export class RacerMovementAnchor {
    /**
     * Construct a racer movement anchor
     * @param {number} time The time in seconds
     * @param {number} movement The movement in meters
     * @param {number} boosting The indicator 0 or 1 if ship is boosting
     */
    constructor(time, movement, boosting) {
        this.time = time;
        this.movement = movement;
        this.boosting = boosting;
    }
}