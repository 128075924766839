import {CameraController} from "../cameraController.js";
import * as THREE from "three";

/**
 * The racer introduction camera
 */
export class CameraControllerIntroduction extends CameraController {
    static RAIL_LENGTH = 24;

    /**
     * Construct the introduction camera
     * @param {Camera} camera The camera
     * @param {Racers} racers The racers
     */
    constructor(camera, racers) {
        super(camera);

        this.target = new THREE.Vector3();
        this.offset = new THREE.Vector3(15, 10, 0);
        this.racers = racers;
        this.t = 0;
    }

    /**
     * Set the animation time
     * @param {number} t The time in the range [0, 1]
     */
    setTime(t) {
        this.t = t;
    }

    /**
     * Set the target for this camera controller
     * @param {CameraTargetable} target The target to track
     * @param {CameraTargetable[]} targets All targets that can be tracked
     * @param {Track} track The track
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {boolean} [instant] True if the change should be instant
     */
    setTarget(target, targets, track, time, instant = false) {
        this.target.set(0, 0, Math.cos(-this.t * Math.PI) * CameraControllerIntroduction.RAIL_LENGTH).lerp(target.getPosition(time), Math.pow(this.t, 12));

        this.camera.position.set(0, 0, Math.cos(-this.t * Math.PI) * CameraControllerIntroduction.RAIL_LENGTH).add(this.offset);
        this.camera.lookAt(this.target);
    }
}