import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./league_landing.css";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals} from "../../../utils";
import {LeagueHome, LeagueLeaderboard, LeagueRacedays} from "./Sections";
import $ from 'jquery';

let web3;
let league_hash_global = '';
let current_league_details = {};
let current_league_id = 0;
let current_season_id = 0;

export const LeagueLanding = () => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    
    var league_unique_id = '';
    var leaguehash = window.location.href.split("#/");
    var start_league_section = 'home';
    var tmp_left_nav_section = 'home';
    if(leaguehash.length > 1) {
        var leaguehash2 = leaguehash[1].replace("#/",'');
        var leaguehash3 = leaguehash2.split("/");
        if(leaguehash3.length > 1) {
            league_unique_id = leaguehash3[1].replace("/",'');
            if(leaguehash3.length > 2) {
                start_league_section = leaguehash3[2].replace("/",'');
            }
        }
    }
    league_hash_global = league_unique_id;

    if((racerID.length == 0)||(accountAddress.length == 0)) {
        window.location.href= '/';
    } else if (league_unique_id.length < 5) {
        window.location.href= '/#/leagues';
    }

    const [pageSet, setPage] = useState(false);
    const [headerSet, setHeader] = useState(false);
    const [league_id, setLeagueID] = useState(0);
    const [league_season_id, setLeagueSeasonID] = useState(0);
    const [league_permalink_id, setLeaguePermalink] = useState(league_unique_id);
    const [current_section, setCurrentSection] = useState(start_league_section);
    const [leagueSectionHTML, setLeagueSectionHTML] = useState('');
    const [leagueRacedaysHTML, setLeagueRacedaysHTML] = useState('');
    const [leagueTableHTML, setLeagueTableHTML] = useState('');
    const [leagueHeaderName, setLeagueHeaderName] = useState('');
    const [leagueHeaderImage, setLeagueHeaderImage] = useState('');
    
    function grabSeasonInfo() {
        var addData = new Object();
        addData.leaguePermalink = encodeURIComponent(league_permalink_id);
        addData.viewerID = encodeURIComponent(userID);
        // addData.userID = encodeURIComponent(userID);
        // addData.leagueID = encodeURIComponent(1);
        // addData.leagueSeasonID = encodeURIComponent(1);
        // addData.userID = encodeURIComponent(userID);

        axiosInstance.post('league/season_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var league_info = result.data;
                current_league_id = league_info[0]['league_id'];
                current_season_id = league_info[0]['league_season_id'];
                current_league_details = league_info;
                let current_season_image = league_info[0]['season_header_photo'];
                let current_season_name = league_info[0]['season_custom_name'];
                let current_season_number = league_info[0]['season_number'];
                let league_name = league_info[0]['league_name'];

                setLeagueID(current_league_id);
                setLeagueSeasonID(current_season_id);

                if(current_season_image.length > 0) {
                    setLeagueHeaderImage(<img src={current_season_image} width="100%" style={{borderRadius:'10px'}} />);
                }

                if(current_season_name.length > 0) {
                    setLeagueHeaderName(current_season_name.toUpperCase());
                } else {
                    current_season_name = league_name+' Season '+current_season_number;
                    setLeagueHeaderName(current_season_name.toUpperCase());
                }

                showCurrentLeagueSection(current_section);

                
            } else {
                // window.location.href= '/#/leagues';
            }
        }).catch(error => {
            console.log(error);
            // window.location.href= '/#/leagues';
        });
    }

    function toggleLeagueMenu(pagename) {
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");
        window.location.href= '/#/league/'+league_hash_global+'/'+pagename;

        showCurrentLeagueSection(pagename);

        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
        },5);

    }

    function showCurrentLeagueSection(pagename) {
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");

        var leagueid = current_league_details[0]['league_id'];
        var leagueseasonid  = current_league_details[0]['league_season_id'];
        var racedayarr  = current_league_details[0]['season_racedays'];
        var memberarr = current_league_details[0]['season_members'];
        var linetop = current_league_details[0]['playoffs_group_top'];
        var linebottom = current_league_details[0]['playoffs_group_bottom'];
        var leaderboard_custom = current_league_details[0]['leaderboard_custom'];
        var leaderboard_tiebreaker = current_league_details[0]['leaderboard_tiebreaker']; 
        var leaderboard_final = current_league_details[0]['final_leaderboard_flag'];
        var league_accent_color = current_league_details[0]['display_accent_color'];


        var seasongroups = current_league_details[0]['season_groups'];
        var season_group_count = 1;
        if(seasongroups != null) {
            season_group_count = seasongroups.length;
        }
        
        var sectiontxt = <></>;
        if(pagename == 'schedule') {
            sectiontxt = <LeagueRacedays league_id={leagueid} league_season_id={leagueseasonid} 
                                        racedays={racedayarr} season_members={memberarr} 
                                        display_type='grid'  view_source='league_schedule' group_count={season_group_count} />;
        } else if(pagename == 'leaderboards') {
            sectiontxt = <LeagueLeaderboard league_id={leagueid} league_season_id={leagueseasonid} 
                                            racedays={racedayarr} playoff_line_top={linetop} playoff_line_bottom={linebottom} 
                                            display_type='grid' view_source='league_leaderboards' group_count={season_group_count} 
                                            custom_boards={leaderboard_custom} board_tiebreaker={leaderboard_tiebreaker} board_final={leaderboard_final}
                                            accent_color={league_accent_color} />;
        } else {
            sectiontxt = <LeagueHome league_info={current_league_details} />;
        }

        setLeagueSectionHTML(sectiontxt);

        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
        },5);
    }

    document.addEventListener('DOMContentLoaded', function() {
        //
    });

    if(pageSet == false) {
        setPage(true);
        grabSeasonInfo();
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row page-width-wide">
            <div className="col s12" style={{position:'relative',marginTop:'7px',padding:'0px',borderRadius:'10px',lineHeight:'1'}}>
                {leagueHeaderImage}
            </div>
            <div id="leftrailnav" className="col s12 l2 border-section-full exr-new-dark-grey hide-on-med-and-down" style={{height:'100vh',position:'relative',padding:"5px"}}>
                <div style={{width:'100%'}}>
                    <div className="headline-text section-title-text center-align" style={{fontSize:'16px',marginTop:"20px",marginBottom:"10px"}}>
                        &nbsp;{leagueHeaderName}
                    </div>
                    <div className="side-nav-button-section">
                        <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="hometoggle"
                                onClick={()=> toggleLeagueMenu('home')}> 
                            <span className="left side-nav-button-text">League Home</span>
                            <span className="right side-nav-button-bubble">
                                <div className="bubble-button"></div>
                            </span>
                        </button>
                    </div>
                    <div className="side-nav-button-section">
                        <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="scheduletoggle"
                                onClick={()=> toggleLeagueMenu('schedule')}> 
                            <span className="left side-nav-button-text">Race Schedule</span>
                            <span className="right side-nav-button-bubble">
                                <div className="bubble-button"></div>
                            </span>
                        </button>
                    </div>
                    <div className="side-nav-button-section">
                        <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="leaderboardstoggle"
                                onClick={()=> toggleLeagueMenu('leaderboards')}> 
                            <span className="left side-nav-button-text">Leaderboards</span>
                            <span className="right side-nav-button-bubble">
                                <div className="bubble-button"></div>
                            </span>
                        </button>
                    </div>
                    
                    <div className="side-nav-button-section" style={{display:'none'}}>
                        <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="playoffstoggle"
                                onClick={()=> toggleLeagueMenu('playoffs')}> 
                            <span className="left side-nav-button-text">Playoffs</span>
                            <span className="right side-nav-button-bubble">
                                <div className="bubble-button"></div>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div id="righsidecontent" className="col s12 l10" style={{position:'relative', minHeight:'100vh', margin:"0px",padding:"0px"}}>
                <div className="col s12" style={{position:'relative', minHeight:'100vh',margin:"0px",padding:"0px 5px"}}>
                    <div className="row white-text" style={{position:'relative',padding:"0px",paddingTop:'0px',margin:'0px'}}>
                        {leagueSectionHTML}
                    </div>
                </div>
            </div>
        </div>
        <div id="invitebox" className="modal page-modal page-modal-medium">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>INVITE TO LEAGUE</div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'18px'}}>
                        Invite an EXR Racer to this league! Type the name of the racer below and click Send Invite to add this racer to the league.
                    </div>
                    <div className="input-field col s12" style={{marginTop:'30px',fontSize:'16px'}}>
                        <div className="chips chips-autocomplete chips-placeholder" id="racer_chips_invite"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'30px'}}>
                        <div id="invitechecksubmit" className="center-align modal-trigger right" title="SEND INVITE">
                            <span className="round-border-button" style={{fontSize:'20px'}} title="SEND INVITE">
                                SEND INVITE
                            </span>
                        </div>
                        <div id="invitecheckloading" className="preloader-wrapper small active right splash-modal-loader">
                            <div className="spinner-layer spinner-red-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};