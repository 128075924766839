import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import "../../../RacesHome/races_home.css";
import $ from "jquery";

let total_page_results = 20;
let current_group_id = 1;
let current_pagenum = 0;
let sectionHTMLArray = [];
let current_sort = '';
let current_filter = 'groupid_1';
let current_group_array = [];
let next_race_array = [];

export const RaceScheduler = (props) => {
    const dispatch = useDispatch();
    const { racerID, spectatorID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [sectionHTML, setSectionHTML] = useState('');
    const [showMoreHTML, setShowMoreHTML] = useState('');
    const [race_group_html,setRaceGroupHTML] = useState('');
    const [race_group_dropdown_html,setRaceGroupDowndown] = useState('');
    const [race_collection_select_html, setRaceCollectionSelectHTML] = useState('');
    const [current_group_pick_made,setGroupPickMade] = useState(0);
    const [totalRacePicks,setTotalRacePicks] = useState(5);

    function grabRacerGroups() {
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.raceCollection = encodeURIComponent(props.collectionID);
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('sportsbook/recent_race_groups/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var race_group_details = result.data;
                current_group_array = race_group_details;
                for(var i = 0; i < race_group_details.length; i++) {
                    var tmp_group_num = race_group_details[i]['race_group_id'];
                    grabRacerRaces(tmp_group_num);
                }
                
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function setCollectionSelect() {
        var current_collection_id = props.collectionID;
        var collection_list = props.collectionList;
        var tmp_select_options = [];
        for (var i = 0; i < collection_list.length;i++) {
            var collection_name = collection_list[i]['race_collection_name'];
            var collection_id = collection_list[i]['race_group_collection_id'];
            var tmp_option = <option value={collection_id}>{collection_name}</option>;
            if(current_collection_id == collection_list[i]['race_group_collection_id']) {
                tmp_option = <option value={collection_id} selected>{collection_name}</option>;
            }
            tmp_select_options.push(tmp_option);
        }
        var tmp_select = <select id="race-collection-select">{tmp_select_options}</select>
        setRaceCollectionSelectHTML(tmp_select);
    }

    var racer_items = [];
    function grabRacerRaces(groupnum) {
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.sortBy = encodeURIComponent(current_sort);
        addData.filterBy = encodeURIComponent('groupid_'+groupnum);
        // addData.raceStatus = 'past';
        addData.raceStatus = 'open';
        addData.page = 0;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('sportsbook/recent_races/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;
                next_race_array.push(racer_details[0]);
                if(groupnum == 4) {
                    setTimeout(function(){
                        loadRacerGroups(current_group_array,next_race_array,1);
                        sendUpdatedSchedule(next_race_array);
                    },500); 
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function sendUpdatedSchedule(currentarray) {
        let tmpschedule = [];
        for(var i = 0; i < currentarray.length;i++) {
            tmpschedule.push(currentarray[i]['race_unique_id']);
        }
        props.updateSchedule(tmpschedule);
    }

    function clickRaceGroupRace(e) {
        e.preventDefault();
        var race_group_id = 0;
        var race_hash = '';
        var race_hash_id = 0;
        let race_elem = e.target;
        while(race_group_id == 0) {
            if(race_elem.dataset.groupid) {
                race_group_id = race_elem.dataset.groupid;
                race_hash = race_elem.dataset.raceuniqueid;
                race_hash_id = race_elem.dataset.raceid;
            } else {
                race_elem = race_elem.parentElement;
            }
        }

        $(".explorer-group-toggle").each(function() {
            $(this).removeClass('grey-light-grey-button-link-active');
        })
        $("#grouptoggle"+race_group_id).addClass('grey-light-grey-button-link-active');
        setTimeout(function() {
            $("#grouptoggle"+race_group_id).addClass('grey-light-grey-button-link-active');
        },50);

        if (race_hash.length > 0) {
            $(".exporer-row").each(function(){
                $(this).removeClass("grey-light-grey-button-link-active");
            })
            $("#race_innerrow_"+race_hash_id).addClass("grey-light-grey-button-link-active");
            props.switchRaces(race_hash);
        }

        switchRaceGroup(race_group_id);
    }

    function changeRaceGroupSelect() {
        var new_group_id = $("#raceGroupDropdown").val();
        switchRaceGroup(new_group_id);
    }

    function switchRaceGroup(groupid) {
        current_group_id = groupid;
        current_filter = 'groupid_'+groupid;
        $(".explorer-group-toggle").each(function() {
            $(this).removeClass('grey-light-grey-button-link-active');
        })
        $("#grouptoggle"+groupid).addClass('grey-light-grey-button-link-active');
        // for(var z = 0; z < current_group_array.length;z++) {
        //     if(groupid == current_group_array[z]['race_group_id']) {
        //         setGroupPickMade(current_group_array[z]['user_predictions']);
        //     }
        // }
        
        // grabRacerRaces(0);
        setTimeout(function() {
            $("#grouptoggle"+groupid).addClass('grey-light-grey-button-link-active');
        },50);

        props.updateRaceGroup(current_group_id);
        
    }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function loadRacerGroups(racearr,scheduled_race_array,usePassedGroup) {
        var group_section_html = [];
        var group_dropdown_html = [];
        var section_predictions = 0;
        for(var z = 0; z < racearr.length;z++) {
            var race_group_id = racearr[z].race_group_id;
            var race_group_name = racearr[z].race_group_name;
            var group_race_number = racearr[z].group_race_number;
            var total_races = racearr[z].total_races;
            var total_predictors = racearr[z].total_predictors;
            var user_predictions = racearr[z].user_predictions;
            var user_predictions_correct = racearr[z].user_predictions_correct;
            var buttonwidth = (100/racearr.length)+'%';
            if(race_group_name.toUpperCase() == 'LEGENDARY EXILED FRIENDLY') {
                race_group_name = 'LEGENDARY EXILED';
            }
            var raceidwatch = scheduled_race_array[z]['race_id'];
            var racehash = scheduled_race_array[z]['race_unique_id'];
            var tmpsection = <div key={z} className="col s12 m3 center-align" style={{padding:'5px',width:buttonwidth}}>
                                <div id={"grouptoggle"+race_group_id} className="row grey-light-grey-button-link explorer-group-toggle" 
                                    style={{padding:'8px 15px 7px',margin:'0px',borderRadius:"10px"}}
                                    data-groupid={race_group_id} 
                                    data-raceid={raceidwatch}
                                    data-raceuniqueid={racehash}
                                    onClick={(e)=>clickRaceGroupRace(e)}>
                                    <div className="col s12 white-text" style={{fontSize:"14px"}}>RACE {group_race_number}</div>
                                    <div className="col s12 headline-text" style={{fontSize:"12px",overflow:'hidden'}}>{race_group_name.toUpperCase()}</div>
                                    <div className="col s12 exr-yellow-text" style={{fontSize:"14px"}}>RACE #{raceidwatch}</div>
                                </div>
                            </div>;
            var tmpsectionselect = <option value={race_group_id}>RACE {group_race_number} // {race_group_name.toUpperCase()}</option>;
            group_section_html.push(tmpsection);
            group_dropdown_html.push(tmpsectionselect);
        }

        let selecthtml = <select onChange={changeRaceGroupSelect} id="raceGroupDropdown" className="input-field" style={{fontSize:'20px',fontFamily:'helvetica'}}>
                            {group_dropdown_html}
                        </select>
        setRaceGroupHTML(group_section_html);
        setRaceGroupDowndown(selecthtml);
        // if(usePassedGroup == 1) {
        //     switchRaceGroup(props.raceGroupID);
        // } else {
        //     switchRaceGroup(current_group_id);
        // }
    }

    function loadRacerRaces(racearr,pagenum) {
        var past_section_html = [];
        var section_predictions = 0;
        for(var z = 0; z < racearr.length;z++) {
            var race_id = racearr[z].race_id;
            var race_unique_id = racearr[z].race_unique_id;
            var race_name = racearr[z].race_name;
            var final_race_name = 'RACE #'+race_id;
            var race_coins_earned = racearr[z].race_final_coins - racearr[z].race_entry_fee;
            var race_xp_earned = racearr[z].race_final_xp;
            var race_standing = racearr[z].race_final_position;
            var racer_sim_watched = racearr[z].racer_sim_watched;
            var race_laps = racearr[z].race_laps;
            var total_racers = racearr[z].total_racers;
            var race_scoring = racearr[z].race_scoring;
            var relevance_rating = racearr[z].total_relevance;
            var user_race = racearr[z].user_race;
            var user_predicted = racearr[z].user_predicted;
            var user_friend = racearr[z].user_friend;
            var invited_by = '';
            var racer_names = '';

            var open_invite_race = racearr[z].open_invite_race;
            var entry_window_closed = racearr[z].entry_window_over;
            var date_entry_close = racearr[z].date_race_entry_close_eastern;
            var date_predictions_close = racearr[z].date_race_predictions_close_eastern;
            var date_simulation_close = racearr[z].date_race_simulated_close_eastern;

            // if(open_invite_race == 1) {
            //     final_race_name = 'Open '+final_race_name;
            // }
            if (race_scoring == 'undefined') {
                race_scoring = 'STANDARD';
            }

            let race_type_text = 'OPEN // '+race_scoring;
            if (race_laps >= 10) {
                race_type_text = 'DISTANCE // '+race_scoring;
            }
            let race_highlight = '';
            let race_highlight_text = '';
            if (relevance_rating > 0) {
                let highlight_text = 'Your Race';
                if(user_race > 0) {
                    highlight_text = 'Your Race';
                } else if(user_predicted > 0) {
                    highlight_text = 'You Predicted';
                } else if (user_friend > 0) {
                    highlight_text = 'Your Friend is Racing';
                }
                race_highlight = <span className="left" style={{marginLeft:'-5px',marginRight:'5px',marginTop:'1px',lineHeight:'16px'}}>
                    <i className="material-icons exr-yellow-text" title={highlight_text} style={{fontSize:'16px',lineHeight:'16px'}}>star</i>
                    </span>;
                race_highlight_text = <span className="left exr-yellow-text" style={{marginLeft:'10px'}}>{highlight_text.toUpperCase()}</span>;
            }

            var statuscolor = 'white black-text';
            if(race_standing == 1) {
                statuscolor = 'exr-red-bg white-text';
            } 
            if(race_standing != null) {
                race_standing = ordinal_suffix_of(race_standing).toUpperCase()+' PLACE';
            } else {
                race_standing = 'DNP';
            }

            var days_ended = racearr[z].days_since_race_ended;
            var race_date = '';
            if(days_ended == 0) {
                race_date = "Today";
            } else if (days_ended == 1) {
                race_date = "Yesterday";
            } else {
                race_date = days_ended+" Days Ago";
            }


            /* Calculate Race Time */
            var racer_final_seconds = racearr[z].race_final_time;
            if(racer_final_seconds == null) {
                var finaltimetxt = '00:00:00';
            } else {
                racer_final_seconds = racer_final_seconds .toString();
                var millisplit = racer_final_seconds.split(".");
                if(millisplit.length == 1) {
                    var millicount = '00';
                    var totalseconds = parseInt(millisplit[0]);
                } else {
                    var millicount = millisplit[1];
                    var totalseconds = parseInt(millisplit[0]);
                }
                if(millicount.length == 1) {
                    millicount = millicount+'0';
                }
                var totalmins = Math.floor(totalseconds / 60);
                var finalseconds = totalseconds - (totalmins*60);
                var finaltimetxt = '';
                if(totalmins < 10) {
                    finaltimetxt = finaltimetxt+'0';
                }
                finaltimetxt = finaltimetxt+totalmins+':';
                if(finalseconds < 10) {
                    finaltimetxt = finaltimetxt+'0';
                }
                finaltimetxt = finaltimetxt+finalseconds+':';
                
                finaltimetxt = finaltimetxt+millicount;
            }

            var person_icon = 'person';
            var invited_by_text = '';
            var total_predictors = 0;
            for(var x = 0; x < racearr[z].racer_info.length;x++) {
                total_predictors = total_predictors + racearr[z]['racer_info'][x]['total_predictors']
            }
            
            var rowkey = z+(pagenum*total_page_results*100);
            var nfticondisplay = 'none';
            var tmp_html = <div key={rowkey} className="col s12 l6" id={'race_row_'+race_id} style={{padding:'5px'}}>
                            <div id={'race_innerrow_'+race_id} data-raceuniqueid={race_unique_id} data-raceid={race_id} onClick={(e)=>switchToRace(e)} 
                                className="row grey-light-grey-button-link exporer-row" 
                                style={{margin:'0px',padding:'10px',borderRadius:'10px', cursor:'pointer'}}>
                                <div className="col s12">
                                    <span className="left">
                                        {race_highlight}
                                        <span className="left">{final_race_name.toUpperCase()}</span>
                                        
                                    </span>
                                    <div className="right" style={{fontSize:'14px'}}>
                                        <span className="left" style={{paddingRight:'5px',marginTop:'2px'}}>
                                            <i className="material-icons race-board-row-launch-icon " style={{fontSize:'14px'}}>launch</i>
                                        </span>
                                        <span className="left">VIEW</span>
                                    </div>
                                </div>
                                <div className="col s12 l6">
                                    <div className="left left-align" style={{marginRight:'15px'}}>
                                        
                                        {race_type_text.toUpperCase()+" // "+total_racers+' RACERS'}
                                    </div>
                                </div>
                                <div className="col s12 l6 right-align  hide-on-small-only show-on-med-and-up">
                                    <div className="right" style={{marginRight:'0px'}}>
                                        <span className={"new badge left white black-text"} style={{fontWeight:'bold'}} data-badge-caption={total_predictors+' PREDICTORS'}></span>
                                    </div> 
                                    <div className="right" style={{marginRight:'0px'}}>
                                        {race_highlight_text}
                                    </div>
                                </div>
                                <div className="col s12 show-on-small hide-on-med-and-up" style={{marginTop:'5px'}}>
                                    <div className="left" style={{marginRight:'0px'}}>
                                        <span className={"new badge left white black-text"} style={{fontWeight:'bold',marginLeft:'0px'}} data-badge-caption={total_predictors+' PREDICTORS'}></span>
                                    </div> 
                                    <div className="left" style={{marginRight:'0px'}}>
                                        {race_highlight_text}
                                    </div>
                                </div>
                            </div>
                        </div>;
            past_section_html.push(tmp_html);

            if(props.raceID == 'waitroom') {
                // props.switchRaces(racearr[z].race_unique_id);
            }
        }

        /* Add No Past Races */
        if((past_section_html.length == 0)&&(pagenum == 0)) {
            var tmp_html = <div className="col s12" style={{marginBottom:'5px'}}>
                                NO PAST RACES
                            </div>;
            past_section_html.push(tmp_html);
        }
        
        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = sectionHTMLArray.concat(past_section_html);
        } else {
            final_section_html_array = past_section_html;
        }
        sectionHTMLArray = final_section_html_array;
        setSectionHTML(final_section_html_array);

        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(past_section_html.length == total_page_results) {
            
            showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                <div id="showmorebutton" className="center-align" title="Show More Race Results">
                                    <span className="round-border-button" style={{fontSize:'20px'}} title="Show More Race Results"  onClick={() => showMoreResults(nextpage) }>
                                        SHOW MORE RACES
                                    </span>
                                </div>
                                <div id="showmoreloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreHTML(showmorebutton);

        setTimeout(function(){
            $("#showmorebutton").show();
            $("#showmoreloading").hide();
        },500);
        setTimeout(function(){
            $("#showmorebutton").show();
            $("#showmoreloading").hide();
        },2000);
    }

    function showMoreResults(pagenum) {
        $("#showmorebutton").hide();
        $("#showmoreloading").show();
        grabRacerRaces(pagenum);
    }

    function switchToRace(e) {
        e.preventDefault();
        var race_hash = '';
        var race_hash_id = 0;
        let race_elem = e.target;
        while(race_hash == '') {
            if(race_elem.dataset.raceuniqueid) {
                race_hash = race_elem.dataset.raceuniqueid;
                race_hash_id = race_elem.dataset.raceid;
            } else {
                race_elem = race_elem.parentElement;
            }
        }
        if (race_hash.length > 0) {
            $(".exporer-row").each(function(){
                $(this).removeClass("grey-light-grey-button-link-active");
            })
            $("#race_innerrow_"+race_hash_id).addClass("grey-light-grey-button-link-active");
            props.switchRaces(race_hash);
        }
    }

    function startPageSelects() {
        var select_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(select_elems,{classes:'exr-select'});

        // var tooltips = document.querySelectorAll('.prize-tips');
        // var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    }

    function sortRacewayRaces() {
        current_sort = $("#racewaySort").val();
        grabRacerRaces(0);
    }


    if(entrySet == false) {
        setEntry(true);
        grabRacerGroups();
        setCollectionSelect();
    }

    return(<div className="row exr-new-dark-grey" style={{borderRadius:'10px',padding:'20px 10px'}}>
                <div className="col s12" style={{marginBottom:'10px',position:'relative'}}>
                    <div className="row" style={{margin:'0px'}}>
                        <div className="col s12 center-align" style={{fontSize:"24px",paddingRight:'20px',margin:'5px 0px'}}>
                            <span className="left exr-light-green-text" style={{width:'100%',fontSize:"14px"}}>YOUR {totalRacePicks} RACES</span><br/>
                            <span className="left headline-text" style={{width:'100%',fontSize:"24px",marginTop:'-15px'}}>WATCH RACES</span>
                        </div>
                    </div>
                    <div id="race-collection-select" style={{position:'absolute',right:'20px',top:'20px'}}>
                        {race_collection_select_html}
                    </div>
                    <div id="race-group-buttons" className="row hide-on-med-and-down show-on-large-only" style={{margin:'0px'}}>
                        {race_group_html}
                    </div>
                </div>
            </div>);
};
