import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import _isEmpty from "lodash/isEmpty";
import { ethers } from "ethers";
import {
  InjectedConnector,
  UserRejectedRequestError,
  NoEthereumProviderError,
} from "@web3-react/injected-connector";

import { toast } from "react-toastify";
import {
  setRacerContract,
  setMintPackContract,
  setPilotContract,
  setBoosterContract,
  setTxHash,
  setTxStatus,
  setTransactionError,
  setTxReceipt,
  addMintedId,
} from "../../../redux/Web3/actions";

import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";

import RacerContract from "../../../helpers/RacerContract";
import MintPackContract from "../../../helpers/MintPackContract";
import PilotContract from "../../../helpers/PilotContract";
import BoosterContract from "../../../helpers/BoosterContract";

const MAINNET_CHAIN_ID = 1;
const ROPSTEN = 3;
const RINKEBY = 4;
const KOVAN = 42;
const MOONBASE = 1287;
const HARDHAT_ID = 31337;
const MOONBEAM = 1284;
const ETHERIUM = 1;
const BINANCE = 56;
const POLYGON = 137;
const ARBITRUM = 42161;
const AVALANCHE = 43114;

const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: [
    MAINNET_CHAIN_ID,
    HARDHAT_ID,
    ROPSTEN,
    RINKEBY,
    KOVAN,
    MOONBASE,
    MOONBEAM,
    ETHERIUM,
    BINANCE,
    POLYGON,
    ARBITRUM,
    AVALANCHE
  ],
});

export const WalletHeader = () => {
  const dispatch = useDispatch();
  const { txHash, racerContract, mintIds, mintPackContract } = useSelector((state) => state.web3);
  const { location, page_hash, signup_loading, game_loading } = useSelector((state) => state.app);

  const { active, chainId, library, error, account, deactivate, activate } =
    useWeb3React();

  let txStatusInterval;
  let headerhtml = "";

  // recreate the contract any time there's a change to the provider
  useEffect(() => {
    if (error) {
      console.log("ERROR");
      console.log(error);
    }
    const signer = library?.getSigner();
    if (!signer) {
      return () => {};
    }

    const pilotContract = new PilotContract(chainId, signer);
    dispatch(setPilotContract(pilotContract));

    const racerContract = new RacerContract(chainId, signer);
    dispatch(setRacerContract(racerContract));

    const boosterContract = new BoosterContract(chainId, signer);
    dispatch(setBoosterContract(boosterContract));
    
  }, [chainId, library, active, account]);

  async function connectWallet() {
    if (!active) {
      try {
        await activate(INJECTED_CONNECTOR, null, true);
      } catch (err) {
        // if((window.location.href !== '/')&&(location != 'splash')) {
        //   waitLogout(2);
        // }
        console.log(err);
      }
    }
  }

  function waitLogout(num) {
      if ((!active)&&(num == 0)) {
        dispatch(setRacerLogin('',''));
        dispatch(updateLocation("splash"));
        window.location.href= '/';
      } else {
        setTimeout(function(){
          waitLogout((num-1));
        },1000);
      }
  }

  async function pollTxHashStatus(txHash) {
    if (library) {
      const provider = new ethers.providers.Web3Provider(library.provider);
      const txReceipt = await provider.getTransactionReceipt(txHash);

      dispatch(setTxStatus("PENDING"));

      if (txReceipt) {
        if (txReceipt.status === 0) {
          dispatch(setTxStatus(null));
          dispatch(setTransactionError("Transaction failed"));
          clearInterval(txStatusInterval);
        }
        if (txReceipt.status === 1) {
          dispatch(setTxStatus("SUCCESS"));
          clearInterval(txStatusInterval);

          racerContract.subscribeToTransferEvent(account, (id) => {
            // TODO: hack fix this
            //console.log({ mintIds });
            if (!mintIds.includes(id)) {
              dispatch(addMintedId(id));
              toast.success(`Received Token ID: ${id}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
        }
        dispatch(setTxHash(null));
      }
    }
  }

  useEffect(() => {
    //console.log("nex TX Hash: ", txHash);
    if (txHash) {
      pollTxHashStatus(txHash);
      txStatusInterval = setInterval(() => {
        pollTxHashStatus(txHash);
      }, 1000);
    }
  }, [txHash]);

  return (<></>);
};

