import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import { updateLocation } from "../../../../../redux/App/actions";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import "../../races_home.css";
import $ from "jquery";

export const LeaguesPage = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [race_results_html, setResultsHTML] = useState('');
    const [bot_races_html, setBotRacesHTML] = useState('');
    const [invite_races_html, setInviteRacesHTML] = useState('');
    const [league_races_html, setLeagueRacesHTML] = useState('');
    const [all_races_html, setAllRacesHTML] = useState('');
    const [boardwidth,setBoardWidth] = useState('90%');
    const [boardlmargin,setBoardLMargin] = useState('5%');

    let pilot_sample = ['https://exr.mypinata.cloud/ipfs/Qmb26S3DUyx8Mc4YsMTpKffErdDyub6wRZRovAECqYJnNG',
    'https://exr.mypinata.cloud/ipfs/Qmey7boQhHUYTg3a7d1M67Uin3Jk8cfLA92PhmKvnF8SG2',
    'https://exr.mypinata.cloud/ipfs/QmS9cJMDyq6BFJ62Fy18RWzi5aV56uGUErABVecPHVmz6C',
    'https://exr.mypinata.cloud/ipfs/Qmc4FmVw89j8ZtQYpMaCvhiJFnL9CYibdJRoJJ5vDuNahV',
    'https://exr.mypinata.cloud/ipfs/QmXWtKm5rNVt9FiA3dJaH8DuQdsD2cuzw67ECWcC1r1bRK'];

    let racer_sample = ['https://exr.mypinata.cloud/ipfs/QmbzvssmAJsTqYAzNznffLcZ7925ZmTygB6yuduCbNwcZc',
    'https://exr.mypinata.cloud/ipfs/QmPQNtj6H9pFZups68PhZ2EkuAG9z18uD69t61T3cjj9N7',
    'https://exr.mypinata.cloud/ipfs/QmWLUCzbbHH321Q8KLutnbobNQVFvnyQXmWmGkFMN7KMZf',
    'https://exr.mypinata.cloud/ipfs/QmWQBo8RLMdsMuqtJ7AQir5SUdsDRsda5pFND2aoPG6jc8',
    'https://exr.mypinata.cloud/ipfs/QmQdhfTKeDR4AAmqmxZxGiPaFix25f1heqfEXXWeHsgTiU'];

    let booster_sample = ['https://exr.mypinata.cloud/ipfs/QmNvJftiSsHv2NNzv4jCZX8MDSKj8ekbMBUXTk81pmTwbD',
    'https://exr.mypinata.cloud/ipfs/QmWb4amPihv4xzDafQZQaVgrovyM6bwFr7N63BrxKKqNTc',
    'https://exr.mypinata.cloud/ipfs/QmU5CcHRB1WffAoH7Hw66NyQfGB2x4vW4rRCJLiHXQSzGY',
    'https://exr.mypinata.cloud/ipfs/QmZME71wbTzotEi6c6jeRaCcEig4byLAZoVRmULRw9G2bo',
    'https://exr.mypinata.cloud/ipfs/QmUtwXtSUjuzZWrz5xZJykndZXSUgZ1NTDahaynhcoESVv'];

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    let items_checked = 'N';
    var racer_items = [];
    function grabRacerRaces() {

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/races_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data; 
                loadRacerRaces(racer_details,'invites');
                loadRacerRaces(racer_details,'leagues');
                loadRacerRaces(racer_details,'allraces');
            } else {
                loadRacerRaces([],'invites');
                loadRacerRaces([],'leagues');
                loadRacerRaces([],'allraces');
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadRacerRaces(racearr,racetype) {
        var allrace_section_html = [];
        var invited_section_html = [];
        var league_section_html = [];
        var invitedboxes = 0;

        for(var z = 0; z < racearr.length;z++) {
            var race_id = racearr[z].race_id;
            var race_unique_id = racearr[z].race_unique_id;
            var race_name = racearr[z].race_name;
            var final_race_name = race_name+' #'+race_id;

            var race_coins_earned = racearr[z].race_coin_prize;
            var race_xp_earned = racearr[z].race_xp_prize;
            var race_standing = racearr[z].race_final_position;
            var race_participant_id = racearr[z].race_participant_id;
            var race_entry_fee = racearr[z].race_entry_fee;
            var invited_by = racearr[z].invited_by_racer_name;
            var racer_names = racearr[z].racer_names;
            var racer_sim_watched = racearr[z].racer_sim_watched;
            var statuscolor = 'white black-text';
            if(race_standing == 1) {
                statuscolor = 'exr-red-bg white-text';
            } 
            if(race_standing != null) {
                race_standing = ordinal_suffix_of(race_standing)+'PLACE';
            } else {
                race_standing = 'DNP';
            }

            var days_ended = racearr[z].days_since_entered;
            var race_date = '';
            if(days_ended == 0) {
                race_date = "Today";
            } else if (days_ended == 1) {
                race_date = "Yesterday";
            } else {
                race_date = days_ended+" Days Ago";
            }

            let racingagainst = '';
            var nfticondisplay = 'none';
            if(((race_standing == 'DNP')||(racer_sim_watched == 0))) {
                var race_link = "/#/race_lineup/"+race_unique_id;
                if(race_standing != 'DNP') {
                    race_standing = 'READY TO RACE';
                    statuscolor = 'exr-red-bg white-text';
                } else if(race_participant_id > 0) {
                    race_standing = 'ENTERED';
                } else {
                    race_standing = 'NOT ENTERED';
                }
                var person_icon = 'person';
                var invited_by_text = invited_by;
                if(invited_by == 0) {
                    invited_by_text = '';
                    var total_invites = 0;
                    for(var x = 0; x < racer_names.length;x++) {
                        if(racer_names[x].toUpperCase() != $("#current-racer-handle").val().toUpperCase()) {
                            if(total_invites > 0) {
                                invited_by_text = invited_by_text+", "; 
                            }
                            total_invites = total_invites + 1;
                            if(total_invites < 3) {
                                invited_by_text = invited_by_text+racer_names[x];
                            } else if (total_invites == 3) {
                                var remaining_people = racer_names.length-3;
                                invited_by_text = invited_by_text+" +"+remaining_people+' More';
                            } else {
                                
                            }
                        }
                    }

                    if(total_invites == 0) {
                        invited_by_text = 'Invite Racers';
                        person_icon = 'person_add';
                        final_race_name = 'Race Battle #'+race_id;
                        racingagainst = 'No One';
                    } else {
                        racingagainst = invited_by_text;
                    }
                    
                    if(invited_by_text == 'EXR-Bot') {
                        final_race_name = 'Bot Race #'+race_id;
                    }
                }

                var feeicondisplay = 'block';
                if(race_entry_fee == 0) {
                    feeicondisplay = 'none';
                }
                    
                var tmp_html = <div key={z} className="row race-board-row" id={'race_row_'+race_id}>
                                <a data-leagueid={race_unique_id} onClick={(e)=>switchToLeague(e)} className="topnavtoggle">
                                    <div className="col s3">
                                        <span className="left topnavtoggle">
                                            {final_race_name.toUpperCase()}
                                        </span>
                                        <span className="left"><i className="material-icons topnavtoggle race-board-row-launch-icon">launch</i></span>
                                    </div>
                                    <div className="col s12 m9 right-align">
                                        <div className="left left-align" style={{minWidth:'85px',marginRight:'15px'}}>
                                            {race_date}
                                        </div>
                                        <div className="left left-align" style={{minWidth:'95px',marginRight:'15px'}}>
                                            <span className={"new badge left "+statuscolor} style={{fontWeight:'bold'}} data-badge-caption={race_standing}></span>
                                        </div>
                                        <div className="left">
                                            <span className="left left-align">
                                                <i className="material-icons grey-text" style={{marginTop:'-3px',marginRight:'5px'}}>{person_icon}</i>
                                            </span>
                                            <span className="left">{invited_by_text}</span>
                                        </div>
                                        <div className="right">
                                            <span className="race-board-prize-text" title="XP Earned">{race_xp_earned}</span>
                                            <span className="race-board-prize-icon" title="XP Earned">
                                                <img src="https://media.exiledracers.com/global/icons/exr_xp_icon.png" height="25px" />
                                            </span>
                                            <span className="race-board-prize-text" style={{marginLeft:'5px'}} title="EXR Awarded">{race_coins_earned}</span>
                                            <span className="race-board-prize-icon" title="EXR Awarded">
                                                <img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="25px" />
                                            </span>
                                            <span className="race-board-prize-text" style={{marginLeft:'5px',display:feeicondisplay}} title="Entry Fee">{race_entry_fee}</span>
                                            <span className="race-board-prize-icon2" style={{display:feeicondisplay}} title="Entry Fee">
                                                <i className="material-icons grey-text">toll</i>
                                            </span>
                                        </div>
                                    </div>
                                </a>
                            </div>;
                
                /* Setup All Races Row */
                allrace_section_html.push(tmp_html);

                /* Push League and Invite Gridboxes */
                if(final_race_name.indexOf("League") >= 0) {
                    let race_type = 'league';
                    let race_action = 'GO TO RACE';
                    let tmp_grid_box = createRaceBoxInfo(race_unique_id, race_type, final_race_name, race_date, race_coins_earned, race_xp_earned, race_action);
                    let final_grid_box = <div className="col s12 m6" style={{padding:'2.5px'}}>{tmp_grid_box}</div>;
                    league_section_html.push(final_grid_box);
                } else if((final_race_name.indexOf("Battle") >= 0)||(final_race_name.indexOf("Invite") >= 0)||(final_race_name.indexOf("Friendly") >= 0)) {
                    let race_type = 'invite';
                    let race_action = 'GO TO LEAGUE';
                    let pilot_image = pilot_sample[z];
                    let tmp_grid_box = createRaceBoxInfoInvite(race_unique_id, race_type, 'EXR Super League '+(z+1), racingagainst, race_coins_earned, race_xp_earned, race_action,pilot_image);
                    let final_grid_box = <div className="col s12 m4" style={{padding:'2.5px'}}>{tmp_grid_box}</div>;
                    if(invitedboxes < 3) {
                        invited_section_html.push(final_grid_box);
                    }
                    invitedboxes = invitedboxes+1;
                }
            }
        }

        /* Relay Proper Loading Even Based on Race Type */
        if(racetype == 'invites') { 
            loadInviteRaces(invited_section_html);
        } else if(racetype == 'leagues') {
            loadLeagueRaces(league_section_html);
        } else {
            loadAllRaces(allrace_section_html);
        }

        setTimeout(function(){
            props.sizeAdjust();
        },500);
    }

    function createRaceBoxInfo(race_hash, race_type, race_name, race_time, exr_amount, xp_amount, race_action, bgimage) {
        let race_info_html = <></>;
        let race_subheadline = '';
        if(race_type == 'daily') {
            race_subheadline = 'LEAGUE STATUS';
        } else if (race_type == 'invite') {
            race_subheadline = 'LEAGUE AGAINST';
        } else if (race_type == 'invite-banner') {
            race_subheadline = 'LEAGUE STATUS';
        } else if (race_type == 'league') {
            race_subheadline = 'LEAGUE STARTS IN';
        } else {
            race_subheadline = 'LEAGUE STARTS IN';
        }
        let bgimagetxt = "black url('"+bgimage+"')";

        race_info_html = <div className="page-gridbox" data-leagueid={race_hash} onClick={(e)=>switchToLeague(e)} style={{position:'relative','cursor':'pointer',background:bgimagetxt,backgroundSize:'cover',backgroundPosition:'center'}} title="Go To Race">
                            <div className="exr-trans-black-bg-subtle" style={{width:'100%',height:'100%',position:'absolute',top:'0px',left:'0px',zIndex:'-10px',borderRadius:'10px'}}></div>
                            <div className="page-gridbox-header">{race_name}</div>
                            <div className="page-gridbox-info">
                                <div className="page-gridbox-inforow headline-text" style={{marginBottom:'0px',lineHeight:'1',fontSize:'14px'}}>
                                    {race_subheadline}
                                </div>
                                <div className="page-gridbox-inforow code-text" style={{fontSize:'18px',marginTop:'5px',lineHeight:'1'}}>
                                    {race_time}
                                </div>
                                <div className="page-gridbox-inforow" style={{marginTop:'10px'}}>
                                    <span className="left" style={{marginTop:'-2px',marginLeft:'-5px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="25px" />
                                    </span>
                                    <span className="left" style={{marginLeft:'2px',marginRight:'15px',fontSize:'16px'}}>
                                        {exr_amount}
                                    </span>
                                    <span className="left" style={{marginTop:'-1.5px'}}>
                                        <i className="material-icons white-text" style={{height:'26px'}}>person</i>
                                    </span>
                                    <span className="left" style={{marginLeft:'2px',fontSize:'16px'}}>
                                        {xp_amount}
                                    </span>
                                </div>
                                <div className="page-gridbox-inforow">
                                    <span className="left round-border-button">
                                        {race_action}
                                    </span>
                                </div>
                            </div>
                            
                        </div>;

        return race_info_html;
    }

    function createRaceBoxInfoInvite(race_hash, race_type, race_name, race_time, exr_amount, xp_amount, race_action, bgimage) {
        let race_info_html = <></>;
        let race_subheadline = '';
        if(race_type == 'daily') {
            race_subheadline = 'LEAGUE STATUS';
        } else if (race_type == 'invite') {
            race_subheadline = 'LEAGUE AGAINST';
        } else if (race_type == 'invite-banner') {
            race_subheadline = 'LEAGUE STATUS';
        } else if (race_type == 'league') {
            race_subheadline = 'LEAGUE STARTS IN';
        } else {
            race_subheadline = 'LEAGUE STARTS IN';
        }
        let bgimagetxt = "black url('"+bgimage+"')";

        race_info_html = <div className="page-gridbox" data-leagueid={race_hash} onClick={(e)=>switchToLeague(e)} style={{position:'relative','cursor':'pointer'}} title="Go To Race">
                            <div className="exr-trans-black-bg-subtle" style={{width:'100%',height:'100%',position:'absolute',top:'0px',left:'0px',zIndex:'-10px',borderRadius:'10px'}}></div>
                            <div className="page-gridbox-header">{race_name}</div>
                            <div className="page-gridbox-info">
                                <div className="page-gridbox-inforow headline-text" style={{marginBottom:'0px',lineHeight:'1',fontSize:'14px'}}>
                                    {race_subheadline}
                                </div>
                                <div className="page-gridbox-inforow code-text" style={{fontSize:'18px',marginTop:'5px',lineHeight:'1'}}>
                                    {race_time}
                                </div>
                                <div className="page-gridbox-inforow" style={{marginTop:'10px'}}>
                                    <span className="left" style={{marginTop:'-2px',marginLeft:'-5px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="25px" />
                                    </span>
                                    <span className="left" style={{marginLeft:'2px',marginRight:'15px',fontSize:'16px'}}>
                                        {exr_amount}
                                    </span>
                                    <span className="left" style={{marginTop:'-3.5px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_xp_icon.png" height="26px" />
                                    </span>
                                    <span className="left" style={{marginLeft:'2px',fontSize:'16px'}}>
                                        {xp_amount}
                                    </span>
                                </div>
                                <div className="page-gridbox-inforow">
                                    <span className="left round-border-button">
                                        {race_action}
                                    </span>
                                </div>
                            </div>
                            
                        </div>;

        return race_info_html;
    }


    
    function loadBotRaces(racesleft) {
        let races_html = <></>;

        let race_hash = 'bot-race';
        let race_type = 'daily';
        let race_name = 'Daily Bot Races';
        let race_time = racesleft+' DAILY RACES LEFT'; 
        let exr_amount = 50;
        let xp_amount = 25;
        let race_action = 'START BOT RACE'
        let bg_image = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
        let bot_html = createRaceBoxInfo(race_hash, race_type, race_name, race_time, exr_amount, xp_amount, race_action,bg_image);

        let league_html = []

        race_hash = 'base-racing-league';
        race_type = 'invite-banner';
        race_name = 'Base Racing League';
        
        race_time = 'RACING IN SESSION';
        exr_amount = 360;
        xp_amount = 1014;
        race_action = 'GO TO LEAGUE';
        bg_image = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_League_Banner_1718649763694.png';
        let banner_html = createRaceBoxInfo(race_hash, race_type, race_name, race_time, exr_amount, xp_amount, race_action,bg_image);
        let final_html = <><div className="col s12" style={{padding:'0px 2.5px',marginBottom:'20px'}}>
                                {banner_html}
                            </div></>;
        league_html.push(final_html);

        race_hash = 'pink-racing-league';
        race_type = 'invite-banner';
        race_name = 'Pink Racing League';
        race_time = 'SEASON COMPLETE'; 
        exr_amount = 360;
        xp_amount = 1372;
        race_action = 'GO TO LEAGUE';
        bg_image = 'https://media.exiledracers.com/assets/game/builder/Pink_Racing_League_Banner_1706050421120.png';
        banner_html = createRaceBoxInfo(race_hash, race_type, race_name, race_time, exr_amount, xp_amount, race_action,bg_image);
        final_html = <><div className="col s12" style={{padding:'0px 2.5px',marginBottom:'20px'}}>
                                {banner_html}
                            </div></>;
        league_html.push(final_html);

        race_hash = 'kwrl-season-2';
        race_type = 'invite-banner';
        race_name = 'KWRL Season 2';
        race_time = 'SEASON COMPLETE'; 
        exr_amount = 900;
        xp_amount = 45;
        race_action = 'GO TO LEAGUE';
        bg_image = 'https://media.exiledracers.com/assets/banners/KWRL_Season2_MiniHeader_Simple2.png';
        banner_html = createRaceBoxInfo(race_hash, race_type, race_name, race_time, exr_amount, xp_amount, race_action,bg_image);
        final_html = <><div className="col s12" style={{padding:'0px 2.5px',marginBottom:'20px'}}>
                                {banner_html}
                            </div></>;

        league_html.push(final_html);

        setBotRacesHTML(league_html);

        setTimeout(function() {
            props.sizeAdjust();
        },500);
    }

    function loadInviteRaces(htmlarr) {

        let races_html = <></>;
        if(htmlarr.length > 0) {
            races_html = <div className="col s12" style={{padding:'0px'}}>
                            <div className="row headline-text" style={{marginLeft:"2%",width:"96%",fontSize:"20px",marginTop:"30px",marginBottom:"0px"}}>
                                YOUR RACING LEAGUES
                            </div>
                            <div className="row" style={{marginLeft:"2%",width:"96%"}}>
                                <div className="divider-thin"></div>
                            </div>
                            <div className="row" style={{marginLeft:"2%",width:"96%",marginTop:'15px'}}>
                                {htmlarr}
                            </div> 
                        </div>;
        }
        setInviteRacesHTML(races_html);
    }

    function loadLeagueRaces(htmlarr) {
        let races_html = <></>;
        if(htmlarr.length > 0) {
            races_html = <div className="col s12"  style={{padding:'0px'}}>
                            <div className="row headline-text" style={{marginLeft:"2%",width:"96%",fontSize:"20px",marginTop:"30px",marginBottom:"0px"}}>
                                UPCOMING LEAGUE RACES
                            </div>
                            <div className="row" style={{marginLeft:"2%",width:"96"}}>
                                <div className="divider-thin"></div>
                            </div>
                            <div className="row" style={{marginLeft:"2%",width:"96%",marginTop:'15px'}}>
                                {htmlarr}
                            </div> 
                        </div>;
        }
        setLeagueRacesHTML(races_html);
    }

    function loadAllRaces(htmlarr) {
        let races_html = <></>;
        if(htmlarr.length > 0) {
            races_html = htmlarr;
        } else {
            races_html = <div className="row race-board-row" >
                            <div className="col s12" style={{marginBottom:'5px'}}>
                                NO UPCOMING RACES
                            </div>
                        </div>;
        }
        setAllRacesHTML(htmlarr);
    }
        
    function switchToLeague(e) {
        e.preventDefault();
        var league_hash = '';
        let league_elem = e.target;
        while(league_hash == '') {
            if(league_elem.dataset.leagueid) {
                league_hash = league_elem.dataset.leagueid;
            } else {
                league_elem = league_elem.parentElement;
            }
        }
        window.location.href= '/#/league/'+league_hash;
        dispatch(updateLocation("league"));
    }

    if(entrySet == false) {
        setEntry(true);
        loadBotRaces(3);
        grabRacerRaces();
    }

    function goToWorkshop() {
        window.location.href= '/#/workshop';
    }

    return(<>
                    <div className="col s12 headline-text" style={{fontSize:"30px"}}>
                        LEAGUE RACING
                    </div>
                    <div className="col s12" style={{marginBottom:"20px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12">
                        <div className="row">
                            <div className="col s12">
                                {bot_races_html}
                            </div>
                            {invite_races_html}
                            {league_races_html}
                            <div className="col s12" style={{padding:'0px',display:'none'}}>
                                <div className="row headline-text" style={{marginLeft:"2%",width:"96%",fontSize:"24px",marginTop:"30px",marginBottom:"0px"}}>
                                    ALL LEAGUES
                                </div>
                                <div className="row" style={{marginLeft:"2%",width:"96%"}}>
                                    <div className="divider-thin"></div>
                                </div>
                                <div className="row" style={{marginLeft:"2%",width:"96%",marginTop:'15px'}}>
                                    {all_races_html}
                                </div> 
                            </div>
                        </div>
                    </div>
                </>);
};