import React, {useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateLocation } from "../../../redux/App/actions";
import "../RaceTrack/constants.css";
import "../RaceTrack/race_track.css";
import "./style.css"
import Race from "../../../webgl/race/race.js";
import {Track} from "../../../webgl/race/track/track.js";
import {RaceLoader} from "../../../webgl/race/utils/raceLoader.js";
import {Random} from "../../../exr-webgl-hub/math/random.js";
import {RaceDesignLocal} from "../../../webgl/race/design/raceDesignLocal.js";
import {GuiPanelTrack} from "./Panels/guiPanelTrack.js";
import {GuiPanelEnvironment} from "./Panels/guiPanelEnvironment.js";
import {RaceElements} from "../../../webgl/race/raceElements.js";

let raceloadingstarted = false;
let race = null;
let tabsInitialized = false;

export const TrackBuilder = () => {
    let hash = '';
    if(window.location.href.indexOf("#/") >= 0) {
        if(window.location.href.split("#/")[1].indexOf("/") >= 0) {
            hash = window.location.href.split("#/")[1].split("/")[1];
        }
    }
    const canvas = useRef();
    const container = useRef();
    const minimap = useRef();
    const players = useRef();
    const leaderboard = useRef();
    const lapInfo = useRef();
    const timeInfo = useRef();
    const popups = useRef();
    const builder = useRef();
    const builderTrack = useRef();
    const builderEnvironment = useRef();
    const builderObjects = useRef();

    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    

    const reload = () => {
        console.log('reloading');
        if(race) {
            console.log(JSON.stringify(race.getDesign()));
        }
        
        race?.load(new RaceDesignLocal(JSON.stringify(race.getDesign())));
    };

    const save = () => {
        const element = document.createElement("a");

        element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(race.getDesign())));
        element.setAttribute("download", "design.json");
        element.style.display = "none";

        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    const initializeTabs = () => {
        builderTrack.current.appendChild(new GuiPanelTrack(popups.current, race).element);
        builderEnvironment.current.appendChild(new GuiPanelEnvironment(popups.current, race).element);

        deactivateTabs();
        activateUITrack();
    };

    const deactivateTabs = () => {
        builderTrack.current.style.display = "none";
        builderEnvironment.current.style.display = "none";
        builderObjects.current.style.display = "none";
    };

    const activateUI = element => {
        deactivateTabs();

        element.current.style.display = "block";
    };

    const activateUITrack = () => activateUI(builderTrack);
    const activateUIEnvironment = () => activateUI(builderEnvironment);
    const activateUIObjects = () => activateUI(builderObjects);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     window.location.href='/';
    // }

    // if(admin_racers.indexOf(parseInt(racerID)) === -1) {
    //     window.location.href='/';
    // }

    function goToTrackUploader() {
        dispatch(updateLocation("track_uploader"));
        window.location.href= '/#/track_uploader/';
    }

    window.addEventListener("resize", () => {
        if (canvas.current && container.current) {
            canvas.current.width = container.current.clientWidth;
            canvas.current.height = container.current.clientHeight;

            race?.setSize(container.current.clientWidth, container.current.clientHeight);
        }
    });

    useEffect(() => {
        if (!raceloadingstarted) {
            canvas.current.width = container.current.clientWidth;
            canvas.current.height = container.current.clientHeight;

            loadInitialRaceData();
        }
    }, [canvas]);

    function loadInitialRaceData() {
        if(canvas.current !== null) {
            raceloadingstarted = true;

            console.log('Loading Track');

            const random = new Random(Random.hashToSeed(hash));
            const raceLoader = new RaceLoader(
                new Track(hash,'hash'),
                null,
                new RaceDesignLocal(),
                new RaceElements(
                    canvas.current,
                    minimap.current),
                random);

            // deactivateTabs();

            raceLoader.load(progress => console.log(progress)).then(loadedRace => {
                race = loadedRace;
                console.log('Race Loaded')
                console.log(race);
                if (!tabsInitialized) {
                    initializeTabs();

                    tabsInitialized = true;
                }
            }).catch(() => {});
        }
    }

    window.addEventListener("hashchange",
    function(){
        window.location.reload();
    }, false);

    return(
        <div style={{position:'relative',width:'auto',height:'auto'}}>
            <div id="container" ref={container} style={{width:'100%',height:'100%',position:'fixed',top:'0px',left:'0px'}}>
                <canvas ref={canvas}/>
            </div>
            <div className="row white-text top-nav-header-wide">
                <div className="col s12 transparent white-text" style={{marginTop:'10px'}}>
                    <div className="left" style={{marginLeft:'5px',cursor:'pointer'}} onClick={()=>goToTrackUploader()} >
                        <img src="https://s3.amazonaws.com/media.exiledracers.com/global/exr_logo_v2_white_trans.png" height="40px" alt="EXR"/>
                    </div>
                </div>
            </div>

            <div id="popups" ref={popups}></div>
            <div id="builder-gui" ref={builder}>
                <ul>
                    <li onClick={activateUITrack}>Track</li>
                    <li onClick={activateUIEnvironment}>Environment</li>
                    <li onClick={activateUIObjects}>Objects</li>
                </ul>
                <div id="builder-content">
                    <div id="builder-track" ref={builderTrack}></div>
                    <div id="builder-environment" ref={builderEnvironment}></div>
                    <div id="builder-objects" ref={builderObjects}></div>
                </div>
                <ul>
                    <li onClick={reload}>Apply</li>
                    <li onClick={save}>Save</li>
                </ul>
            </div>

            <div className="row page-main-section" style={{position:'relative'}}>
                <div id="gui">
                    <div id="minimap" ref={minimap}></div>
                </div>
            </div>
        </div>);
};