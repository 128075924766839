import {RacersEvent} from "./racersEvent.js";

export class RacersEventBoost extends RacersEvent {
    /**
     * Construct a boost event
     * @param {number} frame The frame this event takes place at
     * @param {number[]} boosting The indices of the racers boosting at this frame
     */
    constructor(frame, boosting) {
        super(frame);

        this.boosting = boosting;
    }
}