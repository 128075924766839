/**
 * A camera controller segment which gets a cinematic camera
 */
export class CameraControllerSegment {
    /**
     * Construct a camera controller segment
     * @param {number} start The start track piece index
     * @param {number} length The track piece length
     * @param {CameraAnchor} [anchor] The anchor for this segment
     */
    constructor(start, length, anchor = null) {
        this.start = start;
        this.length = length;
        this.anchor = anchor;
    }

    /**
     * Get the end index
     * @returns {number} The end piece index
     */
    get end() {
        return this.start + this.length - 1;
    }

    /**
     * Extend the start of this segment
     * @param {number} pieceCount The track piece count
     */
    extendStart(pieceCount) {
        ++this.length;

        if (--this.start < 0)
            this.start += pieceCount;
    }

    /**
     * Extend the end of this segment
     */
    extendEnd() {
        ++this.length;
    }
}