import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import "../../race_landing.css";
import $ from "jquery";

import {TrackLoader} from "../../../../../webgl/race/utils/trackLoader.js"
import { Track } from "../../../../../webgl/race/track/track.js";
import { TrackMap } from "../../../../../webgl/race/track/map/trackMap.js";
import { Minimap } from "../../../../../webgl/race/minimap/minimap.js";
import {prize_tier_tooltip} from "../../race_landing_tooltips";

export const SettingsSelectionSection = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    const [headerSet, setHeader] = useState(false);
    const [settings_summary_html, setSummaryHTML] = useState('');
    const [trackID, setTrackID] = useState(0);
    const [selection_title,setTrackSelectionTitle] = useState('');

    const race_creator_types = ['training','bot-race','daily','alpha','invite'];

    let trackList = [];
    let current_scoring_setting = 'standard';
    let current_prize_setting = 'rpm';
    let current_entry_rpm = 25;
    let current_entry_paid = 1;
    let current_winnings_setting = 'tier';
    let current_entry_fee = '';
    let rpm_entry_options = [0,5,10,15,20,25,30,35,40,45,50]
    rpm_entry_options = [10,15,20,25]
    let paid_entry_options = [0,1,2,3,4,5,6,7,8,9,10]
    let training_race = 0;
    let invite_race = 0;
    if(props.hashRaceUniqueID == 'invite') {
        invite_race = 1;
    } else {
        training_race = 1;
    }

    function showRaceSettings() {
        var prize_pool_options = <><option value="rpm">RPM</option>
        <option value="paid">Paid Entry + RPM</option></>;
        
        // Overriding Until Paid Entry Available
        prize_pool_options = <><option value="rpm">RPM</option></>;
        paid_entry_options = [0]
        rpm_entry_options = [0]

        var winning_options = <><option value="tier">Tiered</option>
        <option value="winner">Winner Take All</option></>;

        var prize_pool_rpm_show = 'block';
        var prize_pool_rpm_text = 'Racers enter the race with an RPM Entry Fee that makes up the prize pool. Additional RPM for underdog winners supplied by EXR.';
        var prize_tiered_text = <>The winning racer gets largest share of the prize pool, with every racer getting some RPM back in order they finish.
        <br/><br/>View the&nbsp;<a className="white-green-link-active prize-tips" data-position="bottom" data-tooltip={prize_tier_tooltip}>EXR Prize Tiers</a></>;

        var prize_pool_rpm_options = new Array();
        var prize_pool_paid_options = new Array();
        
        if(training_race == 1) {
            prize_pool_rpm_show = 'none';
            prize_pool_rpm_text = <>Training Races are <a className="exr-light-green-text">free to enter</a> and always earn you RPM.</>;
            prize_tiered_text = <>Beat your EXR Trainer and earn <a className="exr-light-green-text">50 RPM</a>. Lose to your trainer and earn <a className="exr-light-green-text">25 RPM</a>.</>
            prize_tiered_text = <>Training Races have no winnings but let you practice on the same tracks you will be racing on. Choose different stacks to find the best one for each race.</>
            var tmp_rpm_option = <option key={0} value="0" selected>0 RPM</option>;
            prize_pool_rpm_options.push(tmp_rpm_option);

            var tmp_paid_option = <option key={0} value="0" selected>0 USDC</option>;
            prize_pool_paid_options.push(tmp_paid_option);

            winning_options = <><option value="tier">Tiered</option></>;
        } else {
            for(var i = 0; i < rpm_entry_options.length;i++) {
                var tmp_rpm_option = <option key={i+1} value={rpm_entry_options[i]}>{rpm_entry_options[i]} RPM</option>;
                if((invite_race == 1)&&(rpm_entry_options[i] == 25)) {
                    tmp_rpm_option = <option key={i+1} value={rpm_entry_options[i]} selected>{rpm_entry_options[i]} RPM</option>;
                }
                prize_pool_rpm_options.push(tmp_rpm_option)
            }

            for(var i = 0; i < paid_entry_options.length;i++) {
                var tmp_paid_option = <option key={i+1} value={paid_entry_options[i]}>{paid_entry_options[i]} USDC</option>;
                prize_pool_paid_options.push(tmp_paid_option)
            }
        }

        var tmp_html = <><div className="col s12 l4" style={{margin:"0px",padding:"2.5px",marginBottom:'10px'}}>
                            <div className="row exr-new-light-grey race-settings-section" style={{margin:"0px",padding:"0px",borderRadius:'10px',padding:'10px',fontSize:'16px',fontFamily:'helvetica'}}>
                                <div className="col s12 white-text" style={{marginBottom:'10px'}}>
                                <div className="row headline-text" style={{marginTop:'2px',marginBottom:'0px',fontSize:'16px'}}>
                                    <div className="left" style={{marginTop:'5px',marginLeft:'10px',marginRight:'10px'}}>RACE TYPE:</div>
                                        <div className="left" style={{marginBottom:'10px',width:'140px'}}>
                                            <select onChange={makeScoringSelection} id="settingsScoring" className="input-field" style={{fontSize:'20px',fontFamily:'helvetica'}}>
                                                <option value="standard">Standard Race</option>
                                                <option value="reverse">Inverse Race</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="divider-thin"></div>
                                </div>
                                <div id="race-type-info-standard" className="col s12 white-text">
                                    <div className="row" style={{padding:'0px',margin:'0px',marginTop:'10px'}}>
                                        <div className="left"><b>Standard Race</b></div>
                                    </div>
                                    <div className="row " style={{padding:'0px',margin:'0px',marginTop:'5px',marginBottom:'20px'}}>
                                        Classic race type with the <a className="exr-light-green-text">FASTEST</a> racers to complete the circuit winning. Pick your best stack in 
                                        this race.
                                    </div>
                                </div>
                                <div id="race-type-info-reverse" className="col s12 white-text">
                                    <div className="row" style={{padding:'0px',margin:'0px',marginTop:'10px'}}>
                                        <div className="left"><b>Inverse Race</b></div>
                                    </div>
                                    <div className="row" style={{padding:'0px',margin:'0px',marginTop:'5px',marginBottom:'20px'}}>
                                        An upside down race with the <a className="exr-light-green-text">SLOWEST</a> racers to complete the circuit winning. Pick your worst stack in 
                                        this race.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l4" style={{margin:"0px",padding:"2.5px",marginBottom:'10px'}}>
                            <div id="prize-pool-section" className="row exr-new-light-grey race-settings-section" style={{margin:"0px",padding:"0px",borderRadius:'10px',padding:'10px',fontSize:'16px',fontFamily:'helvetica'}}>
                                <div className="col s12 white-text" style={{marginBottom:'10px'}}>
                                    <div className="row headline-text" style={{marginTop:'2px',marginBottom:'0px',fontSize:'16px'}}>
                                        <div className="left" style={{marginTop:'5px',marginLeft:'10px',marginRight:'10px'}}>PRIZE POOL:</div>
                                        <div className="left" style={{marginBottom:'10px',width:'150px'}}>
                                            <select onChange={makePrizeSelection} id="settingsPrizes" className="input-field"
                                                style={{fontSize:'20px',fontFamily:'helvetica'}}>
                                                {prize_pool_options}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="divider-thin"></div>
                                </div>
                                <div id="race-prize-info-rpm" className="col s12 white-text">
                                    <div className="row" style={{padding:'0px',margin:'0px',marginTop:'10px'}}>
                                        <b>RPM Only</b>
                                    </div>
                                    <div className="row " style={{padding:'0px',margin:'0px',marginTop:'5px',marginBottom:'20px'}}>
                                        {prize_pool_rpm_text}
                                    </div>
                                </div>
                                <div id="race-prize-info-paid" className="col s12 white-text">
                                    <div className="row" style={{padding:'0px',margin:'0px',marginTop:'10px'}}>
                                        <b>Paid Entry + RPM</b>
                                    </div>
                                    <div className="row" style={{padding:'0px',margin:'0px',marginTop:'5px',marginBottom:'20px'}}>
                                        Racers enter the race with a Paid USDC Entry Fee and RPM Entry Fee that make up the prize pool.
                                    </div>
                                    <div className="row" style={{marginTop:'5px',marginBottom:'10px',fontSize:'16px'}}>
                                        <div className="left" style={{marginLeft:'10px',marginRight:'10px',marginBottom:'5px'}}>Paid Entry Fee:</div>
                                        <div className="left" style={{marginBottom:'10px',width:'120px'}}>
                                            <select onChange={makePaidEntrySelection} id="settingsPaidEntryFee" className="input-field light-grey-bubble-input"
                                                style={{fontSize:'20px',fontFamily:'helvetica'}}>
                                                {prize_pool_paid_options}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div id="race-prize-info-selection" className="col s12 white-text" style={{display:prize_pool_rpm_show}}>
                                    <div className="row" style={{marginTop:'5px',marginBottom:'20px',fontSize:'16px'}}>
                                        <div className="left" style={{marginLeft:'10px',marginRight:'10px',marginBottom:'5px'}}>RPM Entry Fee:</div>
                                        <div className="left" style={{marginBottom:'10px',width:'120px'}}>
                                            <select onChange={makeRPMEntrySelection} id="settingsRPMEntryFee" className="input-field light-grey-bubble-input"
                                                style={{fontSize:'20px',fontFamily:'helvetica'}}>
                                                {prize_pool_rpm_options}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l4" style={{margin:"0px",padding:"2.5px",marginBottom:'10px'}}>
                            <div className="row exr-new-light-grey race-settings-section" style={{margin:"0px",padding:"0px",borderRadius:'10px',padding:'10px',fontSize:'16px',fontFamily:'helvetica'}}>
                                <div className="col s12 white-text" style={{marginBottom:'10px'}}>
                                    <div className="row headline-text" style={{marginTop:'2px',marginBottom:'0px',fontSize:'16px'}}>
                                        <div className="left" style={{marginTop:'5px',marginLeft:'10px',marginRight:'10px'}}>WINNINGS:</div>
                                        <div className="left" style={{marginBottom:'10px',width:'140px'}}>
                                            <select onChange={makeWinningsSelection} id="settingsWinnings" className="input-field light-grey-bubble-input"
                                                style={{fontSize:'20px',fontFamily:'helvetica'}}>
                                                {winning_options}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="divider-thin"></div>
                                </div>
                                <div id="race-winning-info-tier" className="col s12 white-text">
                                    <div className="row" style={{padding:'0px',margin:'0px',marginTop:'10px'}}>
                                        <b>Tiered Distribution</b>
                                    </div>
                                    <div className="row " style={{padding:'0px',margin:'0px',marginTop:'5px',marginBottom:'20px'}}>
                                        {prize_tiered_text}
                                    </div>
                                </div>
                                <div id="race-winning-info-winner" className="col s12 white-text">
                                    <div className="row" style={{padding:'0px',margin:'0px',marginTop:'10px'}}>
                                        <b>Winner Take All</b>
                                    </div>
                                    <div className="row" style={{padding:'0px',margin:'0px',marginTop:'5px',marginBottom:'20px'}}>
                                        Winner of the race takes the entire prize pool. All other racers only receive XP for racing and no RPM.
                                    </div>
                                </div>
                            </div>
                        </div></>
        setSummaryHTML(tmp_html);
        setTimeout(function(){
            makeScoringSelection();
            makePrizeSelection();
            makeWinningsSelection();
            startPageSelects();
        },50);
    }

    function makeScoringSelection() {
        var scoring_choice = $("#settingsScoring").val();
        current_scoring_setting = scoring_choice;
        $("#race-type-info-standard").hide();
        $("#race-type-info-reverse").hide();
        $("#race-type-info-"+scoring_choice).show();
    }

    function makePrizeSelection() {
        var prize_choice = $("#settingsPrizes").val();
        current_prize_setting = prize_choice;
        $("#race-prize-info-rpm").hide();
        $("#race-prize-info-paid").hide();
        $("#race-prize-info-"+prize_choice).show();
        setTimeout(function(){
            updateRaceSettingsBoxes();
        },10);
    }

    function makeWinningsSelection() {
        var prize_choice = $("#settingsWinnings").val();
        current_winnings_setting = prize_choice;
        $("#race-winning-info-tier").hide();
        $("#race-winning-info-winner").hide();
        $("#race-winning-info-"+prize_choice).show();
    }

    function makeRPMEntrySelection() {
        var prize_choice = $("#settingsRPMEntryFee").val();
        current_entry_rpm = prize_choice;
    }

    function makePaidEntrySelection() {
        var prize_choice = $("#settingsPaidEntryFee").val();
        current_entry_paid = prize_choice;
    }

    function updateRaceSettingsBoxes() {
        var max_settings_height = 0;
        $(".race-settings-section").each(function(){
            if($(this).outerHeight() > max_settings_height) {
                max_settings_height = $(this).outerHeight();
            }
        });
        var final_height = max_settings_height+'px';
        if(window.innerWidth < 1000) {
            final_height = 'auto';
        }
        $(".race-settings-section").each(function(){
            $(this).css({'min-height':final_height});
        });
    }

    function startPageSelects() {
        var select_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(select_elems,{classes:'exr-select'});

        var tooltips = document.querySelectorAll('.prize-tips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    }

    if(headerSet == false) {
        setHeader(true);
        showRaceSettings();
    }

    window.addEventListener("resize", event => {
        updateRaceSettingsBoxes();
    });

    return(<div className="row">
        <div className="col s12 headline-text" style={{fontSize:"24px",marginTop:"15px"}}>
            1. SETUP RACE DETAILS
        </div>
        <div className="col s12">
            <div className="divider-thin"></div>
        </div>
        <div className="col s12" style={{marginTop:'20px'}}>
            {settings_summary_html}
        </div>
    </div>);
};