/**
 * A track cross-section for modelling
 */
export class TrackCrossSection {
    /**
     * Construct a track cross-section
     * @param {TrackSurface[]} surfaces The surfaces making up this cross-section
     */
    constructor(surfaces) {
        this.surfaces = surfaces;
    }

    /**
     * Get the vertex count for this slice
     * @returns {number} The vertex count
     */
    get vertexCount() {
        let count = 0;

        for (let surface = 0, surfaces = this.surfaces.length; surface < surfaces; ++surface)
            count += this.surfaces[surface].vertexCount;

        return count;
    }
}
