import { Contract, Signer, utils, providers, ethers } from "ethers";

import ABI from "./abis/mint-pack-abi.json";

import { mintPackContractAddress } from "../constants";
import { toast } from "react-toastify";

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

class MintContract {
  contract;
  provider;

  constructor(chainId, signer) {
    //console.log({ chainId });
    this.contractAddress = mintPackContractAddress[chainId];
    if (this.contractAddress) {
      this.contract = new Contract(this.contractAddress, ABI, signer);
    } else {
      return;
    }
  }

  mintRacePack() {
    //console.log(this.contract);
    //console.log("minting to contract");
    return this.contract.mint({
      gasPrice: ethers.utils.parseUnits("25", "gwei"),
    });
  }

  mintRacePackTo(address) {
    console.log(this.contract);
    console.log("minting to contract");
    console.log("manually setting gas Limit");
    return this.contract.adminMintTo(address, {
      gasPrice: ethers.utils.parseUnits("25", "gwei"),
      gasLimit: 500000,
    });
  }

  subscribeToTransferEvent(recipient, callback) {
    //console.log("subscribing to event for acc: ", recipient);
    const filter = this.contract.filters.Transfer(
      "0x0000000000000000000000000000000000000000",
      recipient
    );

    //TODO: need to test that this doesn't receive random events
    this.contract.on(filter, (from, to, id) => {
      //console.log("received");
      //console.log({ from, to, id: id.toNumber() });
      callback(id.toNumber());
    });
  }

  tokenOfOwnerByIndex(account, index) {
    return this.contract.tokenOfOwnerByIndex(account, index);
  }

  getOwnedTokens(address) {
    return this.contract.tokenOfOwnerByIndex(address, 0);
  }

  balanceOf(address) {
    return this.contract.balanceOf(address);
  }

  openPack(id) {
    return this.contract.openPack(id, {
      gasPrice: ethers.utils.parseUnits("25", "gwei"),
      gasLimit: 500000,
    });
  }

  checkPackOpenState(id) {
    return this.contract.checkPackOpenState(id);
  }

  tokenUri(id) {
    return this.contract.tokenURI(id);
  }
}

export default MintContract;
