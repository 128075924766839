import { LearnAccordion } from "../../Organisms/LearnAccordion";
import "./sectionTextWithBlock.css";

// Block has type: property, im
export const SectionTextWithBlock = ({
  title,
  description,
  block,
  blockPositionOnDesktop,
  subtitle,
  links,
  additionalNote,
  textImage,
}) => {
  const reverseSectionClassName =
    blockPositionOnDesktop === "left" ? null : "section-reverse";

  const renderBlock = (block) => {
    if (block.type === "image") {
      if (block.content && block.content.src) {
        return (
          <img
            src={block.content.src}
            alt={block.content.title}
            className="section-image"
          />
        );
      }
    } else if (block.type === "accordion") {
      if (
        block.content &&
        block.content.items &&
        block.content.items.length > 0
      ) {
        return (
          <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, }}>
            {block.content.items.map((item, id) => {
              return (
                <div key={id} style={{ marginBottom: `8px`}}>
                  <LearnAccordion title={item.title} content={item.description} />
                </div>
              );
            })}
          </div>
        );
      }
    } else if(block.type === "video") {
      if(block.content && block.content.src) {
        return (
          <video autoPlay muted playsInline loop>
            <source src={block.content.src} type="video/mp4" />
            <p>
              Your browser doesn't support HTML5 video. Here is a{" "}
              <a href={block.content.src}>link to the video</a> instead.
            </p>
          </video>
        );
      }
    } else if(block.type === "youtube") {
      if(block.content && block.content.youTubeID) {
        return (
          <iframe width="100%" src={"https://www.youtube.com/embed/"+block.content.youTubeID+"?"} 
          title={block.content.title} frameborder="0" style={{borderRadius:'10px',height:'45vh'}}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
        );
      }
    }
  };

  return (
    <div className="container">
      <div className={`section-container ${reverseSectionClassName}`}>
        <div className="section-block">{renderBlock(block)}</div>
        <div className="section-text">
          {textImage && textImage.src && (
            <div
              style={{
                position: `absolute`,
                top: 0,
                left: 0,
              }}
            >
              <img
                className="section-text-with-block-text-image"
                src={textImage.src}
                alt={textImage.title}
              />
            </div>
          )}
          <div
            style={{
              display: `flex`,
              alignItems: `flex-start`,
              flexDirection: `column`,
            }}
          >
            <div
              style={{
                display: `flex`,
                alignItems: `center`,
                flexDirection: `row`,
              }}
            >
              <h2 className="section-title">{title}</h2>
            </div>
            {subtitle && <p className="section-subtitle">{subtitle}</p>}
          </div>
          <p className="section-description">{description}</p>
          <div className="section-link-group" style={{ position: `relative`}}>
            {links &&
              links.length > 0 &&
              links.map((link, id) => {
                const isSecondaryLink = id === 1;

                return (
                  <a
                    className="section-link"
                    style={{
                      backgroundColor: isSecondaryLink ? `gray` : null,
                    }}
                    key={id}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {link.name}
                  </a>
                );
              })}
          </div>
          <div>
            {additionalNote && (
              <p className="section-additional-note">
                {additionalNote.label && (
                  <span className="section-additional-note-label">
                    {additionalNote.label}:{" "}
                  </span>
                )}
                {additionalNote.text && (
                  <span className="section-additional-note-text">
                    {additionalNote.text}
                  </span>
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
