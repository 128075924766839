import * as THREE from "three";

/**
 * A track model
 */
export class TrackModel {
    /**
     * Make a track mesh
     * @param {Track} data The track data to model
     * @param {TrackCrossSection} crossSection The track cross-section to extrude
     * @param material A material for this track model
     * @returns {Mesh} The mesh for this track
     */
    static makeMesh(data, crossSection, material) {
        const verticesPerSlice = crossSection.vertexCount;
        const slices = data.createSlices();
        const sliceCount = slices.length;
        const geometry = new THREE.BufferGeometry();
        const positions = [];
        const normals = [];
        const uvs = [];
        const indices = [];
        let slicePrevious = sliceCount - 1;

        for (let slice = 0; slice < sliceCount; ++slice) {
            let pointOffset = 0;

            for (let surface = 0, surfaceCount = crossSection.surfaces.length; surface < surfaceCount; ++surface) {
                const totalLength = crossSection.surfaces[surface].length;
                let length = 0;

                for (let point = 0, pointCount = crossSection.surfaces[surface].vertexCount; point < pointCount; ++point) {
                    const position = crossSection.surfaces[surface].points[point].clone();
                    const normal = crossSection.surfaces[surface].normals[point].clone();

                    slices[slice].transformVector(position);
                    slices[slice].rotateVector(normal);

                    positions.push(position.x, position.y, position.z);
                    normals.push(normal.x, normal.y, normal.z);
                    uvs.push(length / totalLength, .5);

                    if (point + 1 !== pointCount)
                        length += crossSection.surfaces[surface].points[point].distanceTo(crossSection.surfaces[surface].points[point + 1]);

                    if (point !== pointCount - 1)
                        indices.push(
                            slice * verticesPerSlice + pointOffset + point,
                            slicePrevious * verticesPerSlice + pointOffset + point,
                            slicePrevious * verticesPerSlice + pointOffset + point + 1,
                            slicePrevious * verticesPerSlice + pointOffset + point + 1,
                            slice * verticesPerSlice + pointOffset + point + 1,
                            slice * verticesPerSlice + pointOffset + point);
                }

                pointOffset += crossSection.surfaces[surface].vertexCount;
            }

            slicePrevious = slice;
        }

        geometry.setAttribute("position", new THREE.Float32BufferAttribute(positions, 3));
        geometry.setAttribute("normal", new THREE.Float32BufferAttribute(normals, 3));
        geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));
        geometry.setIndex(indices);

        return new THREE.Mesh(geometry, material);
    }

    /**
     * Construct a track model
     * @param {Track} track The track to model
     * @param {TrackCrossSection} crossSection The track cross-section to extrude
     * @param material A material for this track model
     */
    constructor(track, crossSection, material) {
        this.mesh = TrackModel.makeMesh(track, crossSection, material);
    }
}