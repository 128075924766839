import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "./races_home.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance} from "../../../utils";
import {LeaderboardsPage, LeaguesPage, PastRacesPage, UpcomingRacesPage, ClubsPage} from "./Sections";
import {RaceTrainingHomeBox, RaceTrainingLeaderboardBox} from "./Infoboxes";
import $ from 'jquery';

let web3;

let training_box_race_home = 0;
let training_box_leaderboard = 0;
let training_box_race_landing = 0;
let holder_user = 0;

export const RacesHome = () => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    
    if((racerID.length == 0)||(accountAddress.length == 0)) {
        dispatch(setRacerLogin('',''));
        dispatch(updateLocation("splash"));
        window.location.href= '/';
    }

    var url_race_unique_id = '';
    var tmp_current_section = 'upcoming';
    var pagehash = window.location.href.split("#/");
    if(pagehash.length > 1) {
        var pagehash2 = pagehash[1].replace("#/",'');
        var pagehash3 = pagehash2.split("/");
        if(pagehash3.length > 1) {
            tmp_current_section = pagehash3[1].replace("/",'');
        }
    }

    const [pageSet, setPage] = useState(false);
    const [item_list, setItemList] = useState([]);
    const [new_race_count, setNewRaces] = useState(<i className="material-icons workshop-nav-button-chevron">chevron_right</i>);
    const [current_section, setCurrentSection] = useState(tmp_current_section);
    const [current_page_html, setCurrentPageHTML] = useState('');
    const [current_scroll_html, setCurrentScrollHTML] = useState('');
    const [racer_handle, setRacerHandle] = useState('');

    var racer_items = [];
    function grabRacerInfo() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;
                setRacerHandle(result.data[0].racer_name);
                training_box_race_home = result.data[0]['training_race_home_box'];
                training_box_leaderboard = result.data[0]['training_leaderboard_box'];
                training_box_race_landing = result.data[0]['training_race_landing_box'];
                var total_pilots = result.data[0]['total_pilots'];
                var total_racecrafts = result.data[0]['total_racecrafts'];
                var total_boosters = result.data[0]['total_boosters'];
                if((total_pilots > 0)&&(total_racecrafts > 0)&&(total_pilots > 0)) {
                    holder_user = 1;
                }
                toggleRaces(current_section);
            }
            var new_races_check = $("#notification-new-races").val();
            if(new_races_check.length > 0) {
                if(parseInt(new_races_check) > 0) {
                    $("#racetrack-new-races-text").html(new_races_check);
                    $("#racetrack-new-races-text").show();
                } else {
                    $("#racetrack-new-races-text").hide();
                }
            }
        }).catch(error => {
            console.log(error);
            //dispatch(updateLocation("staging"));
        });
    }

    function toggleRaces(pagename) {
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");
        var trainingbox = '';
        var sectiontxt = <></>;
        if(pagename == 'past') {
            sectiontxt = <PastRacesPage racerHandle={racer_handle} sizeAdjust={adjustLargeNFTImage} holderUser={holder_user} />;
            window.location.href="/#/races_home";
        } else if(pagename == 'leagues') {
            sectiontxt = <LeaguesPage racerHandle={racer_handle} sizeAdjust={adjustLargeNFTImage} holderUser={holder_user} />;
            goToLeagues();
        } else if(pagename == 'leaderboards') {
            if(training_box_leaderboard == 0) {
                trainingbox = 'leaderboard';
            }
            sectiontxt = <LeaderboardsPage racerHandle={racer_handle} sizeAdjust={adjustLargeNFTImage} holderUser={holder_user} />;
        } else if(pagename == 'clubs') {
            sectiontxt = <ClubsPage racerHandle={racer_handle} sizeAdjust={adjustLargeNFTImage} holderUser={holder_user} />;
        } else {
            if(training_box_race_home == 0) {
                trainingbox = 'race_home';
            }
            sectiontxt = <UpcomingRacesPage racerHandle={racer_handle} sizeAdjust={adjustLargeNFTImage} holderUser={holder_user} />;
            window.location.href="/#/races_home";
        }

        setCurrentPageHTML(sectiontxt);

        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
        },5);
        if(trainingbox.length > 0) {
            setTimeout(function(){
                showTrainingInfobox(trainingbox);
            },1000);
            setTimeout(function(){
                showTrainingInfobox(trainingbox);
            },2750);
        }
    }

    function toggleRacesSelection() {
        var pageselection = $("#racesPageSelection").val();
        toggleRaces(pageselection);
    }

    function adjustLargeNFTImage() {
        var imagewidth = $("#largenftviewer").width();
        $("#largenftviewer").css({height:imagewidth+'px'});
        $("#nftdetailsright").css({height:imagewidth+'px'});
        var rightsideheight = $("#righsidecontent").height()-10;
        $("#leftrailnav").css({height:rightsideheight+'px'});
    }

    function showTrainingInfobox(boxid) {

        var tinfobox = document.getElementById('training_box_'+boxid);
        var info_instance = window.M.Modal.getInstance(tinfobox);
        info_instance.open();

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.infoBox = encodeURIComponent(boxid);
        axiosInstance.post('member/infobox_view/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var infobox_viewed = result.data;
                if(boxid == 'race_home') {
                    training_box_race_home = 1;
                    $("#current-racer-ibox-race-home").val('1');
                } else if(boxid == 'leaderboard') {
                    training_box_leaderboard = 1;
                    $("#current-racer-ibox-leaderboard").val('1');
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function goToLeaderboards() {
        window.location.href= '/#/open_leaderboard';
        dispatch(updateLocation("open_leaderboard"));
    }

    function goToLeagues() {
        window.location.href= '/#/races_home/leagues';
    }

    function goToRaceway() {
        window.location.href= '/#/raceway';
    }


    document.addEventListener('DOMContentLoaded', function() {
        // window.startPageModals();
    });

    window.addEventListener("hashchange",
    function(){ 
        //window.location.reload(); 
    }, false);

    window.addEventListener("resize",
    function(){ 
        adjustLargeNFTImage();
    }, false);


    if(pageSet == false) {
        setPage(true);
        //window.startPageModals();
        window.resetBodyWidth();
        grabRacerInfo();
        setTimeout(function(){
            var mnav_elem = document.getElementById('racesPageSelection');
            var mnav_instance = window.M.FormSelect.init(mnav_elem,{classes:'exr-select'});
        },500);
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            <div className="row page-width-wide page-clean-body">
                <div id="workshop-mobile-nav" className="col s12 border-section-full exr-new-dark-grey show-on-med-and-down hide-on-large-only" 
                        style={{position:'relative',padding:"5px",paddingBottom:'0px',marginBottom:'0px'}}>
                    <div className="left headline-text white-text" style={{fontSize:'16px',marginTop:"15px",marginBottom:"10px",marginLeft:'30px'}}>
                        &nbsp;THE RACETRACK
                    </div>
                    <div className="right" style={{fontSize:'16px',marginTop:"10px",marginBottom:"10px",marginRight:'30px'}}>
                        <select onChange={toggleRacesSelection} id="racesPageSelection" className="input-field light-grey-bubble-input"
                            style={{fontSize:'20px',fontFamily:'helvetica',padding:'0px'}}>
                            <option value="upcoming">Upcoming Races</option>
                            <option value="leaderboards">Leaderboards</option>
                            <option value="past">Past Races</option>
                        </select>
                    </div>
                </div>
                <div id="leftrailnav" className="col s12 l2 border-section-full exr-new-dark-grey hide-on-med-and-down" style={{height:'180vh',position:'relative',padding:"5px"}}>
                    <div style={{width:'100%'}}>
                        <div className="headline-text section-title-text center-align" style={{fontSize:'16px',marginTop:"20px",marginBottom:"10px"}}>
                            &nbsp;THE RACETRACK
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="upcomingtoggle"
                                    onClick={()=> toggleRaces('upcoming')}> 
                                <span className="left side-nav-button-text">Upcoming Races</span>
                                <span id="racetrack-new-races-text" className="top-nav-header-item-badge exr-red-bg white-text" style={{marginTop:'10px',display:'none'}}>
                                    14
                                </span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="leaguestoggle"
                                    onClick={()=> toggleRaces('leagues')}> 
                                <span className="left side-nav-button-text">Leagues</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section" style={{display:'none'}}>
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="clubstoggle"
                                    onClick={()=> toggleRaces('clubs')}> 
                                <span className="left side-nav-button-text">Racing Clubs</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="pasttoggle"
                                    onClick={()=> toggleRaces('past')}> 
                                <span className="left side-nav-button-text">Past Races</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="leaderboardstoggle"
                                    onClick={()=> goToLeaderboards()}> 
                                <span className="left side-nav-button-text">Past Cups</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="racewaytoggle"
                                    onClick={()=> goToRaceway()}> 
                                <span className="left side-nav-button-text">Raceway</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="righsidecontent" className="col s12 l10" style={{position:'relative', minHeight:'100vh', margin:"0px",padding:"0px"}}>
                    <div className="row border-section-full exr-new-dark-grey white-text" style={{minHeight:'100vh',position:'relative',width:"99.5%",marginLeft:"0.5%",padding:"25px"}}>
                        {current_page_html}
                    </div>
                </div>
            </div>
            <RaceTrainingHomeBox />
            <RaceTrainingLeaderboardBox />
        </div>);
};
