import api from "../../adapters/api";

const initialState = {
  hasAccount: false,
  loggedIn: false,
  status: null,
  // accountAddress: localStorage.getItem('exrWalletID')||'',
  // racerID: localStorage.getItem('exrRacerID')||'',
  accountAddress: localStorage.getItem('exrRacerWallet')||'',
  racerID: (localStorage.getItem('exrTokenVal')? (localStorage.getItem('exrTokenVal').indexOf('.') > 0 ? localStorage.getItem('exrTokenVal').split('.')[1] : 0): 0),
  userID: (localStorage.getItem('exrTokenVal')? (localStorage.getItem('exrTokenVal').indexOf('.') > 0 ? localStorage.getItem('exrTokenVal').split('.')[0] : 0): 0),
  spectatorID:  (localStorage.getItem('exrTokenVal')? (localStorage.getItem('exrTokenVal').indexOf('.') > 0 ? localStorage.getItem('exrTokenVal').split('.')[2] : 0): 0),
  loginTime: localStorage.getItem('exrTokenTime')||'',
  loginToken: localStorage.getItem('exrTokenID')||'',
  infoboxHowTo: 1
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCOUNT_ADDRESS":
      return {
        ...state,
        accountAddress: action.payload,
      };

    case "SET_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    
    case "SET_RACER_ID":
      return {
        ...state,
        racerID: action.payload,
      };
    
    case "SET_RACER_LOGIN":
      return {
        ...state,
        racerID: action.payload.racer_id,
        accountAddress: action.payload.wallet_id,
      };
    
    case "SET_USER_SIGNON":
        return {
          ...state,
          userID: action.payload.user_id,
          racerID: action.payload.racer_id,
          spectatorID: action.payload.spectator_id,
          loginToken: action.payload.token,
          loginTime: action.payload.token_time,
        };

    default:
      return state;
  }
};

export default accountReducer;
