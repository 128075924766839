import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "./race_track_retro.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerXP, setRacerCoins } from "../../../redux/Account/actions";
import {axiosInstance, convertHexToNumber} from "../../../utils";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { ImprovedNoise } from 'three/examples/jsm/math/ImprovedNoise.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import "./racer_helper";

let web3;
let detailedPoints = [];
let trackSegments = [];
let container;
let camera, controls, scene, renderer;
let mesh;
let lastTimestamp;
let trackID = 1;
let trackSummary = [];
let currentLap = 1;
let finalLeaderboard = [];
let finalLeaderboardTime = [];
let game_start = 'N';
let timerstart = 'N';
let racerFirstName = '';
let racerHandle = '';
let racerXP = 0;
let racerCoins = 0;
let winnersCoins = 0;
let totalRaceLaps = 5;
const trackRadius = 7000;
const trackWidth = 2000;
const trackSegmentSegments = 100;
const worldWidth = 256, worldDepth = 256, worldHalfWidth = worldWidth / 2, worldHalfDepth = worldDepth / 2;
const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector3();
let racer_list_store = [];
let leftturncount = 0;
let righturncount = 0;

export const RaceTrackRetro = () => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    const { location } = useSelector((state) => state.app);

    if((racerID.length == 0)||(accountAddress.length == 0)) {
        window.location.href='/';
    }

    var url_race_unique_id = '';
    var start_race_section = '';
    var racehash = window.location.href.split("#/");
    if(racehash.length > 1) {
        var racehash2 = racehash[1].replace("#/",'');
        var racehash3 = racehash2.split("/");
        if(racehash3.length > 1) {
            url_race_unique_id = racehash3[1].replace("/",'');
        }
        if(racehash3[0] == 'race') {
            start_race_section = 'details';
        } else {
            var racehash4 = racehash3[0].split("_");
            start_race_section = racehash4[1];
        }
    }
    
    const [pageSet, setPage] = useState(false);
    // const [racerXP, setXP] = useState(0);
    // const [racerCoins, setCoins] = useState(0);
    // const [racerFirstName, setFirstName] = useState('Tester');
    // const [racerHandle, setRacerHandle] = useState('Tester');
    const [race_id, setRaceID] = useState(0);
    const [race_unique_id, setRaceUniqueID] = useState(url_race_unique_id);
    const [participant_list, setPartipicantList] = useState([]);
    const [trackLoaded, setTrackLoaded] = useState(0);
    const [gameStart, setGameStart] = useState('N');
    const [final_racer_list, setRacerList] = useState([]);

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    function goToWorkshop() {
        window.location.href= '/#/workshop';
        //dispatch(updateLocation("workshop"));
    }

    function goToRaceResults() {
        window.location.href= '/#/race_results/'+race_unique_id;
        //dispatch(updateLocation("workshop"));
    }

    function grabRacerInfo() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);

        axiosInstance.post('member/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;

                // Set Racer XP, Coins and Name
                racerXP = racer_details[0].racer_xp;
                racerCoins = racer_details[0].racer_coins;
                racerFirstName = racer_details[0].first_name;
                racerHandle = racer_details[0].racer_name;
            }
        }).catch(error => {
            console.log(error);
        });
    }
    let items_checked = 'N';

    function grabRaceDetails() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(race_unique_id);

        axiosInstance.post('race/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;
                trackID = racer_details[0].race_track_id;
                totalRaceLaps = racer_details[0].race_laps;
                winnersCoins = racer_details[0].race_coin_prize;
                if(race_id == 0) {
                    setRaceID(racer_details[0].race_id);
                }
            }
        }).catch(error => {
            console.log(error);
            window.location.href='/';
        });
    }

    function grabRaceParticipantsSim() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(race_unique_id);

        axiosInstance.post('race/racer_sim_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var pracer_list = result.data;
                setPartipicantList(pracer_list);

                if(document.getElementById("trackLoaded").value == 'N') {
                    grabRaceTrackDetails();
                    document.getElementById("trackLoaded").value = 'Y';
                    setTimeout(function() { startRaceTrackSim(pracer_list); },1000);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }


    function grabRaceTrackDetails() {
        leftturncount = 0;
        righturncount = 0;
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(race_unique_id);

        axiosInstance.post('race/track_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var track_list = result.data;
                trackSegments = track_list;
                for(var i = 0; i < result.data.length;i++) {
                    if(result.data[i].track_type == 'turn') {
                        if(result.data[i].track_direction == 'left') {
                            leftturncount++;
                        } else {
                            righturncount++;
                        }
                    }
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function saveFinalRaceResults() {
        var final_leaderboard = document.getElementById("storeLeaderboard").value.split(",");
        var finalleaderarr = [];
        for(var k = 0; k < final_leaderboard.length;k++) {
            finalleaderarr.push(final_leaderboard[k]);
        }

        var final_leaderboard_times = document.getElementById("storeLeaderboardTimes").value.split(",");
        var finalleadertimesarr = [];
        for(var k = 0; k < final_leaderboard_times.length;k++) {
            finalleadertimesarr.push(final_leaderboard_times[k]);
        }
        for(var k = finalleadertimesarr.length; k < final_leaderboard.length;k++) {
            finalleadertimesarr.push(currentracetime);
        }

        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(race_unique_id);
        addData.finalStandings = finalleaderarr;
        addData.finalTimes = finalleadertimesarr;

        racer_list_store = [];
        trackSummary = [];
        currentLap = 1;
        finalLeaderboard = [];
        game_start = 'N';
        timerstart = 'N';
        
        // axiosInstance.post('race/save_result/',addData).then(result => {
        //     if(Object.keys(result.data).length > 0) {
        //         var race_result = result.data;
        //     }
        // }).catch(error => {
        //     console.log(error);
        // });
    }

    // Load Page Once With Info, Details, Participants
    if(pageSet === false) {
        setPage(true);
        racer_list_store = [];
        trackSummary = [];
        currentLap = 1;
        finalLeaderboard = [];
        game_start = 'N';
        timerstart = 'N';
        window.startPageModals();
        window.resetBodyWidth();
        grabRacerInfo();
        if(race_unique_id.length > 0) {
            grabRaceDetails();
            grabRaceParticipantsSim();
        }
    }
    
    let racer_list = [];
    
    function startGame() {
        showStartBanner();
        window.$("#startgamebuttonold").fadeOut();
        /* 
        window.$("#pausegamebutton").fadeIn();
        window.$("#finishgamebutton").fadeIn();
        */
    }
    function pauseGame() {
        game_start = 'N';
        timerstart = 'N';
        window.$("#startgamebuttonold").fadeIn();
        window.$("#pausegamebutton").fadeOut();
    }

    function finishRace(stoptime) {
        stopRaceTimer(stoptime);
        // if (racerID != 1) {
        //     saveFinalRaceResults();
        // }
        
        game_start = 'N';
        timerstart = 'N';

        setTimeout(function() {
            updateRaceBannerText('FINISH',3000);
        },500);

        setTimeout(function() {
            //window.$("#finalboardbox").fadeIn();
            window.$("#leaderboardbox").fadeOut();
            window.location.href= '/#/race_results/'+race_unique_id;
        },5000);

        window.$("#startgamebuttonold").fadeOut();
        window.$("#pausegamebutton").fadeOut();
        window.$("#finishgamebutton").fadeOut();
    }

    function showStartBanner() {
        updateRaceBannerText('GET READY!',1000);
        setTimeout(function() {
            updateRaceBannerText('STARTING IN',1000);
        },2000);
        setTimeout(function() {
            updateRaceBannerText('3',1000);
        },4000);
        setTimeout(function() {
            updateRaceBannerText('2',1000);
        },6000);
        setTimeout(function() {
            updateRaceBannerText('1',1000);
        },8000);
        setTimeout(function() {
            updateRaceBannerText('GO!',5000);
        },10000);
        setTimeout(function() {
            game_start = 'Y';
            timerstart = 'Y';
            raceAnimateStep(0,0);
            updateRaceTimer(0);
            startLapNumbers();
        },10500);
    }

    function startLapNumbers() {
        document.getElementById("lapinfo").innerText = 'LAP 1 / '+totalRaceLaps;
    }

    function updateRaceBannerText(bannertext,fadetime) {
        window.$("#racerbannerretro").fadeIn(1000);
        var finaltxt = '<span style="font-size: 80px;background: rgb(0,0,0,.3);color: white;border-radius: 10px;padding:10px 20px;border:1px solid white;">';
            finaltxt = finaltxt + bannertext + '</span>';
        window.$("#racerbannerretro").html(finaltxt);
        window.$("#racerbannerretro").fadeOut(fadetime);
    }

    function showLapBanner(lapnumber) {
        if(lapnumber == totalRaceLaps) {
            updateRaceBannerText('FINAL LAP',4000);
        } else {
            updateRaceBannerText('LAP '+lapnumber,4000);
        }
    }


    let currentracetime = 0.0;
    function updateRaceTimer(timeincrement,finishtime='N') {
        if(finishtime == 'Y') {
            currentracetime = timeincrement;
        } else {
            currentracetime = currentracetime+timeincrement;
        }

        var racetimetxt = '';
        var mins = 0;
        var seconds = 0;
        var milliseconds = 0;
        if(currentracetime > 60) {
            mins = Math.floor(currentracetime / 60);
            seconds = currentracetime - (mins*60);
        } else {
            seconds = currentracetime;
        }
        var finalseconds = Math.floor(seconds);
        var milliseconds = Math.round((seconds - finalseconds)*100);

        if(mins < 10) {
            racetimetxt = racetimetxt+'0';
        }
        racetimetxt = racetimetxt+mins+':';

        if(finalseconds < 10) {
            racetimetxt = racetimetxt+'0';
        }
        racetimetxt = racetimetxt+finalseconds+':';

        if(milliseconds < 10) {
            racetimetxt = racetimetxt+'0';
        } else if (milliseconds == 100) {
            milliseconds = '00';
        }

        racetimetxt = racetimetxt+milliseconds;

        window.$("#timeinfo").html(racetimetxt);
        if(finishtime == 'Y') {
            console.log('Finish Time Text:'+racetimetxt+' '+currentracetime)
        }
        if((game_start == 'Y')&&(finishtime != 'Y')) {
            setTimeout(function(){ updateRaceTimer(0.05,'N');},50);
        }
    }
    function stopRaceTimer(stoptime) {
        timerstart = 'N';
        setTimeout(function() {
            updateRaceTimer(stoptime,'Y');
        },50);
        
    }

function startRaceTrackSim(participant_list) {
    setTrackLoaded(1);
    container = document.getElementById('container');
    container.innerHTML = '';

    /* Add to DOM */
    renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true, shadowMap: true  } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    container.appendChild( renderer.domElement );

    /* Set Background to Black */
    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0x000000 );
    
    /* Set Camera and Orbital Controls */
    camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 10, 50000 );
    controls = new OrbitControls( camera, renderer.domElement );
    controls.minDistance = 1000;
    controls.maxDistance = 50000;
    controls.maxPolarAngle = Math.PI / 2;

    const data = generateHeight( worldWidth, worldDepth );
    controls.target.y = data[ worldHalfWidth + worldHalfDepth * worldWidth ] + 500;
    controls.update();

    // Setup Ambient Light (General Brightness of Object)
    const ambientLight = new THREE.AmbientLight(0xffffff, .7);
    ambientLight.castShadow = true;
    scene.add(ambientLight);

    // Setup Directional Light (Sun in Sky)
    const dirLight = new THREE.DirectionalLight(0xffffff, .7);
    dirLight.position.set(200, 200, 200);
    dirLight.castShadow = true;
    scene.add(dirLight);
    window.addEventListener( 'resize', onWindowResize );


    //Load Background Image
    const loader = new THREE.TextureLoader();
    loader.load('https://www.cryptospaceracers.club/css/images/global/space_backdrop2.jpg' , function(texture) {
        scene.background = texture;  
    });

    // Handle Resize Of Window
    window.addEventListener( 'resize', onWindowResize );

    // DRAW TRACK LINES
    var trackLanes = 13;
    var trackFloorOuter = [];
    var trackFloorInner = [];
    for(var l = 0; l <= trackLanes;l++) {
        var lanenum = l;
        // CREATE TRACK POINTS
        let raceTrackPoints = generateRaceTrackPoints(30000, 30000, lanenum);

        // SETUP TRACK BANKING ANGLES
        setupTrackAngles(lanenum);

        // DRAW TRACK LINE TO SCENE
        if((l >= 2)&&(l <= 11)) {
            const trackdraw = generateRaceTrackPath(raceTrackPoints);
            trackdraw.position.y = 75;
            scene.add(trackdraw);
        }

        if(l == 0) {
            trackFloorOuter = raceTrackPoints;
        }
        if(l == trackLanes) {
            trackFloorInner = raceTrackPoints;
        }
    }

    // DRAW TRACK FLOOR - Opposite Inside/Outside Tracks depending on which way track folds in on itself
    if(leftturncount > righturncount) {
        generateRaceTrackShapePath(trackFloorOuter,trackFloorInner);
    } else {
        generateRaceTrackShapePath(trackFloorInner,trackFloorOuter);
    }
    


    // DRAW TRACK FINISH LINE
    generateTrackFinishLine(scene,30000);
    generateTrackFinishGate(scene,30000);

    // SETUP RACERS LIST
    for(var z = 0; z < participant_list.length;z++) {
        // SET BASE DETAILS
        let racer_details = {
            'id': participant_list[z].racer_id,
            'name': participant_list[z].racer_name,
            'racer_location': participant_list[z].ship_model_location,
            'racer_filetype': 'gltf',
            'racer_yoffset': 0,
            'racer_scale': 10,
            'racer_object': '',
            'pilot_location': participant_list[z].pilot_image_location,
            'lane_number': (z+1),
            'track_position':0,
            'total_distance':0,
            'current_lap':1,
            'race_final_position': participant_list[z].race_final_position,
            'race_final_time': participant_list[z].race_final_time,
            'race_movements': participant_list[z].race_final_movements
        }
        racer_list.push(racer_details);
    }

    // ASSIGN LANE NUMBER TO RACERS - KEEP IN MIDDLE
    if(racer_list.length == 1) {
        racer_list[0].lane_number = 5;
    } else if(racer_list.length == 2) {
        racer_list[0].lane_number = 4;
        racer_list[1].lane_number = 6;
    } else if(racer_list.length == 3) {
        racer_list[0].lane_number = 3;
        racer_list[1].lane_number = 5;
        racer_list[2].lane_number = 7;
    } else if(racer_list.length == 4) {
        racer_list[0].lane_number = 2;
        racer_list[1].lane_number = 4;
        racer_list[2].lane_number = 6;
        racer_list[3].lane_number = 8;
    } else if(racer_list.length == 5) {
        racer_list[0].lane_number = 2;
        racer_list[1].lane_number = 4;
        racer_list[2].lane_number = 5;
        racer_list[3].lane_number = 6;
        racer_list[4].lane_number = 8;
    } else if(racer_list.length == 6) {
        racer_list[0].lane_number = 2;
        racer_list[1].lane_number = 3;
        racer_list[2].lane_number = 4;
        racer_list[3].lane_number = 5;
        racer_list[4].lane_number = 6;
        racer_list[5].lane_number = 8;
    } else if(racer_list.length == 7) {
        racer_list[0].lane_number = 2;
        racer_list[1].lane_number = 3;
        racer_list[2].lane_number = 4;
        racer_list[3].lane_number = 5;
        racer_list[4].lane_number = 6;
        racer_list[5].lane_number = 7;
        racer_list[6].lane_number = 8;
    }

    // LOAD RACER INTO GAME - FUNCTION
    function loadRacer(racernum,raceritem,cameraset) {

        // CORRECTS FOR MODELS THAT ARE SET IN THE WRONG DIRECTION
        if(raceritem.racer_yoffset == 0) {
            racer_list[racernum].racer_yoffset = (Math.PI*2);
        } else if (raceritem.racer_yoffset == 90) {
            racer_list[racernum].racer_yoffset = (Math.PI/2);
        } else if (raceritem.racer_yoffset == -90) {
            racer_list[racernum].racer_yoffset = (-Math.PI/2);
        }
        var raceroffset = racer_list[racernum].racer_yoffset;
        
        // PICKS THE CORRECT LOADER BASED ON FILETYPE
        let shiploader = new GLTFLoader();
        if(raceritem.racer_filetype == 'obj') {
            shiploader = new OBJLoader();
        } else if(raceritem.racer_filetype == 'svg') {
            shiploader = new SVGLoader();
        }

        // LOAD RACER FROM FILE LOCATION
        var racerfiletype = raceritem.racer_filetype;
        var racerscale = raceritem.racer_scale;
        var racerlocation = raceritem.racer_location;
        shiploader.load(racerlocation, function (shipfile) {
            // CREATE SHIP OBJECT SCENE BASED ON FILETYPE
            var ship_object = '';
            if(racerfiletype == 'obj') {
                ship_object = shipfile;
            } else {
                ship_object = shipfile.scene;
            }

            // SCALE MODEL TO CORRECT SIZE
            var ship_scale = racerscale;
            ship_object.scale.set(ship_scale, ship_scale, ship_scale);
            ship_object.receiveShadow = true;
            ship_object.castShadow = true;

            // ADD MODEL TO CANVAS - FLOATING ABOVE TRACK
            scene.add(ship_object);
            ship_object.position.y = 120;

            // STORE THE SHIP OBJECT IN RACER LIST - FOR MOVEMENT
            racer_list[racernum].racer_object = ship_object;

            // MOVE MODEL TO STARTING LINE
            var startingpoint = detailedPoints[racer_list[racernum].lane_number].length-5;
            moveRacerToDetailedPoints(racer_list[racernum].racer_object,startingpoint,racer_list[racernum].lane_number,racer_list[racernum].racer_yoffset);
            
            // MOVES CAMERA BEHIND MODEL - IF CURRENT RACER
            if(cameraset == 'Y') {
                moveCameraBehindRacer(racer_list[racernum].racer_object,racer_list[racernum].track_position,racer_list[racernum].racer_yoffset);

                // START ANIMATION LOOP
                renderer.setAnimationLoop(animationSimple);
            }
            
        },function (xhr) {
            console.log( (xhr.loaded / xhr.total * 100 ) + '% loaded - Racecraft'+racernum);
        },
        function (error) {
            console.log('An error happened - Racecraft'+racernum+': '+error);
        });
    }

    // LOAD ALL RACERS
    if(racer_list_store.length == 0) {
        var racerCameraSet = 'N';
        for(var z=0; z < racer_list.length;z++) {
            // SETS FOLLOW CAMERA IF CURRENT USER IS RACING - OTHERWISE FOLLOWS THE LAST ONE
            var setCamera = 'N';
            if(racer_list[z]['id'] == racerID) {
                setCamera = 'Y'
                racerCameraSet = 'Y';
            } else if ((racerCameraSet == 'N')&&((z+1) == racer_list.length)) {
                setCamera = 'Y';
                racerCameraSet = 'Y';
            }

            // CALLS LOAD RACER FUNCTION TO LOAD INTO CANVAS
            loadRacer(z,racer_list[z],setCamera);
            
        }  
        racer_list_store = racer_list;
    }

    // SET REACT CONSTANT RACER LIST FOR OTHER FUNCTIONS USES
    setRacerList(racer_list);

    

    // SET INTITIAL LEADERBOARD
    updateLeaderboard();
}

// SETS ANIMATAION RENDERER
function animationSimple() {
    renderer.render(scene,camera);
}

// RACE ANIMATION - PRIMARY FUNCTION THAT MOVES RACERS WITH DATA
function raceAnimateStep(steptime,stepnum) {
    // CHECK IF RACER LIST IS LOADED
    var racer_list = racer_list_store;
    if(racer_list.length > 0) {
        // CHECK IF RACER MODELS ARE LOADED
        if(racer_list[0].racer_object != '') {
            // CHECK IF USER STARTED RACE
            if(game_start == 'Y') {
                // GRAB MIDDLE LANE AS PROXY FOR LENGTH OF TRACK
                var middlelane = Math.round(detailedPoints.length/2);

                // GRAB MIDDLE RACER FOR CAMERA MOVEMENTS
                var middleracer = Math.round(racer_list.length/2)-1;
                var lastracertime = 0;
                var yourracer = -1;
                for(var s = 0; s < racer_list.length; s++) {
                    if(racer_list[s].id == racerID) {
                        yourracer = s;
                    }
                    // CHECK IF RACER FINISHED TOTAL LAPS
                    if(racer_list[s].current_lap <= totalRaceLaps) {
                        // MOVE THE RACER TO NEXT POINT ON TRACK
                        let clean_track_pos = Math.round(racer_list[s].track_position);
                        if (clean_track_pos < 0) {
                            clean_track_pos = 0;
                        }
                        moveRacerToDetailedPoints(racer_list[s].racer_object,clean_track_pos,racer_list[s].lane_number,racer_list[s].racer_yoffset);
                        
                        // GRAB NEXT RACER STEP FORWARD
                        let shipmove;
                        shipmove = racer_list[s].race_movements[stepnum].step_distance;

                        // UPDATE RACERS ARRAY FOR TOTAL DISTANCE AND POSITION
                        racer_list[s].total_distance = racer_list[s].total_distance + shipmove;
                        racer_list[s].track_position = racer_list[s].track_position + shipmove;

                        // IF NEW TRACK POSITION IS PAST THE LAP LENGTH, INCREASE LAP NUMBER AND ADJUST TRACK POSITION
                        if ((Math.round(racer_list[s].track_position) >= detailedPoints[middlelane].length)||((stepnum+1) == racer_list[s].race_movements.length)) {
                            racer_list[s].current_lap = racer_list[s].current_lap + 1;
                            racer_list[s].track_position = racer_list[s].track_position-detailedPoints[middlelane].length;

                            // IF THE NEW LAP NUMBER IS HIGHER THAT TOTAL LAPS IN RACE, FINISH RACE FOR RACER
                            if((racer_list[s].current_lap > totalRaceLaps)||((stepnum+1) == racer_list[s].race_movements.length)) {
                                // MOVE TO FINAL FINISH POSITION
                                racer_list[s].track_position = 50;
                                moveRacerToDetailedPoints(racer_list[s].racer_object,racer_list[s].track_position,racer_list[s].lane_number,racer_list[s].racer_yoffset);
                                lastracertime = racer_list[s].race_final_time;
                                console.log('racer ended: '+lastracertime);
                                
                                // ADD TO FINAL LEADERBOARD
                                finalLeaderboard.push(s);
                                finalLeaderboardTime.push(lastracertime);
                            }
                            // UPDATE LAP NUMBER VISUAL DISPLAY
                            updateLapNumber(racer_list[s].current_lap);
                        } 
                    }
                    
                } 
                if(yourracer == -1) {
                    yourracer = middleracer;
                }

                // MOVE CAMERA BEHIND MIDDLE RACERS NEW POSITION
                let camera_clean_pos = Math.round(racer_list[yourracer].track_position);
                moveCameraBehindRacer(racer_list[yourracer].racer_object,camera_clean_pos,racer_list[yourracer].racer_yoffset);


                // IF THE FINAL LEADERBOARD MATCHES TOTAL RACERS THEN RACE ENDS
                if(finalLeaderboard.length == racer_list.length) {
                    
                    // UPDATE FINAL LEADERBOARD WITH FINAL ORDER - INCASE RACE ENDS IN UNDER 5 MS
                    finalLeaderboard = [];
                    finalLeaderboardTime = [];
                    for(var m = 1; m <= racer_list.length;m++) {
                        for(var n = 0; n < racer_list.length;n++) {
                            if(m == parseInt(racer_list[n].race_final_position)) {
                                finalLeaderboard.push(n);
                                finalLeaderboardTime.push(racer_list[n].race_final_time);
                            }
                        }
                    }
                    updateLeaderboard();

                    game_start = 'N';
                    finishRace(lastracertime);
                } else {

                    // UPDATE THE REAL TIME LEADERBOARD
                    updateLeaderboard();

                    // SET NEXT STEP IN RACE TO 20 MILLISENDS (5 MOVEMENTS PER SECOND)
                    var nextstep = steptime+0.20;
                    var nextstepnum = stepnum+1;
                    setTimeout(function() {
                        raceAnimateStep(nextstep,nextstepnum);
                    },203);
                }
            }
        }
    }
}

// UPDATE TOP VISUAL LAP NUMBER
function updateLapNumber(lapnumber) {
    if((lapnumber > currentLap)&&(lapnumber <= totalRaceLaps)) {
        currentLap = lapnumber;
        document.getElementById("lapinfo").innerText = 'LAP '+currentLap+' / '+totalRaceLaps;
        showLapBanner(lapnumber);
    }
}

// UPDATE LEADERBAORD BASED ON CURRENT POSITIONS 
function updateLeaderboard() {
    let leaderboard = Array();

    // ADD ANY RACERS WHO FINISHED RACE FIRST
    var racer_list2 = racer_list_store;
    for(var i = 0; i < finalLeaderboard.length;i++) {
        leaderboard.push(finalLeaderboard[i]);
    }

    // ADD UNFINISHED RACERS BASED ON TOTAL DISTANCE TRAVELED
    for(var i = finalLeaderboard.length; i < racer_list2.length;i++) {
        var nextracer = 0;
        var maxdistance = -1;
        for(var j = 0; j < racer_list2.length;j++) {
            if(racer_list2[j].total_distance > maxdistance) {
                if(!leaderboard.includes(j)) {
                    maxdistance = racer_list2[j].total_distance;
                    nextracer = j;
                }
            }
        }
        leaderboard.push(nextracer);
    }

    var leaderboardtxt = '';
    var finalboardtxt = '';
    var finalboardtxtarr = [];
    for(var k = 0; k < leaderboard.length;k++) {
        leaderboardtxt = leaderboardtxt + '<div class="white-text leaderboardrow">';
        leaderboardtxt = leaderboardtxt + (k+1)+'. '+racer_list2[leaderboard[k]].name;
        leaderboardtxt = leaderboardtxt + '<img src="'+racer_list2[leaderboard[k]].pilot_location+'" height="100%" class="leaderboardimg">';
        leaderboardtxt = leaderboardtxt + '</div>';
        
        finalboardtxt = finalboardtxt + '<div class="white-text leaderboardrow">';
        finalboardtxt = finalboardtxt + (k+1)+'. '+racer_list2[leaderboard[k]].name;
        if(k == 0) {
            finalboardtxt = finalboardtxt+' +'+winnersCoins+' Coins';
        }
        finalboardtxt = finalboardtxt + '<img src="'+racer_list2[leaderboard[k]].pilot_location+'" height="100%" class="leaderboardimg">';
        finalboardtxt = finalboardtxt + '</div>';

        finalboardtxtarr.push(racer_list2[leaderboard[k]].id);
    }

    document.getElementById("raceresults").innerHTML = leaderboardtxt;
    document.getElementById("finalraceresults").innerHTML = finalboardtxt;
    document.getElementById("storeLeaderboard").value = finalboardtxtarr;
    document.getElementById("storeLeaderboardTimes").value = finalLeaderboardTime;
}



function moveRacerToDetailedPoints(ship,trackpos,lanenum,shipoffset) {
    ship.position.x = detailedPoints[lanenum][trackpos].x;
    ship.position.z = detailedPoints[lanenum][trackpos].y;
    ship.rotation.y = shipoffset+detailedPoints[lanenum][trackpos].yaw_rotation;
    if(game_start == 'Y') {
        ship.rotation.z = detailedPoints[lanenum][trackpos].roll_rotation;
    }
    camera.updateProjectionMatrix();
}

function moveCameraBehindRacer(ship,trackpos,shipoffset) {
    var middlelane = Math.round(detailedPoints.length/2);
    var rotZ = Math.cos(detailedPoints[middlelane][trackpos].yaw_rotation);
    var rotX = Math.sin(detailedPoints[middlelane][trackpos].yaw_rotation);
    var camera_distance = 300;

    let newx = ship.position.x + (camera_distance * rotX);
    let newz = ship.position.z + (camera_distance * rotZ);
    let newy = ship.position.y + 100;

    //let nextpos = new THREE.Vector3(newx,newy, newz);
    //camera.position.lerp(nextpos, 1.0)
    camera.position.x = newx;
    camera.position.z = newz;
    camera.position.y = newy;
    
    camera.rotation.y = detailedPoints[middlelane][trackpos].yaw_rotation;
    camera.updateProjectionMatrix();
}

function generateTrackFinishLine(scene,mapWidth) {
    var trackPieceInterval = 1;
    var sectionunit = mapWidth / 10;
    var trackPieceHeight = (sectionunit / trackSegmentSegments)*trackPieceInterval*10;
    var trackPieceWidth = 2000;
    var middlelane = Math.round(detailedPoints.length/2)-1;
    let widthSegments = 1;
    let heightSegments = 1;
    let floor_color = 0xffff00;

    // Create Finish Line Object
    const texture = new THREE.TextureLoader().load( 'https://www.cryptospaceracers.club/css/images/assets/backgrounds/checkerboard_pattern.png');
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(20,1);
    const geometry = new THREE.PlaneGeometry(trackPieceWidth, trackPieceHeight, widthSegments, heightSegments);
    const material = new THREE.MeshBasicMaterial( { side: THREE.DoubleSide, map: texture} );
    const finish_line = new THREE.Mesh(geometry, material);

    // Position Finish Line
    finish_line.position.set(detailedPoints[middlelane][0].x, 80, detailedPoints[middlelane][0].y+75);
    let perp_rotation = detailedPoints[middlelane][0].yaw_rotation;
    finish_line.rotateY(perp_rotation);
    finish_line.rotateX(Math.PI/2);
    finish_line.receiveShadow = true;

    // Add to Scene
    scene.add(finish_line);
}

function generateTrackFinishGate(scene,mapWidth) {
    var middlelane = Math.round(detailedPoints.length/2)-1;
    let gate_radius = 980;
    let gate_width = 20;
    let gate_radial_segments = 32;
    let gate_tubular_segments = 200;
    let gate_color = 0xffff00;

    // Create Finish Line Object
    const texture = new THREE.TextureLoader().load( 'https://www.cryptospaceracers.club/css/images/assets/backgrounds/checkerboard_pattern.png');
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(20,1);
    const geometry = new THREE.TorusGeometry(gate_radius, gate_width, gate_radial_segments, gate_tubular_segments, Math.PI);
    const material = new THREE.MeshPhongMaterial( { side: THREE.DoubleSide, color:0xFFFFFF} );
    const finish_gate = new THREE.Mesh(geometry, material);

    // Position Finish Line
    finish_gate.position.set(detailedPoints[middlelane][0].x, 71, detailedPoints[middlelane][0].y+75);
    let perp_rotation = detailedPoints[middlelane][0].yaw_rotation;
    finish_gate.rotateY(perp_rotation);
    finish_gate.receiveShadow = true;
    finish_gate.castShadow = true;

    // Add to Scene
    scene.add(finish_gate);
}

// CALCULATE THE RACECRAFTS BANKING ANGLES ON ANY GIVEN POINT OF TRACK AND ADD TO ARRAY
function setupTrackAngles(lanenum) {
    let startingRotation = (-Math.PI/2);
    let currentYaw = 0;
    let currentRoll = 0;

    for(var i = 0; i < detailedPoints[lanenum].length; i++) {
        let nextYaw = 0;
        let nextRoll = 0;

        // Grab Next Track Segment For Smoothness
        let current_segment = detailedPoints[lanenum][i].track_segment;
        let next_segment = current_segment+1;
        if(next_segment >= trackSummary.length) {
            next_segment = 0;
        }
        let next_track_type = trackSummary[next_segment].track_type;
        let next_track_direction = trackSummary[next_segment].track_direction;

        // Calculate Yaw If Turning
        if (i == 0) {
            nextYaw = startingRotation;
        } else if(detailedPoints[lanenum][i].track_type == 'turn') {
            nextYaw = (1/trackSegmentSegments)*(Math.PI/2);
            if(detailedPoints[lanenum][i].track_direction == 'left') {
                nextYaw = nextYaw*-1;
            }
        }
        currentYaw = currentYaw + nextYaw;

        // Calculate Roll If Turning
        if(detailedPoints[lanenum][i].track_type == 'turn') {
            // Max Roll at 20 degrees
            nextRoll = (1/(trackSegmentSegments/2))*(Math.PI/3.5);

            if(detailedPoints[lanenum][i].track_direction == 'left') {
                nextRoll = nextRoll*-1;
            }
            // Second Half of Turn In Half
            if(detailedPoints[lanenum][i].segment_fraction > 0.5) {
                // If Full Turn (Two Right Turns or Two Left Turns) - Don't Roll Back Down
                if((next_track_type == 'turn')&&(detailedPoints[lanenum][i].track_direction == next_track_direction)) {
                    nextRoll = 0;
                } else {
                    nextRoll = nextRoll*-1;
                }
            }                        
        }
        let tmpNewRoll = currentRoll + nextRoll;

        if(detailedPoints[lanenum][i].track_type == 'straight') {
            tmpNewRoll = 0;
        }

        if(tmpNewRoll > (Math.PI/3.5)) {
            currentRoll = (Math.PI/3.5);
        } else if(tmpNewRoll < -1*(Math.PI/3.5)) {
            currentRoll = -1*(Math.PI/3.5);
        } else {
            currentRoll = tmpNewRoll;
        }

        
        detailedPoints[lanenum][i].yaw_rotation = currentYaw;
        detailedPoints[lanenum][i].roll_rotation = currentRoll;
    }
}

// PACKAGE SEGEMENT POINTS INTO USABALE ARRAYS
function packageRaceSegmentPoints(segment_number,segment_type,segment_direction,next_xpos,next_ypos,sectionunit,segment_object) {
    // TRACK SUMMARY - Everything except track_start, track_end for each track segment will come from data going forward
    var segment_summary = {
        track_segment: segment_number,
        track_type: segment_type,
        track_direction: segment_direction,
        track_length: sectionunit,
        track_end_xdirection: next_xpos,
        track_end_ydirection: next_ypos,
        track_start: {
            x: segment_object.getPoint(0).x,
            y: segment_object.getPoint(0).y,
            z: segment_object.getPoint(0).z,
        },
        track_end: {
            x: segment_object.getPoint(1).x,
            y: segment_object.getPoint(1).y,
            z: segment_object.getPoint(1).z,
        }
    }
    trackSummary.push(segment_summary);

    // GENERATE THE SEGMENTS MOVEABLE POINTS - EACH SEGMENT HAS UP TO trackSegmentSegments Points - SET FROM DATA
    let tmpDetailedPoints = [];
    for(var i = 0; i < 1;i=i+(1/trackSegmentSegments)) {
        var tmppoint = {
            track_segment: segment_number,
            track_type: segment_type,
            track_direction: segment_direction,
            segment_fraction: i,
            x: segment_object.getPoint(i).x,
            y: segment_object.getPoint(i).y,
            z: segment_object.getPoint(i).z,
            t: segment_object.getTangent(i),
        };
        tmpDetailedPoints.push(tmppoint);
    }
    return tmpDetailedPoints;
}

// GENERATE TRACK - STATIC RIGHT NOW - DATA STORED BUT NOT READING ATM
function generateRaceTrackPoints(mapWidth, mapHeight,lanenum) {
    const pointsPath = new THREE.CurvePath();
    
    // GIVEN A LANE NUMBER - SORT OUT OFFSET
    const laneoffset = lanenum*150;

    // SECTION UNIT = LENGTH OF TRACK SEGMENT
    const sectionunit = mapWidth / 10;

    // STARTING POINT
    const startingX = 0;
    const startingY = laneoffset;
    let section_end_point;
    let next_track_type;
    let next_turn_direction;
    let next_xposition; 
    let next_yposition;
    let next_xend = 0;
    let next_yend = 0;
    let sectionnum = 0;

    let last_end_point = {x:startingX,y:startingY};
    for(var z = 0; z < trackSegments.length;z++) {
        section_end_point = last_end_point;
        next_track_type = trackSegments[z].track_type;
        next_turn_direction = trackSegments[z].track_direction;
        next_xend = section_end_point.x;
        next_yend = section_end_point.y+sectionunit;
        next_xposition = trackSegments[z].end_xposition;
        next_yposition = trackSegments[z].end_yposition;
        sectionnum = z;
        let segmentVector;
        if(trackSegments[z].track_type == 'turn') {
            let turnoffset = sectionunit+laneoffset;
            if(next_turn_direction == 'left') {
                turnoffset = sectionunit-laneoffset;
            } else if(next_turn_direction == 'right') {
                turnoffset = sectionunit+laneoffset;
            }
            segmentVector = generateTrackQuarterTurn(section_end_point.x, section_end_point.y, turnoffset, next_turn_direction, next_xposition, next_yposition);
        } else {
            let straightunit = sectionunit;
            if (trackID > 8) {
                straightunit = sectionunit*2;
            }
            if(next_turn_direction == 'up') {
                next_xend = section_end_point.x;
                next_yend = section_end_point.y+straightunit;
            } else if(next_turn_direction == 'down') {
                next_xend = section_end_point.x;
                next_yend = section_end_point.y-straightunit;
            } else if(next_turn_direction == 'left') {
                next_xend = section_end_point.x-straightunit;
                next_yend = section_end_point.y;
            } else if(next_turn_direction == 'right') {
                next_xend = section_end_point.x+straightunit;
                next_yend = section_end_point.y;
            }
            segmentVector = generateTrackStaightaway(section_end_point.x, section_end_point.y, next_xend, next_yend, straightunit);     
        }
        var section_points = packageRaceSegmentPoints(sectionnum,next_track_type,next_turn_direction,next_xposition,next_yposition,sectionunit,segmentVector);
        if(z == 0) {
            detailedPoints[lanenum] = section_points;
        } else {
            detailedPoints[lanenum] = detailedPoints[lanenum].concat(section_points);
        }
        last_end_point = segmentVector.getPoint(1);
        pointsPath.add(segmentVector);
    }
    return pointsPath;     
}

// CREATE STRAIGHT TRACK SEGMNENT
function generateTrackStaightaway(xstart,ystart,xend,yend,track_length) {
    let sectionstart = new THREE.Vector3(xstart, ystart)
    let sectionend = new THREE.Vector3(xend, yend)
    const straightaway = new THREE.LineCurve(sectionstart,sectionend);
    return straightaway;
}

// CREATE QUARTER TURN SEGMENT VECTOR
function generateTrackQuarterTurn(xstart,ystart,track_length,tdirection,xdirection,ydirection) {
    let turnstart = new THREE.Vector3(xstart, ystart)
    let turncontrol;
    let turnend;
    if(tdirection == 'right') {
        if(((xdirection == 'forward')||(xdirection == 'up'))&&(ydirection == 'right')) {
            turncontrol = new THREE.Vector3(xstart, ystart+track_length);
            turnend = new THREE.Vector3(xstart+track_length,ystart+track_length);

        } else if(((xdirection == 'forward')||(xdirection == 'up'))&&(ydirection == 'left')) {
            turncontrol = new THREE.Vector3(xstart-track_length, ystart);
            turnend = new THREE.Vector3(xstart-track_length,ystart+track_length);

        } else if(((xdirection == 'backward')||(xdirection == 'down'))&&(ydirection == 'right')) {
            turncontrol = new THREE.Vector3(xstart+track_length, ystart);
            turnend = new THREE.Vector3(xstart+track_length,ystart-track_length);

        } else if(((xdirection == 'backward')||(xdirection == 'down'))&&(ydirection == 'left')) {
            turncontrol = new THREE.Vector3(xstart, ystart-track_length);
            turnend = new THREE.Vector3(xstart-track_length,ystart-track_length);
        }
    } else {
        if(((xdirection == 'forward')||(xdirection == 'up'))&&(ydirection == 'right')) {
            turncontrol = new THREE.Vector3(xstart+track_length, ystart);
            turnend = new THREE.Vector3(xstart+track_length,ystart+track_length);

        } else if(((xdirection == 'forward')||(xdirection == 'up'))&&(ydirection == 'left')) {
            turncontrol = new THREE.Vector3(xstart, ystart+track_length);
            turnend = new THREE.Vector3(xstart-track_length,ystart+track_length);

        } else if(((xdirection == 'backward')||(xdirection == 'down'))&&(ydirection == 'right')) {
            turncontrol = new THREE.Vector3(xstart, ystart-track_length);
            turnend = new THREE.Vector3(xstart+track_length,ystart-track_length);

        } else if(((xdirection == 'backward')||(xdirection == 'down'))&&(ydirection == 'left')) {
            turncontrol = new THREE.Vector3(xstart-track_length, ystart);
            turnend = new THREE.Vector3(xstart-track_length,ystart-track_length);
        }
    }
    const turnsection = new THREE.QuadraticBezierCurve3(turnstart,turncontrol,turnend);
    return turnsection;
}

// CREATE RACE RACK LINES
function generateRaceTrackPath(trackPointsPath) {
    const material = new THREE.LineBasicMaterial({color: 0xA9A9A9});

    // REDUCE POINTS FROM PATH
    const trackPoints = trackPointsPath.curves.reduce((p, d)=> [...p, ...d.getPoints(100)], []);
    const geometry = new THREE.BufferGeometry().setFromPoints(trackPoints);

    // ROTATE TO MATCH CORRECT AXIS
    geometry.rotateX(Math.PI / 2 );
    return new THREE.Line( geometry, material );
}

// CREATE RACE TRACK FLOOR
function generateRaceTrackShapePath(outerPointsPath,innerPointsPath) {
    let totalDrawPoints = (trackSegments.length*10)+2;
    const outerTrackPoints = outerPointsPath.getPoints(totalDrawPoints);
    const innerTrackPoints = innerPointsPath.getPoints(totalDrawPoints);

    // TRACK IS A SHAPE WITH HOLE CUT OUT - OUTER LANE IS SHAPE BOUNDARY, INNER LANE IS HOLE BOUNDARY
    const trackShape = new THREE.Shape(outerTrackPoints);
    const innerTrackShape = new THREE.Path(innerTrackPoints);
    trackShape.holes.push(innerTrackShape);

    // GIVE THE TRACK SOME THICKNESS
    const extrudeSettings = { depth: 16, bevelEnabled: true, bevelSegments: 2, steps: 2, bevelSize: 2, bevelThickness: 20 };

    // CREATE THE TRACK MESH
    const geometry = new THREE.ExtrudeGeometry(trackShape,extrudeSettings);
    const material = new THREE.MeshPhongMaterial( { color: 0xCCCCCC, shininess: .25 } );
    const mesh = new THREE.Mesh( geometry, material ) ;
    mesh.receiveShadow = true;
    mesh.castShadow = true;

    // ROTATE TO MATCH AXIS AND SET HEIGHT
    mesh.position.set(0,50,0);
    mesh.rotateX(Math.PI / 2 );
    scene.add(mesh);
}

// NOT USED -- FOR RANDOM MOUNTAIN HEIGHT GENERATION
function generateHeight( width, height ) {
    const size = width * height, data = new Uint8Array( size ),
        perlin = new ImprovedNoise(), z = Math.random() * 100;
    let quality = 1;
    for ( let j = 0; j < 4; j ++ ) {
        for ( let i = 0; i < size; i ++ ) {
            const x = i % width, y = ~ ~ ( i / width );
            data[ i ] += Math.abs( perlin.noise( x / quality, y / quality, z ) * quality * 1.0 );
        }
        quality *= 5;
    }
    return data;
}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );
}

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row white-text top-nav-header-wide">
            <div className="col s12 transparent white-text" style={{marginTop:'10px'}}>
                <div className="left" style={{marginLeft:'5px'}}>
                    <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" height="40px" />
                </div>
            </div>
        </div>
        
        <div className="row page-main-section" style={{position:'relative'}}>
            <div id="raceprogress">
                <span id="lapinfo">5 LAPS</span>
                <span>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
                <span id="timeinfo">00:00:00</span>
                <input type="hidden" id="gameStart" value="N"></input>
                <input type="hidden" id="trackLoaded" value="N"></input>
                <input type="hidden" id="storeLeaderboard" value=""></input>
                <input type="hidden" id="storeLeaderboardTimes" value=""></input>
            </div>
            <div id="racerbannerretro">
                GET READY
            </div>
            <div id="finalboardbox">
                <div className="col s12 m4 " id="finalboardsection">
                    <div className="row finalboardtop">
                        <div className="white-text" id="finalboardtitle">
                            FINAL STANDINGS
                        </div>
                    </div>
                    <div className="row" id="finalraceresults">
                        <div className="white-text leaderboardrow">
                            1. Racer #1
                        </div>
                        <div className="white-text leaderboardrow">
                            2. Racer #2
                        </div>
                    </div>
                    <div id="finalboard-next-button" class="col s12 csr-grey-bg">
                        <button class="btn-flat topnavtoggle white-text finalboard-next-inner-button center" onClick={() => goToWorkshop() }>
                            <span class="finalboard-next-button-text">GO TO WORKSHOP</span><i class="material-icons finalboard-next-button-chevron">chevron_right</i>
                        </button>
                    </div>
                </div>
            </div>
            <div id="leaderboardbox">
                <div className="col s12 m4 " id="leaderboardsection">
                    <div className="row leaderboardtop">
                        <div className="white-text" id="leaderboardtitle">
                            LEADERBOARD
                        </div>
                    </div>
                    <div className="row" id="raceresults">
                        <div className="white-text leaderboardrow">
                            1. Racer #1
                        </div>
                        <div className="white-text leaderboardrow">
                            2. Racer #2
                        </div>
                    </div>
                </div>
            </div>
            <div id="startgamebuttonold" onClick={()=> startGame()}>
                START RACE
            </div>
            <div id="pausegamebutton" onClick={()=> pauseGame()}>
                PAUSE RACE
            </div>
            <div id="finishgamebutton" onClick={()=> finishRace(60)}>
                FINISH RACE
            </div>
        </div>
        <div id="container" style={{width:'100%',height:'95vh',position:'fixed',zIndex:'-10',top:'0px',left:'0px'}}>

        </div>
    </div>);
};
