import "./sectionImageGrid.css"

export const SectionImageGrid = ({ images }) => {
  return (
    <div className="section-image-grid">
      {images && images.map((image, id) => {
        return (
          <div className="section-image-grid-item">
            <div className="section-image-grid-item-image-overlay" />
            <img
              className="section-image-grid-item-image"
              src={image.src}
              alt={image.title}
              key={id}
            />
          </div>
        );
      })}
    </div>
  )
}