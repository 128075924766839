import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerXP, setRacerCoins } from "../../../redux/Account/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals} from "../../../utils";
import $ from "jquery";
let web3;

export const GlobalInfoboxes = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress, userID, spectatorID, loginToken, loginTime } = useSelector((state) => state.account);
    const { admin_racers, live_hosts } = useSelector((state) => state.app);

    const [headerSet, setHeader] = useState(false);
    const [racer_handle, setRacerHandle] = useState(false);
    
    if(headerSet == false) {
        setHeader(true);
    }

    return(<>
            <div id="global_infobox_race_type" className="modal page-modal page-modal-large">
                <div className="modal-content">
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                        <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>
                            RACE TYPES
                            </div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'10px',fontSize:'14px'}}>
                            <div className="row" style={{margin:'10px 0px',fontSize:'16px',marginBottom:'15px'}}>
                            Welcome to the racing recruit! Here's a crash course on EXR Race Types.
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                                <div style={{width:'100%',fontSize:'18px'}}>STANDARD RACE</div> 
                                <div style={{width:'100%',marginTop:'10px'}}>
                                    The EXR Standard Race is the classic race type with the <a className="exr-light-green-text">FASTEST</a> racers to 
                                    complete the circuit winning. Pick your best stack in this race.
                                </div>
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                                <div style={{width:'100%',fontSize:'18px'}}>INVERSE RACE</div> 
                                <div style={{width:'100%',marginTop:'10px'}}>
                                    The EXR Inverse Race is a warped race with the <a className="exr-light-green-text">SLOWEST</a> racers to 
                                    complete the circuit winning. Pick your stack with the worst Speed, Handling, Acceleration and Focus in this race. Inverse racing 
                                    still uses Intelligence, Aggression, Stamina and Strength so pick a Booster for one of those attributes.
                                </div>
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                                <div style={{width:'100%',fontSize:'18px'}}>OPEN RACE</div> 
                                <div style={{width:'100%',marginTop:'10px'}}>
                                    Open races are the base EXR race that do not have a limit with how many racers can enter. 
                                    Open Races have a <a className="exr-light-green-text">5 Lap</a> distance. After entries close, open races automatically place you into competitive racing 
                                    groups of up to <a className="exr-light-green-text">5 Racers</a> and auto-simulates your race once the Prediction period ends.
                                </div>
                            </div>
                            <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                                <div style={{width:'100%',fontSize:'18px'}}>DISTANCE RACE</div> 
                                <div style={{width:'100%',marginTop:'10px'}}>
                                    EXR Distance races are Open Races with a <a className="exr-light-green-text">10 Lap</a> distance. These races require you to choose 
                                    stacks with high levels of Strength and Stamina due to an increase in time and length of race.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
};