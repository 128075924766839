import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import "../../../RaceLanding/race_landing.css";
import $ from "jquery";

import {TrackLoader} from "../../../../../webgl/race/utils/trackLoader.js"
import { Track } from "../../../../../webgl/race/track/track.js";
import { TrackMap } from "../../../../../webgl/race/track/map/trackMap.js";
import { Minimap } from "../../../../../webgl/race/minimap/minimap.js";
import {laps_tooltip, turns_tooltip, straights_tooltip, shifts_tooltip} from "../../race_landing_tooltips";

let trackloading = 0;
let initialtrackloaded = 0;
let unlock_track_id = 0;

export const TrackSelectionSection = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    const [headerSet, setHeader] = useState(false);
    const [track_summary_html, setSummaryHTML] = useState('');
    const [trackID, setTrackID] = useState(0);
    const [selection_title,setTrackSelectionTitle] = useState('');

    const race_creator_types = ['training','bot-race','daily','alpha','invite'];

    const canvas = React.createRef();
    const container = React.createRef();
    const track_viewer_container = React.createRef();
    const track_viewer_test = React.createRef();

    let trackList = [];
    let current_track_id = 0;
    let current_track_index = 0;
    let selected_track_id = 0;
    let seleted_track_system = '';
    const trackUnlockPrice = 25;

    function grabRacerTracks() {
        var addData = new Object();
        if(race_creator_types.indexOf(props.hashRaceUniqueID) >= 0) {
            addData.racerID = encodeURIComponent(racerID);
        } else {
            addData.trackID = encodeURIComponent(props.raceDetails.race_track_id);
            addData.raceID = encodeURIComponent(props.raceDetails.race_id);
            selected_track_id = props.raceDetails.race_track_id;
            seleted_track_system = props.raceDetails.race_track_galaxy; 
            setTimeout(function(){ $("#current-track-selected").val(props.raceDetails.race_track_id); $("#current-track-system-selected").val(props.raceDetails.race_track_galaxy); },500);
        }

        axiosInstance.post('race/track_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_track_list = result.data;
                trackList = new_track_list;
                if(current_track_id == 0) {
                    current_track_index = 0;
                    var current_target_id = props.raceTrackID;
                    if(unlock_track_id > 0) {
                        current_target_id = unlock_track_id;
                    }
                    for(var i = 0; i < new_track_list.length;i++) {
                        if(current_target_id == new_track_list[i].race_track_id) {
                            current_track_index = i;
                        }
                    }
                    current_track_id = new_track_list[current_track_index].race_track_id;
                }
                setTrackID(current_track_id);
                showTrackSummary(new_track_list[current_track_index],current_track_index);
                updateTrackGL(current_track_id);
                unlock_track_id = 0;
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function toggleRacePage(pagename) {
        props.navToggle(pagename,props.raceDetails);
    }

    function unlockTracksCheck() {
        window.startPageModals();
        var unlockbox = document.getElementById('unlocktrackbox');
        var unlock_instance = window.M.Modal.getInstance(unlockbox);
        unlock_instance.open();
    }

    function unlockTracksFromBox() {
        document.getElementById("unlockchecksubmit").style.display = 'none';
        document.getElementById("unlockcheckloading").style.display = 'block';

        var addData = new Object();
        addData.racerID = racerID;
        addData.unlockPrice = trackUnlockPrice;
        addData.raceHost = encodeURIComponent(window.location.host);
        
        axiosInstance.post('race/track_unlock/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                unlock_track_id = current_track_id;
                grabRacerTracks();
                var unlockbox = document.getElementById('unlocktrackbox');
                var unlock_instance = window.M.Modal.getInstance(unlockbox);
                unlock_instance.close();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function makeTrackSelection(trackid) {
        setTrackID(trackid);
        selected_track_id = trackid;
        seleted_track_system = trackList[current_track_index].race_track_galaxy;
        $("#current-track-selected").val(trackid);
        $("#current-track-system-selected").val(seleted_track_system);
        showTrackSummary(trackList[current_track_index],current_track_index);
    }

    function showTrackSlide(trackdirection) {
        if(trackList.length == 0) {
            setTimeout(function() {
                showTrackSlide(trackdirection);
            },500);
        } else {
            var next_track_index = 0;
            if (trackdirection == 'previous') {
                if ((current_track_index-1) >= 0) {
                    next_track_index = current_track_index-1;
                } else {
                    next_track_index = trackList.length-1;
                }
            } else {
                if ((current_track_index+1) < trackList.length) {
                    next_track_index = current_track_index+1;
                } else {
                    next_track_index = 0;
                }
            }
            current_track_id = trackList[next_track_index].race_track_id;
            current_track_index = next_track_index;
            showTrackSummary(trackList[next_track_index],next_track_index);
            updateTrackGL(current_track_id);
        }
    }
    
    function showTrackSummary(track_info,track_index) {
        var trackUnlocked = track_info.track_unlocked;
        var raceTrackID = track_info.race_track_id;
        var raceTrackGalaxy = track_info.race_track_galaxy;
        var raceLaps = track_info.race_laps;
        var raceTurns = track_info.total_turns;
        var raceStraights = track_info.total_straights;
        var raceShifts = track_info.total_shifts;
        var raceSegments = track_info.total_segments;
        var lappct = Math.round(100*(raceLaps / (10)));
        var turnpct = Math.round(100*(raceTurns / raceSegments));
        var straightpct = Math.round(100*(raceStraights / raceSegments));
        var shiftpct = Math.round(100*(raceShifts / raceSegments));

        var days_ended = props.raceDetails.days_since_race_ended;
        var entryDisplay = 'block';
        if((days_ended !== undefined)&&(days_ended !== 'null')&&(days_ended !== '')&&(days_ended !== null)) {
            entryDisplay = 'none';
        }

        if(race_creator_types.indexOf(props.hashRaceUniqueID) >= 0) {
            entryDisplay = 'block';
        } 

        if ((props.racerStatus == 1)||(race_creator_types.indexOf(props.hashRaceUniqueID) == -1)) {
            entryDisplay = 'none';
        }
    
        var track_selection_title = '2. SELECT YOUR TRACK';
        if(entryDisplay == 'none') {
            track_selection_title = 'TRACK DETAILS';
        }
        let entry_button_display = entryDisplay;
        setTrackSelectionTitle(track_selection_title);

        var summary_button = <div className="center-align" style={{display:entry_button_display}}>
                <span className="round-border-button" style={{fontSize:'20px'}} title="Select Track" onClick={()=>makeTrackSelection(raceTrackID)}>
                    SELECT TRACK
                </span>
            </div>;
        var galaxy_faction = '';
        if(raceTrackGalaxy.length > 0) {
            if(raceTrackGalaxy == 'serf') {
                galaxy_faction = 'Serf';
            } else if(raceTrackGalaxy == 'augment') {
                galaxy_faction = "Aug";
            } else {
                galaxy_faction = "Merc";
            }
        }

        var galaxy_faction_icon = '';
            if(galaxy_faction != 'ANY') {
                galaxy_faction_icon = <img title={galaxy_faction+" System Track - Match Stack Factions For Genetic Boost"} src={"https://media.exiledracers.com/global/icons/exr_"+galaxy_faction.toLowerCase()+"_icon.png"} height="50px" />
            }
        var tmp_html = <>
                    <div className="stats-viewer-stats-headline center-align" style={{marginTop:'0px',marginBottom:'15px',position:'relative'}}>
                        <span style={{width:'100%'}}>
                            EXR Track #{raceTrackID}&nbsp;&nbsp;//&nbsp;&nbsp;
                            <span className="exr-light-green-text" title={galaxy_faction+" System Track - Match Stack Factions For Genetic Boost"}>
                                {galaxy_faction} System
                            </span>
                        </span>
                    </div>
                    <div className="divider-thin"></div>
                    <div className="row white-text" style={{fontSize:'14px',marginTop:'15px',marginBottom:'5px'}}>
                        <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={laps_tooltip}>
                            <div style={{order:'1',flexBasis:'40px'}}>
                                <img src="https://media.exiledracers.com/global/icons/exr_strength.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                    <div className="left">Laps</div>
                                    <div className="right">{raceLaps}</div>
                                </div>
                                <div style={{width:'100%'}}>
                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                        <div className="determinate white" style={{width:lappct+'%',backgroundColor: 'white'}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider-thin"></div>
                    <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                        <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={turns_tooltip}>
                            <div style={{order:'1',flexBasis:'40px'}}>
                                <img src="https://media.exiledracers.com/global/icons/exr_handling.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                    <div className="left">Turns</div>
                                    <div className="right">{raceTurns}</div>
                                </div>
                                <div style={{width:'100%'}}>
                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                        <div className="determinate white" style={{width:turnpct+'%',backgroundColor: 'white'}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider-thin"></div>
                    <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                        <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={straights_tooltip}>
                            <div style={{order:'1',flexBasis:'40px'}}>
                                <img src="https://media.exiledracers.com/global/icons/exr_speed.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                    <div className="left">Straights</div>
                                    <div className="right">{raceStraights}</div>
                                </div>
                                <div style={{width:'100%'}}>
                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                        <div className="determinate white" style={{width:straightpct+'%',backgroundColor: 'white'}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider-thin"></div>
                    <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                        <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={shifts_tooltip}>
                            <div style={{order:'1',flexBasis:'40px'}}>
                                <img src="https://media.exiledracers.com/global/icons/exr_acceleration.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                    <div className="left">Speed Shifts</div>
                                    <div className="right">{raceShifts}</div>
                                </div>
                                <div style={{width:'100%'}}>
                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                        <div className="determinate white" style={{width:shiftpct+'%',backgroundColor: 'white'}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider-thin" style={{marginBottom:'25px'}}></div>
                </>;

        setSummaryHTML(tmp_html);

        var tooltips = document.querySelectorAll('.tracktips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
    }
    
    function updateTrackGL(trackid) {
        current_track_id = trackid;
        initialtrackloaded = 0;
        setTrackID(trackid);
        loadTrack(trackid);

        $("#current-track-system-viewing").val(trackList[current_track_index].race_track_galaxy);
    }

    async function loadTrack(trackid) {
        if((track_viewer_container.current)&&(initialtrackloaded == 0)) {
            
            let track_data = await new Track(trackid,'id');
            new TrackLoader(track_data,
                () => {
                    if(track_viewer_container.current) {
                        $("#track-viewer").html('');
                        let track_minimap = new Minimap(new TrackMap(track_data),track_viewer_container.current).populate(null);
                        initialtrackloaded = 1;
                    }
                }
            );
        }
    }
    useEffect(() => {
        if(trackID != 0) {
            loadTrack(trackID);
        }
    }, [track_viewer_container]);

    if(headerSet == false) {
        setHeader(true);
        grabRacerTracks();
    }

    window.addEventListener("resize", event => {
        if(trackID != 0) {
            initialtrackloaded = 0;
            loadTrack(trackID);
        }
    });

    return(<div className="row" style={{margin:'5px',marginBottom:'10px'}}>
        <div className="row exr-new-light-grey" style={{margin:'0px',borderRadius:'10px'}}>
            <div className="col s12 headline-text" style={{fontSize:"20px",marginTop:"15px"}}>
                TRACK DETAILS
                <input type="hidden" id="current-track-selected" />
                <input type="hidden" id="current-track-system-selected" />
                <input type="hidden" id="current-track-system-viewing" />
            </div>
            <div className="col s12">
                <div className="divider-thin"></div>
            </div>
            <div className="col s12" style={{marginTop:'10px'}}>
                <div className="col s12 l6" style={{margin:"0px",padding:"0px"}}>
                    <div className="border-section-full exr-new-dark-grey" style={{position:'relative',width:"99%",marginRight:"1%",overflow:"hidden"}}>
                        <div className="transparent" id="track-viewer" ref={track_viewer_container} style={{width:'95%',marginLeft:'2.5%',marginTop:'20px',marginBottom:'15px',height:"250px",borderRadius:'10px'}}></div>
                    </div>
                </div>
                <div className="col s12 l6 exr-new-light-grey " style={{position:'relative',margin:"0px",padding:"10px",borderRadius:'10px',marginTop:'0px'}}>
                    {track_summary_html}
                </div>
            </div>
        </div>
    </div>);
};