/**
 * A camera controller
 */
 export class CameraController {
    /**
     * Construct a camera controller
     * @param {Camera} camera The camera to control
     * @param {boolean} [targetPlayer] True if this camera should target the player
     */
    constructor(camera, targetPlayer = true) {
        this.camera = camera;
        this.targetPlayer = targetPlayer;
    }

    /**
     * Set the target for this camera controller
     * @param {CameraTargetable} target The target to track
     * @param {CameraTargetable[]} targets All targets that can be tracked
     * @param {Track} track The track
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {boolean} [instant] True if the change should be instant
     */
    setTarget(target, targets, track, time, instant = false) {
        this.camera.lookAt(target.getPosition(time));
    }

    /**
     * Update
     * @param {number} delta The time delta
     */
    update(delta) {

    }

    /**
     * Update before rendering
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {

    }
}