import React, { useEffect, useState } from "react";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import $ from "jquery";

export const RacePredictHowToBox = (props) => {
    return (<div id="training_box_race_howto" className="modal page-modal page-modal-full">
        <div className="modal-content" style={{padding:'15px'}}>
            <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                <div className="round-close-button" style={{top:'0px',right:'0px'}} title="Close" onClick={()=>closeAllModals()}>
                    X
                </div>
                <div className="col s12" style={{fontSize:"20px",marginTop:"10px",padding:"0px 10px"}}>
                    <div className="left headline-text" style={{marginLeft:'5px'}}>
                        HOW TO PREDICT
                    </div>
                </div>
                <div className="col s12" style={{padding:"0px 10px"}}>
                    <div className="divider-thin"></div>
                </div>
                <div className="col s12 subheadline-text" style={{marginTop:'10px',fontSize:'16px'}}>
                    <div className="row" style={{margin:'10px 0px',fontSize:'16px',marginBottom:'15px',padding:"0px 10px"}}>
                    Welcome to the races! Here's a crash course on how to successfully predict on a race.
                    </div>
                    <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                        <div style={{width:'100%',fontSize:'18px'}}>1.&nbsp;&nbsp;Review Race Details </div> 
                        <div style={{width:'100%',marginTop:'10px'}}>
                            Learn how many laps the race is, examine the Race Track and match them with Racer Attributes.
                        </div>
                    </div>
                    <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                        <div style={{width:'100%',fontSize:'18px'}}>2.&nbsp;&nbsp;Win Chance & XP Rewards</div> 
                        <div style={{width:'100%',marginTop:'10px'}}>
                            Find each racers <a className="exr-light-green-text">Win Chance</a> which is the percentage of times they won the race after a thousand simulations.
                            <br/>
                            <br/>Racers with lower odds of success return a higher <a className="exr-light-green-text">XP Reward</a> as the underdog for correct picks. 
                        </div>
                    </div>
                    <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                        <div style={{width:'100%',fontSize:'18px'}}>3.&nbsp;&nbsp;Make Prediction</div> 
                        <div style={{width:'100%',marginTop:'10px'}}>
                            Click the <a className="exr-light-green-text">Predict Button</a> under the racer you want to predict. Select which place you think that racer will finish.
                        </div>
                    </div>
                    <div className="row exr-stats-light-grey" style={{margin:'5px 0px',padding:'15px',borderRadius:'5px'}}>
                        <div style={{width:'100%',fontSize:'18px'}}>5.&nbsp;&nbsp;Watch Simulated Race </div> 
                        <div style={{width:'100%',marginTop:'10px'}}>
                            Once the prediction window closes, <a className="exr-light-green-text">the race is simulated one final time automatically</a> at the time outlined in your race details. 
                            Watch the race and find out if you picked correctly.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
    
};