import * as THREE from "three";

export class Effects {
    #effects = [];
    #scene = new THREE.Scene();

    /**
     * Construct the effects
     * @param {THREE.Scene} scene The scene to render effects in
     */
    constructor(scene) {
        scene.add(this.#scene);
    }

    /**
     * Get the scene
     */
    get scene() {
        return this.#scene;
    }

    /**
     * Add an effect
     * @param {Effect} effect The effect to add
     */
    add(effect) {
        this.#effects.push(effect);
        this.#scene.add(effect.group);
    }

    /**
     * Update the effects
     */
    update() {
        for (let effect = this.#effects.length; effect-- > 0;)
            if (this.#effects[effect].update()) {
                this.#scene.remove(this.#effects[effect].group);

                this.#effects.splice(effect, 1);
            }
    }

    /**
     * Render
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        for (let effect = 0, effectCount = this.#effects.length; effect < effectCount; ++effect)
            this.#effects[effect].render(time);
    }
}