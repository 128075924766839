import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import useSWR from "swr";

import { useMetaMask } from "metamask-react";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import { isCommunityResourcable } from "@ethersproject/providers";
import {closeAllModals} from "../../../utils";
import $ from "jquery";
import {
  InjectedConnector,
  UserRejectedRequestError,
  NoEthereumProviderError,
} from "@web3-react/injected-connector";

let web3;

const HARDHAT_CHAIN_ID = 31337;
const MOONBASE_CHAIN_ID = 1287;
const MOONBEAM_CHAIN_ID = 1284;
const ETH_CHAIN_ID = 1;
const BINANCE_CHAIN_ID = 56;
const POLYGON_CHAIN_ID = 137;
const ARBITRUM_CHAIN_ID = 42161;
const AVAX_CHAIN_ID = 43114;

const supportedChainIds = [
  HARDHAT_CHAIN_ID,
  MOONBASE_CHAIN_ID,
  MOONBEAM_CHAIN_ID,
  ETH_CHAIN_ID,
  BINANCE_CHAIN_ID,
  POLYGON_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  AVAX_CHAIN_ID
];

const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: supportedChainIds,
});

const fetcher = (url) => fetch(url).then((res) => res.json());

let ext_account_id = "";
let total_pilots_sync = 0;
let total_racecrafts_sync = 0;
let total_boosters_sync = 0;

export const LoginBox = (props) => {
  const dispatch = useDispatch();
  const { page_loaded, live_hosts }= useSelector((state) => state.app);
  const { racerID, accountAddress } = useSelector((state) => state.account);
  const { active, chainId, library, error, activate, account } = useWeb3React();
  const { pilotContract, racerContract, boosterContract } = useSelector((state) => state.web3);

  const [accountID, setAccountID] = useState(null);
  const [chainID, setChainID] = useState(null);
  const [currentLoginStatus,setCurrentStatus] = useState('SYNCING GAME ASSETS...');

  let currentInterval = 0;
  let pilotTokenIDs = [];
  let racerTokenIDs = [];
  let boosterTokenIDs = [];

  /* Try To Connect Accounts and Chains Once Ready */
  useEffect(() => {
    if (active) {
      if (ext_account_id != account) {
        setAccountID(account);
        ext_account_id = account;
      }
      if (chainID == null) {
        if (chainId != undefined) {
          setChainID(chainId);
        }
      } else if (chainID != chainId) {
        alert("Detected Network Change...Reloading!");
        forceReloadNetwork();
      }
    }
  }, [chainId, library, active, account]);
  
  /* Any Update To Contracts or Chains Triggers an Automatic Asset Check */
  useEffect(() => {
    checkPilotItems();
  }, [pilotContract, chainId]);

  useEffect(() => {
    checkRacecraftItems();
  }, [racerContract, chainId]);

  useEffect(() => {
    checkBoosterItems();
  }, [boosterContract, chainId]);

  /* Updates Items in DB */
  function updateWalletTokenOwner(tokenIds,tokenType) {
    var tokenstring = '';
    for(var i = 0; i < tokenIds.length;i++) {
      if(i > 0) {
        tokenstring = tokenstring+',';
      }
      tokenstring = tokenstring+tokenIds[i];
    }
    var addData = new Object;
    addData.walletID = encodeURIComponent(account);
    addData.tokenList = tokenstring;
    addData.nType = tokenType;

    if(account !== null) {
      if(account.length > 3) {
          axiosInstance
            .post("https://data.exiledracers.com/api/v1/mint/token_owner_check/", addData)
            .then((result) => {
              if (Object.keys(result.data).length > 0) {
                var view_result = result.data;
              }
            })
            .catch((error) => {
              console.log(error);
            });
      }
    }
  }

  function forceReloadNetwork() {
    window.location.reload();
  }

  function checkPilotItems() {
    if ((pilotContract)&&(chainId == 1284)) {
      if ((account)&&(account !== undefined)) {
        pilotContract
          .walletOfOwner(account)
          .then((ids) => {
            let formattedIds = ids.map((id) => id.toNumber());
            if(formattedIds.length > 0) {
              total_pilots_sync = formattedIds.length;
              updateWalletTokenOwner(formattedIds,'pilot');
            }
            
          })
          .catch((err) => console.log(err));
      }
    }
  }

  function checkRacecraftItems() {
    if ((racerContract)&&(chainId == 1284)) {
      if ((account)&&(account !== undefined)) {
        racerContract
          .walletOfOwner(account)
          .then((ids) => {
            let formattedIdsRC = ids.map((id) => id.toNumber());
            if(formattedIdsRC.length > 0) {
              total_racecrafts_sync = formattedIdsRC.length;
              updateWalletTokenOwner(formattedIdsRC,'racecraft');
            }
            
          })
          .catch((err) => console.log(err));
      }
    }
  }

  function checkBoosterItems() {
    if ((boosterContract)&&(chainId == 1284)) {
      if ((account)&&(account !== undefined)) {
        let promises = [];
        const promise = boosterContract.balanceOfBatch(account,0,202);
        promises.push(promise);
        Promise.all(promises).then((inventoryBalances) => {
            var totalboostersclaimed = 0;
            var booster_token_list =  new Array();
            for (let i = 0; i < inventoryBalances[0].length; i++) {
                if ((inventoryBalances[0][i].toNumber() != 0)&&(inventoryBalances[0][i].toNumber() != '')) {
                    totalboostersclaimed = totalboostersclaimed + inventoryBalances[0][i].toNumber();
                    for (var z = 0; z < inventoryBalances[0][i].toNumber(); z++) {
                        booster_token_list.push(i);
                    }  
                }
            }
            if(booster_token_list.length > 0) {
              total_boosters_sync = booster_token_list.length;
              updateWalletTokenOwner(booster_token_list,'booster');
            }
        });
      }
    }
  }

  /* Updates On Screen Wallet Address Text and Inputs */
  async function updateWalletAddress() {
    let wallet_address = await syncAccountWallet();
    if(wallet_address) {
        if(wallet_address.length > 5) {
            document.getElementById("walletID").value = wallet_address;
            document.getElementById("WID").value = wallet_address;
        }
    }
  }

  function loginStepTwo(signup_flow_flag) {
    checkGameAssets(3,signup_flow_flag);
  }

  function checkGameAssets(checknum,signup_flow_flag) {
    if(((total_pilots_sync > 0)&&(total_racecrafts_sync > 0)&&(total_boosters_sync > 0))||(checknum == 0)) {

      /* Update Login Status */
      if((total_pilots_sync > 0)||(total_racecrafts_sync > 0)||(total_boosters_sync > 0)) {
        setCurrentStatus('EXR NFTS FOUND. CONNECTING TO GAME.');
      } else {
        if(chainId == 1284) {
          setCurrentStatus('EXR NFTS NOT FOUND. CONNECTING TO GAME.');
        } else {
          setCurrentStatus('EXR NFTS NOT ON THIS CHAIN. CONNECTING TO GAME.');
        }
      }
      setTimeout(function(){
        closeAllModals();
        window.$("#splash-main-buttons").fadeOut(2000);
      },2000);
      setTimeout(function() {
        if(window.location.origin.indexOf("builder") >= 0) {
          dispatch(updateLocation("track_uploader"));
          window.location.href = '/#/track_uploader';
        } else if(signup_flow_flag == 0) {
          dispatch(updateLocation("signup"));
          window.location.href = '/#/signup';
        } else {
          dispatch(updateLocation("races_home"));
          window.location.href = '/#/races_home';
        }
      }, 5000);
    } else {
      checkPilotItems();
      checkRacecraftItems();
      checkBoosterItems();
      let nextnum = checknum - 1;
      setTimeout(function(){
        checkGameAssets(nextnum,signup_flow_flag);
      },2000);
    }
  }

  function loginStepOne() {
    var walletID = document.getElementById("walletID").value;
    var addData = new Object();
    addData.walletID = encodeURIComponent(walletID);
    addData.raceHost = encodeURIComponent(window.location.host);
    
    axiosInstance.post('member/racer_login/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var login_result = result.data;
            if(login_result.login_status.includes("success")) {
                let racerID = login_result.racer_id;
                let signup_flow_flag = login_result.signup_flow;
                dispatch(setRacerLogin(walletID,racerID));
                setTimeout(function() {
                  loginStepTwo(signup_flow_flag);
                }, 3000);
            } else {
                setTimeout(function() {
                    $("#loginform").hide();
                    $("#loginformbutton").hide();
                    $("#loginheader").hide();
                    $("#loginchecksubmit").show();
                    $("#logincheckloading").hide();
                    $("#errorheader").show();
                    $("#confirmerror").show();
                }, 3000);
            }
        }
    });
  }

  function startLoginProcess(e) {
    e.preventDefault();
    var walletID = document.getElementById("walletID").value;

    if(walletID.length < 10) {
        alert('Connect Your Metamask Wallet to Start Racing');
    } else {
        $("#loginchecksubmit").hide();
        $("#logincheckloading").show();
        $("#loginstatus").show();
        $("#metamaskconnect").hide();
        loginStepOne();
    }
  }

  return (<div id="login-box" className="modal page-modal page-modal-medium">
      <div className="modal-content">
        <div className="row" style={{marginBottom:'5px',position:'relative'}}>
          <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                  X
              </div>
              <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                  <div id="loginheader" className="left headline-text" style={{marginLeft:'5px'}}>CONNECT TO RACE</div>
                  <div id="errorheader" className="left headline-text" style={{marginLeft:'5px',display:'none'}}>NOT AUTHORIZED</div>
              </div>
              <div className="col s12" style={{padding:"0px 5px"}}>
                  <div className="divider-thin"></div>
              </div>
              <div id="confirmerror" className="col s12 subheadline-text splash-modal-confirmtxt" style={{marginTop:'20px',fontSize:'18px'}}>
                  You don't have access to the Exiled Racers training program. 
                  <br /><br />
                  EXR race training is open to a limited set of early test pilots. Use the Request Training Access Button on the Exiled Racers homepage to register your interest.
                  <br /><br />
                  - The EXR Team
              </div>
              <div id="loginform" className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                  <div className="row" style={{margin:'0px 0px 10px 0px'}}>
                    Exiled Racers race training is now available. If you have been given access as a training pilot, connect to MetaMask to login below.
                  </div>
                  <div className="row" style={{margin:'20px 0px 0px 0px'}}>
                    Wallet Address:
                  </div>
                  <div className="row input-field" style={{margin:'0px'}}>
                      <input value={accountAddress} 
                                        id="WID"
                                        name="WID" 
                                        type="text" 
                                        className="browser-default light-grey-bubble-input"
                                        style={{width:'100%',cursor:'not-allowed'}}
                                        title="Current Wallet Address"
                                        disabled />
                  </div>
                  <div className="row" id="metamaskconnect" style={{margin:'0px',marginTop:'10px',width:'99%'}}>
                      <div id="mmasksync" className="btn-flat right enableEthereumButton splash-submodal-button" title="Connect MetaMask Wallet">
                          <span className="headline-text white-red-link" title="Connect MetaMask Wallet" onClick={updateWalletAddress}>CONNECT METAMASK</span>
                      </div>
                  </div>
                  <div className="row headline-text" id="loginstatus" style={{margin:'0px',marginTop:'10px',width:'99%',display:'none'}}>
                    <span className="headline-text white-text right" style={{fontSize:'12px'}}>{currentLoginStatus}</span>
                  </div>
                  <input name="walletID" id="walletID" type="hidden" className="validate left" disabled required />
              </div>
              <div id="loginformbutton" className="col s12 subheadline-text" style={{marginTop:'30px'}}>
                  <div id="loginchecksubmit" className="center-align modal-trigger right" title="Submit Login">
                      <span className="round-border-button" style={{fontSize:'20px'}} title="Submit Login" onClick={(e)=>startLoginProcess(e)}>
                          SUBMIT
                      </span>
                  </div>
                  <div id="logincheckloading" className="preloader-wrapper small active right splash-modal-loader">
                      <div className="spinner-layer spinner-red-only">
                          <div className="circle-clipper left">
                              <div className="circle"></div>
                          </div>
                          <div className="gap-patch">
                              <div className="circle"></div>
                          </div>
                          <div className="circle-clipper right">
                              <div className="circle"></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
  );
};
