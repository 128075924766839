/**
 * A randomizer
 */
export class Random {
    static MULTIPLIER = 69069;
    static MODULUS = 2 ** 32;
    static INCREMENT = 1;

    /**
     * Make a random seed
     * @returns {number} A valid random seed
     */
    static makeSeed() {
        return Math.floor(Math.random() * Random.MODULUS);
    }

    /**
     * Make a random seed from a string hash
     * @param {string} hash The hash
     * @returns {number} A valid random seed
     */
    static hashToSeed(hash) {
        let seed = 0;

        for (let i = 0; i < hash.length; ++i)
            seed = ((seed << 1) + hash.charCodeAt(i)) & 0xFFFFFFFF;

        return seed;
    }

    /**
     * Construct a randomizer
     * @param {number} seed The seed, which must be a 32-bit unsigned integer
     */
    constructor(seed = Random.makeSeed()) {
        this.n = seed;
    }

    /**
     * Fork this randomizer
     * @returns {Random} A fork of this randomizer
     */
    fork() {
        return new Random(this.n);
    }

    /**
     * Shuffle an array using this randomizer
     * @param {Array} array The array to shuffle
     * @returns {Array} The shuffled array
     */
    shuffle(array) {
        for (let index = array.length; index-- > 0;) {
            const randomIndex = Math.floor(this.float * (index + 1));

            [array[index], array[randomIndex]] = [array[randomIndex], array[index]];
        }

        return array;
    }

    /**
     * Get a randomized number in the range [0, 0xFFFFFFFF]
     * @returns {number} A random number
     */
    get int() {
        return this.n = (Random.MULTIPLIER * this.n + Random.INCREMENT) % Random.MODULUS;
    }

    /**
     * Get a randomized number in the range [0, 1]
     * @returns {number} A random number
     */
    get float() {
        return this.int / Random.MODULUS;
    }

    /**
     * Get a randomized boolean
     * @returns {boolean} A random boolean
     */
    get bool() {
        return this.float < .5;
    }
}