import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import "../../league_landing.css";
import $ from "jquery";

import {TrackLoader} from "../../../../../webgl/race/utils/trackLoader.js"
import { Track } from "../../../../../webgl/race/track/track.js";
import { TrackMap } from "../../../../../webgl/race/track/map/trackMap.js";
import { Minimap } from "../../../../../webgl/race/minimap/minimap.js";


let total_page_results = 25;
let current_pagenum = 0;
let sectionHTMLArray = [];

export const LeagueRacedays = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [nextRaceSectionHTML, setNextRaceSectionHTML] = useState('');
    const [allRaceSectionHTML, setAllRaceSectionHTML] = useState('');
    const [showMoreHTML, setShowMoreHTML] = useState('');

    var racer_items = [];
    function grabLeagueRaces() {
        // current_pagenum = pagenum;
        // var addData = new Object();
        // addData.racerID = encodeURIComponent(racerID);
        // addData.walletID = encodeURIComponent(accountAddress);
        // addData.raceStatus = 'past';
        // addData.page = pagenum;
        // addData.rcount = total_page_results;
        // addData.raceHost = encodeURIComponent(window.location.host);

        // axiosInstance.post('member/races_list/',addData).then(result => {
        //     if(Object.keys(result.data).length > 0) {
        //         var racer_details = result.data;
        //         loadRacerRaces(racer_details,pagenum);
        //     }
        // }).catch(error => {
        //     console.log(error);
        // });

        loadLeagueRaceday(props.racedays);
    }

    function startBlankRace() {
        window.location.href="/#/race/invite";
    }

    if(entrySet == false) {
        setEntry(true);
        grabLeagueRaces();
    }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function loadLeagueRaceday(racedayarr) {
        var next_section_html = [];
        var all_section_html = [];
        var upcoming_section_html = [];
        var past_section_html = [];

        var user_group_id = 0;
        for(var i = 0; i < props.season_members.length;i++) {
            if(props.season_members[i]['user_id'] == userID) {
                user_group_id = props.season_members[i]['group_id'];
            }
        }

        var ondeckrace;
        for(var z = 0; z < racedayarr.length;z++) {
            var raceday_number = racedayarr[z]['raceday_number'];
            var date_entry_close = racedayarr[z]['date_race_entry_close'];
            var date_predictions_start = racedayarr[z]['date_race_prediction_start'];
            var date_predictions_close = racedayarr[z]['date_race_prediction_close'];
            var date_simulation_close = racedayarr[z]['date_race_simulation_start'];
            var date_viewing_open = racedayarr[z]['date_race_viewable'];
            var completed_flag = racedayarr[z]['race_completed'];
            var race_next_flag = racedayarr[z]['race_next'];
            var race_ondeck_flag = racedayarr[z]['race_ondeck'];
            var race_track_id = racedayarr[z]['race_track_id'];
            var race_track_galaxy = racedayarr[z]['race_track_galaxy'];
            var race_scoring = racedayarr[z]['race_scoring'];
            var race_laps = racedayarr[z]['race_laps'];
            var race_rpm = racedayarr[z]['race_rpm'];
            var raceday_races = racedayarr[z]['raceday_races'];

            var pagenum = 0;

            var race_track_galaxy_short = race_track_galaxy;
            if(race_track_galaxy == 'augment') {
                race_track_galaxy_short = 'aug';
            } else if(race_track_galaxy == 'mercenary') {
                race_track_galaxy_short = 'merc';
            } else if(race_track_galaxy == null) {
                race_track_galaxy_short = 'merc';
            }

            var race_date = '';
            var entry_close_time = new Date(date_entry_close);
            var prediction_close_time = new Date(date_predictions_close);
            var simulation_close_time = new Date(date_viewing_open);
            var today_date = new Date();
            var entry_day_diff = (entry_close_time - today_date) / (1000*60*60*24);

            var sim_day = simulation_close_time.getDay();
            var sim_month =  simulation_close_time.toLocaleString('default', { month: 'short' });
            var sim_date =  simulation_close_time.getDate();
            var sim_min = simulation_close_time.getMinutes();
            var sim_hour = simulation_close_time.getHours();
            var timedirection = 'PM';
            if (sim_hour < 12) {
                timedirection = 'AM';
            } else if (sim_hour > 12) {
                sim_hour = sim_hour - 12;
            }
            if(sim_hour == 0) {
                sim_hour = 12;
            }
            let race_time = sim_hour;
            if(sim_min > 10) {
                race_time = race_time + ':'+sim_min+' ';
            } else if(sim_min > 0) {
                race_time = race_time + ':0'+sim_min+' ';
            }
            race_time = race_time + timedirection+' EST';
            var race_time_text = sim_month + " " + sim_date + " @ " + race_time;

            var entry_min = entry_close_time.getMinutes();
            var entry_hour = entry_close_time.getHours();
            var entry_day = entry_close_time.getDay();
            var entry_month =  entry_close_time.toLocaleString('default', { month: 'short' });
            var entry_date =  entry_close_time.getDate();

            var timedirection = 'PM';
            if (entry_hour < 12) {
                timedirection = 'AM';
            } else if (entry_hour > 12) {
                entry_hour = entry_hour - 12;
            }
            if(entry_hour == 0) {
                entry_hour = 12;
            }
            let entry_close_time_text = entry_hour;
            if(entry_min > 10) {
                entry_close_time_text = entry_close_time_text + ':'+entry_min+' ';
            } else if(entry_min > 0) {
                entry_close_time_text = entry_close_time_text + ':0'+entry_min+' ';
            }
            entry_close_time_text = entry_close_time_text + timedirection+' EST';
            entry_close_time_text = entry_month + " " + entry_date + " @ " + entry_close_time_text;
            if(entry_day_diff > 0) {
                race_date = 'Enter By: '+entry_close_time_text;
            } else {
                race_date = ''+race_time+' (Enter By: '+entry_close_time_text+')';
            }

            var pred_min = prediction_close_time.getMinutes();
            var pred_hour = prediction_close_time.getHours();
            var pred_day = prediction_close_time.getDay();
            var pred_month =  prediction_close_time.toLocaleString('default', { month: 'short' });
            var pred_date =  prediction_close_time.getDate();

            var timedirection = 'PM';
            if (pred_hour < 12) {
                timedirection = 'AM';
            } else if (pred_hour > 12) {
                pred_hour = pred_hour - 12;
            }
            if(pred_hour == 0) {
                pred_hour = 12;
            }
            let pred_close_time_text = pred_hour;
            if(pred_min > 10) {
                pred_close_time_text = pred_close_time_text + ':'+pred_min+' ';
            } else if(pred_min > 0) {
                pred_close_time_text = pred_close_time_text + ':0'+pred_min+' ';
            }
            pred_close_time_text = pred_month + " " + pred_date + " @ " + pred_close_time_text + timedirection+' EST';

            if(raceday_races.length > 10) {
                raceday_races.sort(function(a,b) {
                    return b.user_race_importance - a.user_race_importance;
                });
            }
            var racessorted = [];
            var group_entered = 0;
            // if(user_group_id > 0) {
            //     for(var m = 0; m < raceday_races.length; m++) {
            //         if(raceday_races[m]['group_id'] == user_group_id) {
            //             racessorted.push(raceday_races[m]);
            //             group_entered = 1;
            //         }
            //     }
            // }

            var interesting_race_list = [];
            for(var m = 0; m < raceday_races.length; m++) {
                // let race_racers = JSON.parse(raceday_races[m]['racing_users']);
                // for(var p = 0; p < race_racers.length;p++) {
                //     if(race_racers[p] == userID) {
                //         raceday_races[m]['user_race'] = 1;
                //         racessorted.push(raceday_races[m]);
                //         group_entered = 1;
                //         interesting_race_list.push(raceday_races[m]['race_id']);
                //     }
                // }

                if(raceday_races[m]['user_racing'] == 1) {
                    raceday_races[m]['user_race'] = 1;
                    racessorted.push(raceday_races[m]);
                    group_entered = 1;
                    interesting_race_list.push(raceday_races[m]['race_id']);
                }
            }
            
            for(var m = 0; m < raceday_races.length; m++) {
                if(interesting_race_list.length == 0) {
                    racessorted.push(raceday_races[m]);
                } else {
                    for(var p = 0; p < interesting_race_list.length;p++) {
                        if(raceday_races[m]['race_id'] != interesting_race_list[p]) {
                            raceday_races[m]['user_race'] = 0;
                            racessorted.push(raceday_races[m]);
                        }
                    }
                }
                
            }
            
            var racerow = [];
            var racerow2 = [];
            var playoffs_week = '';
            var maxraceshow = racessorted.length;
            // if((props.view_source == 'league_home')&&(maxraceshow > 5)) {
            //     if(raceday_races.length > 10) {
            //         maxraceshow = 5;
            //     } else {
            //         maxraceshow = 6;
            //     }
            // } else if(maxraceshow > 6) {
            //     maxraceshow = 6;
            // }
            
            if(raceday_races.length > 10) {
                maxraceshow = 5;
            } else if(maxraceshow > 6) {
                maxraceshow = 6;
            }
            for(var m = 0; m < maxraceshow; m++) {
                var race_id = racessorted[m]['race_id'];
                var race_group_id = racessorted[m]['group_id'];
                var race_unique_id = racessorted[m]['race_unique_id'];
                var race_group_number = racessorted[m]['group_number'];
                var race_name = racessorted[m]['race_name'];
                var race_type = racessorted[m]['race_type'];
                var racing_users = racessorted[m]['racing_users'];
                var race_scoring = racessorted[m]['race_scoring'];
                var user_race = racessorted[m]['user_race'];

                var racetmpinner = <div className="row center-align stats-light-grey-white-link" style={{margin:'0px',padding:'10px',borderRadius:'5px',marginBottom:'0px'}}
                                    data-raceid={race_unique_id}
                                    onClick={(e)=>switchToRace(e)}>
                                    {"GROUP "+race_group_number}
                                </div>;
                if(race_type != 'L') {
                    
                    if(race_type != 'RF') {
                        playoffs_week = race_type;
                    }
                    racetmpinner = <div className="row center-align stats-light-grey-white-link" style={{margin:'0px',padding:'10px',borderRadius:'5px',marginBottom:'0px'}}
                                    data-raceid={race_unique_id}
                                    onClick={(e)=>switchToRace(e)}>
                                    {race_name.replace("League ","")}
                                </div>;
                } else if((user_race == 1)&&(props.group_count == 1)) {
                    racetmpinner = <div className="row center-align stats-light-grey-white-link" title="Your Race"
                                    style={{position:'relative',margin:'0px',padding:'10px',borderRadius:'5px',marginBottom:'0px',overflow:'hidden'}}
                                    data-raceid={race_unique_id}
                                    onClick={(e)=>switchToRace(e)}>
                                    &nbsp;{"VIEW YOUR RACE"}&nbsp;
                                    <i className="itemtriangle"></i>
                                    <i className="material-icons itemstar" title="Your Race" style={{position:'absolute',top:'3px',right:'3px',fontSize:'12px',color:'#606060'}}>star</i>
                                </div>;
                } else if((race_group_id == user_group_id)&&(props.group_count > 1)) {
                    racetmpinner = <div className="row center-align stats-light-grey-white-link" title="Your Race"
                                    style={{position:'relative',margin:'0px',padding:'10px',borderRadius:'5px',marginBottom:'0px',overflow:'hidden'}}
                                    data-raceid={race_unique_id}
                                    onClick={(e)=>switchToRace(e)}>
                                    {"GROUP "+race_group_number}
                                    <i className="itemtriangle"></i>
                                    <i className="material-icons itemstar" title="Your Race" style={{position:'absolute',top:'3px',right:'3px',fontSize:'12px',color:'#606060'}}>star</i>
                                </div>;
                } else if((props.group_count == 1)) {
                    racetmpinner = <div className="row center-align stats-light-grey-white-link" title="Your Race"
                                    style={{position:'relative',margin:'0px',padding:'10px',borderRadius:'5px',marginBottom:'0px',overflow:'hidden'}}
                                    data-raceid={race_unique_id}
                                    onClick={(e)=>switchToRace(e)}>
                                    {"RACE #"+race_id}
                                </div>;
                }
                var racetmp = <div className="left" style={{padding:'5px',margin:'0px',marginTop:'0px',fontSize:'12px',width:'150px'}}>
                                {racetmpinner}
                                </div>;
                racerow.push(racetmp);

                var racetmp = <div className="col s6" style={{padding:'5px',margin:'0px',marginTop:'0px',fontSize:'12px'}}>
                                {racetmpinner}
                                </div>;
                racerow2.push(racetmp);
            } 

            var final_week_name = "WEEK "+raceday_number;
            if(playoffs_week.length > 0) {
                if(playoffs_week == 'QF') {
                    final_week_name = "QUARTERFINALS";
                } else if(playoffs_week == 'SF') {
                    final_week_name = "SEMIFINALS";
                } else if(playoffs_week == 'F') {
                    final_week_name = "FINAL";
                } else if(playoffs_week == 'RF') {
                    final_week_name = "QUARTERFINALS";
                }
            }


            var trackweek = 'track-viewer-'+raceday_number;
            var rowkey = z+(pagenum*total_page_results*100);
            var entered_text = <></>;
            if(completed_flag == 1) {
                entered_text = <> // <span className="exr-light-green-text">Completed</span> </>;
            } else if(group_entered > 0) {
                entered_text = <> // <span className="exr-yellow-text">Entered</span> </>;
            }
            var tmp_html = <><div className="row exr-new-light-grey" style={{padding:'5px',margin:'0px',marginTop:'10px',borderRadius:'5px'}}>
                                <div className="row" style={{display:'flex',flexDirection:'row',padding:'0px',margin:'0px'}}>
                                    <div className="exr-stats-light-grey hide-on-med-and-down show-on-large-only" 
                                        style={{maxHeight:'165px',width:'100px',borderRadius:'5px',minHeight:'140px',display:'flex',flexDirection:'column'}}>
                                        <div style={{flex:'1'}}></div>
                                        <div className="transparent raceday-track-viewer" id={trackweek} style={{width:'90px',height:'100px',margin:'5px',borderRadius:'10px'}}></div>
                                        <div className="white-text center-align" style={{width:'100%',marginTop:'10px',marginBottom:'5px',fontSize:'12px',verticalAlign:'center'}}>
                                            {race_track_galaxy_short.toUpperCase()+" TRACK"}
                                        </div>
                                        <div style={{flex:'1'}}></div>
                                    </div>
                                    <div className="left" style={{flex:'1',padding:'0px 20px'}}>
                                        <div className="col s12">    
                                            <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'10px'}}>
                                                <div className="col s6">
                                                    <div className="row headline-text" style={{fontSize:'16px',marginBottom:'2px'}}>
                                                        {final_week_name}
                                                    </div>
                                                    <div className="row" style={{marginBottom:'0px'}}>
                                                        {race_time_text}  {entered_text}
                                                    </div>
                                                </div>
                                                <div className="col s6 right-align">
                                                    <div className="row" style={{marginBottom:'2px'}}>
                                                        Enter By: {entry_close_time_text}
                                                    </div>
                                                    <div className="row" style={{marginBottom:'0px'}}>
                                                        Predict By: {pred_close_time_text}       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12 hide-on-med-and-down show-on-large-only" style={{marginBottom:'0px',marginTop:'0px',fontSize:'14px'}}>
                                            <div className="row headline-text" style={{display:'none',fontSize:'12px',marginTop:'5px',marginBottom:'0px'}}>
                                                VIEW RACES
                                            </div>
                                            <div className="row" style={{fontSize:'12px',marginTop:'5px',marginBottom:'5px'}}>
                                                {racerow}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 show-on-med-and-down hide-on-large-only" style={{marginBottom:'10px',marginTop:'10px',fontSize:'14px'}}>
                                        <div className="col s12 headline-text" style={{borderBottom:'1px solid #EBEBEB',fontSize:'12px',paddingBottom:'5px',marginBottom:'5px'}}>
                                            VIEW RACES
                                        </div>
                                        {racerow2}
                                </div>
                                </div>
                            </>;

            if((race_next_flag == 1)) {
                next_section_html.push(tmp_html);
            }
            if((race_ondeck_flag == 1)&&(ondeckrace == null)) {
                ondeckrace = tmp_html;
            }
            if(completed_flag == 1) {
                past_section_html.push(tmp_html);
            } else {
                upcoming_section_html.push(tmp_html);
            }
            all_section_html.push(tmp_html);
        }

        
        if((next_section_html.length == 0)&&(ondeckrace != null)) {
            next_section_html.push(ondeckrace);
        } else if(next_section_html.length == 0) {
            setTimeout(function(){
                $("#next-race-section").hide();
            },10);
        }


        /* Add No Past Races */
        if((all_section_html.length == 0)) {
            var tmp_html = <div className="col s12" style={{marginBottom:'5px'}}>
                                NO LEAGUE RACES
                            </div>;
            all_section_html.push(tmp_html);
        }
        
        setNextRaceSectionHTML(next_section_html);
        setAllRaceSectionHTML(all_section_html);

        setTimeout(function() {
            var nextracksquare = $("#next-race-segment").find(".raceday-track-viewer");
            if(nextracksquare.length > 0) {
                var newtrackid = $("#next-race-segment").find(".raceday-track-viewer")[0].id +'-next';
                $("#next-race-segment").find(".raceday-track-viewer")[0].id = newtrackid;
            }
            for(var z = 0; z < racedayarr.length;z++) {
                var raceday_number = racedayarr[z]['raceday_number'];
                var race_track_id = racedayarr[z]['race_track_id'];
                var completed_flag = racedayarr[z]['race_completed'];
                var race_next_flag = racedayarr[z]['race_next'];
                loadRacedayTrack(raceday_number, race_track_id);
                loadRacedayTrack(raceday_number+'-next', race_track_id);
            }
        },1000);
    }

    async function loadRacedayTrack(raceday_number, race_track_id) {
        let track_viewer_container = window.document.getElementById("track-viewer-"+raceday_number);
        if(track_viewer_container) {
            let track_data = await new Track(race_track_id,'id');
            new TrackLoader(track_data,
                () => {
                    if(track_viewer_container) {
                        $("#track_viewer_container").html('');
                        let track_minimap = new Minimap(new TrackMap(track_data),track_viewer_container).populate(null);
                    }
                }
            );
        }
    }

    function showMoreResults(pagenum) {
        $("#showmorebutton").hide();
        $("#showmoreloading").show();
    }

    function switchToRace(e) {
        e.preventDefault();
        var race_hash = '';
        let race_elem = e.target;
        while(race_hash == '') {
            if(race_elem.dataset.raceid) {
                race_hash = race_elem.dataset.raceid;
            } else {
                race_elem = race_elem.parentElement;
            }
        }
        window.location.href= '/#/race/'+race_hash;
        dispatch(updateLocation("race_landing"));
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
                <div className="row white-text " style={{padding:'5px 0px',margin:'0px'}}>
                    <div id="next-race-section" className="col s12 exr-new-dark-grey" style={{borderRadius:'10px',margin:'0px',padding:'0px',marginBottom:'5px'}}>
                        <div className="row" id="next-race-segment" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div className="col s12 headline-text white-text left-align" style={{marginTop:'0px',fontSize:'20px'}}>
                                    NEXT RACE
                                </div>
                            </div>
                            {nextRaceSectionHTML}
                        </div>
                    </div>
                    <div className="col s12 exr-new-dark-grey" style={{borderRadius:'10px',margin:'0px',marginTop:'0px',padding:'0px'}}>
                        <div className="row" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px',minHeight:'70vh'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div id="full-schedule-title" className="col s9 m6 headline-text white-text left-align" style={{marginTop:'0px',fontSize:'20px'}}>
                                    FULL SCHEDULE
                                </div>
                                <div className="col s3 m6 white-text right-align" style={{fontSize:'14px',marginTop:'4px'}}>
                                    ALL RACES 
                                </div>
                            </div>
                            {allRaceSectionHTML}
                        </div>
                    </div>
                </div>
            </div>);
};