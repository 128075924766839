import React, { useEffect } from "react";
import Viewer from "../../../webgl/viewer/viewer.js";
import "./ship_viewer.css";

export const ShipViewer = () => {
  const canvas = React.createRef();
  let wingSelection = true;
  let fuseSelection = true;
  let propSelection = true;
  let light = true;

  let viewer = null;

  useEffect(() => {
    if (canvas.current) {
      canvas.current.width = 800;
      canvas.current.height = 600;

      viewer = new Viewer(canvas.current);
    }
    loadDropdowns();
  }, [canvas]);


  window.addEventListener("hashchange",
  function(){ 
      window.location.reload(); 
  }, false);

  function loadDropdowns() {
    var ddelems = document.querySelectorAll('.dropdown-trigger');
    var ddinstances = window.M.Dropdown.init(ddelems, {hover:true, alignment:'left',coverTrigger:false});
  }

  function toggleWing() {
    if (wingSelection === true) {
      wingSelection = false;
      fuseSelection = false;
      propSelection = false;
    } else {
      wingSelection = true;
    }
    window.$("#gsettingsviewname").html("Wing")
    viewer?.showWingView();
  }

  function toggleFuse() {
    if (fuseSelection === true) {
      fuseSelection = false;
      propSelection = false;
      wingSelection = false;
    } else {
      fuseSelection = true;
    }
    window.$("#gsettingsviewname").html("Fuselage")
    viewer?.showFuseView();
  }

  function toggleProp() {
    if (propSelection === true) {
      propSelection = false;
      wingSelection = false;
      fuseSelection = false;
    } else {
      propSelection = true;
    }
    viewer?.showPropView();
    window.$("#gsettingsviewname").html("Propulsion")
  }

  function toggleNextShip() {
    viewer?.loadNextShip();
  }

  function togglePreviousShip() {
    viewer?.loadPreviousShip();
  }

  function reloadShip() {
    viewer?.loadSameShip();
  }

  function toggleLight() {
    if (light === true) {
      light = false;
      window.$("#skybox").html("Dark");
    } else {
      light = true;
    }
    viewer?.toggleSkybox();
  }

  return (
    <div style={{ position: "relative", width: "auto", height: "auto" }}>
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "fixed",
          top: "0px",
          left: "0px",
        }}
      >
        <canvas
          id={"renderer"}
          ref={canvas}
          style={{ backgroundColor: "black", border: "5px solid white", borderRadius: "20px" }}
        />
      </div>

      <div className="row page-main-ship" style={{ position: "fixed" }}>
        <div id="gui">
          <div className="menumodule" id="shipviewermenu">
            <div id="shipsettingsview" className="shipsettingsbutton">
              <div className="shipsettingsbuttonname">View:</div>
              <div
                id="shipsettingsviewname"
                className="shipsettingsbuttonvalue dropdown-trigger"
                data-target="viewpicker"
              >
                Full
              </div>
              <ul id="viewpicker" className="dropdown-content shipddmenu">
                <li className="shipddmenuoption" onClick={() => toggleWing()}>Wing</li>
                <li className="shipddmenuoption" onClick={() => toggleFuse()}>Fuselage</li>
                <li className="shipddmenuoption" onClick={() => toggleProp()}>Propulsion System</li>
              </ul>
            </div>
            <div className="shipsettingsdivider"></div>
            <div className="shipsettingsbutton">
              <div className="shipsettingsbuttonvalue" onClick={() => togglePreviousShip()}><i style={{fontSize: '20px'}} className="material-icons">arrow_back</i></div>
            </div>
            <div className="shipsettingsdivider"></div>
            <div className="shipsettingsbutton">
              <div className="shipsettingsbuttonvalue" onClick={() => toggleNextShip()}><i style={{fontSize: '20px'}} className="material-icons">arrow_forward</i></div>
            </div>
          </div>
        </div>
      </div>
  

      <div
        style={{ position: "absolute", padding: "15px" }}
        className="white-text headline-text"
      >
        Ship Viewer
      </div>
      {/* <div
        id="wing"
        style={{ position: "absolute", marginTop: "30px" }}
        className="btn-flat white-text subheadline-text"
        onClick={() => toggleWing()}
      >
        Wing
      </div>
      <div
        id="Fuselage"
        style={{ position: "absolute", marginTop: "60px" }}
        className="btn-flat white-text subheadline-text"
        onClick={() => toggleFuse()}
      >
        Fuselage
      </div>
      <div
        id="Propulsion System"
        style={{ position: "absolute", marginTop: "90px" }}
        className="btn-flat white-text subheadline-text"
        onClick={() => toggleProp()}
      >
        Propulsion System
      </div> */}
    </div>
  );
};