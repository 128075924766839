import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {closeAllModals} from "../../../utils";
import $ from "jquery";

export const ShareResultsGraphic = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers, live_hosts } = useSelector((state) => state.app);

    const [headerSet, setHeader] = useState(false);
    const [raceID, setRaceID] = useState(props.raceID);
    const [raceUniqueID, setRaceUniqueID] = useState(props.raceHash);
    const [race_graphic_html,setRaceGraphicHtml] = useState('');

    function ordinal_suffix_of(i) {
      var j = i % 10,
          k = i % 100;
      if (j == 1 && k != 11) {
          return i + "st";
      }
      if (j == 2 && k != 12) {
          return i + "nd";
      }
      if (j == 3 && k != 13) {
          return i + "rd";
      }
      return i + "th";
    }

    function loadResultsGraphic() {
        var itemarr = props.resultData;

        var section_html = [];
        var racer_attributes = ['speed', 'handling', 'acceleration', 'strength'];
        var pilot_attributes = ['focus', 'stamina', 'aggression', 'intelligence'];
        var booster_attributes = ['speed', 'handling', 'acceleration', 'strength', 'focus', 'stamina', 'aggression', 'intelligence'];
        
        for(var z = 0; z < itemarr.length;z++) {
            var pilot_image = itemarr[z].pilot_image_location;
            var racer_image = itemarr[z].ship_image_location;

            var racer_name = itemarr[z].racer_name;
            var item_subtitle = 'SERF FLEET';
            
            var item_col_size = "col s12 l6";
            var item_image_height = '350px';
            var stats_box_height = '250px';
            if((itemarr.length > 2)&&($("#race-landing-shell").width() > 800)) {
                item_col_size = "col s12 m6 l4";
                item_image_height = '300px';
                stats_box_height = '200px';
            } else if ($("#race-landing-shell").width() < 550) {
                var item_col_size = "col s12";
                var item_image_height = '350px';
                var stats_box_height = '250px';
            }
            
            var final_place = itemarr[z].race_final_position
            var placetext = ordinal_suffix_of((z+1));
            var placecolor = 'white-text';
            if(z == 0) {
                placecolor = 'exr-red-text';
                placetext = 'Winner'
            }
            var racer_final_xp = itemarr[z].race_final_xp;
            var racer_final_exr = itemarr[z].race_final_coins;

            var exrcolor = '';
            if(racer_final_exr > 0) {
                exrcolor = ' exr-light-green-text';
            }

            /* Calculate Race Time */
            var racer_final_seconds = itemarr[z].race_final_time.toString();
            var millisplit = racer_final_seconds.split(".");
            if(millisplit.length == 1) {
                var millicount = '00';
                var totalseconds = parseInt(millisplit[0]);
            } else {
                var millicount = millisplit[1];
                var totalseconds = parseInt(millisplit[0]);
            }
            if(millicount.length == 1) {
                millicount = millicount+'0';
            }
            var totalmins = Math.floor(totalseconds / 60);
            var finalseconds = totalseconds - (totalmins*60);
            var finaltimetxt = '';
            if(totalmins < 10) {
                finaltimetxt = finaltimetxt+'0';
            }
            finaltimetxt = finaltimetxt+totalmins+'m ';
            if(finalseconds < 10) {
                finaltimetxt = finaltimetxt+'0';
            }
            finaltimetxt = finaltimetxt+finalseconds+'s ';
            
            finaltimetxt = finaltimetxt+millicount+'ms';

            var total_racer_predictors = itemarr[z].total_predictors;
            var predicted_html = <></>;
            var predicted_html_row = <></>;
            if(itemarr[z].racer_predicted > 0) {
                if(itemarr[z].racer_predicted_win > 0) {
                    predicted_html = <><div className="col s7">
                                            Your Prediction: <b className="exr-light-green-text">Correct</b>
                                        </div>
                                        <div className="col s5 exr-light-green-text right-align">
                                            {itemarr[z].racer_predicted_win_xp} XP Won
                                        </div></>;
                    predicted_html_row = <span className="exr-light-green-text" style={{fontFamily: 'NBInternationalPro-Light',fontSize:'16px'}}>(Predicted +{itemarr[z].racer_predicted_win_xp} XP)</span>;

                } else {
                    predicted_html = <><div className="col s7">
                                            Your Prediction: <b className="exr-red-text">Missed</b>
                                        </div>
                                        <div className="col s5 exr-red-text right-align">
                                            0 XP Won
                                        </div></>;
                    predicted_html_row = <span className="exr-red-text" style={{fontFamily: 'helvetica',fontSize:'14px'}}><b>(PREDICTION MISSED)</b></span>;
                }
            } else {
                predicted_html = <><div className="col s6">
                                            Total Predictors: {total_racer_predictors}
                                        </div>
                                        <div className="col s6 right-align">
                                            0 XP Won
                                        </div></>;
            }


            var tmp_html_old = <div key={z} className={"col s12 finish-screen-position"} style={{margin:'0px',padding:'0px',paddingLeft:"4px",paddingRight:"4px"}}><div id={"fboard"+z} className="final-results-col exr-new-light-grey">
                                <div className="row finish-screen-racer-name white-text" style={{fontSize:"16px"}}>
                                    <span className={placecolor}><b>{placetext.toUpperCase()}:</b> </span>
                                    <span className="white-text">&nbsp;{racer_name.toUpperCase()}</span>
                                </div>
                                <div className="row finish-screen-racer-divider"></div>
                                <div className="row finish-screen-racer-image" style={{height:item_image_height,background:'url(\''+pilot_image+'\')',backgroundSize:'cover',backgroundPosition:'center'}}>
                                </div>
                                <div className="row finish-screen-racer-sub">
                                    <div className="left finish-screen-racer-ship"  style={{height:stats_box_height,background:'url(\''+racer_image+'\')',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
                                    </div>
                                    <div className="left finish-screen-racer-stats exr-new-dark-grey" style={{minHeight:stats_box_height}}>
                                        <div className="finish-screen-stats-row">
                                            RPM Won
                                        </div>
                                        <div className={"finish-screen-stats-subrow"+exrcolor}>
                                            {racer_final_exr}
                                        </div>
                                        <div className="finish-screen-stats-row">
                                            XP Earned
                                        </div>
                                        <div className="finish-screen-stats-subrow">
                                            {racer_final_xp}
                                        </div>
                                        <div className="finish-screen-stats-row">
                                            Finish Time
                                        </div>
                                        <div className="finish-screen-stats-subrow">
                                            {finaltimetxt}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>;
                        
            
            if(z == 0) {
              var tmp_html = <><div key={z} className={"col s12 finish-screen-position"} style={{margin:'0px',padding:'0px',paddingLeft:"4px",paddingRight:"4px"}}><div id={"fboard"+z} className="final-results-col exr-new-light-grey">
                                <div className="row finish-screen-racer-name white-text headline-text" style={{fontSize:"18px",lineHeight:'1',paddingTop:'15px'}}>
                                    <span className={placecolor}><b>{placetext.toUpperCase()}:</b> </span>
                                    <span className="white-text">&nbsp;{racer_name.toUpperCase()}</span>
                                </div>
                                <div className="row finish-screen-racer-divider"></div>
                                <div className="row" style={{margin:"5px"}}>
                                  <img src={pilot_image} width="100%" style={{borderRadius:'5px'}} />
                                </div>
                                <div className="row exr-new-dark-grey" style={{margin:"5px",padding:'10px 20px',fontSize:'16px',borderRadius:'5px'}}>
                                        <div className="left">
                                            RPM Won:&nbsp;
                                        </div>
                                        <div className={"left"+exrcolor}>
                                            {racer_final_exr}
                                        </div>
                                        <div className="right">
                                            {finaltimetxt}
                                        </div>
                                        <div className="right">
                                            Finish Time: &nbsp;
                                        </div>
                                        
                                </div>
                            </div>
                        </div>
                        <div className={"col s12"} style={{margin:'0px',padding:'10px 5px'}}>
                            <div className="col s12" style={{margin:"0px",padding:"0px",marginTop:'5px',fontSize:"16px"}}>
                                <div className="left headline-text" style={{fontSize:"20px"}}>
                                    &nbsp;FINAL STANDINGS
                                </div>
                                <div className="right" style={{marginTop:"2px"}}>
                                    Finish Time&nbsp;
                                </div>
                            </div>
                            <div className="col s12" style={{margin:"0px",padding:"0px"}}>
                                <div className="divider-thin"></div>
                            </div>
                        </div></>;
                section_html.push(tmp_html);
            }
                var tmp_html_row = <div key={z+100} className={"col s12 finish-screen-position"} style={{margin:'0px',padding:'0px',paddingLeft:"4px",paddingRight:"4px"}}>
                            <div className="final-results-col exr-new-light-grey" style={{marginBottom:'5px'}}>
                                <div className="row white-text" style={{marginBottom:'0px',fontSize:"16px"}}>
                                    <div className="col s8 m9" style={{overflow:'hidden',height:'50px'}}>
                                        <div className="left headline-text center-align" style={{fontSize:"18px",marginRight:"10px",letterSpacing:"-4px",width:"30px",marginTop:"14px"}}>
                                            {final_place}
                                        </div>
                                        <div className="left" style={{marginRight:"15px",width:"50px"}}>
                                            <img src={pilot_image} width="50px" style={{borderRadius:"5px"}} />
                                        </div>
                                        <div className="left" style={{marginTop:"15px"}}>
                                            {racer_name.toUpperCase()}
                                        </div>
                                    </div>
                                    <div className="col s4 m3 right-align" style={{marginTop:"15px",paddingRight:'15px'}}>
                                        {finaltimetxt}
                                    </div>
                                </div>
                                </div>
                            </div>;
                section_html.push(tmp_html_row);

                /* Add Next Line Seperator */
                //section_html.push(tmp_html_div);
        }

        setRaceGraphicHtml(section_html);
    }

    if(headerSet == false) {
        setHeader(true);
        loadResultsGraphic();
    }

    return(<div className="col s12 m8 l6" style={{marginTop:'15px',background:'black',borderRadius:'10px',display:'none',minWidth:'550px'}} id="resultsimagebox">
              <div className="row exr-new-dark-grey exr-red-bg" style={{padding:'15px',borderRadius:'10px',margin:'12px 4px 5px 4px'}}>
                  <div className="col s12" style={{marginTop:'10px'}}>
                      <span className="left">
                          <img id="shareablelogo" src="css/images/exr_logo_white_stacked.png" height="50px" />
                      </span>
                      <span className="right right-align" style={{marginTop:'2px'}}>
                          <span className="headline-text" style={{fontSize:"18px",lineHeight:"1.5"}}>Race Results</span><br/>
                          <span style={{fontSize:"16px",lineHeight:'1'}}>{'Race Battle #'+raceID}</span>
                      </span>
                  </div>
              </div>
              <div className="row" style={{padding:'0px',borderRadius:'10px',margin:'10px 0px 10px 0px',paddingBottom:'0px'}}>
                  {race_graphic_html}
              </div>
          </div>);
};