import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./predictbook.css";
import styles from './styles.module.scss';
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals} from "../../../utils";
import {BetSlipWide, BetSlipTwo, BetSlipThree} from "./Components";
import $ from 'jquery';
import { toInteger } from "lodash";

let web3;
let racer_sim_watched_global = 0;
let race_simulated_global = 0;
let current_racer_global = 0;
let racer_invite_status_global = 0;
let race_hash_global = '';
let current_race_hash = '';
let current_race_group = 1;
let current_race_collection = 4;
let collectionList = [];
let open_invite_race = 0;
let open_entry_closed = 0;
let predictions_closed = 0;
let entryDisplay = 'none';
let training_box_race_landing = 0;
let current_time_schedule = 0;
let scheduleready = 0;
let schedulestarted = 0;
let race_schedule_array = [];
let time_schedule = [];
// time_schedule.push({schedule_type:'wait', group_id:1, race_time: '19:17:00'});
// time_schedule.push({schedule_type:'play', group_id:1, race_time: '19:18:00'});
// time_schedule.push({schedule_type:'wait', group_id:2, race_time: '19:04:30'});
// time_schedule.push({schedule_type:'play', group_id:2, race_time: '19:06:00'});
// time_schedule.push({schedule_type:'wait', group_id:3, race_time: '19:08:30'});
// time_schedule.push({schedule_type:'play', group_id:3, race_time: '19:10:00'});
// time_schedule.push({schedule_type:'wait', group_id:4, race_time: '19:13:30'});
// time_schedule.push({schedule_type:'play', group_id:4, race_time: '19:15:00'});
// time_schedule.push({schedule_type:'wait', group_id:5, race_time: '19:18:30'});
// time_schedule.push({schedule_type:'play', group_id:5, race_time: '19:20:00'});
// time_schedule.push({schedule_type:'play', group_id:5, race_time: '19:20:00'});

time_schedule.push({schedule_type:'wait', group_id:1, race_time: '19:43:00'});
time_schedule.push({schedule_type:'play', group_id:1, race_time: '19:43:30'});
time_schedule.push({schedule_type:'wait', group_id:2, race_time: '19:44:00'});
time_schedule.push({schedule_type:'play', group_id:2, race_time: '19:44:30'});
time_schedule.push({schedule_type:'wait', group_id:3, race_time: '19:45:00'});


export const Predictbook = () => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    
    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(setRacerLogin('',''));
    //     dispatch(updateLocation("splash"));
    //     window.location.href= '/';
    // }

    var url_race_unique_id = '';
    var url_race_invite_list = '';
    var start_race_section = '';
    var tmp_left_nav_section = 'upcoming';
    var racehash = window.location.href.split("#/");
    if(racehash.length > 1) {
        var racehash2 = racehash[1].replace("#/",'');
        var racehash3 = racehash2.split("/");
        if(racehash3.length > 1) {
            url_race_unique_id = racehash3[1].replace("/",'');
        }
        if((racehash3.length > 2)&&(url_race_unique_id == 'invite')) {
            url_race_invite_list = racehash3[2].replace("/",'');
        }
        if(racehash3[0] == 'race') {
            start_race_section = 'details';
        } else {
            var racehash4 = racehash3[0].split("_");
            start_race_section = racehash4[1];
        }
    }
    race_hash_global = url_race_unique_id;

    const [pageSet, setPage] = useState(false);
    const [headerSet, setHeader] = useState(false);
    const [race_id, setRaceID] = useState(0);
    const [raceHash, setRaceHash] = useState('');
    const [current_page_html, setCurrentPageHTML] = useState('');
    const [raceViewerHTML, setRaceViewerHTML] = useState('');
    const [raceExplorerHTML, setRaceExplorerHTML] = useState('');
    const [raceSchedulerHTML, setRaceSchedulerHTML] = useState('');
    const [selected_prediction_html,setSelectedPredictionHTML] = useState('');

    function grabOfficialCollectionList() {
        var addData = new Object();
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('sportsbook/race_collection_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                collectionList = result.data; 
                startPageSections(collectionList);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function startPageSections(collectionarr) {
        current_race_collection = collectionarr[0]['race_group_collection_id'];

        current_race_group = 'groupid_'+collectionarr[0]['primary_race_group_id'];
        if(collectionarr[0]['race_group_collection_id'] == 5) {
            current_race_group = 'groupid_10';
        }
        if (collectionList[0]['collection_type'] == 'hourly_open') {
            if (collectionList[0]['recent_races_show'] > 0) {
                current_race_group = 'recent_'+collectionarr[0]['primary_race_group_id'];
            } else if ((collectionList[0]['upcoming_races'] > 0)||(collectionList[0]['races_status'] == 'upcoming')) {
                current_race_group = 'upcoming_'+collectionarr[0]['primary_race_group_id'];
            } else {
                current_race_group = 'past_'+collectionarr[0]['primary_race_group_id'];
            }
        }
    }


    if(pageSet == false) {
        setPage(true);
        grabOfficialCollectionList();
        window.startPageModals();
        window.resetBodyWidth();
    }

    function startTimeSchedule() {
        for(var i = current_time_schedule; i < time_schedule.length;i++) {
            var currenttime = time_schedule[i]['race_time'];
            var countDownDate = new Date( "Apr 19, 2023 "+currenttime+" GMT-0400 (Eastern Daylight Time)").getTime();
            var now = new Date().getTime();
            var distance = countDownDate - now;
            if((distance <= 0)||(schedulestarted == 0)) {
                if(schedulestarted == 0) {
                    current_time_schedule = 0;
                } else {
                    current_time_schedule = i+1;
                }
                schedulestarted = 1;
                
                var current_group_id = time_schedule[i]['group_id'];
                var nexthash = race_schedule_array[(current_group_id-1)];
                current_race_hash = nexthash;

                var current_action = time_schedule[i].schedule_type;

                var current_title = 'NEXT RACE STARTS IN';
                var nextime = '22:00';
                if((i+1) < time_schedule.length) {
                    nextime = time_schedule[(i+1)]['race_time'];
                }
                
                let countdownarray = {'action':current_action,'title':current_title,'time':nextime};
                i = time_schedule.length;
            }
            // {schedule_type:'race_wait',group_id:1,race_time: '19:00:00'},
        }

        setTimeout(function(){
            startTimeSchedule();
        },1000);
    }

    function updateRaceScheduleArray(hasharray) {
        race_schedule_array = hasharray;
        scheduleready = 1;
        current_race_hash = race_schedule_array[0];
        //startTimeSchedule();
    }

    function updateRaceViewer(newRaceHash) {
        if(newRaceHash.length > 0) {
            current_race_hash = newRaceHash;
            setRaceHash(newRaceHash);
        }
    }

    function updateRaceCollection(collectionid) {
        current_race_collection = collectionid;
        for(var j = 0; j < collectionList.length;j++) {
            if(collectionid == collectionList[j]['race_group_collection_id']) {
                current_race_group = 'groupid_'+collectionList[j]['primary_race_group_id'];
                if (collectionList[j]['collection_type'] == 'hourly_open') {
                    if (collectionList[j]['recent_races_show'] > 0) {
                        current_race_group = 'recent_'+collectionList[j]['primary_race_group_id'];
                    } else if ((collectionList[j]['upcoming_races'] > 0)||(collectionList[j]['races_status'] == 'upcoming')) {
                        current_race_group = 'upcoming_'+collectionList[j]['primary_race_group_id'];
                    } else {
                        current_race_group = 'past_'+collectionList[j]['primary_race_group_id'];
                    }
                }
            }
        }

        setRaceHash('');
        setRaceViewerHTML('')
        setRaceExplorerHTML('');
        setRaceSchedulerHTML('');
    }

    function addRacePrediction(race_id, pred_racer_id, pred_participant_id, pred_finish, pred_amount) {
        var addData = new Object();
        addData.raceID = race_id;
        addData.userID = userID;
        addData.racerID = racerID;
        addData.predRacerID = pred_racer_id;
        addData.predParticipantID = pred_participant_id;
        addData.predFinish = pred_finish;
        addData.predAmount = pred_amount;

        if(userID == 0) {
            alert('Login To Your Account to Predit. To Signup, Ask Another Community Member on Discord For An Invite Link.');
        } else {
            $("#freepredcheckbutton").hide();
            $("#freepredcheckloading").show();
            axiosInstance.post('race/racer_predict/',addData).then(result => {
                setTimeout(function(){
                    $("#freepredcheckbutton").show();
                    $("#freepredcheckloading").hide();
                    var predictbox = document.getElementById('sbpredictracerbox');
                    var predict_instance = window.M.Modal.getInstance(predictbox);
                    predict_instance.close();
                },1500);
                
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function makeRacePrediction(e) {
        e.preventDefault();
        let race_elem = e.target;
        let praceid = race_elem.dataset.raceid; 
        let pracerid = race_elem.dataset.pracerid;
        let participantid = race_elem.dataset.participantid;
        let predfinish = race_elem.dataset.predfinish;
        let freepred = race_elem.dataset.freepred;

        let add_prediction = addRacePrediction(praceid, pracerid, participantid, predfinish, freepred);

    }

    function updateCurrentRaceGroupSelected(newracegroup) {
        current_race_group = newracegroup;
    }

    function updateRacePredictionBox(raceid, predracerid, racernumber, racername, pilotimage, racecraftimage, predxp, winchance) {
        var race_id = 0;
        var pred_racer_id = 0;
        var pred_participant_id = 0;
        var pred_finish = 1;
        var free_pred_amount = 0;
        var paid_pred_amount = 10;
        var racer_number = 1;
        var racer_name = 'Racer';
        var pilot_image = '';
        var racecraft_image = '';
        var pred_xp = 5;
        var win_chance = 50;

        race_id = raceid;
        pred_racer_id = predracerid;
        racer_number = racernumber;
        racer_name = racername;
        pilot_image = pilotimage;
        racecraft_image = racecraftimage;
        pred_xp = predxp;
        win_chance = winchance;

        var selected_prediction_html = <><div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div className="row exr-new-dark-grey" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                    <div className="col s12 m6" style={{position:'relative',zIndex:'10px'}}>
                                                    <div className="left" style={{width:"40%",maxWidth:'100px',lineHeight:'0.75'}}>
                                                        <img src={racecraft_image} style={{width:'100%',borderRadius:'5px'}} />
                                                    </div>
                                                    <div className="left" style={{marginLeft:'0px',width:"47%",maxWidth:'200px',fontSize:'16px',fontFamily:"helvetica"}}>
                                                        <div className="center center-align headline-text" style={{width:'100%',fontSize:'24px',marginTop:'10px',marginBottom:'0px'}}>
                                                            {win_chance}%
                                                        </div>
                                                        <div className="center center-align" style={{width:'100%',marginTop:'-5px'}}>
                                                            WIN CHANCE
                                                        </div>
                                                        <div className="center center-align" style={{width:'100%',fontSize:'14px'}}>
                                                            Your Selection
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m6 subheadline-text" style={{position:'relative',zIndex:'10px',fontSize:'16px',fontFamily:"helvetica",paddingLeft:'10px',paddingRight:'10px'}}>
                                                    <div className="row" style={{margin:'0px',marginTop:'15px',marginBottom:'0px'}}>
                                                        <b>RACER {racer_number}:</b> {racer_name.toUpperCase()}
                                                    </div>
                                                    <div className="row" style={{margin:'0px',fontSize:'14px',marginBottom:'0px'}}>
                                                        When we test ran this race over 100 times, this racer won {win_chance}% of the time vs all other racers.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div id="free-prediction-select-box" className="row exr-stats-light-grey predict-hover" style={{position:'relative',margin:'10px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{fontSize:"14px",marginTop:"0px",padding:"5px 10px 0px 10px"}}>
                                                    <div className="left headline-text" style={{marginLeft:'5px'}}>FREE PREDICTIONS</div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    Play our free predictions game to build your reputation, earn XP for predicting winners.
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px"}}>
                                                    <div className="col s6" style={{padding:"0px"}}>
                                                        <b>Prediction Ticket</b><br/>
                                                        Free 
                                                    </div>
                                                    <div className="col s6">
                                                        <b>Winner Rewards*</b><br/>
                                                        {pred_xp} XP
                                                    </div>
                                                </div>
                                                <div className="col s12" style={{marginTop:'15px',padding:"0px 15px",display:"none"}}>
                                                    * Note: XP Amount determined by racers odds to win the race. 
                                                </div>
                                                <div className="col s12 right-align" style={{marginTop:'35px',marginBottom:'7px',padding:"0px 15px"}}>
                                                    <span className="left" style={{marginTop:'0px',lineHeight:'1'}}>
                                                        <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" height="20px"/>
                                                    </span>
                                                    <div id="freepredcheckbutton" className="round-border-button right" style={{fontSize:'14px',marginTop:'-1px'}} 
                                                            title="Place Free Race Prediction" 
                                                            data-raceid={race_id} 
                                                            data-pracerid={pred_racer_id}
                                                            data-participantid={pred_participant_id}
                                                            data-predfinish={pred_finish} 
                                                            data-freepred={free_pred_amount}
                                                            onClick={(e)=>makeRacePrediction(e)}>
                                                        PREDICT
                                                    </div>
                                                    <div id="freepredcheckloading" className="preloader-wrapper small active right splash-modal-loader" 
                                                            style={{marginTop:'-5px',height:"25px",width:"25px",marginRight:"10px"}}>
                                                        <div className="spinner-layer spinner-red-only">
                                                            <div className="circle-clipper left">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="gap-patch">
                                                                <div className="circle"></div>
                                                            </div>
                                                            <div className="circle-clipper right">
                                                                <div className="circle"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12" style={{padding:"0px 5px",fontFamily:'helvetica'}}>
                                            <div className="row" style={{position:'relative',margin:'0px 0px 0px 0px',padding:'10px 0px',borderRadius:'5px'}}>
                                                <div className="col s12" style={{marginTop:'5px',padding:"0px 15px",fontSize:'12px'}}>
                                                    * Note: XP rewards determined by the number of racers and the odds to win the race. 
                                                </div>
                                            </div>
                                        </div></>;

        if(window.innerWidth < 800) {
            $("#sbpredictracerbox").removeClass("page-modal-large").addClass("page-modal-full");
        } else {
            $("#sbpredictracerbox").removeClass("page-modal-full").addClass("page-modal-large");
        }

        setSelectedPredictionHTML(selected_prediction_html);
        var predictbox = document.getElementById('sbpredictracerbox');
        var predict_instance = window.M.Modal.getInstance(predictbox);
        predict_instance.open();

        
    }
    
    function selectPredictionsPage() {
        let pagename = $("#predictionPageSelection").val();
        togglePredictionsPage(pagename);
    }

    function togglePredictionsPage(pagename) {
        // $(".prednav").each(function(){
        //     $(this).removeClass("navtogglefilled");
        // });

        // $("#predictions_"+pagename+"_button").addClass("navtogglefilled");
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");
        var trainingbox = '';
        var sectiontxt = <></>;
        if(pagename == 'past') {
            sectiontxt = <></>;
        } else if(pagename == 'leagues') {
            sectiontxt = <></>;
        } else {
            sectiontxt = <></>;
        }

        setCurrentPageHTML(sectiontxt);

        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
        },5);
    }


    function showPredictionsInfobox() {
        var infobox = document.getElementById('training_box_predictions');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            <div className="row page-width-wide page-clean-body">
                <div id="workshop-mobile-nav" className="col s12 border-section-full exr-new-dark-grey show-on-med-and-down hide-on-large-only" 
                        style={{position:'relative',padding:"5px",paddingBottom:'0px',marginBottom:'0px'}}>
                    <div className="left headline-text white-text" style={{fontSize:'16px',marginTop:"15px",marginBottom:"10px",marginLeft:'30px'}}>
                        &nbsp;THE SPORTSBOOK
                    </div>
                    <div className="right" style={{fontSize:'16px',marginTop:"10px",marginBottom:"10px",marginRight:'30px'}}>
                        <select onChange={selectPredictionsPage} id="predictionPageSelection" className="input-field light-grey-bubble-input"
                            style={{fontSize:'20px',fontFamily:'helvetica',padding:'0px'}}>
                            <option value="upcoming">Upcoming Races</option>
                            <option value="yourprediction">Your Predictions</option>
                            <option value="allraces">All Races</option>
                        </select>
                    </div>
                </div>
                <div id="leftrailnav" className="col s12 l2 border-section-full exr-new-dark-grey hide-on-med-and-down" style={{height:'100vh',position:'relative',padding:"5px"}}>
                    <div style={{width:'100%'}}>
                        <div className="headline-text section-title-text center-align" style={{fontSize:'14px',marginTop:"20px",marginBottom:"10px"}}>
                            &nbsp;PREDICTION ZONE
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="upcomingtoggle"
                                    onClick={()=> togglePredictionsPage('upcoming')}> 
                                <span className="left side-nav-button-text">Open Predictions</span>
                                <span id="racetrack-new-races-text" className="top-nav-header-item-badge exr-red-bg white-text" style={{marginTop:'10px',display:'none'}}>
                                    14
                                </span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="yourpredictiontoggle"
                                    onClick={()=> togglePredictionsPage('yourprediction')}> 
                                <span className="left side-nav-button-text">Your Predictions</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        <div className="side-nav-button-section">
                            <button className="btn-flat transparent waves-effect side-nav-button grey-fill-link" id="allracestoggle"
                                    onClick={()=> togglePredictionsPage('allraces')}> 
                                <span className="left side-nav-button-text">All Races</span>
                                <span className="right side-nav-button-bubble">
                                    <div className="bubble-button"></div>
                                </span>
                            </button>
                        </div>
                        
                    </div>
                </div>
                <div id="righsidecontent" className="col s12 l10" style={{position:'relative', minHeight:'100vh', margin:"0px",padding:"0px"}}>
                    <div className="row border-section-full exr-new-dark-grey white-text" style={{minHeight:'100vh',position:'relative',width:"99.5%",marginLeft:"0.5%",padding:"25px"}}>
                        
                        <div className="col s12" style={{margin:"0px",padding:"0px"}}>
                            {/* <div id="unofficial-race-title" className="row headline-text" style={{margin:"0px",fontSize:"20px",marginTop:"0px",marginBottom:"0px"}}>
                                OPEN PREDICTIONS
                            </div>
                            <div id="unofficial-race-divider" className="row" style={{margin:"0px",marginBottom:'10px'}}>
                                <div className="divider-thin"></div>
                            </div>
                            <div className="row" style={{margin:"0px",marginTop:'5px'}}>
                                {current_page_html}
                                
                                <BetSlipTwo />
                                <BetSlipTwo />
                                <BetSlipTwo />
                                <BetSlipTwo />
                            </div>  */}
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="training_box_predictions" className="modal page-modal page-modal-large">
                <div className="modal-content">
                    <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                        <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                            X
                        </div>
                        <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                            <div className="left headline-text" style={{marginLeft:'5px'}}>
                            PREDICTIONS
                            </div>
                        </div>
                        <div className="col s12" style={{padding:"0px 5px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                                Welcome to the EXR Predictions! Here you can find the races to predict on, your predictions and past races. 
                                <br/><br/><a className="exr-light-green-text">Training Rewards: </a> 
                                Your RPM and XP are your tickets to win rewards in training. 
                                The more RPM and XP you collect by the end of training the higher likelihood you have to win our training rewards game.
                                <br/><br/><a className="exr-light-green-text">Starblazer Badge: </a> 
                                The racers with the most RPM collected at the close of the EXR Training phase will earn the badge of Starblazer and have access to exclusive prizes. 
                                <br/><br/><a className="exr-light-green-text">Leaderboard Tiers: </a> 
                                Where you finish on the leaderboard may or may not determine which <a style={{color:'silver'}}>[REDACTED]</a> you are placed in once the EXR Training Phase is over and <a style={{color:'silver'}}>[REDACTED]</a> starts. 
                                <br/><br/><a className="exr-light-green-text">Prophet Rewards: </a> 
                                The Prophet status is one to be admired. Collect XP for correctly predicting races which give you a higher chance to get Prophet rewards. Top Prophets will earn badges for bragging rights.
                                <br/><br/>
                            </div>
                    </div>
                </div>
            </div>
        </div>);
};
