import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import "../../../../splash_event.css";
import { setUserSignOn, setLoginWallet } from "../../../../../../../redux/Account/actions";
import {validateEmailText, sendEventEmailCode, checkEventEmailCode, addSpectatorInfo, addWalletInfo, connectTalisman, connectMetamask} from "../../../../Utilities"
import {getEventUserInfo, addEventUserAction, saveRacerHandle} from "../../../../Utilities"
import {axiosInstance } from "../../../../../../../utils";
import {BaseLeagueWeeklyReveal} from "../WeeklyReveal";
import {BaseLeagueWeeklyRace} from "../WeeklyRace";
import {BaseLeagueLeaderboards} from "../Leaderboards";
import {BaseLeagueWeeklyPredictions} from "../WeeklyPredictions";
import {BaseLeagueWeeklyRacedayDetails} from "../WeeklyRacedayDetails";
import {BaseLeagueWeeklyStackDetails} from "../WeeklyStackDetails";
//import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";


import {TrackLoader} from "../../../../../../../webgl/race/utils/trackLoader.js"
import { Track } from "../../../../../../../webgl/race/track/track.js";
import { TrackMap } from "../../../../../../../webgl/race/track/map/trackMap.js";
import { Minimap } from "../../../../../../../webgl/race/minimap/minimap.js";

import $ from "jquery";

let current_user_id = 0; 
let current_wallet_address = '';
let current_racer_status = 'predictor';
let current_season_id = 2;
let current_week_num = 4;
let current_raceday_id = 0;
let current_raceday_number = 0;
let current_raceday_track_id = 0;
let signup_racer = 'spectator';
let start_handle = '';
let confirm_handle = 0;
let confirm_pass = 0;
let confirm_disclaimer = 0;
let confirm_reveal = 0;
let total_pilots_sync = 0;
let total_racecrafts_sync = 0;
let total_boosters_sync = 0;
let pilots_checked = 0;
let racecrafts_checked = 0;
let boosters_checked = 0;
let season_raceday_list = [];

export const BaseLeagueSplash = () => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { chainId, account } = useWeb3React();

    const [pageSet, setPage] = useState(false);
    const [provider, setProvider] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState("");
    const [current_wallet, setCurrentWallet] = useState("");
    const [racer_metamask, setRacerMetamask] = useState('');
    const [racer_talisman, setRacerTalisman] = useState('');
    const [registration_level, setRegistrationlevel] = useState('REGISTRATION COMPLETE');
    const [racer_name, setRacerName] = useState('user123');
    const [race_number, setRaceNumber] = useState('1');
    const [swapped_raceday_number,setSwappedRacedayNumber] = useState('1');
    const [raceday_number, setRacedayNumber] = useState('1');
    const [racer_status,setRacerStatus] = useState('predictor');
    const [racer_action_status,setRacerActionStatus] = useState('predict');

    const [revealWeeklyStackHTML,setRevealWeeklyStackHTML] = useState('');
    const [weeklyRaceHTML,setWeeklyRaceHTML] = useState('');
    const [weeklyPredictionsHTML,setWeeklyPredictionsHTML] = useState('');
    const [weeklyRacedayDetailsHTML, setWeeklyRacedayDetailsHTML] = useState('');
    const [weeklyStackDetailsHTML, setWeeklyStackDetailsHTML] = useState('');
    const [leaderboardsHTML,setLeaderboardsHTML] = useState('');

    /* EXR Event ID */
    // var exreventid = 5;
    // let league_permalink_id = 'base-racing-league'
    var exreventid = 3;
    let league_permalink_id = 'pink-racing-league'
    let signup_user_id = 0;

    let bg_image_side = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
    let bg_image = 'https://media.exiledracers.com/assets/game/builder/Black_Ship_Splash_Background_1704920753949.png';

    let bg_image_ship = 'https://media.exiledracers.com/assets/game/builder/Ship_Bottom_Background_1704950678438.png';

    let bg_primary_splash = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Primary_Splash_Background_1709765585438.png';
    let bg_image_left = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Left_1709765609425.png';
    let bg_image_right = 'https://media.exiledracers.com/assets/game/builder/Base_Racing_Background_Right_1709765624764.png';
    
    let logo_image = 'https://media.exiledracers.com/global/exr_logo_white_stacked.png';
    let logomark_image = 'https://media.exiledracers.com/assets/game/builder/EXR_Logomark_White_1693422646590.png';
    let polka_logo = 'https://media.exiledracers.com/assets/game/builder/Polkadot_White_Icon_1693419269508.png';
    let ztg_logo = 'https://media.exiledracers.com/assets/game/builder/Zeitgeist_White_Icon_1693419306813.png';
    let moonbeam_logo = 'https://media.exiledracers.com/assets/game/builder/Moonbeam_White_Icon_1693419281943.png';
    let moonbeam_logo_full = 'https://media.exiledracers.com/assets/game/builder/Moonbeam_Logo_White_1704934123621.png';
    let exr_wordmark = 'https://media.exiledracers.com/assets/game/builder/EXR_Wordmark_Long_1693420169042.png';
    let pink_full_logo = 'https://media.exiledracers.com/assets/game/builder/Pink_Logo_Icon_1704949451078.png';
    
    let base_full_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Base_Wordmark_Logo_Blue_1709766845568.png';
    let base_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Base_Icon_Logo_Blue_1709766897123.png';
    let base_network_logo = 'https://media.exiledracers.com/assets/game/builder/Base_Network_Logo_1709767498507.png';
    let base_full_logo_white = 'https://media.exiledracers.com/assets/game/builder/Base_Wordmark_Logo_White_1709766877696.png';
    let base_logo_white = 'https://media.exiledracers.com/assets/game/builder/Base_Icon_Logo_White_1709766912123.png';

    let axelar_full_logo_white = 'https://media.exiledracers.com/assets/game/builder/Axelar_Wordmark_Logo_White_1709767280477.png';
    let axelar_full_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Axelar_Wordmark_Logo_Blue_1709767264248.png';
    let axelar_logo_white = 'https://media.exiledracers.com/assets/game/builder/Axelar_Icon_Logo_White_1709767305636.png';
    let axelar_logo_blue = 'https://media.exiledracers.com/assets/game/builder/Axelar_Icon_Logo_Blue_1709767292192.png';

    useEffect(() => {
        if((userID > 0)&&(userID !== undefined)) {
            current_user_id = userID;
            signup_user_id = userID;
            current_wallet_address = accountAddress; 
        }
    }, []);

    

    

    async function submitEmailAddress() {
        var raceremail = document.getElementById("racerEmailAddress").value.trim();

        var addData = new Object();
        addData.specEmail = encodeURIComponent(raceremail);

        if(raceremail.length < 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else {
            $("#email-send-button").hide();
            $("#email-sent-loading").show();
            if(userID > 0) {
                showEmailLoggedIn();
            } else {
                let email_send = await sendEventEmailCode(raceremail);
                if(email_send['status'] == 'email_sent') {
                    setEmail(raceremail);
                    showEmailSendSent();
                } else {
                    showEmailSendReset();
                    alert('Something Went Wrong. Update and Try Again.');
                }
            }
        }
    }

    async function submitEmailCode() {
        var raceremail = document.getElementById("racerEmailAddress").value.trim();
        var racercode = document.getElementById("racerEmailCode").value.trim();
        
        var addData = new Object();
        addData.specEmail = encodeURIComponent(raceremail);
        addData.specCode = encodeURIComponent(racercode);
        addData.specEvent = encodeURIComponent(exreventid);

        if(raceremail.length <= 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else if (racercode.length != 6) {
            alert('Add a Valid Email Code');
        } else {
            $("#code-send-button").hide();
            $("#code-confirmed-loading").show();
            let email_code_check = await checkEventEmailCode(raceremail,racercode,exreventid);
            if(email_code_check['status'] == 'email_code_expired') {
                showEmailCodeReset();
                alert('Your Confirmation Code Expired. Register Again To Get a New Code.');
            } else if(email_code_check['status'] == 'email_code_wrong') {
                showEmailCodeReset();
                alert('Invalid Code. Check Your Code and Enter it Again');
            } else if (email_code_check['status'] == 'confirmed_waitroom') {
                // New User - Add to Users Table
                addNewWaitroomUser();
                setRegistrationlevel('REGISTERED TO PREDICT');

            } else if (email_code_check['status'] == 'confirmed_existing') {
                // Existing User - Approve Code
                let userid = email_code_check['login_info']['userid'];
                let racerid = email_code_check['login_info']['racerid'];
                let spectatorid = email_code_check['login_info']['spectatorid'];
                let logintime = email_code_check['login_info']['logintime'];
                let logintoken = email_code_check['login_info']['logintoken'];
                let walletid = email_code_check['login_info']['walletid'];

                // Login to Site
                current_user_id = userid;
                signup_user_id = userid;
                dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));

                if(walletid != null) {
                    if(walletid.length > 10) {
                        // Existing User - With Wallet
                        current_wallet_address = walletid;
                        var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-15);
                        $("#racerWalletConnected").val(obf_wallet);
                            
                        dispatch(setLoginWallet(current_wallet_address));
                        setCurrentWallet(current_wallet_address);
                        showWalletSaved();
                    } else {
                        // Existing User - No Wallet - Prompt To Add
                        showEmailCodeApproved();
                        setRegistrationlevel('REGISTERED TO PREDICT');
                    }
                } else {
                    // Existing User - No Wallet - Prompt To Add
                    showEmailCodeApproved();
                    setRegistrationlevel('REGISTERED TO PREDICT');
                }
                
            } else {
                showEmailCodeReset();
                alert('Whoops! Something Went Wrong. Update and Try Again');
            }
        }
    }

    async function addNewWaitroomUser() {
        var raceremail = document.getElementById("racerEmailAddress").value.trim();
        var racerhandle = '';

        let new_user_info = await addSpectatorInfo(raceremail,racerhandle,exreventid);

        if (new_user_info['status'] == 'confirmed') {
            // New User Created & Approved Code
            let userid = new_user_info['login_info']['userid'];
            let racerid = new_user_info['login_info']['racerid'];
            let spectatorid = new_user_info['login_info']['spectatorid'];
            let logintime = new_user_info['login_info']['logintime'];
            let logintoken = new_user_info['login_info']['logintoken'];

            // Login to Site
            current_user_id = userid;
            signup_user_id = userid;
            dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));

            // New User - Approve Code
            showEmailCodeApproved();
        } else {
            showEmailCodeReset();
            alert('Whoops! Something Went Wrong. Update and Try Again');
        }
    }

    async function submitWalletSaved() {
        var raceremail = document.getElementById("racerEmailAddress").value.trim();
        if(current_wallet_address.length < 5) {
            alert('Connect A Wallet');
        } else if(raceremail.length < 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else if(current_user_id == 0) {
            alert('Signup or Login To Update');
        } else {
            $("#wallet-save-button").hide();
            $("#wallet-save-loading").show();
            let wallet_info = await addWalletInfo(raceremail,current_user_id,current_wallet_address,signup_racer);

            if(wallet_info['status'] == 'duplicate_wallet') {
                // $("#wallet-save-loading").hide();
                // $("#wallet-save-button").show();
                // alert('This Wallet Is Already Registered. Connect a Different Wallet Later or Login to your Account.');
                showWalletSaved();
            } else if(wallet_info['status'] == 'no_account') {
                $("#wallet-save-loading").hide();
                $("#wallet-save-button").show();
                alert('This Email Account is not Registered. Go Back and Complete the Signup Process.');
            } else if(wallet_info['status'] == 'confirmed') {
                let userid = wallet_info['login_info']['userid'];
                let racerid = wallet_info['login_info']['racerid'];
                let spectatorid = wallet_info['login_info']['spectatorid'];
                let logintime = wallet_info['login_info']['logintime'];
                let logintoken = wallet_info['login_info']['logintoke'];

                dispatch(setUserSignOn(userid,racerid,spectatorid,logintoken,logintime));
                dispatch(setLoginWallet(current_wallet_address));

                showWalletSaved();
            } else {
                $("#wallet-save-loading").hide();
                $("#wallet-save-button").show();
                alert('Whoops! Something Went Wrong. Update and Try Again');
            }
        }
    }

    async function connectWalletProvider(wallet_type) {
        let wallet_connection;

        // if(wallet_type == 'walletconnect') {
        //     wallet_connection = await getWalletConnectModal();
        // } else 
        
        if (wallet_type == 'talisman') {
            wallet_connection = await connectTalisman();
        } else {
            wallet_connection = await connectMetamask();
        }
        if(wallet_connection['status'] == 'not_installed') {
            alert('Install '+wallet_type.charAt(0).toUpperCase()+wallet_type.slice(1)+' on this Browser');
        } else if(wallet_connection['status'] == 'connected') {
            current_wallet_address = wallet_connection['wallet_address'];
            let racer_wallet_type = wallet_connection['wallet_type'];
            let racer_inventory = wallet_connection['inventory_check'];
            
            if ((racer_inventory['total_pilots'] > 0)&&(racer_inventory['total_racecrafts_sync'] > 0)&&(racer_inventory['total_boosters_sync'] > 0)) {
                signup_racer = 'racer';
            } else {
                signup_racer = 'spectator';
            }

            setRacerMetamask(current_wallet_address);
            setCurrentWallet(current_wallet_address);

            if (racer_wallet_type == 'talisman') {
                setRacerTalisman(current_wallet_address);
            } else {
                setRacerMetamask(current_wallet_address);
            }

            var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-15);
            $("#racerWalletConnected").val(obf_wallet);
            showConnectedWalletForm();
        } else {
            alert('Install '+wallet_type.charAt(0).toUpperCase()+wallet_type.slice(1)+' on this Browser');
        }
    }

    async function loadEventUserInfo() {
        if(userID) {
            current_user_id = userID;
        }
        let user_status = await getEventUserInfo(current_user_id,exreventid);
        
        if(user_status['status'] == 'confirmed') {
            let user_racer_name = user_status['event_info']['racer_name'];
            let user_event_status = user_status['event_info']['race_event_status'];

            start_handle = user_racer_name;
            setRacerName(user_racer_name);
            setRacerStatus(user_event_status);
            current_racer_status = user_event_status;

            loadLeagueUserInfo();

            $("#leagueRacerHandle").val(start_handle);
            $("#leagueRacerHandle2").val(start_handle);
            if (user_event_status == 'racer') {
                setRacerActionStatus('race');
                $("#welcome-reveal-racer").show();
                $("#welcome-reveal-predictor").hide();
            } else {
                setRacerActionStatus('predict');
                $("#welcome-reveal-racer").hide();
                $("#welcome-reveal-predictor").show();
                $("#race-weekly-inner-nav").hide();
            }

            confirm_handle = user_status['event_info']['racer_name_viewed'];
            confirm_pass = user_status['event_info']['access_pass_viewed'];
            confirm_disclaimer = user_status['event_info']['disclaimer_viewed'];
            confirm_reveal = user_status['event_info']['reveal_viewed'];

            if((confirm_handle == 0)||(confirm_pass == 0)) {
                $("#event-landing-page-2b").show();
                $("html, body").animate({ scrollTop: $("#event-landing-page-2b").offset().top }, 1000);
            } else if(confirm_disclaimer == 0) {
                $("#event-landing-page-3b").show();
                $("html, body").animate({ scrollTop: $("#event-landing-page-3b").offset().top }, 1000);
            } else if((confirm_reveal < current_week_num)&&(user_event_status == 'racer')) {
                $("#event-landing-page-4b").show();
                $("html, body").animate({ scrollTop: $("#event-landing-page-4b").offset().top }, 1000);
            } else if((confirm_reveal >= current_week_num)||(user_event_status == 'predictor')) {
                viewWeeklyStackRaces();
                if(user_event_status == 'predictor') {
                    toggleRaceSections('predict_race');
                }
            }

            setTimeout(function(){
                startToggles(user_event_status);
            },500);
            
        }
    }

    function loadLeagueUserInfo() {
        if(userID) {
            current_user_id = userID;
            current_racer_status = 'racer';
        }
        var addData = new Object();
        addData.leaguePermalink = encodeURIComponent(league_permalink_id);
        addData.specID = encodeURIComponent(current_user_id);

        axiosInstance.post('league/season_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var league_info = result.data;
                let current_league_id = league_info[0]['league_id'];
                current_season_id = league_info[0]['league_season_id'];
                let current_league_details = league_info;
                let current_season_image = league_info[0]['season_header_photo'];
                let current_season_name = league_info[0]['season_custom_name'];
                let current_season_number = league_info[0]['season_number'];
                let league_name = league_info[0]['league_name'];
                let season_racedays = league_info[0]['season_racedays'];
                season_raceday_list = season_racedays;
                if(season_racedays.length > 0) {
                    var active_raceday_id = 0;
                    var active_raceday_number = 0;
                    var last_completed_raceday_id = 0;
                    var last_completed_raceday_number = 0;
                    current_raceday_id = 0;
                    current_raceday_number = 0;
                    for(var z = 0; z < season_racedays.length;z++) {
                        var next_raceday_id = season_racedays[z]['raceday_id'];
                        var next_raceday_number = season_racedays[z]['raceday_number'];
                        var next_race_completed = season_racedays[z]['race_completed'];
                        var next_race_next = season_racedays[z]['race_next'];
                        var next_race_ondeck = season_racedays[z]['race_ondeck'];

                        // If Race Is Next or Ondeck With Races Available Get Smallest Race Number
                        if ((next_race_next == 1)||((next_race_ondeck == 1)&&(season_racedays[z]['raceday_races'].length > 0))) {
                            if((active_raceday_number == 0)||(active_raceday_number > next_raceday_number)) {
                                active_raceday_id = next_raceday_id;
                                active_raceday_number = next_raceday_number;
                            }
                        }

                        // Get Last Completed Week
                        if(next_race_completed == 1) {
                            if(last_completed_raceday_number < next_raceday_number) {
                                last_completed_raceday_id = next_raceday_id;
                                last_completed_raceday_number = next_raceday_number;
                            }
                        }
                    }

                    if (active_raceday_id > 0) {
                        current_week_num = active_raceday_number;
                        current_raceday_id = active_raceday_id;
                        current_raceday_number = active_raceday_number;
                    } else if (last_completed_raceday_id > 0) {
                        current_week_num = last_completed_raceday_number;
                        current_raceday_id = last_completed_raceday_id;
                        current_raceday_number = last_completed_raceday_number;
                    } else {
                        current_week_num = season_racedays[0]['raceday_number'];
                        current_raceday_id = season_racedays[0]['raceday_id'];
                        current_raceday_number = season_racedays[0]['raceday_number'];
                    }
                    setRaceNumber(current_week_num);
                    showWeeklyRaceSections(current_raceday_number); 
                } 
                loadLeagueLeaderboardLists(season_racedays);

            } else {
                // window.location.href= '/#/leagues';
            }
        }).catch(error => {
            console.log(error);
            // window.location.href= '/#/leagues';
        });
    }

    function showWeeklyRaceSections(raceweeknum) {
        var show_raceday_id = 0;
        var show_race_id = 0;
        var show_race_unique_id = 0;
        var show_track_id = 0;
        var raceday_status = 'details_only';
        var raceday_details = {};
        for(var z = 0; z < season_raceday_list.length;z++) {
            var next_raceday_id = season_raceday_list[z]['raceday_id'];
            var next_raceday_number = season_raceday_list[z]['raceday_number'];
            if(next_raceday_number == raceweeknum) {
                show_raceday_id = next_raceday_id;
                show_track_id = season_raceday_list[z]['race_track_id'];
                raceday_details = season_raceday_list[z];
                if(season_raceday_list[z]['raceday_races'].length > 0) {
                    show_race_id = season_raceday_list[z]['raceday_races'][0]['race_id'];
                    show_race_unique_id = season_raceday_list[z]['raceday_races'][0]['race_unique_id'];
                }

                if(season_raceday_list[z]['results_opened'] == 1) {
                    raceday_status = 'results_open';
                } else if(season_raceday_list[z]['prediction_opened'] == 1) {
                    raceday_status = 'predictions_open';
                } else if((season_raceday_list[z]['entry_open'] == 1)&&(season_raceday_list[z]['raceday_races'].length > 0)) {
                    raceday_status = 'entry_open';
                } else if((season_raceday_list[z]['prediction_opened'] == 0)&&(season_raceday_list[z]['entry_open'] == 0)&&(season_raceday_list[z]['raceday_races'].length > 0)) {
                    raceday_status = 'predictions_opening';
                } 
            }
        }
        current_raceday_track_id = show_track_id;

        if(raceweeknum == 1) {
            $("#swapweekleft").hide();
        } else {
            $("#swapweekleft").show();
        }

        if(season_raceday_list.length > 0) {
            if(raceweeknum == season_raceday_list[season_raceday_list.length-1]['raceday_number']) {
                $("#swapweekright").hide();
            } else {
                $("#swapweekright").show();
            }
        } else {
            $("#swapweekright").hide();
        }
        
        setWeeklyRacedayDetailsHTML(<></>);
        setWeeklyPredictionsHTML(<></>);
        setRevealWeeklyStackHTML(<></>);
        setWeeklyRaceHTML(<></>);
        setWeeklyStackDetailsHTML(<></>);

        setWeeklyRacedayDetailsHTML(<BaseLeagueWeeklyRacedayDetails season_id={current_season_id} raceday_id={show_raceday_id} raceday_details={raceday_details} raceStatus={raceday_status} />);
        setWeeklyPredictionsHTML(<BaseLeagueWeeklyPredictions season_id={current_season_id} raceday_id={show_raceday_id}  raceStatus={raceday_status} />);
        setSwappedRacedayNumber(raceweeknum);
        setRevealWeeklyStackHTML(<BaseLeagueWeeklyReveal race_id={show_race_id} raceday_id={show_raceday_id} />);
        setWeeklyRaceHTML(<BaseLeagueWeeklyRace race_id={show_race_id} raceUniqueID={show_race_unique_id} raceTrackID={show_track_id} raceday_id={show_raceday_id} raceStatus={raceday_status} />);
        setWeeklyStackDetailsHTML(<BaseLeagueWeeklyStackDetails race_id={show_race_id} raceUniqueID={show_race_unique_id} raceTrackID={show_track_id} raceday_id={show_raceday_id} raceStatus={raceday_status} />);

        $("#race_results_link").hide();
        $("#predict_race_link").hide();
        $("#stack_entry_link").hide();
        $("#race_lineup_link").hide();
        $("#stack_details_link").hide();
        $("#race_details_link").show();

        setTimeout(function() {
            if(raceday_status == 'results_open') {
                $("#predict_race_link").show();
                $("#stack_entry_link").hide();
                if(current_racer_status == 'racer') {
                    $("#race_results_link").show();
                    $("#stack_details_link").show();
                    toggleRaceSections('race_results');
                } else {
                    toggleRaceSections('predict_race');
                }
            } else if(raceday_status == 'predictions_open') {
                $("#predict_race_link").show();
                if(current_racer_status == 'racer') {
                    $("#stack_entry_link").show();
                    $("#race_lineup_link").show();
                    $("#stack_details_link").show();
                }
                toggleRaceSections('predict_race');
            } else if((raceday_status == 'entry_open')||(raceday_status == 'predictions_opening')) {
                if(current_racer_status == 'racer') {
                    $("#stack_entry_link").show();
                    $("#race_lineup_link").show();
                    $("#stack_details_link").show();
                    toggleRaceSections('stack_entry');
                } else {
                    toggleRaceSections('race_details');
                }
            } else {
                toggleRaceSections('race_details');
            }
        },500);
    }

    function switchRaceWeek(nextdirection) {
        if(nextdirection == 'back') {
            let next_weeknum = swapped_raceday_number - 1;
            showWeeklyRaceSections(next_weeknum);
        } else {
            let next_weeknum = swapped_raceday_number + 1;
            showWeeklyRaceSections(next_weeknum);
        }
    }

    function startToggles(rstatus) {
        if(rstatus == 'predictor') {
            toggleRaceSections('predict_race');
        } else {
            toggleRaceSections('race_results');
        }
    }

    function loadLeagueLeaderboardLists(racedays) {
        setLeaderboardsHTML(<BaseLeagueLeaderboards racedays={racedays} group_count={1} display_type="grid" />);
    }

    async function confirmRacerHandle(usertype) {
        $("#handle-confirm-button").hide();
        $("#handle-confirm-loading").show();
        $("#handle-confirm-button2").hide();
        $("#handle-confirm-loading2").show();
        var racerhandle = document.getElementById("leagueRacerHandle").value;
        if(usertype == 'predictor') {
            racerhandle = document.getElementById("leagueRacerHandle2").value;
        }

        var moveforward = 0;
        if(start_handle != racerhandle) {
            let handle_response = await saveRacerHandle(userID,start_handle,racerhandle);
            if(handle_response.indexOf('error') >= 0) {
                moveforward = 0;
            } else {
                moveforward = 1;
            }
        } else {
            moveforward = 1;
        }
        
        if(moveforward == 1) {
            let actiontype = 'racer_name';
            let actionid = '';
            let action_response = await addEventUserAction(userID,exreventid,actiontype,actionid);
            confirmRacerPass();

            setTimeout(function() {
                $("#handle-confirm-button").hide();
                $("#handle-confirm-loading").hide();
                $("#handle-confirm-button2").hide();
                $("#handle-confirm-loading2").hide();
                $("#event-landing-page-3b").show();
                $("html, body").animate({ scrollTop: $("#event-landing-page-3b").offset().top }, 1000);
            },3000);
            setTimeout(function() {
                $("#event-landing-page-2b").hide();
            },5000);
        } else {
            $("#handle-confirm-button").show();
            $("#handle-confirm-loading").hide();
            $("#handle-confirm-button2").show();
            $("#handle-confirm-loading2").hide();
        } 
    }

    async function confirmRacerPass() {
        let actiontype = 'access_pass';
        let actionid = '';
        let action_response = await addEventUserAction(userID,exreventid,actiontype,actionid);
    }

    async function confirmLeagueDisclaimer() {
        $("#disclaimer-confirm-button").hide();
        $("#disclaimer-confirm-loading").show();
        let actiontype = 'disclaimer';
        let actionid = '';
        let action_response = await addEventUserAction(userID,exreventid,actiontype,actionid);

        setTimeout(function() {
            $("#disclaimer-confirm-button").hide();
            $("#disclaimer-confirm-loading").hide();
            if(racer_status == 'predictor') {
                viewWeeklyStackRaces();
                toggleRaceSections('predict_race');
            } else {
                $("#event-landing-page-4b").show();
                $("html, body").animate({ scrollTop: $("#event-landing-page-4b").offset().top }, 1000);
            }
            
        },3000);
        setTimeout(function() {
            $("#event-landing-page-3b").hide();
        },5000);
    }

    async function revealWeeklyStack(weeknum) {
        $("#reveal-confirm-button").hide();
        $("#reveal-confirm-loading").show();
        let actiontype = 'reveal';
        let actionid = current_raceday_number;
        let action_response = await addEventUserAction(userID,exreventid,actiontype,actionid);

        setTimeout(function() {
            $("#reveal-confirm-button").hide();
            $("#reveal-confirm-loading").hide();
            $("#weekly-stack-reveal-button").fadeOut();
        },3000);
        setTimeout(function() {
            $("#weekly-stack-reveal-section").fadeIn(2000);
        },5000);         
    }

    async function viewWeeklyStackRaces() {
        // $(".howtoplay").each(function(){
        //     $(this).show();
        // });
        $("#event-landing-page-7").show();
        $("#event-landing-page-9").show();
        $("#event-landing-page-5b").show();
        $("#event-landing-page-6b").show();
        $("#event-landing-page-66b").show();
        $("html, body").animate({ scrollTop: $("#event-landing-page-5b").offset().top }, 1000);      
        
    }

    function scrollToLeaderboards() {
        $("html, body").animate({ scrollTop: $("#event-landing-page-6b").offset().top }, 1000);      
    }

    function toggleRaceSections(sectionname) {
        $("#stack_entry_section").hide();
        $("#stack_details_section").hide();
        $("#race_details_section").hide();
        $("#race_lineup_section").hide();
        $("#race_results_section").hide();
        $("#predict_race_section").hide();
        $("#"+sectionname+"_section").show();

        $("#stack_entry_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#stack_details_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#race_details_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#race_lineup_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#predict_race_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#race_results_link").removeClass("white-base-blue-link-active").addClass("white-base-blue-link");
        $("#"+sectionname+"_link").removeClass("white-base-blue-link").addClass("white-base-blue-link-active");

        if(sectionname == 'race_details') {
            setTimeout(function() {
                loadRacedayTrack(current_raceday_track_id);
            },1000);
        }
    }

    function viewRacerLinueupStack(e) {
        e.preventDefault();
        var tmp_racer_id = e.target.id.replace("view_stack_button_","");
        if($("#lineup_stack_"+tmp_racer_id).is(":visible")) {
            $("#lineup_stack_"+tmp_racer_id).slideUp();
            $("#view_stack_button_"+tmp_racer_id).html('View Stack');
        } else {
            $("#lineup_stack_"+tmp_racer_id).slideDown();
            $("#view_stack_button_"+tmp_racer_id).html('Hide Stack');
        }
    }

    async function loadRacedayTrack(race_track_id) {
        let track_viewer_container = window.document.getElementById("track-viewer-league");
        if(track_viewer_container) {
            let track_data = await new Track(race_track_id,'id');
            new TrackLoader(track_data,
                () => {
                    if(track_viewer_container) {
                        $("#track-viewer-league").html('');
                        let track_minimap = new Minimap(new TrackMap(track_data),track_viewer_container).populate(null);
                    }
                }
            );
        }
    }
    
    function showEmailSendSent() {
        setTimeout(function(){
            $("#register-email").hide();

            $("#email-sent-text").fadeIn();
            $("#register-email-code").fadeIn();
        },3000);
    }

    function showEmailSendReset() {
        $("#email-send-button").show();
        $("#email-sent-loading").hide();
        $("#email-sent-button").hide();
    }

    function showEmailLoggedIn() {
        loadEventUserInfo();
        setTimeout(function(){
            $("#email-send-button").hide();
            $("#email-sent-loading").hide();
        },3000);
    }

    function showEmailCodeApproved() {
        setTimeout(function(){
            $("#register-email-code").hide();
            $("#email-sent-text").hide();

            $("#email-confirmed-text").fadeIn();
            $("#register-wallet").fadeIn();
        },3000);
    }

    function showConnectedWalletForm() {
        $("#connected-wallet").fadeIn();
        $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    }

    function showWalletSaved() {

        setTimeout(function(){
            $("#register-email-code").hide();
            $("#email-sent-text").hide();
            $("#register-wallet").hide();
            $("#connected-wallet").hide();
        
            $("#email-confirmed-text").fadeIn();
            $("#wallet-confirmed-text").fadeIn();
            $("#register-complete-text").fadeIn();

            loadEventUserInfo();
        },3000);
        
    }

    function showEmailCodeReset() {
        $("#code-send-button").show();
        $("#code-confirmed-loading").hide();
        $("#code-confirmed-button").hide();
    }

    function showRegistrationForm() {
        $("#register-button").hide();
        $("#home-value-props").hide();
        
        $("#home-value-props2").fadeIn();
        $('#register-email').fadeIn();
        $("#register-button-subheadline").hide();
    }

    if(pageSet === false) {
        setPage(true);
        // loadLeagueUserInfo();
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto',overflowY:'hidden'}}>
        <div className="row" id="main-landing-page" style={{position:'relative',width:'100%',minHeight:'100vh',marginBottom:'0px',
        background:'url('+bg_primary_splash+')',backgroundSize:'cover',backgroundPosition:'center'
            }}>
            <div className="col s12 transparent firstsection" style={{position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="col s12">
                        <div className="row " style={{marginTop:'10px'}}>
                            <div className="col s12 m10 offset-m1 white-text">
                                <div className="headline-text event-register-large-headline-mid white-text" style={{fontSize:'40px',lineHeight:'1.25'}}>
                                   BASE RACING<br/>
                                   <span className="white-text">LEAGUE</span>
                                </div>
                            </div>
                        </div>
                        <div id="home-value-props" className="row" style={{marginTop:'30px'}}>
                            <div className="col s10 offset-s1 white-text show-on-medium-and-up hide-on-small-only">
                                <div className="subheadline-text event-register-subheadline-small"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    — COMPETE IN WEEKLY RACES<br/>
                                    — CLIMB POINTS LEADERBOARD<br/>
                                    — WIN PRIZE REWARDS<br/>
                                    — BONUS HOLDERS PRIZES
                                </div> 
                            </div>
                            <div className="col s12 white-text show-on-small-only hide-on-med-and-up">
                                <div className="subheadline-text center"
                                    style={{fontSize:'16px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    — COMPETE IN WEEKLY RACES<br/>
                                    — CLIMB POINTS LEADERBOARD<br/>
                                    — WIN PRIZE REWARDS<br/>
                                    — BONUS HOLDERS PRIZES
                                </div> 
                            </div>
                        </div>
                        <div id="home-value-props2" className="row" style={{marginTop:'30px',display:'none'}}>
                            <div className="col s10 offset-s1 m7 offset-m1 l5 offset-l1 white-text">
                                <div className="left subheadline-text event-register-subheadline-small"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    PRE-REGISTER FOR THE ALLOWLIST LOTTERY. CONFIRM YOUR <span className="exr-base-blue-light-text">EMAIL</span> and
                                    CONNECT YOUR <span className="exr-base-blue-light-text">WALLET</span> TO JOIN.
                                </div> 
                            </div>
                            <div className="col s10 offset-s1 m7 offset-m1 l5 offset-l1 white-text" style={{display:'none'}}>
                                <div className="left subheadline-text event-register-subheadline-small"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    BASE RACING ENTRY IS <span className="exr-base-blue-light-text">CLOSED.</span> JOIN AS A <span className="exr-base-blue-light-text">PREDICTOR</span> OR 
                                    ACCESS YOUR <span className="exr-base-blue-light-text">ACCOUNT</span> BELOW.
                                </div> 
                            </div>
                        </div>
                        <div className="row register-sections" style={{marginTop:'0px',paddingTop:'0px',marginBottom:'0px'}}>
                            <div id="email-sent-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined grey-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left"
                                        style={{flex:1,fontSize:'18px',color:'silver',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px'}}> 
                                            <b>EMAIL CODE SENT:&nbsp;&nbsp;</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                {email}
                                            </span>
                                    </div> 
                                </div>
                            </div>
                            <div id="email-confirmed-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined exr-base-blue-light-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left exr-base-blue-light-text"
                                        style={{flex:1,fontSize:'18px',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px'}}> 
                                            <b>EMAIL CONFIRMED:&nbsp;&nbsp;</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                {email}
                                            </span>
                                    </div> 
                                </div>
                            </div>
                            <div id="wallet-confirmed-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined exr-base-blue-light-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left exr-base-blue-light-text"
                                        style={{flex:1,fontSize:'18px',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px'}}> 
                                            <b>WALLET CONFIRMED:&nbsp;&nbsp;</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                {current_wallet.slice(0,7)}...{current_wallet.slice(-5)}
                                            </span>
                                    </div> 
                                </div>
                            </div>
                            <div id="register-complete-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined exr-base-blue-light-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left exr-base-blue-light-text"
                                        style={{flex:1,fontSize:'18px',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px',width:'50%',minWidth:'270px'}}> 
                                        <div style={{width:'50%',minWidth:'270px'}}> 
                                            <b>{registration_level}</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                Follow the <a className="exr-base-blue-light-text" target="_blank" href="https://discord.gg/exiledracers">EXR Discord</a> and <a target="_blank" className="exr-base-blue-light-text" href="https://x.com/exiledracers">X</a> to
                                                stay in the loop on next steps and the start of racing.</span>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div id="register-email" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'0px'}}>
                            
                            <div id="register-email-form-title" className="col s10 offset-s1 white-text register-email-forms">
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'silver'}}>
                                    STEP 1 / 2
                                </span>
                                <span className="left headline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px'}}>
                                    ENTER EMAIL ADDRESS
                                </span>
                            </div>
                            <div id="register-email-form" className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{marginBottom:'20px'}}>
                                <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Enter Email Address to Register">
                                    <input id="racerEmailAddress" className="black-bubble-input-round" 
                                        style={{fontSize:'20px',marginLeft:'0px',margin:'0px',marginRight:'-15px'}} />
                                </span>
                                <span id="email-send-button" className="round-border-button right black white-text base-blue-background" 
                                    style={{marginTop:'20px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>submitEmailAddress()}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                        SUBMIT
                                    </span>
                                </span>
                                <span id="email-sent-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div id="register-email-code" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'0px'}}>
                            <div id="confirm-email-form-title" className="col s10 offset-s1 white-text">
                                <span className="left subheadline-text event-register-formheader" 
                                        style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'silver'}} title="Register For Exiled Racers">
                                        STEP 1 / 2
                                </span>
                                <span className="left headline-text event-register-formheader" 
                                    style={{width:'100%'}} title="Register For Exiled Racers">
                                    ENTER EMAIL CODE
                                </span>
                            </div>
                            <div id="confirm-email-form" className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{marginBottom:'20px'}}>
                                <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Enter Email Code to Confirm">
                                    <input id="racerEmailCode" className="black-bubble-input-round" 
                                        style={{width:'100%',fontSize:'20px',borderColor:'black'}} />
                                </span>
                                <span id="code-send-button" className="round-border-button right black white-text base-blue-background" 
                                    style={{marginTop:'20px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>submitEmailCode()}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                        SUBMIT
                                    </span>
                                </span>
                                <span id="code-confirmed-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div id="register-wallet" className="row register-sections" style={{display:'block',marginTop:'30px',marginBottom:'0px'}}>
                            <div className="col col s10 offset-s1 white-text">
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'silver'}} title="Register For Exiled Racers">
                                    STEP 2 / 2
                                </span>
                                <span className="left headline-text event-register-formheader" 
                                    style={{width:'100%'}} title="Register For Exiled Racers">
                                    CONNECT YOUR WALLET
                                </span>
                            </div>
                            <div className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{padding:'0px',marginTop:'-10px',marginBottom:'20px'}}>
                                <div className="col s12 m6 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px'}}>
                                    <div className="row grey lighten-5" style={{padding:'0px 0px',marginBottom:'0px',borderRadius:'25px', border:'1px solid white',cursor:'pointer'}}
                                            onClick={()=>{connectWalletProvider('walletconnect')}} title="Connect Wallet">
                                        <div className="col s4" style={{borderRadius:'10px',overflow:'hidden'}}>
                                            <img src="https://media.exiledracers.com/assets/game/builder/Wallet_Connect_Icon_Blue_1710281691551.png" style={{marginTop:'7px',marginLeft:'-2px',borderRadius:'25px'}} height="40px" />
                                        </div>
                                        <div className="col s8" style={{fontSize:'12px',borderRadius:'10px'}}>
                                            <div className="row headline-text" style={{padding:'0px',marginTop:'10px',marginBottom:'0px'}}>
                                                CONNECT
                                            </div>
                                            <div className="row" style={{padding:'0px',marginTop:'-2px',marginBottom:'5px',fontSize:'14px'}}>
                                                <div className="left black-text" onClick={()=>{connectWalletProvider('walletconnect')}}>Not Connected</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 m6 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px',display:'none'}}>
                                    <div className="row grey lighten-5" style={{padding:'0px 0px',marginBottom:'0px',borderRadius:'25px', border:'1px solid white',cursor:'pointer'}}
                                            onClick={()=>{connectWalletProvider('metamask')}} title="Connect Metamask Wallet">
                                        <div className="col s4" style={{borderRadius:'10px',overflow:'hidden'}}>
                                            <img src="css/images/metamask_logo.jpeg" style={{marginTop:'7px',marginLeft:'-2px',borderRadius:'25px'}} height="40px" />
                                        </div>
                                        <div className="col s8" style={{fontSize:'12px',borderRadius:'10px'}}>
                                            <div className="row headline-text" style={{padding:'0px',marginTop:'10px',marginBottom:'0px'}}>
                                                METAMASK
                                            </div>
                                            <div className="row" style={{padding:'0px',marginTop:'-2px',marginBottom:'5px',fontSize:'14px'}}>
                                                <div className="left black-text" onClick={()=>{connectWalletProvider('metamask')}}>Connect</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 m6 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px',display:'none'}}>
                                    <div className="row grey lighten-5" style={{padding:'0px 0px',marginBottom:'0px',borderRadius:'25px', border:'1px solid white',cursor:'pointer'}}
                                            onClick={()=>{connectWalletProvider('talisman')}} title="Connect Talisman Wallet">
                                        <div className="col s4" style={{borderRadius:'10px',overflow:'hidden'}}>
                                            <img src="css/images/talisman_logo.jpeg" style={{marginTop:'7px',marginLeft:'-2px',borderRadius:'25px'}} height="40px" />
                                        </div>
                                        <div className="col s8" style={{fontSize:'12px',borderRadius:'10px'}}>
                                            <div className="row headline-text" style={{padding:'0px',marginTop:'10px',marginBottom:'0px'}}>
                                                TALISMAN
                                            </div>
                                            <div className="row" style={{padding:'0px',marginTop:'-2px',marginBottom:'5px',fontSize:'14px'}}>
                                                <div className="left black-text" onClick={()=>connectWalletProvider('talisman')}>Connect</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div id="connected-wallet" className="row register-sections" style={{display:'none',marginTop:'0px',marginBottom:'0px'}}>
                            <div className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{marginBottom:'20px'}}>
                                <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Currently Connected Wallet">
                                    <input id="racerWalletConnected" className="black-bubble-input-round" 
                                        style={{width:'100%',fontSize:'20px',borderColor:'black'}} disabled />
                                </span>
                                <span id="wallet-save-button" className="round-border-button right black white-text base-blue-background" 
                                    style={{marginTop:'20px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>submitWalletSaved()}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                        CONFIRM
                                    </span>
                                </span>
                                <span id="wallet-save-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        <div id="register-button" className="row" style={{marginTop:'50px'}}>
                            <div className="col s12 m10 offset-m1 show-on-medium-and-up hide-on-small-only">
                                <span className="round-border-button left center-align black white-text event-register-formbutton2 base-blue-background" 
                                    style={{marginLeft:'5px',marginRight:'5px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 30px 10px 30px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>showRegistrationForm()}>
                                    <span id="register-button-arrow" className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 5px 20px",fontSize:'14px',letterSpacing:'2px'}}>
                                        REGISTER FOR ALLOWLIST LOTTERY
                                    </span>
                                </span>
                            </div>
                            <div className="col s10 offset-s1 m6 offset-m3 show-on-small-only hide-on-med-and-up center-align">
                                <span className="round-border-button center-align black white-text base-blue-background" 
                                    style={{marginLeft:'5px',marginRight:'5px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 30px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>showRegistrationForm()}>
                                    <span id="register-button-arrow2" className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'14px',letterSpacing:'1px'}}>
                                        REGISTER FOR ALLOWLIST
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id="register-button-subheadline" className="row" style={{marginTop:'30px'}}>
                            <div className="col s10 offset-s1 headline-text event-register-subheadline-small exr-base-blue-light-text show-on-medium-and-up hide-on-small-only" 
                                style={{padding:'0px',marginBottom:'30px'}}>
                                <div style={{fontSize:'14px',marginLeft:'10px',textTransform:'uppercase'}}>
                                    Limited Spots. Lottery Based.  <a href="https://discord.gg/exiledracers" className="grey-text">Want to Partner?</a>
                                </div>
                            </div>
                            <div className="col s12 headline-text event-register-subheadline exr-base-blue-light-text show-on-small-only hide-on-med-and-up" 
                                style={{padding:'0px',marginBottom:'30px'}}>
                                <div style={{fontSize:'14px',marginLeft:'10px',textTransform:'uppercase'}}>
                                    Limited Spots. Lottery Based.<br/><a href="https://discord.gg/exiledracers" className="grey-text">Want to Partner?</a>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'70px',marginBottom:'70px',padding:'0px 20px'}}>
                            <div className="col s12 m10 offset-m1 main-sponsor-icons show-on-medium-and-up hide-on-small-only" style={{padding:'0px'}}>
                                <div className="left" style={{marginTop:'5px',marginRight:'40px'}}>
                                    <a href="https://www.exiledracers.com" target="_blank" className="white-text">
                                        <img id="exr-logo-button" style={{cursor:'pointer'}} title="Exiled Racers" height="45px" src={logo_image} />
                                    </a>
                                </div>
                                <div className="left" style={{marginTop:'5px',marginRight:'40px',height:'45px',width:'1px',background:'white'}}>
                                </div>
                                <div className="left" style={{marginTop:'7px'}}>
                                    <a href="https://www.base.org/" target="_blank" className="center white-text" style={{marginRight:'30px',verticalAlign:'text-bottom'}}>
                                        <img id="base-logo-button" style={{cursor:'pointer'}} title="BASE" height="40px" src={base_full_logo_white} />
                                    </a>
                                </div>
                            </div>
                            <div className="col s12 m7 offset-m1 main-sponsor-icons center-align show-on-small-only hide-on-med-and-up" style={{padding:'0px'}}>
                                <div className="row">
                                    <a href="https://www.exiledracers.com" target="_blank" className="white-text" style={{marginRight:'30px',marginTop:'10px'}}>
                                        <img id="exr-logo-button" style={{cursor:'pointer'}} title="Exiled Racers" height="50px" src={logo_image} />
                                    </a>
                                    <a href="https://www.base.org/" target="_blank" className="center white-text" style={{marginRight:'30px',marginTop:'10px',verticalAlign:'text-bottom'}}>
                                        <img id="base-logo-button" style={{cursor:'pointer'}} title="BASE" height="40px" src={base_full_logo_white} />
                                    </a>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-2" style={{display:'none',position:'relative',background:'black',width:'100vw',maxHeight:'95vh'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row center-align" style={{paddingTop:'60px',paddingBottom:'60px',marginBottom:'0px',backgroundImage:'url('+bg_image_right+')',
                backgroundSize:'cover'}}>
                    <div className="col s12 m10 offset-m1 white-text" style={{marginBottom:'20px'}}>
                        <div className="headline-text event-register-large-headline">
                            WEEKLY RACES. LEADERBOARDS. PRIZES.
                        </div>
                    </div>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text exr-base-blue-text event-register-subheadline" style={{letterSpacing:'3px'}}>
                            FREE VIRTUAL RACING&nbsp;
                            <span className="white-text">//</span> WEEKLY RANDOM SHARED STACKS&nbsp;
                            <span className="white-text">//</span> PRIZE POOLS
                        </div>
                    </div>
                </div>
                <div className="row center-align" style={{marginBottom:'20px'}}>
                    {/* <video src="https://media.exiledracers.com/global/splash/exr_game_footage_events.mp4" width="100%" showcontrols /> */}
                    <img src="https://media.exiledracers.com/global/splash/exr_game_footage_loop.gif" width="100%" loop />
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-2b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_left+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        CONGRATS {racer_name.toUpperCase()}
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        YOU'RE REGISTERED TO <span className="exr-base-blue-text">{racer_action_status.toUpperCase()}.</span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row" id="welcome-reveal-racer-old" style={{display:'none'}}>
                            <div className="col s12 l5" style={{margin:'0px',padding:'0px 20px'}}>
                                <div className="subheadline-text event-register-subheadline" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'20px',marginTop:'30px',marginBottom:'40px'}}>
                                Every week you'll be able to reveal a random single use racing stack to race with powered 
                                by our <a className="exr-base-blue-text" target="_blank" href="https://exiledracers.medium.com/introducing-havoc-racing-supercharging-partnership-opportunities-new-user-growth-and-exr-57126193e00f">EXR Holders Shared Garage</a>. Climb the leaderboards by 
                                racing each week and earning points for where you finish.<br/><br/>
                                <span className="exr-base-blue-text">Racing Soon:</span> You've been airdropped your Racer Pass. Check back 
                                after <span className="exr-base-blue-text">Tuesday at 5PM EST</span> to reveal which race items you got this week and to 
                                adjust your stack. Stacks can be adjusted every week until Thursday and races go live every Friday.<br/><br/>
                                Join the <a className="exr-base-blue-text" target="_blank" href="https://discord.gg/exiledracers">EXR Discord</a> to get in on the banter.
                                </div>
                            </div>
                            <div className="col s12 l1" style={{margin:'0px',padding:'0px 20px'}}>
                                &nbsp;
                            </div>
                            <div className="col s12 l6 center-align" style={{margin:'0px',padding:'0px'}}>
                                <img src="https://media.exiledracers.com/assets/passes/exr_pink_racing_league_pass.png" width="85%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                        <div className="row" id="welcome-reveal-predictor-old" style={{display:'none'}}>
                            <div className="col s12 l5" style={{margin:'0px',padding:'0px 20px'}}>
                                <div className="subheadline-text event-register-subheadline" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'20px',marginTop:'30px',marginBottom:'40px'}}>
                                Predictors have 24 hours once racers have entered to make predictions on the race outcomes for points. Climb the predictors leaderboard and 
                                earn EXR prizes. Races are starting soon, check back for the start of races.<br/><br/>
                                <span className="exr-base-blue-text">Predictions Soon:</span> Predictions for weekly races begin every Thursday at 5PM EST. Check 
                                back here to view races you can predict on and join the <a className="exr-base-blue-text" target="_blank" href="https://discord.gg/exiledracers">EXR Discord</a> to 
                                join in on the banter
                                </div>
                            </div>
                            <div className="col s12 l1" style={{margin:'0px',padding:'0px 20px'}}>
                                &nbsp;
                            </div>
                            <div className="col s12 l6 center-align" style={{margin:'0px',padding:'0px'}}>
                                <img src="https://media.exiledracers.com/assets/passes/exr_pink_racing_league_predictor_pass.png" width="85%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                        <div className="row" id="welcome-reveal-racer" style={{display:'block'}}>
                            <div className="col s12 l5" style={{margin:'0px',padding:'0px 20px'}}>
                                <div className="row subheadline-text event-register-subheadline" style={{lineHeight:'1.3',paddingRight:'0px',fontSize:'20px',marginTop:'10px',marginBottom:'40px'}}>
                                    Race week is here! Finish setting up your Base League Racing details and reveal your weekly random single use racing stack to get racing.
                                </div>
                                <div className="row" style={{marginTop:'40px',padding:'0px 0px'}}>
                                    <div className="left section-line-seperator white-text white" style={{width:'60%',height:'3px'}}>
                                    </div>
                                </div>
                                <div className="row subheadline-text event-register-subheadline exr-base-blue-text" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'16px',marginTop:'40px',marginBottom:'0px'}}>
                                    GET STARTED
                                </div>
                                <div className="row headline-text event-register-subheadline white-text" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'24px',marginTop:'5px',marginBottom:'0px'}}>
                                    SET YOUR RACER NAME
                                </div>
                                
                                <div className="row subheadline-text event-register-subheadline" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'20px',marginTop:'20px',marginBottom:'40px'}}>
                                    What name do you want to show across races and leaderboards.
                                </div>
                                <div id="confirm-handle-form" className="row" style={{marginBottom:'20px'}}>
                                    <div className="col s12 m10 subheadline-text event-register-subheadline" style={{padding:'0px',marginBottom:'20px'}}>
                                        <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Enter Email Code to Confirm">
                                            <input id="leagueRacerHandle" className="black-bubble-input-round" 
                                                style={{width:'100%',fontSize:'20px',borderColor:'black'}} />
                                        </span>
                                        <span id="handle-confirm-button"  className="round-border-button right black white-text base-blue-background" 
                                            style={{marginTop:'40px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                            title="Save Your Racer Name" onClick={()=>confirmRacerHandle('racer')}>
                                            <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                                expand_circle_right
                                            </span>
                                            <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                                CONTINUE
                                            </span>
                                        </span>
                                        <span id="handle-confirm-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                            style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                            <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 l1" style={{margin:'0px',padding:'0px 20px'}}>
                                &nbsp;
                            </div>
                            <div className="col s12 l6 center-align" style={{margin:'0px',padding:'0px'}}>
                                <img src="https://media.exiledracers.com/assets/passes/exr_pink_racing_league_pass.png" width="85%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                        <div className="row" id="welcome-reveal-predictor" style={{display:'none'}}>
                            <div className="col s12 l5" style={{margin:'0px',padding:'0px 20px'}}>
                                <div className="row subheadline-text event-register-subheadline" style={{lineHeight:'1.3',paddingRight:'0px',fontSize:'20px',marginTop:'10px',marginBottom:'40px'}}>
                                    Race week is here! Finish setting up your Base League Racing details and reveal your weekly races to predict on.
                                </div>
                                <div className="row" style={{marginTop:'40px',padding:'0px 0px'}}>
                                    <div className="left section-line-seperator white-text white" style={{width:'60%',height:'3px'}}>
                                    </div>
                                </div>
                                <div className="row subheadline-text event-register-subheadline exr-base-blue-text" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'16px',marginTop:'40px',marginBottom:'0px'}}>
                                    GET STARTED
                                </div>
                                <div className="row headline-text event-register-subheadline white-text" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'24px',marginTop:'5px',marginBottom:'0px'}}>
                                    SET YOUR PREDICTOR NAME
                                </div>
                                
                                <div className="row subheadline-text event-register-subheadline" style={{lineHeight:'1.3',paddingRight:'20px',fontSize:'20px',marginTop:'20px',marginBottom:'40px'}}>
                                    What name do you want to show across races and leaderboards.
                                </div>
                                <div id="confirm-handle-form" className="row" style={{marginBottom:'20px'}}>
                                    <div className="col s12 m10 subheadline-text event-register-subheadline" style={{padding:'0px',marginBottom:'20px'}}>
                                        <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Enter Email Code to Confirm">
                                            <input id="leagueRacerHandle2" className="black-bubble-input-round" 
                                                style={{width:'100%',fontSize:'20px',borderColor:'black'}} />
                                        </span>
                                        <span id="handle-confirm-button2"  className="round-border-button right black white-text base-blue-background" 
                                            style={{marginTop:'40px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                            title="Save Your Racer Name" onClick={()=>confirmRacerHandle('predictor')}>
                                            <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                                expand_circle_right
                                            </span>
                                            <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                                CONTINUE
                                            </span>
                                        </span>
                                        <span id="handle-confirm-loading2" className="preloader-wrapper small active event-register-formbutton right" 
                                            style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                            <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 l1" style={{margin:'0px',padding:'0px 20px'}}>
                                &nbsp;
                            </div>
                            <div className="col s12 l6 center-align" style={{margin:'0px',padding:'0px'}}>
                                <img src="https://media.exiledracers.com/assets/passes/exr_pink_racing_league_predictor_pass.png" width="85%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-3b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'100vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'40px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'18px',letterSpacing:'3px'}}>
                        GET STARTED // DISCLAIMER
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'30px'}}>
                        BASE RACING LEAGUE TERMS & RULES
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',marginBottom:'50px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m9 offset-m1 white-text event-register-subheadline" style={{fontSize:'18px',lineHeight:'1.5'}}>
                    This is a skills based, play to enjoy game.
                    Our platform is created for genuine enjoyment. Have a good time, and consider any rewards if any—as a little bonus recognition from us and our partners for your participation.
                    <br/><br/>
                    <span className="headline-text exr-base-blue-text">PLATFORM UPDATES</span><br/>
                    We might introduce changes to the platform, such as new games or features. Adapt to these changes like you would to a new twist in your favorite game, and enjoy the evolving experience.

                    <br/><br/>
                    <span className="headline-text exr-base-blue-text">PARTNER PRIZE POOLS</span><br/>
                    Partner prize pools are independently owned and managed by our partners. Participating in these pools is entirely optional, and users are encouraged to conduct their own research (DYOR) before participating. We are not financial advisors (NFA), and users should make informed decisions regarding their involvement in partner-sponsored prize pools.
                    <br/><br/>
                    <span className="headline-text exr-base-blue-text">EXR PLATFORM</span><br/>
                    EXR is a fun, engagement gaming platform and not directly affiliated with our partners. Our partners use our platform to enhance community engagement and enjoyment. Participation in partner activities is optional and designed to add depth to the overall gaming experience (DYOR).

                    </div>
                </div>
                <div className="row center-align" style={{marginTop:'50px',marginBottom:'100px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s10 offset-s1 m8 offset-m2 l4 offset-l4 white-text center-align">
                        <div style={{width:'100%',position:'relative'}}>
                            <span id="disclaimer-confirm-button" className="black white-text base-blue-background" 
                                style={{display:'block',cursor:'pointer',maxWidth:'500px',marginTop:'50px',marginLeft:'0px',marginRight:'0px',width:'90%',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                title="Agree To League Rules & Terms To Continue"
                                onClick={()=> confirmLeagueDisclaimer()}>
                                <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                    expand_circle_right
                                </span>
                                <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                I AGREE & CONTINUE
                                </span>
                            </span>
                            <span id="disclaimer-confirm-loading" className="preloader-wrapper small active event-register-formbutton center" 
                                style={{display:'none',position:'absolute',top:'0px',left:'45%',height:'50px',width:'50px',marginTop:'23px'}}>
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-4b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'100vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'40px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'18px',letterSpacing:'3px'}}>
                        WELCOME {racer_name.toUpperCase()}
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'30px'}}>
                        REVEAL YOUR <span className="exr-base-blue-text">WEEK {race_number}</span> STACKS
                    </div>
                </div>
                <div id="weekly-stack-reveal-section" className="row" style={{display:'none',marginTop:'30px',marginBottom:'50px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        {revealWeeklyStackHTML}
                    </div>
                    <div className="row center-align" style={{marginTop:'50px',marginBottom:'100px',lineHeight:'1.1',padding:'0px 20px'}}>
                        <div className="col s12 m10 offset-m1 l7 offset-l3 white-text center-align">
                            <span className="black white-text base-blue-background" 
                                style={{cursor:'pointer',maxWidth:'700px',marginTop:'50px',marginLeft:'0px',marginRight:'0px',width:'100%',
                                        border:'0px solid white',fontSize:'20px',borderRadius:'60px',padding:'10px 20px 12px 30px',position:'relative'}} 
                                title="Continue to Stack Stats"
                                onClick={()=>viewWeeklyStackRaces()}>
                                <span className="hide-on-small-only show-on-med-and-up" style={{marginTop:'50px'}}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'37px'}}>
                                        expand_circle_down
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'20px',letterSpacing:'1px'}}>
                                    VIEW STACK ENTRY
                                    </span>
                                </span>
                                <span className="hide-on-med-and-up show-on-small-only" style={{marginTop:'50px'}}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'37px'}}>
                                        expand_circle_down
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'20px',letterSpacing:'1px'}}>
                                    VIEW STACK ENTRY
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div id="weekly-stack-reveal-button" className="row center-align" style={{marginTop:'200px',marginBottom:'100px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 l7 offset-l3 white-text center-align">
                        <div className="center-align" style={{width:'100%',position:'relative'}}>
                            <div id="reveal-confirm-button" className="black white-text base-blue-background" 
                                style={{cursor:'pointer',maxWidth:'700px',marginTop:'0px',marginLeft:'0px',marginRight:'0px',width:'100%',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'auto',position:'relative'}} 
                                title="Agree To League Rules & Terms To Continue"
                                onClick={()=> revealWeeklyStack(1)}>
                                <span className="hide-on-small-only show-on-med-and-up" style={{marginTop:'50px'}}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'50px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'30px',letterSpacing:'1px'}}>
                                    REVEAL YOUR STACK
                                    </span>
                                </span>
                                <span className="hide-on-med-and-up show-on-small-only" style={{marginTop:'50px'}}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'37px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'20px',letterSpacing:'1px'}}>
                                    REVEAL YOUR STACK
                                    </span>
                                </span>
                                
                            </div>
                            <span id="reveal-confirm-loading" className="preloader-wrapper small active event-register-formbutton center" 
                                style={{display:'none',position:'absolute',top:'0px',left:'45%',height:'50px',width:'50px',marginTop:'23px'}}>
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                
                
                
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-5b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'40px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        <span id="swapweekleft" className="material-icons white-text" 
                            style={{marginLeft:'10px',fontSize:'30px',verticalAlign:'text-bottom',cursor:'pointer'}}
                            title="Previous Race Week"
                            onClick={()=>switchRaceWeek('back')}>chevron_left</span>
                        <span style={{marginLeft:'10px'}}>
                            RACE WEEK {swapped_raceday_number}
                        </span>
                        <span id="swapweekright" className="material-icons white-text" 
                            style={{marginLeft:'10px',fontSize:'30px',verticalAlign:'text-bottom',cursor:'pointer'}}
                            title="Next Race Week"
                            onClick={()=>switchRaceWeek('next')}>chevron_right</span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div id="race-weekly-inner-nav" className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-large-headline" style={{fontSize:'18px'}}>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link-active" style={{cursor:'pointer',maxWidth:'220px',marginBottom:'10px'}} 
                            onClick={()=>toggleRaceSections('race_results')} id="race_results_link">RACE RESULT</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px'}}
                            onClick={()=>toggleRaceSections('predict_race')} id="predict_race_link">PREDICTIONS</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px',display:'none'}}
                            onClick={()=>toggleRaceSections('stack_entry')} id="stack_entry_link">YOUR ENTRY</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{display:'none',cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px'}} 
                            onClick={()=>toggleRaceSections('race_lineup')} id="race_lineup_link">RACE LINEUP</span> 
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px',display:'none'}} 
                            onClick={()=>toggleRaceSections('stack_details')} id="stack_details_link">YOUR STACKS</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px',display:'none'}} 
                            onClick={()=>toggleRaceSections('race_details')} id="race_details_link">RACE DETAILS</span>
                        <span className="col s6 l2 event-register-subheadline white-base-blue-link" style={{cursor:'pointer',maxWidth:'220px', minWidth:'180px',marginBottom:'10px',display:'block'}} 
                            onClick={()=>scrollToLeaderboards()}>LEADERBOARDS</span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',marginBottom:'50px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        {weeklyRaceHTML}
                        {weeklyPredictionsHTML}
                        {weeklyStackDetailsHTML}
                        {weeklyRacedayDetailsHTML}
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-66b" style={{marginBottom:'0px',display:'none'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row exr-dark-grey-bg white-text center-align" style={{marginTop:'0px',marginBottom:'0px',padding:'30px 20px'}}>
                    <div className="col s12">
                        <div className="row headline-text exr-base-blue-text" style={{fontSize:'18px',marginTop:'10px',marginBottom:'10px'}}>BASE RACING LEAGUE SCHEDULE</div>
                    </div>
                    <div className="col s12 m3" style={{padding:'10px'}}>
                        <div className="exr-new-light-grey" style={{padding:'20px',borderRadius:'10px'}}>
                            <div className="row headline-text" style={{fontSize:'16px',marginBottom:'5px'}}>WEEK 1 // <span className="exr-base-blue-text"> NOW</span></div>
                            <div className="row subheadline-text" style={{marginBottom:'0px'}}>DATE: 09 FEB</div>
                        </div>
                    </div>
                    <div className="col s12 m3" style={{padding:'10px'}}>
                        <div className="exr-new-light-grey" style={{padding:'20px',borderRadius:'10px'}}>
                            <div className="row headline-text" style={{fontSize:'16px',marginBottom:'5px'}}>WEEK 2 // NEXT</div>
                            <div className="row subheadline-text" style={{marginBottom:'0px'}}>DATE: 16 FEB</div>
                        </div>
                    </div>
                    <div className="col s12 m3" style={{padding:'10px'}}>
                        <div className="exr-new-light-grey" style={{padding:'20px',borderRadius:'10px'}}>
                            <div className="row headline-text" style={{fontSize:'16px',marginBottom:'5px'}}>WEEK 3 // COMPLETE</div>
                            <div className="row subheadline-text" style={{marginBottom:'0px'}}>DATE: 23 FEB</div>
                        </div>
                    </div>
                    <div className="col s12 m3" style={{padding:'10px'}}>
                        <div className="exr-new-light-grey" style={{padding:'20px',borderRadius:'10px'}}>
                            <div className="row headline-text" style={{fontSize:'16px',marginBottom:'5px'}}>WEEK 4  // COMPLETE</div>
                            <div className="row subheadline-text" style={{marginBottom:'0px'}}>DATE: 01 MAR</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-6b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                {leaderboardsHTML}
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-3" style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                        <div className="col s12 m10 offset-m1 white-text">
                            <div className="left white-text section-line-seperator base-blue-background" style={{width:'35%',height:'2px',background:'#FF0000'}}>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'20px',marginBottom:'10px',padding:'0px 20px'}}>

                        <div className="col s12 m10 offset-m1 white-text">
                            <div className="subheadline-text exr-base-blue-text event-register-subheadline" style={{letterSpacing:'2px',textTransform:'uppercase'}}>
                                HOW IT WORKS:&nbsp;
                                <span className="white-text">
                                    Register. Reveal Your Racing stacks. Race weekly. Climb the Leaderboard.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row base-blue-background" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                        <div className="col s12 m10 offset-m1 white-text">
                            <div className="row event-register-subheadline" style={{marginTop:'50px',marginBottom:'50px',lineHeight:'1.1'}}>
                                <div className="col s12 l4" style={{padding:'10px 20px',marginBottom:'20px'}}>
                                    <div className="row headline-text" style={{fontSize:'18px',margin:'0px',padding:'0px',marginBottom:'20px'}}>
                                        1. REGISTER FOR LEAGUE
                                    </div>
                                    <div className="row" style={{padding:'0px',margin:'0px',marginBottom:'20px',height:'200px',width:'100%', borderRadius:'5px',
                                                    background:'url(https://media.exiledracers.com/assets/game/builder/Bifrost_Register_Screenshot_1704928597773.png)',
                                                    backgroundSize:'cover', backgroundPosition:'left center'}} >
                                    </div>
                                    <div className="row subheadline-text-mono section-triple-subtext" style={{margin:'0px',padding:'0px',color:'silver',fontSize:'16px'}}>
                                            Register for the Base League. This will give you access to free weekly races with a 
                                            random stack of racing items (Pilot, Racecraft & 2x Boosters) each week. 
                                    </div>
                                </div>
                                <div className="col s12 l4" style={{padding:'10px 20px',marginBottom:'20px'}}>
                                    <div className="row headline-text" style={{fontSize:'18px',margin:'0px',padding:'0px',marginBottom:'20px'}}>
                                        2. REVEAL AND RACE
                                    </div>
                                    <div className="row" style={{padding:'0px',margin:'0px',marginBottom:'20px',height:'200px',width:'100%', borderRadius:'5px',
                                                    background:'url(https://media.exiledracers.com/global/splash/exr_event_make_prediction2.png)',
                                                    backgroundSize:'cover', backgroundPosition:'center'}} >
                                    </div>
                                    <div className="row subheadline-text-mono section-triple-subtext" style={{margin:'0px',padding:'0px',color:'silver',fontSize:'16px'}}>
                                        Each week you'll get one free random stack to use for that week only. 
                                        You'll have 2 days to enter your best performing combo to climb the points leaderboard.  

                                    </div>
                                </div>
                                <div className="col s12 l4" style={{padding:'10px 20px',marginBottom:'20px'}}>
                                    <div className="row headline-text" style={{fontSize:'18px',margin:'0px',padding:'0px',marginBottom:'20px'}}>
                                        3. PRIZES FOR TOP SPOTS
                                    </div>
                                    <div className="row" style={{padding:'0px',margin:'0px',marginBottom:'20px',height:'200px',width:'100%', borderRadius:'5px',
                                                    background:'url(https://media.exiledracers.com/global/splash/exr_event_streaming_link.png)',
                                                    backgroundSize:'cover', backgroundPosition:'center'}} >
                                    </div>
                                    <div className="row subheadline-text-mono section-triple-subtext" style={{margin:'0px',padding:'0px',color:'silver',fontSize:'16px'}}>
                                        Earn weekly and end of season prizes for coming in the top spots in races and the final leaderboard. 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-4" style={{display:'none',position:'relative',background:'black',width:'100vw',minHeight:'95vh',marginBottom:'0px',
            backgroundImage:'url('+bg_image_ship+')',
            backgroundSize:'cover',
            backgroundPosition:'bottom'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="row firstsection" style={{marginBottom:'10px'}}>
                        <div className="col s12 m10 offset-m1 white-text">
                            <div className="row" style={{marginTop:'0px',marginBottom:'200px',lineHeight:'1.1'}}>
                                <div className="col s12 l5 white-text" style={{padding:'0px 20px'}}>
                                    <div className="row show-on-large-only hide-on-med-and-down" style={{marginTop:'7vh'}}>
                                        <div className="left base-blue-background white-text" style={{width:'35%',height:'3px'}}>
                                        </div>
                                    </div>
                                    <div className="row headline-text event-register-large-headline event-register-subheadline-mid show-on-large-only hide-on-med-and-down"  
                                        style={{fontSize:'40px',lineHeight:'1.25'}}>
                                        <span className="exr-base-blue-text">BASE</span><br/>
                                        LEAGUE <br/>
                                        REWARDS
                                    </div>
                                    <div className="row headline-text event-register-large-headline event-register-subheadline-mid show-on-medium-and-down hide-on-large-only"  
                                        style={{fontSize:'40px'}}>
                                        BASE LEAGUE REWARDS
                                    </div>
                                    <div className="row show-on-medium-and-down hide-on-large-only" style={{marginTop:'0px'}}>
                                        <div className="left section-line-seperator base-blue-background white-text" style={{width:'45%',height:'2px'}}>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 l7 event-register-subheadline-mid" style={{color:'silver',padding:'0px 20px'}}>
                                    <div className="row subheadline-text" style={{borderBottom:'1px solid silver',marginBottom:'30px'}}>
                                        <div className="row subheadline-text event-register-subheadline-mid" style={{fontSize:'16px',margin:'0px',marginBottom:'15px'}}>
                                            LEADERBOARD PRIZES
                                        </div>
                                        <div className="row headline-text exr-base-blue-text event-register-subheadline-mid" style={{margin:'0px',marginBottom:'20px'}}>
                                            BASE <span className="white-text">PRIZE POOLS</span>
                                        </div>
                                        <div className="row subheadline-text" style={{fontSize:'14px',margin:'0px',marginBottom:'30px',textTransform:'uppercase'}}>
                                            FOR COMMUNITY LEADERBOARD & HOLDERS LEADERBOARD
                                        </div>
                                    </div>
                                    <div className="row subheadline-text" style={{borderBottom:'1px solid silver',marginBottom:'30px'}}>
                                        <div className="row subheadline-text event-register-subheadline-mid" style={{fontSize:'16px',margin:'0px',marginBottom:'15px'}}>
                                            TOP 20 RACING POINTS LEADERS
                                        </div>
                                        <div className="row headline-text exr-base-blue-text event-register-subheadline-mid" style={{margin:'0px',marginBottom:'20px'}}>
                                            WEEKLY <span className="white-text">PRIZE AWARDS</span> 
                                        </div>
                                        <div className="row subheadline-text" style={{fontSize:'14px',margin:'0px',marginBottom:'30px',textTransform:'uppercase'}}>
                                            $USDC Wallet required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row howtoplay" id="event-landing-page-5g" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW TO RACE: STEP 1
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        REVEAL NEW <span className="exr-base-blue-text">ITEMS WEEKLY</span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.1',paddingRight:'20px',fontSize:'16px'}}>
                            every week you'll be able to Reveal a random single use racing stack to race with. 
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <div className="col s12 l10" style={{margin:'0px',padding:'0px'}}>
                                <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Reveal_Stack_Sample_1704930600173.png" width="95%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col s12 l10">
                                <div className="row section-triple-subtext" style={{borderRadius:'20px'}}>
                                    <div className="col s3 m2 l1 right-align">
                                        <img src="https://media.exiledracers.com/assets/game/builder/API3_Logo_Vertical_1704931823661.png" height="70px" />
                                    </div>
                                    <div className="col s9 m10 l10" style={{marginTop:'18px',color:'grey',fontSize:'16px'}}>
                                        Provably fair
                                        random stacks
                                        powered by API3 
                                        <br/>
                                        Weekly stacks are 
                                        single use. For
                                        random league.  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row howtoplay" id="event-landing-page-55b" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_left+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW TO RACE: STEP 2
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        GO TO THE <span className="exr-base-blue-text">EXR RACE MANAGER</span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.1',paddingRight:'20px',fontSize:'16px'}}>
                            Manage your racing stack for the weeks race in the racing manager. every week you’ll get a new stack to race
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <div className="col s12 l10" style={{margin:'0px',padding:'0px'}}>
                                <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Reveal_Stack_Sample_1704930600173.png" width="95%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row howtoplay" id="event-landing-page-5c" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW TO RACE: STEP 3
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        PICK UP <span className="exr-base-blue-text">MORE STACKS</span> FOR THE WEEK
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.1',paddingRight:'20px',fontSize:'16px'}}>
                            If you want more—Every Week you'll be able to pick up 3 additional single use Racing stacks for the week. 
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <div className="col s12 l10" style={{margin:'0px',padding:'0px'}}>
                                <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Reveal_Stack_Sample_1704930600173.png" width="95%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col s12 l10">
                                <div className="row section-triple-subtext" style={{borderRadius:'20px'}}>
                                    <div className="col s3 m2 l1 right-align">
                                        <img src="https://media.exiledracers.com/assets/game/builder/API3_Logo_Vertical_1704931823661.png" height="70px" />
                                    </div>
                                    <div className="col s9 m10 l10" style={{marginTop:'18px',color:'grey',fontSize:'16px'}}>
                                        Provably fair
                                        random stacks
                                        powered by API3 
                                        <br/>
                                        Weekly stacks are 
                                        single use. For
                                        random league.  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row howtoplay" id="event-landing-page-5d" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_left+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW TO RACE: STEP 4
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        ENTER YOUR <span className="exr-base-blue-text">BEST STACK</span>
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.1',paddingRight:'20px',fontSize:'16px'}}>
                            Check entry Period & your stack vs the racetrack attributes.then pick your best stack for the race. 
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <div className="col s12 l10" style={{margin:'0px',padding:'0px'}}>
                                <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Reveal_Stack_Sample_1704930600173.png" width="95%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row howtoplay" id="event-landing-page-5e" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_right+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW TO RACE: STEP 5
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                    <span className="exr-base-blue-text">BONUS:</span> MAKE RACE PREDICTIONS
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.1',paddingRight:'20px',fontSize:'16px'}}>
                            For tie breaks, we look at your prediction leaderboard points—so get those predictions in. 
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <div className="col s12 l10" style={{margin:'0px',padding:'0px'}}>
                                <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Reveal_Stack_Sample_1704930600173.png" width="95%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>

        <div className="row howtoplay" id="event-landing-page-5f" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_left+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text subheadline-text event-register-subheadline"  style={{fontSize:'24px',letterSpacing:'3px'}}>
                        HOW TO RACE: STEP 6
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'30%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'30px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        <span className="exr-base-blue-text">WATCH</span> THE RACES LIVE
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text grey-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.1',paddingRight:'20px',fontSize:'16px'}}>
                            Head to the Base raceway, watch the algorithmic races. Switch views & turn sound on. 
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row">
                            <div className="col s12 l10" style={{margin:'0px',padding:'0px'}}>
                                <img className="section-triple-subtext" src="https://media.exiledracers.com/assets/game/builder/Reveal_Stack_Sample_1704930600173.png" width="95%" style={{borderRadius:'25px'}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
        
        <div className="row howtoplay" id="event-landing-page-6" style={{display:'none',position:'relative',background:'black',width:'100vw',minHeight:'95vh'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row firstsection" style={{marginBottom:'10px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row" style={{marginTop:'30px',marginBottom:'50px',lineHeight:'1.1'}}>
                            <div className="col s12 l6 white-text" style={{padding:"0px 40px"}}>
                                <div className="row show-on-large-only hide-on-med-and-down" style={{marginTop:'12vh'}}>
                                    <div className="left section-line-seperator base-blue-background white-text" style={{width:'45%',height:'2px'}}>
                                    </div>
                                </div>
                                <div className="row headline-text event-register-large-headline event-register-subheadline-mid"  
                                    style={{fontSize:'40px',marginTop:'20px'}}>
                                    COLLECT POINTS<br/>CLIMB THE LEADERBOARD
                                </div>
                                <div className="row show-on-medium-and-down hide-on-large-only" style={{marginTop:'0px'}}>
                                    <div className="left section-line-seperator base-blue-background white-text" style={{width:'45%',height:'2px'}}>
                                    </div>
                                </div>
                                <div className="row subheadline-text section-triple-subtext event-register-subheadline" 
                                        style={{fontSize:'16px',color:'silver',textTransform:'uppercase'}}>
                                            <span className="white-text">
                                                Each week after each race, you'll receive your points for your performance—the better your perform the higher youll climb. 
                                                Do well enough and youll be elligble for a piece of the Prize pool. 
                                            </span>
                                            <br/> <br/>
                                            <span style={{color:'grey'}}>
                                            Holders will have an Exclusive leaderboard and prize pool.
                                            </span>
                                </div>
                            </div>
                            <div className="col s12 l6" style={{color:'silver'}}>
                                <div className="row subheadline-text" style={{marginBottom:'50px',borderRadius:'5px'}}>
                                    <img src="https://media.exiledracers.com/global/splash/exr_event_leaderboard_example.png" 
                                        className="section-triple-subtext" style={{width:'auto',maxWidth:'100%',height:'500px',borderRadius:'10px'}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row howtoplay" id="event-landing-page-7" style={{display:'none',position:'relative',background:'black',width:'100vw',maxHeight:'70vh',overflow:'hidden',marginBottom:'0px'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="row center-align" style={{marginTop:'0px',marginBottom:'0px'}}>
                        <img src="https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg" style={{width:"100vw"}} />
                    </div>
                </div>
            </div>
        </div>
        <div className="row" id="event-landing-page-8" 
            style={{display:'none',position:'relative',background:'black',width:'100%',minHeight:'80vh',marginBottom:'0px',
                    backgroundImage:'url('+bg_image_left+')',
                    backgroundSize:'cover'}}>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'80vh',height:'100%',position:'absolute',top:'0px',left:'0px'}}>
            </div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div className="row" style={{marginTop:'100px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text headline-text event-register-large-headline"  style={{fontSize:'40px'}}>
                        POWERED BY EXR HOLDERS
                    </div>
                </div>
                <div className="row" style={{marginTop:'0px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="left section-line-seperator white-text base-blue-background" style={{width:'20%',height:'3px'}}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'50px',marginBottom:'0px',lineHeight:'1.1',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row event-register-subheadline" style={{margin:'40px 0px',mlineHeight:'1.1',color:'silver',padding:'0px'}}>
                            <div className="col s12 l3" style={{padding:'10px',marginBottom:'20px'}}>
                                <div className="row headline-text white-text" style={{fontSize:'24px',padding:'0px',marginBottom:'0px'}}>
                                    HOLDERS
                                </div>
                                <div className="row headline-text exr-base-blue-text" style={{fontSize:'24px',padding:'0px'}}>
                                    SHARED<br/>GARAGE
                                </div>
                                <div className="row subheadline-text-mono section-triple-subtext" style={{padding:'0px',fontSize:'12px'}}>
                                    Random race stack distributions are made possible by EXR holders 
                                    sharing their stacks via the EXR shared garage—all off chain, 
                                    nothing physically leaves our holders wallets.
                                </div>
                            </div>
                            <div className="col s12 l3" style={{padding:'10px',marginBottom:'20px'}}>
                                <div className="row headline-text white-text" style={{fontSize:'24px',padding:'0px',marginBottom:'0px'}}>
                                    HOLDERS
                                </div>
                                <div className="row headline-text exr-base-blue-text" style={{fontSize:'24px',padding:'0px'}}>
                                    LEADERBOARD<br/>PRIZE POOL
                                </div>
                                <div className="row subheadline-text-mono section-triple-subtext" style={{padding:'0px',fontSize:'12px'}}>
                                    Holders that register to race in the Base League (and mint the access pass) also are put into a separate holders 
                                    leaderboard & prize pool.  
                                </div>
                            </div>
                            <div className="col s12 l3" style={{padding:'10px',marginBottom:'20px'}}>
                                <div className="row headline-text white-text" style={{fontSize:'24px',padding:'0px',marginBottom:'0px'}}>
                                    HOLDERS
                                </div>
                                <div className="row headline-text exr-base-blue-text" style={{fontSize:'24px',padding:'0px'}}>
                                    SHARED ITEM<br/>BONUS POINTS
                                </div>
                                <div className="row subheadline-text-mono section-triple-subtext" style={{padding:'0px',fontSize:'12px'}}>
                                    The holders leaderboard will not just be races won. 
                                    It will be bonus points from shared stack items—firstly 
                                    for being used for races and secondly for winning races. 
                                </div>
                            </div>
                            <div className="col s12 l3" style={{padding:'10px',marginBottom:'0px'}}>
                                <div className="row headline-text white-text" style={{fontSize:'24px',padding:'0px',marginBottom:'0px'}}>
                                    HOLDERS
                                </div>
                                <div className="row headline-text exr-base-blue-text" style={{fontSize:'24px',padding:'0px'}}>
                                    BONUS POT<br/>PRIZE DRAW
                                </div>
                                <div className="row subheadline-text-mono section-triple-subtext" style={{padding:'0px',fontSize:'12px'}}>
                                    0% of funds generated from extra
                                    stacks purchased weekly will go into
                                    a holders prize draw pool. Holders points 
                                    from races and shared items will then 
                                    act as tickets for the prize draw. 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:'20px',marginBottom:'50px',padding:'0px 20px'}}>
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="subheadline-text white-text event-register-subheadline" style={{textTransform:'uppercase',lineHeight:'1.1',paddingRight:'20px',fontSize:'16px'}}>
                            Holders Double chance prizing:&nbsp;
                            <span className="exr-base-blue-text">
                                Holders can win in both the regular race prize pool and the holders prize pool
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row base-blue-background howtoplay" id="event-landing-page-9" style={{display:'block',position:'relative',minWidth:'100vw',minHeight:'70vh',marginBottom:'0px'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="row center-align firstsection" style={{marginBottom:'10px'}}>
                        <div className="col s12 m10 offset-m1 white-text">
                            <div className="row white-text" style={{margin:'0px',padding:'0px',marginTop:'30px'}}>
                                <span className="headline-text white-text event-register-large-headline" style={{padding:'10px 20px',borderBottom:'2px solid white'}}>
                                    SUPPORTED BY
                                </span>
                            </div>
                            <div className="row black-text" style={{margin:'0px',padding:'0px',marginTop:'80px',marginBottom:'50px',lineHeight:'1.1'}}>
                                <div className="col s12 m12 l4" style={{margin:'0px',padding:'0px'}}>
                                    <div className="left" style={{width:'100%',padding:'30px'}}>
                                        <div className="row headline-text" style={{fontSize:'18px',padding:'0px',marginBottom:'30px'}}>
                                            GAME SPONSOR
                                        </div>
                                        <div className="row" style={{padding:'0px',height:'100px'}}>
                                            <a href="https://www.exiledracers.com/" target="_blank" title="Exiled Racers">
                                                <img src={logo_image} style={{marginTop:'-5px',height:'60px'}} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 m6 l4" style={{margin:'0px',padding:'0px'}}>
                                    <div className="left" style={{width:'100%',padding:'30px'}}>
                                        <div className="row headline-text" style={{fontSize:'18px',padding:'0px',marginBottom:'30px'}}>
                                            PLATFORM SPONSORS
                                        </div>
                                        <div className="row" style={{padding:'0px',height:'100px'}}>
                                            <a href="https://www.base.org/" target="_blank" title="BASE">
                                                <img src={base_full_logo_white} style={{height:'50px',marginRight:'30px'}} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 m6 l4" style={{margin:'0px',padding:'0px'}}>
                                    <div className="left" style={{width:'100%',padding:'30px'}}>
                                        <div className="row headline-text" style={{fontSize:'18px',padding:'0px',marginBottom:'30px'}}>
                                            xCHAIN PARTNER
                                        </div>
                                        <div className="row" style={{padding:'0px',height:'100px'}}>
                                            <a href="https://axelar.network/" target="_blank" title="Axelar">
                                                <img src={axelar_full_logo_white} style={{height:'45px'}} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};