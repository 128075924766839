import { Contract, Signer, utils, providers } from "ethers";

import ABI from "./abis/EXRGameAsset.json";

import { racerContractAddresses } from "../constants";

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

class RacerContract {
  contract;
  provider;
  contractAddress;

  constructor(chainId, signer) {
    //console.log({ chainId });
    this.contractAddress = racerContractAddresses[chainId];
    if (this.contractAddress) {
      this.contract = new Contract(this.contractAddress, ABI, signer);
    } else {
      return;
    }
  }

  walletOfOwner(address) {
    return this.contract.walletOfOwner(address);
  }

  balanceOf(address) {
    return this.contract.balanceOf(address);
  }

  tokenUri(id) {
    return this.contract.tokenURI(id);
  }
}

export default RacerContract;
