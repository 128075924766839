import React, { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../../../utils";
import { updateLocation } from "../../../../../../../redux/App/actions";
import { RaceChat } from "../../../RaceChat";
import "../../../../../RacesHome/races_home.css";

export const GeneralTimeline = (props) => {
    const dispatch = useDispatch();

    return(<div id="race-countdown-timer-shell" className="center-align" style={{position:'absolute',width:'100%',zIndex:'100',top:'32%',left:'0px'}}>
                <span id="race-countdown-registered" className="headline-text exr-yellow-text" style={{fontSize:'18px',display:'none'}}></span>
                <span id="race-countdown-title" className="headline-text" style={{fontSize:'2.5vw'}}>RACES NOW LIVE TO WATCH</span>
                <br/><span id="race-countdown-date" style={{fontSize:'2.5vw'}}></span>
                <br/><span id="race-countdown-timer" style={{fontSize:'2.5vw'}}></span>
    
        </div>);
};     