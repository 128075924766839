import * as THREE from "three";
import {TrackPropInstance} from "./trackPropInstance.js";
import {TrackTracer} from "../../trackTracer.js";

/**
 * An instance of a track prop placed along a regular interval on a length of track
 */
export class TrackPropInstanceOnTrackInterval extends TrackPropInstance {
    /**
     * Construct a track prop placed along an interval on a track
     * @param {Track} track The track to place the prop on
     * @param {number} interval The interval between instantiations
     * @param {THREE.Vector3} [offset] The offset of this prop relative to the track center
     * @param {boolean} [skipFirst] True if the first instance should be skipped
     * @param {number} [start] The start length on the track
     * @param {number} [end] The end length on the track
     */
    constructor(
        track,
        interval,
        offset = new THREE.Vector3(),
        skipFirst = false,
        start = 0,
        end = track.length) {
        super();

        this.track = track;
        this.interval = interval;
        this.start = start;
        this.end = end;
        this.offset = offset;
        this.skipFirst = skipFirst;
    }

    /**
     * Instantiate this instance
     * @param {Scene} parent The parent scene to add the prop to
     * @param {Scene} scene The scene containing the meshes
     * @param {Track} track The track
     * @param {number} trackWidth The track width
     */
    instantiate(parent, scene, track, trackWidth,sponsor) {
        const intervals = Math.round((this.end - this.start) / this.interval);
        const interval = (this.end - this.start) / intervals;
        const tracer = new TrackTracer(this.track);

        for (let i = this.skipFirst ? 1 : 0; i < intervals; ++i) {
            const copy = scene.clone();

            this.objects.push(copy);

            tracer.advance(interval);

            copy.quaternion.setFromEuler(new THREE.Euler(
                0,
                -tracer.trace(copy.position, this.offset),
                0,
                "YXZ"));

            parent.add(copy);
        }
    }
}