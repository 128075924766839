import * as THREE from "three";
import {Globals} from "../../global/globals.js";
import {Stage} from "./stage.js";

export class StageHologram {
    static #RESOLUTION_NAME = new THREE.Vector2(160, 40);
    static #RESOLUTION_STATS = new THREE.Vector2(160, 80);

    static LOGO_COLOR = new THREE.Color("#fff");
    static MATERIAL_FADE = new THREE.ShaderMaterial({
        // language=GLSL
        vertexShader: `
            varying vec2 vUv;
            
            void main() {
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
                vUv = uv;
            }`,
        // language=GLSL
        fragmentShader: `
            uniform sampler2D intensity;
            uniform vec3 color;

            varying vec2 vUv;
            
            void main() {
                gl_FragColor = vec4(color, texture(intensity, vUv).r);
            }`,
        uniforms: {
            color: null,
            intensity: null
        },
        transparent: true,
        depthWrite: false,
        blendMode: THREE.AdditiveBlending,
    });

    color;
    colorText;
    position;
    background;
    name;
    stats;
    gradient;
    number;
    augs;
    serfs;
    mercs;

    static makeFadeMaterial(color, map) {
        const clone = StageHologram.MATERIAL_FADE.clone();

        clone.uniforms.color = new THREE.Uniform(color);
        clone.uniforms.intensity = new THREE.Uniform(map);

        return clone;
    }

    /**
     * Make a material for a name tag
     * @param {Color} color The color
     * @param {string} name The name
     * @returns {THREE.MeshBasicMaterial} The material
     */
    static makeNameMaterial(color, name) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = StageHologram.#RESOLUTION_NAME.x;
        canvas.height = StageHologram.#RESOLUTION_NAME.y;

        context.fillStyle = color.getStyle();
        context.font = "bold 36px sans-serif";
        context.fillText(name, 0, 30, canvas.width);

        const texture = new THREE.CanvasTexture(
            canvas,
            THREE.UVMapping,
            THREE.ClampToEdgeWrapping,
            THREE.ClampToEdgeWrapping,
            THREE.LinearFilter,
            THREE.LinearMipMapLinearFilter);

        texture.flipY = false;

        return new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            depthWrite: false
        });
    }

    /**
     * Make a material for a stats tag
     * @param {Color} color The color
     * @param {string} rpm RPM earned
     * @returns {THREE.MeshBasicMaterial} The material
     */
    static makeStatsMaterial(color, rpm) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = StageHologram.#RESOLUTION_STATS.x;
        canvas.height = StageHologram.#RESOLUTION_STATS.y;

        context.fillStyle = color.getStyle();
        context.font = "bold 36px sans-serif";
        context.fillText(`RPM: ${rpm}`, 0, 30, canvas.width);

        const texture = new THREE.CanvasTexture(
            canvas,
            THREE.UVMapping,
            THREE.ClampToEdgeWrapping,
            THREE.ClampToEdgeWrapping,
            THREE.LinearFilter,
            THREE.LinearMipMapLinearFilter);

        texture.flipY = false;

        return new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            depthWrite: false
        });
    }

    /**
     * Construct the hologram for a stage place
     * @param {Color} color The color of this hologram
     * @param {Color} colorContrast The contrast color of this hologram
     * @param {Color} colorText The text color
     * @param {Vector3} position The position the racer should be placed on
     * @param {Mesh} background The background
     * @param {Mesh} name The name tag plane
     * @param {Mesh} stats The stats plane
     * @param {Mesh} gradient The gradient plane
     * @param {Mesh} number The number plane
     * @param {Mesh} portrait The portrait plane
     * @param {Mesh} augs The augs logo
     * @param {Mesh} mercs The mercs logo
     * @param {Mesh} serfs The serfs logo
     */
    constructor(
        color,
        colorContrast,
        colorText,
        position,
        background,
        name,
        stats,
        gradient,
        number,
        portrait,
        augs,
        mercs,
        serfs) {
        this.color = color;
        this.colorText = colorText;
        this.position = position;
        this.background = background;
        this.name = name;
        this.stats = stats;
        this.gradient = gradient;
        this.number = number;
        this.portrait = portrait;
        this.augs = augs;
        this.mercs = mercs;
        this.serfs = serfs;

        augs.material = mercs.material = serfs.material =
            StageHologram.makeFadeMaterial(StageHologram.LOGO_COLOR, augs.material.emissiveMap);

        augs.visible = mercs.visible = serfs.visible = false;

        background.material = new THREE.MeshBasicMaterial({
            color: color,
            opacity: .2,
            transparent: true
        });

        portrait.visible = false;

        number.material = StageHologram.makeFadeMaterial(colorContrast, number.material.emissiveMap);

        gradient.material = StageHologram.makeFadeMaterial(color, gradient.material.emissiveMap);
        gradient.material.uniforms.color = new THREE.Uniform(color);

        name.visible = false;
        stats.visible = false;
    }

    /**
     * Make a racer model clone to place on the stage
     * @param {THREE.Object3D} clone The clone
     * @returns {THREE.Object3D} The modified clone
     */
    makeClone(clone) {
        clone.scale.multiplyScalar(.4);

        clone.position.z -= 200;

        clone.rotateY(Math.PI * .5);
        clone.rotateZ(Math.PI * -.5);

        return clone;
    }

    /**
     * Set the racer for this hologram
     * @param {Racer} racer The racer
     */
    set racer(racer) {
        Globals.TEXTURE_CACHE.load(racer.data.urlAvatarSmall).then(texture => {
            texture.center.set(.5, .5);
            texture.rotation = Math.PI * .5;

            this.portrait.visible = true;
            this.portrait.material = new THREE.MeshBasicMaterial({
                map: texture
            });

            this.name.visible = true;
            this.name.material = StageHologram.makeNameMaterial(this.colorText, racer.data.racerName);

            this.stats.visible = true;
            this.stats.material = StageHologram.makeStatsMaterial(this.colorText, racer.data.rpm);
        });

        switch (racer.data.faction) {
            case "Mercenary":
                this.mercs.visible = true;

                break;
            case "Serf":
                this.serfs.visible = true;

                break;
            case "Augment":
                this.augs.visible = true;

                break;
        }

        this.position.add(this.makeClone(racer.ship.clone()));
    }
}