import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ethers } from "ethers";
import {
  setRacerMintPrice,
  setTransactionError,
  setTxHash,
} from "../../../redux/Web3/actions";

import { Button } from "../../Atoms";
export const MintRacer = () => {
  const dispatch = useDispatch();
  const { racerContract, racerMintPrice, mintIds } = useSelector(
    (state) => state.web3
  );

  useEffect(() => {
    console.log({ racerContract });
    if(racerContract !== null) {
      racerContract
      .getMintPrice()
      .then((price) => {
        console.log({ price });
        dispatch(setRacerMintPrice(price));
      })
      .catch((err) => console.log(err));
    }
  }, []);

  function mintRacer() {
    console.log("minting Racer");
    racerContract
      .mintRacer(1, ethers.utils.parseEther(racerMintPrice.toString()))
      .then(({ hash }) => {
        console.log({ hash });
        dispatch(setTxHash(hash));
      })
      .catch((err) => {
        console.log({ err });
        dispatch(setTransactionError(err));
      });
  }

  return (
    <div>
      <h1>Mint a Racer</h1>
      <div>Mint Price: {racerMintPrice} Eth</div>
      <Button onClick={mintRacer}>Mint Racer</Button>
    </div>
  );
};
