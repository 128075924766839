import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerXP, setRacerCoins } from "../../../redux/Account/actions";
import {axiosInstance} from "../../../utils";

let web3;

export const SplashHeader = () => {
    const dispatch = useDispatch();
    
    function goToStaging() {
        window.location.href='https://testnet.cryptospaceracers.club/';
    }

    const [headerSet, setHeader] = useState(false);
    
    if(headerSet == false) {
        setHeader(true);
    }

    return(<div className="row white-text page-width top-nav-header">
                <div className="col s12 transparent" style={{marginTop:'10px'}}>
                    <div className="show-on-small hide-on-med-and-up" style={{width:'100%'}}>
                        <div className="top-nav-mobile-container">
                            <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" width="75%" className="cursor-pointer" />
                        </div>
                    </div>
                    <div className="show-on-medium-and-up hide-on-small-only" style={{width:'100%'}}>
                        
                        <div className="top-nav-mobile-container" style={{marginTop:'10px'}}>
                            <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" height="75px" className="cursor-pointer" />
                        </div>
                        <div className="right" style={{marginTop:'-70px',fontSize:'16px',marginRight:'20px'}}>
                            <span className="top-nav-header-right-item-text cursor-pointer" onClick={()=>goToStaging()} >
                                Login
                            </span>
                        </div>
                    </div>
                </div>
        </div>);
};