import {RacersEvent} from "./racersEvent.js";

export class RacersEventTakeovers extends RacersEvent {
    /**
     * Construct a takeover event
     * @param {number} frame The frame this event takes place at
     * @param {number[]} changes The rank delta for each racer
     */
    constructor(frame, changes) {
        super(frame);

        this.changes = changes;
        this.changeDelta = 0;

        for (let racer = 0, racerCount = changes.length; racer < racerCount; ++racer)
            this.changeDelta += Math.abs(changes[racer]);
    }
}