import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import "./splash_event.css";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import {
    SplashTrackMeets, 
    TeddyDaoCup, 
    SplashBifrostLeague, 
    SplashPinkLeague,
    SplashBaseLeague
} from "./Pages"


export const SplashEvent = (props) => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { chainId, account } = useWeb3React();

    // if((racerID.length > 1)&&(accountAddress.length > 1)) {
    //     // dispatch(updateLocation('workshop'));
    // }

    const [pageSet, setPage] = useState(false);

    document.addEventListener('DOMContentLoaded', function() {
        
        // window.resetCenterHeight();
        // window.startPageModals();
        // window.resetBodyWidth();
        // startSwappingHoverButtons();

        // window.$("#exr-logo-button").click(function() {
        //     window.location.reload();
        // });

        setTimeout(function(){
            window.startPageModals();
        },1000);
    });

    function startSwappingHoverButtons() {
        window.$("#discord-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Discord_White_Gif0.png');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Discord_White_Gif0.png');
        });

        window.$("#twitter-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Twitter_White_Gif0.png');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Twitter_White_Gif0.png');
        });

        window.$("#medium-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Medium_White_Gif0.png');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Medium_White_Gif0.png');
        });
    }

    function requestTrainingAccess() {
        startSwappingHoverButtons();
    }

    function showLoginBox(e) {
        e.preventDefault();
        dispatch(updateLocation('signin'));
        window.location.href="/#/signin&p=login";
    }

    function showRegisterBox() {
        dispatch(updateLocation('signin'));
        window.location.href="/#/signin";
    }

    function goToLearnSite() {
        dispatch(updateLocation("gameplay"));
        window.location.href="/#/gameplay";
    }

    function goToMintSite() {
        window.location.href="https://mint.exiledracers.com"
    }

    function goToCollectionViewer() {
        window.location.href="https://view.exiledracers.com"
    }

    function showRacewayPage() {
        dispatch(updateLocation("raceway"));
        window.location.href="/#/raceway";
    }

    window.addEventListener('resize', function() {
        window.resetCenterHeight();
    });

    if(setPage === false) {
        pageSet(true);
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            {/* <SplashTrackMeets currentPage={props.currentPage} /> */}
            {/* <TeddyDaoCup currentPage={props.currentPage} /> */}
            {/* <SplashBifrostLeague currentPage={props.currentPage} /> */}
            {/* <SplashPinkLeague currentPage={props.currentPage} /> */}
            <SplashBaseLeague currentPage={props.currentPage} />
        </div>);
};