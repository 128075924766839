import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../utils";
import "../RacesHome/races_home.css";
import $ from "jquery";

let total_page_results = 25;
let current_race_collection = 4;
let collectionList = [];
let leaderboardList = [];
let current_racer_pagenum = 0;
let current_racer_timeseries = 'all-time';
let current_racer_sort = 'rpm';
let current_prophet_pagenum = 0;
let current_prophet_timeseries = 'all-time';
let current_prophet_sort = 'xp';
let racerSectionHTMLArray = [];
let prophetSectionHTMLArray = [];

export const LeaderboardOpen = (props) => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    const [entrySet, setEntry] = useState(false);
    const [leaderboard_collection_title, setLeaderboardCollectionTitle] = useState('');
    const [racersSectionHTML, setRacerSectionHTML] = useState('');
    const [showMoreRacersHTML, setShowMoreRacersHTML] = useState('');
    const [prophetSectionHTML, setProphetSectionHTML] = useState('');
    const [showMoreProphetsHTML, setShowMoreProphetsHTML] = useState('');
    const [customLeaderboardHTML, setCustomLeaderboardHTML] = useState('');
    const [racerTimeSeries, setRacerTimeSeries] = useState('all-time');
    const [prophetTimeSeries, setProphetTimeSeries] = useState('all-time');
    const [invite_racer_name, setInviteRacerName] = useState('');
    const [user_game_points, setUserGamePoints] = useState('');
    const [user_prophet_points, setUserProphetPoints] = useState('');
    const [total_game_points, setTotalGamePoints] = useState('');
    const [total_prophet_points, setTotalProphetPoints] = useState('');
    const [user_referral_points,setReferralPoints] = useState('');
    const [race_collection_select_html, setRaceCollectionSelectHTML] = useState('');
    const [race_collection_select_html_small, setRaceCollectionSelectHTMLSmall] = useState('');
    const [totalPicksCount,setTotalPicksCount] = useState(5);
    const [totalRacesCount,setTotalRacesCount] = useState(5);

    const bot_pilots = ['https://media.exiledracers.com/assets/bots/PILOT_AUGMENT_BOT.png',
    'https://media.exiledracers.com/assets/bots/PILOT_MERCENARY_BOT.png',
    'https://media.exiledracers.com/assets/bots/PILOT_SERF_BOT.png'];

    function grabOfficialCollectionList() {
        var addData = new Object();
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('sportsbook/race_collection_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                collectionList = result.data; 
                var recent_collection_id = collectionList[0]['race_group_collection_id'];
                setCurrentLeaderboardCollection(recent_collection_id);
                setCollectionSelect();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function setCurrentLeaderboardCollection(collectionid) {
        current_race_collection = collectionid;
        for(var i = 0; i < collectionList.length;i++) {
            if(collectionid == collectionList[i]['race_group_collection_id']) {
                var collection_title = collectionList[i]['race_collection_name'];
                setLeaderboardCollectionTitle(collection_title);
                setTotalPicksCount(collectionList[i]['picks_count']);
                setTotalRacesCount(collectionList[i]['races_count']);
            }
        }
        setRacerSectionHTML('');
        setProphetSectionHTML('');
        setShowMoreRacersHTML('');
        setShowMoreProphetsHTML('');
        showLeaderboardSummary();
        grabCurrentCollectionLeaderboards(collectionid);
    }

    function grabCurrentCollectionLeaderboards(collectionid) {
        var addData = new Object();
        addData.collectionID = encodeURIComponent(collectionid);

        axiosInstance.post('leaderboard/collection_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                leaderboardList = result.data; 
                $("#base-leaderboard-box").hide();
                $("#custom-leaderboard-box").show();
                showCustomLeaderboards();
            } else {
                leaderboardList = [];
                $("#base-leaderboard-box").show();
                $("#custom-leaderboard-box").hide();
                grabRacerLeaderboard(racerTimeSeries,0);
                grabProphetLeaderboard(prophetTimeSeries,0);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function showCustomLeaderboards() {
        for(var j = 0; j < leaderboardList.length;j++) {
            grabCustomLeaderboard(j,racerTimeSeries,0);
        }
    }

    function setCollectionSelect() {
        var current_collection_id = current_race_collection;
        var collection_list = collectionList;
        var tmp_select_options = [];
        for (var i = 0; i < collection_list.length;i++) {
            var collection_name = collection_list[i]['race_collection_name'];
            var collection_id = collection_list[i]['race_group_collection_id'];
            var tmp_option = <option value={collection_id}>{collection_name}</option>;
            if(current_collection_id == collection_list[i]['race_group_collection_id']) {
                tmp_option = <option value={collection_id} selected>{collection_name}</option>;
            }
            tmp_select_options.push(tmp_option);
        }
        var tmp_select = <select id="race-collection-select" onChange={()=>switchCollections()}>{tmp_select_options}</select>
        var tmp_select2 = <select id="race-collection-select2" onChange={()=>switchCollections2()}>{tmp_select_options}</select>
        setRaceCollectionSelectHTML(tmp_select);
        setRaceCollectionSelectHTMLSmall(tmp_select2);
        setTimeout(function(){
            startPageSelects();
        },500);
    }

    var racer_items = [];
    function grabRacerLeaderboard(timeSort,pagenum) {
        current_racer_pagenum = pagenum;

        if(current_race_collection == 7) {
            current_racer_sort = 'event_1';
        }

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.userID = encodeURIComponent(userID);
        addData.collectionID = encodeURIComponent(current_race_collection);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_racer_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('leaderboard/racers_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data; 
                loadRacerLeaderboard(racer_details,pagenum);
                if(pagenum == 0) {
                    //grabRacerLeaderboardLottery(timeSort,pagenum);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabProphetLeaderboard(timeSort,pagenum) {
        current_prophet_pagenum = pagenum;
        if(current_race_collection == 7) {
            current_prophet_sort = 'event_1';
        }

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.userID = encodeURIComponent(userID);
        addData.collectionID = encodeURIComponent(current_race_collection);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_prophet_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);
        
        axiosInstance.post('leaderboard/prophets_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data; 
                loadProphetLeaderboard(racer_details,pagenum);
                if(pagenum == 0) {
                    //grabProphetLeaderboardLottery(timeSort,pagenum);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabCustomLeaderboard(leaderboardnum,timeSort,pagenum) {
        current_racer_pagenum = pagenum;
        var whitelistid = 0;
        var whitelistexclude = 0;
        if(leaderboardList[leaderboardnum]['leaderboard_whitelisted'] > 0) {
            whitelistid = leaderboardList[leaderboardnum]['race_collection_leaderboard_id'];
        }
        if(leaderboardList[leaderboardnum]['leaderboard_whitelist_exclude'] > 0) {
            whitelistexclude = leaderboardList[leaderboardnum]['leaderboard_whitelist_exclude'];
        }
        if(current_race_collection == 7) {
            current_racer_sort = 'event_1';
        }

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.userID = encodeURIComponent(userID);
        addData.collectionID = encodeURIComponent(current_race_collection);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_racer_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);
        addData.whitelistID = whitelistid;
        addData.whitelistExcludeID = whitelistexclude;

        var final_leaderboard_endpoint = 'leaderboard/racers_list/';
        if(leaderboardList[leaderboardnum]['leaderboard_type'] == 'predictor') {
            final_leaderboard_endpoint = 'leaderboard/prophets_list/';
        }

        

        axiosInstance.post(final_leaderboard_endpoint,addData).then(result => {
            var leaderboard_details = result.data; 
            leaderboardList[leaderboardnum]['current_page'] = pagenum;
            if (pagenum > 0) {
                for(var p = 0; p < leaderboard_details.length;p++) {
                    leaderboardList[leaderboardnum]['leaderboard_data'].push(leaderboard_details[p]);
                }
            } else {
                leaderboardList[leaderboardnum]['leaderboard_data'] = leaderboard_details;
            }
            
            var totalboardsfilled = 0;
            for (var i = 0; i < leaderboardList.length;i++) {
                if('leaderboard_data' in leaderboardList[leaderboardnum]) {
                    totalboardsfilled = totalboardsfilled + 1;
                }
            }
            if (totalboardsfilled == leaderboardList.length) {
                loadCustomLeaderboards();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabRacerLeaderboardLottery(timeSort,pagenum) {
        current_racer_pagenum = pagenum;
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.userID = encodeURIComponent(userID);
        addData.collectionID = encodeURIComponent(current_race_collection);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_racer_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);
        addData.outputType = 'lottery_entry';

        axiosInstance.post('leaderboard/racers_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data; 
                console.log(racer_details);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabProphetLeaderboardLottery(timeSort,pagenum) {
        current_prophet_pagenum = pagenum;
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.userID = encodeURIComponent(userID);
        addData.collectionID = encodeURIComponent(current_race_collection);
        addData.time = timeSort;
        addData.page = pagenum;
        addData.sortBy = current_prophet_sort;
        addData.rcount = total_page_results;
        addData.raceHost = encodeURIComponent(window.location.host);
        addData.outputType = 'lottery_entry';
        
        axiosInstance.post('leaderboard/prophets_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data; 
                console.log(racer_details);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function updateRacerLeaderBoardTime(newtime) {
        current_racer_timeseries = newtime;
        setRacerTimeSeries(newtime);
        window.$(".racer-board-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#racer-lb-time-"+newtime).removeClass("white-green-link").addClass("white-green-link-active");
        grabRacerLeaderboard(newtime,0);
    }

    function updateProphetBoardTime(newtime) {
        current_prophet_timeseries = newtime;
        setProphetTimeSeries(newtime);
        window.$(".prophet-board-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#prophet-lb-time-"+newtime).removeClass("white-green-link").addClass("white-green-link-active");
        grabProphetLeaderboard(newtime,0);
    }

    function updateRacerLeaderBoardSort(newsort) {
        current_racer_sort = newsort;
        window.$(".racer-row-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-green-link");
        });
        window.$("#racer-lb-sort-"+newsort).removeClass("white-green-link").addClass("white-green-link-active");
        grabRacerLeaderboard(racerTimeSeries,0);
    }

    function updateProphetLeaderBoardSort(newsort) {
        current_prophet_sort = newsort;
        window.$(".prophet-row-sort-link").each(function() {
            window.$(this).removeClass("white-green-link-active").addClass("white-red-link");
        });
        window.$("#prophet-lb-sort-"+newsort).removeClass("white-green-link").addClass("white-green-link-active");
        grabProphetLeaderboard(prophetTimeSeries,0);
    }

    function showMoreResultsRacers(pagenum) {
        $("#showmoreracersbutton").hide();
        $("#showmoreracersloading").show();
        grabRacerLeaderboard(current_racer_timeseries,pagenum);
    }

    function showMoreResultsProphet(pagenum) {
        $("#showmoreprophetsbutton").hide();
        $("#showmoreprophetsloading").show();
        grabProphetLeaderboard(current_prophet_timeseries,pagenum);
    }

    function showMoreResultsCustom(e) {
        e.preventDefault();

        let race_elem = e.target;
        let boardnum = race_elem.dataset.boardnum; 
        let newpagenum = race_elem.dataset.pagenum;

        $("#showmorecustombutton"+boardnum).hide();
        $("#showmorecustomloading"+boardnum).show();
        grabCustomLeaderboard(boardnum,racerTimeSeries,newpagenum);
    }

    function openInviteModal() {
        var invitebox = document.getElementById('invite-single-box');
        var invite_instance = window.M.Modal.getInstance(invitebox);
        invite_instance.open();
    }

    function inviteToRaceSingle(e) {
        var racer_name = e.target.dataset.invitename;
        window.startPageModals();
        setInviteRacerName(racer_name);
        window.$("#invitesinglesubmit").click(function() {
            window.location.href="/#/race_landing/invite/"+encodeURIComponent(racer_name);
        });
        openInviteModal();
    }

    function loadRacerLeaderboard(racearr,pagenum) {
        var ranking_section_html = [];
        var pilotcnt = 0;
        for(var z = 0; z < racearr.length;z++) {
            var racer_id = racearr[z].racer_id;
            var racer_unique_id = racearr[z].racer_unique_id;
            var racer_name = racearr[z].racer_name;
            var racer_coins = racearr[z].racer_coins;
            var racer_xp = racearr[z].racer_xp;
            var racer_photo = racearr[z].racer_photo;

            var leaderboard_winner_type = racearr[z].leaderboard_winner_type;

            /* Calculate Race Time */
            var total_races = racearr[z].total_races;
            var total_wins = racearr[z].total_wins;
            var win_pct = Math.round((total_wins / total_races)*100);

            var star_icon = '';
            var rowcolor = 'white-text';
            var rowlinkcolor = 'white-green-link';
            var rowbgcolor = '';
            var invite_icon = <i id={"invite-"+racer_name} className="material-icons topnavtoggle race-board-row-launch-icon">launch</i>
            if(racer_id == racerID) {
                //star_icon = <i className="material-icons exr-red-text">star</i>
                rowcolor = 'exr-light-green-text';
                rowbgcolor = 'exr-red-bg';
                rowlinkcolor = 'exr-light-green-text';
                invite_icon = '';
            } 
            if(racer_coins < 25) {
                invite_icon = '';
            }
            
            var pilot_image = <div className="exr-stats-light-grey" data-invitename={racer_name} style={{width:'50px',height:'50px',borderRadius:'5px',marginBottom:'7px'}}></div>;
            if(racer_photo !== null) {
                pilot_image = <img src={racer_photo} width="50px" data-invitename={racer_name} style={{borderRadius:"5px",lineHeight:'1'}} />;
            }

            var showbattles = 'Y';
            var invite_name_button = <><div className="left" style={{marginRight:"10px",width:"50px"}}>
                                            {pilot_image}
                                        </div>
                                        <div className="left" style={{marginTop:"12px"}}>
                                        {racer_name}
                                        </div></>
            if(racer_id == racerID) {
                showbattles = 'N';
            }
            if(showbattles == 'Y') {
                invite_name_button = <><div className={"left "+rowlinkcolor} title="Invite To Race" 
                                            style={{marginRight:"10px",width:"50px"}} onClick={(e)=>inviteToRaceSingle(e)}>
                                        {pilot_image}
                                    </div>
                                    <div className={"left "+rowlinkcolor} title="Invite To Race" data-invitename={racer_name} 
                                        style={{marginTop:"12px"}} onClick={(e)=>inviteToRaceSingle(e)}>
                                        {racer_name}
                                    </div></>
            }

            var leaderboardcolor = '';
            if(leaderboard_winner_type == 'top_racer') {
                leaderboardcolor = ' exr-new-light-grey';
                rowcolor = 'exr-light-green-text';
            } else if(leaderboard_winner_type == 'random_draw') {
                leaderboardcolor = ' exr-new-light-grey';
                rowcolor = 'exr-yellow-text';
            }
            
            var rowkey = z+1+(pagenum*total_page_results);
            var tmp_html = <div key={rowkey} className={"row race-board-row"} id={'leader_row_'+racer_id} style={{cursor:'auto',marginBottom:'0px',paddingBottom:'0px'}}>
                            <div className={"row "+rowcolor} style={{marginTop:'0px',marginBottom:'0px',fontSize:'16px',fontWeight:'900',paddingTop:'7px'}}>
                                <div className="col s6" style={{overflow:'hidden'}}>
                                    <div className="left" style={{width:'200%'}}>
                                        <div className="left headline-text center-align" style={{fontSize:"16px",marginRight:"10px",letterSpacing:"-1px",width:"32px",marginTop:"15px"}}>
                                            {rowkey}
                                        </div>
                                        {invite_name_button}
                                    </div>
                                </div>
                                <div className="col s2 center-align" title="RPM Awarded" style={{marginTop:"12px"}}>
                                    {racer_coins.toLocaleString("en-US")}
                                </div>
                                <div className="col s2 center-align" title="XP Earned" style={{marginTop:"12px"}}>
                                    {racer_xp.toLocaleString("en-US")}
                                </div>
                                <div className="col s2 center-align" style={{marginTop:"12px"}}>
                                    {total_wins}
                                </div>
                            </div>
                        </div>;
            ranking_section_html.push(tmp_html);
            
        }

        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = racerSectionHTMLArray.concat(ranking_section_html);
        } else {
            final_section_html_array = ranking_section_html;
        }
        racerSectionHTMLArray = final_section_html_array;
        setRacerSectionHTML(final_section_html_array);

        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(ranking_section_html.length == total_page_results) {
            showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                <div id="showmoreracersbutton" className="center-align" style={{display:'table',margin:'0 auto'}} title="Show More Racers">
                                    <span className="round-border-button" style={{fontSize:'16px'}} title="Show More Racers"  onClick={() => showMoreResultsRacers(nextpage) }>
                                        SHOW MORE RACERS
                                    </span>
                                </div>
                                <div id="showmoreracersloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreRacersHTML(showmorebutton);
        
        setTimeout(function(){
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },500);
        setTimeout(function(){
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },2000);
    }

    function loadProphetLeaderboard(racearr,pagenum) {
        
        

        var ranking_section_html = [];
        var pilotcnt = 0;
        for(var z = 0; z < racearr.length;z++) {
            var racer_id = racearr[z].racer_id;
            var racer_unique_id = racearr[z].racer_unique_id;
            var racer_name = racearr[z].racer_name;
            var total_rpm = racearr[z].rpm_earned;
            var total_xp = racearr[z].xp_earned;
            var racer_photo = racearr[z].racer_photo;

            /* Calculate Race Time */
            var total_predicted = racearr[z].total_predictions;
            var total_correct = racearr[z].total_predictions_correct;
            var correct_pct = Math.round((total_correct / total_predicted)*100);

            var star_icon = '';
            var rowcolor = 'white-text';
            var rowbgcolor = '';
            if(racer_id == racerID) {
                //star_icon = <i className="material-icons exr-red-text">star</i>
                rowcolor = 'exr-light-green-text';
                rowbgcolor = 'exr-red-bg';
            } 

            if((racer_photo == '')||(racer_photo == null)) {
                racer_photo = bot_pilots[Math.floor(Math.random(3))];
            }

            var pilot_image = <div className="exr-stats-light-grey" style={{width:'50px',height:'50px',borderRadius:'5px',marginBottom:'7px'}}></div>;
            if(racer_photo !== null) {
                pilot_image = <img src={racer_photo} width="50px" style={{borderRadius:"5px",lineHeight:'1'}} />;
            }
            
            var rowkey = z+1+(pagenum*total_page_results);
            var tmp_html = <div key={rowkey} className="row race-board-row" id={'leader_row_'+racer_id} style={{cursor:'auto',marginBottom:'0px',paddingTop:'7px',paddingBottom:'0px'}}>
                            <div className={"row "+rowcolor} style={{marginTop:'0px',marginBottom:'0px',fontSize:'16px'}}>
                                <div className="col s6" style={{overflow:'hidden'}}>
                                    <div className="left" style={{width:'200%'}}>
                                        <div className="left headline-text center-align" style={{fontSize:"16px",marginRight:"10px",letterSpacing:"-1px",width:"32px",marginTop:"15px",fontWeight:'900'}}>
                                            {rowkey}
                                        </div>
                                        <div className="left" style={{marginRight:"10px",width:"50px"}}>
                                            {pilot_image}
                                        </div>
                                        <div className="left" style={{marginTop:"12px"}}>
                                            {racer_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="col s2 center-align" title="XP Earned" style={{marginTop:"12px"}}>
                                    {total_xp.toLocaleString("en-US")}
                                </div>
                                <div className="col s2 center-align" style={{marginTop:"12px"}}>
                                    {total_correct}
                                </div>
                                <div className="col s2 center-align" style={{marginTop:"12px"}}>
                                    {correct_pct+'%'}
                                </div>
                            </div>
                        </div>;
            ranking_section_html.push(tmp_html);
            
        }

        var final_section_html_array = [];
        if(pagenum > 0) {
            final_section_html_array = prophetSectionHTMLArray.concat(ranking_section_html);
        } else {
            final_section_html_array = ranking_section_html;
        }
        prophetSectionHTMLArray = final_section_html_array;
        setProphetSectionHTML(final_section_html_array);
        
        var nextpage = pagenum+1;
        var showmorebutton = <></>;
        if(racearr.length >= total_page_results) {
            showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                <div id="showmoreprophetsbutton" className="center-align" style={{display:'table',margin:'0 auto'}} title="Show More Prophets">
                                    <span className="round-border-button" style={{fontSize:'16px'}} title="Show More Prophets"  onClick={() => showMoreResultsProphet(nextpage) }>
                                        SHOW MORE PROPHETS
                                    </span>
                                </div>
                                <div id="showmoreprophetsloading" className="preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                    <div className="spinner-layer spinner-red-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
        }
        setShowMoreProphetsHTML(showmorebutton);
        
        setTimeout(function(){
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },500);
        setTimeout(function(){
            $("#showmoreracersbutton").show();
            $("#showmoreracersloading").hide();
            $("#showmoreprophetsbutton").show();
            $("#showmoreprophetsloading").hide();
        },2000);
    }

    function loadCustomLeaderboards() {
        var custom_section_html = [];
        for(var m = 0; m < leaderboardList.length;m++) {
            var pilotcnt = 0;
            var boarddata = leaderboardList[m]['leaderboard_data'];
            var boardtype = leaderboardList[m]['leaderboard_type'];
            var rpmtracked = leaderboardList[m]['rpm_tracked'];
            var xptracked = leaderboardList[m]['xp_tracked'];
            var rpmmultiplier = leaderboardList[m]['rpm_multiplier'];
            var xpmulitplier = leaderboardList[m]['xp_multiplier'];
            var maxraces = leaderboardList[m]['total_races_max'];
            var countedraces = leaderboardList[m]['total_races_count'];
            var pagenum = parseInt(leaderboardList[m]['current_page']);

            var ranking_section_html = [];
            for(var z = 0; z < boarddata.length;z++) {
                var board_user_id = boarddata[z].user_id;
                var board_racer_id = boarddata[z].racer_id;
                var board_racer_name = boarddata[z].racer_name;
                var total_rpm = boarddata[z].rpm_earned;
                var total_xp = boarddata[z].xp_earned;
                var racer_photo = boarddata[z].racer_photo;

                /* Racer Specific */
                var racer_coins = 0;
                var racer_xp = 0
                var leaderboard_winner_type = '';
                var total_races = 0;
                var total_wins = 0;
                var win_pct = 0;
                if(boardtype == 'racer') {
                    racer_coins = boarddata[z].racer_coins;
                    racer_xp = boarddata[z].racer_xp;
                    racer_photo = boarddata[z].racer_photo;
                    leaderboard_winner_type = boarddata[z].leaderboard_winner_type;
                    total_races = boarddata[z].total_races;
                    total_wins = boarddata[z].total_wins;
                    win_pct = Math.round((total_wins / total_races)*100);
                }

                /* Predictor Specific */
                var total_predicted = 0;
                var total_correct = 0;
                var correct_pct = 0;
                if(boardtype == 'predictor') {
                    total_predicted = boarddata[z].total_predictions;
                    total_correct = boarddata[z].total_predictions_correct;
                    correct_pct = Math.round((total_correct / total_predicted)*100);
                }
                
                var rowcolor = 'white-text';
                var rowbgcolor = '';
                if(board_racer_id == racerID) {
                    rowcolor = 'exr-light-green-text';
                    rowbgcolor = 'exr-red-bg';
                } 

                if((racer_photo == '')||(racer_photo == null)) {
                    racer_photo = bot_pilots[Math.floor(Math.random(3))];
                }

                var pilot_image = <div className="exr-stats-light-grey" style={{width:'50px',height:'50px',borderRadius:'5px',marginBottom:'7px'}}></div>;
                if(racer_photo !== null) {
                    pilot_image = <img src={racer_photo} width="50px" style={{borderRadius:"5px",lineHeight:'1'}} />;
                }

                var statshtml = <><div className="col s2 center-align" title="RPM Awarded" style={{marginTop:"12px"}}>
                                        {racer_coins.toLocaleString("en-US")}
                                    </div>
                                    <div className="col s2 center-align" title="XP Earned" style={{marginTop:"12px"}}>
                                        {racer_xp.toLocaleString("en-US")}
                                    </div>
                                    <div className="col s2 center-align" style={{marginTop:"12px"}}>
                                        {total_wins}
                                    </div></>;
                if(boardtype == 'predictor') {
                    statshtml = <><div className="col s2 center-align" title="XP Earned" style={{marginTop:"12px"}}>
                                    {total_xp.toLocaleString("en-US")}
                                </div>
                                <div className="col s2 center-align" style={{marginTop:"12px"}}>
                                    {total_correct}
                                </div>
                                <div className="col s2 center-align" style={{marginTop:"12px"}}>
                                    {correct_pct+'%'}
                                </div></>
                }

                var boardsubtitlepx2 = "16px";
                if(leaderboardList.length == 3) {
                    boardsubtitlepx2 = "16px";
                }

                
                var rowkey = z+1+'board'+m;
                var rownum = z+1;
                var tmp_html = <div key={rowkey} className="row race-board-row" id={'leader_row_'+board_racer_id} style={{cursor:'auto',marginBottom:'0px',paddingTop:'7px',paddingBottom:'0px'}}>
                                <div className={"row "+rowcolor} style={{marginTop:'0px',marginBottom:'0px',fontSize:boardsubtitlepx2}}>
                                    <div className="col s6" style={{overflow:'hidden'}}>
                                        <div className="left" style={{width:'200%'}}>
                                            <div className="left headline-text center-align" style={{fontSize:boardsubtitlepx2,marginRight:"10px",letterSpacing:"-1px",width:"32px",marginTop:"15px",fontWeight:'900'}}>
                                                {rownum}
                                            </div>
                                            <div className="left" style={{marginRight:"10px",width:"50px"}}>
                                                {pilot_image}
                                            </div>
                                            <div className="left" style={{marginTop:"12px"}}>
                                                {board_racer_name}
                                            </div>
                                        </div>
                                    </div>
                                    {statshtml}
                                </div>
                            </div>;
                ranking_section_html.push(tmp_html);
                
            }
            var final_section_html_array = [];
            final_section_html_array = ranking_section_html;
            // if(pagenum > 0) {
            //     final_section_html_array = leaderboardList[m]['leaderboard_html'].concat(ranking_section_html);
            // } else {
            //     final_section_html_array = ranking_section_html;
            // }
            leaderboardList[m]['leaderboard_html'] = final_section_html_array;

            var boardcols = "col s12 l6";
            var boardtitlepx = "16px";
            var boardsubtitlepx = "14px";
            if(leaderboardList.length == 3) {
                boardcols = "col s12 l4";
                boardtitlepx = "14px";
                boardsubtitlepx = "12px";
            }

            var boardmax = <>TOP {countedraces} RACES</>;
            var boardfilters = <><div className="col s2 headline-text white-green-link-active racer-row-sort-link center-align" title="Sort by Most RPM" 
                                        onClick={()=>updateRacerLeaderBoardSort('rpm')} id="racer-lb-sort-rpm" 
                                        style={{fontSize:boardsubtitlepx}}>
                                        RPM
                                    </div>
                                    <div className="col s2 headline-text white-green-link racer-row-sort-link center-align" title="Sort by Most XP" 
                                        onClick={()=>updateRacerLeaderBoardSort('xp')} id="racer-lb-sort-xp" 
                                        style={{fontSize:boardsubtitlepx}}>
                                        XP
                                    </div>
                                    <div className="col s2 headline-text white-green-link racer-row-sort-link center-align" title="Sort by Most Wins" 
                                        onClick={()=>updateRacerLeaderBoardSort('wins')} id="racer-lb-sort-wins" 
                                        style={{fontSize:boardsubtitlepx}}>
                                        WINS
                                    </div></>;
            if(boardtype == 'predictor') {
                boardmax = <>TOP {countedraces} PICKS</>;
                boardfilters = <><div className="col s2 headline-text white-green-link-active prophet-row-sort-link center-align" title="Sort by Most XP" 
                                        onClick={()=>updateProphetLeaderBoardSort('xp')} id="prophet-lb-sort-xp" 
                                        style={{fontSize:boardsubtitlepx}}>
                                        XP
                                    </div>
                                    <div className="col s2 headline-text white-green-link prophet-row-sort-link center-align" title="Sort by Most Correct Picks" 
                                        onClick={()=>updateProphetLeaderBoardSort('wins')} id="prophet-lb-sort-wins" 
                                        style={{fontSize:boardsubtitlepx}}>
                                        WINS
                                    </div>
                                    <div className="col s2 headline-text white-green-link prophet-row-sort-link center-align" title="Sort by Highest Percent Correct" 
                                        onClick={()=>updateProphetLeaderBoardSort('win_pct')} id="prophet-lb-sort-win_pct" 
                                        style={{fontSize:boardsubtitlepx}}>
                                        PCT
                                    </div></>;
            }


            var nextpage = pagenum+1;
            var showmorebutton = <></>;
            if(boarddata.length >= total_page_results) {
                showmorebutton = <div className="row white-text" style={{fontSize:'14px',marginTop:'30px',marginBottom:'5px'}}>
                                    <div id={"showmorecustombutton"+m} className="customshowmorebutton center-align" style={{display:'table',margin:'0 auto'}} title="Show More Prophets">
                                        <span className="round-border-button" style={{fontSize:'16px'}} title="Show More Prophets"  
                                            data-boardnum={m} 
                                            data-pagenum={nextpage}
                                            onClick={(e) => showMoreResultsCustom(e) }>
                                            SHOW MORE
                                        </span>
                                    </div>
                                    <div id={"showmorecustomloading"+m} className="customshowmoreloading preloader-wrapper small active" style={{left:'49%',marginTop: '-8px',display:'none'}}>
                                        <div className="spinner-layer spinner-red-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>;
            }

            var final_board_html = <div className={boardcols} style={{padding:'5px'}}>
                        <div className="row exr-new-dark-grey" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px',minHeight:'100vh'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div className="col s8 m8 headline-text white-text left-align" style={{marginTop:'0px',fontSize:boardtitlepx}}>
                                    {leaderboardList[m]['leaderboard_name'].toUpperCase()}
                                </div>
                                <div className="col s4 m4 white-text right-align  exr-yellow-text" style={{fontSize:'14px'}} title={"Top "+totalPicksCount+" pick results count towards the leaderboard"}>
                                    {boardmax}
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            <div className="row" style={{marginBottom:'5px',marginTop:'5px',fontSize:'14px'}}>
                                <div className="col s1 headline-text white-text">
                                    &nbsp;&nbsp;#
                                </div>
                                <div className="col s5 headline-text white-text" style={{fontSize:boardsubtitlepx}}>
                                    {boardtype.toUpperCase()}
                                </div>
                                {boardfilters}
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            {final_section_html_array}
                            <div className="col s12">
                                {showmorebutton}
                            </div>
                        </div>
                    </div>;

            custom_section_html.push(final_board_html);
        }

        
        setCustomLeaderboardHTML(custom_section_html);
        
        setTimeout(function(){
            $(".customshowmorebutton").each(function(){
                $(this).show();
            });
            $(".customshowmoreloading").each(function(){
                $(this).hide();
            });
        },500);
        setTimeout(function(){
            $(".customshowmorebutton").each(function(){
                $(this).show();
            });
            $(".customshowmoreloading").each(function(){
                $(this).hide();
            });
        },2000);
    }

    function showLeaderboardSummary() {
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.collectionID = encodeURIComponent(current_race_collection);
        addData.raceHost = encodeURIComponent(window.location.host);
        
        axiosInstance.post('leaderboard/collection_summary/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data; 

                var user_referrals = racer_details[0]['user_referrals'];
                if(user_referrals > 3) {
                    user_referrals = 3;
                }

                var race_rpm = racer_details[0]['racer_rpm'];
                var race_xp = racer_details[0]['racer_xp'];
                var racer_race_points = 5*race_rpm + race_xp;


                var total_race_rpm = racer_details[0]['total_racer_rpm'];
                var total_race_xp = racer_details[0]['total_racer_xp'];
                var total_race_points = 5*total_race_rpm + total_race_xp;

                setUserGamePoints(racer_race_points);
                setUserProphetPoints(racer_details[0]['prophet_xp']);

                setTotalGamePoints(racer_details[0]['total_racer_rpm']);
                setTotalProphetPoints(racer_details[0]['total_racer_rpm']);
                
                setReferralPoints((user_referrals*15));
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function switchCollections() {
        var new_collection_id = $("select[id=race-collection-select]").val();
        setCurrentLeaderboardCollection(new_collection_id);
    }
    function switchCollections2() {
        var new_collection_id = $("select[id=race-collection-select2]").val();
        setCurrentLeaderboardCollection(new_collection_id);
    }

    function startPageSelects() {
        var select_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(select_elems,{classes:'exr-select'});
    }

    function showLeaderboardInfobox() {
        var infobox = document.getElementById('training_box_leaderboard');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    window.$(document).ready(function(){
        var sel_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(sel_elems);
    });

    if(entrySet == false) {
        setEntry(true);
        grabOfficialCollectionList();
    }

    function goToWorkshop() {
        window.location.href= '/#/workshop';
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row white-text page-width-wide top-nav-header-full">
            <div className="row" style={{margin:'0px',borderRadius:'10px',padding:'0px 0px 5px 0px',marginBottom:'0px'}}>
                <div className="col s12 exr-new-dark-grey" style={{position:'relative',fontSize:'30px',padding:'10px 0px',paddingTop:'0px',borderRadius:'10px',marginBottom:'0px'}}>
                    <div className="row" style={{margin:'0px'}}>
                        <div className="col s12 center-align" style={{margin:'0px',padding:'5px 30px'}}>
                            
                            <span className="left exr-light-green-text hide-on-med-and-down show-on-large-only" style={{width:'100%',fontSize:"16px",marginTop:'5px'}}>{leaderboard_collection_title}</span>
                            <span className="left headline-text hide-on-med-and-down show-on-large-only" style={{width:'100%',fontSize:"24px",marginTop:'0px'}}>LEADERBOARDS</span>

                            <div className="left headline-text hide-on-large-only show-on-medium-and-down" style={{width:'100%',fontSize:"24px",marginTop:'10px'}}>LEADERBOARDS</div>
                            <div id="race-collection-select-small" className="left hide-on-large-only show-on-medium-and-down" style={{width:'60%',minWidth:'250px',marginLeft:'20%',marginTop:'0px',marginBottom:'20px'}}>
                                {race_collection_select_html_small}
                            </div>
                        </div>
                        <div className="col s12" style={{margin:'0px',padding:'0px 10px'}}>
                            <div className="row exr-new-light-grey" style={{margin:'0px',padding:'10px',borderRadius:'10px',fontSize:'14px'}}>
                                <div className="col s12 m4 center-align" style={{margin:'0px',padding:'5px 10px'}}>
                                    RACER REWARDS: {user_game_points} POINTS
                                </div>
                                <div className="col s12 m4 center-align" style={{margin:'0px',padding:'5px 10px'}}>
                                    PROPHET REWARDS: {user_prophet_points} POINTS
                                </div>
                                <div className="col s12 m4 center-align" style={{margin:'0px',padding:'5px 10px'}}>
                                    REFERRAL REWARDS: {user_referral_points} XP BONUS 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="race-collection-select"  className="row hide-on-med-and-down show-on-large-only" style={{position:'absolute',right:'80px',top:'13px',width:'250px'}}>
                        {race_collection_select_html}
                    </div>
                    
                    <span className="material-icons material-symbols-outlined white-red-link" 
                    style={{position:'absolute',right:'20px',top:'10px',fontSize:"30px",marginLeft:"10px",marginTop:'4px'}} 
                    onClick={()=>showLeaderboardInfobox()} 
                    title="Learn About The EXR Leaderboards">info</span>
                </div>
            </div>
            <div className="col s12">
                <div id="custom-leaderboard-box" className="row" style={{display:'none',marginTop:'0px',marginBottom:'0px',fontSize:'16px'}}>
                    {customLeaderboardHTML}
                </div>
                <div id="base-leaderboard-box" className="row" style={{marginTop:'0px',marginBottom:'0px',fontSize:'16px'}}>
                    <div className="col s12 l6" style={{padding:'5px'}}>
                        <div className="row exr-new-dark-grey" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px',minHeight:'70vh'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div className="col s6 m6 headline-text white-text left-align" style={{marginTop:'0px'}}>
                                    TOP RACERS
                                </div>
                                <div className="col s6 m6 white-text right-align exr-yellow-text" style={{fontSize:'14px'}} title={"Top "+totalRacesCount+" race results count towards the leaderboard"}>
                                    TOP {totalRacesCount} RACES
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            <div className="row" style={{marginBottom:'5px',marginTop:'5px',fontSize:'14px'}}>
                                <div className="col s1 headline-text white-text center-align">
                                    #
                                </div>
                                <div className="col s5 headline-text white-text">
                                    RACER
                                </div>
                                <div className="col s2 headline-text white-green-link-active racer-row-sort-link center-align" title="Sort by Most RPM" 
                                    onClick={()=>updateRacerLeaderBoardSort('rpm')} id="racer-lb-sort-rpm" >
                                    RPM
                                </div>
                                <div className="col s2 headline-text white-green-link racer-row-sort-link center-align" title="Sort by Most XP" 
                                    onClick={()=>updateRacerLeaderBoardSort('xp')} id="racer-lb-sort-xp" >
                                    XP
                                </div>
                                <div className="col s2 headline-text white-green-link racer-row-sort-link center-align" title="Sort by Most Wins" 
                                    onClick={()=>updateRacerLeaderBoardSort('wins')} id="racer-lb-sort-wins" >
                                    WINS
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            {racersSectionHTML}
                            <div className="col s12">
                                {showMoreRacersHTML}
                            </div>
                        </div>
                    </div>
                    <div className="col s12 l6" style={{padding:'5px'}}>
                        <div className="row  exr-new-dark-grey" style={{margin:'0px',marginBottom:'10px',padding:'20px',borderRadius:'10px',minHeight:'70vh'}}>
                            <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                <div className="col s8 m6 headline-text white-text left-align" style={{marginTop:'0px'}}>
                                    TOP PROPHETS
                                </div>
                                <div className="col s4 m6 white-text right-align  exr-yellow-text" style={{fontSize:'14px'}} title={"Top "+totalPicksCount+" pick results count towards the leaderboard"}>
                                    TOP {totalPicksCount} PICKS
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            <div className="row" style={{marginBottom:'5px',marginTop:'5px',fontSize:'14px'}}>
                                <div className="col s1 headline-text white-text">
                                    &nbsp;&nbsp;#
                                </div>
                                <div className="col s5 headline-text white-text">
                                    PROPHET
                                </div>
                                <div className="col s2 headline-text white-green-link-active prophet-row-sort-link center-align" title="Sort by Most XP" 
                                    onClick={()=>updateProphetLeaderBoardSort('xp')} id="prophet-lb-sort-xp">
                                    XP
                                </div>
                                <div className="col s2 headline-text white-green-link prophet-row-sort-link center-align" title="Sort by Most Correct Picks" 
                                    onClick={()=>updateProphetLeaderBoardSort('wins')} id="prophet-lb-sort-wins">
                                    WINS
                                </div>
                                <div className="col s2 headline-text white-green-link prophet-row-sort-link center-align" title="Sort by Highest Percent Correct" 
                                    onClick={()=>updateProphetLeaderBoardSort('win_pct')} id="prophet-lb-sort-win_pct">
                                    PCT
                                </div>
                            </div>
                            <div className="section-title-underline" style={{marginTop:'-5px',marginBottom:'0px',height:'0px'}}></div>
                            {prophetSectionHTML}
                            <div className="col s12">
                                {showMoreProphetsHTML}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="training_box_leaderboard" className="modal page-modal page-modal-large">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div className="round-close-button" style={{right:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                        X
                    </div>
                    <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'5px'}}>
                        LEADERBOARDS
                        </div>
                    </div>
                    <div className="col s12" style={{padding:"0px 5px"}}>
                        <div className="divider-thin"></div>
                    </div>
                    <div className="col s12 subheadline-text" style={{marginTop:'20px',fontSize:'16px'}}>
                            Welcome to the EXR Leaderboards! Here you can find the top Racers by RPM and XP as well as the top Prophets correctly predicting races. 
                            <br/><br/><a className="exr-light-green-text">Training Rewards: </a> 
                            Your RPM and XP are your tickets to win rewards in training. 
                            The more RPM and XP you collect by the end of training the higher likelihood you have to win our training rewards game.
                            <br/><br/><a className="exr-light-green-text">Starblazer Badge: </a> 
                            The racers with the most RPM collected at the close of the EXR Training phase will earn the badge of Starblazer and have access to exclusive prizes. 
                            <br/><br/><a className="exr-light-green-text">Leaderboard Tiers: </a> 
                            Where you finish on the leaderboard may or may not determine which <a style={{color:'silver'}}>[REDACTED]</a> you are placed in once the EXR Training Phase is over and <a style={{color:'silver'}}>[REDACTED]</a> starts. 
                            <br/><br/><a className="exr-light-green-text">Prophet Rewards: </a> 
                            The Prophet status is one to be admired. Collect XP for correctly predicting races which give you a higher chance to get Prophet rewards. Top Prophets will earn badges for bragging rights.
                            <br/><br/>
                        </div>
                </div>
            </div>
        </div>
    </div>);
};