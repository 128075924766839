import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../RaceLanding/race_landing.css";
import "./pub_race_landing.css";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals} from "../../../utils";
import {TrackSelectionSection, StackSelectionSection, ResultsViewSection, LineupViewSection, SettingsSelectionSection} from "./Sections";
import $ from 'jquery';
import { StackInfoBox, RacePredictHowToBox } from "./Infoboxes";
import {prize_tier_tooltip,training_tier_tooltip,prize_wta_tooltip} from "./race_landing_tooltips";
import { toInteger } from "lodash";

let web3;
let racer_sim_watched_global = 0;
let race_simulated_global = 0;
let current_racer_global = 0;
let racer_invite_status_global = 0;
let race_hash_global = '';
let open_invite_race = 0;
let open_entry_closed = 0;
let predictions_closed = 0;
let entryDisplay = 'none';
let training_box_race_landing = 0;
let current_sort = 'recent';
let current_filter = 'groupid_6';
let current_race_group_id = 6;

export const PubRaceLanding = () => {
    const dispatch = useDispatch();
    const { userID, racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    
    if((userID.length == 0)||(userID == 0)) {
        dispatch(setRacerLogin('',''));
        dispatch(updateLocation("splash"));
        window.location.href= '/';
    }

    var url_race_unique_id = '';
    var url_race_invite_list = '';
    var start_race_section = '';
    var tmp_left_nav_section = 'upcoming';
    var racehash = window.location.href.split("#/");
    if(racehash.length > 1) {
        var racehash2 = racehash[1].replace("#/",'');
        var racehash3 = racehash2.split("/");
        if(racehash3.length > 1) {
            url_race_unique_id = racehash3[1].replace("/",'');
        }
        if((racehash3.length > 2)&&(url_race_unique_id == 'invite')) {
            url_race_invite_list = racehash3[2].replace("/",'');
        }
        if(racehash3[0] == 'race') {
            start_race_section = 'details';
        } else {
            var racehash4 = racehash3[0].split("_");
            start_race_section = racehash4[1];
        }
    }
    race_hash_global = url_race_unique_id;

    var current_training_left = $("#current-racer-traces").val();
    var current_battles_left = $("#current-racer-iraces").val();

    const [pageSet, setPage] = useState(false);
    const [headerSet, setHeader] = useState(false);
    const [race_id, setRaceID] = useState(0);
    const [final_race_id, setFinalRaceID] = useState('');
    const [race_unique_id, setRaceUniqueID] = useState(url_race_unique_id);
    const [race_unique_id_url, setRaceUniqueIDURL] = useState(url_race_unique_id);
    const [race_invite_list, setRaceInviteList] = useState(url_race_invite_list);
    const [race_invite_details, setRaceInviteDetails] = useState([]);
    const [race_details, setRaceDetails] = useState([]);
    const [racer_details, setRacerDetails] = useState([]);
    const [racer_status, setRacerStatus] = useState({});
    const [racer_creator_id, setRacerCreator] = useState(0);
    const [racer_invite_status, setRacerInviteStatus] = useState('N');
    const [racer_entry_status, setRacerEntryStatus] = useState('N');
    const [race_simulated, setRaceSimStatus] = useState('N');
    const [racer_sim_watched, setRacerSimWatched] = useState('N');
    const [current_race_section, setCurrentSection] = useState(start_race_section);
    const [race_landing_html, setRaceLandingHTML] = useState('');
    const [race_name, setRaceName] = useState('');
    const [race_game_type, setRaceType] = useState('');
    const [race_entry_close_text, setRaceEntryCloseText] = useState('');
    const [race_predictions_close_text, setRacePredictionCloseText] = useState('');
    const [race_exr, setRaceEXR] = useState(0);
    const [race_xp, setRaceXP] = useState('');
    const [race_entry_text, setRaceEntryText] = useState('');
    const [race_entry_laps, setRaceEntryLaps] = useState('');
    const [racer_count,setRacerCount] = useState('');
    const [raceTime, setRaceTime] = useState('');
    const [race_prize_type,setRacePrizeType] = useState('');
    const [race_prize_dist,setRacePrizeDist] = useState('');
    const [final_tier_tooltip,setFinalTierTooltop] = useState('');
    const [race_top_status,setRaceTopStatus] = useState('STARTS SOON');

    const race_creator_types = ['training','bot-race','daily','alpha','invite'];

    let race_track_id = 1;
    let entryFeeDisplay = 'none';
    

    function refreshNewRace() {
        var url_race_unique_id = '';
        var url_race_invite_list = '';
        var start_race_section = '';
        var racehash = window.location.href.split("#/");
        if(racehash.length > 1) {
            var racehash2 = racehash[1].replace("#/",'');
            var racehash3 = racehash2.split("/");
            if(racehash3.length > 1) {
                url_race_unique_id = racehash3[1].replace("/",'');
            }
            if((racehash3.length > 2)&&(url_race_unique_id == 'invite')) {
                url_race_invite_list = racehash3[2].replace("/",'');
            }
            if(racehash3[0] == 'race') {
                start_race_section = 'details';
            } else {
                var racehash4 = racehash3[0].split("_");
                start_race_section = racehash4[1];
            }
        }
        setRaceUniqueID(url_race_unique_id);
        setRaceUniqueIDURL(url_race_unique_id);
        setRaceInviteList(url_race_invite_list);
        setCurrentSection(start_race_section);

        race_hash_global = url_race_unique_id;
        setTimeout(function(){grabRaceDetails(); $(window).scrollTop(0); },500);
    }

    function refreshRaceGlobalHash() {
        var url_race_unique_id = '';
        var url_race_invite_list = '';
        var start_race_section = '';
        var racehash = window.location.href.split("#/");
        if(racehash.length > 1) {
            var racehash2 = racehash[1].replace("#/",'');
            var racehash3 = racehash2.split("/");
            if(racehash3.length > 1) {
                url_race_unique_id = racehash3[1].replace("/",'');
            }
            if((racehash3.length > 2)&&(url_race_unique_id == 'invite')) {
                url_race_invite_list = racehash3[2].replace("/",'');
            }
            if(racehash3[0] == 'race') {
                start_race_section = 'details';
            } else {
                var racehash4 = racehash3[0].split("_");
                start_race_section = racehash4[1];
            }
        }
        race_hash_global = url_race_unique_id;
    }

    var racer_items = [];
    function grabCurrentPubRace(pagenum) {
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.sortBy = encodeURIComponent(current_sort);
        addData.filterBy = encodeURIComponent(current_filter);
        // addData.raceStatus = 'past';
        addData.raceStatus = 'live_pickem';
        addData.page = pagenum;
        addData.rcount = 20;
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('sportsbook/recent_races/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_race_details = result.data;
                var next_race_hash = new_race_details[0]['race_unique_id'];
                race_hash_global = next_race_hash;
                grabRaceDetails();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    var racer_items = [];
    function grabRaceDetails() {
        if(race_hash_global == '') {
            refreshRaceGlobalHash();
        }
        var addData = new Object();
        var tmp_uniqud_id = race_hash_global;
        addData.raceUniqueID = encodeURIComponent(tmp_uniqud_id);
        race_simulated_global = 0;
        axiosInstance.post('race/details_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var race_details = result.data;
                if(race_creator_types.indexOf(race_hash_global) === -1) {
                    setFinalRaceID(race_details[0].race_id);
                    setRaceID(race_details[0].race_id);
                    if(race_details[0].race_simulated == 1) {
                        setRaceSimStatus('Y');
                        race_simulated_global = 1;
                        tmp_left_nav_section = 'past';
                        if(race_details[0].results_viewable == 1) {
                            setRaceTopStatus('RACE COMPLETE');
                        } else {
                            setRaceTopStatus('RACE IN PROGRESS');
                        }
                    }
                    setRacerCreator(race_details[0].creator_id);
                    race_track_id = race_details[0].race_track_id;
                    open_invite_race = race_details[0].open_invite_race;
                    open_entry_closed = race_details[0].entry_window_over;
                    predictions_closed = race_details[0].predictions_window_over;

                    setTimeout(function(){
                        $("#race-id-header").val(race_details[0].race_id);
                        $("#race-unique-id-header").val(race_details[0].race_unique_id);
                    },500);
                }
                setRaceDetails(race_details[0]);
                grabRaceParticipants(race_details[0]);
                startRaceMenu(tmp_left_nav_section);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function grabRaceParticipants(raceDetails) {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(race_hash_global);
        addData.predictorID = userID;
        current_racer_global = 0;
        racer_sim_watched_global = 0;
        axiosInstance.post('race/racer_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_list = result.data;
                var current_racer_status = {};
                for(var i = 0;i < racer_list.length;i++) {
                    if(racer_list[i].racer_id == racerID) {
                        current_racer_status = racer_list[i];
                        current_racer_global = 1;
                        setRacerEntryStatus('Y');
                        if(racer_list[i].racer_sim_watched == 1) {
                            setRacerSimWatched('Y');
                            racer_sim_watched_global = 1;
                        }
                    }
                }
                setRacerStatus(current_racer_status);
            }
            setRacerDetails(result.data);
            grabRacersInvited(raceDetails,result.data);
            
        }).catch(error => {
            console.log(error);
        });
    }

    function grabRacersInvited(raceDetails,racerList) {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(race_hash_global);
        racer_invite_status_global = 0;
        axiosInstance.post('race/invite_list/',addData).then(result => {
            var invitedDetails = result.data;
            if(Object.keys(invitedDetails).length > 0) {
                var final_invite_list = invitedDetails;
                for(var m = 0;m < final_invite_list.length;m++) {
                    if(final_invite_list[m].racer_id == racerID) {
                        setRacerInviteStatus('Y');
                        racer_invite_status_global = 1;
                    }
                }
                
            }
            setRaceInviteDetails(invitedDetails);
            loadRaceGlobalInfo(raceDetails,racerList,invitedDetails);
            loadRaceSections(raceDetails,racerList,invitedDetails);
        }).catch(error => {
            console.log(error);
        });
    }

    function refreshPageHeader() {
        
    }
    
    var entryRacer = 0;
    var entryPilot = 0;
    var entryBoosters = 0;
    function loadRaceGlobalInfo(raceDetails,racerList,invitedDetails) {
        var raceName = 'Loading...';
        if(raceDetails.race_name !== undefined) {
            var raceName = raceDetails.race_name+" #"+raceDetails.race_id;
        }
        // if((open_invite_race == 1)&&(open_entry_closed == 0)) {
        //     var raceName = 'Open Race Battle';
        // }
        
        var totalEXR = raceDetails.race_coin_prize;
        var totalXP = raceDetails.race_xp_prize;
        var totalLaps = raceDetails.race_laps;
        var botRace = raceDetails.botRace;
        var legendaryRacers = raceDetails.legendary_exiled_racers;
        var days_ended = raceDetails.days_since_race_ended;
        var days_started = raceDetails.days_since_race_starts;
        var date_entry_close = raceDetails.date_race_entry_close_eastern;
        var date_predictions_close = raceDetails.date_race_predictions_close_eastern;
        var date_simulation_close = raceDetails.date_race_simulated_close_eastern;

        if(raceDetails.botRace == 1) {
            totalXP = 0;
            totalEXR = 0;
        }

        var race_time = 'Today';
        var resultsDisplay = 'none';
        entryDisplay = 'none';
        var lineupDisplay = 'block';
        var invitingDisplay = 'none';
        var simstatustext = 'none';
        entryFeeDisplay = 'none';

        var racers_invited = '';
        var race_simulated = window.$("#race-simulated-header").val();
        var racer_entered = window.$("#racer-entry-header").val();
        var racer_watched = window.$("#racer-simulation-watched").val();
        var racer_invited = window.$("#racer-invite-status").val();
        var entry_close_time_text = '';
        var pred_close_time_text = '';

        if((racer_watched == 'N')&&(race_simulated == 'Y')) {
            entryDisplay = 'none';
            race_time = 'Ready';
            simstatustext = 'block';
        } else if((days_ended !== undefined)&&(days_ended !== 'null')&&(days_ended !== '')&&(days_ended !== null)) {
            if(days_ended == 0) {
                race_time = "Ended Today";
            } else if (days_ended == 1) {
                race_time = "Ended Yesterday";
            } else {
                race_time = "Ended "+days_ended+" Days Ago";
            }
            entryDisplay = 'none';
            resultsDisplay = 'block';
        } else if ((days_started !== undefined)&&(days_started !== 'null')&&(days_started !== '')&&(days_started !== null)) {
            race_time = "In "+days_ended+" Days";
        } else if (open_invite_race == 1) {
            var entry_close_time = new Date(date_entry_close);
            var prediction_close_time = new Date(date_predictions_close);
            var simulation_close_time = new Date(date_simulation_close);

            var sim_min = simulation_close_time.getMinutes();
            var sim_hour = simulation_close_time.getHours();
            var sim_month = simulation_close_time.toLocaleString('default', { month: 'short' });
            var sim_date = simulation_close_time.getDate();
            var timedirection = 'PM';
            if (sim_hour < 12) {
                timedirection = 'AM';
            } else if (sim_hour > 12) {
                sim_hour = sim_hour - 12;
            }
            if(sim_hour == 0) {
                sim_hour = 12;
            }
            let race_time_tmp = sim_hour;
            if(sim_min > 10) {
                race_time_tmp = race_time_tmp + ':'+sim_min+' ';
            } else if(sim_min > 0) {
                race_time_tmp = race_time_tmp + ':0'+sim_min+' ';
            }
            console.log(simulation_close_time);
            race_time_tmp = sim_month+' '+sim_date+' @ '+race_time_tmp + timedirection+' EST';
            race_time = <a className="exr-light-green-text">{race_time_tmp}</a>;

            var entry_min = entry_close_time.getMinutes();
            var entry_hour = entry_close_time.getHours();
            var entry_month =  entry_close_time.toLocaleString('default', { month: 'short' });
            var entry_date =  entry_close_time.getDate();
            var timedirection = 'PM';
            if (entry_hour < 12) {
                timedirection = 'AM';
            } else if (entry_hour > 12) {
                entry_hour = entry_hour - 12;
            }
            if (entry_hour == 0) {
                entry_hour = 12;
            }
            entry_close_time_text = entry_hour;
            if(entry_min > 10) {
                entry_close_time_text = entry_close_time_text + ':'+entry_min+' ';
            } else if(entry_min > 0) {
                entry_close_time_text = entry_close_time_text + ':0'+entry_min+' ';
            }
            entry_close_time_text = entry_month+' '+entry_date+' @ '+entry_close_time_text + timedirection+' EST';
            
            if(open_entry_closed == 0) {
                setRaceEntryCloseText(<span className="left" style={{marginRight:'40px'}}>Entry Closes: <a className="exr-light-green-text">{entry_close_time_text}</a></span>);
            } else {
                setRaceEntryCloseText(<></>);
            }


            var pred_min = prediction_close_time.getMinutes();
            var pred_hour = prediction_close_time.getHours();
            var timedirection = 'PM';
            if (pred_hour < 12) {
                timedirection = 'AM';
            } else if (pred_hour > 12) {
                pred_hour = pred_hour - 12;
            }
            if (pred_hour == 0) {
                pred_hour = 12;
            }
            pred_close_time_text = pred_hour;
            if(pred_min > 10) {
                pred_close_time_text = pred_close_time_text + ':'+pred_min+' ';
            } else if(pred_min > 0) {
                pred_close_time_text = pred_close_time_text + ':0'+pred_min+' ';
            }
            pred_close_time_text = pred_close_time_text + timedirection+' EST';
            // if((open_invite_race == 1)&&(race_simulated_global == 0)) {
            //     setRacePredictionCloseText(<span className="left" style={{marginRight:'40px'}}>Predictions Close: <a className="exr-light-green-text">{pred_close_time_text}</a></span>);
            // } else {
            //     setRacePredictionCloseText(<></>);
            // }
            setRacePredictionCloseText(<></>);
        }

        if(race_invite_list.length > 2) {
            var invited_names = race_invite_list.replace(/,/g,", ");
            var challengercount = race_invite_list.split(",").length;
            var challengerword = 'INVITING';
            racers_invited = <span><span className="exr-light-green-text">{challengerword}:</span><span>&nbsp;&nbsp;{invited_names}</span></span>;
        }

        if((racer_entered == 'N')&&(race_simulated == 'N')) {
            entryDisplay = 'block';
            var lineupDisplay = 'block';
        }

        var total_racers = 0;
        if(racerList.length > 0) {
            total_racers = total_racers+racerList.length;
        }
        if(invitedDetails.length > 0) {
            total_racers = total_racers+invitedDetails.length;
        }
        if(total_racers < 2) {
            total_racers = 2;
        }
        var race_type = <>Standard Race</>;
        var prize_type = 'RPM';
        var prize_dist = 'Tiered Winnings';
        var raceEntryEXR = raceDetails.race_entry_fee;
        var raceEntryPaid = raceDetails.race_paid_entry_fee;

        if (raceDetails.race_scoring == 'reverse') {
            race_type = <a className="exr-yellow-text">Inverse Race</a>;
        }
        if((race_unique_id == 'daily')||(race_unique_id == 'bot-race')||(race_unique_id == 'training')) {
            raceName = 'DAILY TRAINING RACE';
            race_type = 'Standard Race';
            race_time = 'Today';
            entryDisplay = 'block';
            lineupDisplay = 'none';
            resultsDisplay = 'none';
        } else if(race_unique_id == 'alpha') {
            raceName = 'DAILY TRAINING RACE';
            race_time = 'Today';
            entryDisplay = 'block';
            lineupDisplay = 'none';
            resultsDisplay = 'none';
        } else if(race_unique_id == 'invite') {
            raceName = 'RACE BATTLE';
            race_time = 'Today';
            entryDisplay = 'block';
            lineupDisplay = 'none';
            resultsDisplay = 'none';
            entryFeeDisplay = 'block';
            if(raceEntryEXR == 0) {
                raceEntryEXR = 25;
            }
            totalEXR = total_racers*raceEntryEXR;
        } 

        raceName = 'LIVE RACE #'+raceDetails.race_id;

        if(raceEntryEXR > 0) {
            entryFeeDisplay = 'block';
            if((raceName.indexOf('Battle') >= 0)&&(raceDetails.race_prize_scoring == 'standard')) {
                prize_dist = 'Winner Take All';
            }
        }
        var final_tooltip = '';
        if(raceDetails.race_prize_scoring != 'winner') {
            final_tooltip = prize_tier_tooltip;
            if(raceName.indexOf('Training') >= 0) {
                final_tooltip = training_tier_tooltip;
            }
        } else {
            prize_dist = 'Winner Take All';
            final_tooltip = prize_wta_tooltip;
        }

        // Calculate Legendary / Exiled Bonus If Available
        var legendary_bonus = 0;
        if((legendaryRacers > 0)&&(botRace == 0)) {
            legendary_bonus = 5;
        }
        
        var final_entry_text = '';
        var final_prize_text = '';
        if(raceEntryPaid > 0) {
            final_entry_text = final_entry_text+raceEntryPaid+' USDC';
            final_prize_text = final_prize_text+(total_racers*raceEntryPaid)+' USDC';
            if(raceEntryEXR > 0) {
                final_entry_text = final_entry_text+' + '+raceEntryEXR+' RPM';
                final_prize_text = final_prize_text+' + '+((total_racers*raceEntryEXR)+legendary_bonus)+' RPM';
            }
        } else if(raceEntryEXR > 0) {
            final_entry_text = final_entry_text+raceEntryEXR+' RPM';
            final_prize_text = final_prize_text+((total_racers*raceEntryEXR)+legendary_bonus)+' RPM';
        } else {
            final_entry_text = "Free";
            final_prize_text = '50 RPM';
        }

        if(legendary_bonus > 0) {
            final_prize_text = <a className="exr-yellow-text">{final_prize_text+"*"}</a>;
            final_tooltip = final_tooltip.replace("display:none;display:none;","");
        }

        var bigEntryButtonDisplay = 'none';
        if((racer_invited == 'Y')&&(racer_entered == 'N')) {
            bigEntryButtonDisplay = 'block';
        }

        setRaceName(raceName);
        setRaceType(race_type);
        setRaceEXR(final_prize_text);
        setRaceXP(totalXP);
        setRaceEntryText(final_entry_text);
        setRacePrizeType(prize_type);
        setRacePrizeDist(prize_dist);
        setRacerCount(total_racers);
        setRaceTime(race_time);
        setRaceEntryLaps(totalLaps);
        setFinalTierTooltop(final_tooltip);

        if(race_creator_types.indexOf(race_hash_global) >= 0) {
            $(".race-sub-header").each(function(){
                $(this).hide();
            });
        } else {
            $(".race-sub-header").each(function(){
                $(this).show();
            });
        }

        if(headerSet == false) {
            setHeader(true);
            headerCheck();
        }
    }

    function loadRaceSections(raceDetails,racerList,invitedDetails) {
        if(race_hash_global == '') {
            refreshRaceGlobalHash();
        }
        /* Check Open Invite Race and Entry Window Over */
        var tmp_main = <>
                {(() => {
                    if((race_simulated_global == 1)&&((racer_sim_watched_global == 1)||(current_racer_global == 0))){
                        // return (<ResultsViewSection raceDetails={raceDetails} racerList={racerList} />);
                        return (<LineupViewSection 
                            raceDetails={raceDetails} 
                            racerList={racerList} 
                            inviteList={invitedDetails} 
                            racerStatus={current_racer_global}
                            refreshPageHeader={refreshPageHeader}
                            entryCloseDate={race_entry_close_text}
                            hashRaceUniqueID={race_hash_global} />);

                    } else {
                        return (<LineupViewSection 
                                    raceDetails={raceDetails} 
                                    racerList={racerList} 
                                    inviteList={invitedDetails} 
                                    racerStatus={current_racer_global}
                                    refreshPageHeader={refreshPageHeader}
                                    entryCloseDate={race_entry_close_text}
                                    hashRaceUniqueID={race_hash_global} />);
                    }
                })()}
                <TrackSelectionSection navToggle={toggleRaceMenu} 
                        raceDetails={raceDetails} 
                        hashRaceUniqueID={race_hash_global} 
                        inviteList={invitedDetails}
                        racerStatus={current_racer_global} 
                        raceTrackID={race_track_id} />
                </>;
        
        setRaceLandingHTML(tmp_main);
        var tooltips = document.querySelectorAll('.prize-tips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
        setTimeout(function(){ adjustLeftRailHeight(); },500);
    }

    function toggleRaceMenu(pagename) {
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");
        window.location.href= '/#/races_home/'+pagename;
        dispatch(updateLocation("races_home"));

        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
        },5);
    }

    function startRaceMenu(pagename) {
        $(".side-nav-button-active").each(function() {
            $(this).removeClass("side-nav-button-active");
        });
        $("#"+pagename+"toggle").addClass("side-nav-button-active");
        setTimeout(function(){
            $("#"+pagename+"toggle").addClass("side-nav-button-active");
        },5);
    }

    function updateRaceTrackChosenDetails(trackid) {
        race_track_id = trackid;
    }
    
    function adjustLeftRailHeight() {
        var rightsideheight = $("#righsidecontent").height()-10;
        $("#leftrailnav").css({height:rightsideheight+'px'});
    }

    function showRaceHowToInfobox() {
        var infobox = document.getElementById('training_box_race_howto');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    function showRaceTypeInfobox(e) {
        e.preventDefault();
        var infobox = document.getElementById('global_infobox_race_type');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    function showFirstTrainingInfobox(boxid) {
        var infobox = document.getElementById('training_box_race_howto');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.infoBox = encodeURIComponent(boxid);
        axiosInstance.post('member/infobox_view/',addData).then(result => {
            var infobox_viewed = result.data;
            if((boxid == 'race_howto')||(boxid == 'race_landing')) {
                training_box_race_landing = 1;
                $("#current-racer-ibox-race-landing").val(training_box_race_landing);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    document.addEventListener('DOMContentLoaded', function() {
        adjustLeftRailHeight();
    });

    function headerCheck() {
        var url_race_unique_id_check = '';
        var racehashb = window.location.href.split("#/");
        if(racehashb.length > 1) {
            var racehash2b = racehashb[1].replace("#/",'');
            var racehash3b = racehash2b.split("/");
            if(racehash3b.length > 1) {
                url_race_unique_id_check = racehash3b[1].replace("/",'');
            }
        }
        if((race_creator_types.indexOf(url_race_unique_id_check) == 0)&&((race_name == 'DAILY TRAINING RACE')||(race_name == 'RACE BATTLE'))) {
            loadRaceGlobalInfo(race_details,racer_details,race_invite_details);
        }
        setTimeout(function(){
            headerCheck();
        },1000);
    }

    window.addEventListener("resize",
    function(){ 
        // clearInterval();
        adjustLeftRailHeight();
    }, false);

    if(pageSet == false) {
        setPage(true);
        window.startPageModals();
        window.resetBodyWidth();
        if(race_hash_global == '') {
            grabCurrentPubRace(0);
        } else {
            grabRaceDetails();
        }
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            <div id="race-landing-shell" className="row page-width-wide page-clean-body">
                <div id="righsidecontent" className="col s12" style={{position:'relative', minHeight:'100vh', margin:"0px",padding:"0px",overflow:'hidden'}}>
                <div className="row border-section-full white-text" style={{minHeight:'100vh',position:'relative',margin:'0px',padding:"5px"}}>
                    <div className="row" style={{padding:'0px 15px',borderRadius:'10px',marginTop:'0px',marginBottom:'0px'}}>
                        <div className="col s12 headline-text" style={{fontSize:"20px",lineHeight:'1',marginTop:'20px'}}>
                            {race_name.toUpperCase()}
                        </div>
                        <div className="col s12" style={{marginTop:'8px'}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="col s12 center-align" style={{marginTop:"10px",fontSize:'14px',lineHeight:'2'}} title="Learn More" onClick={(e)=>showRaceTypeInfobox(e)}>
                            <span className="left exr-yellow-text">{race_top_status}</span>
                            <span className="right">{race_game_type} // {race_entry_laps} Laps</span>
                            
                        </div>
                    </div>
                    {race_landing_html}
                </div>
            </div>
        </div>
        <StackInfoBox />
        <RacePredictHowToBox />
    </div>);
};
