import {CameraController} from "../cameraController.js";
import {Interpolation} from "../../../../../exr-webgl-hub/math/interpolation.js";

/**
 * A camera shown before the race starts
 */
export class CameraControllerOrientation extends CameraController {
    static RADIUS = 55;
    static HEIGHT = 40;
    static SPEED = .04;

    /**
     * Construct an orientation camera controller
     * @param {Camera} camera The camera to control
     * @param {Environment} environment The environment
     */
    constructor(camera, environment) {
        super(camera);

        this.angle = this.anglePrevious = Math.random() * Math.PI * 2;
    }

    /**
     * Update
     * @param {number} delta The time delta
     */
    update(delta) {
        this.anglePrevious = this.angle;

        if ((this.angle += CameraControllerOrientation.SPEED * delta) > Math.PI * 2) {
            this.angle -= Math.PI * 2;
            this.anglePrevious -= Math.PI * 2;
        }
    }

    /**
     * Update before rendering
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        const angle = Interpolation.lerp(this.anglePrevious, this.angle, time);

        this.camera.position.x = Math.cos(angle) * CameraControllerOrientation.RADIUS;
        this.camera.position.y = CameraControllerOrientation.HEIGHT;
        this.camera.position.z = Math.sin(angle) * CameraControllerOrientation.RADIUS;
    }
}