import * as THREE from "three";
import {Effect} from "./effect.js";
import {effectGlow} from "../../../exr-webgl-hub/effects/2d/effectGlow.js";

export class EffectFinishFlare extends Effect {
    static SPEED = .1;
    static SCALE = 25;
    static POWER = .2;

    #material = effectGlow.clone();
    #mesh = new THREE.Mesh(new THREE.PlaneGeometry(), this.#material);
    #progress = 0;

    /**
     * Construct a finish flare
     * @param {THREE.Vector3} position The position
     */
    constructor(position) {
        super();

        this.group.add(this.#mesh);

        this.#mesh.position.copy(position);
        this.#mesh.rotateY(Math.PI * .5);
    }

    /**
     * Update the effect
     * @returns {boolean} True if the effect should be deleted
     */
    update() {
        return (this.#progress += EffectFinishFlare.SPEED) > 1;
    }

    /**
     * Render
     * @param {number} time The time interpolation in the range [0, 1]
     */
    render(time) {
        const progress = this.#progress + time * EffectFinishFlare.SPEED;
        const scale = Math.pow(progress, EffectFinishFlare.POWER) * EffectFinishFlare.SCALE;

        this.#mesh.scale.set(scale, scale, scale);
        this.#material.uniforms.opacity.value = Math.max(0, 1 - progress);
    }
}