/**
 * A racer movement sampler
 */
export class RacerMovementSampler {
    /**
     * Construct a racer movement sampler
     * @param {RacerMovement} movement The movement to sample
     */
    constructor(movement) {
        this.movement = movement;
        this.anchor = 0;
    }

    /**
     * Interpolate movement
     * @param {number} anchor The anchor index of the fist point
     * @param {number} t The interpolation in the range [0, 1]
     */
    interpolateMovement(anchor, t) {
        const a = this.movement.anchors[Math.max(0, anchor - 1)].movement;
        const b = this.movement.anchors[anchor].movement;
        const c = this.movement.anchors[anchor + 1].movement;
        // const d = this.movement.anchors[Math.min(this.movement.anchors.length - 1, anchor + 2)].movement;
        const d = anchor + 2 === this.movement.anchors.length ?
            c + (c - b) :
            this.movement.anchors[anchor + 2].movement;
        return t * (t * ((2 * (a + c + c) - 5 * b  - d) - (a - 3 * (b - c) - d) * t) - a + c) * .5 + b;
    }

    /**
     * Sample movement
     * @param {number} time The time to sample at
     * @returns {number} The movement at this time, or -1 if the end of the movement was reached
     */
    sample(time) {
        if (this.anchor === this.movement.anchors.length - 2 && time > this.movement.anchors[this.anchor + 1].time)
            return -1;

        while (this.anchor < this.movement.anchors.length - 2 && time > this.movement.anchors[this.anchor + 1].time)
            ++this.anchor;

        if (time !== -1) while (time < this.movement.anchors[this.anchor].time)
            --this.anchor;

        const timeDelta = this.movement.anchors[this.anchor + 1].time - this.movement.anchors[this.anchor].time;
        const movementProgress = (time - this.movement.anchors[this.anchor].time) / timeDelta;

        return this.interpolateMovement(this.anchor, movementProgress);
    }

    /**
     * Check if Aggression Boosting is Active
     * @param {number} time The time to sample at
     * @returns {number} The aggression boost at this time, 1 if on and 0 if not
     */
     boostingCheck(time) {
        if (this.anchor === this.movement.anchors.length - 2)
            return 0;

        while (this.anchor < this.movement.anchors.length - 1 && time > this.movement.anchors[this.anchor + 1].time)
            ++this.anchor;

        const boosting_flag = this.movement.anchors[this.anchor + 1].boosting;

        return boosting_flag;
    }
}