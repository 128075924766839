import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import useSWR from "swr";

import { useMetaMask } from "metamask-react";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import { isCommunityResourcable } from "@ethersproject/providers";
import {closeAllModals} from "../../../utils";
import $ from "jquery";
import {
  InjectedConnector,
  UserRejectedRequestError,
  NoEthereumProviderError,
} from "@web3-react/injected-connector";

let web3;

const HARDHAT_CHAIN_ID = 31337;
const MOONBASE_CHAIN_ID = 1287;
const MOONBEAM_CHAIN_ID = 1284;
const ETH_CHAIN_ID = 1;
const BINANCE_CHAIN_ID = 56;
const POLYGON_CHAIN_ID = 137;
const ARBITRUM_CHAIN_ID = 42161;
const AVAX_CHAIN_ID = 43114;

const supportedChainIds = [
  HARDHAT_CHAIN_ID,
  MOONBASE_CHAIN_ID,
  MOONBEAM_CHAIN_ID,
  ETH_CHAIN_ID,
  BINANCE_CHAIN_ID,
  POLYGON_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  AVAX_CHAIN_ID
];

const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: supportedChainIds,
});

const fetcher = (url) => fetch(url).then((res) => res.json());

let ext_account_id = "";
let total_pilots_sync = 0;
let total_racecrafts_sync = 0;
let total_boosters_sync = 0;

export const TrainingRegistration = (props) => {
  const dispatch = useDispatch();
  const { page_loaded }= useSelector((state) => state.app);
  const { racerID, accountAddress } = useSelector((state) => state.account);
  const { active, chainId, library, error, activate, account } = useWeb3React();
  const { pilotContract, racerContract, boosterContract } = useSelector((state) => state.web3);

  const [accountID, setAccountID] = useState(null);
  const [chainID, setChainID] = useState(null);
  const [currentLoginStatus,setCurrentStatus] = useState('SYNCING NFT ASSETS...');
  let logo_image = 'https://media.exiledracers.com/global/exr_logo_white_stacked.png';
  let currentInterval = 0;
  let pilotTokenIDs = [];
  let racerTokenIDs = [];
  let boosterTokenIDs = [];

  /* Try To Connect Accounts and Chains Once Ready */
  useEffect(() => {
    if (active) {
      if (ext_account_id != account) {
        if(ext_account_id != '') {
          alert("Detected Wallet Change...Reloading!");
          forceReloadNetwork();
        } else {
          setAccountID(account);
          ext_account_id = account;
        }
      }
      if (chainID == null) {
        if (chainId != undefined) {
          setChainID(chainId);
        }
      } else if (chainID != chainId) {
        alert("Detected Network Change...Reloading!");
        forceReloadNetwork();
      }
    }
  }, [chainId, library, active, account]);
  
  /* Any Update To Contracts or Chains Triggers an Automatic Asset Check */
  useEffect(() => {
    checkPilotItems();
  }, [pilotContract, chainId]);

  useEffect(() => {
    checkRacecraftItems();
  }, [racerContract, chainId]);

  useEffect(() => {
    checkBoosterItems();
  }, [boosterContract, chainId]);

  /* Updates Items in DB */
  function updateWalletTokenOwner(tokenIds,tokenType) {
    var tokenstring = '';
    for(var i = 0; i < tokenIds.length;i++) {
      if(i > 0) {
        tokenstring = tokenstring+',';
      }
      tokenstring = tokenstring+tokenIds[i];
    }
    var addData = new Object;
    addData.walletID = encodeURIComponent(account);
    addData.tokenList = tokenstring;
    addData.nType = tokenType;

    if(account !== null) {
      if(account.length > 3) {
          axiosInstance
            .post("https://data.exiledracers.com/api/v1/mint/token_owner_check/", addData)
            .then((result) => {
              if (Object.keys(result.data).length > 0) {
                var view_result = result.data;
              }
            })
            .catch((error) => {
              console.log(error);
            });
      }
    }
  }

  function forceReloadNetwork() {
    window.location.reload();
  }

  function checkPilotItems() {
    if ((pilotContract)&&(chainId == 1284)) {
      if ((account)&&(account !== undefined)) {
        pilotContract
          .walletOfOwner(account)
          .then((ids) => {
            let formattedIds = ids.map((id) => id.toNumber());
            if(formattedIds.length > 0) {
              total_pilots_sync = formattedIds.length;
              updateWalletTokenOwner(formattedIds,'pilot');
            }
            
          })
          .catch((err) => console.log(err));
      }
    }
  }

  function checkRacecraftItems() {
    if ((racerContract)&&(chainId == 1284)) {
      if ((account)&&(account !== undefined)) {
        racerContract
          .walletOfOwner(account)
          .then((ids) => {
            let formattedIdsRC = ids.map((id) => id.toNumber());
            if(formattedIdsRC.length > 0) {
              total_racecrafts_sync = formattedIdsRC.length;
              updateWalletTokenOwner(formattedIdsRC,'racecraft');
            }
            
          })
          .catch((err) => console.log(err));
      }
    }
  }

  function checkBoosterItems() {
    if ((boosterContract)&&(chainId == 1284)) {
      if ((account)&&(account !== undefined)) {
        let promises = [];
        const promise = boosterContract.balanceOfBatch(account,0,202);
        promises.push(promise);
        Promise.all(promises).then((inventoryBalances) => {
            var totalboostersclaimed = 0;
            var booster_token_list =  new Array();
            for (let i = 0; i < inventoryBalances[0].length; i++) {
                if ((inventoryBalances[0][i].toNumber() != 0)&&(inventoryBalances[0][i].toNumber() != '')) {
                    totalboostersclaimed = totalboostersclaimed + inventoryBalances[0][i].toNumber();
                    for (var z = 0; z < inventoryBalances[0][i].toNumber(); z++) {
                        booster_token_list.push(i);
                    }  
                }
            }
            if(booster_token_list.length > 0) {
              total_boosters_sync = booster_token_list.length;
              updateWalletTokenOwner(booster_token_list,'booster');
            }
        });
      }
    }
  }

  /* Updates On Screen Wallet Address Text and Inputs */
  async function updateWalletAddress() {
    let wallet_address = await syncAccountWallet();
    if(wallet_address) {
        if(wallet_address.length > 5) {
            document.getElementById("walletID").value = wallet_address;
            document.getElementById("WID").value = wallet_address;
        }
    }
  }

  function registerStepOne(signup_flow_flag) {
    checkGameAssets(3,signup_flow_flag);
  }

  function checkGameAssets(checknum,signup_flow_flag) {
    if(((total_pilots_sync > 0)&&(total_racecrafts_sync > 0)&&(total_boosters_sync > 0))||(checknum == 0)) {
      var final_status = '';
      if((total_pilots_sync == 0)&&(total_racecrafts_sync == 0)&&(total_boosters_sync == 0)) {
        final_status = 'no-nft';
        setCurrentStatus('EXR NFTS NOT FOUND');
        setTimeout(function() {
          $("#registerform").hide();
          $("#registerheader").hide();
          $("#registersubmit").hide();
          $("#registerloading").hide();
          $("#errornftheader").show();
          $("#confirmnfterror").show();
      }, 3000);
      } else if((total_pilots_sync == 0)||(total_racecrafts_sync == 0)||(total_boosters_sync == 0)) {
        final_status = 'mint-remaining';
        setCurrentStatus('EXR NFTS NOT ALL MINTED');
        setTimeout(function() {
            $("#registerform").hide();
            $("#registerheader").hide();
            $("#registersubmit").hide();
            $("#registerloading").hide();
            $("#errormintheader").show();
            $("#confirmminterror").show();
        }, 3000);
      } else {
        setCurrentStatus('EXR NFTS FOUND. REGISTERING.');
        registerStepTwo();
      }
    } else {
      checkPilotItems();
      checkRacecraftItems();
      checkBoosterItems();
      let nextnum = checknum - 1;
      setTimeout(function(){
        checkGameAssets(nextnum,signup_flow_flag);
      },2000);
    }
  }

  function registerStepTwo() {
    var walletID = document.getElementById("walletID").value;
    var addData = new Object();
    addData.walletID = encodeURIComponent(walletID);
    axiosInstance.post('member/training_register/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var login_result = result.data;
            if(login_result.status.includes("not-eligible")) {
              setTimeout(function() {
                $("#registerform").hide();
                $("#registerheader").hide();
                $("#registersubmit").hide();
                $("#registerloading").hide();
                $("#errorheader").show();
                $("#confirmerror").show();
            }, 3000);
            } else {
                setTimeout(function() {
                  $("#register-main-section").fadeOut(2000);
                }, 3000);
                setTimeout(function() {
                  $("#register-confirm-section").fadeIn(3000);
                }, 5500);
            }
        }
    });
  }

  function startRegisterProcess(e) {
    e.preventDefault();
    var walletID = document.getElementById("walletID").value;

    if(walletID.length < 10) {
      alert('Connect Your Metamask Wallet to Register');
    } else if(chainId != 1284) {
      alert('Switch to the Moonbeam Network on Metamask to Register');
    } else {
        $("#registersubmit").hide();
        $("#registerloading").show();
        $("#registerstatus").show();
        $("#metamaskconnect").hide();
        registerStepOne();
    }
  }

  return (<><div id="register-main-section" className="col s12 transparent" style={{marginTop:'5vh',position:'relative',zIndex:'10',display:'none'}}>
            <div className="row center-align" style={{marginBottom:'10px'}}>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                    <img id="exr-logo-button" style={{cursor:'pointer',marginLeft:'-20px',marginTop:'3vh',minWidth:'250px'}} title="Exiled Racers" width="50%" src={logo_image} />
                </div>
            </div>
            <div className="row white-text" style={{position:'relative'}}>
              <div className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{marginBottom:'50px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                  <div id="registerheader" className="left headline-text" style={{marginLeft:'5px'}}>REGISTER FOR TRAINING</div>
                  <div id="errorheader" className="left headline-text" style={{marginLeft:'5px',display:'none'}}>NOT AUTHORIZED</div>
                  <div id="errornftheader" className="left headline-text" style={{marginLeft:'5px',display:'none'}}>EXR NFTS NOT FOUND</div>
                  <div id="errormintheader" className="left headline-text" style={{marginLeft:'5px',display:'none'}}>FULL STACK NOT FOUND</div>
                </div>
                <div className="col s12" style={{padding:"0px 5px"}}>
                    <div className="divider-thin"></div>
                </div>
                <div id="confirmerror" className="col s12 subheadline-text" style={{display:'none',fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                    You don't have access to the Exiled Racers training program. 
                    <br /><br />
                    EXR race training is open to racers that have a full stack of EXR NFTs including a Pilot, Racecraft and Booster. For EXR Mint Pass owners, use the EXR Mint Site to complete the minting 
                    of your assets. 
                    <br /><br />
                    - The EXR Team
                </div>
                <div id="confirmnfterror" className="col s12 subheadline-text" style={{display:'none',fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                    This wallet does not contain any EXR NFTs. EXR race training is open to racers that have a 
                    full stack of Pilot, Racecraft and Booster NFTs. 
                    <br/><br/>Switch to your wallet that contains your EXR NFTs or buy your NFTs on&nbsp; 
                    <a className="exr-light-green-text" href="https://moonbeans.io/storefront/exr" target="_blank">Moonbeans</a> or&nbsp;
                    <a className="exr-light-green-text" href="https://nftrade.com/assets/moonbeam/0x3507fd182dc5bd1b29214950fdcde40bd167114f/1" target="_blank">NFTrade</a>.
                    <br /><br />
                    - The EXR Team
                </div>
                <div id="confirmminterror" className="col s12 subheadline-text" style={{display:'none',fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                    This wallet does not contain a full stack of EXR NFTs. EXR race training is open to racers that have a 
                    full stack of Pilot, Racecraft and Booster NFTs. 
                    <br/><br/>Before registering, finish minting the rest of your NFTs on the&nbsp;
                    <a className="exr-light-green-text" href="https://mint.exiledracers.com/" target="_blank">EXR Mint Site</a>.
                    <br /><br />
                    - The EXR Team
                </div>
                <div id="registerform" className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                    <div className="row"  style={{padding:'0px 10px',marginBottom:'10px'}}>
                      The Exiled Racers training registration is now open. The wallet you are registering with 
                      must be switched to the <a className="exr-light-green-text">Moonbeam Network</a>&nbsp;and have a&nbsp; 
                      <a className="exr-light-green-text">Full Stack of EXR NFTs (Pilot, Racecraft and Booster)</a>. If you don't have EXR NFTs, you can acquire them on&nbsp; 
                    <a className="exr-light-green-text" href="https://moonbeans.io/storefront/exr" target="_blank">Moonbeans</a> or&nbsp;
                    <a className="exr-light-green-text" href="https://nftrade.com/assets/moonbeam/0x3507fd182dc5bd1b29214950fdcde40bd167114f/1" target="_blank">NFTrade</a>.
                      <br/><br/>
                      For a limited time, racers who register for training will receive an inaugural EXR Training Pass NFT. 
                      Connect your MetaMask Wallet to register below.
                      <br/><br/>
                    </div>
                    <div className="row" style={{padding:'0px 10px',marginBottom:'5px'}}>
                      Wallet Address:
                    </div>
                    <div className="row input-field" style={{padding:'0px 10px',marginBottom:'0px'}}>
                        <input id="WID"
                                name="WID" 
                                type="text" 
                                className="browser-default white-bubble-input"
                                style={{width:'100%',cursor:'not-allowed'}}
                                title="Current Wallet Address"
                                disabled />
                    </div>
                    <div className="row" id="metamaskconnect" style={{padding:'0px 10px',margin:'0px',marginTop:'10px',width:'99%'}}>
                        <div id="mmasksync" className="btn-flat right enableEthereumButton splash-submodal-button" title="Connect MetaMask Wallet">
                            <span className="headline-text white-red-link" title="Connect MetaMask Wallet" onClick={updateWalletAddress}>CONNECT METAMASK</span>
                        </div>
                    </div>
                    <div className="row headline-text" id="registerstatus" style={{padding:'0px 10px',margin:'0px',marginTop:'10px',width:'99%',display:'none'}}>
                      <span className="headline-text white-text right" style={{fontSize:'12px'}}>{currentLoginStatus}</span>
                    </div>
                    <input name="walletID" id="walletID" type="hidden" className="validate left" disabled required />
                </div>
                <div className="col s12" style={{marginTop:'50px',marginBottom:'10px'}}>
                    <div id="registersubmit" className="right" title="Continue to Training">
                        <span type="submit" className="round-border-button" style={{fontSize:'18px'}} title="Submit Registration" onClick={(e)=>startRegisterProcess(e)}>
                            SUBMIT
                        </span>
                    </div>
                    <div id="registerloading" className="preloader-wrapper small active right splash-modal-loader" style={{height:'30px',width:'30px',marginTop:'3px'}}>
                        <div className="spinner-layer spinner-red-only">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div>
                            <div className="gap-patch">
                                <div className="circle"></div>
                            </div>
                            <div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div id="register-confirm-section" className="col s12 transparent" style={{marginTop:'2.5vh',position:'relative',zIndex:'10',display:'none'}}>
            <div className="row center-align" style={{marginBottom:'10px'}}>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                    <img id="exr-logo-button" style={{cursor:'pointer',marginLeft:'-20px',minWidth:'250px'}} title="Exiled Racers" width="50%" src={logo_image} />
                </div>
            </div>
            <div className="row white-text" style={{position:'relative'}}>
                <div className="col s10 offset-s1 m8 offset-m2 l4 offset-l4" style={{marginBottom:'50px',position:'relative',borderRadius:'10px',border:'1px solid white',padding:'20px'}}>
                    <div className="col s12" style={{fontSize:"18px",marginTop:"0px",padding:"0px"}}>
                        <div className="left headline-text" style={{marginLeft:'10px'}}>YOU'RE REGISTERED</div>
                    </div>
                    <div className="col s12 subheadline-text" style={{fontSize:'16px',borderRadius:'10px',marginTop:'20px'}}>
                        You're now registered for EXR Training. Stay connected to the EXR&nbsp;
                        <a className="exr-light-green-text" href="https://discord.gg/exiledracers" target="_blank">Discord</a>&nbsp;and&nbsp; 
                        <a className="exr-light-green-text" href="https://twitter.com/exiledracers" target="_blank">Twitter</a> for updates on when training is live.
                        <br/><br/>
                        As an early member of the movement you've received a 
                        limited edition Training Pass NFT that will be airdropped to your wallet within a few minutes. 
                    </div>
                    <div className="col s12 headline-text center-align" style={{fontSize:'16px',borderRadius:'10px',marginTop:'30px'}}>
                        EXR TRAINING PASS NFT
                    </div>
                    <div className="col s12 subheadline-text center-align" style={{fontSize:'16px',borderRadius:'10px',marginTop:'10px',marginBottom:'40px'}}>
                        <img src="https://media.exiledracers.com/assets/passes/exr_training_pass_nft.jpg" width="80%" style={{minWidth:'250px',borderRadius:'10px',border:'1px solid white'}} />
                    </div>
                </div>
            </div>
          </div>
        </>
  );
};
