var CryptoJS = require("crypto-js");

export const encryptLoginData = (datatype,data) => {
    let LOGIN_CHECK_KEY="ZD_m66pr1QpsLKq7xEiA3Tj41Mhh7ro4t5K3xHIbnic=";
    let LOGIN_WALLET_CHECK="BNmk6jhdLj2peyHlQpTdwWke23YkufxajZIfLJQrEwc=";

    // if(datatype == 'wallet') {
    //     return CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(data, LOGIN_WALLET_CHECK).toString());
    // } else {
    //     return CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(data, LOGIN_CHECK_KEY).toString());
    // } 
    if(datatype == 'wallet') {
        return CryptoJS.HmacSHA256(data, LOGIN_WALLET_CHECK).toString();
    } else {
        return CryptoJS.HmacSHA256(data, LOGIN_CHECK_KEY).toString();
    } 
};