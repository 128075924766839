import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {closeAllModals} from "../../../utils";
import $ from "jquery";

export const ShareResultsBox = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers, live_hosts } = useSelector((state) => state.app);

    if((racerID.length == 0)||(accountAddress.length == 0)) {
        // dispatch(setRacerLogin('',''));
        // dispatch(updateLocation("splash"));
        // window.location.href= '/';
    }

    const [headerSet, setHeader] = useState(false);
    const [raceID, setRaceID] = useState(props.raceID);
    const [raceUniqueID, setRaceUniqueID] = useState(props.raceHash);

    if(headerSet == false) {
        setHeader(true);
    }

    window.$(document).ready(function(){
    });

    // var final_tweet_link = "https://twitter.com/intent/tweet?text="+encodeURIComponent(tweettxt)+"&via="+via_text+"&url="+encodeURIComponent(mint_key_image)+"&hashtags="+hashtag_text;
    var finalTwitterText = 'Check out this race on Exiled Racers!';

    return(<div id="result-share-box" className="modal page-modal page-modal-small">
    <div className="modal-content">
      <div className="row" style={{marginBottom:'5px',position:'relative'}}>
        <div className="round-close-button" style={{right:'0px'}} title="Close" onClick={()=>closeAllModals()}>
                X
            </div>
            <div className="col s12" style={{fontSize:"24px",marginTop:"0px",padding:"0px"}}>
                <div className="left headline-text" style={{marginLeft:'5px'}}>SHARE RESULTS</div>
            </div>
            <div className="col s12" style={{padding:"0px 5px"}}>
                <div className="divider-thin"></div>
            </div>
            <div className="col s12 subheadline-text" style={{marginTop:'10px',marginBottom:'10px',fontSize:'16px'}}>
                <div className="row" style={{margin:'0px'}}>
                  Share a Race Results Infographic, Watch Link or Tweet to let others enjoy this race.
                </div>
            </div>
            <div className="col s12 m6 subheadline-text" style={{marginTop:'10px',fontSize:'16px',padding:'0px',paddingRight:'5px'}}>
                <div className="exr-stats-light-grey" style={{padding:'12px 10px',borderRadius:'10px'}}>
                  <div className="row center-align" style={{margin:'0px'}}>
                    Race Results Graphic
                  </div>
                  <div className="row center-align" style={{margin:'5px 0px'}}>
                        <a href="#" target="_blank" id="share_download_link"
                            className="round-border-button" style={{fontSize:'12px'}} 
                            title="Download Graphic" download={'exr_results_race_'+raceID+'.png'}>
                            DOWNLOAD
                        </a>
                  </div>
                </div>
            </div>
            <div className="col s12 m6 subheadline-text" style={{marginTop:'10px',fontSize:'16px',padding:'0px',paddingLeft:'5px'}}>
                <div className="exr-stats-light-grey" style={{padding:'12px 10px',borderRadius:'10px'}}>
                  <div className="row center-align" style={{margin:'0px'}}>
                    Social Share
                  </div>
                  <div className="row center-align" style={{margin:'5px 0px'}}>
                        <a target="_blank" className="round-border-button" style={{fontSize:'12px'}} 
                            title="Share to Twitter"
                            href={
                                "https://twitter.com/share?text="+
                                encodeURIComponent(finalTwitterText)+
                                "&url=" +
                                encodeURIComponent("https://www.exiledracers.com/#/watch/"+raceUniqueID) +
                                " &hashtags=ExiledRacers,EXR,winning&via=exiledracers"
                              } >
                          TWITTER
                          </a>
                  </div>
                </div>
            </div>
            <div className="col s12 subheadline-text exr-stats-light-grey" style={{marginTop:'10px',fontSize:'16px',padding:'15px 20px',borderRadius:'10px'}}>
                <div className="row" style={{margin:'0px'}}>
                  &nbsp;Race Replay Share Link
                </div>
                <div className="row input-field" style={{margin:'10px 0px'}}>
                    <input value={'https://www.exiledracers.com/#/watch/'+raceUniqueID} 
                                      id="race_share_link"
                                      name="race_share_link" 
                                      type="text" 
                                      className="browser-default light-grey-bubble-input"
                                      style={{width:'100%',cursor:'not-allowed',background:"grey"}}
                                      title="Race Watch Shareable Link" 
                                      disabled />
                </div>
            </div>
            
        </div>
      </div>
  </div>);
};