import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import "../../../../splash_event.css";


import { setUserSignOn, setLoginWallet } from "../../../../../../../redux/Account/actions";

import {genetic_tooltip, genetic_max_tooltip, genetic_track_tooltip, genetic_tooltip_wtrack, genetic_max_tooltip_wtrack} from "../../../../../RaceLanding/race_landing_tooltips";
import {laps_tooltip, turns_tooltip, straights_tooltip, shifts_tooltip} from "../../../../../RaceLanding/race_landing_tooltips";
import {getEventUserInfo, createRedirectToken} from "../../../../Utilities"
import {axiosInstance } from "../../../../../../../utils";
import {StackInfoBox} from "../../../../../RaceLanding/Infoboxes";

import {TrackLoader} from "../../../../../../../webgl/race/utils/trackLoader.js"
import { Track } from "../../../../../../../webgl/race/track/track.js";
import { TrackMap } from "../../../../../../../webgl/race/track/map/trackMap.js";
import { Minimap } from "../../../../../../../webgl/race/minimap/minimap.js";

import "./raceday_details.css";

import $ from "jquery";

let current_user_id = 0; 
let current_wallet_address = '';
let signup_racer = 'spectator';
let race_raceday_id = 0;
let boosters_checked = 0;
let current_racer_global = 0;
let current_race_unique_id = '';
let raceDetails = [];
export const BaseLeagueWeeklyRacedayDetails = (props) => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { chainId, account } = useWeb3React();

    /* EXR Event ID */
    var exreventid = 3;
    let league_permalink_id = 'pink-racing-league'
    let signup_user_id = 0;
    const [entrySet, setEntry] = useState(false);
    const [raceweek_number,setRaceweekNumber] = useState(1);
    const [race_details_html, setRaceDetailsHTML] = useState('');
    const [race_track_html, setRaceTrackHTML] = useState('');
    const [item_list, setItemList] = useState([]);
    let [racerList,setRacerList] = useState([]);


    function grabRacedayDetails() {
        race_raceday_id = props.raceday_id;
        raceDetails = props.raceday_details;
        let raceweek_num = raceDetails['raceday_number'];
        setRaceweekNumber(raceweek_num);
        $("#track-viewer-league").html('');
        loadWeekDetails();
        grabRaceTracks();
    }

    function raceTimeToText(timestring) {
        var next_race_time = new Date(timestring);

        var race_time = '';
        var racetime_day = next_race_time.getDay();
        var racetime_month =  next_race_time.toLocaleString('default', { month: 'short' });
        var racetime_date =  next_race_time.getDate();
        var racetime_min = next_race_time.getMinutes();
        var racetime_hour = next_race_time.getHours();
        var timedirection = 'PM';
        if (racetime_hour < 12) {
            timedirection = 'AM';
        } else if (racetime_hour > 12) {
            racetime_hour = racetime_hour - 12;
        }
        if(racetime_hour == 0) {
            racetime_hour = 12;
        }
        race_time = racetime_hour;
        if(racetime_min > 10) {
            race_time = race_time + ':'+racetime_min+' ';
        } else if(racetime_min > 0) {
            race_time = race_time + ':0'+racetime_min+' ';
        }
        race_time = race_time + timedirection+' EST';
        
        var race_date_text = racetime_month + " " + racetime_date;
        var race_time_text = race_date_text + " @ " + race_time;
        
        return {'race_date':race_date_text,'race_time':race_time};
    }


    function loadWeekDetails() {
        var date_race_entry_start = raceDetails['date_race_entry_start']
        var date_race_entry_close = raceDetails['date_race_entry_close']
        var date_race_entry_close_eastern = raceDetails['date_race_entry_close_eastern'];
        var date_race_prediction_close = raceDetails['date_race_prediction_close'];
        var date_race_prediction_start = raceDetails['date_race_prediction_start'];
        var date_race_predictions_close_eastern = raceDetails['date_race_predictions_close_eastern'];
        var date_race_simulated_close_eastern = raceDetails['date_race_simulated_close_eastern'];
        var date_race_simulation_start = raceDetails['date_race_simulation_start'];
        var date_race_viewable = raceDetails['date_race_viewable'];

        var entry_start_time = raceTimeToText(date_race_entry_start);
        var entry_close_time = raceTimeToText(date_race_entry_close);
        var prediction_start_time = raceTimeToText(date_race_prediction_start);
        var prediction_close_time = raceTimeToText(date_race_prediction_close);
        var simulation_close_time = raceTimeToText(date_race_viewable);

        var results_open_status = <></>;
        var predictions_open_status = <></>;
        var predictions_close_status = <></>;
        var entry_open_status = <></>;
        var entry_close_status = <></>;

        if(raceDetails['results_opened'] == 1) {
            results_open_status = <>// <span className="exr-base-blue-text">NOW</span></>;
        } else if(raceDetails['predictions_window_over'] == 1) {
            predictions_close_status = <>// <span className="exr-base-blue-text">NOW</span></>;
        } else if(raceDetails['prediction_opened'] == 1) {
            predictions_open_status = <>// <span className="exr-base-blue-text">NOW</span></>;
        } else if((raceDetails['entry_open'] == 1)&&(raceDetails['raceday_races'].length > 0)) {
            entry_open_status = <>// <span className="exr-base-blue-text">NOW</span></>;
        } else if((raceDetails['prediction_opened'] == 0)&&(raceDetails['entry_open'] == 0)&&(raceDetails['raceday_races'].length > 0)) {
            entry_close_status  = <>// <span className="exr-base-blue-text">NOW</span></>;
        } 

        var stack_number = 2;
        if(props.raceday_id == 15) {
            stack_number = 1;
        }
        var tmp_html = <>
        <div className="row" style={{margin:'0px',padding:'10px 0px',display:'flex',flexDirection:'row'}}>
            <div className="left right-align headline-text" style={{marginRight:"20px",width:'100px'}}>
                <div className="left" style={{width:'100%',marginBottom:'5px',fontSize:'16px',textTransform:'uppercase'}}>
                    {entry_start_time['race_date']}
                </div>
                <div className="exr-base-blue-text left" style={{width:'100%',fontSize:'14px'}}>
                    {entry_start_time['race_time']}
                </div>
            </div>
            <div className="left" style={{flex:'1',padding:'0px 20px 0px 5px'}}>
                <div className="headline-text left" style={{width:'100%',marginBottom:'5px',fontSize:'20px'}}>
                    Races & Stacks Revealed {entry_open_status}
                </div>
                <div className="grey-text left" style={{width:'100%',marginBottom:'10px',fontSize:'16px',lineHeight:'1.5'}}>
                    Every week Base League Racers are given random racing items to use in races shared by EXR stack holders. 
                    This week you have <span className="exr-base-blue-text">{stack_number} stacks</span> of items available 
                    to choose from. Update your stack entry to pick your best performing combination.
                </div>
            </div>
        </div>
        <div className="row" style={{margin:'0px',padding:'10px 0px',display:'flex',flexDirection:'row'}}>
            <div className="left right-align headline-text" style={{marginRight:"20px",width:'100px'}}>
                <div className="left" style={{width:'100%',marginBottom:'5px',fontSize:'16px',textTransform:'uppercase'}}>
                    {entry_close_time['race_date']}
                </div>
                <div className="exr-base-blue-text left" style={{width:'100%',fontSize:'14px'}}>
                    {entry_close_time['race_time']}
                </div>
            </div>
            <div className="left" style={{flex:'1',padding:'0px 20px 0px 5px'}}>
                <div className="headline-text left" style={{width:'100%',marginBottom:'5px',fontSize:'20px'}}>
                    Race Stack Entry Closes {entry_close_status}
                </div>
                <div className="grey-text left" style={{width:'100%',marginBottom:'10px',fontSize:'16px',lineHeight:'1.5'}}>
                    Race stack entries are locked and final prediction odds are calculated. Odds are calculated by running each race 500 times through the 
                    EXR racing algorithm to determine each racers win chance, likely finish place and underdog points.
                </div>
            </div>
        </div>
        <div className="row" style={{margin:'0px',padding:'10px 0px',display:'flex',flexDirection:'row'}}>
            <div className="left right-align headline-text" style={{marginRight:"20px",width:'100px'}}>
                <div className="left" style={{width:'100%',marginBottom:'5px',fontSize:'16px',textTransform:'uppercase'}}>
                    {prediction_start_time['race_date']}
                </div>
                <div className="exr-base-blue-text left" style={{width:'100%',fontSize:'14px'}} >
                    {prediction_start_time['race_time']}
                </div>
            </div>
            <div className="left" style={{flex:'1',padding:'0px 20px 0px 5px'}}>
                <div className="headline-text left" style={{width:'100%',marginBottom:'5px',fontSize:'20px'}}>
                    Predictions Open {predictions_open_status}
                </div>
                <div className="grey-text left" style={{width:'100%',marginBottom:'10px',fontSize:'16px',lineHeight:'1.5'}}>
                    Every week both Racers and Predictors registered for the Base Racing League are assigned <span className="exr-base-blue-text">5 Races</span> to predict on. 
                    Races are randomly assigned unless there are relevant races to you that week. 
                    Pick a winner in each race to make <span className="exr-base-blue-text">5 Predictions</span> total.
                </div>
            </div>
        </div>
        <div className="row" style={{margin:'0px',padding:'10px 0px',display:'flex',flexDirection:'row'}}>
            <div className="left right-align headline-text" style={{marginRight:"20px",width:'100px'}}>
                <div className="left" style={{width:'100%',marginBottom:'5px',fontSize:'16px',textTransform:'uppercase'}}>
                    {prediction_close_time['race_date']}
                </div>
                <div className="exr-base-blue-text left" style={{width:'100%',fontSize:'14px'}}>
                    {prediction_close_time['race_time']}
                </div>
            </div>
            <div className="left" style={{flex:'1',padding:'0px 20px 0px 5px'}}>
                <div className="headline-text left" style={{width:'100%',marginBottom:'5px',fontSize:'20px'}}>
                    Predictions Close & Races Simulated {predictions_close_status}
                </div>
                <div className="grey-text left" style={{width:'100%',marginBottom:'10px',fontSize:'16px',lineHeight:'1.5'}}>
                    Race predictions become final and each race is simulated using the EXR racing algorithm to determine the final race result. 
                </div>
            </div>
        </div>
        <div className="row" style={{margin:'0px',padding:'10px 0px',display:'flex',flexDirection:'row'}}>
            <div className="left right-align headline-text" style={{marginRight:"20px",width:'100px'}}>
                <div className="left" style={{width:'100%',marginBottom:'5px',fontSize:'16px',textTransform:'uppercase'}}>
                    {simulation_close_time['race_date']}
                </div>
                <div className="exr-base-blue-text left" style={{width:'100%',fontSize:'14px'}}>
                    {simulation_close_time['race_time']}
                </div>
            </div>
            <div className="left" style={{flex:'1',padding:'0px 20px 0px 5px'}}>
                <div className="headline-text left" style={{width:'100%',marginBottom:'5px',fontSize:'20px'}}>
                    Watch Races & View Results {results_open_status}
                </div>
                <div className="grey-text left" style={{width:'100%',marginBottom:'10px',fontSize:'16px',lineHeight:'1.5'}}>
                    Race results are published on-chain and are viewable for all racers and predictors. Watch the races to see how your racer and predicted racers performed against the competition.
                    Check the leaderboards to see your current standings.
                </div>
            </div>
        </div>
        </>;

        setRaceDetailsHTML(tmp_html);
    }

    
    
    
    function grabRaceTracks() {
        var addData = new Object();
        addData.trackID = encodeURIComponent(raceDetails['race_track_id']);

        axiosInstance.post('race/track_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_track_list = result.data;
                showTrackSummary(new_track_list[0]);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function showTrackSummary(track_info) {
        var trackUnlocked = track_info.track_unlocked;
        var raceTrackID = track_info.race_track_id;
        var raceTrackGalaxy = raceDetails['race_track_galaxy'];
        var raceLaps = raceDetails['race_laps'];
        var raceTurns = track_info.total_turns;
        var raceStraights = track_info.total_straights;
        var raceShifts = track_info.total_shifts;
        var raceSegments = track_info.total_segments;
        var lappct = Math.round(100*(raceLaps / (10)));
        var turnpct = Math.round(100*(raceTurns / raceSegments));
        var straightpct = Math.round(100*(raceStraights / raceSegments));
        var shiftpct = Math.round(100*(raceShifts / raceSegments));

        var days_ended = raceDetails.days_since_race_ended;
        var entryDisplay = 'block';
        if((days_ended !== undefined)&&(days_ended !== 'null')&&(days_ended !== '')&&(days_ended !== null)) {
            entryDisplay = 'none';
        }
    
        var track_selection_title = '2. SELECT YOUR TRACK';
        if(entryDisplay == 'none') {
            track_selection_title = 'TRACK DETAILS';
        }
        let entry_button_display = entryDisplay;

        var galaxy_faction = '';
        if(raceTrackGalaxy.length > 0) {
            if(raceTrackGalaxy == 'serf') {
                galaxy_faction = 'Serf';
            } else if(raceTrackGalaxy == 'augment') {
                galaxy_faction = "Aug";
            } else {
                galaxy_faction = "Merc";
            }
        }

        var galaxy_faction_icon = '';
            if(galaxy_faction != 'ANY') {
                galaxy_faction_icon = <img title={galaxy_faction+" System Track - Match Stack Factions For Genetic Boost"} src={"https://media.exiledracers.com/global/icons/exr_"+galaxy_faction.toLowerCase()+"_icon.png"} height="50px" />
            }
        var tmp_html = <>
                    <div className="stats-viewer-stats-headline center-align" style={{marginTop:'0px',marginBottom:'15px',position:'relative'}}>
                        <span style={{width:'100%'}}>
                            Base Track #{raceTrackID}&nbsp;//&nbsp;
                            <span className="exr-base-blue-text" title={galaxy_faction+" System Track - Match Stack Factions For Genetic Boost"}>
                                {galaxy_faction} System
                            </span>
                        </span>
                    </div>
                    <div className="divider-thin"></div>
                    <div className="row white-text" style={{fontSize:'14px',marginTop:'15px',marginBottom:'5px'}}>
                        <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={laps_tooltip}>
                            <div style={{order:'1',flexBasis:'40px'}}>
                                <img src="https://media.exiledracers.com/global/icons/exr_strength.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                    <div className="left">Laps</div>
                                    <div className="right">{raceLaps}</div>
                                </div>
                                <div style={{width:'100%'}}>
                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                        <div className="determinate white" style={{width:lappct+'%',backgroundColor: 'white'}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider-thin"></div>
                    <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                        <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={turns_tooltip}>
                            <div style={{order:'1',flexBasis:'40px'}}>
                                <img src="https://media.exiledracers.com/global/icons/exr_handling.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                    <div className="left">Turns</div>
                                    <div className="right">{raceTurns}</div>
                                </div>
                                <div style={{width:'100%'}}>
                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                        <div className="determinate white" style={{width:turnpct+'%',backgroundColor: 'white'}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider-thin"></div>
                    <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                        <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={straights_tooltip}>
                            <div style={{order:'1',flexBasis:'40px'}}>
                                <img src="https://media.exiledracers.com/global/icons/exr_speed.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                    <div className="left">Straights</div>
                                    <div className="right">{raceStraights}</div>
                                </div>
                                <div style={{width:'100%'}}>
                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                        <div className="determinate white" style={{width:straightpct+'%',backgroundColor: 'white'}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider-thin"></div>
                    <div className="row white-text" style={{fontSize:'14px',marginTop:'10px',marginBottom:'5px'}}>
                        <div className="col s12 tracktips" style={{display:'flex',flexDirection:'row',cursor:'context-menu'}} data-position="bottom" data-tooltip={shifts_tooltip}>
                            <div style={{order:'1',flexBasis:'40px'}}>
                                <img src="https://media.exiledracers.com/global/icons/exr_acceleration.png" height="35px" style={{marginLeft:'-2px',marginTop:'-2px'}} />
                            </div>
                            <div style={{order:'2',flex:'10'}}>
                                <div style={{width:'100%',textAlign:'right',fontSize:'12px',marginBottom:'0px'}}>
                                    <div className="left">Speed Shifts</div>
                                    <div className="right">{raceShifts}</div>
                                </div>
                                <div style={{width:'100%'}}>
                                    <div className="progress exr-stats-light-grey" style={{borderRadius:'3px',height:'10px',marginTop:'2px'}}>
                                        <div className="determinate white" style={{width:shiftpct+'%',backgroundColor: 'white'}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider-thin"></div>
                    <div className="row white-text right-align" style={{marginTop:'45px',marginBottom:'0px'}}>
                        
                    </div>
                    <div style={{position:'absolute',bottom:'10px',right:'25px'}}>
                        {galaxy_faction_icon}
                    </div>
                    <div style={{display:'none',position:'absolute',bottom:'25px',left:'25px'}}>
                        {galaxy_faction_icon}
                    </div>
                </>;


        setRaceTrackHTML(tmp_html);

        var tooltips = document.querySelectorAll('.tracktips');
        var instances = window.M.Tooltip.init(tooltips, {exitDelay:200});
        var raceday_number = 1;
        var race_track_id = raceDetails['race_track_id'];

        setTimeout(function() {
            loadRacedayTrack(race_track_id);
        },1000);
    }


    async function loadRacedayTrack(race_track_id) {
        let track_viewer_container = window.document.getElementById("track-viewer-league");
        if(track_viewer_container) {
            let track_data = await new Track(race_track_id,'id');
            new TrackLoader(track_data,
                () => {
                    if(track_viewer_container) {
                        $("#track-viewer-league").html('');
                        let track_minimap = new Minimap(new TrackMap(track_data),track_viewer_container).populate(null);
                    }
                }
            );
        }
    }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function viewRacerLinueupStack(e) {
        var tmp_racer_id = e.target.dataset.racerid;
        if($("#lineup_stack_"+tmp_racer_id).is(":visible")) {
            $("#lineup_stack_"+tmp_racer_id).slideUp();
            $("#view_stack_button_"+tmp_racer_id).html('View Stack');
            $("#view_stack_button2_"+tmp_racer_id).html('View Stack');
        } else {
            $("#lineup_stack_"+tmp_racer_id).slideDown();
            $("#view_stack_button_"+tmp_racer_id).html('Hide Stack');
            $("#view_stack_button2_"+tmp_racer_id).html('Hide Stack');
        }

        if($("#result_stack_"+tmp_racer_id).is(":visible")) {
            $("#result_stack_"+tmp_racer_id).slideUp();
            $("#view_stack_button3_"+tmp_racer_id).html('View Stack');
            $("#view_stack_button4_"+tmp_racer_id).html('View Stack');
        } else {
            $("#result_stack_"+tmp_racer_id).slideDown();
            $("#view_stack_button3_"+tmp_racer_id).html('Hide Stack');
            $("#view_stack_button4_"+tmp_racer_id).html('Hide Stack');
        }
    }


    function openPredictRacerBox(e) {
        //
    }

    function showStackInfoBox() {
        var sinfobox = document.getElementById('stackinfobox');
        var info_instance = window.M.Modal.getInstance(sinfobox);
        info_instance.open();
    }

    async function goToRaceLanding() {
        let redirect_link = await createRedirectToken(userID);
        if(redirect_link['status'] == 'new') {
            let redirect_token = redirect_link['token'];
            window.open("https://www.exiledracers.com/#/redirect?p=race/"+current_race_unique_id+"&"+redirect_token);
            // console.log("http://localhost:3000/redirect?p=race/"+current_race_unique_id+"&"+redirect_token);
            // window.open("http://localhost:3000/redirect?p=race/"+current_race_unique_id+"&"+redirect_token);
        } else {
            window.open("https://www.exiledracers.com/#/race/"+current_race_unique_id);
        }
    }

    if((entrySet == false)||(race_raceday_id != props.raceday_id)||(props.loadtype == 'details_reload')) {
        setEntry(true);
        grabRacedayDetails();
    }

    return(<>
        <div id="race_details_section" className="row" style={{display:'none',marginBottom:'10px'}}>
            <div className="row" style={{marginTop:'0px',marginBottom:'10px'}}>
                <div className="col s12 l8" style={{margin:"0px",padding:"0px"}}>
                    <div className="row headline-text" style={{margin:'0px',padding:'10px 20px',fontSize:'24px',marginBottom:'10px'}}>
                        <div className="divider-thin" style={{width:'30%'}}></div>
                    </div>
                    <div className="row headline-text" style={{margin:'0px',padding:'0px 20px',fontSize:'20px',marginBottom:'30px'}}>
                        RACE WEEK {raceweek_number} TIMELINE
                    </div>
                    <div className="row"  style={{margin:'0px',padding:'0px 20px'}}>
                        {race_details_html}
                    </div>
                </div>
                <div className="col s12 l4 exr-dark-grey-bg" style={{position:'relative',margin:"0px",padding:"10px",borderRadius:'10px',marginTop:'5px'}}>
                    <div className="col s12 m6 l12" style={{position:'relative',overflow:"hidden",marginBottom:'30px'}}>
                        <div className="row headline-text center-align" style={{position:'relative',margin:"0px",padding:"10px 0px",borderRadius:'10px',fontSize:'14px',lineHeight:'1.25'}}>
                            WEEK {raceweek_number} <br/><span className="exr-base-blue-text" style={{fontSize:'20px'}}>RACE TRACK</span>
                        </div>
                        <div className="transparent" id="track-viewer-league" style={{width:'95%',marginLeft:'2.5%',marginTop:'10px',height:"250px",borderRadius:'10px'}}></div>
                    </div>
                    <div className="col s12 m6 l12 exr-new-dark-grey" style={{position:'relative',margin:"0px",padding:"25px",borderRadius:'10px',marginTop:'5px'}}>
                        {race_track_html}
                    </div>
                </div>
            </div>
        </div>
    </>);
}