import { useEffect } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import "./landing.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
let web3;

export const Landing = () => {
    const dispatch = useDispatch();

    async function updateWalletAddress() {
        let wallet_address = await syncAccountWallet();
        if(wallet_address) {
            if(wallet_address.length > 5) {
                document.getElementById("walletID").value = wallet_address;
                document.getElementById("walletID2").value = wallet_address;
                document.getElementById("walletlabel").style.display = 'none';
                document.getElementById("walletlabel2").style.display = 'none';
            }
        }
    }

    async function submitWhitelistSignup(e) {
        e.preventDefault();

        var firstname = document.getElementById("fname").value;
        var lastname = document.getElementById("lname").value;
        var email = document.getElementById("email").value;
        var walletID = document.getElementById("walletID").value;
        var invitecode = document.getElementById("invitecode").value;

        var addData = new Object();
        addData.fname= encodeURIComponent(firstname);
        addData.lname = encodeURIComponent(lastname);
        addData.email = encodeURIComponent(email);
        addData.walletID = encodeURIComponent(walletID);
        addData.invite_code = encodeURIComponent(invitecode);

        if(walletID.length < 10) {
            alert('Connect Your Metamask Wallet to Register');
        } else {
            document.getElementById("registersubmit").style.display = 'none';
            document.getElementById("registerloading").style.display = 'block';
            axiosInstance.post('whitelist/register/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var signup_result = result.data;
                        
                    if(signup_result.register_status.includes("whitelist")) {
                        setTimeout(function() {
                            document.getElementById("registerform").style.display = 'none';
                            document.getElementById("signupheader").style.display = 'none';
                            document.getElementById("confirmheader").style.display = 'block';
                            document.getElementById("confirmsignupwhitelist").style.display = 'block';
                        }, 3000);
                    } else {
                        setTimeout(function() {
                            document.getElementById("registerform").style.display = 'none';
                            document.getElementById("signupheader").style.display = 'none';
                            document.getElementById("confirmheader").style.display = 'block';
                            document.getElementById("confirmsignuplottery").style.display = 'block';
                        }, 3000);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    async function checkWhitelistSignup(e) {
        e.preventDefault();
        var walletID = document.getElementById("walletID2").value;
        var addData = new Object();
        addData.walletID= encodeURIComponent(walletID);

        document.getElementById("regchecksubmit").style.display = 'none';
        document.getElementById("regcheckloading").style.display = 'block';
        axiosInstance.post('whitelist/user_check/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var signup_result = result.data;
                if(signup_result.register_status == 'whitelist') {
                    setTimeout(function() {
                        document.getElementById("registercheckform").style.display = 'none';
                        document.getElementById("regcheckheader").style.display = 'none';
                        document.getElementById("whitelistheader").style.display = 'block';
                        document.getElementById("confirmwhitelist").style.display = 'block';
                    }, 3000);
                } else if(signup_result.register_status == 'lottery') {
                    setTimeout(function() {
                        document.getElementById("registercheckform").style.display = 'none';
                        document.getElementById("regcheckheader").style.display = 'none';
                        document.getElementById("mintpassheader").style.display = 'block';
                        document.getElementById("confirmlottery").style.display = 'block';
                    }, 3000);
                } else {
                    setTimeout(function() {
                        document.getElementById("registercheckform").style.display = 'none';
                        document.getElementById("regcheckheader").style.display = 'none';
                        document.getElementById("noconfirmheader").style.display = 'block';
                        document.getElementById("confirmnone").style.display = 'block';
                    }, 3000);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    document.addEventListener('DOMContentLoaded', function() {
        window.resetCenterHeight();
        window.startPageModals();
    });
    
    window.addEventListener('resize', function() {
        window.resetCenterHeight();
    });

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="show-on-medium-and-up hide-on-small-only splash-discord-top">
            <div style={{padding:'20px'}}>
                <a onClick={() =>  window.joinDiscordOpen()} title="Join Our Discord" className="cursor-pointer">
                    <img src="css/images/global/Discord-Logo-White.png" height="30px" />
                </a>
            </div>
        </div>
        <div className="row" id="main-landing-page">
            <div className="col s12 transparent" style={{position:'relative'}}>
                <div id="splash-main">
                    <div className="show-on-medium-and-up hide-on-small-only splash-logo">
                        <img src="css/images/global/csr_logo_white.png" height="250px" />
                    </div>
                    <div className="show-on-small hide-on-med-and-up splash-logo">
                        <img src="css/images/global/csr_logo_white.png" width="75%" />
                    </div>
                    <div className="white-text subheadline-text splash-headline">
                        The world's first play-to-earn fantasy NFT<br/>
                        racing & prediction club built on Moonbeam.
                    </div>
                    <div className="white-text headline-text splash-button-bullet-top">
                        &#8226;
                    </div>
                    <div className="splash-main-button-wrapper" title="Register For Whitelist">
                        <button className="btn-flat csr-button-white modal-trigger splash-main-button" 
                                data-target="registerbox"
                                type="submit" title="Submit Registration">
                                <span className="splash-main-button-bracket">[ </span>
                                <span className="headline-text">REQUEST ACCESS</span>
                                <span className="splash-main-button-bracket"> ]</span>
                            </button>
                    </div>
                    <div className="white-text headline-text splash-button-bullet-bottom">
                        &#8226;
                    </div>
                    <div className="white-text subheadline-text splash-mint-status-text">
                        Mint date coming soon. Limited availability. 
                        <a className="csr-button-white modal-trigger" data-target="whitelistcheckbox" style={{cursor:'pointer'}}>
                            <u>Check Your Status</u>.
                        </a> 
                    </div>
                    <div className="show-on-small hide-on-med-and-up splash-discord-button-inline">
                        <a onClick={() =>  window.joinDiscordOpen()} title="Join Our Discord" className="cursor-pointer">
                            <img src="css/images/global/Discord-Logo-White.png" height="30px" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div id="registerbox" className="modal">
            <div className="modal-content">
                <div className="row">
                    <div className="col s12" style={{marginBottom:'15px'}}>                    
                        <h5 id="signupheader" className="headline-text left" style={{marginBottom:'5px'}}>REQUEST ACCESS</h5>
                        <h5 id="confirmheader" className="headline-text left splash-modal-title-alt">YOU'RE REGISTERED!</h5>
                        <img src="css/images/global/csr_logo_black.png" height="40px" className="right hide-on-small-only" />
                    </div>
                    <div id="confirmsignupwhitelist" className="col s12 subheadline-text splash-modal-confirmtxt">
                        Thanks! You're now registered for the Crypto Space Racers whitelist. 
                        <br /><br />
                        We'll be in touch soon with more details on when to expect minting and how the game will work. 
                        <br /><br />
                        Join our <a onClick={() =>  window.joinDiscordOpen()} className="cursor-pointer">Discord</a> to keep in the loop with updates and banter. You can reach out to us 
                        <a href="mailto:info@cryptospaceracers.club">here</a> if you have any questions.
                        <br /><br />
                        - The CSR Team
                    </div>
                    <div id="confirmsignuplottery" className="col s12 subheadline-text splash-modal-confirmtxt">
                        Thanks! You're now registered for the Crypto Space Racers mint pass lottery. 
                        <br /><br />
                        We'll be in touch soon with more details on how the lottery and game will work. 
                        <br /><br />
                        Join our <a onClick={() =>  window.joinDiscordOpen()} className="cursor-pointer">Discord</a> to keep in the loop with updates and banter. You can reach out to us 
                        <a href="mailto:info@cryptospaceracers.club">here</a> if you have any questions.
                        <br /><br />
                        - The CSR Team
                    </div>
                    <form id="registerform" className="subheadline-text" style={{fontSize:'14px'}} onSubmit={submitWhitelistSignup}>
                        <div className="col s12 m6 input-field">
                            <input name="fname" id="fname" type="text" className="validate left" required />
                            <label htmlFor="fname" className="black-text">First Name</label>
                        </div>
                        <div className="col s12 m6 input-field">
                            <input name="lname" id="lname" type="text" className="validate left" required />
                            <label htmlFor="lname" className="black-text">Last Name</label>
                        </div>
                        <div className="col s12 m6 input-field">
                            <input name="email" id="email" type="email" className="validate left" required />
                            <label htmlFor="email" className="black-text">Email Address</label>
                        </div>
                        <div className="col s12 m6 input-field">
                            <input name="invitecode" id="invitecode" type="text" className="left" />
                            <label htmlFor="invitecode" className="black-text">Invite Code (optional)</label>
                        </div>
                        <div className="col s12 m12 input-field">
                            <input name="walletID" id="walletID" type="text" className="validate left" disabled required />
                            <label id="walletlabel" htmlFor="walletID" className="black-text">Wallet Address</label>
                        </div>
                        <div className="col s12 m12">
                            <div id="mmasksync" className="btn-flat csr-button-black left enableEthereumButton splash-submodal-button"
                                title="Connect MetaMask Wallet"
                                onClick={updateWalletAddress}>
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">CONNECT METAMASK</span>
                                <span className="splash-main-button-bracket">]</span>
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'15px'}}>
                            <button id="registersubmit" className="btn-flat csr-button-black modal-trigger right splash-modal-button"
                                type="submit" title="Submit Registration">
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">REGISTER</span>
                                <span className="splash-main-button-bracket">]</span>
                            </button>
                            <div id="registerloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="whitelistcheckbox" className="modal">
            <div className="modal-content">
                <div className="row">
                    <div className="col s12" style={{marginBottom:'15px'}}>                    
                        <h5 id="regcheckheader" className="headline-text left" style={{marginTop:'5px'}}>CHECK MY STATUS</h5>
                        <h5 id="whitelistheader" className="headline-text left splash-modal-title-alt">ON THE WHITELIST!</h5>
                        <h5 id="mintpassheader" className="headline-text left splash-modal-title-alt">MINT PASS LOTTERY!</h5>
                        <h5 id="noconfirmheader" className="headline-text left splash-modal-title-alt">YOU'RE NOT REGISTERED!</h5>
                        <img src="css/images/global/csr_logo_black.png" height="40px" className="right hide-on-small-only" />
                    </div>
                    <div id="confirmwhitelist" className="col s12 subheadline-text splash-modal-confirmtxt">
                        Congrats! You're registered for the Crypto Space Racers Whitelist. 
                        <br /><br />
                        Join our <a onClick={() =>  window.joinDiscordOpen()} className="cursor-pointer">Discord</a> to keep in the loop with updates and banter. You can reach out to us 
                        <a href="mailto:info@cryptospaceracers.club">here</a> if you have any questions.
                        <br /><br />
                        - The CSR Team
                    </div>
                    <div id="confirmlottery" className="col s12 subheadline-text splash-modal-confirmtxt">
                        You're registered for the Crypto Space Racers Mint Pass Lottery. 
                        <br /><br />
                        Join our <a onClick={() =>  window.joinDiscordOpen()} className="cursor-pointer">Discord</a> to keep in the loop with updates and banter. You can reach out to us 
                        <a href="mailto:info@cryptospaceracers.club">here</a> if you have any questions.
                        <br /><br />
                        - The CSR Team
                    </div>
                    <div id="confirmnone" className="col s12 subheadline-text splash-modal-confirmtxt">
                        You're not registered for the Crypto Space Racers Whitelist or Mint Pass Lottery. 
                        <br /><br />
                        Use the Register Button on the Crypto Space Racers homepage to register.
                        <br /><br />
                        - The CSR Team
                    </div>
                    <form id="registercheckform" className="subheadline-text" style={{fontSize:'14px'}} onSubmit={checkWhitelistSignup}>
                        <div className="col s12 m12 input-field">
                            <input name="walletID2" id="walletID2" type="text" className="validate left" disabled required />
                            <label id="walletlabel2" htmlFor="walletID2" className="black-text">Wallet Address</label>
                        </div>
                        <div className="col s12 m12">
                            <div id="mmasksync" className="btn-flat csr-button-black left enableEthereumButton2 splash-submodal-button"
                                title="Connect MetaMask Wallet"
                                onClick={updateWalletAddress}>
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">CONNECT METAMASK</span>
                                <span className="splash-main-button-bracket">]</span>
                            </div>
                        </div>
                        <div className="col s12" style={{marginTop:'15px'}}>
                            <button id="regchecksubmit" className="btn-flat csr-button-black modal-trigger right splash-modal-button"
                                type="submit" title="Submit Registration">
                                <span className="splash-main-button-bracket">[</span>
                                <span className="headline-text">SUBMIT</span>
                                <span className="splash-main-button-bracket">]</span>
                            </button>
                            <div id="regcheckloading" className="preloader-wrapper small active right splash-modal-loader">
                                <div className="spinner-layer spinner-red-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>);
};
