import * as THREE from "three";

/**
 * Scene lights
 */
export class Lights {
    static SUN_STRENGTH_MAIN = 0;
    static SUN_STRENGTH_BACKGROUND = 0;
    static AMBIENT_STRENGTH = .3;
    static SHADOW_CAM_Y = 15;
    static SHADOW_RADIUS = 30;
    static SHADOW_RESOLUTION = 2048;

    /**
     * Construct the lights
     * @param {WebGLRenderer} renderer The renderer
     * @param {Scene} scene The scene to add lights to
     * @param {Scene} sceneBackground The background scene
     * @param {boolean} [castShadow] True if a shadow needs to be cast
     */
    constructor(renderer, scene, sceneBackground, castShadow = false) {
        this.sun = new THREE.DirectionalLight(0xFFFFFF, Lights.SUN_STRENGTH_MAIN);
        this.sunBackground = new THREE.DirectionalLight(0xFFFFFF, Lights.SUN_STRENGTH_BACKGROUND);
        this.ambient = new THREE.AmbientLight(0xFFFFFF, Lights.AMBIENT_STRENGTH);

        this.sun.position.set(0, 1, 0);

        if (castShadow) {
            this.sun.castShadow = true;
            this.sun.shadow.mapSize.width = this.sun.shadow.mapSize.height = Lights.SHADOW_RESOLUTION;
            this.sun.shadow.camera.near = .1;
            this.sun.shadow.camera.far = 100;

            this.sun.shadow.camera.left = this.sun.shadow.camera.top = -Lights.SHADOW_RADIUS;
            this.sun.shadow.camera.right = this.sun.shadow.camera.bottom = Lights.SHADOW_RADIUS;
        }

        scene.add(this.sun);
        scene.add(this.ambient);

        sceneBackground.add(this.ambient.clone());
        sceneBackground.add(this.sunBackground);
    }

    /**
     * Set the strength of the background lighting
     * @param {number} strength The light strength in the range [0, 1]
     */
    setBackgroundStrength(strength) {
        this.sunBackground.intensity = Lights.SUN_STRENGTH_BACKGROUND * strength;
    }

    /**
     * Set the preferred focus of the shadow map
     * @param {Vector3} position The preferred focus
     */
    setShadowFocus(position) {
        this.sun.position.copy(position);
        this.sun.position.y += Lights.SHADOW_CAM_Y;
        this.sun.target.position.copy(position);
        this.sun.target.updateMatrixWorld();
    }
}