import {CameraController} from "../cameraController.js";
import * as THREE from "three";

/**
 * A chasing camera controller
 */
export class CameraControllerChase extends CameraController {
    static DISTANCE = 7;
    static RAISE = new THREE.Vector3(0, 8, 0);
    static SHIFT = new THREE.Vector3(0, 6, 0);
    static SPRING_POSITION = .18;
    static SPRING_QUATERNION = .06;

    /**
     * A ship chasing camera
     * @param {Camera} camera The camera to control
     */
    constructor(camera) {
        super(camera);

        this.targetPosition = new THREE.Vector3();
        this.targetQuaternion = new THREE.Quaternion();
        this.targetAim = new THREE.Vector3();

        this.cameraPosition = new THREE.Vector3();
        this.cameraPositionPrevious = this.cameraPosition.clone();
    }

    /**
     * Update
     * @param {number} delta The time delta
     */
    update(delta) {
        this.cameraPositionPrevious.copy(this.cameraPosition);
        this.cameraPosition.lerp(this.targetPosition, CameraControllerChase.SPRING_POSITION);
    }

    /**
     * Set the target for this camera controller
     * @param {CameraTargetable} target The target to track
     * @param {CameraTargetable[]} targets All targets that can be tracked
     * @param {Track} track The track
     * @param {number} time The time interpolation in the range [0, 1]
     * @param {boolean} [instant] True if the change should be instant
     */
    setTarget(target, targets, track, time, instant = false) {
        this.targetPosition.copy(target.getDirection(time)).multiplyScalar(-CameraControllerChase.DISTANCE).add(target.getPosition(time)).add(CameraControllerChase.RAISE);

        if (instant) {
            this.cameraPosition.copy(this.targetPosition);
            this.cameraPositionPrevious.copy(this.cameraPosition);
            this.targetQuaternion.copy(target.getQuaternion(time));
        }
        else
            this.targetQuaternion.slerpQuaternions(this.targetQuaternion, target.getQuaternion(time), CameraControllerChase.SPRING_QUATERNION);

        this.targetAim.copy(CameraControllerChase.SHIFT).applyQuaternion(this.targetQuaternion).add(target.getPosition(time));

        this.camera.position.copy(this.cameraPositionPrevious).lerp(this.cameraPosition, time);
        this.camera.up.set(0, 1, 0).applyQuaternion(this.targetQuaternion);
        this.camera.lookAt(this.targetAim);
        this.camera.up.set(0, 1, 0);
    }
}