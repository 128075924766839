import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

export const BoosterNft = ({ address, index }) => {
  const { boosterContract } = useSelector((state) => state.web3);
  const [tokenUri, setTokenUri] = useState("");
  const [tokenId, setTokenId] = useState(null);
  const { data, error } = useSWR(tokenUri, fetcher);

  useEffect(() => {
    console.log("loading NFT for pilot index: ", index);
    getNft();
  }, [index]);

  async function getNft() {
    try {
      // const id = await boosterContract.tokenOfOwnerByIndex(address, index);
      // setTokenId(id.toNumber());
      const uri = await boosterContract.tokenUri(1);
      console.log("booster uri: ", uri);
      setTokenUri(uri);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="col s12 m6" style={{marginBottom:'20px'}}>
      <div className="center-align" style={{height:'300px',width:'100%',overflow:'hidden',border:'1px solid #141414'}}>
        <img src={data?.image} height="300px"/>
      </div>
    </div>
  );
};