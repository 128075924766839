import { ethers } from "ethers";

export const setRacerContract = (contract) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_RACER_CONTRACT",
      payload: contract,
    });
  };
};

export const setPilotContract = (contract) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_PILOT_CONTRACT",
      payload: contract,
    });
  };
};

export const setBoosterContract = (contract) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_BOOSTER_CONTRACT",
      payload: contract,
    });
  };
};

export const setMintPackContract = (contract) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_MINT_PACK_CONTRACT",
      payload: contract,
    });
  };
};

export const setRacerMintPrice = (price) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_RACER_MINT_PRICE",
      payload: ethers.utils.formatEther(price),
    });
  };
};

export const setTxHash = (hash) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_TX_HASH",
      payload: hash,
    });
  };
};

export const setTransactionError = (err) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_TX_ERROR",
      payload: err,
    });
  };
};

export const setTxStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_TX_STATUS",
      payload: status,
    });
  };
};

export const setTxReceipt = (receipt) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_TX_RECEIPT",
      payload: receipt,
    });
  };
};

export const addMintedId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "ADD_MINT_ID",
      payload: id,
    });
  };
};