import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance, closeAllModals} from "../../../../../utils";
import { updateLocation } from "../../../../../redux/App/actions";
import {AutoEntryBanner} from "../../../../Organisms";

import "../../races_home.css";
import $ from "jquery";

export const UpcomingRacesPage = (props) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    // if((racerID.length == 0)||(accountAddress.length == 0)) {
    //     dispatch(updateLocation("staging"));
    // }

    var current_training_left = $("#current-racer-traces").val();
    var current_battles_left = $("#current-racer-iraces").val();
    var current_open_left = $("#current-racer-oraces").val();

    const [entrySet, setEntry] = useState(false);
    const [race_results_html, setResultsHTML] = useState('');
    const [bot_races_html, setBotRacesHTML] = useState('');
    const [invite_races_html, setInviteRacesHTML] = useState('');
    const [open_races_html, setOpenRacesHTML] = useState('');
    const [league_races_html, setLeagueRacesHTML] = useState('');
    const [all_races_html, setAllRacesHTML] = useState('');
    const [boardwidth,setBoardWidth] = useState('90%');
    const [boardlmargin,setBoardLMargin] = useState('5%');

    let pilot_sample = ['https://exr.mypinata.cloud/ipfs/Qmb26S3DUyx8Mc4YsMTpKffErdDyub6wRZRovAECqYJnNG',
    'https://exr.mypinata.cloud/ipfs/Qmey7boQhHUYTg3a7d1M67Uin3Jk8cfLA92PhmKvnF8SG2',
    'https://exr.mypinata.cloud/ipfs/QmS9cJMDyq6BFJ62Fy18RWzi5aV56uGUErABVecPHVmz6C',
    'https://exr.mypinata.cloud/ipfs/Qmc4FmVw89j8ZtQYpMaCvhiJFnL9CYibdJRoJJ5vDuNahV',
    'https://exr.mypinata.cloud/ipfs/QmXWtKm5rNVt9FiA3dJaH8DuQdsD2cuzw67ECWcC1r1bRK'];

    let currentRacerHandle = $("#current-racer-handle").val();

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    let items_checked = 'N';
    var racer_items = [];
    function grabRacerRaces() {

        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.raceStatus = 'upcoming';
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('member/races_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var race_details = result.data; 
                loadRacerRaces(race_details,'invites');
                loadRacerRaces(race_details,'leagues');
                loadRacerRaces(race_details,'allraces');
            } else {
                loadRacerRaces([],'invites');
                loadRacerRaces([],'leagues');
                loadRacerRaces([],'allraces');
            }
            
        }).catch(error => {
            console.log(error);
        });
    }

    function grabOpenRacerRaces() {
        var addData = new Object();
        addData.racerID = encodeURIComponent(racerID);
        addData.walletID = encodeURIComponent(accountAddress);
        addData.raceStatus = 'upcoming';
        addData.raceHost = encodeURIComponent(window.location.host);

        // addData.racerID = encodeURIComponent(1156);
        // addData.walletID = encodeURIComponent('0xE8454877F1955F4e1049D591E7cEAEe92Ec53fA6');
        // addData.raceStatus = 'upcoming';
        // addData.raceHost = encodeURIComponent('www.exiledracers.com');

        axiosInstance.post('member/open_races_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var race_details = result.data; 
                loadRacerRaces(race_details,'open_races');
            } else {
                loadOpenRaces([]);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadRacerRaces(racearr,racetype) {
        var allrace_section_html = [];
        var invited_section_html = [];
        var league_section_html = [];
        var open_section_html = [];
        var invitedboxes = 0;
        var openboxes = 0;
        var leagueboxes = 0;

        for(var z = 0; z < racearr.length;z++) {
            var race_id = racearr[z].race_id;
            var race_unique_id = racearr[z].race_unique_id;
            var race_name = racearr[z].race_name;
            var final_race_name = race_name+' #'+race_id;

            var race_coins_earned = racearr[z].race_coin_prize;
            var race_xp_earned = racearr[z].race_xp_prize;
            var race_standing = racearr[z].race_final_position;
            var race_participant_id = racearr[z].race_participant_id;
            var race_entry_fee = racearr[z].race_entry_fee;
            var invited_by = racearr[z].invited_by_racer_name;
            var racer_names = racearr[z].racer_names;
            var racer_sim_watched = racearr[z].racer_sim_watched;

            var open_invite_race = racearr[z].open_invite_race;
            var entry_window_closed = racearr[z].entry_window_over;
            var date_entry_close = racearr[z].date_race_entry_close_eastern;
            var date_predictions_close = racearr[z].date_race_predictions_close_eastern;
            var date_simulation_close = racearr[z].date_race_simulated_close_eastern;

            if(open_invite_race == 1) {
                final_race_name = 'Open '+final_race_name;
            }
            var statuscolor = 'white black-text';
            if(race_standing == 1) {
                statuscolor = 'exr-red-bg white-text';
            } 
            if(race_standing != null) {
                race_standing = ordinal_suffix_of(race_standing)+'PLACE';
            } else {
                race_standing = 'DNP';
            }

            var days_ended = racearr[z].days_since_entered;
            var race_date = '';
            if(days_ended == 0) {
                race_date = "Today";
            } else if (days_ended == 1) {
                race_date = "Yesterday";
            } else {
                race_date = days_ended+" Days Ago";
            }

            let racingagainst = '';
            var nfticondisplay = 'none';
            var race_link = "/#/race_lineup/"+race_unique_id;
            if(race_standing != 'DNP') {
                race_standing = 'READY TO RACE';
                statuscolor = 'exr-red-bg white-text';
            } else if(race_participant_id > 0) {
                race_standing = 'ENTERED';
            } else {
                race_standing = 'NOT ENTERED';
            }
            var person_icon = 'person';
            var invited_by_text = '';
            var total_invites = 0;
            var currently_racing = 0;
            for(var x = 0; x < racer_names.length;x++) {
                if(racer_names[x] !== null) {
                    if(racer_names[x].toUpperCase() != $("#current-racer-handle").val().toUpperCase()) {
                        if(total_invites > 0) {
                            invited_by_text = invited_by_text+", "; 
                        }
                        total_invites = total_invites + 1;
                        if(total_invites < 3) {
                            invited_by_text = invited_by_text+racer_names[x];
                        } else if (total_invites == 3) {
                            var remaining_people = racer_names.length-3;
                            invited_by_text = invited_by_text+" +"+remaining_people+' More';
                            x = racer_names.length;
                        } else {
                            
                        }
                    } else {
                        currently_racing = 1;
                    }
                }
            }

            if(total_invites == 0) {
                invited_by_text = 'Invite Racers';
                person_icon = 'person_add';
                final_race_name = 'Race Battle #'+race_id;
                racingagainst = 'No One';
            } else {
                racingagainst = invited_by_text;
            }
            
            if(invited_by_text == 'EXR-Bot') {
                final_race_name = 'Bot Race #'+race_id;
            }

            var feeicondisplay = 'block';
            if(race_entry_fee == 0) {
                feeicondisplay = 'none';
            }

            var newbadgehtml = <></>;
            if((race_date == 'Today')||(currently_racing == 0)) {
                newbadgehtml = <span className={"top-nav-header-item-badge left exr-red-bg white-text"} style={{marginTop:'0px'}}>NEW</span>;
            }
                
            var tmp_html = <div key={z} className="row race-board-row" id={'race_row_'+race_id}>
                            <a data-raceid={race_unique_id} onClick={(e)=>switchToRace(e)} className="topnavtoggle">
                                <div className="col s12 l3">
                                    <span className="left topnavtoggle">
                                        {final_race_name.toUpperCase()}
                                    </span>
                                    {newbadgehtml}
                                    <div className="right right-align show-on-med-and-down hide-on-large-only" style={{marginRight:'0px',marginBottom:'10px'}}>
                                        <span className={"new badge left "+statuscolor} style={{fontWeight:'bold'}} data-badge-caption={race_standing}></span>
                                    </div>
                                    <div className="right right-align show-on-med-and-down hide-on-large-only" style={{minWidth:'85px',marginRight:'15px'}}>
                                        {race_date}
                                    </div>
                                    
                                </div>
                                <div className="col s12 l9 right-align">
                                    <div className="left left-align hide-on-med-and-down show-on-large-only" style={{minWidth:'85px',marginRight:'15px'}}>
                                        {race_date}
                                    </div>
                                    <div className="left left-align hide-on-med-and-down show-on-large-only" style={{minWidth:'95px',marginRight:'15px'}}>
                                        <span className={"new badge left "+statuscolor} style={{fontWeight:'bold'}} data-badge-caption={race_standing}></span>
                                    </div>
                                    <div className="left">
                                        <span className="left left-align">
                                            <i className="material-icons grey-text" style={{marginTop:'-3px',marginRight:'5px'}}>{person_icon}</i>
                                        </span>
                                        <span className="left">{invited_by_text}</span>
                                    </div>
                                    <div className="right right-align">
                                        <span className="race-board-prize-text" title="XP Earned">{race_xp_earned}</span>
                                        <span className="race-board-prize-icon" title="XP Earned">
                                            <img src="https://media.exiledracers.com/global/icons/exr_xp_icon.png" height="25px" />
                                        </span>
                                        <span className="race-board-prize-text" style={{marginLeft:'5px'}} title="RPM Awarded">{race_coins_earned}</span>
                                        <span className="race-board-prize-icon" title="RPM Awarded">
                                            <img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="25px" />
                                        </span>
                                        <span className="race-board-prize-text" style={{marginLeft:'5px',display:feeicondisplay}} title="Entry Fee">{race_entry_fee}</span>
                                        <span className="race-board-prize-icon2" style={{display:feeicondisplay}} title="Entry Fee">
                                            <i className="material-icons grey-text">toll</i>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>;
            
            /* Setup All Races Row */
            if((open_invite_race == 0)||(entry_window_closed == 1)) {
                allrace_section_html.push(tmp_html);
            }

            /* Push League and Invite Gridboxes */
            if(final_race_name.indexOf("League") >= 0) {
                let race_type = 'league';
                let race_name = racearr[z].race_name;
                let race_action = 'GO TO RACE';
                if(racearr[z].current_participant == 1) {
                    race_action = 'RACE ENTERED';
                } else if(racearr[z].racer_gated == 1) {
                    race_action = 'LOCKED';
                } else if((racearr[z].racer_gated == 0)&&(racearr[z].token_gated == 1)) {
                    race_action = 'UNLOCKED';
                }

                let race_participants = 0;
                if(racearr[z].total_participants > 0) {
                    race_participants = racearr[z].total_participants;
                }
                
                var entry_close_time = new Date(date_entry_close);
                var prediction_close_time = new Date(date_predictions_close);
                var simulation_close_time = new Date(date_simulation_close);
                var today_date = new Date();
                var entry_day_diff = (entry_close_time - today_date) / (1000*60*60*24);

                var sim_min = simulation_close_time.getMinutes();
                var sim_hour = simulation_close_time.getHours();
                var timedirection = 'PM';
                if (sim_hour < 12) {
                    timedirection = 'AM';
                } else if (sim_hour > 12) {
                    sim_hour = sim_hour - 12;
                }
                if(sim_hour == 0) {
                    sim_hour = 12;
                }
                let race_time = sim_hour;
                if(sim_min > 10) {
                    race_time = race_time + ':'+sim_min+' ';
                } else if(sim_min > 0) {
                    race_time = race_time + ':0'+sim_min+' ';
                }
                race_time = race_time + timedirection+' EST';

                var entry_min = entry_close_time.getMinutes();
                var entry_hour = entry_close_time.getHours();
                var entry_day = entry_close_time.getDay();
                var entry_month =  entry_close_time.toLocaleString('default', { month: 'short' });
                var entry_date =  entry_close_time.getDate();

                var timedirection = 'PM';
                if (entry_hour < 12) {
                    timedirection = 'AM';
                } else if (entry_hour > 12) {
                    entry_hour = entry_hour - 12;
                }
                if(entry_hour == 0) {
                    entry_hour = 12;
                }
                let entry_close_time_text = entry_hour;
                if(entry_min > 10) {
                    entry_close_time_text = entry_close_time_text + ':'+entry_min+' ';
                } else if(entry_min > 0) {
                    entry_close_time_text = entry_close_time_text + ':0'+entry_min+' ';
                }
                entry_close_time_text = entry_close_time_text + timedirection+' EST';

                if(entry_day_diff > 0) {
                    entry_close_time_text = entry_month + " " + entry_date + " @ " + entry_close_time_text
                    race_date = 'Enter By: '+entry_close_time_text;
                } else {
                    race_date = ''+race_time+' (Enter By: '+entry_close_time_text+')';
                }

                

                //race_date = 'Enter By: Apr 17th 5PM EST';
                let openimage = 'https://media.exiledracers.com/assets/banners/race_background_midj_'+(z)+'.jpeg';
                if(z == 4) {
                    openimage = 'https://media.exiledracers.com/assets/banners/race_background_midj_7.jpeg';
                }
                current_open_left = $("#current-racer-oraces").val();
                
                let race_type_title = 'TYPE: ';
                if(racearr[z].race_laps > 5) {
                    race_type_title = race_type_title + ' DISTANCE / ';
                } else {
                    race_type_title = race_type_title + ' OPEN / ';
                }
                if(racearr[z].race_scoring == 'reverse') {
                    race_type_title = race_type_title + 'INVERSE RACE';
                } else {
                    race_type_title = race_type_title + 'STANDARD RACE';
                }
                if(racearr[z].current_participant == 0) {
                    if(current_open_left == 1) {
                        race_type_title = race_type_title + ' / 1 RACE LEFT';
                    } else {
                        race_type_title = race_type_title + ' / '+current_open_left+' RACES LEFT';
                    }
                }
                
                let tmp_grid_box = createRaceBoxInfoOpen(race_unique_id, race_date, race_coins_earned, race_xp_earned, race_action, race_participants, openimage, race_name, race_type_title);
                let final_grid_box = <div className="col s12 l6" style={{padding:'2.5px',marginBottom:'10px'}}>{tmp_grid_box}</div>;
                leagueboxes = leagueboxes + 1;
                if(leagueboxes <= 2) {
                    league_section_html.push(final_grid_box);
                }
            } else if (racetype == 'open_races') {
                let race_type = 'open';
                let race_name = racearr[z].race_name;
                let race_action = 'GO TO RACE';
                if(racearr[z].current_participant == 1) {
                    race_action = 'RACE ENTERED';
                } else if(racearr[z].racer_gated == 1) {
                    race_action = 'LOCKED';
                } else if((racearr[z].racer_gated == 0)&&(racearr[z].token_gated == 1)) {
                    race_action = 'UNLOCKED';
                }

                let race_participants = 0;
                if(racearr[z].total_participants > 0) {
                    race_participants = racearr[z].total_participants;
                }
                
                var entry_close_time = new Date(date_entry_close);
                var prediction_close_time = new Date(date_predictions_close);
                var simulation_close_time = new Date(date_simulation_close);
                var today_date = new Date();
                var entry_day_diff = (entry_close_time - today_date) / (1000*60*60*24);

                var sim_min = simulation_close_time.getMinutes();
                var sim_hour = simulation_close_time.getHours();
                var timedirection = 'PM';
                if (sim_hour < 12) {
                    timedirection = 'AM';
                } else if (sim_hour > 12) {
                    sim_hour = sim_hour - 12;
                }
                if(sim_hour == 0) {
                    sim_hour = 12;
                }
                let race_time = sim_hour;
                if(sim_min > 10) {
                    race_time = race_time + ':'+sim_min+' ';
                } else if(sim_min > 0) {
                    race_time = race_time + ':0'+sim_min+' ';
                }
                race_time = race_time + timedirection+' EST';

                var entry_min = entry_close_time.getMinutes();
                var entry_hour = entry_close_time.getHours();
                var entry_day = entry_close_time.getDay();
                var entry_month =  entry_close_time.toLocaleString('default', { month: 'short' });
                var entry_date =  entry_close_time.getDate();

                var timedirection = 'PM';
                if (entry_hour < 12) {
                    timedirection = 'AM';
                } else if (entry_hour > 12) {
                    entry_hour = entry_hour - 12;
                }
                if(entry_hour == 0) {
                    entry_hour = 12;
                }
                let entry_close_time_text = entry_hour;
                if(entry_min > 10) {
                    entry_close_time_text = entry_close_time_text + ':'+entry_min+' ';
                } else if(entry_min > 0) {
                    entry_close_time_text = entry_close_time_text + ':0'+entry_min+' ';
                }
                entry_close_time_text = entry_close_time_text + timedirection+' EST';

                if(entry_day_diff > 0) {
                    entry_close_time_text = entry_month + " " + entry_date + " @ " + entry_close_time_text
                    race_date = 'Enter By: '+entry_close_time_text;
                } else {
                    race_date = ''+race_time+' (Enter By: '+entry_close_time_text+')';
                }

                

                //race_date = 'Enter By: Apr 17th 5PM EST';
                let openimage = 'https://media.exiledracers.com/assets/banners/race_background_midj_'+(z)+'.jpeg';
                if(z == 4) {
                    openimage = 'https://media.exiledracers.com/assets/banners/race_background_midj_7.jpeg';
                }
                current_open_left = $("#current-racer-oraces").val();
                
                let race_type_title = 'TYPE: ';
                if(racearr[z].race_laps > 5) {
                    race_type_title = race_type_title + ' DISTANCE / ';
                } else {
                    race_type_title = race_type_title + ' OPEN / ';
                }
                if(racearr[z].race_scoring == 'reverse') {
                    race_type_title = race_type_title + 'INVERSE RACE';
                } else {
                    race_type_title = race_type_title + 'STANDARD RACE';
                }
                if(racearr[z].current_participant == 0) {
                    if(current_open_left == 1) {
                        race_type_title = race_type_title + ' / 1 RACE LEFT';
                    } else {
                        race_type_title = race_type_title + ' / '+current_open_left+' RACES LEFT';
                    }
                }

                if(race_action != 'LOCKED') {
                    let tmp_grid_box = createRaceBoxInfoOpen(race_unique_id, race_date, race_coins_earned, race_xp_earned, race_action, race_participants, openimage, race_name, race_type_title);
                    let final_grid_box = <div className="col s12" style={{padding:'2.5px',marginBottom:'0px'}}>{tmp_grid_box}</div>;
                    if(z == 4) {
                        final_grid_box = <div className="col s12" style={{padding:'2.5px',marginBottom:'0px'}}>{tmp_grid_box}</div>;
                    }
                    // if(openboxes < 1) {
                    //     open_section_html.push(final_grid_box);
                    // }
                    if(openboxes >= 0) {
                        open_section_html.push(final_grid_box);
                    }
                    openboxes = openboxes + 1;
                }
            } else if((final_race_name.indexOf("Battle") >= 0)||(final_race_name.indexOf("Invite") >= 0)||(final_race_name.indexOf("Friendly") >= 0)) {
                let race_type = 'invite';
                let race_action = 'GO TO RACE';
                let pilot_image = pilot_sample[z];
                let tmp_grid_box = createRaceBoxInfoInvite(race_unique_id, race_type, final_race_name, racingagainst, race_coins_earned, race_xp_earned, race_action, pilot_image, race_date, currently_racing);
                let final_grid_box = <div className="col s12 l6" style={{padding:'2.5px',marginBottom:'10px'}}>{tmp_grid_box}</div>;
                if((invitedboxes < 2)&&((open_invite_race == 0)||(entry_window_closed == 1))) {
                    invited_section_html.push(final_grid_box);
                }
                invitedboxes = invitedboxes+1;
            }
        }

        /* Relay Proper Loading Even Based on Race Type */
        if(racetype == 'invites') { 
            loadInviteRaces(invited_section_html);
        } else if(racetype == 'leagues') {
            loadLeagueRaces(league_section_html);
        } else if(racetype == 'open_races') {
            loadOpenRaces(open_section_html);
        } else {
            loadAllRaces(allrace_section_html);
        }

        setTimeout(function(){
            props.sizeAdjust();
        },500);
    }

    function createRaceBoxInfo(race_hash, race_type, race_name, race_time, rpm_amount, xp_amount, race_action, bgimage, race_participants=0, race_type_text) {
        let race_info_html = <></>;
        let race_subheadline = 'RACE STARTS IN';
        let race_helpicon = <></>;
        if(race_type == 'daily') {
            race_subheadline = 'RACE STATUS';
        } else if (race_type == 'invite') {
            race_subheadline = 'RACING AGAINST';
        } else if (race_type == 'invite-banner') {
            race_subheadline = 'RACE AGAINST FRIENDS';
        } else if (race_type == 'league') {
            race_subheadline = 'RACE STARTS IN';
        } else if (race_type == 'open-banner') {
            race_subheadline = 'NEXT OPEN INVITE RACE';  
        } else if (race_type == 'open-custom') {
            race_subheadline = race_type_text; 
            race_helpicon = <div data-iboxid="infobox" className="material-icons material-symbols-outlined white-yellow-link" 
            style={{position:'absolute',top:'20px',right:'20px',fontSize:"24px"}} 
            title="Learn About Race Types" onClick={(e)=>showRaceTypeInfobox(e)}>info</div>
        } 
        let bgimagetxt = "black url('"+bgimage+"')";

        let people_amount = <></>;
        if(race_participants > 0) {
            people_amount = <>
            <span className="left material-icons white-text" style={{marginLeft:'15px',fontSize:'18px',marginTop:'2px'}}>person
            </span>
            <span className="left" style={{marginLeft:'2px',fontSize:'16px'}}>
                {race_participants} Racers
            </span></>
        }   

        let buttoncolor = 'round-border-button';
        if(race_action == 'RACE ENTERED') {
            buttoncolor = 'round-border-button-yellow';
        }

        race_info_html = <div className="page-gridbox" data-racegating={race_action} data-race_type={race_type} data-raceid={race_hash} onClick={(e)=>switchToRace(e)} style={{position:'relative','cursor':'pointer',background:bgimagetxt,backgroundSize:'cover',backgroundPosition:'center'}} title="Go To Race">
                            <div className="exr-trans-black-bg-subtle" style={{width:'100%',height:'100%',position:'absolute',top:'0px',left:'0px',zIndex:'-10px',borderRadius:'10px'}}></div>
                            {race_helpicon}
                            <div className="page-gridbox-header">{race_name}</div>
                            <div className="page-gridbox-info">
                                <div className="page-gridbox-inforow headline-text" style={{marginBottom:'0px',lineHeight:'1',fontSize:'14px'}}>
                                    {race_subheadline}
                                </div>
                                <div className="page-gridbox-inforow code-text" style={{fontSize:'18px',marginTop:'5px',lineHeight:'1'}}>
                                    {race_time}
                                </div>
                                <div className="page-gridbox-inforow" style={{marginTop:'10px'}}>
                                    <span className="left" style={{marginTop:'-2px',marginLeft:'-5px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="25px" />
                                    </span>
                                    <span className="left" style={{marginLeft:'2px',marginRight:'15px',fontSize:'16px'}}>
                                        {rpm_amount}
                                    </span>
                                    <span className="left" style={{marginTop:'-2.5px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_xp_icon.png" height="26px" />
                                    </span>
                                    <span className="left" style={{marginLeft:'2px',fontSize:'16px'}}>
                                        {xp_amount}
                                    </span>
                                    {people_amount}
                                </div>
                                <div className="page-gridbox-inforow">
                                    <span className={"left "+buttoncolor}>
                                        {race_action}
                                    </span>
                                </div>
                            </div>
                            
                        </div>;

        return race_info_html;
    }

    function createRaceBoxInfoInvite(race_hash, race_type, race_name, race_time, rpm_amount, xp_amount, race_action, bgimage, race_date, currently_racing) {
        let race_info_html = <></>;
        let race_subheadline = '';
        if(race_type == 'daily') {
            race_subheadline = 'RACE STATUS';
        } else if (race_type == 'invite') {
            race_subheadline = 'RACING AGAINST';
        } else if (race_type == 'invite-banner') {
            race_subheadline = 'RACE STATUS';
        } else if (race_type == 'league') {
            race_subheadline = 'RACE STARTS IN';
        } else if (race_type == 'open-banner') {
            race_subheadline = 'NEXT OPEN INVITE RACE';  
        } else {
            race_subheadline = 'RACE STARTS IN';
        }
        let bgimagetxt = "black url('"+bgimage+"')";

        var newbadgehtml = <></>;
        if((race_date == 'Today')||(currently_racing == 0)) {
            newbadgehtml = <span className={"top-nav-header-item-badge exr-red-bg white-text"} style={{position:'absolute',bottom:'20px',left:'20px',fontSize:'12px'}}>NEW</span>;
        }

        race_info_html = <div className="page-gridbox" data-race_type={race_type} data-raceid={race_hash} onClick={(e)=>switchToRace(e)} style={{position:'relative','cursor':'pointer'}} title="Go To Race">
                            <div className="slantedPilot" style={{background:bgimagetxt,backgroundSize:'cover',backgroundPosition:'center'}}></div>
                            <div className="page-gridbox-header">
                                <span className="left">{race_name}</span>
                            </div>
                            <div className="page-gridbox-info">
                                <div className="page-gridbox-inforow headline-text" style={{marginBottom:'0px',lineHeight:'1',fontSize:'14px'}}>
                                    {race_subheadline}
                                </div>
                                <div className="page-gridbox-inforow code-text" style={{fontSize:'18px',marginTop:'5px',lineHeight:'1'}}>
                                    {race_time}
                                </div>
                                <div className="page-gridbox-inforow" style={{marginTop:'10px'}}>
                                    <span className="left" style={{marginTop:'-2px',marginLeft:'-5px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_coin_icon.png" height="25px" />
                                    </span>
                                    <span className="left" style={{marginLeft:'2px',marginRight:'15px',fontSize:'16px'}}>
                                        {rpm_amount}
                                    </span>
                                    <span className="left" style={{marginTop:'-3.5px'}}>
                                        <img src="https://media.exiledracers.com/global/icons/exr_xp_icon.png" height="26px" />
                                    </span>
                                    <span className="left" style={{marginLeft:'2px',fontSize:'16px'}}>
                                        {xp_amount}
                                    </span>
                                </div>
                                <div className="page-gridbox-inforow">
                                    <span className="left round-border-button">
                                        {race_action}
                                    </span>
                                </div>
                            </div>
                            {newbadgehtml}
                        </div>;

        return race_info_html;
    }


    
    function loadBotRaces() {
        if(props.holderUser == 0) {
            $("#unofficial-race-section").hide();
        } else {
            current_training_left = $("#current-racer-traces").val();
            current_battles_left = $("#current-racer-iraces").val();
            current_open_left = $("#current-racer-oraces").val();

            let races_html = <></>;

            let race_hash = 'training';
            let race_type = 'daily';
            let race_name = 'Practice Races (Optional)';
            // let race_time = current_training_left+' DAILY RACES LEFT'; 
            // if(current_training_left == 1) {
            //     race_time = current_training_left+' DAILY RACE LEFT'; 
            // }
            let race_time = 'TEST YOUR STACKS AGAINST THE TRAINER'; 
            let rpm_amount = 0;
            let xp_amount = 0;
            let race_action = 'START TRAINING'
            let bg_image = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
            let bot_html = createRaceBoxInfo(race_hash, race_type, race_name, race_time, rpm_amount, xp_amount, race_action,bg_image);

            race_hash = 'invite';
            race_type = 'invite-banner';
            race_name = 'Race Battle Invite';
            race_time = 'RACE AGAINST FRIENDS'; 
            race_time = current_battles_left+' INVITE RACES LEFT'
            if(current_battles_left == 1) {
                race_time = current_battles_left+' INVITE RACE LEFT'
            }
            race_time = 'BATTLE FOR BRAGGING RIGHTS';
            rpm_amount = 0;
            xp_amount = 25;
            race_action = 'CREATE INVITE RACE';
            bg_image = 'https://media.exiledracers.com/assets/banners/EXR_PLANET_01.jpg';
            let invite_html = createRaceBoxInfo(race_hash, race_type, race_name, race_time, rpm_amount, xp_amount, race_action,bg_image);

            // races_html = <><div className="col s12" style={{padding:'0px 2.5px',marginBottom:'10px'}}>
            //                         {bot_html}
            //                     </div></>;

            races_html = <><div className="col s12 l6" style={{padding:'0px 2.5px',marginBottom:'10px'}}>
                    {bot_html}
                    </div>
                    <div className="col s12 l6" style={{padding:'0px 2.5px',marginBottom:'10px'}}>
                    {invite_html}
                    </div></>;

            setBotRacesHTML(races_html);
        }
    }

    function createRaceBoxInfoOpen(race_hash, race_time, rpm_amount, xp_amount, race_action, race_participants, bgimage, racename, racetypetext) {
        let race_info_html = <></>;        

        let races_html = <></>;
        let race_type = 'open-banner';
        let race_name = 'Open Race Battle';
        if(racename) {
            race_name = racename;
        }
        
        if(race_action == '') {
            race_action = 'JOIN RACE';
        }
        if(racetypetext) {
            race_type = 'open-custom';
        }

        // let bg_image = 'https://media.exiledracers.com/assets/banners/EXR_PLANET_02.jpg';
        let bg_image = bgimage;
        let race_html = createRaceBoxInfo(race_hash, race_type, race_name, race_time, rpm_amount, xp_amount, race_action, bg_image, race_participants, racetypetext);

        races_html = <><div className="col s12" style={{padding:'0px 2.5px',marginBottom:'10px'}}>
                                {race_html}
                            </div></>;

        return races_html;
    }

    function loadInviteRaces(htmlarr) {

        let races_html = <></>;
        if(htmlarr.length > 0) {
            races_html = <div className="col s12">
                            <div className="row headline-text" style={{margin:"0px",fontSize:"20px",marginTop:"30px",marginBottom:"0px"}}>
                                LATEST INVITES
                            </div>
                            <div className="row" style={{margin:"0px"}}>
                                <div className="divider-thin"></div>
                            </div>
                            <div className="row" style={{margin:"0px",marginTop:'15px'}}>
                                {htmlarr}
                            </div> 
                        </div>;
        }
        setInviteRacesHTML(races_html);
    }

    function loadOpenRaces(htmlarr) {

        let races_html = <></>;
        if(htmlarr.length > 0) {
            races_html = <div className="col s12">
                            {/* <div className="row headline-text" style={{margin:"0px",fontSize:"20px",marginTop:"30px",marginBottom:"0px"}}>
                                OPEN RACES
                            </div>
                            <div className="row" style={{margin:"0px"}}>
                                <div className="divider-thin"></div>
                            </div> */}
                            <div className="row" style={{margin:"0px",marginTop:'5px'}}>
                                {htmlarr}
                            </div> 
                        </div>;
        }
        setOpenRacesHTML(races_html);
        // $("#unofficial-race-title").show();
        // $("#unofficial-race-divider").show();
        // $("#primary-page-title").html('UPCOMING RACES');
        if(htmlarr.length > 0) {
            $("#unofficial-race-title").show();
            $("#unofficial-race-divider").show();
            $("#primary-page-title").html('UPCOMING RACES');
        } else if (props.holderUser == 1) {
            $("#unofficial-race-title").hide();
            $("#unofficial-race-divider").hide();
            $("#primary-page-title").html('UNOFFICIAL RACES');
        }
    }

    function loadLeagueRaces(htmlarr) {
        let races_html = <></>;
        if(htmlarr.length > 0) {
            races_html = <div className="col s12">
                            <div className="row headline-text" style={{margin:"0px",ontSize:"20px",marginTop:"30px",marginBottom:"0px"}}>
                                UPCOMING LEAGUE RACES
                            </div>
                            <div className="row" style={{margin:"0px"}}>
                                <div className="divider-thin"></div>
                            </div>
                            <div className="row" style={{margin:"0px",marginTop:'15px'}}>
                                {htmlarr}
                            </div> 
                        </div>;
        }
        setLeagueRacesHTML(races_html);
    }

    function loadAllRaces(htmlarr) {
        let races_html = <></>;
        
        if(htmlarr.length > 0) {
            races_html = htmlarr;
        } else {
            races_html = <div className="row race-board-row" >
                            <div className="col s12" style={{marginBottom:'5px'}}>
                                NO UPCOMING RACES
                            </div>
                        </div>;
            if(props.holderUser == 0) {
                
                races_html = <div className="row" >
                            <div className="col s12" style={{marginBottom:'5px'}}>
                                NO UPCOMING RACES
                            </div>
                        </div>;
                $("#upcoming-races-title").hide();
                $("#upcoming-races-divider").hide();
            }
        }
        setAllRacesHTML(races_html);
    }
        
    function switchToRace(e) {
        e.preventDefault();
        var race_hash = '';
        var link_race_type = '';
        var race_gating = '';
        let race_elem = e.target;
        if(race_elem.dataset.iboxid) {

        } else {
            while(race_hash == '') {
                if(race_elem.dataset.raceid) {
                    race_hash = race_elem.dataset.raceid;
                    if(race_elem.dataset.race_type) {
                        link_race_type = race_elem.dataset.race_type;
                    }
                    if(race_elem.dataset.racegating) {
                        race_gating = race_elem.dataset.racegating;
                    }
                } else {
                    race_elem = race_elem.parentElement;
                }
            }
            if(race_gating == 'LOCKED') {
                alert('Race Only Available For Legendary / Exiled Pilot or Racecraft Holders');
            } else {
                var current_training_left = $("#current-racer-traces").val();
                var current_battles_left = $("#current-racer-iraces").val();
                var current_open_left = $("#current-racer-oraces").val();
                var current_host = window.location.host;
                window.location.href= '/#/race/'+race_hash;
                dispatch(updateLocation("race_landing"));
            }
        }
        // if((current_host == 'tester.exiledracers.com')||(current_host == 'localhost:3000')||(current_host == 'localhost:3001')) {
        //     window.location.href= '/#/race/'+race_hash;
        //     dispatch(updateLocation("race_landing"));
        // } else {    
        //     if((current_training_left > 0)&&((link_race_type == 'open-banner')||(link_race_type == 'invite-banner'))) {
        //         alert('Complete All Of Your Daily Training Races Before Joining a New Race');
        //     } else {
        //         window.location.href= '/#/race/'+race_hash;
        //         dispatch(updateLocation("race_landing"));
        //     }
        // }
        
    }

    function showRaceHomeInfobox() {
        var infobox = document.getElementById('training_box_race_home');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    function showRaceTypeInfobox(e) {
        e.preventDefault();
        var infobox = document.getElementById('global_infobox_race_type');
        var info_instance = window.M.Modal.getInstance(infobox);
        info_instance.open();
    }

    if(entrySet == false) {
        setEntry(true);
        loadBotRaces();
        grabRacerRaces();
        grabOpenRacerRaces();
    }

    function goToWorkshop() {
        window.location.href= '/#/workshop';
    }

    return(<>
            <div className="col s12 headline-text" style={{fontSize:"30px"}}>
                <span id="primary-page-title" className="left">UPCOMING RACES</span>
                <span className="right material-icons material-symbols-outlined white-red-link" 
                style={{fontSize:"30px",marginLeft:"10px",marginTop:'4px'}} 
                onClick={()=>showRaceHomeInfobox()} 
                title="Learn About EXR Training">info</span>
            </div>
            <div className="col s12" style={{marginBottom:"20px"}}>
                <div className="divider-thin"></div>
            </div>
            <div className="col s12">
                <div className="row">
                    {open_races_html}
                    {/* <div className="col s12">
                        <div className="row" style={{margin:"0px",marginTop:'5px',marginBottom:'10px'}}>
                            <AutoEntryBanner collectionID={6} />
                        </div> 
                    </div> */}
                    
                    <div id="unofficial-race-section" className="col s12">
                        <div id="unofficial-race-title" className="row headline-text" style={{margin:"0px",fontSize:"20px",marginTop:"30px",marginBottom:"0px",display:'none'}}>
                            UNOFFICIAL RACES
                        </div>
                        <div id="unofficial-race-divider" className="row" style={{margin:"0px",display:'none',marginBottom:'10px'}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="row" style={{margin:"0px",marginTop:'5px'}}>
                            {bot_races_html}
                        </div> 
                    </div>
                    
                    
                    {invite_races_html}
                    {league_races_html}
                    <div className="col s12">
                        <div id="upcoming-races-title" className="row headline-text" style={{margin:"0px",fontSize:"24px",marginTop:"30px",marginBottom:"0px"}}>
                            ALL UPCOMING RACES
                        </div>
                        <div id="upcoming-races-divider" className="row" style={{margin:"0px"}}>
                            <div className="divider-thin"></div>
                        </div>
                        <div className="row" style={{margin:"0px",marginTop:'15px'}}>
                            {all_races_html}
                        </div> 
                    </div>
                </div>
            </div>
        </>);
};