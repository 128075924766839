import axios from "axios";
import dotenv from "dotenv";


const RACER_CONTRACT_ADDRESS = "";

const baseUrl =
  process.env.NODE_ENV === "development" ? `http://localhost:4040/api` : "";

const getNftsForAddress = async (addr) => {
  const res = await axios.get(
    `https://api-rinkeby.etherscan.io/api?module=account&action=tokennfttx&contractaddress=${RACER_CONTRACT_ADDRESS}&address=${addr}&page=1&offset=100&sort=asc&apikey=${"ID2ZN3KHKVBVMRIV1GURWU98DDWBQ14HFA"}`
  );

  console.log(res);
};

export { getNftsForAddress };
