import ImageGallery from 'react-image-gallery';
import "./sectionGallery.css"

export const SectionGallery = ({
  images
}) => {
  if(images && images.length > 0) {
      return (
        <ImageGallery
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
          showBullets={true}
          showNav={true}
          items={images}
          additionalClass="react-image-gallery-buttons gallery-nav"
        />
      );
  } else {
    return null
  }
}