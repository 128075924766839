import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateLocation } from "../../../../../redux/App/actions";
import "../../pubrace_host.css";
import $ from 'jquery';

let web3;
let racer_sim_watched_global = 0;
let race_simulated_global = 0;
let current_racer_global = 0;
let racer_invite_status_global = 0;
let race_hash_global = '';
let pubrace_event_id = '';
let current_race_hash = '';
let current_race_group = 6;
let open_invite_race = 0;
let open_entry_closed = 0;
let predictions_closed = 0;
let entryDisplay = 'none';
let training_box_race_landing = 0;
let current_time_schedule = 0;
let scheduleready = 0;
let schedulestarted = 0;
let race_schedule_array = [];
let backgroundColor = '#EE1965';
let backgroundVideo = 'https://static.vecteezy.com/system/resources/previews/003/605/890/mp4/pink-particle-wave-loop-animation-free-video.mp4';
let cobrandedLogo = 'https://media.exiledracers.com/assets/game/builder/Polkadot_Logo_White_1699372311249.png';


backgroundVideo = 'https://static.vecteezy.com/system/resources/previews/024/199/463/mp4/abstract-red-energy-surface-with-magic-waves-of-particles-and-points-with-an-enfense-of-blur-and-glow-abstract-background-free-video.mp4';
backgroundVideo = 'https://static.vecteezy.com/system/resources/previews/003/500/874/mp4/led-red-light-arrow-background-loop-animation-free-video.mp4';
backgroundVideo = 'https://static.vecteezy.com/system/resources/previews/002/264/381/mp4/3d-slow-motion-glowing-shimmering-wire-mesh-tunnel-loop-free-video.mp4';
export const PolkadotThailandHost = () => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);
    const [pageSet, setPage] = useState(false);
    const [raceHash, setRaceHash] = useState('');
    const [pubRaceInfoHTML, setPubRaceInfoHTML] = useState('');

    if(pageSet == false) {
        setPage(true);
    }

    if((racerID != 1)&&(racerID != 3)&&(racerID != 5)&&(userID != 2146)&&(userID != 2147)) {
        dispatch(updateLocation("workshop"));
        window.location.href= '/#/workshop';
    }

    return(<div style={{position:'relative',width:'100%',height:'100%'}}>
            <div id="splash-image-container" style={{width:'100%',minHeight:'100vh',height:'100%',overflow:'hidden',position:'absolute',top:'0px',left:'0px', zIndex:'-20',background:'url('+backgroundColor+')',backgroundSize:'cover',backgroundPosition:'center'}}>
                <video src={backgroundVideo} style={{border:'0px',overflow:'hidden',marginTop:'-15%',marginLeft:'-25%'}} width="150%" autoPlay loop muted />;
            </div>
            <div className="exr-trans-black-bg-subtle center-align white-text" 
                style={{width:'100%',minHeight:'100vh',height:'100%',position:'absolute',zIndex:'-10',
                        top:'0px',left:'0px',padding:'20px',fontSize:'20px',
                        background:'rgb(0,0,0,.65)',
                        background:'rgb(238,25,101,.8)',
                        }}>
            </div>
            <div className="row white-text page-width-wide top-nav-header-full" style={{marginTop:'0px'}}>
                <div className="col s12 center" style={{marginTop:'2vh'}}>
                    <img src={cobrandedLogo} style={{height:'6.8vh'}} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src="https://media.exiledracers.com/global/exr_logo_white_stacked.png" style={{height:'6vh'}} />
                </div>   
            </div>
        </div>);
};